import React from 'react'
import { Tree, Input, Spin, Tooltip, Modal, Table, Button, DatePicker, Upload, message, Tabs, Form, Row, Col, Drawer, InputNumber, Select } from 'antd';
import './style.scss'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { conversion, updateRowObj } from '../../converse'
import moment from 'moment';
import "moment/locale/zh-cn"
import { onCellf } from '../../comfunc'
import store from '../../store';
import { toTreeDataA, toTreeDataB } from '../../utils/topathtreedata'
import TextArea from 'antd/lib/input/TextArea';
import { Resizable } from 'react-resizable';
import reqwest from 'reqwest';
import { exportExcel } from 'xlsx-oc'
import Draggable from 'react-draggable';
import FileViewer from 'react-file-viewer';
import { getButtonLimit } from '../../utils/getbuttonlimit'

moment.locale('zh-cn')
const confirm = Modal.confirm
const { Search } = Input;
const { TreeNode, DirectoryTree } = Tree;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Error = () => {
  return (
    <div>
      预览文件出错!!!
    </div>
  )
}

class Documentmanage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr: [],

      activeDrags: 0,
      controlledPosition: {
        x: 0, y: 0
      },
      //树属性
      attr: {
        ID: 'ID',
        Name: 'Name',
        UserNumber: 'UserNumber',
        ParentType: 'ParentType',
        Code: 'Code',
        rootId: "0000",
        Leaf: 'Leaf',
        ParentId: 'ParentId',
        Path: 'Path',
        State: 'State',
        treepath: '',
        RecordVersion: 'RecordVersion'
      },

      data: [],
      pageIndex: 1,
      doclistidsele: [],//选择的文档列表
      RecordVersionsele: [],
      doclistsele: [],

      rowId: '',
      rowObj: {},
      leftd: 240,
      centerd: store.getState().clientWidth - 240,
      leftw: store.getState().clientWidth - 240,
      centerw: 0,
      //文档操作
      uploading: false,
      fileList: [],
      formiptobj: {},

      // 备注内容数据
      textcondata: [
        { author: "Administrator", pubtime: "2020-04-10 02:11:53", content: "备注内容备注内容备注内容" },
        { author: "Administrator", pubtime: "2020-04-10 04:22:50", content: "备注内容222备注内容备注内容" }
      ],
      //文件备注新增编辑标识
      textaddedit: "新增",
      textvalue: "",//备注框内容

      //文件预览
      type: "",
      file: "",

      //文档搜索
      searchflag: false,
      searchrange: '',
      searchrangeType: "",
      searchrangeID: "",
      searchuser: '',
      searchname: '',
      searchkey: '',
      searchcode: '',
      searchdate: [],
      searchtype: "",
      datadoc: [],

      //文档树
      currenttabs: 0,
      treeDataA: [],
      treeDataB: [],
      treeDataBB: [],
      selectedKeysBB: [],
      selectedKeysBBObj: {},
      treeDataC: [],
      treeDataD: [],
      treeDataD1: [],
      treeDataD2: [],
      selectedKeysA: [],
      selectedKeysB: [],
      selectedKeysC: [],
      selectedKeysD: [],
      selectedKeysAObj: {},
      selectedKeysBObj: {},
      selectedKeysCObj: {},
      selectedKeysDObj: {},
      treepath: '',

      //上传文件进度
      percent: 0,
      progressper: false,//是否正在上传中判断

      counflag: false,

      //文档版本
      datadocver: [],
      totaldatadoc: 0,
      docId: '',

      //文档收藏
      visible: false,
      collectmoveflag: "",

      //关键字
      ExtractedText: "",
      //上传文件成功失败
      showtooltip: false,

      //搜索
      selectedKeyssearch: [],
      selectedKeyssearchObj: {},
      userdata: store.getState().userdata,
      pageIndex1: 1,

    }
    this.columns = [{
      title: '',
      dataIndex: '',
      key: 'Type2',
      width: '3%',
      onCell: onCellf,
      render: (text, record) => {
        ////console.log(record)
        if (record.Type && record.Type != "Folder") {
          if (record.CheckOut.indexOf("1") > -1) {
            return (<img style={{ width: "18px", height: "14px", top: "5px" }} src={require("../../assets/file/jianchu.png")} alt="" />)
          } else if (record.Locked.indexOf("1") > -1) {
            return (<img style={{ width: "22px", height: "18px", top: "4px", left: "28%" }} src={require("../../assets/file/上锁.png")} alt="" />)
          }
        }
      }
    },
    {
      title: '',
      dataIndex: 'MimeTypeGif',
      key: 'MimeTypeGif',
      width: '4%',
      onCell: onCellf,
      render: (text, record) => {
        if (record.Type && record.Type == "Folder") {
          return (
            <img src={require(`../../assets/file/wenjianjia.png`)} alt="" />
          )
        } else {
          if (text) {
            var str = text.toLowerCase()
            return (
              <img src={require(`../../assets/file/${str}`)} alt="" />
            )
          }
        }
      }
    },
    {
      title: '文件编码',
      dataIndex: 'Code',
      key: 'Code',
      width: '16%',
      onCell: onCellf,
      render: this.renderf,
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: '文件名',
      dataIndex: 'Name',
      key: 'Name',
      width: '25%',
      onCell: onCellf,
      render: this.renderf,
      sorter: (a, b) => {
        var stringA = a.Name.toUpperCase(); // ignore upper and lowercase
        var stringB = b.Name.toUpperCase(); // ignore upper and lowercase
        if (stringA < stringB) {
          return -1;
        }
        if (stringA > stringB) {
          return 1;
        }
        // names must be equal
        return 0;
      },
    },
    {
      title: '大小',
      dataIndex: 'FileSize',
      key: 'FileSize',
      width: '10%',
      onCell: onCellf,
      render: this.renderf
    },
    {
      title: '上传时间',
      dataIndex: 'CreateTime',
      key: 'CreateTime',
      width: '18%',
      onCell: onCellf,
      render: this.renderf,
      sorter: this.compareSortFunction("CreateTime"),
    },
    {
      title: '上传人',
      dataIndex: 'CreatePeople',
      key: 'CreatePeople',
      width: '12%',
      onCell: onCellf,
      render: this.renderf,
      sorter: (a, b) => {
        return a.CreatePeople.localeCompare(b.CreatePeople);
      }
    },
    {
      title: '版本',
      dataIndex: 'VersionNum',
      key: 'VersionNum',
      width: '8%'
    }
    ]
    this.columnsdoc = [
      {
        title: '',
        dataIndex: 'MimeTypeGif',
        key: 'MimeTypeGif',
        width: '5%',
        onCell: onCellf,
        render: (text, record) => {
          if (record.Type && record.Type == "Folder") {
            return (
              <img src={require(`../../assets/file/wenjianjia.png`)} alt="" />
            )
          } else {
            if (text) {
              var str = text.toLowerCase()
              return (
                <img src={require(`../../assets/file/${str}`)} alt="" />
              )
            }
          }
        }
      },
      {
        title: '文件编码',
        dataIndex: 'Code',
        key: 'Code',
        width: '10%',
        onCell: onCellf,
        render: this.renderf,
      },
      {
        title: '文件名',
        dataIndex: 'Name',
        key: 'Name',
        width: '22%',
        onCell: onCellf,
        render: (text, record) => {
          return (<a onClick={() => {
            if (record.MimeType.slice(1).toLowerCase().indexOf('pdf') > -1) {
              window.open(`./pdfjs-dist/web/viewer.html?file=/${encodeURI(record.Path)}`)
            } else if (record.MimeType.slice(1).toLowerCase().indexOf('xls') > -1) {
              this.setState({
                file: record.Path,
                type: "xlsx"
              })
            } else if (record.MimeType.slice(1).toLowerCase().indexOf('doc') > -1 ||
              record.MimeType.slice(1).toLowerCase().indexOf('txt') > -1) {
              this.setState({
                file: record.Path,
                type: "docx"
              })
            } else {
              this.setState({
                file: record.Path,
                type: record.MimeType.slice(1).toLowerCase()
              })
            }
          }} href="javascript:;" style={{ color: "#fff", textDecoration: "underline" }}>{text}</a>)
        }
      },
      {
        title: '大小',
        dataIndex: 'FileSize',
        key: 'FileSize',
        width: '10%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '上传时间',
        dataIndex: 'CreateTime',
        key: 'CreateTime',
        width: '22%',
        onCell: onCellf,
        render: this.renderf,
        sorter: this.compareSortFunction("CreateTime"),
      },
      {
        title: '上传人',
        dataIndex: 'CreatePeople',
        key: 'CreatePeople',
        width: '18%',
        onCell: onCellf,
        render: this.renderf,
        sorter: (a, b) => {
          return a.CreatePeople.localeCompare(b.CreatePeople);
        }
      },
      {
        title: '版本',
        dataIndex: 'VersionNum',
        key: 'VersionNum',
        width: '10%'
      }
    ]
    this.columnsdocver = [
      {
        title: '版本',
        dataIndex: 'VersionNum',
        key: 'VersionNum',
        width: '12%'
      },
      {
        title: '日期',
        dataIndex: 'CreateTime',
        key: 'CreateTime',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '作者',
        dataIndex: 'CreatePeople',
        key: 'CreatePeople',
        width: '25%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '大小',
        dataIndex: 'FileSize',
        key: 'FileSize',
        width: '15%',
      },
      {
        title: '历史版本',
        dataIndex: 'verzip',
        key: 'verzip',
        width: '18%',
        onCell: onCellf,
        render: (text, record) => {
          return (<Button onClick={this.verziprenew.bind(this, record)} className="verzipbutton"
            disabled={this.state.currenttabs == 1 || this.state.currenttabs == 3 || this.state.rowObj.CheckOut == "1" || this.state.rowObj.Locked == "1" || record.VersionFlag == "1" ? true : false}>恢复</Button>)
        }
      }
    ]
    this.renderf = this.renderf.bind(this)
    this.MouseWheelHandler = this.MouseWheelHandler.bind(this)
  }
  renderf(text, record) {
    return (<Tooltip placement="top" className="managetooltip" title={text}>{text}</Tooltip>)
  }
  compareSortFunction(propeties) {
    var compareSort = (rowa, rowb) => this.sortFunction(rowa[propeties], rowb[propeties]);
    return compareSort;
  }
  sortFunction(a, b) {
    if (!a) {
      a = "";
    }
    if (!b) {
      b = "";
    }
    if ((typeof a) !== "number") {
      return a.localeCompare(b);
    }

  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限', res)
    this.setState({
      buttonarr: res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(41)


    axios.get('/DATA/Document/Document.aspx?flag=4').then(data => {
      if (data.data.success) {
        if (data.data.data.length > 0) {
          let attr = this.state.attr;
          let arr = toTreeDataA(data.data.data, attr);
          ////console.log(arr)
          this.setState({
            treeDataA: arr
          })
        } else {
          this.setState({
            treeDataA: []
          })
        }
      }
    })
  }

  //查看属性信息
  toviewattribute() {
    if (this.state.rowId) {
      this.setState({
        centerw: 400,
        leftw: store.getState().clientWidth - 240 - 400,
      }, function () {
        $('.listinfo').css("display", "block")
      })
    } else {
      message.error('请选择文档！')
    }
  }
  //版本恢复
  verziprenew(record) {
    var currenttabs = this.state.currenttabs
    var _this = this
    confirm({
      title: '确定要恢复吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post('/DATA/Document/Document.aspx?flag=17', {
          ID: record.ID,
          GroupId: record.GroupId,
          RecordVersion: record.RecordVersion,
          DVID: record.DVID
        }).then(data => {
          //////console.log(data)
          if (data.data.success) {
            axios.post('/DATA/Document/Document.aspx?flag=21', {
              ID: _this.state.rowId,
              GroupId: _this.state.rowObj.GroupId
            }).then(data => {
              _this.setState({
                datadocver: conversion(data),
              })
            })
            axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
              ParentId: record.ParentId
            }).then(data => {
              _this.setState({
                data: conversion(data),
                rowId: "",
                rowObj: {}
              })
            })
          } else if (data.data.indexOf('数据版本已发生变化') > -1) {
            message.error('数据版本已发生变化,请刷新后重试!')
          }
        })
      },
      onCancel() {
        _this.setState({
          // detaillistId: ''
        })
      },
    });
  }
  //关闭属性信息
  tocloseattribute() {
    this.setState({
      leftw: store.getState().clientWidth - 240,
      centerw: 0
    }, function () {
      $('.listinfo').css("display", "none")
    })
  }
  //显示节点树
  showhidedoctree(key) {
    ////console.log(key)
    if (key == this.state.currenttabs) {

    } else {
      this.setState({
        data: [],
        datadocver: [],
        rowObj: {},
        rowId: "",
        centerw: 0,
        treepath: "",
        doclistsele: [],
        doclistidsele: [],
        RecordVersionsele: []
      }, function () {
        $('.listinfo').css("display", "none")
      })
      if (key == 0) {
        this.setState({
          selectedKeysC: [],
          selectedKeysCObj: {},
          selectedKeysB: [],
          selectedKeysBObj: {},
          selectedKeysD: [],
          selectedKeysDObj: {},
        })
        axios.get('/DATA/Document/Document.aspx?flag=4').then(data => {
          if (data.data.success) {
            if (data.data.data.length > 0) {
              let attr = this.state.attr;
              let arr = toTreeDataA(data.data.data, attr);
              ////console.log(arr)
              this.setState({
                treeDataA: arr,
                currenttabs: 0
              })
            } else {
              this.setState({
                treeDataA: [],
                currenttabs: 0
              })
            }
          }
        })
      } else if (key == 1) {
        this.setState({
          selectedKeysC: [],
          selectedKeysCObj: {},
          selectedKeysA: [],
          selectedKeysAObj: {},
          selectedKeysD: [],
          selectedKeysDObj: {},
        })
        axios.get('/DATA/Document/Document.aspx?flag=6').then(data => {
          var arr = conversion(data)
          let attr = this.state.attr;
          ////console.log(arr)
          let obj = toTreeDataB(arr, attr);
          // //console.log(obj)
          var arr = obj.tree
          var resarr = obj.resData
          var newarr = arr.concat(resarr)
          this.setState({
            treeDataB: newarr,
            currenttabs: 1
          })
        })
      } else if (key == 2) {
        this.setState({
          selectedKeysB: [],
          selectedKeysBObj: {},
          selectedKeysA: [],
          selectedKeysAObj: {},
          selectedKeysD: [],
          selectedKeysDObj: {},
        })
        axios.get('/DATA/Document/Document.aspx?flag=5').then(data => {
          if (data.data.success) {
            if (data.data.data.length > 0) {
              let attr = this.state.attr;
              let arr = toTreeDataA(data.data.data, attr);
              ////console.log(arr)
              this.setState({
                treeDataC: arr,
                currenttabs: 2
              })
            } else {
              this.setState({
                treeDataC: [],
                currenttabs: 2
              })
            }
          }
        })
      } else if (key == 3) {
        this.setState({
          selectedKeysB: [],
          selectedKeysBObj: {},
          selectedKeysA: [],
          selectedKeysAObj: {},
          selectedKeysC: [],
          currenttabs: 3,
          selectedKeysCObj: {},
        })
        axios.get('/DATA/Document/Document.aspx?flag=7').then(data => {
          var arr = conversion(data)
          let attr = this.state.attr;
          let obj = toTreeDataB(arr, attr);
          // //console.log(obj)
          var arr = obj.tree
          var resarr = obj.resData
          var newarr = arr.concat(resarr)
          //console.log('树',newarr)
          this.setState({
            treeDataD2: newarr,
          })
        })
        axios.get('/DATA/Document/Document.aspx?flag=8').then(data => {
          var arr = conversion(data)
          let attr = this.state.attr;
          let obj = toTreeDataB(arr, attr);
          // //console.log(obj)
          var arr = obj.tree
          var resarr = obj.resData
          var newarr = arr.concat(resarr)
          //console.log('树',newarr)
          this.setState({
            treeDataD1: newarr,
          })
        })
      }

      $('.docmanagemodule .doctree').removeClass('doctree-active').eq(key).addClass("doctree-active")
      $('.docmanagemodule .doctitle').removeClass('doctitle-active').eq(key).addClass("doctitle-active")
    }
  }
  //树节点选中
  onSelectA = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
    this.toclosesearch()
    if (info.selected) {
      this.setState({
        selectedKeysA: selectedKeys,
        selectedKeysAObj: info.node.props,
        treepath: info.node.props.treepath,
        file: "",
        type: "",
        counflag: true
      }, function () {
        axios.post('/DATA/Document/Document.aspx?flag=20', {
          ParentId: this.state.selectedKeysA[0]
        }).then(data => {
          ////console.log(conversion(data))
          this.setState({
            data: conversion(data),
            rowObj: {},
            rowId: "",
            doclistidsele: [],
            RecordVersionsele: [],
            doclistsele: [],
            counflag: false
          })
        })
      })
    } else {
      this.setState({
        selectedKeysA: [],
        selectedKeysAObj: {},
        treepath: "",
        data: [],
        file: "",
        type: "",
      })
    }
  };
  onSelectB = (selectedKeys, info) => {
    ////console.log('selected', selectedKeys, info);
    this.toclosesearch()
    if (info.selected) {
      this.setState({
        selectedKeysB: selectedKeys,
        selectedKeysBObj: info.node.props,
        treepath: info.node.props.treepath,
        counflag: true,
        file: "",
        type: "",
      }, function () {
        axios.post('/DATA/Document/Document.aspx?flag=23', {
          ParentId: this.state.selectedKeysB[0]
        }).then(data => {
          if (data.data.success != false) {
            this.setState({
              data: conversion(data),
              counflag: false,
              rowObj: {},
              doclistidsele: [],
              RecordVersionsele: [],
              doclistsele: [],
              rowId: "",
            })
          }
        })
      })
    } else {
      this.setState({
        selectedKeysB: [],
        selectedKeysBObj: {},
        treepath: "",
        data: [],
        file: "",
        type: "",
      })
    }
  };
  onSelectBB = (selectedKeys, info) => {
    this.setState({
      selectedKeysBB: selectedKeys,
      selectedKeysBBObj: info.node.props
    })
  }
  onSelectC = (selectedKeys, info) => {
    ////console.log('selected', selectedKeys, info);
    this.toclosesearch()
    if (info.selected) {
      this.setState({
        selectedKeysC: selectedKeys,
        selectedKeysCObj: info.node.props,
        treepath: info.node.props.treepath,
        counflag: true,
        file: "",
        type: "",
      }, function () {
        axios.post('/DATA/Document/Document.aspx?flag=20', {
          ParentId: this.state.selectedKeysC[0]
        }).then(data => {
          ////console.log(conversion(data))
          this.setState({
            data: conversion(data),
            counflag: false,
            rowObj: {},
            rowId: "",
            doclistidsele: [],
            RecordVersionsele: [],
            doclistsele: [],
          })
        })
      })
    } else {
      this.setState({
        selectedKeysC: [],
        selectedKeysCObj: {},
        treepath: "",
        data: [],
        file: "",
        type: "",
      })
    }
  };
  onSelectD = (selectedKeys, info) => {
    this.toclosesearch()
    console.log('selected', selectedKeys, info);
    var treepath = ""
    if (selectedKeys[0] == "公共文档" || selectedKeys[0] == "个人文档") {
      treepath = info.node.props.treepath
    } else {
      if (selectedKeys[0] != "公共文档" && info.node.props.ParentType == "Public") {
        treepath = "公共文档" + '/' + info.node.props.treepath
      } else if (selectedKeys[0] != "个人文档" && info.node.props.ParentType == "Person") {
        treepath = "个人文档" + '/' + info.node.props.treepath
      }
    }
    if (info.selected) {
      this.setState({
        selectedKeysD: selectedKeys,
        selectedKeysDObj: info.node.props,
        treepath: treepath,
        counflag: true,
        file: "",
        type: "",
      }, function () {
        var flag = this.state.selectedKeysD[0].indexOf('公共文档') > -1 ? 24 :
          this.state.selectedKeysD[0].indexOf('个人文档') > -1 ? 25 : 22
        if (flag == 24 || flag == 25) {
          axios.get(`/DATA/Document/Document.aspx?flag=${flag}`).then(data => {
            this.setState({
              data: conversion(data),
              counflag: false,
              rowObj: {},
              rowId: "",
              doclistidsele: [],
              RecordVersionsele: [],
              doclistsele: [],
            })
          })
        } else {
          axios.post(`/DATA/Document/Document.aspx?flag=${flag}`, {
            ParentId: this.state.selectedKeysD[0]
          }).then(data => {
            this.setState({
              data: conversion(data),
              counflag: false,
              rowObj: {},
              rowId: "",
              doclistidsele: [],
              RecordVersionsele: [],
              doclistsele: [],
            })
          })
        }
      })
    } else {
      this.setState({
        selectedKeysD: [],
        selectedKeysDObj: {},
        treepath: "",
        data: [],
        file: "",
        type: "",
      })
    }
  };

  onSelectsearch = (selectedKeys, info) => {
    //console.log('selected', selectedKeys, info);
    if (selectedKeys[0] == "公共文档" || selectedKeys[0] == "个人文档") {
      this.setState({
        searchrange: selectedKeys[0],
        searchrangeType: selectedKeys[0] == "公共文档" ? "Public" : "Person",
        searchrangeID: "0000"
      })
    } else {
      if (selectedKeys[0]) {
        this.setState({
          searchrange: info.node.props.Code + ' ' + info.node.props.Name,
          searchrangeType: info.node.props.ParentType,
          searchrangeID: selectedKeys[0]
        })
      } else {
        this.setState({
          searchrange: "",
          searchrangeType: "",
          searchrangeID: ""
        })
      }
    }
    this.setState({
      selectedKeyssearch: selectedKeys,
      selectedKeyssearchObj: info.node.props
    })
  };

  //支持格式：
  //Images:png jpeg gif bmp 360-degree images
  //pdf csv xslx docx Video:mp4,webm Audio:mp3
  image2Base64(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var dataURL = canvas.toDataURL("image/png");
    window.open(dataURL)
    // return dataURL;
  }
  toviewfile() {
    if (this.state.rowId) {
      var strlower = this.state.rowObj.MimeType.slice(1).toLowerCase()
      console.log(strlower)
      var type = ""
      if (strlower=="xls" || strlower=="doc" || strlower=='txt' || strlower=='xlsx') {
        message.error(`暂不支持${strlower}格式预览！`)
      } else {
        type = strlower

        this.setState({
          file: this.state.rowObj.Path,
          type: type
        }, function () {
          if (this.state.type == "pdf") {
            window.open(`./pdfjs-dist/web/viewer.html?file=/${encodeURI(this.state.file)}`)
          }
          else if (this.state.type == "xlsx" || this.state.type == "docx") {
            // window.open(`https://view.officeapps.live.com/op/view.aspx?src=/${encodeURI(this.state.file)}`)
          } else {
            var myimage = document.getElementById("swiplun");
            if (myimage.addEventListener) {
              // IE9, Chrome, Safari, Opera
              myimage.addEventListener("mousewheel", this.MouseWheelHandler, false);
              // Firefox
              myimage.addEventListener("DOMMouseScroll", this.MouseWheelHandler, false);
            }
            // IE 6/7/8
            else myimage.attachEvent("onmousewheel", this.MouseWheelHandler);
          }
        })
      }
      // if(type=="pdf"){
      //   window.open(`./pdfjs-dist/web/viewer.html?file=/${encodeURI(this.state.file)}`)
      // }else if(type == "xlsx" || type=="docx"){
      //   window.open(`https://view.officeapps.live.com/op/view.aspx?src=/${encodeURI(this.state.file)}`)
      // }else{
      //   window.open('/'+this.state.rowObj.Path)
      //   // var img = new Image();
      //   // img.crossOrigin = '';
      //   // img.src = this.state.rowObj.Path
      //   // var deferred = $.Deferred();
      //   // var _this = this
      //   // img.onload = function(){
      //   //   deferred.resolve(_this.image2Base64(img))
      //   // }
      // }
    } else {
      message.error('请选择文件!')
    }
  }
  MouseWheelHandler(e) {

    var myimage = document.getElementById("swiplun");
    // cross-browser wheel delta
    var e = window.event || e; // old IE support
    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
    //现在我们直接决定图片的大小范围。以下代码将图片的宽度范围设置在50-800个像素之间
    myimage.style.width = Math.max(50, Math.min(2000, myimage.width + (30 * delta))) + "px";
    myimage.style.height = Math.max(50, Math.min(2000, myimage.height + (30 * delta))) + "px";
    // 最后一点，我们在方法中返回false是为了终止标准的鼠标滚轮事件处理，以防它上下滑动网页
    return false;
  }
  //关闭文档预览
  tocloseviewfile() {
    this.setState({
      file: "",
      type: ""
    })
  }

  //全局搜索
  tohandleSearch() {
    this.setState({
      searchflag: true,
      datadoc: [],
      searchrange: '',
      searchuser: '',
      searchname: '',
      searchkey: '',
      searchcode: '',
      searchdate: [],
      searchtype: ""
    })
  }
  toclosesearch() {
    $('.searchdoctree').css("display", "none")
    this.setState({
      searchflag: false,
    })
    this.tocleardoc()
  }
  //清空
  tocleardoc() {
    this.setState({
      datadoc: [],
      searchrange: '',
      searchuser: '',
      searchname: '',
      searchkey: '',
      searchcode: '',
      searchdate: [],
      searchtype: "",
      searchrangeType: "",
      searchrangeID: "",
      totaldatadoc: 0
    })
  }
  //检索
  tosearchdoc() {
    var arr = this.state.userdata
    console.log(arr)
    var searchuser = ""
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].userName == this.state.searchuser) {
        searchuser = arr[i].userid
      }
    }
    this.setState({
      counflag: true
    }, function () {
      axios.post('/DATA/Document/Document.aspx?flag=41', {
        ParentType: this.state.searchrangeType,
        ID: this.state.searchrangeID,
        Code: this.state.searchcode,
        Name: this.state.searchname,
        ExtractedText: this.state.searchkey,
        UserNumber: this.state.searchrangeType == "Person" ? sessionStorage.getItem('unumber') : searchuser,
        UploadTime: this.state.searchdate[0] ? this.state.searchdate[0] : "",
        UpdateTime: this.state.searchdate[1] ? this.state.searchdate[1] : "",
        MimeType: this.state.searchtype,
        pageIndex: 1,
        pageSize: parseInt((store.getState().clientHeight - 50 - 30 - 270 - 50 - 30) / 28)
      }).then(res => {
        //console.log(res)
        var totaldatadoc = res.data.split(',')[0].split(':')[1]
        this.setState({
          datadoc: conversion(res),
          totaldatadoc: totaldatadoc,
          counflag: false
        })
      })
    })
  }

  //文档树 操作
  adddoctree() {
    this.setState({
      addeditflag: "新建文件夹",
      visibledraw: true,
      formiptobj: {}
    }, function () {
      this.props.form.resetFields()
    })
  }
  editdoctree() {
    var currenttabs = this.state.currenttabs
    if (currenttabs == 0) {
      if (this.state.selectedKeysA[0]) {
        var obj = this.state.selectedKeysAObj
        ////console.log(obj)
        this.setState({
          addeditflag: "文档树编辑",
          visibledraw: true,
          formiptobj: obj
        }, function () {
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择!')
      }
    } else if (currenttabs == 1) {
      if (this.state.selectedKeysB[0]) {
        var obj = this.state.selectedKeysBObj
        this.setState({
          addeditflag: "文档树编辑",
          visibledraw: true,
          formiptobj: obj
        }, function () {
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择!')
      }
    } else if (currenttabs == 2) {
      if (this.state.selectedKeysC[0]) {
        var obj = this.state.selectedKeysCObj
        this.setState({
          addeditflag: "文档树编辑",
          visibledraw: true,
          formiptobj: obj
        }, function () {
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择!')
      }
    } else if (currenttabs == 3) {
      if (this.state.selectedKeysD[0]) {
        var obj = this.state.selectedKeysDObj
        this.setState({
          addeditflag: "文档树编辑",
          visibledraw: true,
          formiptobj: obj
        }, function () {
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择!')
      }
    }
  }
  deledoctree(key) {
    var _this = this
    var currenttabs = this.state.currenttabs
    if (key == "树") {
      if (currenttabs == 0) {
        if (this.state.selectedKeysA[0]) {
          confirm({
            title: '确定要删除吗?',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              axios.post('/DATA/Document/Document.aspx?flag=3', {
                ID: _this.state.selectedKeysA[0],
                Path: _this.state.selectedKeysAObj.Path,
                RecordVersion: _this.state.selectedKeysAObj.RecordVersion,
              }).then(data => {
                //////console.log(data)
                if (data.data.success) {
                  axios.get('/DATA/Document/Document.aspx?flag=4').then(data => {
                    if (data.data.success) {
                      let attr = _this.state.attr;
                      let arr = toTreeDataA(data.data.data, attr);
                      ////console.log(arr)
                      _this.setState({
                        treeDataA: arr,
                        selectedKeysAObj: {},
                        selectedKeysA: [],
                        data: []
                      }, function () {
                        message.success('删除成功!')
                      })
                    }
                  })
                } else if (typeof data.data == "string" && data.data.indexOf('数据版本已发生变化') > -1) {
                  message.error('数据版本已发生变化,请刷新后重试！')
                } else if (typeof data.data == "string" && data.data.indexOf('无法删除') > -1) {
                  message.error('该文件夹下有文档处于锁定、编辑状态无法删除！')
                }
              })
            },
            onCancel() {
              _this.setState({
                // detaillistId: ''
              })
            },
          });
        } else {
          message.error('请选择树节点!')
        }
      } else if (currenttabs == 1) {
        if (this.state.selectedKeysB[0]) {
          confirm({
            title: '确定要删除吗?',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              axios.post('/DATA/Document/Document.aspx?flag=3', {
                ID: _this.state.selectedKeysB[0],
                Path: _this.state.selectedKeysBObj.Path,
                RecordVersion: _this.state.selectedKeysBObj.RecordVersion,
              }).then(data => {
                //////console.log(data)
                if (data.data.success) {
                  axios.get('/DATA/Document/Document.aspx?flag=6').then(data => {
                    var arr = conversion(data)
                    let attr = _this.state.attr;
                    ////console.log(arr)
                    let obj = toTreeDataB(arr, attr);
                    // //console.log(obj)
                    var arr = obj.tree
                    var resarr = obj.resData
                    var newarr = arr.concat(resarr)
                    _this.setState({
                      treeDataB: newarr,
                      selectedKeysBObj: {},
                      selectedKeysA: [],
                      data: []
                    }, function () {
                      message.success('删除成功!')
                    })
                  })
                } else if (data.data.indexOf('数据版本已发生变化') > -1) {
                  message.error('数据版本已发生变化,请刷新后重试!')
                } else if (typeof data.data == "string" && data.data.indexOf('无法删除') > -1) {
                  message.error('该文件夹下有文档处于锁定、编辑状态无法删除！')
                }
              })
            },
            onCancel() {
              _this.setState({
                // detaillistId: ''
              })
            },
          });
        } else {
          message.error('请选择树节点!')
        }
      } else if (currenttabs == 2) {
        if (this.state.selectedKeysC[0]) {
          confirm({
            title: '确定要删除吗?',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              axios.post('/DATA/Document/Document.aspx?flag=3', {
                ID: _this.state.selectedKeysC[0],
                Path: _this.state.selectedKeysCObj.Path,
                RecordVersion: _this.state.selectedKeysCObj.RecordVersion,
              }).then(data => {
                //////console.log(data)
                if (data.data.success) {
                  axios.get('/DATA/Document/Document.aspx?flag=5').then(data => {
                    if (data.data.success) {
                      let attr = _this.state.attr;
                      let arr = toTreeDataA(data.data.data, attr);
                      ////console.log(arr)
                      _this.setState({
                        treeDataC: arr,
                        selectedKeysCObj: {},
                        selectedKeysC: [],
                        data: []
                      }, function () {
                        message.success('删除成功!')
                      })
                    }
                  })
                } else if (data.data.indexOf('数据版本已发生变化') > -1) {
                  message.error('数据版本已发生变化,请刷新后重试!')
                } else if (typeof data.data == "string" && data.data.indexOf('无法删除') > -1) {
                  message.error('该文件夹下有文档处于锁定、编辑状态无法删除！')
                }
              })
            },
            onCancel() {
              _this.setState({
                // detaillistId: ''
              })
            },
          });
        } else {
          message.error('请选择树节点!')
        }
      }
    } else if (key == "文档") {
      if (this.state.doclistidsele.length > 0) {
        var arr = this.state.doclistsele
        var doclistidsele = []
        var RecordVersionsele = []
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].Type != "Folder" && (arr[i].CheckOut && arr[i].CheckOut == "0")) {
            doclistidsele.push(arr[i].ID)
            RecordVersionsele.push(arr[i].RecordVersion)
          }
        }
        if (doclistidsele.length == 0) {
          message.error('请重新选择文件！')
          this.setState({
            doclistidsele: [],
            doclistsele: [],
            RecordVersionsele: []
          })
        } else {
          confirm({
            title: '确定要删除吗?',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              axios.post('/DATA/Document/Document.aspx?flag=16', {
                ID: doclistidsele.join(','),
                RecordVersion: RecordVersionsele.join(','),
              }).then(data => {
                if (data.data.success) {
                  var ParentId = currenttabs == 0 ? _this.state.selectedKeysA[0] :
                    currenttabs == 1 ? _this.state.selectedKeysB[0] :
                      currenttabs == 2 ? _this.state.selectedKeysC[0] :
                        _this.state.selectedKeysD[0]
                  axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
                    ParentId: ParentId
                  }).then(data => {
                    ////console.log(conversion(data))
                    _this.setState({
                      data: conversion(data),
                      rowObj: {},
                      rowId: "",
                      doclistidsele: [],
                      RecordVersionsele: [],
                      doclistsele: [],
                    }, function () {
                      message.success('删除成功!')
                    })
                  })
                } else if (data.data.indexOf('数据版本已发生变化') > -1) {
                  message.error('数据版本已发生变化,请刷新后重试!')
                }
              })
            },
            onCancel() {
              _this.setState({
                // detaillistId: ''
              })
            },
          });
        }
      } else {
        message.error('请选择文件!')
      }
    }
  }

  //draw保存
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      ////console.log(err, values)
      if (!err) {
        var currenttabs = this.state.currenttabs
        if (this.state.addeditflag == "新建文件夹") {
          if (currenttabs == 0) {
            values.ParentId = this.state.selectedKeysA[0] ? this.state.selectedKeysA[0] : "0000"
          } else if (currenttabs == 1) {
            values.ParentId = this.state.selectedKeysB[0] ? this.state.selectedKeysB[0] : "0000"
          } else if (currenttabs == 2) {
            values.ParentId = this.state.selectedKeysC[0] ? this.state.selectedKeysC[0] : "0000"
          } else if (currenttabs == 3) {
            values.ParentId = this.state.selectedKeysD[0] ? this.state.selectedKeysD[0] : "0000"
          }
          values.ParentType = currenttabs == 0 ? "Public" : currenttabs == 1 ? "Favorite" : currenttabs == 2 ? "Person" : "Other"
          values.Path = this.state.treepath
          axios.post('/DATA/Document/Document.aspx?flag=1', values).then(data => {
            ////console.log(data)
            if (data.data.success) {
              if (currenttabs == 0) {
                axios.get('/DATA/Document/Document.aspx?flag=4').then(data => {
                  if (data.data.success) {
                    if (data.data.data.length > 0) {
                      let attr = this.state.attr;
                      let arr = toTreeDataA(data.data.data, attr);
                      ////console.log(arr)
                      this.setState({
                        treeDataA: arr,
                        visibledraw: false
                      }, function () {
                        message.success('添加成功!')
                      })
                    } else {
                      this.setState({
                        treeDataA: []
                      })
                    }
                  }
                })
              } else if (currenttabs == 1) {
                axios.get('/DATA/Document/Document.aspx?flag=6').then(data => {
                  var arr = conversion(data)
                  let attr = this.state.attr;
                  ////console.log(arr)
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  this.setState({
                    treeDataB: newarr,
                    visibledraw: false
                  }, function () {
                    message.success('添加成功!')
                  })
                })
              } else if (currenttabs == 2) {
                axios.get('/DATA/Document/Document.aspx?flag=5').then(data => {
                  if (data.data.success) {
                    if (data.data.data.length > 0) {
                      let attr = this.state.attr;
                      let arr = toTreeDataA(data.data.data, attr);
                      ////console.log(arr)
                      this.setState({
                        treeDataC: arr,
                        visibledraw: false
                      }, function () {
                        message.success('添加成功!')
                      })
                    } else {
                      this.setState({
                        treeDataC: []
                      })
                    }
                  }
                })
              } else if (currenttabs == 3) {
                axios.get('/DATA/Document/Document.aspx?flag=7').then(data => {
                  var arr = conversion(data)
                  let attr = this.state.attr;
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  ////console.log(arr)
                  this.setState({
                    treeDataD2: newarr
                  })
                })
                axios.get('/DATA/Document/Document.aspx?flag=8').then(data => {
                  var arr = conversion(data)
                  let attr = this.state.attr;
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  ////console.log(arr)
                  this.setState({
                    treeDataD1: newarr,
                    visibledraw: false
                  }, function () {
                    message.success('添加成功！')
                  })
                })
              }
            } else if (data.data.indexOf('存在该编码') > -1) {
              message.error('存在该编码的文件夹!')
            }
          })
        } else if (this.state.addeditflag == "文档树编辑") {
          values.ID = this.state.formiptobj.ID
          values.ParentType = this.state.formiptobj.ParentType
          values.RecordVersion = this.state.formiptobj.RecordVersion
          axios.post('/DATA/Document/Document.aspx?flag=2', values).then(data => {
            ////console.log(data)
            if (data.data.success) {
              if (currenttabs == 0) {
                axios.get('/DATA/Document/Document.aspx?flag=4').then(data => {
                  if (data.data.success) {
                    if (data.data.data.length > 0) {
                      var RData = data.data.data
                      var RDataobj = updateRowObj(RData, this.state.formiptobj.ID)
                      let attr = this.state.attr;
                      let arr = toTreeDataA(data.data.data, attr);
                      ////console.log(arr)
                      this.setState({
                        treeDataA: arr,
                        visibledraw: false,
                        selectedKeysAObj: RDataobj
                      }, function () {
                        message.success('保存成功!')
                      })
                    } else {
                      this.setState({
                        treeDataA: []
                      })
                    }
                  }
                })
              } else if (currenttabs == 1) {
                axios.get('/DATA/Document/Document.aspx?flag=6').then(data => {
                  var arr = conversion(data)
                  var RDataobj = updateRowObj(arr, this.state.formiptobj.ID)
                  let attr = this.state.attr;
                  ////console.log(arr)
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  this.setState({
                    treeDataB: newarr,
                    visibledraw: false,
                    selectedKeysBObj: RDataobj
                  }, function () {
                    message.success('保存成功!')
                  })
                })
              } else if (currenttabs == 2) {
                axios.get('/DATA/Document/Document.aspx?flag=5').then(data => {
                  if (data.data.success) {
                    if (data.data.data.length > 0) {
                      var RData = data.data.data
                      var RDataobj = updateRowObj(RData, this.state.formiptobj.ID)
                      let attr = this.state.attr;
                      let arr = toTreeDataA(data.data.data, attr);
                      ////console.log(arr)
                      this.setState({
                        treeDataC: arr,
                        visibledraw: false,
                        selectedKeysCObj: RDataobj
                      }, function () {
                        message.success('保存成功!')
                      })
                    } else {
                      this.setState({
                        treeDataC: []
                      })
                    }
                  }
                })
              } else if (currenttabs == 3) {
                axios.get('/DATA/Document/Document.aspx?flag=7').then(data => {
                  var arr = conversion(data)
                  var RDataobj = updateRowObj(arr, this.state.formiptobj.ID)
                  let attr = this.state.attr;
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  ////console.log(arr)
                  this.setState({
                    treeDataD2: newarr,
                    visibledraw: false,
                    selectedKeysDObj: RDataobj
                  })
                })
                axios.get('/DATA/Document/Document.aspx?flag=8').then(data => {
                  var arr = conversion(data)
                  var RDataobj = updateRowObj(arr, this.state.formiptobj.ID)
                  let attr = this.state.attr;
                  let obj = toTreeDataB(arr, attr);
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  ////console.log(arr)
                  this.setState({
                    treeDataD1: newarr,
                    visibledraw: false,
                    selectedKeysDObj: RDataobj
                  }, function () {
                    message.success('保存成功！')
                  })
                })
              }
            } else if (typeof data.data == "string" && data.data.indexOf('编码冲突') > -1) {
              message.error('编码冲突，请检查!')
            } else if (typeof data.data == "string" && data.data.indexOf('数据版本已发生变化') > -1) {
              message.error('数据版本已发生变化,请刷新后重试!')
            }
          })
        } else if (this.state.addeditflag == "重命名") {
          values.ID = this.state.rowId
          values.ParentType = this.state.rowObj.ParentType
          values.RecordVersion = this.state.rowObj.RecordVersion
          axios.post('/DATA/Document/Document.aspx?flag=11', values).then(data => {
            if (data.data.success) {
              var ParentId = currenttabs == 0 ? this.state.selectedKeysA[0] : currenttabs == 1 ? this.state.selectedKeysB[0] :
                currenttabs == 2 ? this.state.selectedKeysC[0] : this.state.selectedKeysD[0]
              axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
                ParentId: ParentId
              }).then(data => {
                ////console.log(conversion(data))
                this.setState({
                  data: conversion(data),
                  visibledraw: false,
                  rowObj: updateRowObj(conversion(data), this.state.rowId)
                }, function () {
                  message.success('修改成功!')
                })
              })
            } else if (typeof data.data == "string" && data.data.indexOf('编码冲突') > -1) {
              message.error('编码冲突，请检查!')
            } else if (typeof data.data == "string" && data.data.indexOf('数据版本已发生变化') > -1) {
              message.error('数据版本已发生变化,请刷新后重试!')
            }
          })
        }
      }
    });
  };
  onClose() {
    this.setState({
      visibledraw: false,
      counflag: false,
      uploading: false,
      fileList: [],
      formiptobj: {},
      showtooltip: false
    }, function () {
      this.props.form.resetFields();
    })
  }
  //上传文件
  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files[]', file);
    });
    this.setState({
      uploading: true,
      progressper: true
    });
    var currenttabs = this.state.currenttabs
    var ParentId = currenttabs == 0 ? this.state.selectedKeysA[0] : this.state.selectedKeysC[0]
    var ParentType = currenttabs == 0 ? this.state.selectedKeysAObj.ParentType : this.state.selectedKeysCObj.ParentType
    var Path = currenttabs == 0 ? this.state.selectedKeysAObj.treepath : this.state.selectedKeysCObj.treepath
    if ((currenttabs == 0 && this.state.selectedKeysA[0]) || (currenttabs == 2 && this.state.selectedKeysC[0])) {
      reqwest({
        url: `/DATA/Document/Document.aspx?flag=10&ParentId=${ParentId}&ParentType=${ParentType}&Path=${Path}`,
        method: 'post',
        processData: false,
        data: formData,
        success: (text) => {
          //console.log('文件上传打印结果', text, typeof text)
          if (text.indexOf('success') > -1 || text.indexOf(true) > -1) {
            var ParentId = currenttabs == 0 ? this.state.selectedKeysA[0] : currenttabs == 1 ? this.state.selectedKeysB[0] :
              currenttabs == 2 ? this.state.selectedKeysC[0] : this.state.selectedKeysD[0]
            axios.post('/DATA/Document/Document.aspx?flag=20', {
              ParentId: ParentId
            }).then(data => {
              ////console.log(conversion(data))
              this.setState({
                data: conversion(data),
                visibledraw: false,
                uploading: false,
                progressper: false,
                fileList: [],
                doclistidsele: [],
                doclistsele: [],
                RecordVersionsele: [],
              }, function () {
                message.success('上传成功!')
              })
            })
          } else if (typeof text == "string") {
            message.error(text)
            this.setState({
              uploading: false,
              progressper: false,
              showtooltip: true
            });
          }
        },
        error: () => {
          this.setState({
            uploading: false,
            progressper: false,
            fileList: []
          });
          message.error('上传失败！');
        },
      });
    } else {
      message.error('请选择!')
    }
  };
  //更新文件
  handleUploadA = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files[]', file);
    });
    this.setState({
      uploading: true,
      progressper: true
    });
    var fileobj = this.state.rowObj
    var currenttabs = this.state.currenttabs
    var Path = currenttabs == 0 ? this.state.selectedKeysAObj.treepath : this.state.selectedKeysCObj.treepath
    reqwest({
      url: `/DATA/Document/Document.aspx?flag=12&ID=${fileobj.ID}&GroupId=${fileobj.GroupId}&Code=${fileobj.Code}&ParentId=${fileobj.ParentId}&ParentType=${fileobj.ParentType}&Path=${Path}&VersionNum=${fileobj.VersionNum}`,
      method: 'post',
      processData: false,
      data: formData,
      success: (text) => {
        ////console.log('文件上传打印结果',text,typeof text)
        // if(text.indexOf('文件命名不符合规定')>-1){
        //   message.error('该文件命名不符合规定，请检查！')
        //   this.setState({
        //     uploading: false,
        //     progressper:false
        //   });
        // }else if(text.indexOf('文件已存在')>-1){
        //   message.error('该文件已存在，请进行版本更新！')
        //   this.setState({
        //     uploading: false,
        //     progressper:false
        //   });
        // }else if(text.indexOf('文件编码不一致')>-1){
        //   message.error('文件编码不一致无法更新版本，请检查！')
        //   this.setState({
        //     uploading: false,
        //     progressper:false
        //   });
        // }
        if (text.indexOf('success') > -1 || text.indexOf(true) > -1) {
          var currenttabs = this.state.currenttabs
          axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
            ParentId: fileobj.ParentId
          }).then(data => {
            ////console.log(conversion(data))
            this.setState({
              data: conversion(data),
              visibledraw: false,
              uploading: false,
              progressper: false,
              fileList: [],
              doclistidsele: [],
              doclistsele: [],
              RecordVersionsele: [],
              rowId: "",
              rowObj: {},
              type: "",
              file: ""
            }, function () {
              message.success('更新成功!')
            })
          })
        } else if (typeof text == "string") {
          message.error(text)
          this.setState({
            uploading: false,
            progressper: false,
            showtooltip: true
          });
        }
      },
      error: () => {
        this.setState({
          uploading: false,
          progressper: false,
          fileList: []
        });
        message.error('上传失败！');
      },
    });
  };
  //上传文件
  toUploaddoc() {
    var currenttabs = this.state.currenttabs
    if ((currenttabs == 0 && this.state.selectedKeysA[0]) ||
      (currenttabs == 1 && this.state.selectedKeysB[0]) ||
      (currenttabs == 2 && this.state.selectedKeysC[0]) ||
      (currenttabs == 3 && this.state.selectedKeysD[0])) {
      this.setState({
        addeditflag: "上传文件",
        visibledraw: true,
        fileList: []
      }, function () {
        $('.docuploadtooltip').hover(function () {
          $('.docuploadtooltipcon').css("display", "block")
        }, function () {
          setTimeout(function () {
            $('.docuploadtooltipcon').css("display", "none")
          }, 1000)
        })
      })
    } else {
      message.error('请选择！')
    }
  }

  // senddata(record) {
  //   // //console.log(record)
  //   var arr = this.state.doclistidsele
  //   var arr1 = this.state.RecordVersionsele
  //   var arr2 = this.state.doclistsele
  //   if (arr.indexOf(record.ID) > -1) {
  //     if (this.state.rowId == record.ID) {
  //       var index;
  //       for (let i = 0; i < arr.length; i++) {
  //         if (arr[i] == record.ID) {
  //           index = i
  //         }
  //       }
  //       if (index > -1) {
  //         arr.splice(index, 1)
  //         arr1.splice(index, 1)
  //         arr2.splice(index, 1)
  //       }
  //     }
  //   } else {
  //     arr.push(record.ID)
  //     arr1.push(record.RecordVersion)
  //     arr2.push(record)
  //   }
  //   this.setState({
  //     rowId: record.ID,
  //     rowObj: record,
  //     doclistidsele: arr,
  //     RecordVersionsele: arr1,
  //     doclistsele: arr2
  //   }, function () {
  //     if (record.Type != "Folder") {
  //       //获取文档版本
  //       axios.post('/DATA/Document/Document.aspx?flag=21', {
  //         ID: this.state.rowId,
  //         GroupId: this.state.rowObj.GroupId
  //       }).then(data => {
  //         ////console.log(data)
  //         this.setState({
  //           datadocver: conversion(data),
  //         })
  //       })
  //       //获取文档属性关键字
  //       axios.post('/DATA/Document/Document.aspx?flag=37', {
  //         ParentId: this.state.rowId
  //       }).then(data => {
  //         //console.log(data)
  //         if (data.data.success) {
  //           this.setState({
  //             ExtractedText: data.data.data[0] ? data.data.data[0].ExtractedText : "",
  //           })
  //         }
  //       })
  //       //获取文档备注
  //       axios.post('/DATA/Document/Document.aspx?flag=34', {
  //         ID: this.state.rowId
  //       }).then(data => {
  //         //console.log(data)
  //         if (data.data.success) {
  //           this.setState({
  //             textcondata: data.data.data,
  //           })
  //         }
  //       })
  //     }
  //   })
  // }
  senddata(record) {
    console.log(record)
    var arr = []
    var arr1 = []
    var arr2 = []
    arr.push(record.ID)
    arr1.push(record.RecordVersion)
    arr2.push(record)
    this.setState({
      rowId: record.ID,
      rowObj: record,
      doclistidsele: arr,
      RecordVersionsele: arr1,
      doclistsele: arr2
    }, function () {
      if (record.Type != "Folder") {
        //获取文档版本
        axios.post('/DATA/Document/Document.aspx?flag=21', {
          ID: this.state.rowId,
          GroupId: this.state.rowObj.GroupId
        }).then(data => {
          ////console.log(data)
          this.setState({
            datadocver: conversion(data),
          })
        })
        //获取文档属性关键字
        axios.post('/DATA/Document/Document.aspx?flag=37', {
          ParentId: this.state.rowId
        }).then(data => {
          //console.log(data)
          if (data.data.success) {
            this.setState({
              ExtractedText: data.data.data[0] ? data.data.data[0].ExtractedText : "",
            })
          }
        })
        //获取文档备注
        axios.post('/DATA/Document/Document.aspx?flag=34', {
          ID: this.state.rowId
        }).then(data => {
          //console.log(data)
          if (data.data.success) {
            this.setState({
              textcondata: data.data.data,
            })
          }
        })
      }
    })
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }
  //下载文件
  handleDown() {
    if (this.state.rowObj.ID) {
      if (this.state.rowObj.Type == "Folder") {
        message.error('文件夹不能下载！')
      }
      else {
        this.openPostWindow('Data/Document/DownLoad.aspx', this.state.rowObj.Path, "downfile", this.state.rowObj.Code + ' ' + this.state.rowObj.Name + this.state.rowObj.MimeType)
      }
    } else {
      message.error('请选择！')
    }
    // if (this.state.doclistidsele.length > 0) {
    //   var arr = this.state.doclistsele
    //   //console.log(arr)
    //   var Pathstr = ""
    //   var Zuhestr = ""
    //   for (let i = 0; i < arr.length; i++) {
    //     //console.log(arr[i])
    //     if (arr[i].Type == "Folder") { }
    //     else {
    //       // this.download(arr[i].Name , `http://localhost:6300/`+arr[i].Path)
    //       // if(i<arr.length-1){
    //       //   Pathstr = Pathstr + arr[i].Path + ','
    //       //   Zuhestr = Zuhestr + arr[i].Code + ' ' + arr[i].Name + arr[i].MimeType +','
    //       // }
    //       // if(i==arr.length-1){
    //       //   Pathstr = Pathstr + arr[i].Path
    //       //   Zuhestr = Zuhestr + arr[i].Code + ' ' + arr[i].Name + arr[i].MimeType
    //       //   //console.log(Pathstr,Zuhestr)
    //       //   this.openPostWindow('Data/Document/DownLoad.aspx', Pathstr, "downfile", Zuhestr)
    //       // }
    //       this.openPostWindow('Data/Document/DownLoad.aspx', arr[i].Path, "downfile", arr[i].Code + ' ' + arr[i].Name + arr[i].MimeType)
    //     }
    //   }
    // } else {
    //   message.error('请选择！')
    // }
  }

  download(name, href) {
    var a = document.createElement("a"), //创建a标签
      e = document.createEvent("MouseEvents"); //创建鼠标事件对象
    e.initEvent("click", false, false); //初始化事件对象
    a.href = href; //设置下载地址
    a.download = name; //设置下载文件名
    a.dispatchEvent(e); //给指定的元素，执行事件click事件
  }

  openPostWindow(url, data, name, data2) {
    ////console.log(url, data, name)
    var tempForm = document.createElement("form");
    tempForm.id = "tempForm";
    tempForm.method = "post";
    tempForm.action = url;
    tempForm.target = name;

    var hideInput = document.createElement("input");
    hideInput.type = "hidden";
    hideInput.name = "content"
    hideInput.value = data;

    var hideInput2 = document.createElement("input");
    hideInput2.type = "hidden";
    hideInput2.name = "xtmc";
    hideInput2.value = data2;

    tempForm.appendChild(hideInput2);

    tempForm.appendChild(hideInput);

    document.body.appendChild(tempForm);

    tempForm.submit();
    document.body.removeChild(tempForm);
  }
  //重命名
  toUpdateName() {
    if (this.state.rowId) {
      this.setState({
        addeditflag: "重命名",
        visibledraw: true,
        formiptobj: this.state.rowObj
      }, function () {
        this.props.form.resetFields()
      })
    } else {
      message.error('请选择文件!')
    }
  }
  //文档上锁
  toLockDoc() {
    if (this.state.rowId) {
      var currenttabs = this.state.currenttabs
      var unumber = sessionStorage.getItem('unumber')
      if (this.state.rowObj.Locked == "1") {
        if (this.state.rowObj.LockedPeopleId == "admin" || this.state.rowObj.LockedPeopleId == unumber) {
          axios.post('/DATA/Document/Document.aspx?flag=14', {
            ID: this.state.rowId,
            RecordVersion: this.state.rowObj.RecordVersion,
            Locked: "0"
          }).then(data => {
            ////console.log(data)
            if (data.data.success) {
              axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
                ParentId: this.state.rowObj.ParentId
              }).then(data => {
                ////console.log(conversion(data))
                var arr = conversion(data)
                var obj = {}
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].ID == this.state.rowId) {
                    obj = arr[i]
                  }
                }
                this.setState({
                  data: conversion(data),
                  rowObj: obj
                }, function () {
                  message.success('操作成功!')
                })
              })
            }
          })
        } else {
          message.error('没有操作的权限!')
        }
      } else {
        axios.post('/DATA/Document/Document.aspx?flag=14', {
          ID: this.state.rowId,
          RecordVersion: this.state.rowObj.RecordVersion,
          Locked: "1"
        }).then(data => {
          ////console.log(data)
          if (data.data.success) {
            axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
              ParentId: this.state.rowObj.ParentId
            }).then(data => {
              ////console.log(conversion(data))
              var arr = conversion(data)
              var obj = {}
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].ID == this.state.rowId) {
                  obj = arr[i]
                }
              }
              this.setState({
                data: conversion(data),
                rowObj: obj
              }, function () {
                message.success('操作成功!')
              })
            })
          }
        })
      }
    } else {
      message.error('请选择！')
    }
  }

  //更新文件
  toUpdateDoc() {
    if (this.state.rowId) {
      this.setState({
        addeditflag: "更新文件",
        visibledraw: true
      }, function () {
        this.props.form.resetFields()
        $('.docuploadtooltip').hover(function () {
          $('.docuploadtooltipcon').css("display", "block")
        }, function () {
          setTimeout(function () {
            $('.docuploadtooltipcon').css("display", "none")
          }, 1000)
        })
      })
    } else {
      message.error('请选择行!')
    }
  }
  //编辑文件 进入检出状态
  editdoc() {
    if (this.state.rowId) {
      var currenttabs = this.state.currenttabs
      var unumber = sessionStorage.getItem('unumber')
      if (this.state.rowObj.CheckOut == "1") {
        if (this.state.rowObj.CheckOutPeopleId == "admin" || this.state.rowObj.CheckOutPeopleId == unumber) {
          axios.post('/DATA/Document/Document.aspx?flag=13', {
            ID: this.state.rowId,
            RecordVersion: this.state.rowObj.RecordVersion,
            CheckOut: "0"
          }).then(data => {
            ////console.log(data)
            if (data.data.success) {
              axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
                ParentId: this.state.rowObj.ParentId
              }).then(data => {
                ////console.log(conversion(data))
                var arr = conversion(data)
                var obj = {}
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].ID == this.state.rowId) {
                    obj = arr[i]
                  }
                }
                this.setState({
                  data: conversion(data),
                  rowObj: obj
                }, function () {
                  message.success('操作成功!')
                })
              })
            }
          })
        } else {
          message.error('没有操作的权限!')
        }
      } else {
        this.openPostWindow('Data/Document/DownLoad.aspx', this.state.rowObj.Path, "downfile", this.state.rowObj.Code + ' ' + this.state.rowObj.Name + this.state.rowObj.MimeType)
        axios.post('/DATA/Document/Document.aspx?flag=13', {
          ID: this.state.rowId,
          RecordVersion: this.state.rowObj.RecordVersion,
          CheckOut: "1"
        }).then(data => {
          ////console.log(data)
          if (data.data.success) {
            axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
              ParentId: this.state.rowObj.ParentId
            }).then(data => {
              ////console.log(conversion(data))
              var arr = conversion(data)
              var obj = {}
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].ID == this.state.rowId) {
                  obj = arr[i]
                }
              }
              this.setState({
                data: conversion(data),
                rowObj: obj
              }, function () {
                message.success('操作成功!')
              })
            })
          }
        })
      }
    } else {
      message.error('请选择！')
    }
  }
  //文档收藏
  toCollectDoc() {
    if (this.state.doclistidsele.length > 0) {
      axios.get('/DATA/Document/Document.aspx?flag=6').then(data => {
        var arr = conversion(data)
        let attr = this.state.attr;
        let obj = toTreeDataB(arr, attr);
        // //console.log(obj)
        var arr = obj.tree
        var resarr = obj.resData
        var newarr = arr.concat(resarr)
        ////console.log(arr)
        this.setState({
          treeDataBB: newarr,
          visible: true,
          collectmoveflag: "收藏",
          selectedKeysBB: [],
          selectedKeysBBObj: {}
        })
      })
    } else {
      message.error('请选择文件!')
    }
  }
  //取消文档收藏
  tocancelFavorite() {
    var arr = this.state.doclistidsele
    var _this = this
    if (arr.length == 0) {
      message.error('请复选至少一条数据！')
    } else {
      confirm({
        title: '确定要取消收藏吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/Document/Document.aspx?flag=19', {
            ID: arr.length > 1 ? arr.join(',') : arr[0],
            FavId: _this.state.selectedKeysB[0]
          }).then(data => {
            //console.log(data)
            if (data.data.success) {
              axios.post('/DATA/Document/Document.aspx?flag=23', {
                ParentId: _this.state.selectedKeysB[0]
              }).then(data => {
                if (data.data.success != false) {
                  _this.setState({
                    data: conversion(data),
                    rowObj: {},
                    rowId: "",
                    doclistidsele: [],
                    RecordVersionsele: [],
                    doclistsele: [],
                  }, function () {
                    message.success('操作成功！')
                  })
                }
              })
            }
          })
        },
        onCancel() {
        },
      });
    }
  }
  //文档
  handleOk = e => {
    ////console.log(e);
    if (this.state.selectedKeysBB[0]) {
      if (this.state.collectmoveflag == "收藏") {
        var arr = this.state.doclistsele
        var Codearr = []
        var Typearr = []
        for (let i = 0; i < arr.length; i++) {
          Codearr.push(arr[i].Code)
          Typearr.push(arr[i].Type)
        }
        //console.log(arr, this.state.doclistidsele.join(','), this.state.RecordVersionsele.join(','))
        axios.post('/DATA/Document/Document.aspx?flag=15', {
          ID: this.state.doclistidsele.join(','),
          FavId: this.state.selectedKeysBB[0],
          RecordVersion: this.state.RecordVersionsele.join(','),
          Code: Codearr.join(','),
          Type: Typearr.join(',')
        }).then(data => {
          //console.log(data)
          if (data.data.success) {
            this.setState({
              visible: false,
              doclistidsele: []
            })
            message.success('收藏成功!')
          }
          else if (data.data && data.data.indexOf('不需要重复收藏') > -1) {
            message.error(data.data)
          }
        })
      } else if (this.state.collectmoveflag == "移动") {
        axios.post('/DATA/Document/Document.aspx?flag=18', {
          ID: this.state.rowId,
          GroupId: this.state.rowObj.GroupId,
          RecordVersion: this.state.rowObj.RecordVersion,
          Type: this.state.rowObj.Type,
          ParentId: this.state.selectedKeysBB[0]
        }).then(data => {
          ////console.log(data)
          if (data.data.success) {
            var currenttabs = this.state.currenttabs
            var flag = currenttabs == 0 ? 4 :
              currenttabs == 1 ? 6 :
                currenttabs == 2 ? 5 : 7
            axios.get(`/DATA/Document/Document.aspx?flag=${flag}`).then(data => {
              if (data.data.success) {
                if (data.data.data) {
                  let attr = this.state.attr;
                  let arr = toTreeDataA(data.data.data, attr);
                  ////console.log(arr)
                  if (currenttabs == 0) {
                    this.setState({
                      treeDataA: arr
                    })
                  } else if (currenttabs == 2) {
                    this.setState({
                      treeDataC: arr
                    })
                  }
                }
              }
            })

            axios.post('/DATA/Document/Document.aspx?flag=20', {
              ParentId: this.state.rowObj.ParentId
            }).then(data => {
              ////console.log(conversion(data))
              this.setState({
                data: conversion(data),
                rowObj: {},
                rowId: "",
              })
            })

            this.setState({
              visible: false
            })
            message.success('移动成功!')
          }
        })
      } else if (this.state.collectmoveflag == "恢复") {
        var arr = this.state.doclistsele
        var doclistidsele = []
        var RecordVersionsele = []
        var ParentTypesele = []
        var Codesele = []
        for (let i = 0; i < arr.length; i++) {
          doclistidsele.push(arr[i].ID)
          RecordVersionsele.push(arr[i].RecordVersion)
          ParentTypesele.push(arr[i].ParentType)
          Codesele.push(arr[i].Code)
        }
        var _this = this
        axios.post('/DATA/Document/Document.aspx?flag=30', {
          ID: doclistidsele.join(','),
          RecordVersion: RecordVersionsele.join(','),
          ParentType: ParentTypesele.join(','),
          Code: Codesele.join(','),
          ParentId: this.state.selectedKeysBB[0]
        }).then(data => {
          if (data.data.success) {

            var flag = _this.state.selectedKeysD[0].indexOf('公共文档') > -1 ? 24 :
              _this.state.selectedKeysD[0].indexOf('个人文档') > -1 ? 25 : 22

            axios.post(`/DATA/Document/Document.aspx?flag=${flag}`, {
              ParentId: _this.state.selectedKeysD[0]
            }).then(data => {
              ////console.log(conversion(data))
              _this.setState({
                visible: false,
                data: conversion(data),
                rowObj: {},
                rowId: "",
                doclistidsele: [],
                RecordVersionsele: [],
                doclistsele: [],
              })
            })
            axios.get('/DATA/Document/Document.aspx?flag=7').then(data => {
              var arr = conversion(data)
              let attr = _this.state.attr;
              let obj = toTreeDataB(arr, attr);
              // //console.log(obj)
              var arr = obj.tree
              var resarr = obj.resData
              var newarr = arr.concat(resarr)
              ////console.log(arr)
              _this.setState({
                treeDataD2: newarr
              })
            })
            axios.get('/DATA/Document/Document.aspx?flag=8').then(data => {
              var arr = conversion(data)
              let attr = _this.state.attr;
              let obj = toTreeDataB(arr, attr);
              // //console.log(obj)
              var arr = obj.tree
              var resarr = obj.resData
              var newarr = arr.concat(resarr)
              ////console.log(arr)
              _this.setState({
                treeDataD1: newarr,
              })
            })
            message.success('操作成功！')
          } else if (typeof data.data == "string" && data.data.indexOf('数据版本已发生变化') > -1) {
            message.error(data.data)
          }
        })
      }
    } else {
      message.error('请选择节点!')
    }
  }
  //文档收藏取消
  handleCancel = e => {
    ////console.log(e);
    this.setState({
      visible: false,
      collectmoveflag: ""
    });
  }
  //文档移动
  toRemoveDoc() {
    if (this.state.rowId) {
      var currenttabs = this.state.currenttabs
      var flag = currenttabs == 0 ? 4 :
        currenttabs == 1 ? 6 :
          currenttabs == 2 ? 5 : 7
      axios.get(`/DATA/Document/Document.aspx?flag=${flag}`).then(data => {
        if (data.data.success) {
          if (data.data.data) {
            let attr = this.state.attr;
            let arr = toTreeDataA(data.data.data, attr);
            ////console.log(arr)
            this.setState({
              treeDataBB: arr,
              visible: true,
              collectmoveflag: "移动",
              selectedKeysBB: [],
              selectedKeysBBObj: {}
            })
          }
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  //文档管理报表
  handleimport() {
    // if(this.state.selectedKeysA[0]){
    //   var company = sessionStorage.getItem("factory")
    //   var tid = this.state.selectedKeysA[0]
    //   var folder = this.state.treepath
    //   window.open(`http://47.97.112.136:8080/WebReport/ReportServer?reportlet=B-SPPs/DocumentsList.cpt&company=${company}&tid=${tid}&folder=${folder}`)
    // }else{
    //   message.error('请选择！')
    // }
    var arrcolumns = this.columns;
    var newarr = []
    for (let i = 0; i < arrcolumns.length; i++) {
      if (arrcolumns[i].title == "操作") {

      } else {
        var obj = { k: arrcolumns[i].dataIndex, v: arrcolumns[i].title }
        newarr.push(obj)
      }
    }
    var currenttabs = this.state.currenttabs
    var selecode = currenttabs == 0 ? this.state.selectedKeysAObj.Code
      : currenttabs == 1 ? this.state.selectedKeysBObj.Code
        : currenttabs == 2 ? this.state.selectedKeysCObj.Code
          : this.state.selectedKeysDObj.Code

    var fileName = selecode + "文档列表.xlsx";

    if (this.state.data.length > 0) {
      exportExcel(newarr, this.state.data, fileName)
    } else if (this.state.data.length == 0 && (this.state.selectedKeysA[0] || this.state.selectedKeysB[0] ||
      this.state.selectedKeysC[0] || this.state.selectedKeysD[0])) {
      message.error('暂无数据导出！')
    } else {
      message.error('请先选择！')
    }
  }
  //刷新文档列表
  toUpdateDocList() {
    var currenttabs = this.state.currenttabs
    var ParentId = currenttabs == 0 ? this.state.selectedKeysA[0] :
      currenttabs == 1 ? this.state.selectedKeysB[0] :
        currenttabs == 2 ? this.state.selectedKeysC[0] : this.state.selectedKeysD[0]
    if (ParentId) {
      axios.post(`/DATA/Document/Document.aspx?flag=${currenttabs == 1 ? 23 : currenttabs == 3 ? 22 : 20}`, {
        ParentId: ParentId
      }).then(data => {
        ////console.log(conversion(data))
        this.setState({
          data: conversion(data),
          doclistsele: [],
          doclistidsele: [],
          RecordVersionsele: [],
          rowObj: {},
          rowId: ""
        }, function () {
          message.success('刷新成功!')
        })
      })
    } else {
      message.error('请选择！')
    }
  }

  //回收站操作
  //恢复文件
  toresetRecyle() {
    if (this.state.selectedKeysDObj.ParentType == "Public" || this.state.selectedKeysD[0] == "公共文档") {
      var arr = this.state.treeDataA
      if (this.state.doclistidsele.length > 0) {
        this.setState({
          treeDataBB: arr,
          visible: true,
          collectmoveflag: "恢复"
        })
      } else {
        message.error('请选择!')
      }
    } else {
      var arr = this.state.treeDataC
      if (this.state.doclistidsele.length > 0) {
        this.setState({
          treeDataBB: arr,
          visible: true,
          collectmoveflag: "恢复"
        })
      } else {
        message.error('请选择!')
      }
    }
  }
  //删除文件
  todeleteRecyle() {
    if (this.state.doclistidsele.length > 0) {
      var arr = this.state.doclistsele
      if (arr.length == 0) {
        message.error('请重新选择文件！')
        this.setState({
          doclistidsele: [],
          doclistsele: [],
          RecordVersionsele: []
        })
      } else {
        var doclistidsele = []
        var RecordVersionsele = []
        var ParentTypesele = []
        var Codesele = []
        var Typesele = []
        for (let i = 0; i < arr.length; i++) {
          doclistidsele.push(arr[i].ID)
          RecordVersionsele.push(arr[i].RecordVersion)
          ParentTypesele.push(arr[i].ParentType)
          Codesele.push(arr[i].Code)
          Typesele.push(arr[i].Type)
        }
        var _this = this
        console.log(Typesele)
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/Document/Document.aspx?flag=31', {
              ID: doclistidsele.join(','),
              RecordVersion: RecordVersionsele.join(','),
              ParentType: ParentTypesele.join(','),
              Code: Codesele.join(','),
              Type: Typesele.join(',')
            }).then(data => {
              if (data.data.success) {
                var flag = _this.state.selectedKeysD[0].indexOf('公共文档') > -1 ? 24 :
                  _this.state.selectedKeysD[0].indexOf('个人文档') > -1 ? 25 : 22
                axios.post(`/DATA/Document/Document.aspx?flag=${flag}`, {
                  ParentId: _this.state.selectedKeysD[0]
                }).then(data => {
                  ////console.log(conversion(data))
                  _this.setState({
                    data: conversion(data),
                    rowObj: {},
                    rowId: "",
                    doclistidsele: [],
                    RecordVersionsele: [],
                    doclistsele: [],
                  })
                })

                axios.get('/DATA/Document/Document.aspx?flag=7').then(data => {
                  var arr = conversion(data)
                  let attr = _this.state.attr;
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  ////console.log(arr)
                  _this.setState({
                    treeDataD2: newarr
                  })
                })
                axios.get('/DATA/Document/Document.aspx?flag=8').then(data => {
                  var arr = conversion(data)
                  let attr = _this.state.attr;
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  ////console.log(arr)
                  _this.setState({
                    treeDataD1: newarr,
                  })
                })
                message.success('操作成功！')
              } else if (typeof data.data == "string" && data.data.indexOf('数据版本已发生变化') > -1) {
                message.error(data.data)
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      }
    } else {
      message.error('请选择文件!')
    }
  }
  //清空文件
  toclearRecyle() {
    if (this.state.selectedKeysD[0]) {
      var _this = this
      confirm({
        title: '确定要清空吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/Document/Document.aspx?flag=32', {
            // ParentType: _this.state.selectedKeysD[0] == "公共文档" ? "Public" : _this.state.selectedKeysD[0] == "个人文档"?"Person":_this.state.selectedKeysDObj.ParentType,
            ID: _this.state.selectedKeysD[0],
            RecordVersion: _this.state.selectedKeysD[0] == "公共文档" || _this.state.selectedKeysD[0] == "个人文档" ? "" : _this.state.selectedKeysDObj.RecordVersion
          }).then(res => {
            //console.log(res)
            if (res.data.success) {
              if (_this.state.selectedKeysDObj.ParentType == "Public" || _this.state.selectedKeysD[0] == "公共文档") {
                axios.get('/DATA/Document/Document.aspx?flag=7').then(data => {
                  var arr = conversion(data)
                  let attr = _this.state.attr;
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  _this.setState({
                    treeDataD2: newarr,
                  })
                })
              } else if (_this.state.selectedKeysDObj.ParentType == "Person" || _this.state.selectedKeysD[0] == "个人文档") {
                axios.get('/DATA/Document/Document.aspx?flag=8').then(data => {
                  var arr = conversion(data)
                  let attr = _this.state.attr;
                  let obj = toTreeDataB(arr, attr);
                  // //console.log(obj)
                  var arr = obj.tree
                  var resarr = obj.resData
                  var newarr = arr.concat(resarr)
                  _this.setState({
                    treeDataD1: newarr,
                  })
                })
              }
              _this.setState({
                data: [],
                rowObj: {},
                rowId: "",
                doclistidsele: [],
                RecordVersionsele: [],
                doclistsele: [],
              })
              // axios.post(`/DATA/Document/Document.aspx?flag=22`, {
              //   ParentId: _this.state.selectedKeysD[0]
              // }).then(data => {
              //   ////console.log(conversion(data))
              //   _this.setState({
              //     data: conversion(data),
              //     rowObj: {},
              //     rowId: "",
              //     doclistidsele: [],
              //     RecordVersionsele: [],
              //     doclistsele: [],
              //   })
              // })
              message.success('操作成功！')
            }
          })
        },
        onCancel() {
          _this.setState({
            // detaillistId: ''
          })
        },
      });
    } else {
      message.error('请选择！')
    }
    // if (this.state.doclistidsele.length > 0) {
    //   var arr = this.state.doclistsele
    //   if (arr.length == 0) {
    //     message.error('请重新选择文件！')
    //     this.setState({
    //       doclistidsele: [],
    //       doclistsele: [],
    //       RecordVersionsele: []
    //     })
    //   } else {
    //     var doclistidsele = []
    //     var RecordVersionsele = []
    //     var ParentTypesele = []
    //     var Codesele = []
    //     for (let i = 0; i < arr.length; i++) {
    //       doclistidsele.push(arr[i].ID)
    //       RecordVersionsele.push(arr[i].RecordVersion)
    //       ParentTypesele.push(arr[i].ParentType)
    //       Codesele.push(arr[i].Code)
    //     }
    //     var _this = this
    //     confirm({
    //       title: '确定要清空吗?',
    //       okText: '确定',
    //       cancelText: '取消',
    //       onOk() {
    //         axios.post('/DATA/Document/Document.aspx?flag=32', {
    //           ID: doclistidsele.join(','),
    //           RecordVersion: RecordVersionsele.join(','),
    //           ParentType: ParentTypesele.join(','),
    //           Code: Codesele.join(',')
    //         }).then(data => {
    //           if (data.data.success) {
    //             var flag = _this.state.selectedKeysD[0].indexOf('公共文档') > -1 ? 24 :
    //             _this.state.selectedKeysD[0].indexOf('个人文档') > -1 ? 25 : 22
    //             axios.post(`/DATA/Document/Document.aspx?flag=${flag}`, {
    //               ParentId: _this.state.selectedKeysD[0]
    //             }).then(data => {
    //               ////console.log(conversion(data))
    //               _this.setState({
    //                 data: conversion(data),
    //                 rowObj: {},
    //                 rowId: "",
    //                 doclistidsele: [],
    //                 RecordVersionsele: [],
    //                 doclistsele: [],
    //               })
    //             })
    //             message.success('操作成功！')
    //           } else if (typeof data.data == "string" && data.data.indexOf('数据版本已发生变化') > -1) {
    //             message.error(data.data)
    //           }
    //         })
    //       },
    //       onCancel() {
    //         _this.setState({
    //           // detaillistId: ''
    //         })
    //       },
    //     });
    //   }
    // } else {
    //   message.error('请选择文件!')
    // }
  }
  //关键字
  onKeyWordsave() {
    axios.post('/DATA/Document/Document.aspx?flag=38', {
      ExtractedText: this.state.ExtractedText,
      ParentId: this.state.rowObj.ID
    }).then(res => {
      //console.log(res)
      if (res.data.success) {
        message.success('保存成功！')
      }
    })
  }
  //文件备注
  toaddtext() {
    axios.post('/DATA/Document/Document.aspx?flag=33', {
      Remark: this.state.textvalue,
      ParentId: this.state.rowObj.ID
    }).then(res => {
      //console.log(res)
      if (res.data.success) {
        axios.post('/DATA/Document/Document.aspx?flag=34', {
          ID: this.state.rowObj.ID
        }).then(data => {
          //console.log(data)
          if (data.data.success) {
            this.setState({
              textcondata: data.data.data,
              textvalue: ""
            })
          }
        })
        message.success('添加成功！')
      }
    })
  }
  toedittext(item) {
    this.setState({
      textaddedit: "编辑",
      textvalue: item.Remark,
      docId: item.ID
    })
  }
  //编辑保存
  toedittextsave() {
    axios.post('/DATA/Document/Document.aspx?flag=35', {
      Remark: this.state.textvalue,
      ID: this.state.docId
    }).then(res => {
      //console.log(res)
      if (res.data.success) {
        axios.post('/DATA/Document/Document.aspx?flag=34', {
          ID: this.state.rowObj.ID
        }).then(data => {
          //console.log(data)
          if (data.data.success) {
            this.setState({
              textcondata: data.data.data,
              textaddedit: "新增",
              textvalue: ""
            })
          }
        })
        message.success('修改成功！')
      }
    })
  }
  //编辑取消保存
  tocanceledittextsave() {
    this.setState({
      textaddedit: "新增",
      textvalue: ""
    })
  }
  todeletetext(item) {
    var _this = this
    confirm({
      title: '确定要删除吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post('/DATA/Document/Document.aspx?flag=36', {
          ParentId: item.ID
        }).then(res => {
          //console.log(res)
          if (res.data.success) {
            var arr = _this.state.textcondata
            _this.setState({
              textcondata: arr.filter(function (ele) { return ele.ID != item.ID })
            })
            message.success('删除成功！')
          }
        })
      },
      onCancel() {

      },
    });
  }

  pagechange(page, pageSize) {
    //console.log(page,pageSize)
    this.setState({
      pageIndex: Number(page.current)
    })
  }

  pagechange1(page, pageSize) {
    this.setState({
      counflag: true
    }, function () {
      axios.post('/DATA/Document/Document.aspx?flag=41', {
        ParentType: this.state.searchrangeType,
        ID: this.state.searchrangeID,
        Code: this.state.searchcode,
        Name: this.state.searchname,
        ExtractedText: this.state.searchkey,
        UserNumber: this.state.searchrangeType == "Public" ? this.state.searchuser : sessionStorage.getItem('unumber'),
        UploadTime: this.state.searchdate[0] ? this.state.searchdate[0] : "",
        UpdateTime: this.state.searchdate[1] ? this.state.searchdate[1] : "",
        MimeType: this.state.searchtype,
        pageIndex: Number(page.current),
        pageSize: parseInt((store.getState().clientHeight - 50 - 30 - 270 - 50 - 30) / 28)
      }).then(res => {
        //console.log(res)
        this.setState({
          pageIndex1: Number(page.current),
          datadoc: conversion(res),
          counflag: false,
        })
      })
    })
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDrag(e, ui) {
    e.preventDefault();
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { uploading, fileList } = this.state;  //ES6解构赋值
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        ////console.log(file)
        // 手动上传显示缩略图
        // 利用FileReader的readAsDataURL 将File对象 转为base64 赋值给File对象的thumbUrl 属性,组件读到这个属性作为<img>中src的值从而显示缩略图
        // const r = new FileReader();
        // r.readAsDataURL(file);
        // r.onload = e => {
        //   file.thumbUrl = e.target.result;
        //   this.setState( state => ({
        //     fileList: [...state.fileList, file],
        //   }))
        // }
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      multiple: this.state.addeditflag == "上传文件" ? true : false,
      fileList,
    };
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    if (clientWidth < 1200) {
      $('.docmanageright .listactionl button').css('padding', "0 15px")
      $('.listactionr button').css('padding', "0 10px")
      $('.listactionr button').css('margin', "0 2px")
    } else {
      $('.docmanageright .listactionl button').css('padding', "0 30px")
      $('.listactionr button').css('padding', "0 15px")
      $('.listactionr button').css('margin', "0 4px")
    }
    const rowSelection = {
      selectedRowKeys: this.state.doclistidsele,
      onChange: (selectedRowKeys, selectedRows) => {
        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        var arr = selectedRows
        var newarr = []
        var newarr1 = []
        for (let i = 0; i < arr.length; i++) {
          newarr.push(arr[i].ID)
          newarr1.push(arr[i].RecordVersion)
        }
        this.setState({
          doclistidsele: newarr,
          RecordVersionsele: newarr1,
          doclistsele: arr
        })
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
        if (selected) {
          this.setState({
            rowId: record.ID,
            rowObj: record
          })
        } else {
          if (selectedRows.length == 1) {
            this.setState({
              rowId: selectedRows[0].ID,
              rowObj: selectedRows[0]
            })
          }
        }
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        //console.log(selected, selectedRows, changeRows);
      },
    };
    const dateFormat = 'YYYY/MM/DD';
    const loop = data =>
      data.map(item => {
        if (item.children) {
          return (
            <TreeNode key={item.ID} title={item.Code + ' ' + item.Name} ID={item.ID} RecordVersion={item.RecordVersion}
              Name={item.Name} Code={item.Code} ParentId={item.ParentId} ParentType={item.ParentType} Path={item.Path}
              treepath={item.treepath}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} title={item.Code + ' ' + item.Name} ID={item.ID} RecordVersion={item.RecordVersion}
          Name={item.Name} Code={item.Code} ParentId={item.ParentId} ParentType={item.ParentType} Path={item.Path}
          treepath={item.treepath} />;
      });

    return (
      <div className="docmanagecon" style={{ height: clientHeight - 50 + 'px' }}>
        <Modal
          title={this.state.collectmoveflag == "收藏" ? "收藏夹" :
            this.state.currenttabs == 0 ? "公共文档" : this.state.currenttabs == 2 ? "个人文档" :
              "选择恢复的目标文件夹"}
          className="favoritemodal"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="doctree">
            {
              this.state.currenttabs == 3 ?
                <DirectoryTree
                  onSelect={this.onSelectBB}
                  selectedKeys={this.state.selectedKeysBB}
                >
                  <TreeNode title={this.state.selectedKeysDObj.ParentType == "Public" || this.state.selectedKeysD[0] == "公共文档" ? "公共文档" : "个人文档"} key="0000"
                    ParentType={this.state.selectedKeysDObj.ParentType == "Public" || this.state.selectedKeysD[0] == "公共文档" ? "Public" : "Person"}>
                    {loop(this.state.treeDataBB)}
                  </TreeNode>
                </DirectoryTree> :
                <DirectoryTree
                  onSelect={this.onSelectBB}
                  selectedKeys={this.state.selectedKeysBB}
                >
                  {loop(this.state.treeDataBB)}
                </DirectoryTree>
            }
          </div>
        </Modal>

        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>

        <Drawer
          title={this.state.addeditflag}
          onClose={this.onClose.bind(this)}
          visible={this.state.visibledraw}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            {
              this.state.addeditflag == "新建文件夹" || this.state.addeditflag == "文档树编辑" ?
                <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="文件夹编码">
                        {getFieldDecorator('Code', {
                          rules: [{ required: false, message: '请输入文件夹编码' }],
                          initialValue: this.state.formiptobj.Code
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="文件夹名称">
                        {getFieldDecorator('Name', {
                          rules: [{ required: true, message: '请输入文件夹名称' }],
                          initialValue: this.state.formiptobj.Name
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
                : this.state.addeditflag == "重命名" ?
                  <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="文件编码">
                          {getFieldDecorator('Code', {
                            initialValue: this.state.formiptobj.Code,
                            rules: [{ required: true, message: '请输入文件夹名称' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="文件名称">
                          {getFieldDecorator('Name', {
                            initialValue: this.state.formiptobj.Name,
                            rules: [{ required: true, message: '请输入文件夹名称' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
                      </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                  : this.state.addeditflag == "上传文件" ?
                    <div className="uploaddocument" style={{ height: clientHeight - 50 - 55 - 40 + 'px' }}>
                      <Upload {...props} className="selectpicture">
                        <Button>
                          {/* <Icon type="upload" /> */}
                        选择文件
                      </Button>
                      </Upload>
                      <img className="docuploadtooltip" style={{ width: "26px", height: "26px", cursor: "pointer", display: this.state.showtooltip ? "block" : "none" }} src={require('../../assets/file/tishi.png')} alt="" />
                      <span className="docuploadtooltipcon" style={{ fontSize: "12px", color: "#Fff" }}>文件命名形式为：<br />编码 文件名  例如:<br />JS0101 汽轮机</span>
                      <Button
                        className="uploadpicture"
                        type="primary"
                        onClick={this.handleUpload}
                        disabled={fileList.length === 0}
                        loading={uploading}
                      >
                        {uploading ? '上传中' : '上传'}
                      </Button>
                      {/* <Progress percent={this.state.percent} style={{display:this.state.progressper?"block":"none"}}/> */}

                    </div>
                    : this.state.addeditflag == "更新文件" ?
                      <div className="uploaddocument" style={{ height: clientHeight - 50 - 55 - 40 + 'px' }}>
                        <Upload {...props} className="selectpicture">
                          <Button>
                            {/* <Icon type="upload" /> */}
                          选择文件
                      </Button>
                        </Upload>
                        <img className="docuploadtooltip" style={{ width: "26px", height: "26px", cursor: "pointer", display: this.state.showtooltip ? "block" : "none" }} src={require('../../assets/file/tishi.png')} alt="" />
                        <span className="docuploadtooltipcon" style={{ fontSize: "12px", color: "#Fff" }}>文件命名形式为：<br />编码 文件名  例如:<br />JS0101 汽轮机</span>
                        <Button
                          className="uploadpicture"
                          type="primary"
                          onClick={this.handleUploadA}
                          disabled={fileList.length === 0}
                          loading={uploading}
                        >
                          {uploading ? '上传中' : '上传'}
                        </Button>
                        {/* <Progress percent={this.state.percent}  style={{display:this.state.progressper?"block":"none"}}/> */}
                      </div>
                      : ""}
          </div>
        </Drawer>

        <Resizable className="box1" axis="x"
          minConstraints={[100, 100]} maxConstraints={[360, 360]}
          width={this.state.leftd}
          height={clientHeight - 90}
          onResize={(event, { element, size }) => {
            //console.log(size)
            this.setState({ leftd: size.width, centerd: clientWidth - size.width });
          }}>

          <div className="docmanageleft" style={{ width: this.state.leftd + 'px' }}>
            <div className="docmanagetitle">文档目录</div>
            <h2 className="listaction">
              {
                this.state.currenttabs == 3 ?
                  <div className="listactionl listactiondisable">
                    <Button style={{ display: this.state.buttonarr.indexOf('文件夹添加') > -1 ? "inline-block" : "none" }}>添加</Button>
                    <Button style={{ display: this.state.buttonarr.indexOf('文件夹编辑') > -1 ? "inline-block" : "none" }}>编辑</Button>
                    <Button style={{ display: this.state.buttonarr.indexOf('文件夹删除') > -1 ? "inline-block" : "none" }}>删除</Button>
                  </div> :
                  <div className="listactionl">
                    <Button style={{ display: this.state.buttonarr.indexOf('文件夹添加') > -1 ? "inline-block" : "none" }} onClick={this.adddoctree.bind(this)}>添加</Button>
                    <Button style={{ display: this.state.buttonarr.indexOf('文件夹编辑') > -1 ? "inline-block" : "none" }} onClick={this.editdoctree.bind(this)}>编辑</Button>
                    <Button style={{ display: this.state.buttonarr.indexOf('文件夹删除') > -1 ? "inline-block" : "none" }} onClick={this.deledoctree.bind(this, '树')}>删除</Button>
                  </div>
              }
            </h2>
            <div className="docmanagemodule">
              <div className="publicdoc">
                <h2 className="doctitle doctitle-active" onClick={this.showhidedoctree.bind(this, 0)}>
                  <img src={require('../../assets/file/公共文档.png')} alt="" />公共文档</h2>
                <div className="doctree doctree-active" style={{ height: clientHeight - 50 - 30 - 40 * 5 + 'px' }}>
                  <DirectoryTree
                    // checkable
                    defaultExpandedKeys={[]}
                    defaultSelectedKeys={[]}
                    selectedKeys={this.state.selectedKeysA}
                    // defaultCheckedKeys={['0-0-0', '0-0-1']}
                    onSelect={this.onSelectA}
                  // onCheck={this.onCheck}
                  >
                    {loop(this.state.treeDataA)}
                  </DirectoryTree>
                </div>
              </div>
              <div className="favoritedoc">
                <h2 className="doctitle" onClick={this.showhidedoctree.bind(this, 1)}>
                  <img src={require('../../assets/file/收藏夹.png')} alt="" />收藏夹</h2>
                <div className="doctree" style={{ height: clientHeight - 50 - 30 - 40 * 5 + 'px' }}>
                  <DirectoryTree
                    defaultExpandedKeys={[]}
                    defaultSelectedKeys={[]}
                    selectedKeys={this.state.selectedKeysB}
                    onSelect={this.onSelectB}
                  >
                    {loop(this.state.treeDataB)}
                  </DirectoryTree>
                </div>
              </div>
              <div className="personeldoc">
                <h2 className="doctitle" onClick={this.showhidedoctree.bind(this, 2)}>
                  <img src={require('../../assets/file/个人文档.png')} alt="" />个人文档</h2>
                <div className="doctree" style={{ height: clientHeight - 50 - 30 - 40 * 5 + 'px' }}>
                  <DirectoryTree
                    defaultExpandedKeys={[]}
                    defaultSelectedKeys={[]}
                    selectedKeys={this.state.selectedKeysC}
                    onSelect={this.onSelectC}
                  >
                    {loop(this.state.treeDataC)}
                  </DirectoryTree>
                </div>
              </div>
              <div className="recyclebindoc">
                <h2 className="doctitle" onClick={this.showhidedoctree.bind(this, 3)}>
                  <img src={require('../../assets/file/回收站.png')} alt="" />回收站</h2>
                <div className="doctree" style={{ height: clientHeight - 50 - 30 - 40 * 5 + 'px' }}>
                  <DirectoryTree
                    defaultExpandedKeys={[]}
                    defaultSelectedKeys={[]}
                    selectedKeys={this.state.selectedKeysD}
                    onSelect={this.onSelectD}
                  >
                    <TreeNode title={"个人文档"} key="个人文档" treepath="个人文档">
                      {loop(this.state.treeDataD1)}
                    </TreeNode>
                    <TreeNode title={"公共文档"} key="公共文档" treepath="公共文档">
                      {loop(this.state.treeDataD2)}
                    </TreeNode>
                  </DirectoryTree>
                </div>
              </div>
            </div>
          </div>
        </Resizable>

        <div className="docmanageright" style={{ width: clientWidth - this.state.leftd + 'px' }}>

          <div className="docmanagetitle">路径：<span>{this.state.treepath}</span></div>
          {/* 文档检索 */}
          <div className="docsearch" style={{ height: clientHeight - 50 + 'px', display: this.state.searchflag ? "block" : "none" }}>
            <h2>文档检索<img onClick={this.toclosesearch.bind(this)} src={require("../../assets/imgs/close.png")} alt="" /></h2>
            <div className="docsearchcondition">
              <table>
                <tbody>
                  <tr>
                    <td>范围</td>
                    <td>
                      <Input value={this.state.searchrange} /><span><img onClick={() => { $('.searchdoctree').css("display", "block") }} style={{ width: "14px", height: "14px", marginLeft: "6px" }} src={require('../../assets/file/wenjianjia.png')} alt="" /></span>
                    </td>
                  </tr>
                  <tr>
                    <td>编码</td>
                    <td><Input value={this.state.searchcode} onChange={(e) => { this.setState({ searchcode: e.target.value }) }} /></td>
                  </tr>
                  <tr>
                    <td>名称</td>
                    <td><Input value={this.state.searchname} onChange={(e) => { this.setState({ searchname: e.target.value }) }} /></td>
                  </tr>
                  <tr>
                    <td>关键字</td>
                    <td><Input value={this.state.searchkey} onChange={(e) => { this.setState({ searchkey: e.target.value }) }} /></td>
                  </tr>
                  <tr>
                    <td>用户</td>
                    <td>
                      {/* <Select showSearch labelInValue
                      disabled ={ this.state.searchrangeType=="Person"?true:false}
                        onSearch ={(v)=>{
                          var arr = this.state.userdatafu
                          console.log(v,arr)
                          var newarr = []
                          for(let i=0;i<arr.length;i++){
                            if(arr[i].userName.indexOf(v)>-1){
                              newarr.push(arr[i])
                            }
                          }
                          console.log(v)
                          this.setState({
                            userdata:newarr
                          })
                        }}
                          value={{key:this.state.searchrangeType=="Person"? sessionStorage.getItem('unumber'):this.state.searchuser}} onChange={(v) => {//console.log(v);
                            this.setState({ searchuser: v.key })
                           }}>
                        {
                         this.state.userdata && this.state.userdata.length >0 && this.state.userdata.map((item,index)=>{
                            return (
                            <Option value={item.userid} key={item.userid}>{item.userName}</Option>)
                          })
                        }
                      </Select> */}
                      <Select showSearch
                        disabled={this.state.searchrangeType == "Person" ? true : false}
                        value={this.state.searchrangeType == "Person" ? sessionStorage.getItem('unumber') : this.state.searchuser}
                        onChange={(v) => {
                          console.log(v);
                          this.setState({ searchuser: v })
                        }}>
                        {
                          this.state.userdata && this.state.userdata.length > 0 && this.state.userdata.map((item, index) => {
                            return (
                              <Option value={item.UserName} key={item.ID}>{item.UserName}</Option>)
                          })
                        }
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <td>日期范围</td>
                    <td>
                      <RangePicker
                        value={this.state.searchdate} onChange={(v) => { this.setState({ searchdate: v }) }}
                        format={dateFormat}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>类型</td>
                    <td>
                      <Select value={this.state.searchtype} onChange={(v) => { this.setState({ searchtype: v }) }}>
                        <Option value="pdf" key="pdf">pdf</Option>
                        <Option value="word" key="word">word</Option>
                        <Option value="excel" key="excel">excel</Option>
                        <Option value="png" key="png">png</Option>
                        <Option value="jpg" key="jpg">jpg</Option>
                        <Option value="txt" key="txt">txt</Option>
                      </Select>
                    </td>
                    <td><Button onClick={this.tocleardoc.bind(this)}>清空</Button></td><td>
                      <Button onClick={this.tosearchdoc.bind(this)}>检索</Button></td>
                  </tr>
                </tbody>
              </table>
              <div className="searchdoctree">
                <Tree
                  defaultExpandedKeys={[]}
                  defaultSelectedKeys={[]}
                  selectedKeys={this.state.selectedKeyssearch}
                  onSelect={this.onSelectsearch}
                >
                  <TreeNode title={"公共文档"} key="公共文档">
                    {loop(this.state.treeDataA)}
                  </TreeNode>
                  <TreeNode title={"个人文档"} key="个人文档">
                    {loop(this.state.treeDataC)}
                  </TreeNode>
                </Tree>
              </div>
            </div>
            <div className="docsearchlist listpage" style={{ height: clientHeight - 50 - 30 - 270 + 'px' }}>
              <Table
                rowKey={record => record.id}
                dataSource={this.state.datadoc}
                size="small"
                columns={this.columnsdoc}
                // scroll={{ y: clientHeight -50-30-270 - 50 }}
                pagination={{
                  pageSize: parseInt((clientHeight - 50 - 30 - 270 - 50 - 30) / 28),
                  current: this.state.pageIndex1,
                  total: Number(this.state.totaldatadoc)
                }}
                onChange={this.pagechange1.bind(this)}
              />
            </div>
          </div>
          {/* 文档预览 */}
          <div className="toviewfile"
            id="toviewfilecon"
            style={{ height: clientHeight - 56 + 'px', display: this.state.file && this.state.type != "pdf" ? "block" : "none" }} >
            {
              this.state.file && this.state.type != "pdf" && this.state.type != "docx" ?
                <Draggable
                  position={this.state.controlledPosition}
                  onStart={this.handleStart.bind(this)}
                  onDrag={this.handleDrag.bind(this)}
                  onStop={this.handleStop.bind(this)}>
                  <img id="swiplun" src={'/' + this.state.file}
                    style={{ maxHeight: "98%", maxWidth: "98%", width: "auto", height: "auto" }} />
                </Draggable> : ""
            }
            {
              this.state.file && this.state.type == "docx" ?
                <FileViewer
                  fileType={this.state.type}
                  filePath={this.state.file}
                  onError={Error} /> : ""
            }
            <span className="tocloseviewfile" onClick={this.tocloseviewfile.bind(this)}>
              <img src={require("../../assets/imgs/close.png")} alt="" /></span>
          </div>

          <h2 className="listaction">
            <div className="listactionl">
              <Button className="searchbtn" style={{ display: this.state.buttonarr.indexOf('搜索') > -1 ? "inline-block" : "none" }} onClick={this.tohandleSearch.bind(this)}>搜索</Button>
            </div>
            {
              this.state.currenttabs == 3 ?
                <div className="listactionr">
                  <Button onClick={this.toviewattribute.bind(this)} style={{ display: this.state.buttonarr.indexOf('属性') > -1 ? "inline-block" : "none" }} disabled={this.state.rowObj.Type == "Folder" || this.state.rowId == "" || this.state.doclistsele.length > 1 ? true : false}>属性</Button>
                  <Button onClick={this.toresetRecyle.bind(this)} style={{ display: this.state.buttonarr.indexOf('恢复') > -1 ? "inline-block" : "none" }} disabled={this.state.doclistsele.length < 1 ? true : false}>恢复</Button>
                  <Button onClick={this.todeleteRecyle.bind(this)} style={{ display: this.state.buttonarr.indexOf('回收站删除') > -1 ? "inline-block" : "none" }} disabled={this.state.doclistsele.length < 1 ? true : false}>删除</Button>
                  <Button onClick={this.toclearRecyle.bind(this)} style={{ display: this.state.buttonarr.indexOf('清空') > -1 ? "inline-block" : "none" }} disabled={this.state.selectedKeysD.length == 0 ? true : false}>清空</Button>
                </div> :
                this.state.currenttabs == 1 ?
                  <div className="listactionr">
                    <Button disabled={this.state.doclistidsele.length == 0 ? true : false} style={{ display: this.state.buttonarr.indexOf('收藏') > -1 ? "inline-block" : "none" }} onClick={this.tocancelFavorite.bind(this)}>取消收藏</Button>
                    <Button onClick={this.handleDown.bind(this)} style={{ display: this.state.buttonarr.indexOf('下载') > -1 ? "inline-block" : "none" }}
                      disabled={this.state.rowObj.CheckOut == "1" || this.state.rowObj.Type == "Folder" ||
                        this.state.doclistidsele.length > 1 || this.state.rowId == ""
                        ? true : false}>下载</Button>
                    <Button onClick={this.toviewattribute.bind(this)} style={{ display: this.state.buttonarr.indexOf('属性') > -1 ? "inline-block" : "none" }} disabled={this.state.rowObj.Type == "Folder" || this.state.doclistidsele.length > 1 || this.state.rowId == "" ? true : false}>属性</Button>
                    <Button onClick={this.toviewfile.bind(this)} style={{ display: this.state.buttonarr.indexOf('预览') > -1 ? "inline-block" : "none" }} disabled={this.state.rowObj.Type == "Folder" || this.state.doclistidsele.length > 1 || this.state.rowId == "" ? true : false}>预览</Button>
                    <Button onClick={this.toUpdateDocList.bind(this)} style={{ display: this.state.buttonarr.indexOf('刷新') > -1 ? "inline-block" : "none" }} disabled={this.state.treepath == "" ? true : false}>刷新</Button>
                    <Button onClick={this.handleimport.bind(this)} style={{ display: this.state.buttonarr.indexOf('导出') > -1 ? "inline-block" : "none" }} disabled={this.state.data.length == 0 ? true : false}>导出</Button>
                  </div> :
                  // 上锁状态:重命名 编辑 更新禁用 Locked
                  // 编辑状态:下载 上锁 重命名 删除 收藏 移动禁用 CheckOut
                  <div className="listactionr">
                    <Button onClick={this.toUploaddoc.bind(this)} style={{ display: this.state.buttonarr.indexOf('上传') > -1 ? "inline-block" : "none" }} disabled={this.state.treepath ? false : true}>上传</Button>

                    <Button onClick={this.handleDown.bind(this)} style={{ display: this.state.buttonarr.indexOf('下载') > -1 ? "inline-block" : "none" }}
                      disabled={this.state.rowObj.CheckOut == "1" || this.state.rowObj.Type == "Folder" || this.state.treepath == "" ||
                        this.state.doclistidsele.length > 1 || this.state.rowId == "" ? true : false}>下载</Button>

                    <Button onClick={this.toUpdateName.bind(this)} style={{ display: this.state.buttonarr.indexOf('重命名') > -1 ? "inline-block" : "none" }}
                      disabled={this.state.rowObj.CheckOut == "1" || this.state.rowObj.Locked == "1" ||
                        this.state.rowObj.Type == "Folder" || this.state.treepath == "" || this.state.doclistidsele.length > 1 || this.state.rowId == "" ? true : false}>重命名</Button>
                    {
                      this.state.rowObj.Locked == "1" ?
                        <Button onClick={this.toLockDoc.bind(this)} style={{ display: this.state.buttonarr.indexOf('上锁') > -1 ? "inline-block" : "none" }}
                          disabled={this.state.rowObj.CheckOut == "1" || this.state.rowObj.Type == "Folder" || this.state.rowId == "" || this.state.treepath == "" || this.state.doclistidsele.length > 1 ? true : false}>解锁</Button> :
                        <Button onClick={this.toLockDoc.bind(this)} style={{ display: this.state.buttonarr.indexOf('上锁') > -1 ? "inline-block" : "none" }}
                          disabled={this.state.rowObj.CheckOut == "1" || this.state.rowObj.Type == "Folder" || this.state.rowId == "" || this.state.treepath == "" || this.state.doclistidsele.length > 1 ? true : false}>上锁</Button>
                    }
                    {
                      this.state.rowObj.CheckOut == "1" ?
                        <Button onClick={this.editdoc.bind(this)} style={{ display: this.state.buttonarr.indexOf('编辑') > -1 ? "inline-block" : "none" }}
                          disabled={this.state.rowObj.Locked == "1" || this.state.rowObj.Type == "Folder" || this.state.rowId == "" || this.state.treepath == "" || this.state.doclistidsele.length > 1 ? true : false}>取消编辑</Button> :
                        <Button onClick={this.editdoc.bind(this)} style={{ display: this.state.buttonarr.indexOf('编辑') > -1 ? "inline-block" : "none" }}
                          disabled={this.state.rowObj.Locked == "1" || this.state.rowObj.Type == "Folder" || this.state.rowId == "" || this.state.treepath == "" || this.state.doclistidsele.length > 1 ? true : false}>编辑</Button>
                    }

                    <Button onClick={this.toUpdateDoc.bind(this)} style={{ display: this.state.buttonarr.indexOf('更新') > -1 ? "inline-block" : "none" }}
                      disabled={this.state.rowObj.Locked == "1" || this.state.rowObj.Type == "Folder" || this.state.rowId == "" || this.state.treepath == "" || this.state.doclistidsele.length > 1 ? true : false}>更新</Button>

                    <Button onClick={this.deledoctree.bind(this, '文档')} style={{ display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }}
                      disabled={this.state.rowObj.CheckOut == "1" || this.state.rowObj.Type == "Folder" || this.state.treepath == "" || this.state.doclistidsele.length == 0 ? true : false}>删除</Button>

                    <Button onClick={this.toCollectDoc.bind(this)} style={{ display: this.state.buttonarr.indexOf('收藏') > -1 ? "inline-block" : "none" }}
                      disabled={this.state.doclistidsele.length == 0 || this.state.rowId == "" || this.state.treepath == "" ? true : false}>收藏</Button>

                    <Button onClick={this.toRemoveDoc.bind(this)} style={{ display: this.state.buttonarr.indexOf('移动') > -1 ? "inline-block" : "none" }}
                      disabled={this.state.rowObj.CheckOut == "1" || this.state.treepath == "" || this.state.doclistidsele.length > 1 || this.state.rowId == "" ? true : false}>移动</Button>

                    <Button onClick={this.toviewattribute.bind(this)} style={{ display: this.state.buttonarr.indexOf('属性') > -1 ? "inline-block" : "none" }} disabled={this.state.rowObj.Type == "Folder" || this.state.treepath == "" || this.state.doclistidsele.length > 1 || this.state.rowId == "" ? true : false}>属性</Button>

                    <Button onClick={this.toviewfile.bind(this)} style={{ display: this.state.buttonarr.indexOf('预览') > -1 ? "inline-block" : "none" }} disabled={this.state.rowObj.Type == "Folder" || this.state.treepath == "" || this.state.doclistidsele.length > 1 || this.state.rowId == "" ? true : false}>预览</Button>


                    {/* {
                      this.state.type.indexOf('pdf')>-1?
                      <Button onClick={this.toviewfile.bind(this)} disabled={this.state.rowObj.Type == "Folder" || this.state.treepath=="" || this.state.doclistidsele.length > 1|| this.state.rowId ==""? true : false}>预览</Button>
                      :
                      <Link to={`/文档预览/${this.state.rowObj.Path}`} target="_blank">
                        <Button disabled={this.state.rowObj.Type == "Folder" || this.state.treepath=="" || this.state.doclistidsele.length > 1|| this.state.rowId ==""? true : false}>预览</Button>
                      </Link>
                    } */}


                    <Button onClick={this.toUpdateDocList.bind(this)} style={{ display: this.state.buttonarr.indexOf('刷新') > -1 ? "inline-block" : "none" }} disabled={this.state.treepath == "" ? true : false}>刷新</Button>
                    <Button onClick={this.handleimport.bind(this)} style={{ display: this.state.buttonarr.indexOf('导出') > -1 ? "inline-block" : "none" }} disabled={this.state.treepath == "" || this.state.data.length == 0 ? true : false}>导出</Button>
                  </div>
            }
          </h2>

          <div className="listcon" style={{ width: "100%", height: clientHeight - 90 + 'px' }}>
            <Resizable className="box2" axis="x"
              minConstraints={[700, 700]}
              // maxConstraints={[300, 300]}
              width={this.state.leftw}
              height={clientHeight - 90}
              onResize={(event, { element, size }) => {
                // ////console.log(size)
                this.setState({ leftw: size.width, centerw: clientWidth - this.state.leftd - size.width });
              }}>
              <div className="listdata" style={{ width: this.state.centerw ? this.state.leftw + 'px' : "100%" }}>
                <div className="listpage" style={{ height: clientHeight - 50 - 30 - 50 + 'px' }}>
                  <Table
                    rowSelection={rowSelection}
                    rowKey={record => record.ID}
                    dataSource={this.state.data}
                    size="small"
                    columns={this.columns}
                    // scroll={{y:clientHeight-30-90-50}}
                    pagination={{
                      pageSize: Number((clientHeight - 30 - 50 - 40 - 30 - 50) / 28),
                      current: this.state.pageIndex,
                      total: this.state.data.length
                    }}
                    onChange={this.pagechange.bind(this)}
                    onRow={(record) => {
                      return {
                        onClick: this.senddata.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassName}
                  />
                </div>
              </div>
            </Resizable>
            <div className="listinfo"
            // style={{ width: clientWidth - this.state.leftd - this.state.leftw+ 'px' }}
            >
              <span className="tabscloseattr" onClick={this.tocloseattribute.bind(this)} ><img src={require("../../assets/imgs/close.png")} alt="" /></span>
              <Tabs defaultActiveKey="1" className="docmanagetabs">
                <TabPane tab="属性" key="1">
                  <table>
                    <tbody>
                      <tr>
                        <td>名称</td><td>{this.state.rowObj.Name}</td>
                      </tr>
                      <tr style={{ display: this.state.currenttabs == 3 ? "none" : "" }}>
                        <td>文件夹</td><td>{this.state.treepath}</td>
                      </tr>
                      <tr>
                        <td>大小</td><td>{this.state.rowObj.FileSize}</td>
                      </tr>
                      <tr>
                        <td>创建</td><td>{this.state.rowObj.CreatePeople}</td>
                      </tr>
                      <tr>
                        <td>创建时间</td><td>{this.state.rowObj.CreateTime}</td>
                      </tr>
                      <tr>
                        <td>修改</td><td>{this.state.rowObj.UpdatePeople}</td>
                      </tr>
                      <tr>
                        <td>修改时间</td><td>{this.state.rowObj.UpdateTime}</td>
                      </tr>
                      <tr>
                        <td>文件类型</td><td>{this.state.rowObj.MimeType}</td>
                      </tr>
                      <tr className="attrkeyword">
                        <td>关键字</td><td><span style={{ fontSize: "10px", color: "#ccc" }}>(多个关键字直接用,分隔)</span>
                          <TextArea value={this.state.ExtractedText}
                            onChange={(e) => { this.setState({ ExtractedText: e.target.value }) }}
                            onBlur={this.onKeyWordsave.bind(this)} /></td>
                      </tr>
                      <tr style={{ display: this.state.currenttabs == 3 ? "none" : "" }}>
                        <td>状态</td><td>{
                          this.state.rowObj.CheckOut == "1" ? "编辑状态-" + this.state.rowObj.CheckOutPeople + '-' + this.state.rowObj.CheckOutTime :
                            this.state.rowObj.Locked == "1" ? "上锁状态-" + this.state.rowObj.LockedPeople + '-' + this.state.rowObj.LockedTime :
                              "正常情况"
                        }</td>
                      </tr>
                    </tbody>
                  </table>
                </TabPane>
                <TabPane tab="版本" key="2">
                  <div className="docversion" style={{ height: clientHeight - 30 - 90 - 40 + 'px' }}>
                    <div className="listscroll" style={{ height: clientHeight - 30 - 90 - 40 + 'px' }}>
                      <Table
                        rowKey={record => record.DVID}
                        dataSource={this.state.datadocver}
                        size="small"
                        columns={this.columnsdocver}
                        scroll={{ y: clientHeight - 50 - 40 - 50 }}
                        pagination={false}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="备注" key="3">
                  <div className="textcontent" style={{ height: clientHeight - 30 - 90 - 40 + 'px' }}>
                    <div className="addtext">
                      <h2>
                        <div className="listaction">
                          <div className="listactionl" >添加备注</div>

                          <div className="listactionr" style={{ display: this.state.textaddedit == "编辑" ? "flex" : "none" }}>
                            <Button onClick={this.toedittextsave.bind(this)}>更新</Button>
                            <Button onClick={this.tocanceledittextsave.bind(this)}>取消</Button>
                          </div>
                          <div className="listactionr" style={{ display: this.state.textaddedit == "编辑" ? "none" : "block" }}>
                            <Button onClick={this.toaddtext.bind(this)} disabled={this.state.textvalue ? false : true}>新增</Button>
                          </div>
                        </div></h2>
                      <TextArea rows={4} value={this.state.textvalue} onChange={(e) => { this.setState({ textvalue: e.target.value }) }} />
                      <h2>备注列表</h2>
                    </div>
                    <ul className="textlist">
                      {
                        this.state.textcondata.length > 0 && this.state.textcondata.map((item, index) => {
                          return (<li key={index}>
                            <h2 className="textlisttitle">
                              <span className="author">{item.CreatePeople}
                              </span>
                              <span className="publishtime">
                                {item.CreateTime}
                                <span>
                                  <img onClick={this.toedittext.bind(this, item)} className="editimg" src={require("../../assets/imgs/edit.png")} alt="" />
                                  <img onClick={this.todeletetext.bind(this, item)} className="deleteimg" src={require("../../assets/imgs/delete.png")} alt="" />
                                </span>
                              </span>
                            </h2>
                            <p>{item.Remark}</p>
                          </li>)
                        })
                      }
                    </ul>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const Documentmanageapp = Form.create()(Documentmanage);
export default Documentmanageapp;