import React from 'react';
// 引入折现图
import echarts from 'echarts';
import 'echarts/lib/chart/bar';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import axios from 'axios';
import './style.scss'
import { conversion } from '../../../converse'
import store from '../../../store/index'
var timer;
class Fivedata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intemperatureoption: {},
      intemperaturexAais: [],
      intemperaturedata: [],
      arr0 :[0,0,0,0,0,0,0,0,0,0,0,0]
    }
  }
  componentDidMount() {
    //锅炉蒸发量 #1
    axios.get('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=99').then(res => {
      var arr = conversion(res)
      var dataAxis = []
      var data = []
      if (Number(arr[0].values) < 50) {
        //锅炉蒸发量 #2
        axios.get('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=100').then(res1 => {
          var arr1 = conversion(res1)
          var dataAxis1 = []
          var data1 = []
          for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].time.indexOf("T") > -1) {
              dataAxis1.push(arr1[i].time.split('T')[1])
            } else {
              dataAxis1.push(arr1[i].time.split(' ')[1])
            }
            data1.push((parseFloat(arr1[i].values)).toFixed(2))
          }
          var intemperatureoption1 = {
            tooltip: {
              trigger: 'item',
              // position: [50, 20],
              formatter: " {c} "
            },
            legend: {
              data: ['#2锅炉效率','#1锅炉效率'],
              icon: "rect",
              textStyle: { color: 'white' },
            },
            color:['#ff6600','#2db7f5'], 
            // grid: {
            //   left: '5%',   // 与容器左侧的距离
            //   top: "4%"
            // },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: dataAxis1,
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#fff',  //更改坐标轴文字颜色
                  fontSize: 12      //更改坐标轴文字大小
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              }
            },
            yAxis: {
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#fff',  //更改坐标轴文字颜色
                  fontSize: 12      //更改坐标轴文字大小
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              },
              splitLine: {
                show: false
              },
              min: function (value) {
                return value.min - 1;
              },
              // show: false,
              type: 'value',
            },
            series: [{
              data: data1,
              type: 'line',
              name: "#2锅炉效率",
              smooth: 0.5,
            },{
              data: this.state.arr0,
              type: 'line',
              name: "#1锅炉效率",
              smooth: 0.5,
            }]
          };
          this.setState({
            intemperatureoption: intemperatureoption1,
            intemperaturexAais: dataAxis1,
            intemperaturedata: data1,
          }, function () {
            //基于准备好的dom，初始化echarts实例
            var myChart11 = echarts.init(document.getElementById('capacity'));
            // 绘制图表
            myChart11.setOption(this.state.intemperatureoption);
            var _this = this
            timer = setInterval(function () {
              axios.get('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=100').then(res1 => {
                var data1 = _this.state.intemperaturedata
                var dataAxis1 = _this.state.intemperaturexAais
                var arr1 = conversion(res1)
                if (data1.length > 11) {
                  dataAxis1.shift()
                  data1.shift()
                  for (let i = 0; i < arr1.length; i++) {
                    if (arr1[i].time.indexOf("T") > -1) {
                      dataAxis1.push(arr1[i].time.split('T')[1])
                    } else {
                      dataAxis1.push(arr1[i].time.split(' ')[1])
                    }
                    data1.push((parseFloat(arr1[i].values)).toFixed(2))
                  }
                } else {
                  for (let i = 0; i < arr1.length; i++) {
                    if (arr1[i].time.indexOf("T") > -1) {
                      dataAxis1.push(arr1[i].time.split('T')[1])
                    } else {
                      dataAxis1.push(arr1[i].time.split(' ')[1])
                    }
                    data1.push((parseFloat(arr1[i].values)).toFixed(2))
                  }
                }
                var intemperatureoption = {
                  tooltip: {
                    trigger: 'item',
                    // position: [50, 20],
                    formatter: " {c} "
                  },
                  color:['#ff6600','#2db7f5'], 
                  legend: {
                    data: ['#2锅炉效率','#1锅炉效率'],
                    icon: "rect",
                    textStyle: { color: 'white' },
                  },
                  // grid: {
                  //   left: '5%',   // 与容器左侧的距离
                  //   top: "4%"
                  // },
                  xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: dataAxis1,
                    axisLabel: {
                      show: true,
                      textStyle: {
                        color: '#fff',  //更改坐标轴文字颜色
                        fontSize: 12      //更改坐标轴文字大小
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#fff' //更改坐标轴颜色
                      }
                    }
                  },
                  yAxis: {
                    axisLabel: {
                      show: true,
                      textStyle: {
                        color: '#fff',  //更改坐标轴文字颜色
                        fontSize: 12      //更改坐标轴文字大小
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#fff' //更改坐标轴颜色
                      }
                    },
                    splitLine: {
                      show: false
                    },
                    min: function (value) {
                      return value.min - 1;
                    },
                    // show: false,
                    type: 'value',
                  },
                  series: [{
                    data: data1,
                    type: 'line',
                    name: "#2锅炉效率",
                    smooth: 0.5,
                  },{
                    data: _this.state.arr0,
                    type: 'line',
                    name: "#1锅炉效率",
                    smooth: 0.5,
                  }]
                };
                _this.setState({
                  intemperatureoption: intemperatureoption,
                  intemperaturexAais: dataAxis1,
                  intemperaturedata: data1
                }, function () {
                  //基于准备好的dom，初始化echarts实例
                  var myChart111 = echarts.init(document.getElementById('capacity'));
                  // 绘制图表
                  myChart111.setOption(_this.state.intemperatureoption);
                })
              })
            }, 5000)
          })
        })
      } else {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].time.indexOf("T") > -1) {
            dataAxis.push(arr[i].time.split('T')[1])
          } else {
            dataAxis.push(arr[i].time.split(' ')[1])
          }
          data.push((parseFloat(arr[i].values)).toFixed(2))
        }
        var intemperatureoption = {
          tooltip: {
            trigger: 'item',
            // position: [50, 20],
            formatter: " {c} "
          },
          legend: {
            data: ['#1锅炉效率','#2锅炉效率'],
            icon: "rect",
            textStyle: { color: 'white' },
          },
          color:['#ff6600','#2db7f5'], 
          // grid: {
          //   left: '5%',   // 与容器左侧的距离
          //   top: "3%"
          // },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dataAxis,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',  //更改坐标轴文字颜色
                fontSize: 12      //更改坐标轴文字大小
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          yAxis: {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',  //更改坐标轴文字颜色
                fontSize: 12      //更改坐标轴文字大小
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            },
            splitLine: {
              show: false
            },
            min: function (value) {
              return value.min - 1;
            },
            // show: false,
            type: 'value',
          },
          series: [{
            data: data,
            type: 'line',
            name: "#1锅炉效率",
            smooth: 0.5,
          },{
            data: this.state.arr0,
            type: 'line',
            name: "#2锅炉效率",
            smooth: 0.5,
          }]
        };
        this.setState({
          intemperatureoption: intemperatureoption,
          intemperaturexAais: dataAxis,
          intemperaturedata: data,
        }, function () {
          //基于准备好的dom，初始化echarts实例
          var myChart11 = echarts.init(document.getElementById('capacity'));
          console.log(document.getElementById('capacity'),myChart11)
          // 绘制图表
          myChart11.setOption(this.state.intemperatureoption);
          var _this = this
          timer = setInterval(function () {
            var data = _this.state.intemperaturedata
            var dataAxis = _this.state.intemperaturexAais
            axios.get('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=99').then(res => {
              var arr = conversion(res)
              if (data.length > 11) {
                dataAxis.shift()
                data.shift()
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].time.indexOf("T") > -1) {
                    dataAxis.push(arr[i].time.split('T')[1])
                  } else {
                    dataAxis.push(arr[i].time.split(' ')[1])
                  }
                  data.push((parseFloat(arr[i].values)).toFixed(2))
                }
              } else {
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].time.indexOf("T") > -1) {
                    dataAxis.push(arr[i].time.split('T')[1])
                  } else {
                    dataAxis.push(arr[i].time.split(' ')[1])
                  }
                  data.push((parseFloat(arr[i].values)).toFixed(2))
                }
              }
              var intemperatureoption = {
                tooltip: {
                  trigger: 'item',
                  // position: [50, 20],
                  formatter: " {c} "
                },
                color:['#ff6600','#2db7f5'], 
                legend: {
                  data: ['#1锅炉效率','#2锅炉效率'],
                  icon: "rect",
                  textStyle: { color: 'white' },
                },
                // grid: {
                //   left: '5%',   // 与容器左侧的距离
                //   top: "3%"
                // },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: dataAxis,
                  axisLabel: {
                    show: true,
                    textStyle: {
                      color: '#fff',  //更改坐标轴文字颜色
                      fontSize: 12      //更改坐标轴文字大小
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#fff' //更改坐标轴颜色
                    }
                  }
                },
                yAxis: {
                  axisLabel: {
                    show: true,
                    textStyle: {
                      color: '#fff',  //更改坐标轴文字颜色
                      fontSize: 12      //更改坐标轴文字大小
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#fff' //更改坐标轴颜色
                    }
                  },
                  splitLine: {
                    show: false
                  },
                  min: function (value) {
                    return value.min - 1;
                  },
                  // show: false,
                  type: 'value',
                },
                series: [{
                  data: data,
                  type: 'line',
                  name: "#1锅炉效率",
                  smooth: 0.5,
                },{
                  data: _this.state.arr0,
                  type: 'line',
                  name: "#2锅炉效率",
                  smooth: 0.5,
                }]
              };
              _this.setState({
                intemperatureoption: intemperatureoption,
                intemperaturexAais: dataAxis,
                intemperaturedata: data
              }, function () {
                //基于准备好的dom，初始化echarts实例
                var myChart111 = echarts.init(document.getElementById('capacity'));
                // 绘制图表
                myChart111.setOption(_this.state.intemperatureoption);
              })
            })
          }, 5000)
        })
      }
    })
  }

  componentWillMount() {
    clearInterval(timer)
  }

  render() {
    const clientHeight = store.getState().clientHeight
    return (
      <div className="fivepage">
        <div className="fivepagecon" style={{height:clientHeight - 50 - 20 + 'px' }}>
          <h2 className="datatitlename">效率百分比</h2>
          <div id="capacity" style={{ height: clientHeight - 160- 20 + 'px'}}>
          </div>
        </div>
      </div>
    )
  }
}
export default Fivedata;