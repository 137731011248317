import React from 'react';
import { Input, message, Button, Select, Radio, Drawer, Form, Col, Row, Table, Tooltip, DatePicker, Modal, TimePicker } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import './style.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { onCellf } from '../../comfunc'
import store from '../../store';
import { getButtonLimit } from '../../utils/getbuttonlimit'
moment.locale('zh-cn')
const Option = Select.Option;
const { TextArea, Search } = Input
const confirm = Modal.confirm

class InspectionTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      uname: '',

      iptobj: {},
      addedit: "添加",

      data1: [],
      data1fu: [],
      rowIdA: '',
      rowIdAobj: {},

      pageSize: parseInt(parseFloat(store.getState().clientHeight  - 50 - 70 - 50) / 28),
      pageIndex1: 1,

      //角色
      rolearr: [],

      //巡检任务定期
      montharr: [],//月份遍历

      weeksele: [],//周选择
      monthsele: [],//月选中

      regular4sele: 0,
      dataroutearr: [],
      PathIdseleobj: {}
    }
    //巡检任务
    this.data1columns = [{
      title: '序号',
      dataIndex: 'xu',
      key: 'xu',
      width: '5%',
      render: (text, record, index) => {
        return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
      }
    },
    {
      title: '任务名称',
      dataIndex: 'TaskName',
      key: 'TaskName',
      width: '15%',
    },
    {
      title: '关联路径信息',
      dataIndex: 'PathId',
      key: 'PathId',
      width: '15%',
      onCell: onCellf,
      render: (text, record) => {
        return (<span>{record.PathCode + '' + record.PathName}</span>)
      }
    },
    {
      title: '任务周期',
      dataIndex: 'TaskCycle',
      key: 'TaskCycle',
      width: '20%',
      render: (text, record) => {
        if (text == 0) {
          return (<span>{'时间：' + record.TaskTime + ' ' + ' / ' + ' ' + '日期：' + record.TaskDate}</span>)
        } else if (text == 1) {
          return (<span>{'时间：' + record.TaskTime + ' ' + ' / ' + ' ' + '间隔：' + record.TaskInterval + '小时'}</span>)
        } else if (text == 2) {
          return (<span>{'时间：' + record.TaskTime + ' ' + ' / ' + ' ' + '周选：' + record.TaskDate}</span>)
        } else if (text == 3) {
          return (<span>{'时间：' + record.TaskTime + ' ' + ' / ' + ' ' + '月选：' + record.TaskDate}</span>)
        }
      },
      onCell: onCellf,
    },
    {
      title: '任务捐派角色',
      dataIndex: 'AssignedRole',
      key: 'AssignedRole',
      width: '12%',
      onCell: onCellf,
      render: (text) => {
        var arr = this.state.rolearr
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].ID == text) {
            return (<span>{arr[i].RoleName}</span>)
          }
        }
      }
    },
    {
      title: '任务要求',
      dataIndex: 'InspectionRequirements',
      key: 'InspectionRequirements',
      width: '20%',
      onCell: onCellf,
      render: this.renderf
    },
    {
      title: '任务状态',
      dataIndex: 'TaskStatus',
      key: 'TaskStatus',
      render: (text) => {
        if (text == "1") {
          return (<span>启用</span>)
        } else if (text == "0") {
          return (<span>停用</span>)
        }
      },
      width: '12%',
    }]
    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
    }
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(59)

    var arr = []
    for (let i = 1; i < 32; i++) {
      arr.push(i + '号')
    }
    this.setState({
      montharr: arr
    })

    //日历
    var uname = sessionStorage.getItem('uname')
    this.setState({
      uname
    })

    axios.get('/DATA/OM/OM_PatrolTask.aspx?flag=12').then(data => {
      console.log('巡检任务', conversion(data))
      this.setState({
        data1: conversion(data),
        data1fu: conversion(data)
      })
    })
    axios.get('/DATA/UserInfo/UserInfo.aspx?flag=20').then(data => {
      console.log('角色', conversion(data))
      this.setState({
        rolearr: conversion(data)
      })
    })
    axios.get('/DATA/OM/OM_PatrolTask.aspx?flag=13').then(data => {
      console.log('巡检任务定时周期', conversion(data))
      this.setState({
        regulartimearr: conversion(data)
      })
    })
    //巡检路径
    axios.get('/DATA/OM/OM_PatrolPath.aspx?flag=1').then(data => {
      console.log('路径', conversion(data))
      this.setState({
        dataroutearr: conversion(data)
      })
    })
  }
  componentWillReceiveProps(nextprops) {
    ////console.log(nextprops)
    this.setState({
      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 70 - 50) / 28),
    })
  }

  //选中
  senddata1(record) {
    console.log(record)
    var arr = this.state.dataroutearr
    var PathIdseleobj = {}
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id == record.PathId) {
        PathIdseleobj = arr[i]
      }
    }
    this.setState({
      rowIdA: record.id,
      rowIdAobj: record,
      regular4sele: record.TaskCycle,
      PathIdseleobj: PathIdseleobj
    })
  }
  setRowClassName1 = (record) => {
    return record.id === this.state.rowIdA ? 'clickRowStyl' : 'clickRowSty';
  }

  //添加
  handleAdd() {
    this.setState({
      addeditname: "巡检任务",
      addedit: "添加",
      iptobj: { TaskDate: "" },
      visible: true,
      regular4sele: 0
    }, function () {
      this.props.form.resetFields()
    })
  }
  //编辑
  handleEdit() {
    if (this.state.rowIdA) {
      var obj = this.state.rowIdAobj
      this.setState({
        addeditname: "巡检任务",
        addedit: "编辑",
        iptobj: obj,
        visible: true,
      })
    } else {
      message.error('请选择！')
    }
  }
  //保存
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d && !value._i) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
          } else if (value._f == "HH:mm:ss") {
            values[i] = value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else if (value._f == "YYYY-MM-DD") {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
          } else {
            values[i] = values[i]
          }
        }
        values.CreatePeople = this.state.uname
        values.PathId = this.state.PathIdseleobj.id
        values.PathCode = this.state.PathIdseleobj.PathCode
        values.PathName = this.state.PathIdseleobj.PathName
        values.PathVersions = this.state.PathIdseleobj.PathVersions
        values.PathVersionsName = this.state.PathIdseleobj.VersionsName
        if (values.TaskCycle == 2) {
          values.TaskDate = values.TaskDate1.join(',')
        } else if (values.TaskCycle == 3) {
          values.TaskDate = values.TaskDate2.join(',')
        }
        delete values.TaskDate1
        delete values.TaskDate2
        if (this.state.addedit == "添加") {

          axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=11', values).then(data => {
            console.log(data)
            if (data.data == true || data.data.indexOf("true") > -1) {
              axios.get('/DATA/OM/OM_PatrolTask.aspx?flag=12').then(data => {
                console.log('巡检任务', conversion(data))
                this.setState({
                  data1: conversion(data),
                  visible: false
                })
              })
            }
          })
        } else if (this.state.addedit == "编辑") {
          values.id = this.state.rowIdA
          axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=14', values).then(data => {
            console.log(data)
            if (data.data == true || data.data.indexOf("true") > -1) {
              axios.get('/DATA/OM/OM_PatrolTask.aspx?flag=12').then(data => {
                console.log('巡检任务', conversion(data))
                this.setState({
                  data1: conversion(data),
                  visible: false
                })
              })
            }
          })
        }
      }
    });
  };
  //删除
  handleDel() {
    var _this = this
    if (this.state.rowIdA) {
      var key = this.state.rowIdA
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=15', {
            id: _this.state.rowIdA
          }).then(data => {
            //console.log(data)
            if (data.data == true || data.data.indexOf('true') > -1) {
              const dataSource = [..._this.state.data1];
              _this.setState({ data1: dataSource.filter(item => item.id !== key), rowIdAobj: {}, rowIdA: "" }, function () {
                message.success('删除成功！')
              });
            }
          })
        },
        onCancel() {
          _this.setState({
            // detaillistId: ''
          })
        },
      });
    } else {
      message.error('请选择！')
    }
  }
  //启用/停用
  handleStartEnd() {
    var _this = this
    var str = ''
    if (this.state.rowIdAobj.TaskStatus == "1") {
      str = '停用'
    } else if (this.state.rowIdAobj.TaskStatus == "0") {
      str = '启用'
    }
    if (this.state.rowIdA) {
      confirm({
        title: `确定要${str}吗?`,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=16', {
            id: _this.state.rowIdA,
            TaskStatus: _this.state.rowIdAobj.TaskStatus
          }).then(data => {
            if (data.data == true || data.data.indexOf("true") > -1) {
              var arr = _this.state.data1
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].id == _this.state.rowIdA) {
                  arr[i].TaskStatus = arr[i].TaskStatus == "1" ? "0" : arr[i].TaskStatus == "0" ? "1" : ""
                }
              }
              _this.setState({
                data1: arr
              }, function () {
                message.success('操作成功！')
              })
            }
          })
        },
        onCancel() {
          _this.setState({
            // detaillistId: ''
          })
        },
      });
    } else {
      message.error('请选择！')
    }
  }
  onClose = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      iptobj: {},
      rowIdA: '',
      rowIdAobj: {}
    })
  }
  pagechange(page) {
    this.setState({
      pageIndex1: page.current
    })
  }

  PathrouteId(v) {
    console.log(v)
    var arr = this.state.dataroutearr
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id == v) {
        this.setState({
          PathIdseleobj: arr[i]
        })
      }
    }
  }
  //时间转为HH:mm:ss
  timetostandard(str) {
    var arr = str.split(":")
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].length == 1) {
        arr[i] = '0' + arr[i]
      }
    }
    var newstr = arr.join(':')
    return newstr
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="Inspectiontask" style={{ height: clientHeight - 50 + 'px' }}>
        <div className="Inspectiontaskcon">
          <h2 className="h2title">巡检任务</h2>
          <span className="action">
            <span>任务名称：<Search onSearch={(v) => {
              var arr = this.state.data1fu
              var newarr = []
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].TaskName.indexOf(v) > -1) {
                  newarr.push(arr[i])
                }
              }
              this.setState({
                data1: newarr
              })
            }} /></span>
            <span className="actionright">
              <Button style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this)}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this)}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this)}>删除</Button>
              <Button style={{display:this.state.buttonarr.indexOf('启用/停用')>-1?"inline-block":"none"}} onClick={this.handleStartEnd.bind(this)}>启用/停用</Button>
            </span>
          </span>
          <div className="listpage" style={{ height: clientHeight - 50 - 80 }}>
            <Table
              size="small"
              dataSource={this.state.data1}
              columns={this.data1columns}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndex1,
                total: this.state.data1length
              }}
              onChange={this.pagechange.bind(this)}
              onRow={(record) => {
                return {
                  onClick: this.senddata1.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassName1}
            />
          </div>
        </div>

        <Drawer
          title={this.state.addeditname + '' + this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
            // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="路径">
                    {getFieldDecorator('PathId', {
                      initialValue: this.state.iptobj["PathId"],
                      rules: [{ required: true, message: '请选择路径' }],
                    })(
                      <Select onChange={this.PathrouteId.bind(this)}>
                        {
                          this.state.dataroutearr.map((item, index) => {
                            return (<Option value={item.id} key={item.id}>{item.PathCode + '' + item.PathName}</Option>)
                          })
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="任务名称">
                    {getFieldDecorator('TaskName', {
                      initialValue: this.state.iptobj["TaskName"],
                      rules: [{ required: true, message: '请输入任务名称' }],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="任务要求">
                    {getFieldDecorator('InspectionRequirements', {
                      initialValue: this.state.iptobj["InspectionRequirements"],
                      rules: [{ required: true, message: '请输入任务要求' }],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="任务角色">
                    {getFieldDecorator('AssignedRole', {
                      initialValue: this.state.iptobj["AssignedRole"],
                      rules: [{ required: true, message: '请选择任务角色' }],
                    })(
                      <Select >
                        {
                          this.state.rolearr.map((item, index) => {
                            return (<Option value={item.ID} key={item.ID}>{item.RoleName}</Option>)
                          })
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="任务周期">
                    {getFieldDecorator('TaskCycle', {
                      initialValue: this.state.addedit == "添加" ? this.state.regular4sele : Number(this.state.iptobj['TaskCycle']),
                      rules: [{ required: true, message: '请选择任务周期' }],
                    })(
                      <Radio.Group onChange={(e) => {
                        console.log(e)
                        this.setState({
                          regular4sele: e.target.value
                        }, function () {
                          if (this.state.regular4sele == 0) {
                            this.props.form.setFieldsValue({
                              TaskTime: null,
                              TaskDate: null,
                              TaskInterval: ''
                            })
                          } else {
                            this.props.form.setFieldsValue({
                              TaskTime: null,
                              TaskDate: '',
                              TaskInterval: '',
                              TaskDate1: [],
                              TaskDate2: []
                            })
                          }
                        })
                      }}>
                        <Radio value={0} key={0}>单次</Radio>
                        <Radio value={1} key={1}>每日</Radio>
                        <Radio value={2} key={2}>每周</Radio>
                        <Radio value={3} key={3}>每月</Radio>
                      </Radio.Group>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12} >
                  <Form.Item label="时间">
                    {getFieldDecorator('TaskTime', {
                      rules: [{ required: true, message: '请选择时间' }],
                      initialValue: this.state.iptobj['TaskTime'] ? moment(this.timetostandard(this.state.iptobj['TaskTime']), "HH:mm:ss") : null,
                    })(
                      <TimePicker defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} locale={locale} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* 单次 */}
              <Row gutter={16} style={{ display: this.state.regular4sele == 0 ? "block" : "none" }}>
                <Col span={12} >
                  <Form.Item label="单次">
                    {getFieldDecorator('TaskDate', {
                      rules: [{ required: this.state.regular4sele == 0 ? true : false, message: '请选择时间' }],
                      initialValue: this.state.iptobj['TaskDate'] ? moment(this.state.iptobj['TaskDate'], 'YYYY-MM-DD') : null,
                    })(
                      <DatePicker locale={locale} defaultOpenValue={moment('2019-01-01', 'YYYY-MM-DD')} />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {/* 日 */}
              <Row gutter={16} style={{ display: this.state.regular4sele == 1 ? "block" : "none" }}>
                <Col span={12}>
                  <Form.Item label="间隔">
                    {getFieldDecorator('TaskInterval', {
                      rules: [{ required: this.state.regular4sele == 1 ? true : false, message: '请选择间隔' }],
                      initialValue: this.state.iptobj['TaskInterval'],
                    })(
                      <Select>
                        <Option value="1" key="1">1小时</Option>
                        <Option value="2" key="2">2小时</Option>
                        <Option value="3" key="3">3小时</Option>
                        <Option value="4" key="4">4小时</Option>
                        <Option value="6" key="6">6小时</Option>
                        <Option value="8" key="8">8小时</Option>
                        <Option value="12" key="12">12小时</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* 周 */}
              <Row gutter={16} style={{ display: this.state.regular4sele == 2 ? "block" : "none" }}>
                <Col span={12}>
                  <Form.Item label="周选">
                    {getFieldDecorator('TaskDate1', {
                      rules: [{ required: this.state.regular4sele == 2 ? true : false, message: '请选择' }],
                      initialValue: this.state.iptobj['TaskDate'] ?
                        this.state.iptobj['TaskDate'].indexOf(',') > -1 ?
                          this.state.iptobj['TaskDate'].split(',') :
                          [this.state.iptobj['TaskDate']]
                        : []
                    })(
                      <Select
                        style={{ display: this.state.regular4sele == 2 ? "block" : "none" }}
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="请选择"
                      >
                        <Option value="周一" key="周一">周一</Option>
                        <Option value="周二" key="周二">周二</Option>
                        <Option value="周三" key="周三">周三</Option>
                        <Option value="周四" key="周四">周四</Option>
                        <Option value="周五" key="周五">周五</Option>
                        <Option value="周六" key="周六">周六</Option>
                        <Option value="周日" key="周日">周日</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* 月 */}
              <Row gutter={16} style={{ display: this.state.regular4sele == 3 ? "block" : "none" }}>
                <Col span={12}>
                  <Form.Item label="月选">
                    {getFieldDecorator('TaskDate2', {
                      rules: [{ required: this.state.regular4sele == 3 ? true : false, message: '请选择' }],
                      initialValue: this.state.iptobj['TaskDate'] ?
                      this.state.iptobj['TaskDate'].indexOf(',') > -1 ?
                        this.state.iptobj['TaskDate'].split(',') :
                        [this.state.iptobj['TaskDate']]
                      : []
                    })(
                      <Select
                        style={{ display: this.state.regular4sele == 3 ? "block" : "none" }}
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="请选择"
                      >
                        {
                          this.state.montharr.map((item, index) => {
                            return (<Option value={item} key={item}>{item}</Option>)
                          })
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} className="formbutton">
                <Form.Item>
                  <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                    保存
                      </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </Drawer>

      </section>
    )
  }
}
const InspectionTaskapp = Form.create()(InspectionTask);
export default InspectionTaskapp;