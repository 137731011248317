import React, { Component, Fragment } from 'react';
import { Select, Input, Button, Tooltip, Tree, Table, Form, message } from 'antd';
import 'antd/dist/antd.css';
//多条件搜索
import $ from 'jquery'
import moment from 'moment';
import axios from 'axios';
import './style.scss'
import { Resizable } from 'react-resizable';
import Search from 'antd/lib/input/Search';
import store from '../../../store';
import { conversion } from '../../../converse';
import { toMenuTreeData } from '../../../utils/menutree'
import { onCellf } from '../../../comfunc'
import { changemoduledata } from '../../../store/actionCreators'
import { getButtonLimit } from '../../../utils/getbuttonlimit'
const { Option } = Select;
const { TextArea } = Input
const { TreeNode } = Tree;
class LimitManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr: [],

      iptobj: {},
      addeditflag: "",
      visible: false,

      data: [],
      datafu: [],
      datalength: 0,
      pageIndex: 1,

      data1: [],
      data1fu: [],
      data1length: 0,
      pageIndex1: 1,

      pageSize: parseInt((store.getState().clientHeight - 50 - 30 - 80 - 30 - 60) / 2),

      rowId: "",
      rowObj: {},
      attr: {
        ID: 'ID',
        rootId: '0',
        Name: 'Name',
        ParentId: 'ParentId',
        Leaf: 'Leaf',
        ModuleType: 'ModuleType',
        Visable: 'Visable',
        RecordVersion: 'RecordVersion',
        Rank: 'Rank',
        IconCls: 'IconCls',
        OnClickIconCls: 'OnClickIconCls'
      },
      treeData: [],
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      checkedKeys: [],
      halfCheckedKeys: []
    }

    //权限
    this.columnslimit = [
      {
        title: '序号', width: '5%', dataIndex: 'xu', key: "xu",
        render: (text, record, index) => {
          return (<span>{index + 1}</span>)
        }
      },
      { title: '角色名称', width: '35%', dataIndex: 'RoleName', key: "RoleName", },
      {
        title: '角色描述', width: '60%', dataIndex: 'RoleRemark', key: "RoleRemark",
        onCell: onCellf,
        render: (text) => <Tooltip placement="left" title={text}>{text}</Tooltip>
      }
    ]
    this.columnslimitper = [
      {
        title: '序号', width: '5%', dataIndex: 'xu', key: "xu",
        render: (text, record, index) => {
          return (<span>{index + 1}</span>)
        }
      },
      { title: '用户账号', width: '20%', dataIndex: 'UserNumber', key: "UserNumber" },
      { title: '用户名', width: '20%', dataIndex: 'UserName', key: "UserName", },
      {
        title: '部门', width: '55%', dataIndex: 'Department', key: "Department",
        onCell: onCellf,
        render: (text) => <Tooltip placement="left" title={text}>{text}</Tooltip>
      }
    ]
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限', res)
    this.setState({
      buttonarr: res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(91)

    axios.get('/DATA/WebSet/UserRole.aspx?flag=1').then(res => {
      console.log('角色管理', res, conversion(res))
      this.getRoleUser(conversion(res)[0].ID)
      this.getRoleLimit(conversion(res)[0].ID)
      this.setState({
        data: conversion(res),
        datafu: conversion(res),
        rowObj: conversion(res)[0],
        rowId: conversion(res)[0].ID
      })
    })
    axios.get('/DATA/WebSet/FunctionMenu.aspx?flag=1').then(res => {
      console.log('菜单树', res, conversion(res))
      var arr = conversion(res)
      var newarr = toMenuTreeData(arr, this.state.attr)
      this.setState({
        treeData: newarr
      })
    })
  }
  getRoleLimit(key) {
    axios.post(`/DATA/WebSet/T_PermitMenu.aspx?flag=1`, {
      parentId: key
    }).then(res => {
      console.log(res)
      if (res.data.success) {
        var arr = res.data.data
        var newarr = []
        for (let i = 0; i < arr.length; i++) {
          newarr.push(Number(arr[i].menuId))
        }
        this.setState({
          checkedKeys: newarr
        })
      }
    })
  }
  //获取角色用户
  getRoleUser(key) {
    axios.post(`/DATA/WebSet/UserRole.aspx?flag=4`, {
      ID: key
    }).then(data => {
      var arr = conversion(data)
      this.setState({
        data1: arr,
        data1fu: arr
      })
    })
  }

  shouldComponentUpdate() {
    return true;
  }


  //表格拉伸
  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  senddata(record) {
    this.getRoleUser(record.ID)
    this.getRoleLimit(record.ID)
    this.setState({
      rowId: record.ID,
      rowObj: record,
    })
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }

  //权限保存
  onSave() {
    if (this.state.rowId) {
      var arr = this.state.checkedKeys
      axios.post(`/DATA/WebSet/T_PermitMenu.aspx?flag=3`, {
        roleId: this.state.rowId,
        menuIds: arr.join(',')
      }).then(res => {
        console.log(res)
        if (res.data == true || res.data.success) {

          axios.get(`/DATA/WebSet/T_PermitMenu.aspx?flag=2`).then(res => {
            console.log(res)
            if (res.data.success) {
              var arr = res.data.data
              const action = changemoduledata(arr)
              store.dispatch(action)
            } else {
              message.error(res.data.data.describe)
            }
          })
          message.success('保存成功！')
        } else {
          message.error(res.data.data.describe)
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  onCheck(checkedkeys, info) {
    console.log(checkedkeys, info)
    this.setState({
      checkedKeys: checkedkeys.checked
    })
  }

  findsearchvalue(value, arr, hasarr) {
    var value = value
    var arr = arr
    var hasarr = hasarr
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].Name.indexOf(value) > -1) {
        hasarr.push(Number(arr[i].ID))
      } else {

      }
      if (arr[i].children.length > 0) {
        this.findsearchvalue(value, arr[i].children, hasarr)
      }
    }
  }
  findsearchvaluefirst(value, arr) {
    // console.log(value,arr)
    var hasarr = []
    var arr = arr
    var value = value
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].Name.indexOf(value) > -1) {
        hasarr.push(Number(arr[i].ID))
        if (arr[i].children.length > 0) {
          this.findsearchvalue(value, arr[i].children, hasarr)
        }
      } else {
        if (arr[i].children.length > 0) {
          this.findsearchvalue(value, arr[i].children, hasarr)
        }
      }
    }
    return hasarr;
  }
  onChange = e => {
    const { value } = e.target;
    if (value == "") {
      this.setState({
        expandedKeys: [],
        searchValue: value,
        autoExpandParent: false,
      });
    } else {
      var arr = this.state.treeData
      var newarr = this.findsearchvaluefirst(value, arr)
      // console.log(newarr)
      this.setState({
        expandedKeys: newarr,
        searchValue: value,
        autoExpandParent: true,
      });
    }
  };
  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  // pageChange(key,page, pageSize) {
  //   console.log(key,page, pageSize)
  //   if(key=="角色成员"){
  //     this.setState({
  //       pageIndex:Number(page.current)
  //     })
  //   }else if(key=="用户成员"){
  //     this.setState({
  //       pageIndex1:Number(page.current)
  //     })
  //   }
  // }
  render() {
    const { searchValue, expandedKeys, autoExpandParent } = this.state;
    const { getFieldDecorator } = this.props.form;
    const clientH = store.getState().clientHeight
    const loop = data =>
      data.map(item => {
        const index = item.Name.indexOf(searchValue);
        const beforeStr = item.Name.substr(0, index);
        const afterStr = item.Name.substr(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
              <span>{item.Name}</span>
            );
        if (item.children) {
          // return { title, key: item.id, children: loop(item.children) , type:item.type};
          return (
            <TreeNode key={item.ID} title={title} Type={item.Type}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} title={title} Type={item.Type} />
      });
    return (
      <Fragment>
        <div className="limitmanage">
          <div className="limitmanagel">
            <div className="limitmanagelt">
              <div className="listsearch">
                <div className="listsearchl">
                  <Search placeholder="请输入搜索内容"
                    onSearch={(v) => {
                      var arr = this.state.datafu
                      var newarr = []
                      for (let i = 0; i < arr.length; i++) {
                        if (arr[i].RoleName.indexOf(v) > -1 || arr[i].RoleRemark.indexOf(v) > -1) {
                          newarr.push(arr[i])
                        }
                      }
                      this.setState({
                        data: newarr
                      })
                    }} />
                </div>
              </div>
              <div className="listscroll" style={{ height: Number((clientH - 50 - 30 - 80 - 30) / 2) + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.data}
                  columns={this.columnslimit}
                  scroll={{ y: Number((clientH - 50 - 30 - 80 - 30) / 2) - 35 }}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: this.senddata.bind(this, record)
                    }
                  }}
                  rowClassName={this.setRowClassName}
                // pagination={{
                //   showQuickJumper: true,
                //   pageSize: this.state.pageSize,
                //   current: this.state.pageIndex,
                //   showTotal: (total) => `共 ${total} 条`,
                //   total: Number(this.state.datalength),
                // }}
                // onChange={this.pageChange.bind(this,'角色成员')}
                />
              </div>
            </div>
            <div className="limitmanagelb">
              <div className="listsearch">
                <div className="listsearchl">
                  <Search placeholder="请输入搜索内容"
                    onSearch={(v) => {
                      var arr = this.state.data1fu
                      var newarr = []
                      for (let i = 0; i < arr.length; i++) {
                        if (arr[i].UserNumber.indexOf(v) > -1
                          || arr[i].UserName.indexOf(v) > -1) {
                          newarr.push(arr[i])
                        }
                      }
                      this.setState({
                        data1: newarr
                      })
                    }}
                  />
                </div>
              </div>
              <div className="listscroll" style={{ height: Number((clientH - 50 - 30 - 80 - 30) / 2) + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.data1}
                  columns={this.columnslimitper}
                  scroll={{ y: Number((clientH - 50 - 30 - 80 - 30) / 2) - 35 }}
                  pagination={false}
                // pagination={{
                //   showQuickJumper: true,
                //   pageSize: this.state.pageSize,
                //   current: this.state.pageIndex1,
                //   showTotal: (total) => `共 ${total} 条`,
                //   total: Number(this.state.data1length),
                // }}
                // onChange={this.pageChange.bind(this,'用户成员')}
                />
              </div>
            </div>
          </div>
          <div className="limitmanager">
            <div className="listsearch">
              <div className="listsearchl">
                <Search placeholder="请输入搜索内容" onChange={this.onChange.bind(this)} />
              </div>
              <div className="listactionr">
                <Button className="addaction" style={{ display: this.state.buttonarr.indexOf('保存') > -1 ? "inline-block" : "none" }} onClick={this.onSave.bind(this)}><span className="listaddtu"></span>保存</Button>
              </div>
            </div>
            <div className="limittree" style={{ height: clientH - 50 - 30 - 40 - 10 + 'px' }}>
              <Tree
                checkable
                checkStrictly
                checkedKeys={this.state.checkedKeys}
                onCheck={this.onCheck.bind(this)}
                onExpand={this.onExpand.bind(this)}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
              >
                {loop(this.state.treeData)}
              </Tree>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const LimitManageapp = Form.create()(LimitManage)
export default LimitManageapp;