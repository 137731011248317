import React, { Component } from 'react'
import { Table, Select,Tooltip } from 'antd'
// 引入 ECharts 主模块
import echarts from 'echarts';
// import { conversion } from '../../converse'
// 引入折现图
import 'echarts/lib/chart/bar';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import './style.scss'
const Option = Select.Option;
//页数为3得数据
class Threedata extends Component {
  constructor(props) {
    super(props);
    this.state = {

      dataSource: [{
        "serialNumber": '1', "taskname": "除氧器", "taskdesc": '常规指标', "tasktype": '常规点检',
        "people": '周邵武', "time": '2019-3-2', "xiada": '2019-3-1', "xiadapeople": '周元', "status": '已完成'
      },
      {
        "serialNumber": '2', "taskname": "一次风机", "taskdesc": '常规指标', "tasktype": '常规点检',
        "people": '季政军', "time": '2019-3-11', "xiada": '2019-3-9', "xiadapeople": '周元', "status": '已完成'
      }],

      dataviewobj: {},


      dataviewobjline: {
        grid: {
          left: 50,
          top: 40,
          right: 60,
          bottom: 0
        },
        tooltip: {
          // trigger: 'item',
          formatter: "{c}"
        },
        legend: {
          y: '5',
          data: ['严重缺陷', '危急缺陷', '一般缺陷']
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: ["12月", "1月", "2月", "3月"],
            axisLabel: {
              interval: 0
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '严重缺陷',
            type: 'line',
            stack: '总量1',
            data: [43.3, 33.1, 26.4, 32.4]
          },
          {
            name: '危急缺陷',
            type: 'line',
            stack: '总量2',
            data: [55.8, 43.4, 45.2, 43.9]
          },
          {
            name: '一般缺陷',
            type: 'line',
            stack: '总量3',
            data: [73.7, 55.1, 62.5, 59.1]
          },
        ]
      },
    }
    this.columns = [
      { title: '序号', dataIndex: 'serialNumber', key: 'serialNumber', width: "7%" },
      { title: '任务名称', dataIndex: 'taskname', key: 'taskname', width: "10%" },
      { title: '任务描述', dataIndex: 'taskdesc', key: 'taskdesc', width: "14%" ,
      onCell: () => {
        return {
          style: {
            maxWidth: "10px",
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer'
          }
        }
      },
      render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
},
      { title: '任务类型', dataIndex: 'tasktype', key: 'tasktype', width: "10%" },
      { title: '任务当前执行人', dataIndex: 'people', key: 'people', width: "10%" },
      { title: '最后操作时间', dataIndex: 'time', key: 'time', width: "13%" },
      { title: '下达时间', dataIndex: 'xiada', key: 'xiada', width: "13%" },
      { title: '下达人', dataIndex: 'xiadapeople', key: 'xiadapeople', width: "10%" },
      { title: '状态', dataIndex: 'status', key: 'status', width: "13%" },
    ]
  }
  componentDidMount() {
    this.setState({
      dataviewobj: {
        grid: {
          left: 40,
          right: 40,
          bottom: 10,
          top: 40,
          containLabel: true
        },
        legend: {
          textStyle: { color: "#FFF" }
        },
        tooltip: {
          // trigger: 'item',
          formatter: "{c}"
        },
        dataset: {
          source: [
            ['data', '严重缺陷', '危急缺陷', '一般缺陷'],
            ['12月', 43.3, 55.8, 73.7],
            ['1月', 33.1, 43.4, 55.1],
            ['2月', 26.4, 45.2, 62.5],
            ['3月', 32.4, 43.9, 59.1]
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 10      //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
        },
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 10      //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
        },
        series: [
          {
            type: 'bar', barWidth: 30,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: "#659be1" // 0% 处的颜色
                },{
                  offset: 1,
                  color: "#6bacfe" // 100% 处的颜色
                }], false)
              }
            }
          },
          { type: 'bar', barWidth: 30,
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                offset: 0,
                color: "#ed6d76" // 0% 处的颜色
              },{
                offset: 1,
                color: "#fe959d" // 100% 处的颜色
              }], false)
            }
          } },
          { type: 'bar', barWidth: 30, 
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                offset: 0,
                color: "#f1c510" // 0% 处的颜色
              },{
                offset: 1,
                color: "#fee47a" // 100% 处的颜色
              }], false)
            }
          }}
        ]
      },
    }, function () {
      //基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('threepagesmain'));
      // 绘制图表
      myChart.setOption(this.state.dataviewobj);
      // var chartName = document.getElementById('threepagesmain')
      // chartName.childNodes[0].style.height = (clientHeight-50-40)/2 -50-20 + "px";
      // chartName.childNodes[0].childNodes[0].style.height = (clientHeight-50-40)/2 -50-20 + "px";
    })
  }
  handleChange(value) {
    //console.log(value);
    if (value.label == "折线图") {
      var myChart2 = echarts.init(document.getElementById('threepagesmain'));
      myChart2.setOption(this.state.dataviewobjline);
    } else if (value.label == "柱状图") {
      var myChart2 = echarts.init(document.getElementById('threepagesmain'));
      myChart2.setOption(this.state.dataviewobj);
    }
  }

  render() {
    return (
      <div className="threepage">
        <section className="threepagetop">
          <h2 className="datatitlename">点检任务详情</h2>
          <div className="listscroll">
            <Table
              columns={this.columns}
              pagination={false}
              scroll={{ y:120 }}
              dataSource={this.state.dataSource}
            />
          </div>
        </section>
        <section className="threepagebot">
          <h2 className="datatitlename">
            缺陷统计
          </h2>
          <div className="linetypesele">
            <Select labelInValue defaultValue={{ key: '柱状图' }} onChange={this.handleChange.bind(this)}>
              <Option value="柱状图">柱状图</Option>
              <Option value="折线图">折线图</Option>
            </Select>
          </div>
          <div id="threepagesmain" style={{ height: '90%', width: "100%" }}>
          </div>
        </section>
      </div>
    )
  }
}

export default Threedata