import React from 'react';
import { Input, Spin, Icon, message, Button, Select, Calendar, Drawer, Form, Col, Row, Badge, Tabs, Tooltip, Table, DatePicker, Modal, InputNumber } from 'antd';
import axios from 'axios';
import { conversion } from '../../../converse'
import $ from 'jquery'
import locale from 'antd/lib/date-picker/locale/zh_CN'
// 引入折现图
import echarts from 'echarts';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import './style.scss'
import moment from 'moment'
import Draggable from 'react-draggable';
import 'moment/locale/zh-cn'
import { element } from 'prop-types';
import store from '../../../store';
import { getButtonLimit } from '../../../utils/getbuttonlimit'
moment.locale('zh-cn')
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { TextArea } = Input
const confirm = Modal.confirm
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<InputNumber ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
            children
          )}
      </td>
    );
  }
}

class SmallIndicators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr: [],

      //计算状态
      counflag: false,
      uname: '',

      startValue: null,
      endValue: null,
      endOpen: false,

      startValueexam: null,
      endValueexam: null,
      endOpenexam: false,


      startValuesearch: null,
      endValuesearch: null,
      endOpensearch: false,
      //页签
      activeKey: "1",
      //锅炉选择
      runningseleflag: '1',
      //运行数据
      data: [],
      data1: [],
      currentdate: '',
      classgroupseleflag: "早班",

      //运行数据测点数据右侧选择
      detaillistIdcedian: '',//
      detaillistIdcedianobj: {},//测点对象选择
      cedianoption: {},//测点曲线对象

      //搜索值
      StartDatesearch: '',
      EndDatesearch: '',
      Testidsearch: '',

      //日历初始化
      Calendarinitarr: [],
      //测点初始化
      cedianinitarr: [],

      //异常设置
      dataabnormaldatalength: 0,
      dataabnormal: [],
      dataabnormalfu: [],
      detaillistId: '',
      iptobj: {},
      addedit: "添加",

      //测点曲线
      viewcediansele: '',
      frameobj: {},

      //考核
      examarrdatalength: 0,
      examarr: [],
      detaillistIdexam: '',
      detaillistIdexamobj: {},
      detaillistIdexamobjState: '1',
      addeditname: "",
      examdetailarr: [],

      //考核详情列表
      examdetailsarrdatalength: 0,
      examdetailsarr: [],
      indexNamearr: [],//指标名称初始化
      //考核详情搜索条件
      indexNamesele: '',
      daytimesele: '',
      valuenamesele: '',

      //拖拽
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      controlledPosition: {
        x: 0, y: 0
      },
      TestIdselectflag: false,

      //异常
      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 30 - 40 - 40 - 50) / 28),
      pageIndex: 1,
      //考核结果
      pageSize1: parseInt(parseFloat(store.getState().clientHeight - 50 - 30 - 40 - 40 - 50) / 28),
      pageIndex1: 1,
      //考核详情
      pageSize2: 15,
      pageIndex2: 1,
    }

    this.datacolumns = [
      {
        title: '序号',
        dataIndex: 'IndexId',
        key: 'IndexId',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{index + 1}</span>)
        }
      },
      {
        title: '指标名称',
        dataIndex: 'IndexName',
        key: 'IndexName',
        width: '50%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '20%',
      },
      {
        title: '数值',
        editable: true,
        dataIndex: 'Score',
        key: 'Score',
        width: '20%',
      }
    ]
    this.data1columns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{index + 1}</span>)
        }
      },
      {
        title: '测点名称',
        dataIndex: 'IndexName',
        key: 'IndexName',
        width: '50%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '测点编号',
        dataIndex: 'Testid',
        key: 'Testid',
        width: '40%',
      }]
    this.abnormalcolumns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '15%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '指标名称',
        dataIndex: 'IndexName',
        key: 'IndexName',
        width: '35%',
        render: (text, record) => {
          var arr = this.state.cedianinitarr
          for (let i = 0; i < arr.length; i++) {
            if (record.Testid == arr[i].Testid) {
              return (<span>{arr[i].IndexName}</span>)
            }
          }
        }
      },
      {
        title: '异常区间',
        dataIndex: 'AbnormalInterval',
        key: 'AbnormalInterval',
        width: '50%',
        render: (text, record) => {
          return (<span>{record.StartDate + '——' + record.EndDate}</span>)
        }
      }]
    this.examcolumns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '15%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize1 + index + 1}</span>)
        }
      },
      {
        title: '考核名称',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '85%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      }]
    this.examdetailscolumns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '6%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex2 - 1) * this.state.pageSize2 + index + 1}</span>)
        }
      },
      {
        title: '开始时间',
        dataIndex: 'start_t',
        key: 'start_t',
        width: '16%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>

      },
      {
        title: '结束时间',
        dataIndex: 'end_t',
        key: 'end_t',
        width: '16%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>

      },
      {
        title: '指标名称',
        dataIndex: 'IndexName',
        key: 'IndexName',
        width: '26%',
      },
      {
        title: '班次',
        dataIndex: 'DayShift',
        key: 'DayShift',
        width: '10%',
        render: (text) => {
          if (text == "1") {
            return (<span>早班</span>)
          } else if (text == "3") {
            return (<span>晚班</span>)
          }
        }
      },
      {
        title: '得分',
        dataIndex: 'score',
        key: 'score',
        width: '10%',
        render: (text) => {
          return (<span>{(parseFloat(text)).toFixed(2)}</span>)
        }
      },
      {
        title: '值名',
        dataIndex: 'ClassValue',
        key: 'ClassValue',
        width: '6%',
      },
      {
        title: '原因',
        dataIndex: 'UL',
        key: 'UL',
        width: '10%',
        render: (text) => {
          if (text == "1") {
            return (<span>偏低</span>)
          } else if (text == "2") {
            return (<span>偏高</span>)
          } else {
            return (<span></span>)
          }
        }
      },
    ]
    this.examdetailcolumns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{index + 1}</span>)
        }
      },
      {
        title: '指标名称',
        dataIndex: 'IndexName',
        key: 'IndexName',
        width: '40%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '1值得分',
        dataIndex: 'Score1',
        key: 'Score1',
        width: '16%',
      },
      {
        title: '2值得分',
        dataIndex: 'Score2',
        key: 'Score2',
        width: '16%',
      },
      {
        title: '3值得分',
        dataIndex: 'Score3',
        key: 'Score3',
        width: '16%',
      },]
    this.range = this.range.bind(this)
    this.formatTen = this.formatTen.bind(this)
    this.formatDate = this.formatDate.bind(this)
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限', res)
    this.setState({
      buttonarr: res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(128)

    //日历
    var curDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + "1"
    //console.log(curDate)
    axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=7', {
      CurTime: curDate
    }).then(data => {
      //console.log(conversion(data))
      var arr = conversion(data)
      this.setState({
        Calendarinitarr: arr
      })
    })
    var uname = sessionStorage.getItem('uname')
    this.setState({
      uname
    })
    var date = new Date()
    var datecurrent = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    //测点列表
    axios.get('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=8').then(data => {
      //console.log(conversion(data))
      this.setState({
        cedianinitarr: conversion(data)
      })
    })

    axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/Index.aspx', {
      DayShift: this.state.classgroupseleflag == "早班" ? "1" : "3",
      Data: datecurrent
    }).then(data => {
      //console.log('运行数据', conversion(data))
      this.setState({
        data: conversion(data),
        currentdate: datecurrent
      })
    })
    axios.get('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=1').then(data => {
      //console.log('导入数据', conversion(data))
      this.setState({
        data1: conversion(data)
      })
    })
    //获取异常记录表
    axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=11', {
      StartDate: this.state.StartDatesearch,
      pageIndex: 1,
      pageSize: this.state.pageSize,
      EndDate: this.state.EndDatesearch,
      Testid: this.state.Testidsearch,
    }).then(data => {
      //console.log('异常数据', conversion(data))
      var str = data.data
      this.setState({
        dataabnormaldatalength: str.substring(12, str.indexOf(',')),
        dataabnormal: conversion(data),
        dataabnormalfu: conversion(data)
      })
    })
    //考核结果列表
    axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=12', {
      pageIndex: this.state.pageIndex1,
      pageSize: this.state.pageSize1
    }).then(data => {
      var str = data.data
      //console.log('考核结果列表', conversion(data))
      this.setState({
        examarr: conversion(data),
        examarrdatalength: str.substring(12, str.indexOf(',')),
      })
    })
  }
  componentWillReceiveProps(nextprops) {
    ////console.log(nextprops)
    this.setState({
      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 30 - 40 - 40 - 50) / 28),
      pageSize1: parseInt(parseFloat(store.getState().clientHeight - 50 - 30 - 40 - 40 - 50) / 28),
    })
  }

  //单元格编辑保存
  handleSave = row => {
    //console.log(row)
    const newData = [...this.state.data];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ data: newData }, function () {
      // //console.log(this.state.data)
    });
  };
  //运行数据早晚班保存
  classgrouprundataseve() {
    var DayShift = this.state.classgroupseleflag == "早班" ? "1" : "3"
    var curDate = this.state.currentdate
    var arr = this.state.data
    var idstr = ''
    var IndexIdstr = ''
    var IndexNamestr = ''
    var unitstr = ''
    var Scorestr = ''

    var curDatechange = curDate.split('-')[0] + '-' + curDate.split('-')[1] + '-' + '1'

    for (let i = 0; i < arr.length; i++) {
      if (i == arr.length - 1) {
        idstr = idstr + arr[i].id
        IndexIdstr = IndexIdstr + arr[i].IndexId
        IndexNamestr = IndexNamestr + arr[i].IndexName
        unitstr = unitstr + arr[i].Unit
        Scorestr = Scorestr + arr[i].Score
      } else {
        idstr = idstr + arr[i].id + ','
        IndexIdstr = IndexIdstr + arr[i].IndexId + ','
        IndexNamestr = IndexNamestr + arr[i].IndexName + ','
        unitstr = unitstr + arr[i].Unit + ','
        Scorestr = Scorestr + arr[i].Score + ','
      }
    }
    //日历数据
    axios.post('/URL/IntelligentDecision/PerformanceAppraisal/Operate.aspx?flag=1', {
      Date: curDate,
      DayShift: DayShift,
      id: idstr,
      IndexId: IndexIdstr,
      indexName: IndexNamestr,
      unit: unitstr,
      Score: Scorestr
    }).then(data => {
      //console.log(data)
      if (data.data == true) {
        axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=7', {
          CurTime: curDatechange
        }).then(data => {
          //console.log(conversion(data))
          var arr = conversion(data)
          this.setState({
            Calendarinitarr: arr
          }, function () {
            message.success('保存成功！')
          })
        })
      }
    })
  }

  //导出报表
  exportbaobiao() {
    var factory = sessionStorage.getItem('factory')
    var RecordDate = this.state.RecordDate
    window.open(`http://47.97.112.136:8080/WebReport/ReportServer?reportlet=B-SPPs/DailyReport.cpt&company=${encodeURI(factory)}&RecordDate=${RecordDate}`)
  }

  onClose = () => {
    this.setState({
      visible: false,
    }, function () {
      this.props.form.resetFields();
    });
  };
  onPanelChange(value, mode) {
    console.log(value, mode);
    if (mode == "month") {
      var curDate = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + "1";
      var currentdate = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate();
      //日历数据
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=7', {
        CurTime: curDate
      }).then(data => {
        //console.log(conversion(data))
        var arr = conversion(data)
        this.setState({
          Calendarinitarr: arr,
          currentdate: currentdate,
        })
      })

      //运行数据
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/Index.aspx', {
        DayShift: this.state.classgroupseleflag == "早班" ? "1" : "3",
        Data: currentdate
      }).then(data => {
        //console.log('运行数据', conversion(data))
        this.setState({
          data: conversion(data),
          currentdate: currentdate
        })
      })
    }
  }
  onDateSelect(date) {
    //console.log(date)
    var date1 = date._d.getFullYear() + '-' + (date._d.getMonth() + 1) + '-' + date._d.getDate();
    axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/Index.aspx', {
      DayShift: this.state.classgroupseleflag == "早班" ? "1" : "3",
      Data: date1
    }).then(data => {
      //console.log('运行数据', conversion(data))
      this.setState({
        data: conversion(data),
        currentdate: date1
      })
    })

  }
  dateCellRenderfun(value) {
    var arr = this.state.Calendarinitarr
    if (arr[0]) {
      var monthsele = new Date((arr[0].Date)).getMonth()
      if (value.month() == monthsele) {
        var valuetotime = value.date()
        var DraftName = []
        var count = 0;
        for (let i = 0; i < arr.length; i++) {
          if (i == (arr.length - 1)) {
            if ((new Date((arr[i].Date)).getDate()) == valuetotime) {
              count = count + 1;
              DraftName.push(arr[i].DayShift)

              if (count == 2) {
                return (
                  <span className="twostatus">
                    <Badge status="success" />
                    <Badge status="success" />
                  </span>
                );
              } else if (count == 1) {
                if (DraftName[0] == "1") {
                  return (
                    <span className="twostatus">
                      <Badge status="success" />
                      <Badge status="default" />
                    </span>
                  );
                } else {
                  return (
                    <span className="twostatus">
                      <Badge status="default" />
                      <Badge status="success" />
                    </span>
                  );
                }
              } else if (count == 0) {
                return (
                  <span className="twostatus">
                    <Badge status="default" />
                    <Badge status="default" />
                  </span>
                );
              }
            } else {
              if (count == 2) {
                return (
                  <span className="twostatus">
                    <Badge status="success" />
                    <Badge status="success" />
                  </span>
                );
              } else if (count == 1) {
                if (DraftName[0] == "1") {
                  return (
                    <span className="twostatus">
                      <Badge status="success" />
                      <Badge status="default" />
                    </span>
                  );
                } else {
                  return (
                    <span className="twostatus">
                      <Badge status="default" />
                      <Badge status="success" />
                    </span>
                  );
                }
              } else if (count == 0) {
                return (
                  <span className="twostatus">
                    <Badge status="default" />
                    <Badge status="default" />
                  </span>
                );
              }
            }
          } else {
            if ((new Date((arr[i].Date)).getDate()) == valuetotime) {
              count = count + 1;
              DraftName.push(arr[i].DayShift)
            }
          }
        }
      }
    } else {
      var monthsele = new Date(this.state.currentdate).getMonth()
      if (value.month() == monthsele) {
        return (
          <span className="twostatus">
            <Badge status="default" />
            <Badge status="default" />
          </span>
        );
      }
    }
  }
  //锅炉选择
  runningsele(key) {
    this.setState({
      runningseleflag: key
    })
  }
  //班组选择
  classgroupsele(key) {
    //console.log(key)
    this.setState({
      classgroupseleflag: key
    }, function () {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/Index.aspx', {
        DayShift: this.state.classgroupseleflag == "早班" ? "1" : "3",
        Data: this.state.currentdate
      }).then(data => {
        //console.log('运行数据', conversion(data))
        this.setState({
          data: conversion(data)
        })
      })
      axios.get('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=1').then(data => {
        //console.log('导入数据', conversion(data))
        this.setState({
          data1: conversion(data)
        })
      })
    })
  }
  onBegintimeChange(data, dataString) {
    //console.log(data, dataString)
    this.setState({
      StartDatesearch: dataString
    })
  }
  onEndtimeChange(data, dataString) {
    //console.log(data, dataString)
    this.setState({
      EndDatesearch: dataString
    })
  }
  //测点输入
  onIptChange(e) {
    //console.log(e)
    this.setState({
      Testidsearch: e.target.value
    })
  }
  //选中
  senddatacedian(record) {
    //console.log(record)
    this.setState({
      detaillistIdcedian: record.Testid,
      detaillistIdcedianobj: record,
      counflag: true
    }, function () {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=10', {
        TestId: this.state.detaillistIdcedian,
        DayShift: this.state.classgroupseleflag == "早班" ? "1" : "3",
        Date: this.state.currentdate
      }).then(data => {
        //console.log('测点曲线数据', conversion(data))
        var arr = conversion(data)
        var datex = []
        var data = []
        for (let i = 0; i < arr.length; i++) {
          datex.push(arr[i].time1.split(' ')[1])
          data.push((parseFloat(arr[i].data1)).toFixed(2))
        }
        //console.log(datex)
        var cedianoption = {
          tooltip: {
            trigger: 'axis',
            position: function (pt) {
              return [pt[0], '10%'];
            }
          },
          // title: {
          //   left: 'center',
          //   text: '大数据量面积图',
          // },
          grid: {
            top: "10%",
            bottom: "20%",
            left: "10%",
            right: "10%"
          },
          toolbox: {
            show: false,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: datex,
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            min: function (value) {
              return value.min - 1;
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          dataZoom: [{
            type: 'inside',
            start: 0,
            end: 10
          }, {
            start: 0,
            end: 10,
            handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
            handleSize: '80%',
            handleStyle: {
              color: '#fff',
              shadowBlur: 3,
              // shadowColor: 'rgba(0, 0, 0, 0.6)',
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          }],
          series: [
            {
              // name: '模拟数据',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: 'rgb(255, 70, 131)'
              },
              // areaStyle: {
              //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              //     offset: 0,
              //     color: 'rgb(255, 158, 68)'
              //   }, {
              //     offset: 1,
              //     color: 'rgb(255, 70, 131)'
              //   }])
              // },
              data: data
            }
          ]
        };
        this.setState({
          cedianoption,
        }, function () {
          this.refs.cepointechartstulinesmallindicators.style.display = "block"

          var myChart1 = echarts.init(document.getElementById('echartstulinecedian'));
          // 绘制图表
          myChart1.setOption(this.state.cedianoption);
          this.setState({
            counflag: false
          })
        })

      })
    })
  }
  //关闭
  closeechartstuzhu() {
    this.refs.cepointechartstulinesmallindicators.style.display = "none"
  }
  setRowClassNamecedian = (record) => {
    return record.Testid === this.state.detaillistIdcedian ? 'clickRowStyl' : 'clickRowSty';
  }
  //异常搜索
  abnormalsearch() {
    //获取异常记录表
    axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=11', {
      StartDate: this.state.StartDatesearch,
      pageIndex: 1,
      pageSize: this.state.pageSize,
      EndDate: this.state.EndDatesearch,
      Testid: this.state.Testidsearch,
    }).then(data => {
      //console.log('异常数据', conversion(data))
      var str = data.data
      this.setState({
        dataabnormaldatalength: str.substring(12, str.indexOf(',')),
        dataabnormal: conversion(data),
        dataabnormalfu: conversion(data)
      })
    })
  }
  //选中
  senddata(record) {
    //console.log(record)
    this.props.form.resetFields()
    this.setState({
      addedit: "修改",
      addeditname: "异常",
    })
    axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AbnormalSetting.aspx?flag=3', {
      id: record.id
    }).then(data => {
      console.log('异常信息数据', conversion(data))
      this.setState({
        detaillistId: record.id,
        iptobj: conversion(data)[0],
        startValue: conversion(data)[0].StartDate,
        endValue: conversion(data)[0].EndDate,
      })
    })
  }
  setRowClassName = (record) => {
    return record.id === this.state.detaillistId ? 'clickRowStyl' : 'clickRowSty';
  }
  //异常列表添加
  abnormallistadd() {
    this.props.form.resetFields()
    this.setState({
      addedit: "添加",
      addeditname: "异常",
      iptobj: {},
      detaillistId: "",
      TestIdselectflag: false
    }, function () {
      this.props.form.resetFields()
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      for (var i in values) {
        var value = values[i]
        if (value == undefined) {
          values[i] = ""
        } else if (value.label) {
          values[i] = value.label
        } else if (value._d) {
          values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate() + ' ' + value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
        } else {
          values[i] = values[i]
        }
      }
      if (this.state.addedit == "添加") {
        if (this.state.addeditname == "考核") {
          var obj = {}
          obj.Remark = values.Remark1
          obj.StartDate = values.StartDate1
          obj.EndDate = values.EndDate1
          if (obj.Remark && obj.StartDate && obj.EndDate) {
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentReport.aspx?flag=1', obj).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                //考核结果列表
                //考核结果列表
                axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=12', {
                  pageIndex: 1,
                  pageSize: this.state.pageSize1
                }).then(data => {
                  var str = data.data
                  //console.log('考核结果列表', conversion(data))
                  this.setState({
                    examarr: conversion(data),
                    examarrdatalength: str.substring(12, str.indexOf(',')),
                    visible: false,
                    pageIndex1: 1
                  }, function () {
                    message.success('添加成功！')
                  })
                })
              }
            })
          }
        } else if (this.state.addeditname == "异常") {
          var obj = {}
          obj.Operator = this.state.uname
          obj.Remark = values.Remark
          obj.StartDate = values.StartDate
          obj.EndDate = values.EndDate
          obj.Boilernum = values.Boilernum
          obj.Testid = values.Testid
          obj.Turbinenum = values.Turbinenum
          if (obj.StartDate && obj.EndDate) {
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AbnormalSetting.aspx?flag=1', obj).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                //获取异常记录表
                axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=11', {
                  StartDate: this.state.StartDatesearch,
                  pageIndex: 1,
                  pageSize: this.state.pageSize,
                  EndDate: this.state.EndDatesearch,
                  Testid: this.state.Testidsearch,
                }).then(data => {
                  //console.log('异常数据', conversion(data))
                  var str = data.data
                  this.setState({
                    dataabnormaldatalength: str.substring(12, str.indexOf(',')),
                    dataabnormal: conversion(data),
                    dataabnormalfu: conversion(data)
                  }, function () {
                    message.success('添加成功！')
                  })
                })
              }
            })
          }
        }

      } else if (this.state.addedit == "修改") {
        if (this.state.addeditname == "异常") {
          var obj = {}
          obj.Operator = this.state.uname
          obj.Remark = values.Remark
          obj.StartDate = values.StartDate
          obj.EndDate = values.EndDate
          obj.Boilernum = values.Boilernum
          obj.Testid = values.Testid
          obj.Turbinenum = values.Turbinenum
          obj.id = this.state.detaillistId
          if (obj.StartDate && obj.EndDate) {
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AbnormalSetting.aspx?flag=4', obj).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                //获取异常记录表
                axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=11', {
                  StartDate: this.state.StartDatesearch,
                  pageIndex: 1,
                  pageSize: this.state.pageSize,
                  EndDate: this.state.EndDatesearch,
                  Testid: this.state.Testidsearch,
                }).then(data => {
                  //console.log('异常数据', conversion(data))
                  this.setState({
                    dataabnormal: conversion(data),
                    dataabnormalfu: conversion(data)
                  }, function () {
                    message.success('修改成功！')
                  })
                })
              }
            })
          }
        }
      }
    });
  };

  //异常列表删除
  abnormallistdel() {
    var _this = this
    if (this.state.detaillistId) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AbnormalSetting.aspx?flag=5', {
            id: _this.state.detaillistId
          }).then(data => {
            //console.log(data)
            if (data.data == true || data.data.indexOf('true') > -1) {
              //获取异常记录表
              axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=11', {
                StartDate: _this.state.StartDatesearch,
                pageIndex: 1,
                pageSize: _this.state.pageSize,
                EndDate: _this.state.EndDatesearch,
                Testid: _this.state.Testidsearch,
              }).then(data => {
                //console.log('异常数据', conversion(data))
                var str = data.data
                _this.setState({
                  dataabnormaldatalength: str.substring(12, str.indexOf(',')),
                  dataabnormal: conversion(data),
                  pageIndex:1,
                  dataabnormalfu: conversion(data),
                  detaillistId: "",
                  iptobj: {}
                }, function () {
                  message.success('删除成功！')
                })
              })
            }
          })
        },
        onCancel() {
          _this.setState({
            // detaillistId: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }
  //查看测点曲线
  updatecedianline() {
    if (this.state.detaillistId) {
      this.setState({
        counflag: true
      })
      //测点曲线数据
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=9', {
        PD_Table: this.state.viewcediansele,
        start_time: this.state.iptobj.StartDate,
        end_time: this.state.iptobj.EndDate
      }).then(data => {
        //console.log(conversion(data))
        var arr = conversion(data)
        var datex = []
        var data = []
        for (let i = 0; i < arr.length; i++) {
          datex.push(arr[i].time1)
          data.push((parseFloat(arr[i].data1)).toFixed(2))
        }
        var frameobj = {
          tooltip: {
            trigger: 'axis',
            position: function (pt) {
              return [pt[0], '10%'];
            }
          },
          grid: {
            top: "5%",
            left: "10%",
            right: "10%",
            bottom: "0%"
          },
          // title: {
          //   left: 'center',
          //   text: '大数据量面积图',
          // },
          toolbox: {
            show: false,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: datex,
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            min: function (value) {
              return value.min - 1;
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          dataZoom: [{
            type: 'inside',
            start: 0,
            end: 10
          }, {
            start: 0,
            end: 10,
            handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
            handleSize: '80%',
            handleStyle: {
              color: '#fff',
              shadowBlur: 3,
              shadowColor: 'rgba(0, 0, 0, 0.6)',
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          }],
          series: [
            {
              // name: '模拟数据',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: 'rgb(255, 70, 131)'
              },
              data: data
            }
          ]
        };
        this.setState({
          frameobj,
        }, function () {
          //基于准备好的dom，初始化echarts实例
          var myChart2 = echarts.init(document.getElementById('cedianline'));
          //绘制图表
          myChart2.setOption(this.state.frameobj);
          this.setState({
            counflag: false
          })
        })
      })
    } else {
      message.error('请选择！')
    }
  }
  //选中
  senddataexam(record) {
    //console.log(record)
    this.setState({
      detaillistIdexam: record.id,
      detaillistIdexamobj: record,
      detaillistIdexamobjState: record.State
    }, function () {
      //考核结果列表
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentReport.aspx?flag=3', {
        Rid: this.state.detaillistIdexam
      }).then(data => {
        //console.log('导入列表', conversion(data))
        this.setState({
          examdetailarr: conversion(data)
        })
      })
      //指标名称
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentReport.aspx?flag=6', {
        Rid: this.state.detaillistIdexam
      }).then(data => {
        //console.log('指标名称', conversion(data))
        this.setState({
          indexNamearr: conversion(data)
        })
      })
    })
  }
  setRowClassNameexam = (record) => {
    return record.id === this.state.detaillistIdexam ? 'clickRowStyl' : 'clickRowSty';
  }
  //考核结果给
  examansweradd() {
    this.setState({
      visible: true,
      addeditname: "考核",
      addedit: "添加"
    })
  }
  //计算历史考核
  examanswercount() {
    this.setState({
      counflag: true
    })
    if (this.state.detaillistIdexam) {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=6', {
        Rid: this.state.detaillistIdexam,
        StartDate: this.state.detaillistIdexamobj.StartDate,
        EndDate: this.state.detaillistIdexamobj.EndDate
      }).then(data => {
        //console.log(data)
        if (data.data == true) {
          //考核结果列表
          axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=12', {
            pageIndex: 1,
            pageSize: this.state.pageSize1
          }).then(data => {
            var str = data.data
            //console.log('考核结果列表', conversion(data))
            this.setState({
              examarr: conversion(data),
              examarrdatalength: str.substring(12, str.indexOf(',')),
              detaillistIdexam: "",
              counflag: false,
              pageIndex1: 1
            })
          })
        }
      })
    } else {
      message.error('请选择!')
    }
  }
  examanswerdel() {
    var _this = this
    if (this.state.detaillistIdexam) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentReport.aspx?flag=5', {
            Rid: _this.state.detaillistIdexam
          }).then(data => {
            //console.log(data)
            if (data.data == true || data.data.indexOf('true') > -1) {
              //考核结果列表

              axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=12', {
                pageIndex: 1,
                pageSize: _this.state.pageSize1
              }).then(data => {
                var str = data.data
                //console.log('考核结果列表', conversion(data))
                _this.setState({
                  examarr: conversion(data),
                  examarrdatalength: str.substring(12, str.indexOf(',')),
                  detaillistIdexam: "",
                  pageIndex1: 1
                }, function () {
                  message.success('删除成功！')
                })
              })
            }
          })
        },
        onCancel() {
          _this.setState({
            // detaillistIdexam: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }
  //考核详情
  examdetail() {
    if (this.state.detaillistIdexam) {
      this.refs.examdetail.style.display = "block"
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=13', {
        rid: this.state.detaillistIdexam,
        IndexName: '',
        DraftName: '',
        ValueName: '',
        pageSize: this.state.pageSize2,
        pageIndex: 1,
      }).then(data => {
        //console.log('考核详情', conversion(data))
        var str = data.data
        //console.log('考核详情', conversion(data))
        this.setState({
          examdetailsarr: conversion(data),
          examdetailsarrdatalength: str.substring(12, str.indexOf(',')),
        })
      })
    } else {
      message.error('请选择！')
    }
  }
  closetocalcucate() {
    this.refs.examdetail.style.display = "none"
    this.setState({
      examdetailsarr: []
    })
  }
  //考核指标详情搜索
  examdetaildatasearch() {
    this.setState({
      counflag: true,
      pageIndex2: 1
    }, function () {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=13', {
        rid: this.state.detaillistIdexam,
        IndexName: this.state.indexNamesele,
        DraftName: this.state.daytimesele,
        ValueName: this.state.valuenamesele,
        pageSize: this.state.pageSize2,
        pageIndex: 1,
      }).then(data => {
        var str = data.data
        //console.log('考核详情', conversion(data))
        this.setState({
          examdetailsarr: conversion(data),
          counflag: false,
          examdetailsarrdatalength: str.substring(12, str.indexOf(',')),
        })
      })
    })
  }
  //报表
  printbaobiao() {
    if (this.state.detaillistIdexam) {
      var factory = sessionStorage.getItem('factory')
      var Rid = this.state.detaillistIdexam
      var StartDate = this.state.detaillistIdexamobj.StartDate
      var EndDate = this.state.detaillistIdexamobj.EndDate
      window.open(`http://47.97.112.136:8080/WebReport/ReportServer?reportlet=B-SPPs/Monthly_result.cpt&company=${encodeURI(factory)}&Rid=${Rid}&StartDate=${StartDate}&EndDate=${EndDate}`)
    } else {
      message.error('请选择！')
    }
  }
  //考核详情报表
  printdetailbaobiao() {
    if (this.state.detaillistIdexam) {
      var factory = sessionStorage.getItem('factory')
      var Rid = this.state.detaillistIdexam
      var indexname = this.state.indexNamesele
      var DayShift = this.state.daytimesele
      var ClassValue = this.state.valuenamesele

      var strQuery = "  where Version='" + Rid + "' ";
      if (indexname != "") {
        strQuery += " And Testid='" + indexname + "'";
      }
      if (DayShift != "") {
        strQuery += " And DayShift='" + DayShift + "'";
      }
      if (ClassValue != "") {
        strQuery += " And ClassValue ='" + ClassValue + "'";
      }
      window.open(`http://47.97.112.136:8080/WebReport/ReportServer?reportlet=B-SPPs/Result_Details.cpt&company=${encodeURI(factory)}&Rid=${Rid}&sqlC=${strQuery}`)
    }
  }
  //
  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }

  handleDrag(e, ui) {
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  //异常起始终止日期
  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    // return startValue.valueOf() >= endValue.valueOf();
    return new Date(endValue).getTime() + 86400000 <= new Date(startValue).getTime()
  };

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    // return endValue.valueOf() <= startValue.valueOf();
    return new Date(endValue).getTime() <= new Date(startValue).getTime() - 86400000
  };

  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  disabledStartDateTime(value) {
    var endValue = this.state.endValue
    endValue = this.formatDate(endValue)
    var valuecur = this.formatDate(value._d)
    if (endValue.split(' ')[0] == valuecur.split(' ')[0]) {
      var h = endValue.split(' ')[1].split(':')[0]
      return {
        disabledHours: () => this.range(h, 24),
      };
    }
  }
  disabledEndDateTime(value) {
    var startValue = this.state.startValue
    startValue = this.formatDate(startValue)
    var valuecur = this.formatDate(value._d)
    if (startValue.split(' ')[0] == valuecur.split(' ')[0]) {
      var h = startValue.split(' ')[1].split(':')[0]
      return {
        disabledHours: () => this.range(0, h),
      }
    }
  }
  formatTen(num) {
    return num > 9 ? (num + "") : ("0" + num);
  }
  formatDate(date) {
    var date = new Date(date)
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    return year + "-" + this.formatTen(month) + "-" + this.formatTen(day) + " " + this.formatTen(hour) + ":" + this.formatTen(minute) + ":" + this.formatTen(second);
  }
  onChange = (field, value) => {
    this.setState({
      [field]: this.formatDate(value._d),
    });
  };

  onStartChange = value => {
    this.onChange('startValue', value);
  };

  onEndChange = value => {
    this.onChange('endValue', value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };



  //异常
  pagechange(page) {
    console.log(page)
    this.setState({
      pageIndex: page.current,
    }, function () {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=11', {
        StartDate: this.state.StartDatesearch,
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize,
        EndDate: this.state.EndDatesearch,
        Testid: this.state.Testidsearch,
      }).then(data => {
        this.setState({
          dataabnormal: conversion(data),
          dataabnormalfu: conversion(data)
        })
      })
    })
  }
  //考核
  pagechange1(page) {
    console.log(page)
    this.setState({
      pageIndex1: page.current,
    }, function () {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentReport.aspx?flag=2', {
        pageIndex: this.state.pageIndex1,
        pageSize: this.state.pageSize1
      }).then(data => {
        //console.log('考核结果列表', conversion(data))
        this.setState({
          examarr: conversion(data)
        })
      })
    })
  }
  //考核详情
  pagechange2(page) {
    console.log(page)
    this.setState({
      pageIndex2: page.current,
    }, function () {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=13', {
        rid: this.state.detaillistIdexam,
        IndexName: this.state.indexNamesele,
        DraftName: this.state.daytimesele,
        ValueName: this.state.valuenamesele,
        pageSize: this.state.pageSize2,
        pageIndex: this.state.pageIndex2,
      }).then(data => {
        //console.log('考核详情', conversion(data))
        this.setState({
          examdetailsarr: conversion(data)
        })
      })
    })
  }


  //起始终止
  disabledStartDateexam = startValueexam => {
    const { endValueexam } = this.state;
    if (!startValueexam || !endValueexam) {
      return false;
    }
    return startValueexam.valueOf() > endValueexam.valueOf();
  };

  disabledEndDateexam = endValueexam => {
    const { startValueexam } = this.state;
    if (!endValueexam || !startValueexam) {
      return false;
    }
    return endValueexam.valueOf() <= startValueexam.valueOf();
  };

  onChangeexam = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChangeexam = value => {
    this.onChangeexam('startValueexam', value);
  };

  onEndChangeexam = value => {
    //console.log(value)
    var curDate = new Date().getTime()
    var selDate = new Date(value._d).getTime()
    //console.log(curDate, selDate)
    if (curDate < selDate) {

    } else {
      this.onChangeexam('endValueexam', value);
    }

  };

  handleStartOpenChangeexam = open => {
    if (!open) {
      this.setState({ endOpenexam: true });
    }
  };

  handleEndOpenChangeexam = open => {
    this.setState({ endOpenexam: open });
  };

  //异常列表搜索时间限制

  //起始终止
  disabledStartDatesearch = startValuesearch => {
    const { endValuesearch } = this.state;
    if (!startValuesearch || !endValuesearch) {
      return false;
    }
    return startValuesearch.valueOf() > endValuesearch.valueOf();
  };

  disabledEndDatesearch = endValuesearch => {
    const { startValuesearch } = this.state;
    if (!endValuesearch || !startValuesearch) {
      return false;
    }
    return endValuesearch.valueOf() <= startValuesearch.valueOf();
  };

  onChangesearch = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChangesearch = value => {
    this.onChangesearch('startValuesearch', value);
  };

  onEndChangesearch = value => {
    //console.log(value)
    var curDate = new Date().getTime()
    var selDate = new Date(value._d).getTime()
    //console.log(curDate, selDate)
    if (curDate < selDate) {

    } else {
      this.onChangesearch('endValuesearch', value);
    }

  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpensearch: true });
    }
  };

  handleEndOpenChangesearch = open => {
    this.setState({ endOpensearch: open });
  };
  render() {
    const { startValue, endValue, endOpen, startValueexam, endValueexam, endOpenexam, endValuesearch, endOpensearch } = this.state;
    const { getFieldDecorator } = this.props.form;
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const datacolumns = this.datacolumns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="smallindicators" style={{ height: clientHeight - 50 + 'px' }}>
        {/* <Draggable
          position={this.state.controlledPosition}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDrag.bind(this)}
          onStop={this.handleStop.bind(this)}> */}
        <div className="cepointechartstulinesmallindicators" ref="cepointechartstulinesmallindicators">
          <h2>{this.state.detaillistIdcedianobj.IndexName + ' ' + this.state.detaillistIdcedianobj.Unit}图示 <span onClick={this.closeechartstuzhu.bind(this)}>x</span></h2>
          <div id="echartstulinecedian" style={{ width: "100%", height: "86%" }}>

          </div>
        </div>
        {/* </Draggable> */}

        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
        <Tabs type="card" onChange={(key) => {
          this.setState({ activeKey: key }, function () {
            var id = key == "1" ? 128 : key == "2" ? 129 : 130
            this.getButtonLimitarr(id)
          })
        }} activeKey={this.state.activeKey}>
          <TabPane tab="运行数据" key="1">
            <div className="runningdata" style={{ height: clientHeight - 50 - 36 + 'px' }}>
              <div className="runningdataleft">
                <h3 className="h3title">时间选择</h3>
                <div className="calenderrili" style={{ width: "300px", border: '1px solid #d9d9d9', borderRadius: 4 }}>
                  <Calendar fullscreen={false}
                    locale={locale}
                    dateCellRender={this.dateCellRenderfun.bind(this)}
                    onPanelChange={this.onPanelChange.bind(this)}
                    onSelect={this.onDateSelect.bind(this)}
                  />
                </div>
                {/* <span className="runningsele">运行锅炉</span>
                <div className="runaction">
                  <Button onClick={this.runningsele.bind(this, '1')}
                    style={{
                      background: this.state.runningseleflag == "1" ? "#00b7ee" : "none",
                      border: this.state.runningseleflag == "1" ? "1px solid #00b7ee" : "1px solid #fff"
                    }}
                  >#1锅炉</Button>
                  <Button onClick={this.runningsele.bind(this, '2')}
                    style={{
                      background: this.state.runningseleflag == "2" ? "#00b7ee" : "none",
                      border: this.state.runningseleflag == "2" ? "1px solid #00b7ee" : "1px solid #fff"
                    }}
                  >#2锅炉</Button>
                </div> */}
              </div>
              <div className="runningdataright">
                <h3 className="h3title">数据录入</h3>
                <div className="runningdatarightlist" style={{ height: clientHeight - 50 - 70 + 'px' }}>

                  <div className="runningdatarightlistl">
                    <div className="classgroupsele">
                      <span>班组</span>
                      <Button onClick={this.classgroupsele.bind(this, '早班')}
                        style={{
                          background: this.state.classgroupseleflag == "早班" ? "#00b7ee" : "none",
                          border: this.state.classgroupseleflag == "早班" ? "1px solid #00b7ee" : "1px solid #fff"
                        }}
                      >早班</Button>
                      <Button onClick={this.classgroupsele.bind(this, '晚班')}
                        style={{
                          background: this.state.classgroupseleflag == "晚班" ? "#00b7ee" : "none",
                          border: this.state.classgroupseleflag == "晚班" ? "1px solid #00b7ee" : "1px solid #fff"
                        }}
                      >晚班</Button>
                    </div>
                    <div className="listscroll" style={{ height: clientHeight - 50 - 70 - 30 - 60 + 'px' }}>
                      <Table
                        size="small"
                        components={components}
                        rowClassName={() => 'editable-row'}
                        dataSource={this.state.data}
                        columns={datacolumns}
                        pagination={false}
                        scroll={{ y: clientHeight - 50 - 70 - 30 - 60 - 30 }}
                      />
                    </div>
                    <span className="datasave"><Button style={{ display: this.state.buttonarr.indexOf('保存') > -1 ? "inline-block" : "none" }} onClick={this.classgrouprundataseve.bind(this)}>保存</Button></span>
                  </div>
                  <div className="runningdatarightlistr">
                    <div className="classgroupsele"></div>
                    <div className="listscroll" style={{ height: clientHeight - 50 - 70 - 30 - 60 + 'px' }}>
                      <Table
                        size="small"
                        dataSource={this.state.data1}
                        columns={this.data1columns}
                        pagination={false}
                        onRow={(record) => {
                          return {
                            onClick: this.senddatacedian.bind(this, record)
                          }
                        }}
                        rowClassName={this.setRowClassNamecedian}
                        scroll={{ y: clientHeight - 50 - 70 - 30 - 60 - 30 }}
                      />
                    </div>
                    <span className="datasave"></span>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="异常设置" key="2">
            <div className="abnormalconfig" style={{ height: clientHeight - 50 - 36 + 'px' }}>
              <div className="abnormalconfigleft">
                <h3 className="h3title">异常列表
                   <div className="action">
                    <Button style={{ display: this.state.buttonarr.indexOf('添加') > -1 ? "inline-block" : "none" }} onClick={this.abnormallistadd.bind(this)}>添加</Button>
                    <Button style={{ display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }} onClick={this.abnormallistdel.bind(this)}>删除</Button>
                  </div>
                </h3>
                <div className="searchlistcondition">
                  <div className="left">
                    <span className="searchlistconditions">
                      <span className="conditions">起始日期</span>
                      <DatePicker
                        disabledDate={this.disabledStartDatesearch}
                        placeholder="请选择"
                        onChange={this.onStartChangesearch}
                        onOpenChange={this.handleStartOpenChangesearch}
                        showTime
                        locale={locale}
                        format="YYYY-MM-DD HH:mm:ss"
                      // onChange={this.onBegintimeChange.bind(this)} 
                      /></span>
                    <span className="searchlistconditions">
                      <span className="conditions">终止日期</span>
                      <DatePicker
                        locale={locale}
                        disabledDate={this.disabledEndDatesearch}
                        onChange={this.onEndChangesearch}
                        onOpenChange={this.handleEndOpenChangesearch}
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        // onChange={this.onEndtimeChange.bind(this)} 
                        placeholder="请选择" /></span>
                    <span className="searchlistconditions">
                      <span className="conditions">测点编号</span>
                      <Input onChange={this.onIptChange.bind(this)} /></span>
                  </div>
                  <div className="right">
                    <Button onClick={this.abnormalsearch.bind(this)}>搜索</Button>
                  </div>
                </div>
                <div className="listpage" style={{ height: clientHeight - 50 - 70 - 40 + 'px' }}>
                  <Table
                    size="small"
                    dataSource={this.state.dataabnormal}
                    columns={this.abnormalcolumns}
                    // pagination={false}
                    pagination={{
                      pageSize: this.state.pageSize,
                      current: this.state.pageIndex,
                      total: Number(this.state.dataabnormaldatalength)
                    }}
                    onChange={this.pagechange.bind(this)}
                    onRow={(record) => {
                      return {
                        onClick: this.senddata.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassName}
                  // scroll={{ y: clientHeight - 50 - 30 - 40 - 40 - 50 }}
                  />
                </div>
              </div>
              <div className="abnormalconfigright">
                <h3 className="h3title">异常录入</h3>
                <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="汽机编号">
                        {getFieldDecorator('Turbinenum', {
                          initialValue: this.state.iptobj['Turbinenum'],
                          rules: [{ required: false, message: '请输入汽机编号' }],
                        })(
                          <Select
                            disabled={
                              // (thiss.props.form.getFieldValue('Tetid')) || 
                              this.state.TestIdselectflag == true ||
                                this.state.addedit == "修改" ?
                                true : false
                            }
                            style={{
                              background:
                                // (this.props.form.getFieldValue('Testid')) || 
                                this.state.TestIdselectflag == true ||
                                  this.state.addedit == "修改" ?
                                  "#ccc" : "none"
                            }}
                          >
                            <Option value="0" key="0">无</Option>
                            <Option value="1" key="1">#1汽机</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="锅炉编号">
                        {getFieldDecorator('Boilernum', {
                          initialValue: this.state.iptobj['Boilernum'],
                          rules: [{ required: false, message: '请输入锅炉编号' }],
                        })(
                          <Select
                            disabled={
                              // (thiss.props.form.getFieldValue('Tetid')) || 
                              this.state.TestIdselectflag == true ||
                                this.state.addedit == "修改" ?
                                true : false
                            }
                            style={{
                              background:
                                // (this.props.form.getFieldValue('Testid')) || 
                                this.state.TestIdselectflag == true ||
                                  this.state.addedit == "修改" ?
                                  "#ccc" : "none"
                            }}
                          >
                            <Option value="0" key="0">无</Option>
                            <Option value="1" key="1">#1锅炉</Option>
                            <Option value="2" key="2">#2锅炉</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="测点编号">
                        {getFieldDecorator('Testid', {
                          initialValue: this.state.iptobj['Testid'],
                          rules: [{ required: false, message: '请输入测点编号' }],
                        })(
                          <Select
                            onChange={(v) => {
                              if (v == "") {

                              } else {
                                this.setState({
                                  TestIdselectflag: true
                                })
                              }
                            }}
                            disabled={
                              this.state.addedit == "添加" ?
                                (this.props.form.getFieldValue('Turbinenum') &&
                                  this.props.form.getFieldValue('Turbinenum') != "0") ||
                                  (this.props.form.getFieldValue('Boilernum') &&
                                    this.props.form.getFieldValue('Boilernum') != "0")
                                  ? true : false : ""}
                            style={{
                              background:

                                this.state.addedit == "添加" ?
                                  (this.props.form.getFieldValue('Turbinenum') &&
                                    this.props.form.getFieldValue('Turbinenum') != "0") ||
                                    (this.props.form.getFieldValue('Boilernum') &&
                                      this.props.form.getFieldValue('Boilernum') != "0")
                                    ? "#ccc" : "none" : ""
                            }}
                          >
                            <Option value="" key=""></Option>
                            {
                              this.state.cedianinitarr.map((item, index) => {
                                return (<Option value={item.Testid} key={item.Testid}>{item.IndexName}</Option>)
                              })
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="起始时间">
                        {getFieldDecorator('StartDate', {
                          initialValue: this.state.iptobj['StartDate'] ? moment(this.state.iptobj['StartDate']) : null,
                          rules: [{ required: true, message: '请输入起始时间' }],
                        })(
                          <DatePicker
                            locale={locale}
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder="请选择"
                          // disabledDate={this.disabledStartDate.bind(this)}
                          // onChange={this.onStartChange.bind(this)}
                          // disabledTime={this.disabledStartDateTime.bind(this)}
                          // onOpenChange={this.handleStartOpenChange}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="终止时间">
                        {getFieldDecorator('EndDate', {
                          initialValue: this.state.iptobj['EndDate'] ? moment(this.state.iptobj['EndDate']) : null,
                          rules: [{ required: true, message: '请输入终止时间' }],
                        })(
                          <DatePicker
                            locale={locale}
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder="请选择"
                          // disabledDate={this.disabledEndDate.bind(this)}
                          // onChange={this.onEndChange.bind(this)}
                          // disabledTime={this.disabledEndDateTime.bind(this)}
                          // open={endOpen}
                          // onOpenChange={this.handleEndOpenChange}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="textipt">
                    <Col span={24}>
                      <Form.Item label="备注">
                        {getFieldDecorator('Remark', {
                          initialValue: this.state.iptobj['Remark'],
                          rules: [{ required: false, message: '请输入备注' }],
                        })(
                          <TextArea rows={8} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button style={{ display: this.state.buttonarr.indexOf('保存') > -1 ? "inline-block" : "none" }} htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                        </Button>
                    </Form.Item>
                  </Row>
                </Form>
                <div className="cediantu" >
                  <div className="cediansele">
                    <span className="viewcedian">查看测点</span>
                    <Select labelInValue onChange={(v) => { this.setState({ viewcediansele: v.key }) }}>
                      {
                        this.state.cedianinitarr.map((item, index) => {
                          return (<Option value={item.Testid} key={item.Testid}>{item.IndexName}</Option>)
                        })
                      }
                    </Select>
                    <Button onClick={this.updatecedianline.bind(this)}>刷新</Button>
                  </div>
                  <div id="cedianline" style={{ width: "100%", height: clientHeight - 50 - 70 - 200 - 50 - 80 + 'px' }}>

                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="考核结果" key="3">
            <div className="examanswer" style={{ height: clientHeight - 50 - 36 + 'px' }}>
              <div className="examanswerleft">
                <h3 className="h3title">历史考核表</h3>

                <div className="examansweraction">
                  <Button style={{ display: this.state.buttonarr.indexOf('添加') > -1 ? "inline-block" : "none" }} onClick={this.examansweradd.bind(this)}>添加</Button>
                  {
                    this.state.detaillistIdexamobjState == "1" ?
                      <Button style={{ display: this.state.buttonarr.indexOf('计算') > -1 ? "inline-block" : "none", background: "#ccc" }}>计算</Button> :
                      <Button style={{ display: this.state.buttonarr.indexOf('计算') > -1 ? "inline-block" : "none" }} onClick={this.examanswercount.bind(this)}>计算</Button>

                  }
                  <Button style={{ display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }} onClick={this.examanswerdel.bind(this)}>删除</Button>
                </div>
                <div className="listpage" style={{ height: clientHeight - 50 - 30 - 40 - 30 + 'px' }}>
                  <Table
                    size="small"
                    dataSource={this.state.examarr}
                    columns={this.examcolumns}
                    // pagination={false}
                    pagination={{
                      pageSize: this.state.pageSize1,
                      current: this.state.pageIndex1,
                      total: Number(this.state.examarrdatalength)
                    }}
                    onChange={this.pagechange1.bind(this)}
                    onRow={(record) => {
                      return {
                        onClick: this.senddataexam.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassNameexam}
                  // scroll={{ y: clientHeight - 50 - 30 - 40 - 40 - 50 }}
                  />
                </div>
              </div>
              <Drawer
                title="历史考核添加"
                onClose={this.onClose.bind(this)}
                visible={this.state.visible}
                className="Drawercommon"
              >
                <div className="Drawercommonaddedit">
                  <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                    style={{ height: clientHeight - 50 - 55 + 'px' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="起始日期">
                          {getFieldDecorator('StartDate1', {
                            initialValue: this.state.iptobj['StartDate1'] ? moment(this.state.iptobj['StartDate1']) : null,
                            rules: [{ required: true, message: '请输入起始日期' }],
                          })(
                            <DatePicker
                              locale={locale}
                              disabledDate={this.disabledStartDateexam}
                              // showTime
                              format="YYYY-MM-DD"
                              // value={startValueexam}
                              placeholder="请选择"
                              onChange={this.onStartChangeexam}
                              onOpenChange={this.handleStartOpenChangeexam}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="终止日期">
                          {getFieldDecorator('EndDate1', {
                            initialValue: this.state.iptobj['EndDate1'] ? moment(this.state.iptobj['EndDate1']) : null,
                            rules: [{ required: true, message: '请输入终止日期' }],
                          })(
                            <DatePicker
                              locale={locale}
                              disabledDate={this.disabledEndDateexam}
                              // showTime
                              format="YYYY-MM-DD"
                              // value={endValueexam}
                              placeholder="请选择"
                              onChange={this.onEndChangeexam}
                              open={endOpenexam}
                              onOpenChange={this.handleEndOpenChangeexam}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="textipt">
                      <Col span={12}>
                        <Form.Item label="名称">
                          {getFieldDecorator('Remark1', {
                            initialValue: this.state.iptobj['Remark1'],
                            rules: [{ required: true, message: '请输入名称' }],
                          })(
                            <TextArea rows={4} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
                        </Button>
                      </Form.Item>
                    </Row>
                  </Form>

                </div>
              </Drawer>

              <Draggable
                position={this.state.controlledPositionA}
                onStart={this.handleStart.bind(this)}
                onDrag={this.handleDragA.bind(this)}
                onStop={this.handleStop.bind(this)}>
                <div className="examdetail dragwindow" ref="examdetail"
                  style={{
                    height: 560 + 'px',
                  }}>
                  <h2>考核详情<span onClick={this.closetocalcucate.bind(this)}>x</span></h2>
                  <div className="examsearchcondition">
                    <span>指标名：
                    <Select labelInValue value={{ key: this.state.indexNamesele }}
                        onChange={(v) => { this.setState({ indexNamesele: v.key }) }}>
                        <Option value="" key="">全部</Option>
                        {
                          this.state.indexNamearr.length > 0 ? this.state.indexNamearr.map((item, index) => {
                            return (<Option value={item.Testid} key={item.Testid}>{item.IndexName}</Option>)
                          }) : ""
                        }
                      </Select>
                    </span>

                    <span>班次：
                    <Select value={this.state.daytimesele} onChange={(v) => { this.setState({ daytimesele: v }) }}>
                        <Option value="0">全部</Option>
                        <Option value="1">早</Option>
                        <Option value="2">晚</Option>
                      </Select>
                    </span>
                    <span>值名：
                    <Select value={this.state.valuenamesele} onChange={(v) => { this.setState({ valuenamesele: v }) }}>
                        <Option value="0">全部</Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                      </Select>
                    </span>
                    <Button onClick={this.examdetaildatasearch.bind(this)}>搜索</Button>
                    <Button onClick={this.printdetailbaobiao.bind(this)}>报表</Button>
                  </div>
                  <div className="calcucatedata">
                    <div className="listpage" style={{ height: "490px" }}>
                      <Table
                        size="small"
                        dataSource={this.state.examdetailsarr}
                        columns={this.examdetailscolumns}
                        // pagination={false}
                        pagination={{
                          pageSize: this.state.pageSize2,
                          current: this.state.pageIndex2,
                          total: Number(this.state.examdetailsarrdatalength)
                        }}
                        onChange={this.pagechange2.bind(this)}
                      // scroll={{ y: 300 }}
                      />
                    </div>
                  </div>
                </div>
              </Draggable>

              <div className="examanswerright">
                <h3 className="h3title">考核结果&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>时间区间：<span style={{ color: "#f00" }}>{this.state.detaillistIdexamobj.StartDate ? this.state.detaillistIdexamobj.StartDate.split(' ')[0] + '——' + this.state.detaillistIdexamobj.EndDate.split(' ')[0] : ""}</span></span></h3>

                <div className="examansweraction">
                  <Button style={{ display: this.state.buttonarr.indexOf('考核详情') > -1 ? "inline-block" : "none" }} onClick={this.examdetail.bind(this)}>考核详情</Button>
                  <Button style={{ display: this.state.buttonarr.indexOf('报表') > -1 ? "inline-block" : "none" }} onClick={this.printbaobiao.bind(this)}>报表</Button>
                </div>
                <div className="listscroll" style={{ height: clientHeight - 50 - 30 - 40 - 60 + 'px' }}>
                  <Table
                    size="small"
                    dataSource={this.state.examdetailarr}
                    columns={this.examdetailcolumns}
                    pagination={false}
                    scroll={{ y: clientHeight - 50 - 30 - 40 - 40 - 54 }}
                  />
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </section>
    )
  }
}
const SmallIndicatorsApp = Form.create()(SmallIndicators);
export default SmallIndicatorsApp;

