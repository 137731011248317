import React from 'react'
import { Tree, Input, Spin, Tabs, Collapse,Form, Table, Button, message, Tooltip, Row, Col, Drawer, Upload, Icon, Select, DatePicker, Modal, InputNumber } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import { conversion } from '../../converse'
import { MergeArray,MergeArray1 } from '../../utils/arraydelrepeat'
import { onCellf } from '../../comfunc'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import { toKKSTreeData } from '../../utils/tokkstreedata'
import { toProTreeData1 } from '../../utils/toprojecttreedata'
import TextArea from 'antd/lib/input/TextArea';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import store from '../../store';
import { Resizable } from 'react-resizable';
import FileViewer from 'react-file-viewer';
import Draggable from 'react-draggable';
import { getButtonLimit } from '../../utils/getbuttonlimit'

const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { TreeNode } = Tree;
const { confirm } = Modal;
const { Panel } = Collapse;

class CreateResponsibilityrelated extends React.Component {
  constructor(props) {
    // console.log(props)
    super(props)
    this.state = {
      buttonarr:[],
      formiptobj: {},
      KKS: "",
      areaarr: [],
      departmentarr: [],
      userarr: store.getState().userdata,

    }
  }
  componentDidMount() {

    axios.get('/DATA/ParaSet/paraset.aspx?flag=8').then(data => {
      console.log(data)
      this.setState({
        areaarr: conversion(data)
      })
    })
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=3',{
      type:"1"
    }).then(data => {
      console.log('部门列表', data)
      this.setState({
        departmentarr: data.data.data
      })
    })
  }
  componentWillReceiveProps(nextprops) {
    // console.log('对比',nextprops ,this.state.KKS,this.state.formiptobj)
    if (nextprops.KKS != this.state.KKS) {
      this.setState({
        KKS: nextprops.KKS,
        buttonarr:nextprops.buttonarr,
      })
    }
    if (nextprops.formiptobjA.ID != this.state.formiptobj.ID) {
      this.props.form.resetFields();
      this.setState({
        formiptobj: nextprops.formiptobjA,
        buttonarr:nextprops.buttonarr,
      })
    }
  }
  searchMan = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values)
        values.KKS = this.state.KKS
        values.Decommissioningdate = ""
        axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=9', values).then(data => {
          console.log(data)
          if (data.data == true || data.data.indexOf('true') > -1) {
            message.success('保存成功！')
          }
        })
      } else {
        return;
      }
    })
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div style={{ height: clientHeight - 50 - 50 + 'px', overflow: "auto" }}>
        <Form onSubmit={this.searchMan.bind(this)}>
          <Row>
            <Form.Item  >
              <Button type="primary" style={{display:this.state.buttonarr.indexOf('保存-责任相关')>-1?"inline-block":"none"}} disabled={this.state.KKS?false:true} onClick={this.searchMan.bind(this)} >保存</Button>
            </Form.Item >
          </Row>
          <Row>
            <Col>
              <Form.Item label="冗余">
                {getFieldDecorator('Redundancy', {
                  initialValue: this.state.formiptobj['Redundancy'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="工作方式">
                {getFieldDecorator('OperationMode', {
                  initialValue: this.state.formiptobj['OperationMode'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="安装日期">
                {getFieldDecorator('InstallDate', {
                  initialValue: this.state.formiptobj['InstallDate'] ? moment(this.state.formiptobj['InstallDate']) : null,
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<DatePicker locale={locale} />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="累积运行时间/h">
                {getFieldDecorator('RunningTime', {
                  initialValue: this.state.formiptobj['RunningTime'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="检测周期">
                {getFieldDecorator('MonitoringCycle', {
                  initialValue: this.state.formiptobj['MonitoringCycle'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="投用日期">
                {getFieldDecorator('DateOfInvestment', {
                  initialValue: this.state.formiptobj['DateOfInvestment'] ?
                    moment(this.state.formiptobj['DateOfInvestment']) : null,
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<DatePicker />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="指令次数">
                {getFieldDecorator('NumberOfInstructions', {
                  initialValue: this.state.formiptobj['NumberOfInstructions'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<InputNumber/>)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="安装区域">
                {getFieldDecorator('InstallPosition', {
                  initialValue: this.state.formiptobj['InstallPosition'],
                  rules: [{ required: false, message: '请输入' }],
                })(
                  <Select>
                    {
                      this.state.areaarr.map((item, index) => {
                        return (<Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                      })
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="外部环境要求">
                {getFieldDecorator('ExternalEnvironment', {
                  initialValue: this.state.formiptobj['ExternalEnvironment'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="内部环境要求">
                {getFieldDecorator('InternalEnvironment', {
                  initialValue: this.state.formiptobj['InternalEnvironment'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row className="particular">
            <Col>
              <Form.Item label="巡检负责人">
                {getFieldDecorator('Ruser', {
                  initialValue: this.state.formiptobj['Ruser'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(
                  <Select>
                    {
                      this.state.userarr && this.state.userarr.length > 0 ?
                        this.state.userarr.map((item, index) => {
                          return (<Option value={item.UserNumber} key={item.UserNumber}>{item.UserName}</Option>)
                        }) : ""
                    }
                  </Select>)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="隶属部门">
                {getFieldDecorator('Dept', {
                  initialValue: this.state.formiptobj['Dept'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Select>{
                  this.state.departmentarr.map((item, index) => {
                    return (
                      <Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                  })
                }
                </Select>)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="联系电话">
                {getFieldDecorator('UserMobile', {
                  initialValue: this.state.formiptobj['UserMobile'],
                  rules: [{ required: false, message: '请输入电话' }, {
                    pattern: /^(\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/,
                    message: "电话号码格式不正确!"
                  }],
                })(<Input readOnly />)}
              </Form.Item >
            </Col>
          </Row>
          <Row className="particular">
            <Col>
              <Form.Item label="点检负责人">
                {getFieldDecorator('DRuser', {
                  initialValue: this.state.formiptobj['DRuser'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Select>
                  {
                    this.state.userarr && this.state.userarr.length > 0 ?
                      this.state.userarr.map((item, index) => {
                        return (<Option value={item.UserNumber} key={item.UserNumber}>{item.UserName}</Option>)
                      }) : ""
                  }
                </Select>)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="隶属部门">
                {getFieldDecorator('Ddept', {
                  initialValue: this.state.formiptobj['Ddept'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Select>{
                  this.state.departmentarr.map((item, index) => {
                    return (
                      <Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                  })
                }
                </Select>)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="联系电话">
                {getFieldDecorator('DuserMobile', {
                  initialValue: this.state.formiptobj['DuserMobile'],
                  rules: [{ required: false, message: '请输入电话' }, {
                    pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/,
                    message: "电话号码格式不正确!"
                  }],

                })(<Input readOnly />)}
              </Form.Item >
            </Col>
          </Row>
        </Form>

      </div>
    )
  }
}
CreateResponsibilityrelated = Form.create({})(CreateResponsibilityrelated)
//供应商信息
class Supplierinfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr:[],
      formiptobj: {},
      KKS: ""
    }
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.KKS != this.state.KKS) {
      this.setState({
        KKS: nextprops.KKS,
        buttonarr:nextprops.buttonarr,
      })
    }
    if (nextprops.formiptobjB.ID != this.state.formiptobj.ID) {
      this.props.form.resetFields();
      this.setState({
        formiptobj: nextprops.formiptobjB,
        buttonarr:nextprops.buttonarr,
      })
    }
  }
  searchMan = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values)
        values.KKS = this.state.KKS
        values.Zipcode = ""
        axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=10', values).then(data => {
          console.log(data)
          if (data.data == true || data.data.indexOf('true') > -1) {
            message.success('保存成功！')
          }
        })
      } else { return; }

    })
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div style={{ height: clientHeight - 50 - 50 + 'px', overflow: "auto" }}>
        <Form onSubmit={this.searchMan.bind(this)}>
          <Row>
            <Form.Item  >
              <Button type="primary" style={{display:this.state.buttonarr.indexOf('保存-供应商信息')>-1?"inline-block":"none"}} disabled={this.state.KKS?false:true}  onClick={this.searchMan.bind(this)}>保存</Button>
            </Form.Item >
          </Row>
          <Row>
            <Col>
              <Form.Item label="型号">
                {getFieldDecorator('ModelNum', {
                  initialValue: this.state.formiptobj['ModelNum'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="产品编号">
                {getFieldDecorator('FactoryNum', {
                  initialValue: this.state.formiptobj['FactoryNum'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="检验机构">
                {getFieldDecorator('InspectionInstitution', {
                  initialValue: this.state.formiptobj['InspectionInstitution'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="检验证书">
                {getFieldDecorator('CertificateOfInspection', {
                  initialValue: this.state.formiptobj['CertificateOfInspection'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="储存环境">
                {getFieldDecorator('StorageEnvironment', {
                  initialValue: this.state.formiptobj['StorageEnvironment'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="维修周期">
                {getFieldDecorator('MaintenanceCycle', {
                  initialValue: this.state.formiptobj['MaintenanceCycle'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="出厂日期">
                {getFieldDecorator('DateOfProduction', {
                  initialValue: this.state.formiptobj['DateOfProduction'] ?
                    moment(this.state.formiptobj['DateOfProduction']) : null,
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<DatePicker />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="保修到期">
                {getFieldDecorator('ExpirationofWarranty', {
                  initialValue: this.state.formiptobj['ExpirationofWarranty'] ?
                    moment(this.state.formiptobj['ExpirationofWarranty']) : null,
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<DatePicker />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="供应商">
                {getFieldDecorator('Supplier', {
                  initialValue: this.state.formiptobj['Supplier'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="联系人">
                {getFieldDecorator('SupName', {
                  initialValue: this.state.formiptobj['SupName'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="电话">
                {getFieldDecorator('Phone', {
                  initialValue: this.state.formiptobj['Phone'],
                  rules: [{ required: false, message: '请输入电话' }, {
                    pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/,
                    message: "电话号码格式不正确!"
                  }],
                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="传真">
                {getFieldDecorator('Fax', {
                  initialValue: this.state.formiptobj['Fax'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row className="address">
            <Form.Item label="地址">
              {getFieldDecorator('Address', {
                initialValue: this.state.formiptobj['Address'],
                rules: [
                  { required: false, message: '请输入', }
                ],

              })(<TextArea className="textarea" />)}
            </Form.Item >
          </Row>
          <Row>
            <Col>
              <Form.Item label="经销商">
                {getFieldDecorator('Distributor', {
                  initialValue: this.state.formiptobj['Distributor'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="联系人">
                {getFieldDecorator('DName', {
                  initialValue: this.state.formiptobj['DName'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="电话">
                {getFieldDecorator('DPhone', {
                  initialValue: this.state.formiptobj['DPhone'],
                  rules: [{ required: false, message: '请输入电话' }, {
                    pattern: /^(\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/,

                    message: "电话号码格式不正确!"
                  }],

                })(<Input />)}
              </Form.Item >
            </Col>
            <Col>
              <Form.Item label="传真">
                {getFieldDecorator('DFax', {
                  initialValue: this.state.formiptobj['DFax'],
                  rules: [
                    { required: false, message: '请输入', }
                  ],

                })(<Input />)}
              </Form.Item >
            </Col>
          </Row>
          <Row className="address">
            <Form.Item label="地址">
              {getFieldDecorator('DAddress', {
                initialValue: this.state.formiptobj['DAddress'],
                rules: [
                  { required: false, message: '请输入', }
                ],

              })(<TextArea className="textarea" />)}
            </Form.Item >
          </Row>

        </Form>

      </div>
    );
  }
}
Supplierinfo = Form.create({})(Supplierinfo)


class Visualizationofrelative extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr:[],

      visibledraw: false,
      activeDrags: 0,
      controlledPosition: {
        x: 0, y: 0
      },

      attr: {
        ID: 'ID',
        ParentId: 'ParentId',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        RecordVersion: 'RecordVersion',
        UpdateTime: 'UpdateTime',
        UpdatePeople: 'UpdatePeople',
        CreatePeople: 'CreatePeople',
        CreateTime: 'CreateTime'
      },
      attrB: {
        KKS: 'KKS',
        ParentId: 'ParentId',
        ReadName: 'ReadName',
        rootId: "0",
        Name: 'Name'
      },
      treeDataA: [],
      selectedKeysA: [],


      treeDataB: [],
      selectedKeysB: [],

      dataSource: [],
      dataSource11:[],
      dataSource12:[],
      pageIndex: 1,
      pageIndex11: 1,
      pageIndex12: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 80 - 50) / 28),

      formiptobjB: {},
      formiptobjA: {},
      //加载
      counflag: false,

      //关联文档添加弹框
      relativedocdata: [],
      relativedocsearchKey: '',//搜索条件
      //技术参数弹框
      pageIndextechpara: 1,
      techparadata: [],
      techparadatasearchKey: '',
      techparadatafu: [],

      //基本信息二维码上传
      imageUrl: "",
      loading: false,
      imageFlag: '0',

      selectedRowKeys: [],//关联文档绑定复选选择
      selectedRows:[],

      //专业宽高
      assetsviewleftw: 200,
      assetsviewcenterw: 240,

      //预览文件
      file:"",
      type:"",
      FileTypearr:[],
      dataSource12sele:[],
      activeKey:"1"
    }
    //基本信息
    this.columns = [
      {
        title: '属性名称',
        dataIndex: 'LeftAttribute',
        key: 'LeftAttribute',
        width: '50%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '属性值',
        dataIndex: 'RightAttribute',
        key: 'RightAttribute',
        width: '50%',
        onCell: onCellf,
        render: this.renderf
      },
    ]
    //技术参数
    this.columns11 = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex11 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '参数名称',
        dataIndex: 'ParameterName',
        key: 'ParameterName',
        width: '36%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '技术规范',
        dataIndex: 'Specifications',
        key: 'Specifications',
        width: '40%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '14%',
        onCell: onCellf,
        render: this.renderf
      },
    ]
    //模型关联文档
    this.columns12 = [
      {
        title: '文件号',
        dataIndex: 'Code',
        key: 'Code',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '文件名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '50%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '文件格式',
        dataIndex: 'MimeTypeGif',
        key: 'MimeTypeGif',
        width: '20%',
        render: (text) => {
          if(text){
            var str = text.toLowerCase()
            return (<div style={{ height: "22px", display: "flex", alignItems: "center",color:"#fff", justifyContent: "center" }}>
              <img src={require(`../../assets/file/${str}`)} style={{ width: "15px", height: "15px",top:"6px",left:"22%" }} alt="" />
              {text.split('.')[0]}
            </div>)
          }else{
            return (<span></span>)
          }
        }
      },
    ]
    //关联文档弹框
    this.relativedoccolumns = [
      {
        title: '文件号',
        dataIndex: 'Code',
        key: 'Code',
        width: '38%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '文件名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '40%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '文件格式',
        dataIndex: 'MimeTypeGif',
        key: 'MimeTypeGif',
        width: '15%',
        render: (text) => {
          var str = text.toLowerCase()
          return (<div style={{ height: "22px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src={require(`../../assets/file/${str}`)} style={{ width: "15px", height: "15px",top:"6px",left:"24%" }} alt="" />
            {text.split('.')[0]}
          </div>)
        }
      },
    ]
    //技术参数弹框
    this.techparacolumns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '8%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndextechpara - 1) * 12 + index + 1}</span>)
        }
      },
      {
        title: '编码',
        dataIndex: 'ClassificationName',
        key: 'ClassificationName',
        width: '15%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '说明',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '创建人',
        dataIndex: 'CreatePeople',
        key: 'CreatePeople',
        width: '16%',
      },
      {
        title: '创建时间',
        dataIndex: 'CreateTime',
        key: 'CreateTime',
        width: '18%',
        onCell: onCellf,
        render: this.renderf
      }
    ]
    this.renderf = this.renderf.bind(this)
    this.MouseWheelHandler = this.MouseWheelHandler.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(83)

    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res => {
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      this.setState({
        treeDataA: arr
      })
    })
    //关联文档分组
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=3',{
      type:10
    }).then(data => {
      this.setState({
        FileTypearr: data.data.data
      })
    })
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight- 50 - 80 - 50) / 28),
    })
  }

  senddata(record) {
    console.log(record)
    this.setState({
      rowId: record.ID,
      rowObj: record
    })
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }
  senddata11(record) {
    console.log(record)
    this.setState({
      rowId11: record.ID,
      rowObj11: record
    })
  }

  setRowClassName11 = (record) => {
    return record.ID === this.state.rowId11 ? 'clickRowStyl' : 'clickRowSty';
  }
  senddata12(record) {
    console.log(record)
    this.setState({
      rowId12: record.ID,
      rowObj12: record
    })
  }
  setRowClassName12 = (record) => {
    return record.ID === this.state.rowId12 ? 'clickRowStyl' : 'clickRowSty';
  }
  // handlePreview() {
  //   if (this.state.rowId12) {
  //     axios.post('/Data/TdModel/RelevantInformation.aspx?flag=6', {
  //       FileId: this.state.rowObj12.FileId
  //     }).then(data => {
  //       // console.log(data)
  //       window.open(data.data.data[0].filePath)
  //     })
  //   } else {
  //     message.error('请选择！')
  //   }
  // }

  //预览文件
  handlePreview() {
    if (this.state.rowId12) {
      var strlower = this.state.rowObj12.MimeTypeGif.split('.')[0].toLowerCase()
      var type = ""
      if(strlower.indexOf('xls')>-1){
        type = "xlsx"
      }else if(strlower.indexOf('doc')>-1 || strlower.indexOf('txt')>-1){
        type = "docx"
      }else{
        type = strlower
      }
      this.setState({
        file: this.state.rowObj12.Path,
        type: type
      },function(){
        if(this.state.type=="pdf"){
          window.open(`./pdfjs-dist/web/viewer.html?file=/${encodeURI(this.state.file)}`)
        }else if(this.state.type=="docx"){
          // window.open(`https://view.officeapps.live.com/op/view.aspx?src=/${encodeURI(this.state.file)}`)
        }else {
          var myimage = document.getElementById("swiplun");
          if (myimage.addEventListener) {
            // IE9, Chrome, Safari, Opera
            myimage.addEventListener("mousewheel", this.MouseWheelHandler, false);
            // Firefox
            myimage.addEventListener("DOMMouseScroll", this.MouseWheelHandler, false);
          }
          // IE 6/7/8
          else myimage.attachEvent("onmousewheel", this.MouseWheelHandler);
        }
      })
    } else {
      message.error('请选择文件!')
    }
  }

  //关闭文档预览
  tocloseviewfile() {
    this.setState({
      file: "",
      type: ""
    })
  }
  MouseWheelHandler(e) {

    var myimage = document.getElementById("swiplun");
    // cross-browser wheel delta
    var e = window.event || e; // old IE support
    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
    //现在我们直接决定图片的大小范围。以下代码将图片的宽度范围设置在50-800个像素之间
    myimage.style.width = Math.max(50, Math.min(2000, myimage.width + (30 * delta))) + "px";
    myimage.style.height = Math.max(50, Math.min(2000, myimage.height + (30 * delta))) + "px";
    // 最后一点，我们在方法中返回false是为了终止标准的鼠标滚轮事件处理，以防它上下滑动网页
    return false;
  }
  onTreeSelect(selectedKeys, info) {
    console.log(selectedKeys, info)
    this.setState({
      selectedKeysA: selectedKeys
    })
    //KKS数据
    if (selectedKeys[0]) {
      axios.post('/DATA/ParaSet/ParaSet.aspx?flag=40', {
        ID: selectedKeys[0]
      }).then(data => {
        console.log(data)
        let attr = this.state.attrB;
        let arr = toKKSTreeData(conversion(data), attr)
        //console.log('KKS树数据', arr);
        this.setState({
          treeDataB: arr
        })
      })
    } else {
      this.setState({
        treeDataB: []
      })
    }
  }
  onTreeBSelect(selectedKeys, info) {
    console.log(selectedKeys, info)
    if (selectedKeys[0] && selectedKeys[0].length > 8) {
      this.setState({
        selectedKeysB: selectedKeys,
        counflag: true
      })
      axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=1', {
        KKS: selectedKeys[0]
      }).then(data => {
        console.log('基本信息', conversion(data))
        this.setState({
          dataSource: conversion(data)
        })
      })
      axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=2', {
        KKS: selectedKeys[0]
      }).then(data => {
        console.log('责任相关', conversion(data))
        this.setState({
          formiptobjA: conversion(data)[0] ? conversion(data)[0] : {}
        })
      })
      axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=3', {
        KKS: selectedKeys[0]
      }).then(data => {
        console.log('供应商信息', conversion(data))
        this.setState({
          formiptobjB: conversion(data)[0] ? conversion(data)[0] : {}
        })
      })
      axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=4', {
        KKS: selectedKeys[0]
      }).then(data => {
        console.log('技术参数', conversion(data))
        this.setState({
          dataSource11: conversion(data)
        })
      })
      axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=5', {
        KKS: selectedKeys[0]
      }).then(data => {
        console.log('关联文档', conversion(data))
        var arr = conversion(data)
        var newarr = []
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].FileType == "1") {
            newarr.push(arr[i])
          }
        }
        this.setState({
          dataSource12: conversion(data),
          dataSource12sele:newarr
        }, function () {
          if (this.state.formiptobjA && this.state.formiptobjB) {
            this.setState({
              counflag: false
            })
          }
        })
      })
    }
  }

  pagechange(key, page) {
    console.log(key,page)
    if (key == "基本信息") {
      this.setState({
        pageIndex: page.current,
      })
    } else if (key == "技术参数") {
      this.setState({
        pageIndex11: page.current,
      })
    } else if (key == "关联文档") {
      this.setState({
        pageIndex12: page.current,
      })
    } else if (key == "关联问档drag") {
      this.setState({
        pageIndexdrag: page.current,
      }, function () {
        axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=15', {
          pageIndex: this.state.pageIndexdrag,
          pageSize: 12,
          searchKey: this.state.relativedocsearchKey
        }).then(data => {
          console.log('关联文档列表数据', conversion(data))
          var arr = conversion(data)
          for (let i = 0; i < arr.length; i++) {
            arr[i].key = arr[i].id
          }
          this.setState({
            relativedocdata: arr,
          })
        })
      })

    } else if (key == "技术参数drag") {
      this.setState({
        pageIndextechpara: page.current,
      })
    }
  }
  onClose() {

  }

  //关联文档弹框搜索
  relativedocsearch(value) {
    console.log(value);
    this.setState({
      relativedocsearchKey: value,
      selectedRowKeys: [],
    }, function () {
      axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=15', {
        pageIndex: 1,
        pageSize: 12,
        searchKey: value
      }).then(data => {
        var str = data.data
        console.log('关联文档列表数据', conversion(data))
        var arr = conversion(data)
        for (let i = 0; i < arr.length; i++) {
          arr[i].key = arr[i].ID
        }
        this.setState({
          relativedocdata: arr,
          totalcount: str.substring(12, str.indexOf(','))
        })
      })
    })
  }

  handleAdd(key) {
    if (key == "关联文档") {
      if(this.state.activeKey){
        // this.refs.relativedoc.style.display = "block"
        axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=15', {
          pageIndex: 1,
          pageSize: 12,
          searchKey: ""
        }).then(data => {
          var str = data.data
          console.log('关联文档列表数据', conversion(data))
          var arr = conversion(data)
          for (let i = 0; i < arr.length; i++) {
            arr[i].key = arr[i].ID
          }
          this.setState({
            relativedocdata: arr,
            relativedocsearchKey: "",
            selectedRowKeys: [],
            pageIndexdrag: 1,
            totalcount: str.substring(12, str.indexOf(','))
          }, function () {
            this.refs.relativedoc.style.display = "block"
          })
        })
      }else{
        message.error('请选择组！')
      }
    } else {
      this.setState({
        addedit: "添加",
        addeditname: key,
        visibledraw: true,
        iptobj: {}
      }, function () {
        this.props.form.resetFields();
      })
    }
  }
  handleEdit(key) {
    if (key == "技术参数") {
      if (this.state.rowId11) {
        var obj = this.state.rowObj11
        this.setState({
          addedit: "编辑",
          addeditname: key,
          iptobj: obj,
          visibledraw: true
        },function(){
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "基本信息") {
      if (this.state.rowId) {
        var obj = this.state.rowObj
        this.setState({
          addedit: "编辑",
          addeditname: key,
          iptobj: obj,
          visibledraw: true
        },function(){
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择！')
      }
    }
  }
  //二维码查看 上传
  twodimensionalcode() {
    if (this.state.selectedKeysB[0]) {
      axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=19', {
        KKS: this.state.selectedKeysB[0]
      }).then(data => {
        this.setState({
          imageUrl: conversion(data)[0] ? conversion(data)[0].KKSPhoto : "",
          addedit: "查看",
          addeditname: "二维码",
          visibledraw: true
        })
      })
    } else {
      message.error('请选择！')
    }
  }
  handleDelete(key) {
    var _this = this
    if (key == "基本信息") {
      if (this.state.rowId) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=8', {
              ID: _this.state.rowId
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                const dataSource = [..._this.state.dataSource];
                if(Number(dataSource.length) % Number(_this.state.pageSize) == 1){
                  var curpage = _this.state.pageIndex
                  _this.setState({
                    pageIndex:curpage-1
                  })
                }
                _this.setState({ dataSource: dataSource.filter(item => item.ID !== _this.state.rowId), rowId: "" },
                  function () {
                    message.success('删除成功！')
                  });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择!')
      }
    } else if (key == "技术参数") {
      if (this.state.rowId11) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=13', {
              ID: _this.state.rowId11
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                const dataSource = [..._this.state.dataSource11];
                if(Number(dataSource.length) % Number(_this.state.pageSize) == 1){
                  var curpage = _this.state.pageIndex11
                  _this.setState({
                    pageIndex11:curpage-1
                  })
                }
                _this.setState({ dataSource11: dataSource.filter(item => item.ID !== _this.state.rowId11), rowId11: "" },
                  function () {
                    message.success('删除成功！')
                  });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择!')
      }
    } else if (key == "关联文档") {
      if (this.state.rowId12) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=14', {
              ID: _this.state.rowId12
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                const dataSource = [..._this.state.dataSource12];
                var arr = dataSource.filter(item => item.ID !== _this.state.rowId12)
                var newarr = []
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].FileType == _this.state.activeKey) {
                    newarr.push(arr[i])
                  }
                }
                _this.setState({ dataSource12: arr, rowId12: "" ,dataSource12sele:newarr},
                  function () {
                    message.success('删除成功！')
                  });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择!')
      }
    }
  }
  //基本信息初始化
  handleInitial(key) {
    var _this = this
    if (key == "基本信息") {
      if (this.state.selectedKeysB[0]) {
        confirm({
          title: '确定要初始化吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=7', {
              KKS: _this.state.selectedKeysB[0]
            }).then(data => {
              console.log('初始化', data)
              if (data.data == true || typeof data.data == "string" && data.data.indexOf('true') > -1) {
                axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=1', {
                  KKS: _this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('基本信息', conversion(data))
                  _this.setState({
                    dataSource: conversion(data)
                  }, function () {
                    message.success('初始化成功！')
                  })
                })
              }else if(typeof data.data == "string" && data.data.indexOf('err')>-1){
                message.info('不能重复初始化！')
              }
            })

          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择KKS！')
      }
    } else if (key == "技术参数") {
      axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
        this.setState({
          techparadata: conversion(data),
          techparadatafu: conversion(data),
          techparadatasearchKey: ""
        }, function () {
          this.refs.techpara.style.display = "block"
        })
      })
    }
  }

  //页签切换
  callback() {

  }

  //关联文档添加弹框
  senddatadrag(record) {
    console.log(record)
    this.setState({
      rowIddrag: record.id,
      rowIddragobj: record
    })
  }
  setRowClassNamedrag = (record) => {
    return record.id === this.state.rowIddrag ? 'clickRowStyl' : 'clickRowSty';
  }
  //添加关联文件
  toaddrelativedoc() {
    console.log(this.state.selectedRowKeys,this.state.selectedRows)
    var arr = this.state.selectedRows
    if (arr.length > 0) {
      axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=16', {
        FileType: this.state.activeKey,
        FileId: this.state.selectedRowKeys.join(','),
        KKS: this.state.selectedKeysB[0],
        UserName: sessionStorage.getItem('unumber')
      }).then(data => {
        console.log(data)
        if (data.data == true || data.data.indexOf('true') > -1) {
          axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=5', {
            KKS: this.state.selectedKeysB[0]
          }).then(data => {
            console.log('关联文档', conversion(data))
            var arr = conversion(data)
            var newarr = []
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].FileType == this.state.activeKey) {
                newarr.push(arr[i])
              }
            }
            this.setState({
              dataSource12: conversion(data),
              dataSource12sele:newarr,
              selectedRowKeys: [],
              selectedRows:[]
            }, function () {
              this.refs.relativedoc.style.display = "none"
              message.success('添加成功！')
            })
          })
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  tocloserelativedoc() {
    this.setState({
      selectedRowKeys: [],
      selectedRows:[]
    })
    this.refs.relativedoc.style.display = "none"
  }
  Collapsecallback(key) {
    //console.log('面板切换选择',key)
    var arr = this.state.dataSource12
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].FileType == key) {
        newarr.push(arr[i])
      }
    }
    //console.log(newarr)
    this.setState({
      dataSource12sele: newarr,
      activeKey:key
    })
  }
  //技术参数初始化弹框
  senddatatechpara(record) {
    console.log(record)
    this.setState({
      rowIdtechpara: record.ID,
      rowIdtechparaobj: record
    })
  }
  setRowClassNametechpara = (record) => {
    return record.ID === this.state.rowIdtechpara ? 'clickRowStyl' : 'clickRowSty';
  }
  //技术参数初始化
  toaddtechpara() {
    var _this = this
    if (this.state.rowIdtechpara) {
      confirm({
        title: '确定要初始化吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=12', {
            KKS: _this.state.selectedKeysB[0],
            ParentId: _this.state.rowIdtechpara
          }).then(data => {
            console.log(data)
            if (data.data == true) {
              axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=4', {
                KKS: _this.state.selectedKeysB[0],
              }).then(data => {
                console.log('技术参数', conversion(data))
                _this.setState({
                  dataSource11: conversion(data)
                }, function () {
                  _this.refs.techpara.style.display = "none"
                  message.success('初始化成功！')
                })
              })
            }
          })
        },
        onCancel() {
          _this.setState({
            // detaillistId: ''
          })
        },
      });
    } else {
      message.error('请选择！')
    }
  }
  toclosetechpara() {
    this.refs.techpara.style.display = "none"
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDrag(e, ui) {
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "基本信息") {
            values.KKS = this.state.selectedKeysB[0]
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=6', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=1', {
                  KKS: this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('基本信息', conversion(data))
                  this.setState({
                    dataSource: conversion(data),
                    visibledraw: false
                  })
                })
              }
            })
          } else if (this.state.addeditname == "技术参数") {
            values.ID = 0
            values.KKS = this.state.selectedKeysB[0]
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=11', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=4', {
                  KKS: this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('技术参数', conversion(data))
                  this.setState({
                    dataSource11: conversion(data),
                    visibledraw: false
                  })
                })
              }
            })
          }
        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "技术参数") {
            values.ID = this.state.rowId11
            values.KKS = this.state.selectedKeysB[0]
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=11', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=4', {
                  KKS: this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('技术参数', conversion(data))
                  this.setState({
                    dataSource11: conversion(data),
                    visibledraw: false,
                    rowId11:"",
                    rowObj11:{}
                  })
                })
              }
            })
          } else if (this.state.addeditname == "基本信息") {
            values.ID = this.state.rowId
            values.EditFlag = this.state.rowObj.EditFlag
            // values.KKS = this.state.selectedKeysB[0]
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=17', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=1', {
                  KKS: this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('基本信息', conversion(data))
                  this.setState({
                    dataSource: conversion(data),
                    visibledraw: false,
                    rowId:"",
                    rowObj:{}
                  })
                })
              }else if(data.data.indexOf('不能修改')>-1){
                this.setState({
                  visibledraw: false
                })
                message.error(data.data)
              }
            })
          }
        }
      }
    });
  };
  onClose() {
    this.setState({
      visibledraw: false
    }, function () {
      this.props.form.resetFields();
    })
  }

  //二维码
  //图片上传
  getBase64(img, callback) {
    //console.log(img, callback)
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  //限制上传图片的格式和大小
  beforeUpload(file) {
    console.log(file)
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('超过10M限制，不允许上传!');
    }
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    const isBMP = file.type === 'image/bmp';
    if (!(isJPG || isJPEG || isGIF || isPNG || isBMP)) {
      message.error('只能上传JPG 、JPEG 、GIF、 PNG、 BMP格式的图片!')
    }
    return (isBMP || isGIF || isJPEG || isJPG || isPNG) && isLt2M;
  }
  handletuChange = (info) => {
    console.log(info)
    if (info.file.status === 'uploading') {
      this.setState({ loading: true, imageUrl: "" });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => this.setState({
        imageUrl,
        loading: false,
        imageFlag: '1'
      }, function () {
        console.log(this.state.imageUrl);
        axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=18', {
          KKS: this.state.selectedKeysB[0],
          Photo: this.state.imageUrl
        }).then(data => {
          console.log(data)
          if (data.data == true || data.data.indexOf('true') > -1) {
            message.success(`${info.file.name} 上传成功`);
          }
        })
      }));
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 上传失败`);
    }
  }
  render() {
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    if(clientWidth<1200){
      $('.Visualizationofrelativerightconl button').css('padding',"0 4px")
    }else{
      $('.Visualizationofrelativerightconl button').css('padding',"0 15px")
    }
    const imageUrl = this.state.imageUrl;
    const { getFieldDecorator } = this.props.form;
    const loop = data =>
      data.map(item => {
        if (item.children) {
          return (
            <TreeNode key={item.ID} title={item.Code + '-' + item.Name}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} title={item.Code + '-' + item.Name} />;
      });
    const loop1 = data =>
      data.map(item => {
        if (item.children) {
          return (
            <TreeNode key={item.KKS} title={item.ReadName}>
              {loop1(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.KKS} title={item.ReadName} />;
      });
    const { selectedRowKeys,selectedRows } = this.state
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
        if(selected){
          var selectedRowsfu = this.state.selectedRows
          var selectedRowsnew = MergeArray1(selectedRowsfu,selectedRows)
        }else{
          var selectedRowsfu = this.state.selectedRows
          var selectedRowsnew = []
          
          for(let i=0;i<changeRows.length;i++){
            for(let j=0;j<selectedRowsfu.length;j++){
              if(selectedRowsfu[j].ID === changeRows[i].ID){
                selectedRowsfu.splice(j,1);
                j--;
              }
            }
          }
          selectedRowsnew = selectedRowsfu
        }
        var selectedRowKeysnew = []
        for(let i=0;i<selectedRowsnew.length;i++){
          selectedRowKeysnew.push(selectedRowsnew[i].ID)
        }
        this.setState({
          selectedRowKeys:selectedRowKeysnew,
          selectedRows:selectedRowsnew
        })
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
        var selectedRows = this.state.selectedRows
        if(selected){
          selectedRows.push(record)
        }else{
          selectedRows = selectedRows.filter(function(e){
            return e.ID != record.ID
          })
        }
        console.log(selectedRows)
        var selectedRowKeys = this.state.selectedRowKeys
        if(selected){
          selectedRowKeys.push(record.ID)
        }else{
          selectedRowKeys = selectedRowKeys.filter(function(e){
            return e != record.ID
          })
        }
        this.setState({
          selectedRows:selectedRows,
          selectedRowKeys:selectedRowKeys
        })
      },
      // onChange: (selectedRowKeys, selectedRows) => {
      //   console.log(selectedRowKeys,selectedRows)
      //   var arr = this.state.selectedRowKeys
      //   var arr1 = this.state.selectedRows
      //   this.setState({
      //     selectedRowKeys: MergeArray(arr,selectedRowKeys),
      //     selectedRows: MergeArray1(arr1,selectedRows)
      //   })
      // },
    };
    return (
      <div className="Visualizationofrelative" style={{ height: clientHeight - 50 + 'px' }}>
        {/* 关联文档 */}
        <Draggable
          position={this.state.controlledPosition}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDrag.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="dragwindow relativedoc" ref="relativedoc"
            style={{
              width: '800px', height: 530 + 'px'
            }}>
            <h2>添加<span onClick={this.tocloserelativedoc.bind(this)}>x</span></h2>
            <span className="searchlist">搜索
            <Search onMouseDown={(e) => { e.stopPropagation() }}
                value={this.state.relativedocsearchKey}
                onChange={(e) => { this.setState({ relativedocsearchKey: e.target.value }) }}
                onSearch={this.relativedocsearch.bind(this)} /></span>
            <div className="listpage" style={{ height: "400px" }}>
              <Table
                size="small"
                rowKey={record => record.ID}
                rowSelection={rowSelection}
                dataSource={this.state.relativedocdata}
                columns={this.relativedoccolumns}
                pagination={{
                  pageSize: 12,
                  current: this.state.pageIndexdrag,
                  total: parseInt(this.state.totalcount)
                }}
                onChange={this.pagechange.bind(this, '关联问档drag')}
              // onRow={(record) => {
              //   return {
              //     onClick: this.senddatadrag.bind(this, record)
              //   }
              // }}
              // rowClassName={this.setRowClassNamedrag}
              />
            </div>
            <div className="dragaction">
              <Button onClick={this.toaddrelativedoc.bind(this)}>确定</Button>
              <Button onClick={this.tocloserelativedoc.bind(this)}>取消</Button>
            </div>
          </div>
        </Draggable>
        {/* 文档预览 */}
        <div className="toviewfile"
          id="toviewfilecon"
            style={{ height: clientHeight - 56 + 'px', display:this.state.file && this.state.type !="pdf"? "block" : "none" }} >
              {
                this.state.file && this.state.type !="pdf" && this.state.type !="docx"?
                <Draggable
                  position={this.state.controlledPosition}
                  onStart={this.handleStart.bind(this)}
                  onDrag={this.handleDrag.bind(this)}
                  onStop={this.handleStop.bind(this)}>
                  <img id="swiplun" src={'/'+this.state.file} 
                  style={{maxHeight:"98%",maxWidth:"98%",width:"auto",height:"auto"}}/>
                </Draggable>:""
              }
              {
                this.state.file && this.state.type =="docx"?
                <FileViewer
                  fileType={this.state.type}
                  filePath={this.state.file}
                  onError={Error} />:""
              }
            <span className="tocloseviewfile" onClick={this.tocloseviewfile.bind(this)}>
              <img src={require("../../assets/imgs/close.png")} alt="" /></span>
          </div>
            
        {/* 技术参数 */}
        <Draggable
          position={this.state.controlledPosition}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDrag.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="dragwindow techpara" ref="techpara"
            style={{
              width: '800px', height: 530 + 'px'
            }}>
            <h2>添加<span onClick={this.toclosetechpara.bind(this)}>x</span></h2>
            <span className="searchlist">搜索 <Search
              value={this.state.techparadatasearchKey}
              onChange={(e) => { this.setState({ techparadatasearchKey: e.target.value }) }}
              onMouseDown={(e) => { e.stopPropagation() }}
              onSearch={value => {
                var arr = this.state.techparadatafu
                var newarr = []
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].ClassificationName.indexOf(value) > -1 || arr[i].Remark.indexOf(value) > -1 || arr[i].CreatePeople.indexOf(value) > -1) {
                    newarr.push(arr[i])
                  }
                }
                this.setState({
                  techparadata: newarr
                })
              }}
            /></span>
            <div className="listpage" style={{ height: "400px" }}>
              <Table
                size="small"
                dataSource={this.state.techparadata}
                columns={this.techparacolumns}
                pagination={{
                  pageSize: 12,
                  current: this.state.pageIndextechpara,
                  total: parseInt(this.state.techparadata.length)
                }}
                onChange={this.pagechange.bind(this, '技术参数drag')}
                onRow={(record) => {
                  return {
                    onClick: this.senddatatechpara.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassNametechpara}
              />
            </div>
            <div className="dragaction">
              <Button onClick={this.toaddtechpara.bind(this)}>确定</Button>
              <Button onClick={this.toclosetechpara.bind(this)}>取消</Button>
            </div>
          </div>
        </Draggable>


        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
        <Drawer
          title={this.state.addedit + '' + this.state.addeditname}
          onClose={this.onClose.bind(this)}
          visible={this.state.visibledraw}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            {
              this.state.addeditname == "基本信息" ?
                <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="属性名称">
                        {getFieldDecorator('LeftAttribute', {
                          initialValue: this.state.iptobj["LeftAttribute"],
                          rules: [{ required: false, message: '请输入' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="属性值">
                        {getFieldDecorator('RightAttribute', {
                          initialValue: this.state.iptobj["RightAttribute"],
                          rules: [{ required: false, message: '请输入' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                   </Button>
                    </Form.Item>
                  </Row>
                </Form> :
                this.state.addeditname == "技术参数" ?
                  <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="参数名称">
                          {getFieldDecorator('ParameterName', {
                            initialValue: this.state.iptobj["ParameterName"],
                            rules: [{ required: false, message: '请输入' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="技术规范">
                          {getFieldDecorator('Specifications', {
                            initialValue: this.state.iptobj["Specifications"],
                            rules: [{ required: false, message: '请输入' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="单位">
                          {getFieldDecorator('Unit', {
                            initialValue: this.state.iptobj["Unit"],
                            rules: [{ required: false, message: '请输入' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
                   </Button>
                      </Form.Item>
                    </Row>
                  </Form> :
                  this.state.addeditname == "二维码" ?
                    <div className="twocodeupload">
                      <div className="image">
                        <Upload
                          name=""
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={this.beforeUpload}
                          onChange={this.handletuChange}
                        >
                          {
                            imageUrl ?
                              <img src={imageUrl} style={{
                                width: "auto", height: "auto",
                                maxHeight: "380px",
                                maxWidth: "380px",
                                minHeight: "340px",
                                minWidth: "340px",
                              }} alt="" />
                              :
                              <div>
                                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                                <div className="ant-upload-text">点击此处上传照片</div>
                              </div>
                          }
                        </Upload>
                      </div>
                    </div> : ""

            }
          </div>
        </Drawer>

        <div className="Visualizationofrelativeleftcenter" style={{height:clientHeight-50+'px'}}>
          <Resizable className="box" axis="x" minConstraints={[100, 100]} maxConstraints={[400, 400]} height={clientHeight - 50} width={this.state.assetsviewleftw}
            onResize={(event, { element, size }) => {
              this.setState({ assetsviewleftw: size.width, assetsviewcenterw: 440 - size.width });
            }}>
            <div className="Visualizationofrelativeleft" style={{width: this.state.assetsviewleftw + 'px'}}>
              <h2 className="h2title">专业树</h2>
              <div className="tree" style={{ height: clientHeight - 50 - 50 + 'px' }}>
                <Tree
                  selectedKeys={this.state.selectedKeysA}
                  onSelect={this.onTreeSelect.bind(this)}
                >
                  {loop(this.state.treeDataA)}
                </Tree>
              </div>
            </div>
          </Resizable>
          <div className="Visualizationofrelativecenter" style={{ width: this.state.assetsviewcenterw + 'px' }}>
            <h2 className="h2title">KKS树</h2>
            <div className="tree" style={{ height: clientHeight - 50 - 50 + 'px' }}>
              <Tree
                selectedKeys={this.state.selectedKeysB}
                onSelect={this.onTreeBSelect.bind(this)}
              >
                {loop1(this.state.treeDataB)}
              </Tree>
            </div>
          </div>
        </div>
        <div className="Visualizationofrelativeright" style={{ width: clientWidth - 440 + 'px' }}>
          <div className="Visualizationofrelativerightconl">
            <h2 className="h2title">基本信息</h2>
            <div className="actionall">
              <span className="actionleft">
                <Button className="twoword" style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} disabled={this.state.selectedKeysB[0]?false:true} onClick={this.handleAdd.bind(this, '基本信息')}>添加</Button>
                <Button className="twoword" style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} disabled={this.state.rowId?false:true} onClick={this.handleEdit.bind(this, '基本信息')}>编辑</Button>
                <Button className="twoword" style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} disabled={this.state.rowId?false:true} onClick={this.handleDelete.bind(this, '基本信息')}>删除</Button>
                <Button className="twoword" style={{display:this.state.buttonarr.indexOf('初始化')>-1?"inline-block":"none"}} disabled={this.state.selectedKeysB[0]?false:true} onClick={this.handleInitial.bind(this, '基本信息')}>初始化</Button>
                <Button className="twoword" style={{display:this.state.buttonarr.indexOf('二维码')>-1?"inline-block":"none"}} disabled={this.state.selectedKeysB[0]?false:true} onClick={this.twodimensionalcode.bind(this)}>二维码</Button>
              </span>
            </div>
            <div className="listpage"
              style={{ height: clientHeight - 50 - 80 + 'px' }}>
              <Table
                size="small"
                dataSource={this.state.dataSource}
                columns={this.columns}
                pagination={{
                  pageSize: this.state.pageSize,
                  current: this.state.pageIndex,
                  total: parseInt(this.state.dataSource.length)
                }}
                onChange={this.pagechange.bind(this, '基本信息')}
                onRow={(record) => {
                  return {
                    onClick: this.senddata.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassName}
              />
            </div>

          </div>
          <div className="Visualizationofrelativerightconr">

            <Tabs onChange={this.callback.bind(this)}>
              <TabPane tab="责任相关" key="1">
                <CreateResponsibilityrelated formiptobjA={this.state.formiptobjA}
                  clientHeight={clientHeight}
                  clientWidth={clientWidth}
                  KKS={this.state.selectedKeysB[0]}
                  buttonarr = {this.state.buttonarr}
                />
              </TabPane>
              <TabPane tab="供应商信息" key="2">
                <Supplierinfo formiptobjB={this.state.formiptobjB}
                  clientHeight={clientHeight}
                  clientWidth={clientWidth}
                  KKS={this.state.selectedKeysB[0]}
                  buttonarr = {this.state.buttonarr} />
              </TabPane>
              <TabPane tab="技术参数" key="3">
                <div className="techparamater">
                  <div className="actionall">
                    <span className="actionleft">
                      <Button className="twoword" style={{display:this.state.buttonarr.indexOf('添加-技术参数')>-1?"inline-block":"none"}} disabled={this.state.selectedKeysB[0]?false:true} onClick={this.handleAdd.bind(this, '技术参数')}>添加</Button>
                      <Button className="twoword" style={{display:this.state.buttonarr.indexOf('编辑-技术参数')>-1?"inline-block":"none"}} disabled={this.state.rowId11?false:true} onClick={this.handleEdit.bind(this, '技术参数')}>编辑</Button>
                      <Button className="twoword" style={{display:this.state.buttonarr.indexOf('技术参数-初始化')>-1?"inline-block":"none"}} disabled={this.state.selectedKeysB[0]?false:true} onClick={this.handleInitial.bind(this, '技术参数')}>初始化</Button>
                      <Button className="twoword" style={{display:this.state.buttonarr.indexOf('技术参数-删除')>-1?"inline-block":"none"}} disabled={this.state.rowId11?false:true} onClick={this.handleDelete.bind(this, '技术参数')}>删除</Button>
                    </span>
                  </div>
                  <div className="listpage"
                    style={{ height: clientHeight - 50 - 80 + 'px' }}>
                    <Table
                      size="small"
                      dataSource={this.state.dataSource11}
                      columns={this.columns11}
                      pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageIndex11,
                        total: parseInt(this.state.dataSource11.length)
                      }}
                      onChange={this.pagechange.bind(this, '技术参数')}
                      onRow={(record) => {
                        return {
                          onClick: this.senddata11.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassName11}
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane tab="模型关联文档" key="4">
                <div className="modeldoc">
                  <div className="actionall">
                    <span className="actionleft">
                      <Button className="twoword" style={{display:this.state.buttonarr.indexOf('添加-模型关联文档')>-1?"inline-block":"none"}} disabled={this.state.selectedKeysB[0]?false:true} onClick={this.handleAdd.bind(this, '关联文档')}>添加</Button>
                      <Button className="twoword" style={{display:this.state.buttonarr.indexOf('删除-模型关联文档')>-1?"inline-block":"none"}} disabled={this.state.rowId12?false:true} onClick={this.handleDelete.bind(this, '关联文档')}>删除</Button>
                    </span>
                    <span className="actionright">
                      <Button className="twoword" style={{display:this.state.buttonarr.indexOf('预览-模型关联文档')>-1?"inline-block":"none"}} disabled={this.state.rowId12?false:true} onClick={this.handlePreview.bind(this)}>预览</Button>
                    </span>
                  </div>
                  <table className="tableheader">
                    <tbody>
                      <tr>
                        <th>文件号</th>
                        <th>文件名称</th>
                        <th>文件格式</th>
                      </tr>
                    </tbody>
                  </table>
                  <Collapse accordion activeKey={this.state.activeKey} onChange={this.Collapsecallback.bind(this)}>
                    {
                      this.state.FileTypearr.map((item, index) => {
                        return (
                          <Panel header={item.Name} key={item.Num}>
                            <div className="listscroll" style={{ height: clientHeight - 50 - 80 - 32 - 36 * (Number(this.state.FileTypearr.length)) }}>
                              <Table
                                size="small"
                                showHeader={false}
                                pagination={false}
                                scroll={{ y:clientHeight - 50 - 80 - 32 - 36 * (Number(this.state.FileTypearr.length)) }}
                                columns={this.columns12}
                                onRow={(record) => {
                                  return {
                                    onClick: this.senddata12.bind(this, record)
                                  }
                                }}
                                rowClassName={this.setRowClassName12}
                                dataSource={this.state.dataSource12sele}
                              />
                            </div>
                          </Panel>
                        )
                      })
                    }
                  </Collapse>
                  
                  {/* <div className="listpage"
                    style={{ height: clientHeight - 50 - 80 + 'px' }}>
                    <Table
                      size="small"
                      dataSource={this.state.dataSource12}
                      columns={this.columns12}
                      pagination={{
                        pageSize: this.state.pageSize1,
                        current: this.state.pageIndex12,
                        total: parseInt(this.state.dataSource12.length)
                      }}
                      onChange={this.pagechange.bind(this, '关联文档')}
                      onRow={(record) => {
                        return {
                          onClick: this.senddata12.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassName12}
                    />
                  </div> */}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    )
  }
}

const Visualizationofrelativeapp = Form.create({})(Visualizationofrelative)
export default Visualizationofrelativeapp;