import React from 'react';
import { Table, Input, Tabs, Tree, Tooltip, Collapse, Icon, Button, Select, Progress, message, Checkbox } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import store from '../../store/index'
import $ from 'jquery'
import { onCellf } from '../../comfunc'
import { toProTreeData1 } from '../../utils/toprojecttreedata'
import { toKKSTreeData } from '../../utils/tokkstreedata'
import './style.scss'
import { Resizable } from 'react-resizable';
import { MergeArray1 } from '../../utils/arraydelrepeat'

const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;
const { Option } = Select;
const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;
class Firstdata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      treeDataproject: [],
      treeDataprojectall:[],
      treeDataarea: [],
      treeDataareaall:[],
      treeDataconstrut: [],
      treeDataconstrutall:[],
      treeDataequipasset: [],
      treeDataequipassetall:[],

      genExtracheckA:false,
      genExtracheckB:false,
      genExtracheckC:false,
      genExtracheckD:false,
      //资产可视化装配树类型
      //装配树
      assemblytreearr: [],
      //资产可视化
      activekey: '1',
      checkedKeysA: [],
      checkedKeysB:[],
      checkedKeysC:[],
      checkedKeysD:[],

      //KKS树
      treeDataB: [],
      treeDataBfu: [],
      treeDataBsearch: [],
      kksselekey: '',
      //搜索结果
      treeDataBb: [],
      selectedKeysB: '',
      autoExpandParent: false,
      expandedKeys: [],
      searchValue: '',
      //基础属性 技术参数
      BasicAttributedata: [],
      TechParamaterdata: [],
      Supplierdata: [],
      DutyRelativedata: [],
      RelativeDocdata: [],
      RelativeDocdatasele:[],
      tableactiveKey:"1",//技术文档
      //五个模块
      activetabsKey: '1',
      //模型加载标识
      treeIdselearr: [],
      //关联文件
      FileTypearr:[],
      FileId: '',
      //加载文件
      filePath: '',
      //区域树
      treeDataAA: [],
      //获取kks树 选择的左树id字符串
      kksstr: '',

      //加载模型的数量
      begincount: 0,
      loadingcount: 0,

      //下拉框初始化
      initialdataarr: [],

      //加载过的ModelKey
      ModelKeyarr:[],

      attr: {
        ID: 'ID',
        ParentId: 'ParentId',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        RecordVersion: 'RecordVersion',
        UpdateTime: 'UpdateTime',
        UpdatePeople: 'UpdatePeople',
        CreatePeople: 'CreatePeople',
        CreateTime: 'CreateTime'
      },
      attrB: {
        KKS: 'KKS',
        ParentId: 'ParentId',
        ReadName: 'ReadName',
        rootId: "0",
        Name: 'Name'
      }
      ,
      //一键全选获取的modelKay
      resarrnew: [],

      //专业宽高
      assetsviewleftw: 200,
      assetsviewcenterw: 220,
    };
    //基础属性 供应商 责任相关 技术参数  文档关联
    this.columns = [
      {
        title: '属性名称',
        dataIndex: 'LeftAttribute',
        key: 'LeftAttribute',
        width: '40%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '属性值',
        dataIndex: 'RightAttribute',
        key: 'RightAttribute',
        width: '60%',
        onCell: onCellf,
        render: this.renderf

      },
    ]
    this.columns1 = [
      {
        title: '属性名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '40%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '属性值',
        dataIndex: 'RightAttribute',
        key: 'RightAttribute',
        width: '60%',
        onCell: onCellf,
        render: this.renderf

      },
    ]
    this.columns2 = [
      {
        title: '属性名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '40%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '属性值',
        dataIndex: 'RightAttribute',
        key: 'RightAttribute',
        width: '60%',
        onCell: onCellf,
        render: this.renderf

      },
    ]
    this.columns3 = [
      {
        title: '参数名称',
        dataIndex: 'ParameterName',
        key: 'ParameterName',
        width: '30%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '技术规范',
        dataIndex: 'Specifications',
        key: 'Specifications',
        width: '30%',
        onCell: onCellf,
        render: this.renderf

      }, {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '30%',
        onCell: onCellf,
        render: this.renderf

      },
    ]
    this.columns4 = [
      {
        title: '图号',
        dataIndex: 'Code',
        key: 'Code',
        width: '40%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '文档名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '60%',
        onCell: onCellf,
        render: this.renderf

      },
    ]
    this.receivemess = this.receivemess.bind(this)
    this.storeChange = this.storeChange.bind(this)
    store.subscribe(this.storeChange)
    this.renderf = this.renderf.bind(this)
    this.onCheckAA = this.onCheckAA.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
    }
  }
  storeChange() {
    this.setState(store.getState())
  }
  componentDidMount() {
  
    axios.get('/DATA/ParaSet/AssemblyMode.aspx').then(res => {
      //console.log('装配方式', conversion(res))
      this.setState({
        assemblytreearr: conversion(res)
      })
    })
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('专业树数据', arr)
      this.setState({
        treeDataproject: arr,
        treeDataprojectall:conversion(res)
      })
    })
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=8').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('区域树数据', arr)
      this.setState({
        treeDataarea: arr,
        treeDataareaall:conversion(res)
      })
    })
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=63').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('施工包树数据', arr)
      this.setState({
        treeDataconstrut: arr,
        treeDataconstrutall:conversion(res)
      })
    })
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=73').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('设备资产树数据', arr)
      this.setState({
        treeDataequipasset: arr,
        treeDataequipassetall:conversion(res)
      })
    })
    window.addEventListener('message', this.receivemess)

    // //kks
    axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
      console.log('下拉框初始化', conversion(data))
      this.setState({
        initialdataarr: conversion(data)
      })
    })
    
    //关联文档分组
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=3',{
      type:10
    }).then(data => {
      this.setState({
        FileTypearr: data.data.data
      })
    })
  }
  receivemess(e) {
    console.log(e.data)
    if (e.data == "COMPLETE") {
      var num = this.state.loadingcount
      num = num + 1
      if (num / this.state.begincount == 1) {
        this.setState({
          loadingcount: 0,
          begincount: 0,
        }, function () {
          this.refs.loadprogress.style.display = "none"
        })
      } else {
        this.setState({
          loadingcount: num
        })
      }
    } else {
      var arr = []
      if(e.data.indexOf("KKSCode1")>-1){
        var obj = JSON.parse(e.data)
        arr.push(obj.KKSCode1)
      }
      this.setState({
        selectedKeysBB:arr,
        BasicAttributedata: [],
        TechParamaterdata: [],
        Supplierdata: [],
        DutyRelativedata: [],
        RelativeDocdata: [],
        RelativeDocdatasele:[]
      }, function () {
        if (this.state.selectedKeysB && this.state.selectedKeysB == this.state.selectedKeysBB) {

        } else {

          if (e.data.length > 8) {
            this.refs.assetsviewright.style.display = "block"
            axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=1', {
              KKS: this.state.selectedKeysBB[0]?this.state.selectedKeysBB[0]:""
            }).then(data => {
              //console.log('基础属性', conversion(data))
              this.setState({
                BasicAttributedata: conversion(data)
              })
            })
            axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=2', {
              KKS: this.state.selectedKeysBB[0]?this.state.selectedKeysBB[0]:""
            }).then(data => {
              //console.log('技术参数', conversion(data))
              this.setState({
                TechParamaterdata: conversion(data)
              })
            })
            axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=3', {
              KKS: this.state.selectedKeysBB[0]?this.state.selectedKeysBB[0]:""
            }).then(data => {
              //console.log('供应商资料', conversion(data))
              this.setState({
                Supplierdata: conversion(data)
              })
            })
            axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=4', {
              KKS: this.state.selectedKeysBB[0]?this.state.selectedKeysBB[0]:""
            }).then(data => {
              //console.log('责任相关', conversion(data))
              this.setState({
                DutyRelativedata: conversion(data)
              })
            })
            axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=5', {
              KKS: this.state.selectedKeysBB[0]?this.state.selectedKeysBB[0]:""
            }).then(data => {
              //console.log('相关文件', conversion(data))
              var arr = conversion(data)
              var newarr = []
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].FileType == "1") {
                  newarr.push(arr[i])
                }
              }
              this.setState({
                RelativeDocdata: conversion(data),
                RelativeDocdatasele:newarr
              })
            })
          }
        }
      })
    }

  }
  //面板切换
  Collapsechange(key) {
    console.log(key)
    if(key == this.state.activekey){
      this.setState({
        activekey: key,
      })
    }else{
      this.setState({
        activekey: key,
        begincount: 0,
        loadingcount: 0
      })
    }
  }
  
  onCheckA = (checkedKeys, info) =>{
    this.setState({
      searchValue:""
    })
    if (info.checked == false && this.state.begincount != 0) {
    } else {
      var activekey = this.state.activekey
      var treeIdselearr = this.state.treeIdselearr
      var ModelKeyarr = this.state.ModelKeyarr
      var treeIdselearractivekey = this.state.treeIdselearractivekey


      //选中装配树获取kks树
      var checkedarr = checkedKeys
      var kksstr = ''
      for (let i = 0; i < checkedarr.length; i++) {
        if (i == checkedarr.length - 1) {
          kksstr += checkedarr[i]
        } else {
          kksstr += checkedarr[i] + ','
        }
      }
      if (checkedKeys.length == 0) {
        this.setState({
          treeDataB: []
        })
      } else {
        //kks数据
        var flag = activekey =="1"? 40 :activekey=="2"?41:activekey=="3"?42:43
        axios.post(`/DATA/ParaSet/ParaSet.aspx?flag=${flag}`,{
          ID:kksstr
        }).then(res=>{
          // console.log('kks树',conversion(res))
          let attr = this.state.attrB;
          let arr = toKKSTreeData(conversion(res), attr)
          //console.log('设备资产树数据', arr)
          this.setState({
            treeDataBsearch:conversion(res),
            treeDataB: arr,
            treeDataBfu: arr,
            kksstr: kksstr
          },function(){
            this.refs.assetsviewcenter.style.display = "block"
            this.refs.treeshoufangkks.style.display = "none"
          })
        })
      }

      if(activekey=="1"){
        this.setState({
          treeIdselearr:treeIdselearractivekey != activekey?[]:treeIdselearr,
          ModelKeyarr:treeIdselearractivekey != activekey?[]:ModelKeyarr,
          checkedKeysA:checkedKeys,
          genExtracheckA:checkedKeys.length == this.state.treeDataprojectall.length ?true:false,
          checkedKeysB:[],
          checkedKeysC:[],
          checkedKeysD:[],
          genExtracheckB:false,
          genExtracheckC:false,
          genExtracheckD:false,
        },function(){
          this.onCheckAA(this.state.checkedKeysA,info)
        })
      }else if(activekey=="2"){
        this.setState({
          ModelKeyarr:treeIdselearractivekey != activekey?[]:ModelKeyarr,
          treeIdselearr:treeIdselearractivekey != activekey?[]:treeIdselearr,
          genExtracheckB:checkedKeys.length == this.state.treeDataareaall.length ?true:false,
          checkedKeysB:checkedKeys,
          checkedKeysA:[],
          checkedKeysC:[],
          checkedKeysD:[],
          genExtracheckA:false,
          genExtracheckC:false,
          genExtracheckD:false
        },function(){
          this.onCheckAA(this.state.checkedKeysB,info)
        })
      }else if(activekey=="3"){
        this.setState({
          ModelKeyarr:treeIdselearractivekey != activekey?[]:ModelKeyarr,
          treeIdselearr:treeIdselearractivekey != activekey?[]:treeIdselearr,
          genExtracheckC:checkedKeys.length == this.state.treeDataconstrutall.length ?true:false,
          checkedKeysC:checkedKeys,
          checkedKeysA:[],
          checkedKeysB:[],
          checkedKeysD:[],
          genExtracheckA:false,
          genExtracheckB:false,
          genExtracheckD:false
        },function(){
          this.onCheckAA(this.state.checkedKeysC,info)
        })
      }else if(activekey=="4"){
        this.setState({
          ModelKeyarr:treeIdselearractivekey != activekey?[]:ModelKeyarr,
          treeIdselearr:treeIdselearractivekey != activekey?[]:treeIdselearr,
          checkedKeysD:checkedKeys,
          genExtracheckD:checkedKeys.length == this.state.treeDataequipassetall.length ?true:false,
          checkedKeysA:[],
          checkedKeysB:[],
          checkedKeysC:[],
          genExtracheckA:false,
          genExtracheckB:false,
          genExtracheckC:false
        },function(){
          this.onCheckAA(this.state.checkedKeysD,info)
        })
      }

    }
  }
  
  onCheckAA (checkedKeys,  info){
    var str = this.state.treeIdselearr
    // console.log(str,checkedKeys ,info)
    var activekey = this.state.activekey
    var flagmodel = activekey =="1"? 1 :activekey=="2"?2:activekey=="3"?5:6
    //选择
    if (info.checked == true) {
      //第一次选择
      if (str.length==0) {
        //alert('第一次选择。。。')
        //选择的节点
        var checkedKeysarr = checkedKeys

        for (let i = 0; i < checkedKeysarr.length; i++) {
          str.push(checkedKeysarr[i])
        }
        this.setState({
          treeIdselearr: str,
          treeIdselearractivekey:activekey
        })

        var assetsviewframe = document.getElementById("assetsviewframe")
        assetsviewframe.src = `./BOS3dhtml/areatree.html`
        var _this = this
        assetsviewframe.onload = function () {
          _this.refs.loadprogress.style.display = "block"
          var homeyjbtn = document.getElementById("assetsviewframe").contentWindow.document.getElementsByClassName('yj-but')[0]
          homeyjbtn.addEventListener('click', function () {
            document.getElementById("assetsviewframe").contentWindow.viewer3D.resetScene({ visible: false, view: true, selected: true, transparent: true, colorfully: true })
          })
          axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=${flagmodel}&treeId=${info.node.props.eventKey}`).then(data => {
            console.log('第一次选择', conversion(data))
            //开始总数量
            var dataarr = conversion(data)
            if (dataarr.length == 0) {
              // message.error(`${info.node.props.eventKey}无ModelKey`)
              _this.refs.loadprogress.style.display = "none"
            } else {
              _this.setState({
                begincount: dataarr.length,
                ModelKeyarr:conversion(data)
              }, function () {
                for (let i = 0; i < dataarr.length; i++) {
                  document.getElementById("assetsviewframe").contentWindow.viewer3D.addView(dataarr[i].ModelKey, "bole");
                }
              })
            }

          })
        }
      }
      //第n次选择
      else {
        this.refs.loadprogress.style.display = "block"
        //节点之前选择过
        if (str.indexOf(info.node.props.eventKey) > -1) {
          //alert('第n次选择。。。之前选择过')
          axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=${flagmodel}&treeId=${info.node.props.eventKey}`).then(data => {
            console.log('之前选择过',conversion(data))
            var arr = conversion(data)
            if (arr.length == 0) {
              // message.error(`${info.node.props.eventKey}无ModelKey`)
              if (this.state.begincount == 0) {
                this.refs.loadprogress.style.display = "none"
              }
            } else {
              for (let i = 0; i < arr.length; i++) {
                document.getElementById("assetsviewframe").contentWindow.viewer3D.showModelsByKey(arr[i].ModelKey, "bole");
                if (i == arr.length - 1) {
                  this.refs.loadprogress.style.display = "none"
                }
              }
            }
          })
        }
        //节点之前未选择过
        else {
          //alert('第n次选择。。。之前未选择过')
          var checkedKeysarr = checkedKeys
          for (let i = 0; i < checkedKeysarr.length; i++) {
            str.push(checkedKeysarr[i])
          }
          this.setState({
            treeIdselearr: str,
            treeIdselearractivekey:activekey
          })
          this.refs.loadprogress.style.display = "block"
          axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=${flagmodel}&treeId=${info.node.props.eventKey}`).then(data => {
            var num = this.state.begincount
            var arr = MergeArray1(conversion(data),this.state.ModelKeyarr) 
            console.log('第n次选中',arr,this.state.ModelKeyarr)
            var ModelKeyarr = this.state.ModelKeyarr
            if (arr.length == 0 && num == 0) {
              this.refs.loadprogress.style.display = "none"
            } else {
              this.setState({
                begincount: parseInt(num) + arr.length,
                ModelKeyarr:ModelKeyarr.concat(arr)
              }, function () {
                for (let i = 0; i < arr.length; i++) {
                  document.getElementById("assetsviewframe").contentWindow.viewer3D.addView(arr[i].ModelKey, "bole");
                }
              })
            }
          })

        }
      }
    }
    //取消选择
    else {
      if (this.state.begincount == 0 && this.state.loadingcount == 0) {
        this.refs.loadprogress.style.display = "block"
        axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=${flagmodel}&treeId=${info.node.props.eventKey}`).then(data => {
          var arr = conversion(data)
          if (arr.length == 0) {
            this.refs.loadprogress.style.display = "none"
          } else {
            for (let i = 0; i < arr.length; i++) {
              document.getElementById("assetsviewframe").contentWindow.viewer3D.hideModelsByKey(arr[i].ModelKey)
              if (i == arr.length - 1) {
                this.refs.loadprogress.style.display = "none"
              }
            }
          }
        })
      } else {

      }
    }
  }

  //KKs树选择
  onTreeSelectB(selectedKeys, info) {
    console.log(selectedKeys, info, selectedKeys[0].length)
    this.setState({
      BasicAttributedata: [],
      TechParamaterdata: [],
      Supplierdata: [],
      DutyRelativedata: [],
      RelativeDocdata: [],
      RelativeDocdatasele:[]
    }, function () {
      if (this.state.selectedKeysBB && this.state.selectedKeysBB == this.state.selectedKeysB) {

      } else {
        if (selectedKeys[0].length > 8) {
          this.setState({
            selectedKeysB: selectedKeys,
          })
          axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=10', {
            KKS: selectedKeys[0]
          }).then(data => {
            // console.log('图guid', conversion(data))

            var guidgroup = [];
            var arr =  conversion(data)
            for (let i = 0; i < arr.length; i++) {
              guidgroup.push(arr[i].Guid)
            }
            this.refs.assetsviewright.style.display = "block"
            if (guidgroup.length > 0) {
              document.getElementById('assetsviewframe').contentWindow.viewer3D.transparentOtherComponentsByKey(guidgroup)
              document.getElementById('assetsviewframe').contentWindow.viewer3D.highlightComponentsByKey(guidgroup)
              document.getElementById('assetsviewframe').contentWindow.viewer3D.adaptiveSize(guidgroup);
            }
          })
          axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=1', {
            KKS: selectedKeys[0]
          }).then(data => {
            console.log('基础属性', conversion(data))
            this.setState({
              BasicAttributedata: conversion(data)
            })
          })
          axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=2', {
            KKS: selectedKeys[0]
          }).then(data => {
            console.log('技术参数', conversion(data))
            this.setState({
              TechParamaterdata: conversion(data)
            })
          })
          axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=3', {
            KKS: selectedKeys[0]
          }).then(data => {
            console.log('供应商资料', conversion(data))
            this.setState({
              Supplierdata: conversion(data)
            })
          })
          axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=4', {
            KKS: selectedKeys[0]
          }).then(data => {
            console.log('责任相关',conversion(data))
            this.setState({
              DutyRelativedata: conversion(data)
            })
          })
          axios.post('/DATA/TdModel/TM_Correlation.aspx?flag=5', {
            KKS: selectedKeys[0]
          }).then(data => {
            console.log('相关文件', conversion(data))
            var arr = conversion(data)
            var newarr = []
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].FileType == "1") {
                newarr.push(arr[i])
              }
            }
            this.setState({
              RelativeDocdatasele:newarr,
              RelativeDocdata: conversion(data)
            })
          })
        } else if (selectedKeys[0].length >= 2 && selectedKeys[0].length <= 8) {
          this.setState({
            selectedKeysB: selectedKeys,
          })
          axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=11', {
            KKS: selectedKeys[0],
            kksstr: this.state.kksstr,
            Num:this.state.activekey
          }).then(data => {
            // console.log('图guid', data)
            var guidgroup = [];
            var arr = data.data.data
            for (let i = 0; i < arr.length; i++) {
              guidgroup.push(arr[i].Guid)
            }
            if (guidgroup.length > 0) {
              document.getElementById('assetsviewframe').contentWindow.viewer3D.transparentOtherComponentsByKey(guidgroup)
              document.getElementById('assetsviewframe').contentWindow.viewer3D.highlightComponentsByKey(guidgroup)
              document.getElementById('assetsviewframe').contentWindow.viewer3D.adaptiveSize(guidgroup);
            }
          })
        }
      }
    })
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  //kks树搜索
  searchkks(value){
    this.setState({
      searchValue:value
    },function(){
      var activekey = this.state.activekey
      var flag = activekey =="1"? 40 :activekey=="2"?41:activekey=="3"?42:43
      if(this.state.searchValue==""){
        var arr = this.state.treeDataBfu
        this.setState({
          treeDataB:arr
        })
      }else{
        axios.post(`/DATA/ParaSet/ParaSet.aspx?flag=${flag}`,{
          ID:this.state.kksstr,
          flag:1,
          SearchKey:this.state.searchValue
        }).then(res=>{
          this.setState({
            treeDataB: conversion(res)
          })
        })
      }
    })
  }
  // searchkks(value) {
  //   //console.log(value)
  //   if (value == "") {
  //     var arr = this.state.treeDataBfu
  //     this.setState({
  //       treeDataB: arr,
  //       searchValue: value,
  //       autoExpandParent: false,
  //     })
  //   } else {
  //     var treeDataBarr = this.state.treeDataBsearch
  //     var newarr = []
  //     for (let i = 0; i < treeDataBarr.length; i++) {
  //       if (i == (treeDataBarr.length - 1)) {
  //         if (treeDataBarr[i].ReadName.indexOf(value) > -1) {
  //           newarr.push(treeDataBarr[i].KKS)
  //           // //console.log(newarr)
  //           if (newarr.length > 0) {
  //             this.setState({
  //               expandedKeys: newarr,
  //               autoExpandParent: true,
  //               searchValue: value
  //             })
  //           } else {
  //             this.setState({
  //               expandedKeys: newarr,
  //               autoExpandParent: false,
  //               searchValue: value,
  //               treeDataB: []
  //             })
  //           }

  //         } else {
  //           ////console.log(newarr)
  //           if (newarr.length > 0) {
  //             this.setState({
  //               expandedKeys: newarr,
  //               autoExpandParent: true,
  //               searchValue: value
  //             })
  //           } else {
  //             this.setState({
  //               expandedKeys: newarr,
  //               autoExpandParent: false,
  //               searchValue: value,
  //               treeDataB: []
  //             })
  //           }
  //         }
  //       } else {
  //         if (treeDataBarr[i].ReadName.indexOf(value) > -1) {
  //           newarr.push(treeDataBarr[i].KKS)
  //         }
  //       }
  //     }
  //   }
  // }
  //KKS功能
  togongneng() {
    var activekey = this.state.activekey
    var checkedKeysarr =activekey=="1"? this.state.checkedKeysA:activekey=="2"?this.state.checkedKeysB:
    activekey=="3"?this.state.checkedKeysC:this.state.checkedKeysD
    var str = ''
    for (let i = 0; i < checkedKeysarr.length; i++) {
      if (i == checkedKeysarr.length - 1) {
        str += checkedKeysarr[i]
      } else {
        str += checkedKeysarr[i] + ','
      }
    }
    var factory = sessionStorage.getItem('factory')
    if (this.state.kksselekey) {
      // //console.log(encodeURI(factory))
      // //console.log(decodeURI(encodeURI(factory)))
      window.open(`http://47.97.112.136:8080/WebReport/ReportServer?reportlet=B-SPPs/ModelStatisticsReport.cpt&company=${encodeURI(factory)}&searchKey=${this.state.kksselekey}&id=${str}&Num=${this.state.activekey}`)
    } else {
      message.error('请先选择！')
    }
  }

  //装配树收缩
  zhuangpeishushou() {
    this.refs.assetsviewleft.style.display = "none"
    this.refs.treeshoufangzhang.style.display = "block"
    if ($('.assetsviewcenter').css("display") == "block") {
      $('.treeshoufang').css("left", this.state.assetsviewcenterw+10 +'px')
    } else if ($('.assetsviewcenter').css("display") == "none") {
      $('.treeshoufang').css("left", "10px")
    }
  }
  zhuangpeishufang() {
    this.refs.assetsviewleft.style.display = "block"
    this.refs.treeshoufangzhang.style.display = "none"
    if ($('.assetsviewcenter').css("display") == "none") {
      $('.treeshoufang').css("left",  this.state.assetsviewleftw+10 +'px')
    } 
  }
  kksshushou() {
    this.refs.assetsviewcenter.style.display = "none"
    this.refs.treeshoufangkks.style.display = "block"
    if ($('.assetsviewleft').css("display") == "block") {
      $('.treeshoufang').css("left",  this.state.assetsviewleftw+10 +'px')
    } else if ($('.assetsviewleft').css("display") == "none") {
      $('.treeshoufang').css("left", "10px")
    }
  }
  kksshufang() {
    this.refs.assetsviewcenter.style.display = "block"
    this.refs.treeshoufangkks.style.display = "none"
    if ($('.assetsviewleft').css("display") == "none") {
      $('.treeshoufang').css("left", this.state.assetsviewcenterw+10 +'px')
    }
  }

  //关联文件点击
  rowclick(record) {
    console.log(record)
    this.setState({
      FileId: record.ID
    },function(){
      window.open(`./pdfjs-dist/web/viewer.html?file=/${encodeURI(record.Path)}`)
    })
  }
  setRowClassName(record) {
    return record.ID === this.state.FileId ? 'clickRowStyl' : 'clickRowSty';
  }

  //树全选取消全选
  checkchange(key,e) {
    console.log(key,e)
    if (e.target.checked == true) {
      if(key=="genExtracheckA"){
        if(this.state.activekey=="1"){
          var arr = this.state.treeDataprojectall
          var newarr = []
          for (let i = 0; i < arr.length; i++) {
            newarr.push(arr[i].ID)
          }
          this.setState({
            checkedKeysA: newarr,
            genExtracheckA:true,
            genExtracheckB:false,
            genExtracheckC:false,
            genExtracheckD:false,
            checkedKeysB:[],
            checkedKeysC:[],
            checkedKeysD:[]
          })
        }else{
          
        }
      }else if(key=="genExtracheckB"){
        if(this.state.activekey=="2"){
          var arr = this.state.treeDataareaall
          var newarr = []
          for (let i = 0; i < arr.length; i++) {
            newarr.push(arr[i].ID)
          }
          this.setState({
            genExtracheckB:true,
            genExtracheckA:false,
            genExtracheckC:false,
            genExtracheckD:false,
            checkedKeysB:newarr,
            checkedKeysA:[],
            checkedKeysC:[],
            checkedKeysD:[]
          })
        }else{
          
        }
      }else if(key=="genExtracheckC"){
        if(this.state.activekey=="3"){
          var arr = this.state.treeDataconstrutall
          var newarr = []
          for (let i = 0; i < arr.length; i++) {
            newarr.push(arr[i].ID)
          }
          this.setState({
            genExtracheckC:true,
            genExtracheckA:false,
            genExtracheckB:false,
            genExtracheckD:false,
            checkedKeysC:newarr,
            checkedKeysA:[],
            checkedKeysB:[],
            checkedKeysD:[]
          })
        }else{
          
        }
      }else if(key=="genExtracheckD"){
        if(this.state.activekey=="4"){
          var arr = this.state.treeDataequipassetall
          var newarr = []
          for (let i = 0; i < arr.length; i++) {
            newarr.push(arr[i].ID)
          }
          this.setState({
            genExtracheckD:true,
            genExtracheckA:false,
            genExtracheckB:false,
            genExtracheckC:false,
            checkedKeysD:newarr,
            checkedKeysA:[],
            checkedKeysB:[],
            checkedKeysC:[]
          })
        }else{
          
        }
      }
      
      if(key=="genExtracheckA" && this.state.activekey=="1" ||
      key=="genExtracheckB" && this.state.activekey=="2"||
      key=="genExtracheckC" && this.state.activekey=="3"||
      key=="genExtracheckD" && this.state.activekey=="4"
      ){
        
        //kks
        var activekey = this.state.activekey
        var flag = activekey =="1"? 40 :activekey=="2"?41:activekey=="3"?42:43
        axios.post(`/DATA/ParaSet/ParaSet.aspx?flag=${flag}`,{
          ID:newarr.join(',')
        }).then(res=>{
          // console.log('kks树',conversion(res))
          let attr = this.state.attrB;
          let arr = toKKSTreeData(conversion(res), attr)
          //console.log('设备资产树数据', arr)
          this.setState({
            treeDataBsearch:conversion(res),
            treeDataB: arr,
            treeDataBfu: arr
          },function(){
            this.refs.assetsviewcenter.style.display = "block"
            this.refs.treeshoufangkks.style.display = "none"
          })
        })
        if( this.state.activekey=="1" && this.state.checkedKeysA.length==0 
        || this.state.activekey=="2" && this.state.checkedKeysB.length==0 
        || this.state.activekey=="3" && this.state.checkedKeysC.length==0 
        || this.state.activekey=="4" && this.state.checkedKeysD.length==0 ){
          //请求获取ModelKey
          axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=11', {
            Num:activekey,
            TreeId: 0,
            pageIndex:1,
            pageSize:9999
          }).then(data => {
            // console.log(conversion(data))
            var resarr = conversion(data)
            var numcount = 0
            var resarrnew = []
            for (let i = 0; i < resarr.length; i++) {
              if (resarr[i].ModelKey) {
                resarrnew.push(resarr[i])
                numcount = numcount + 1
              }
            }
            var assetsviewframe = document.getElementById("assetsviewframe")
            assetsviewframe.src = `./BOS3dhtml/areatree.html`
            var _this = this
            assetsviewframe.onload = function () {
              _this.refs.loadprogress.style.display = "block"
    
              if (numcount == 0) {
                _this.refs.loadprogress.style.display = "none"
              } else {
                _this.setState({
                  begincount: numcount,
                  resarrnew: resarrnew
                }, function () {
                  for (var i = 0; i < numcount; i++) {
                    document.getElementById("assetsviewframe").contentWindow.viewer3D.addView(resarr[i].ModelKey, "bole");
                  }
                })
              }
            }
          })
        }else{
          //请求获取ModelKey
          axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=11', {
            Num:activekey,
            TreeId: 0,
            pageIndex:1,
            pageSize:9999
          }).then(data => {
            // console.log(conversion(data))
            var resarr = conversion(data)
            var numcount = 0
            var resarrnew = []
            for (let i = 0; i < resarr.length; i++) {
              if (resarr[i].ModelKey) {
                resarrnew.push(resarr[i])
                numcount = numcount + 1
              }
            }
            var _this = this
            _this.refs.loadprogress.style.display = "block"
              if (numcount == 0) {
                _this.refs.loadprogress.style.display = "none"
              } else {
                _this.setState({
                  begincount: numcount,
                  resarrnew: resarrnew
                }, function () {
                  for (var i = 0; i < numcount; i++) {
                    document.getElementById("assetsviewframe").contentWindow.viewer3D.addView(resarr[i].ModelKey, "bole");
                  }
                })
              }
          })
        }
      }
      else{
        alert('请切换！')
      }
    } else {
      if(key=="genExtracheckA"){
        if(this.state.activekey=="1"){
          this.setState({
            genExtracheckA:false,
            checkedKeysA:[]
          })
        }
      }else if(key=="genExtracheckB"){
        if(this.state.activekey=="2"){
          this.setState({
            genExtracheckB:false,
            checkedKeysB:[]
          })
        }
      }else if(key=="genExtracheckC"){
        if(this.state.activekey=="3"){
          this.setState({
            genExtracheckC:false,
            checkedKeysC:[]
          })
        }
      }else if(key=="genExtracheckD"){
        if(this.state.activekey=="4"){
          this.setState({
            genExtracheckD:false,
            checkedKeysD:[]
          })
        }
      }
      if(key=="genExtracheckA" && this.state.activekey=="1" ||
      key=="genExtracheckB" && this.state.activekey=="2"||
      key=="genExtracheckC" && this.state.activekey=="3"||
      key=="genExtracheckD" && this.state.activekey=="4"
      ){
        this.refs.loadprogress.style.display = "block"
        var data = this.state.resarrnew
        if (data.length == 0) {
          this.refs.loadprogress.style.display = "none"
        } else {
          for (var i = 0; i < data.length; i++) {
            document.getElementById("assetsviewframe").contentWindow.viewer3D.hideModelsByKey(data[i].ModelKey);
            if (i == data.length - 1) {
              this.refs.loadprogress.style.display = "none"
            }
          }
        }
      }
      else{
        alert('请切换！')
      }
    }
  }

  
  Collapsecallback(key) {
    //console.log('面板切换选择',key)
    var arr = this.state.RelativeDocdata
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].FileType == key) {
        newarr.push(arr[i])
      }
    }
    //console.log(newarr)
    this.setState({
      RelativeDocdatasele: newarr,
      tableactiveKey:key
    })
  }
  render() {
    const { expandedKeys, autoExpandParent, searchValue } = this.state
    const customPanelStyle = {
      borderRadius: 4,
      marginBottom: 10,
      border: 0,
      overflow: 'hidden',
    };
    const loop = data =>
      data.map(item => {
        if (item.children) {
          return (
            // title={<Tooltip title={item.ReadName}>{item.CodeName+'-'+item.ReadName}</Tooltip>}
            <TreeNode key={item.ID} title={item.Code + '-' + item.Name}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} title={item.Code + '-' + item.Name} />;
      });

    const loop1 = data =>
      data.map(item => {
        if (item.children) {
          return (
            <TreeNode key={item.KKS} title={item.ReadName}>
              {loop1(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.KKS} title={item.ReadName} />;
      });
    const genExtra = (key) => {
      return (<span onClick={event => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}><Checkbox onChange={this.checkchange.bind(this,key)} checked={this.state[key]}></Checkbox></span>)
    }
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="assetsview" style={{ width: "100%", height: clientHeight - 50 + 'px' }}>
        <iframe title="myiframe" src="" id="assetsviewframe" style={{
          width: clientWidth + 'px', height: clientHeight - 50 + 'px',
          maxHeight: clientHeight - 50 + 'px', maxWidth: clientWidth + 'px',
        }}></iframe>
        <div className="assetviewleftcenter" style={{ height: clientHeight - 50 + 'px' }}>
          <Resizable className="box" axis="x" minConstraints={[100, 100]} maxConstraints={[300, 300]} height={clientHeight - 50} width={this.state.assetsviewleftw}
            onResize={(event, { element, size }) => {
              this.setState({ assetsviewleftw: size.width, assetsviewcenterw: 420 - size.width });
            }}>
            <div className="assetsviewleft" ref="assetsviewleft" style={{ height: clientHeight - 50 + 'px', width: this.state.assetsviewleftw + 'px' }}>
              <h2 className="zhuangpeishu" >
                <span>装配树</span>
                <span onClick={this.zhuangpeishushou.bind(this)} className="zhuangpeishushousuo"></span>
              </h2>
              <div className="zhedieban">
                <Collapse
                  bordered={false}
                  accordion
                  activeKey={this.state.activekey}
                  onChange={this.Collapsechange.bind(this)}
                  expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                >
                  {
                    this.state.assemblytreearr.map((item, index) => {
                      if(item.Name=="专业"){
                        return (
                          <Panel header={item.Name} key={item.ID} style={customPanelStyle} extra={genExtra("genExtracheckA")}>
                            <div className="assetviewtree" style={{ height: clientHeight - 50 -80 - 40 * this.state.assemblytreearr.length + 'px', overflow: "auto" }}>
                              <DirectoryTree
                                checkable
                                onCheck={this.onCheckA.bind(this)}
                                checkedKeys={this.state.checkedKeysA}
                                selectedKeys={[]}
                                // onSelect={this.onTreeSelectA.bind(this)}
                                multiple
                              >
                                {loop(this.state.treeDataproject)}
                              </DirectoryTree>
                            </div>
                          </Panel>
                        )
                      }else if(item.Name=="区域"){
                        return (
                          <Panel header={item.Name} key={item.ID} style={customPanelStyle} extra={genExtra("genExtracheckB")}>
                            <div className="assetviewtree" style={{ height: clientHeight - 50 -80 - 40 * this.state.assemblytreearr.length + 'px', overflow: "auto" }}>
                              <DirectoryTree
                                checkable
                                onCheck={this.onCheckA.bind(this)}
                                checkedKeys={this.state.checkedKeysB}
                                selectedKeys={[]}
                                // onSelect={this.onTreeSelectA.bind(this)}
                                multiple
                              >
                                {loop(this.state.treeDataarea)}
                              </DirectoryTree>
                            </div>
                          </Panel>
                        )
                      }else if(item.Name=="施工包"){
                        return (
                          <Panel header={item.Name} key={item.ID} style={customPanelStyle} extra={genExtra("genExtracheckC")}>
                            <div className="assetviewtree" style={{ height: clientHeight - 50 -80 - 40 * this.state.assemblytreearr.length + 'px', overflow: "auto" }}>
                              <DirectoryTree
                                checkable
                                onCheck={this.onCheckA.bind(this)}
                                checkedKeys={this.state.checkedKeysC}
                                selectedKeys={[]}
                                // onSelect={this.onTreeSelectA.bind(this)}
                                multiple
                              >
                                {loop(this.state.treeDataconstrut)}
                              </DirectoryTree>
                            </div>
                          </Panel>
                        )
                      }else if(item.Name=="设备资产"){
                        return (
                          <Panel header={item.Name} key={item.ID} style={customPanelStyle} extra={genExtra("genExtracheckD")}>
                            <div className="assetviewtree" style={{ height: clientHeight - 50 -80 - 40 * this.state.assemblytreearr.length + 'px', overflow: "auto" }}>
                              <DirectoryTree
                                checkable
                                onCheck={this.onCheckA.bind(this)}
                                checkedKeys={this.state.checkedKeysD}
                                selectedKeys={[]}
                                // onSelect={this.onTreeSelectA.bind(this)}
                                multiple
                              >
                                {loop(this.state.treeDataequipasset)}
                              </DirectoryTree>
                            </div>
                          </Panel>
                        )
                      }
                    })
                  }
                </Collapse>
              </div>
            </div>
          </Resizable>

          <div className="assetsviewcenter" ref="assetsviewcenter" style={{
            height: clientHeight - 50 + 'px',
            width: this.state.assetsviewcenterw + 'px'
          }}>
            <h2 className="KKS">
              <span>KKS树</span>
              <span onClick={this.kksshushou.bind(this)} className="kksshousuo"></span>
            </h2>
            <div className="KKSsearch" >
              <Search
                placeholder="请输入关键字搜索"
                onSearch={this.searchkks.bind(this)}
                onChange={(e)=>{console.log(e); this.setState({searchValue:e.target.value})}}
                value={this.state.searchValue}
                style={{ width: "100%", height: '30px', background: "none" }}
              />
              <div className="kkssele">
                <Select style={{ width: "80%", height: "28px", background: "none" }} labelInValue onChange={(value) => {
                  this.setState({
                    kksselekey: value.key
                  })
                }}>
                  {
                    this.state.initialdataarr.map((item, index) => {
                      return (<Option value={item.ClassificationName} key={item.ClassificationName}>{item.Remark}</Option>)
                    })
                  }
                </Select>
                <span className="btnkks" onClick={this.togongneng.bind(this)}></span>
              </div>
            </div>
            <div className="tree" style={{ height: clientHeight - 50 - 80 - 70 + 'px', overflow: "auto" }}>
              <DirectoryTree
                multiple
                onExpand={this.onExpand.bind(this)}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onSelect={this.onTreeSelectB.bind(this)}>
                {loop1(this.state.treeDataB)}
              </DirectoryTree>
            </div>
          </div>


        </div>
        <div className="loadprogress" ref="loadprogress"><Progress strokeWidth={15} type="circle" percent={parseInt((this.state.loadingcount / this.state.begincount) * 100)} status={(this.state.loadingcount / this.state.begincount) == 1 ? "success" : 'active'} /></div>

        <div className="assetsviewright" ref="assetsviewright" style={{ maxHeight: clientHeight - 50 - 16 + 'px' }}>
          <Tabs activekey={this.state.activetabsKey}
            onChange={(key) => {
              this.setState({ activetabsKey: key })
            }}
            onTabClick={(key) => {
              if (key == this.state.activetabsKey) {
                $('.alltabsshuxing').eq(parseInt(key) - 1).toggle()
                if ($('.alltabsshuxing').eq(parseInt(key) - 1).css("display") == "block") {
                  $('.assetsviewright').css("height", clientHeight - 50 - 16 + 'px')
                } else {
                  $('.assetsviewright').css("height", "80px")
                }
              } else {
                if ($('.alltabsshuxing').eq(parseInt(key) - 1).css("display") == "none") {
                  $('.alltabsshuxing').eq(parseInt(key) - 1).css("display", "block")
                }
              }
            }}
          >
            <TabPane tab={<span className="assetsviewright1">
              {
                this.state.activetabsKey == "1" ?
                  <img src={require('../../assets/img/基本信息2.png')} /> :
                  <img src={require('../../assets/img/基本信息.png')} />
              }
            </span>} key="1"
            >
              <div className="shuxing1 alltabsshuxing" style={{ height: clientHeight - 50 - 80 - 20 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.BasicAttributedata}
                  columns={this.columns}
                  pagination={false}
                  scroll={{ y: clientHeight - 50 - 80 - 20 - 40 - 120 + 'px' }}
                />
                <span className="twoweima">
                  <img src="" style={{ width: "100px", height: "100px" }} />
                </span>
              </div>
            </TabPane>
            <TabPane tab={<span className="assetsviewright2">
              {
                this.state.activetabsKey == "2" ?
                  <img src={require('../../assets/img/供应商2.png')} /> :
                  <img src={require('../../assets/img/供应商.png')} />
              }
            </span>} key="2"
            >
              <div className="shuxing2 alltabsshuxing" style={{ height: clientHeight - 50 - 80 - 20 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.Supplierdata}
                  columns={this.columns1}
                  pagination={false}
                  scroll={{ y: clientHeight - 50 - 80 - 20 - 35 + 'px' }}
                />
              </div>
            </TabPane>
            <TabPane tab={<span className="assetsviewright3">
              {
                this.state.activetabsKey == "3" ?
                  <img src={require('../../assets/img/责任相关2.png')} /> :
                  <img src={require('../../assets/img/责任相关.png')} />
              }
            </span>} key="3"
            >
              <div className="shuxing3 alltabsshuxing" style={{ height: clientHeight - 50 - 80 - 20 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.DutyRelativedata}
                  columns={this.columns2}
                  pagination={false}
                  scroll={{ y: clientHeight - 50 - 80 - 20 - 35 + 'px' }}
                />
              </div>
            </TabPane>
            <TabPane tab={<span className="assetsviewright4">
              {
                this.state.activetabsKey == "4" ?
                  <img src={require('../../assets/img/技术参数2.png')} /> :
                  <img src={require('../../assets/img/技术参数.png')} />
              }
            </span>} key="4"
            >
              <div className="shuxing4 alltabsshuxing" style={{ height: clientHeight - 50 - 80 - 20 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.TechParamaterdata}
                  columns={this.columns3}
                  pagination={false}
                  scroll={{ y: clientHeight - 50 - 80 - 20 - 35 + 'px' }}
                />
              </div>
            </TabPane>
            <TabPane tab={<span className="assetsviewright5">
              {
                this.state.activetabsKey == "5" ?
                  <img src={require('../../assets/img/文档关联2.png')} /> :
                  <img src={require('../../assets/img/文档关联.png')} />
              }
            </span>} key="5"
            >
              <div className="shuxing5 alltabsshuxing" style={{ height: clientHeight - 50 - 80 - 20 + 'px' }}>
               <table className="tableheader">
                    <tbody>
                      <tr>
                        <th>图号</th>
                        <th>文档名称</th>
                      </tr>
                    </tbody>
                  </table>
                  <Collapse accordion activeKey={this.state.tableactiveKey} onChange={this.Collapsecallback.bind(this)}>
                    {
                      this.state.FileTypearr.map((item, index) => {
                        return (
                          <Panel header={item.Name} key={item.Num}>
                            <div className="listscroll" style={{ height: clientHeight - 50 - 80  - 20 -30 - 35 * (Number(this.state.FileTypearr.length)) }}>
                              <Table
                                size="small"
                                showHeader={false}
                                pagination={false}
                                scroll={{ y:clientHeight - 50 - 80 - 20 -30 - 35 * (Number(this.state.FileTypearr.length)) }}
                                columns={this.columns4}
                                rowClassName={this.setRowClassName.bind(this)}
                                onRow={(record) => {
                                  return {
                                    onClick: this.rowclick.bind(this, record)
                                  }
                                }}
                                dataSource={this.state.RelativeDocdatasele}
                              />
                            </div>
                          </Panel>
                        )
                      })
                    }
                  </Collapse>
                  
                {/* <Table
                  size="small"
                  dataSource={this.state.RelativeDocdata}
                  columns={this.columns4}
                  pagination={false}
                  rowClassName={this.setRowClassName.bind(this)}
                  onRow={(record) => {
                    return {
                      onClick: this.rowclick.bind(this, record)
                    }
                  }}
                  scroll={{ y: clientHeight - 50 - 80 - 20 - 35 + 'px' }}
                /> */}
              </div>
            </TabPane>
          </Tabs>
        </div>

        <div className="treeshoufang">
          <span ref="treeshoufangzhang" onClick={this.zhuangpeishufang.bind(this)}>装配树</span>
          <span ref="treeshoufangkks" onClick={this.kksshufang.bind(this)}>KKS树</span>
        </div>
      </section >

    )
  }
}
export default Firstdata;