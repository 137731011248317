import {Change_clientHW ,Change_userData, Change_configImage,Change_ModuleData,Change_deptData} from './actionTypes'
var clientH = document.documentElement.clientHeight
var clientW = document.documentElement.clientWidth
const defaultState = {
    clientHeight: clientH,
    clientWidth: clientW,
    userdata:[],
    deptdata:[],
    moduledata:[]
} //默认值
export default (state = defaultState, action) => {
    // console.log(state,action)
    if(action.type == Change_clientHW){
        let newState = JSON.parse(JSON.stringify(state))
        newState.clientHeight = action.clientHeight
        newState.clientWidth = action.clientWidth
        return newState
    }
    if(action.type == Change_userData){
        let newState = JSON.parse(JSON.stringify(state))
        newState.userdata = action.userdata
        return newState
    }
    if(action.type == Change_deptData){
        let newState = JSON.parse(JSON.stringify(state))
        newState.deptdata = action.deptdata
        return newState
    }
    if(action.type == Change_configImage){
        let newState = JSON.parse(JSON.stringify(state))
        newState.ProjectconfigimageUrl = action.ProjectconfigimageUrl
        newState.CompanyconfigimageUrl = action.CompanyconfigimageUrl
        newState.configID = action.configID
        newState.configVersion = action.configVersion
        return newState
    }
    if(action.type == Change_ModuleData){
        let newState = JSON.parse(JSON.stringify(state))
        newState.moduledata = action.moduledata
        return newState
    }
    return state;
}