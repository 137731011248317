import React from 'react';
import {
  Table, Input, Tree, Tooltip, Form, Button, Select, Tabs, Drawer, Modal, Row, Col,
  Spin, DatePicker, message, Upload, Icon
} from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import { onCellf } from '../../comfunc'
import $ from 'jquery'
import echarts from 'echarts';
import reqwest from 'reqwest';
import './style.scss'
import moment from 'moment'
// import "moment/locale/zh-cn"
// moment.locale('zh-cn')
import Draggable from 'react-draggable';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { getButtonLimit } from '../../utils/getbuttonlimit'
import store from '../../store';
const { Option } = Select;
const TabPane = Tabs.TabPane;
const { TreeNode, DirectoryTree } = Tree;
const { Search, TextArea } = Input;
const { confirm } = Modal

class WisdomDiagnosisModelConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      counflag:false,
      activeDrags: 0,
      controlledPosition: {
        x: 0, y: 0
      },
      controlledPositionA: {
        x: 0, y: 0
      },

      service: '',
      uname: '',
      //树
      treedataA: [],
      visible: false,
      Code: '',//选择的编码
      detaillistIdA: '',
      //原理ifrmae得src
      yuanlisrc: '',
      //上传文件
      fileList: [],
      uploading: false,

      //表
      dataSource: [],

      //计算文件
      dataSourcejisuan: [],
      addedit: '',
      addeditname: '',
      visible: false,
      formiptobj: {},
      jiantunameipt: '',//上传计算文件名称

      //计算工具
      dataSourcejisuantool: [],
      //页签
      activeKey: '1',

      selectedRowKeysA: [],
      selectedRowKeysB: [],


      //模型简图
      dataSourcemodel: [],
      detaillistIdmodel: '',
      detaillistIdmodelobj: {},
      modelimgsrc: '',
      fileListA: [],
      uploadingA: false,
      modelimgflag: '',

      //关联文件
      dataSourcerelative: [],
      pageIndex: 1,
      fileListB: [],
      uploadingB: false,

      //诊断工况
      Software:[],
      detaillistIdGK:"",
      equipmentinitarr:[],
      detaillistIdGKPara:""
    };
    this.columns = [
      {
        title: '编号',
        dataIndex: 'Code',
        key: 'Code',
        width: '50%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '50%',
        onCell: onCellf,
        render: this.renderf
      },
    ]

    this.columnsjisuan = [
      {
        title: '编号',
        dataIndex: 'Number',
        key: 'Number',
        width: '15%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '计算软件',
        dataIndex: 'SoftwareName',
        key: 'SoftwareName',
        width: '20%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '计算文件名称',
        dataIndex: 'FileName',
        key: 'FileName',
        width: '30%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '计算文件版本',
        dataIndex: 'Version',
        key: 'Version',
        width: '20%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '上传人员',
        dataIndex: 'Operation',
        key: 'Operation',
        width: '15%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      }
    ]

    this.columnsmodel = [
      {
        title: '编号',
        dataIndex: 'Row',
        key: 'Row',
        width: '20%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '名称',
        dataIndex: 'FileName',
        key: 'FileName',
        width: '80%',
        onCell: onCellf,
        render: this.renderf

      },
    ]

    this.columnsrelative = [
      {
        title: '文件名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '40%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '文件编号',
        dataIndex: 'Code',
        key: 'Code',
        width: '40%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '文件类型',
        dataIndex: 'MimeTypeGif',
        key: 'MimeTypeGif',
        width: '20%',
        render: (text) => {
          return (<span>{text.split('.')[0]}</span>)
          // if (text == "1") {
          //   return (<span className="tabledatacenter">设计文档</span>)
          // } else if (text == "2") {
          //   return (<span className="tabledatacenter">建造文档</span>)
          // } else if (text == "3") {
          //   return (<span className="tabledatacenter">运维文档</span>)
          // }
        }
      },
    ]

    this.equipmentinitcolumns = [
      {
        title: '序号',
        dataIndex: 'Row',
        key: 'Row',
        width: '15%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{index + 1}</span>
        }
      },
      {
        title: '工况名称',
        dataIndex: 'WorkingConditionName',
        key: 'WorkingConditionName',
        width: '50%',
      },
      {
        title: '工况编码',
        dataIndex: 'WorkingConditionNum',
        key: 'WorkingConditionNum',
        width: '35%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
    ]

    this.equipmentinitparacolumns = [
      {
        title: '序号',
        dataIndex: 'Row',
        key: 'Row',
        width: '10%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{index + 1}</span>
        }
      },
      {
        title: '参数名称',
        dataIndex: 'InputName',
        key: 'InputName',
        width: '25%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '参数编码',
        dataIndex: 'InputNum',
        key: 'InputNum',
        width: '20%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '参数类型',
        dataIndex: 'InputType',
        key: 'InputType',
        width: '15%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '参数单位',
        dataIndex: 'InputUnit',
        key: 'InputUnit',
        width: '10%',
      },
      {
        title: '数量',
        dataIndex: 'Value',
        key: 'Value',
        width: '10%',
      },
    ]
    this.MouseWheelHandler = this.MouseWheelHandler.bind(this)
    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>)
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(74)

    var service = sessionStorage.getItem('service')
    var uname = sessionStorage.getItem('uname')
    this.setState({
      service: service,
      uname: uname
    })

    //诊断模型装配
    axios.get('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=1').then(data => {
      console.log('诊断模型装配列表', conversion(data))
      this.setState({
        dataSource: conversion(data)
      })
    })


    // var myimage1 = document.getElementById("swiptu");
    // if ( myimage1 && myimage1.addEventListener) {
    //   // IE9, Chrome, Safari, Opera
    //   myimage1.addEventListener("mousewheel", this.MouseWheelHandler.bind(this,'swiptu'), false);
    //   // Firefox
    //   myimage1.addEventListener("DOMMouseScroll", this.MouseWheelHandler.bind(this,'swiptu'), false);
    // }
    // // IE 6/7/8
    // else myimage1.attachEvent("onmousewheel", this.MouseWheelHandler.bind(this,'swiptu'));
  }
  MouseWheelHandler(key, e) {
    var myimage = document.getElementById(key);
    // cross-browser wheel delta
    var e = window.event || e; // old IE support
    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
    //现在我们直接决定图片的大小范围。以下代码将图片的宽度范围设置在50-800个像素之间
    myimage.style.width = Math.max(50, Math.min(2000, myimage.width + (30 * delta))) + "px";
    // 最后一点，我们在方法中返回false是为了终止标准的鼠标滚轮事件处理，以防它上下滑动网页
    return false;
  }

  zhuangpeishushou() {

  }


  //树删除
  handleDeltree() {
    var _this = this
    if (this.state.detaillistId) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/IntelligentDecision/RegularWork.aspx?flag=5', {
            id: _this.state.detaillistId
          }).then(data => {
            console.log(data)
            if (data.data.success == true) {
              //定期工作配置
              axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=2').then(data => {
                console.log('定期工作配置列表', conversion(data))
                _this.setState({
                  dataSource1: conversion(data),
                  dataSource1fu: conversion(data),
                  detaillistId: ""
                }, function () {
                  var arr = _this.state.dataSource1fu
                  var newarr = []
                  for (let i = 0; i < arr.length; i++) {
                    if (arr[i].WorkType == _this.state.configtestsele) {
                      newarr.push(arr[i])
                    }
                  }
                  _this.setState({
                    dataSource1sele: newarr,
                    detaillistId: ''
                  })
                  message.success('删除成功！')
                })
              })
            }
          })
        },
        onCancel() {
          _this.setState({
            detaillistId: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }
  //诊断模型配置选择
  senddataA(record) {
    console.log(record)
    this.setState({
      detaillistIdA: record.ID,
      Code: record.Code,
      counflag:true
    }, function () {
      //原理图src
      this.getSourceImg()
      //计算文件
      axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=3', {
        Code: this.state.Code
      }).then(data => {
        console.log(conversion(data))
        this.setState({
          dataSourcejisuan: conversion(data)
        })
      })
      //计算工具
      axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=9', {
        Code: this.state.Code
      }).then(data => {
        console.log('计算工具', conversion(data))
        this.setState({
          dataSourcejisuantool: conversion(data)
        })
      })
      //模型简图
      axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=7', {
        Code: this.state.Code
      }).then(data => {
        console.log('模型简图', conversion(data))
        this.setState({
          dataSourcemodel: conversion(data)
        })
      })
      //关联文件
      axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=10', {
        id: this.state.detaillistIdA
      }).then(data => {
        console.log('关联文件', conversion(data))
        this.setState({
          dataSourcerelative: conversion(data)
        })
      })
      axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=1', {
        EquipmentCode: record.Code
      }).then(data => {
        this.setState({
          Software: conversion(data)
        })
      })
      //工况数据
      axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=2', {
        ID: this.state.detaillistIdA
      }).then(data => {
        // console.log('工况下拉框初始化数据', conversion(data))
        this.setState({
          equipmentinitarr: conversion(data),
          counflag:false
        })
      })
    })
  }

  setRowClassNameA = (record) => {
    return record.ID === this.state.detaillistIdA ? 'clickRowStyl' : 'clickRowSty';
  }

  //获取原理图
  getSourceImg() {
    axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=2', {
      Code: this.state.Code
    }).then(data => {
      console.log(data.data)
      if (data.data.indexOf('默认') > -1) {
        this.setState({
          yuanlisrc: ""
        })
      } else {
        this.setState({
          yuanlisrc: data.data.slice(6)
        })
      }
    })
  }
  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files[]', file);
    });
    this.setState({
      uploading: true,
    });
    //
    if (this.state.Code) {
      if (this.state.yuanlisrc == "") {
        reqwest({
          url: `/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=1&Code=${this.state.Code}&type=1`,
          method: 'post',
          processData: false,
          data: formData,
          success: (text) => {
            console.log(text)
            if (typeof text == "string" && text.indexOf('true') > -1) {
              this.getSourceImg()
              this.setState({
                fileList: [],
                uploading: false,
              });
              message.success('上传成功！');
            }
          },
          error: () => {
            this.setState({
              uploading: false,
            });
            message.error('上传失败！');
          },
        });
      } else {
        reqwest({
          url: `/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=1&Code=${this.state.Code}&type=2`,
          method: 'post',
          processData: false,
          data: formData,
          success: (text) => {
            console.log(text)
            if (typeof text == "string" && text.indexOf('true') > -1) {
              this.getSourceImg()
              this.setState({
                fileList: [],
                uploading: false,
              });
              message.success('上传成功！');
            }
          },
          error: () => {
            this.setState({
              uploading: false,
            });
            message.error('上传失败！');
          },
        });
      }
    }
  };

  //计算文件选择
  senddatajisuan(record) {
    console.log(record)
    this.setState({
      detaillistIdjisuan: record.ID,
    })
  }

  setRowClassNamejisuan = (record) => {
    return record.ID === this.state.detaillistIdjisuan ? 'clickRowStyl' : 'clickRowSty';
  }

  //计算文件添加删除
  handleAddjisuan() {
    if (this.state.Code) {
      this.setState({
        addedit: '添加',
        addeditname: '计算文件',
        visible: true,
        formiptobj: {},
        fileListB: []
      }, function () {
        this.props.form.resetFields()
      })
    } else {
      message.error('请选择！')
    }

  }

  onClose = () => {
    this.setState({
      visible: false,
      fileListB: [],
    }, function () {
      this.props.form.resetFields();
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values)
      for (var i in values) {
        var value = values[i]
        if (value == undefined) {
          values[i] = ""
        } else if (value.label) {
          values[i] = value.label
        } else if (value._d) {
          values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
        } else {
          values[i] = values[i]
        }
      }

      if (!err) {
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "计算文件") {
            values.ToolCodeNum = this.state.dataSourcejisuantool[0].ToolCodeNum
            values.Code = this.state.Code

            const { fileListB } = this.state;
            const formData = new FormData();
            fileListB.forEach(file => {
              formData.append('files[]', file);
            });
            this.setState({
              uploadingB: true,
            });
            reqwest({
              url: `/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=4&ToolCodeNum=${values.ToolCodeNum}&Code=${values.Code}&Number=${values.Number}&Version=${values.Version}`,
              method: 'post',
              processData: false,
              data: formData,
              success: (text) => {
                console.log(text, typeof text)
                if (typeof text == "string" && text.indexOf('true') > -1) {
                  axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=3', {
                    Code: this.state.Code
                  }).then(data => {
                    console.log(conversion(data))
                    this.setState({
                      dataSourcejisuan: conversion(data),
                      visible: false,
                      fileListB: [],
                      uploadingB: false,
                    }, function () {
                      // message.success('添加成功！')
                    })
                  })
                }

              },
              error: (err) => {
                console.log(err)
                this.setState({
                  uploadingB: false,
                });
                message.error('上传失败！');
              },
            });
          }else if (this.state.addeditname == "诊断工况") {
            values.ID = this.state.detaillistIdA
            axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=4', values).then(data => {
              console.log(data)
              if (data.data.success==true) {
                //工况下拉框初始化数据
                axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=2', {
                  ID: this.state.detaillistIdA
                }).then(data => {
                  console.log('工况下拉框初始化数据', conversion(data))
                  this.setState({
                    equipmentinitarr: conversion(data),
                    visible: false
                  }, function () {
                    // message.success('添加成功！')
                  })
                })
              }else if(typeof data.data == "string" && data.data.indexOf('err')>-1){
                message.error('此编号已存在！')
              }
            })
          } 

        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "诊断工况参数") {
            values.ID = this.state.detaillistIdGKPara
            axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=6', values).then(data => {
              console.log(data)
              if (data.data.success==true) {
                //工况下拉框初始化数据
                axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=3', {
                  WDId: this.state.detaillistIdGK,
                  SoftwareCode: this.state.Software[0].SoftwareName,
                }).then(data => {
                  console.log('工况下拉框初始化数据', conversion(data))
                  this.setState({
                    equipmentinitparaarr: conversion(data),
                    visible: false,
                    detaillistIdGKPara: ''
                  }, function () {
                    // message.success('添加成功！')
                  })
                })

              }
            })
          }
        }
      }
    });
  };
  handleDeljisuan() {
    var _this = this
    var key = this.state.detaillistIdjisuan
    if (this.state.detaillistIdjisuan) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=5', {
            ID: _this.state.detaillistIdjisuan
          }).then(data => {
            console.log(data)
            if (data.data.success == true) {
              const dataSource = [..._this.state.dataSourcejisuan];
              _this.setState({ dataSourcejisuan: dataSource.filter(item => item.ID !== key), detaillistIdjisuan: "" },
                function () {
                  message.success('删除成功！')
                });
            }
          })
        },
        onCancel() {
          _this.setState({
            detaillistIdjisuan: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }
  

  //关联文档选中
  // senddata(record) {
  //   console.log(record)
  //   this.setState({
  //     detaillistId: record.ID,
  //     Code: record.Code,
  //     formiptobj: record
  //   }, function () {
  //     this.props.form.resetFields()
  //   })
  // }

  // setRowClassName = (record) => {
  //   return record.ID === this.state.detaillistId ? 'clickRowStyl' : 'clickRowSty';
  // }

  //模型简图添加
  modelAdd() {
    this.setState({
      jiantunameipt: '',
      modelimgflag: '添加',
      fileListA: []
    }, function () {
      this.refs.tuupload.style.display = "block"
      console.log(this.state.fileListA)
    })
  }
  //模型简图编辑
  modelEdit() {
    if (this.state.detaillistIdmodel) {
      var name = this.state.detaillistIdmodelobj.FileName
      this.setState({
        jiantunameipt: name,
        modelimgflag: '编辑',
        fileListA: []
      }, function () {
        this.refs.tuupload.style.display = "block"
      })
    } else {
      message.error('请选择简图！')
    }

  }
  closetuupload() {
    this.setState({
      uploadingA: false,
      fileListA: []
    })
    this.refs.tuupload.style.display = "none"
  }
  //选中
  senddatamodel(record) {
    console.log(record)
    this.setState({
      detaillistIdmodel: record.ID,
      detaillistIdmodelobj: record,
      modelimgsrc: record.FilePath.slice(6)
    })
  }
  setRowClassNamemodel = (record) => {
    return record.ID === this.state.detaillistIdmodel ? 'clickRowStyl' : 'clickRowSty';
  }
  modelDel() {
    var _this = this
    var key = this.state.detaillistIdmodel
    if (this.state.detaillistIdmodel) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=8', {
            ID: _this.state.detaillistIdmodel
          }).then(data => {
            console.log(data)
            if (data.data.success == true) {
              const dataSource = [..._this.state.dataSourcemodel];
              _this.setState({ dataSourcemodel: dataSource.filter(item => item.ID !== key), detaillistIdmodel: "", modelimgsrc: "" },
                function () {
                  message.success('删除成功！')
                });
            }
          })
        },
        onCancel() {
          _this.setState({
            detaillistIdmodel: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }

  }
  //模型简图上传
  handleUploadA = () => {
    const { fileListA } = this.state;
    const formData = new FormData();
    fileListA.forEach(file => {
      formData.append('files[]', file);
    });
    this.setState({
      uploadingA: true,
    });
    //
    if (this.state.detaillistIdA) {
      if (this.state.modelimgflag == "添加") {
        reqwest({
          url: `/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=6&Code=${this.state.Code}&type=1&FileName=${this.state.jiantunameipt}`,
          method: 'post',
          processData: false,
          data: formData,
          success: (text) => {
            console.log(text)
            if (typeof text == "string" && text.indexOf('true') > -1) {
              axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=7', {
                Code: this.state.Code
              }).then(data => {
                console.log('模型简图', conversion(data))
                this.setState({
                  dataSourcemodel: conversion(data),
                  fileListA: [],
                  uploadingA: false,
                }, function () {
                  message.success('上传成功！');
                  this.refs.tuupload.style.display = "none"
                })
              })
            }
          },
          error: (err) => {
            console.log(err)
            this.setState({
              uploadingA: false,
              fileListA: []
            });
            message.error('上传失败！');
          },
        });
      } else if (this.state.modelimgflag == "编辑") {
        if (this.state.detaillistIdmodel) {
          reqwest({
            url: `/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=6&ID=${this.state.detaillistIdmodel}&Code=${this.state.Code}&type=2&FileName=${this.state.jiantunameipt}`,
            method: 'post',
            processData: false,
            data: formData,
            success: (text) => {
              if (typeof text == "string" && text.indexOf('true') > -1) {
                //模型简图
                axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelSetting.aspx?flag=7', {
                  Code: this.state.Code
                }).then(data => {
                  console.log('模型简图', conversion(data))
                  this.setState({
                    dataSourcemodel: conversion(data),
                    fileListA: [],
                    uploadingA: false,
                    detaillistIdmodel: '',
                    modelimgsrc: ''
                  }, function () {
                    message.success('上传成功！');
                    this.refs.tuupload.style.display = "none"
                  })
                })
              }
            },
            error: (err) => {
              console.log(err)
              this.setState({
                uploadingA: false,
                fileListA: []
              });
              message.error('上传失败！');
            },
          });
        }
      }
    }
  };

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDrag(e, ui) {
    e.preventDefault();
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  handleStartA() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStopA() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    e.preventDefault();
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  pageChange(page, pageSize) {
    this.setState({
      pageIndex: Number(page.current)
    })
  }

  //诊断工况
  senddatagk(record) {
    console.log(record)
    this.setState({
      detaillistIdGK: record.ID,
      formiptobj: record
    }, function () {
      this.props.form.resetFields()
    })
    axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=3', {
      WDId: record.ID,
      SoftwareCode: this.state.Software[0].SoftwareName
    }).then(data => {
      console.log(conversion(data))
      this.setState({
        equipmentinitparaarr: conversion(data)
      })
    })
  }

  setRowClassNamegk = (record) => {
    return record.ID === this.state.detaillistIdGK ? 'clickRowStyl' : 'clickRowSty';
  }
  //诊断工况参数
  senddatagkA(record) {
    console.log(record)
    this.setState({
      detaillistIdGKPara: record.ID,
      formiptobj: record
    }, function () {
      this.props.form.resetFields()
    })
  }

  setRowClassNamegkA = (record) => {
    return record.ID === this.state.detaillistIdGKPara ? 'clickRowStyl' : 'clickRowSty';
  }
  //诊断工况
  handleAdd(){
    this.setState({
      visible: true,
      formiptobj: {},
      addeditname: '诊断工况',
      addedit: '添加'
    })
  }
  //工况添加合删除
  handleDel() {
    var _this = this
    var key = this.state.detaillistIdGK
    if (this.state.detaillistIdGK) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=5', {
            ID: _this.state.detaillistIdGK
          }).then(data => {
            console.log(data)
            if (data.data.success==true) {
              const dataSource = [..._this.state.equipmentinitarr];
              _this.setState({ equipmentinitarr: dataSource.filter(item => item.ID !== key), detaillistIdGK: "",equipmentinitparaarr:[],detaillistIdGKPara:"" },
                function () {
                  message.success('删除成功！')
                });
            }
          })
        },
        onCancel() {
          _this.setState({
            detaillistIdGK: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }
  handleEdit(){
    if (this.state.detaillistIdGKPara) {
      this.setState({
        visible: true,
        addeditname: '诊断工况参数',
        addedit: '编辑'
      })
    } else {
      message.error('请选择!')
    }
  }
  render() {
    const { uploading, fileList, uploadingA, fileListA, uploadingB, fileListB } = this.state;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      multiple: false,
      fileList,
    };
    const propsA = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListA.indexOf(file);
          const newFileList = state.fileListA.slice();
          newFileList.splice(index, 1);
          return {
            fileListA: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileListA: [...state.fileListA, file],
        }));
        return false;
      },
      // showUploadList:false,
      multiple: false,
      fileListA,
    };
    const propsB = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileListB.indexOf(file);
          const newFileList = state.fileListB.slice();
          newFileList.splice(index, 1);
          return {
            fileListB: newFileList,
          };
        });
      },
      multiple: false,
      beforeUpload: file => {
        this.setState(state => ({
          fileListB: [...state.fileListB, file],
        }));
        return false;
      },
      fileListB,
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <section className="wisdomdiagnosismodelconfig" style={{ height: clientHeight - 50 + 'px' }}>
        
        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight-50 + 'px' }}>
        </Spin>
        <div className="wisdomdiagnosismodelconfigleft" ref="wisdomdiagnosismodelconfigleft" style={{ height: clientHeight - 50 + 'px' }}>
          <h2 className="h2title">
            <span>诊断模型装配</span>
            <span onClick={this.zhuangpeishushou.bind(this)} className="zhuangpeishushousuo"></span>
          </h2>
          <div className="wisdomdiagnosismodelconfigtree" style={{ height: clientHeight - 50 - 30 + 'px' }}>
            {/* <span className="treeaction">
              <Button onClick={this.handleAdd.bind(this)}>添加</Button>
              <Button onClick={this.handleEdit.bind(this)}>编辑</Button>
              <Button onClick={this.handleDeltree.bind(this)}>删除</Button>
            </span> */}
            <div className="listscroll" style={{ height: clientHeight - 50 - 30 + 'px' }}>
              <Table
                size="small"
                rowKey={record => record.ID}
                dataSource={this.state.dataSource}
                columns={this.columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: this.senddataA.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassNameA}
                scroll={{ y: clientHeight - 50 - 30 - 20 - 30 + 'px' }}
              />
            </div>
          </div>
        </div>

        <Drawer
          title={this.state.addeditname + '' + this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            {
              this.state.addeditname == "计算文件" ?
                <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="计算文件名称">
                        {getFieldDecorator('Number', {
                          // initialValue: this.state.formiptobj['FileName'],
                          rules: [{ required: true, message: '请输入计算文件名称' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="版本号">
                        {getFieldDecorator('Version', {
                          // initialValue: this.state.formiptobj['Version'],
                          rules: [{ required: false, message: '请输入版本号' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Upload {...propsB} className="selectpicture">
                    <Button>
                      {/* <Icon type="upload" /> */}
                      选择文件
                   </Button>
                  </Upload>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>

                      <Button
                        className="uploadpicture"
                        htmlType="submit"
                        type="primary"
                        onClick={this.handleSubmit}
                        disabled={fileListB.length === 0}
                        loading={uploadingB}
                      >
                        {uploadingB ? '上传中' : '上传'}
                      </Button>
                      {/* <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                      </Button> */}
                    </Form.Item>
                  </Row>
                </Form>
                : this.state.addeditname == "诊断工况" ?
                <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="工况名称">
                        {getFieldDecorator('WorkingConditionName', {
                          // initialValue: this.state.formiptobj['fileName'],
                          rules: [{ required: true, message: '请输入工况名称' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="工况编码">
                        {getFieldDecorator('WorkingConditionNum', {
                          // initialValue: this.state.formiptobj['version'],
                          rules: [{ required: true, message: '请输入工况编码' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                        </Button>
                    </Form.Item>
                  </Row>
                </Form>
                : this.state.addeditname == "诊断工况参数" ?
                  <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="工况参数名称">
                          {getFieldDecorator('InputName', {
                            initialValue: this.state.formiptobj['InputName'],
                            rules: [{ required: true, message: '请输入工况名称' }],
                          })(
                            <Input disabled />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="工况参数数量">
                          {getFieldDecorator('Value', {
                            initialValue: this.state.formiptobj['Value'],
                            rules: [{ required: true, message: '请输入工况参数数量' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
                        </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                  : ""
            }
          </div>
        </Drawer>

        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStartA.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStopA.bind(this)}>
          <div className="tuupload" ref="tuupload">
            <span className="closetuupload" onClick={this.closetuupload.bind(this)}>x</span>
            <div className="FileName">
              简图名称: <Input className="jiantunameipt" value={this.state.jiantunameipt}
                onChange={(e) => { this.setState({ jiantunameipt: e.target.value }) }}
                onMouseDown={(e) => { e.stopPropagation() }}
              />
            </div>
            <div className="upfilelist">
              <Upload {...propsA} className="selectpicture">
                <Button>
                  {/* <Icon type="upload" /> */}
                  选择文件
                </Button>
              </Upload>
            </div>
            <div className="action">
              <Button
                className="uploadpicture"
                type="primary"
                onClick={this.handleUploadA}
                disabled={fileListA.length === 0}
                loading={uploadingA}
              >
                {uploadingA ? '上传中' : '上传'}
              </Button>
            </div>
          </div>
        </Draggable>

        <div className="wisdomdiagnosismodelconfigright" ref="wisdomdiagnosismodelconfigright"
          style={{ height: clientHeight - 50 + 'px' }}
        >
          <Tabs type="card" onChange={(key) => {
            this.setState({
              activeKey: key,
              activeDrags: 0,
              controlledPosition: {
                x: 0, y: 0
              }
            }, function () {
              if (this.state.activeKey == "3") {

                var myimage = document.getElementById("swiplun");
                if (myimage.addEventListener) {
                  // IE9, Chrome, Safari, Opera
                  myimage.addEventListener("mousewheel", this.MouseWheelHandler.bind(this, 'swiplun'), false);
                  // Firefox
                  myimage.addEventListener("DOMMouseScroll", this.MouseWheelHandler.bind(this, 'swiplun'), false);
                }
                // IE 6/7/8
                else myimage.attachEvent("onmousewheel", this.MouseWheelHandler.bind(this, 'swiplun'));

              }
            })
          }} activeKey={this.state.activeKey}>
            <TabPane tab="原理图" key="1">
              <div style={{ height: clientHeight - 50 - 30 + 'px' }}
                className="wisdomdiagnosismodelconfigrightcon modelconfigrightcon1">
                <div className="yuanlitu" style={{ height: clientHeight - 50 - 35 + 'px' }}>
                  <iframe title="myiframe" src={this.state.yuanlisrc} id="assetsviewframe" style={{
                    width: "100%", height: clientHeight - 50 - 35 + 'px',
                    maxHeight: clientHeight - 50 - 35 + 'px', maxWidth: "100%",
                  }}></iframe>
                  {/* <div className="yuanliimg" style={{height: clientHeight - 50 - 35 + 'px'}}>
                    <Draggable
                      position={this.state.controlledPosition}
                      onStart={this.handleStart.bind(this)}
                      onDrag={this.handleDrag.bind(this)}
                      onStop={this.handleStop.bind(this)}>
                      <img src={this.state.yuanlisrc}
                            id="swiptu"
                            style={{
                              width: 'auto', height: 'auto', minWidth: "50%", minHeight: "50%"
                            }} />

                    </Draggable>
                  </div> */}
                  <div className="action" style={{display:this.state.buttonarr.indexOf('上传原理图')>-1?"inline-block":"none"}}>
                    <Upload {...props} className="selectpicture">
                      <Button>
                        {/* <Icon type="upload" /> */}
                        选择文件
                    </Button>
                    </Upload>
                    <Button
                      className="uploadpicture"
                      type="primary"
                      onClick={this.handleUpload}
                      disabled={fileList.length === 0}
                      loading={uploading}
                    >
                      {uploading ? '上传中' : '上传'}
                    </Button>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="计算文件" key="2">
              <div className="modelconfigrightcon1" style={{ height: clientHeight - 50 - 30 + 'px' }}>
                <div className="listscroll" style={{ height: clientHeight - 50 - 30 - 72 + 'px' }}>
                  <Table
                    size="small"
                    dataSource={this.state.dataSourcejisuan}
                    columns={this.columnsjisuan}
                    pagination={false}
                    onRow={(record) => {
                      return {
                        onClick: this.senddatajisuan.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassNamejisuan}
                    scroll={{ y: clientHeight - 50 - 30 - 70 - 32 + 'px' }}
                  />
                </div>
                <div className="action">
                  <Button style={{display:this.state.buttonarr.indexOf('文件添加')>-1?"inline-block":"none"}} onClick={this.handleAddjisuan.bind(this)}>添加</Button>
                  <Button style={{display:this.state.buttonarr.indexOf('文件删除')>-1?"inline-block":"none"}} onClick={this.handleDeljisuan.bind(this)}>删除</Button>
                </div>
              </div>

            </TabPane>
            <TabPane tab="模型简图" key="3">
              <div style={{ height: clientHeight - 50 - 30 + 'px' }} className="wisdomdiagnosismodelconfigrightcon modelconfigrightcon3">
                <div className="left">
                  {/* <Button>保存</Button> <span  onClick={this.shushou.bind(this)} className="kksshousuo"></span>*/}
                  <h2>简图列表  <span>
                    <Button style={{display:this.state.buttonarr.indexOf('简图添加')>-1?"inline-block":"none"}} onClick={this.modelAdd.bind(this)}>添加</Button>
                    <Button style={{display:this.state.buttonarr.indexOf('简图编辑')>-1?"inline-block":"none"}} onClick={this.modelEdit.bind(this)}>编辑</Button>
                    <Button style={{display:this.state.buttonarr.indexOf('简图删除')>-1?"inline-block":"none"}} onClick={this.modelDel.bind(this)}>删除</Button></span></h2>
                  <div className="leftlist">
                    <div className="listscroll" style={{ height: clientHeight - 50 - 32 + 'px' }}>
                      <Table
                        size="small"
                        dataSource={this.state.dataSourcemodel}
                        columns={this.columnsmodel}
                        pagination={false}
                        onRow={(record) => {
                          return {
                            onClick: this.senddatamodel.bind(this, record)
                          }
                        }}
                        rowClassName={this.setRowClassNamemodel}
                        scroll={{ y: clientHeight - 50 - 30 - 40 + 'px' }}
                      />
                    </div>
                  </div>
                </div>
                <div className="right" style={{ height: clientHeight - 50 - 30 + 'px' }}>
                  <div className="rightcon" style={{ height: clientHeight - 50 - 30 - 20 + 'px' }}>
                    <Draggable
                      position={this.state.controlledPosition}
                      onStart={this.handleStart.bind(this)}
                      onDrag={this.handleDrag.bind(this)}
                      onStop={this.handleStop.bind(this)}>
                      <img src={this.state.modelimgsrc}
                        id="swiplun"
                        style={{
                          width: 'auto', height: 'auto', minWidth: "50%", minHeight: "50%"
                        }} />

                    </Draggable>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="关联文档" key="4">
              <div className="listpage wisdomdiagnosismodelconfigrightcon" style={{ height: clientHeight - 50 - 33 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.dataSourcerelative}
                  columns={this.columnsrelative}
                  onChange={this.pageChange.bind(this)}
                  pagination={{
                    pageSize: parseInt((clientHeight - 50 - 30 - 30 - 50) / 26),
                    current: this.state.pageIndex,
                    showTotal: (total) => `共 ${total} 条`,
                    total: Number(this.state.dataSourcerelative.length),
                  }}
                // onRow={(record) => {
                //   return {
                //     onClick: this.senddata.bind(this, record)
                //   }
                // }}
                // rowClassName={this.setRowClassName}
                // scroll={{ y: clientHeight - 50 - 30 -40 + 'px' }}
                />
              </div>
            </TabPane>
            <TabPane tab="诊断工况" key="5">
              <div className="gongkuang wisdomdiagnosismodelconfigrightcon" style={{ height: clientHeight - 50- 33 + 'px' }}>
                <div className="listleft">
                  <h2>工况数据
                  <span className="treeaction">
                      <Button style={{display:this.state.buttonarr.indexOf('工况添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this)}>添加</Button>
                      <Button style={{display:this.state.buttonarr.indexOf('工况删除')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this)}>删除</Button>
                    </span>
                  </h2>
                  <div className="listscroll">
                    <Table
                      size="small"
                      dataSource={this.state.equipmentinitarr}
                      columns={this.equipmentinitcolumns}
                      pagination={false}
                      onRow={(record) => {
                        return {
                          onClick: this.senddatagk.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassNamegk}
                      scroll={{ y: clientHeight - 50 - 40  - 30 -40 }}
                    />
                  </div>
                </div>
                <div className="listright">
                  <h2>工况参数数据
                  <span className="treeaction">
                      <Button style={{display:this.state.buttonarr.indexOf('工况参数编辑')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this)}>编辑</Button>
                    </span>
                  </h2>
                  <div className="listscroll">
                    <Table
                      size="small"
                      dataSource={this.state.equipmentinitparaarr}
                      columns={this.equipmentinitparacolumns}
                      pagination={false}
                      onRow={(record) => {
                        return {
                          onClick: this.senddatagkA.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassNamegkA}
                      scroll={{ y: clientHeight - 50 - 40  - 30 -40 }}
                    />
                  </div>
                </div>
              </div>

            </TabPane>
          </Tabs>
        </div>
      </section >
    )
  }
}
const WisdomDiagnosisModelConfigapp = Form.create()(WisdomDiagnosisModelConfig);
export default WisdomDiagnosisModelConfigapp;

