import {Change_clientHW,Change_userData,Change_configImage,Change_ModuleData,Change_deptData } from './actionTypes'

export const changeclienthwact = (clientHeight,clientWidth) => ({
    type:Change_clientHW,
    clientHeight:clientHeight,
    clientWidth:clientWidth
})

export const changeuserdata = (data)=>({
    type: Change_userData,
    userdata:data
})
export const changedeptdata = (data)=>({
    type: Change_deptData,
    deptdata:data
})
export const changemoduledata = (data)=>({
    type: Change_ModuleData,
    moduledata:data
})

export const changeconfigimage =(ProjectconfigimageUrl,CompanyconfigimageUrl,configID,configVersion)=>({
    type:Change_configImage,
    ProjectconfigimageUrl:ProjectconfigimageUrl,
    CompanyconfigimageUrl:CompanyconfigimageUrl,
    configID:configID,
    configVersion:configVersion
})