import axios from 'axios'
export const getButtonLimit = async function (id) {
    var buttonarr = []
    await axios.post('/DATA/WebSet/T_PermitMenu.aspx?flag=4', {
        ParentId: id
    }).then(res => {
        // console.log(res)
        if (res.data.success) {
            var arr = res.data.data
            for (let i = 0; i < arr.length; i++) {
                buttonarr.push(arr[i].Name)
            }
        }
    })
    // console.log(buttonarr)
    return buttonarr
}