import React, { Component, Fragment } from 'react';
import { Select, Input, Button, Radio, Tree, Table, Modal, Popconfirm,Drawer, Form, Row, Col, message,Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { changedeptdata } from '../../../store/actionCreators'
//多条件搜索
import $ from 'jquery'
import moment from 'moment';
import axios from 'axios';
import './style.scss'
import { Resizable } from 'react-resizable';
import Search from 'antd/lib/input/Search';
import store from '../../../store';
import { conversion } from '../../../converse';
import { toOrganTreeData } from '../../../utils/menutree'
import { onCellf } from '../../../comfunc'
import { getButtonLimit } from '../../../utils/getbuttonlimit'
const { Option } = Select;
const { TextArea } = Input
const { TreeNode } = Tree;
const { confirm } = Modal;
class OrganizationManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      iptobj: {},
      addeditflag: "",
      visible: false,

      data: [],
      datalength: 0,
      pageIndex: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 50 - 30 - 30) / 28),
      columns: [
        {
          dataIndex: 'Name',
          key: 'Name',
          title: '名称',
          width: '30%',
        },
        
        {
          dataIndex: 'CreatePeople',
          key: 'CreatePeople',
          title: '创建人',
          width: '10%',
        },
        {
          dataIndex: 'CreateTime',
          key: 'CreateTime',
          title: '创建时间',
          width: '20%',onCell: onCellf,
          render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
       
        },
        {
          dataIndex: 'Functions',
          key: 'Functions',
          title: '职能',
          width: '35%',onCell: onCellf,
          render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
       
        }
      ],
      rowId: "",
      rowObj: {},
      attr: {
        ID: 'ID',
        rootId: '0',
        Name: 'Name',
        Type:'Type',
        ParentId: 'ParentId',
        Leaf: 'Leaf',
        Functions: 'Functions',
        CreatePeople: 'CreatePeople',
        CreateTime: 'CreateTime',
        UpdatePeople: 'UpdatePeople',
        UpdateTime: 'UpdateTime'
      },
      leftd: store.getState().clientWidth,

      
      data1:[],
      data1total:[],
      pageIndex1:1,

      data2:[],
      data2total:[],
      pageIndex2:1,

      selectedRowKeys:[],
      selectedRows:[],
      selectedRowKeys1:[],
      selectedRows1:[],
      selectedRowKeys2:[],
      selectedRows2:[]
    }
    
    this.columnsper = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '15%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '用户姓名',
        dataIndex: 'UserName',
        key: 'UserName',
        width: '35%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '职务',
        dataIndex: 'Post',
        key: 'Post',
        width: '35%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      }
    ]
    this.columnsall = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '15%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex2 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '用户姓名',
        dataIndex: 'UserName',
        key: 'UserName',
        width: '35%',onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
     
      },
      {
        title: '职务',
        dataIndex: 'Post',
        key: 'Post',
        width: '35%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      }
    ]
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(27)

    this.getListData()
  }

  test(arr) {
    var arr = arr
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].children.length > 0) {
        this.test(arr[i].children)
      } else {
        delete arr[i].children
      }
    }
  }
  testfirst(arr) {
    this.test(arr)
    return arr
  }
  getListData() {
    axios.get('/DATA/WebSet/CompanyOrganization.aspx?flag=13').then(res => {
      //console.log('菜单树', res)
      var arr = res.data.data
      var newarr = toOrganTreeData(arr, this.state.attr)
      //console.log(newarr)
      this.setState({
        data: this.testfirst(newarr)
      })
    })
  }
  listSearch(v){
    if(v==""){
      this.getListData()
    }else{
      axios.post('/DATA/WebSet/CompanyOrganization.aspx?flag=17',{
        Search:v
      }).then(res=>{
        this.setState({
          data: res.data.data
        })
      })
    }
  }
  shouldComponentUpdate() {
    return true;
  }

  //删除
  delModal() {
    if(this.state.selectedRowKeys.length>0){
      var _this = this
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post(`/DATA/WebSet/CompanyOrganization.aspx?flag=12`,{
            ID:_this.state.selectedRowKeys.join(',')
          }).then((res) => {
            if (res.data == true || res.data.success) {
              
              axios.get('/DATA/WebSet/CompanyOrganization.aspx?flag=13').then(res=>{
                const action = changedeptdata(res.data.data)
                store.dispatch(action)
              })
              _this.getListData()
              _this.setState({
                rowId: "",
                rowObj: {}
              })
              message.success('删除成功！')
            }
          })
        },
        onCancel() {

        },
      });
    }else{
      message.error('请选择!')
    }
  }
  //编辑
  editModal() {
    if(this.state.rowId){
      var record = this.state.rowObj
      this.setState({
        visible: true,
        hasclick: false,
        addeditflag: "编辑",
        iptobj: record,
      }, function () {
        this.props.form.resetFields();
      })
    }else{
      message.error('请选择!')
    }
  }
  //增加
  addModal() {
    var obj = {}
    this.setState({
      visible: true,
      addeditflag: "新增",
      iptobj: obj
    }, function () {
      this.props.form.resetFields();
    })
  }
  //提交
  modalSave = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      //console.log(err, values)
      if (!err) {
        var flag = 0
        if (this.state.addeditflag == "编辑") {
          flag = 11
          values.ID = this.state.rowId
        } else if (this.state.addeditflag == "新增") {
          flag = 10
          values.ParentId = this.state.rowId?this.state.rowId:"0"
        }
        axios.post(`/DATA/WebSet/CompanyOrganization.aspx?flag=${flag}`,values).then((res) => {
          //console.log(res)
          if (res.data.success) {
            this.setState({
              visible: false
            }, function () {
              this.getListData()
              
              axios.get('/DATA/WebSet/CompanyOrganization.aspx?flag=13').then(res=>{
                const action = changedeptdata(res.data.data)
                store.dispatch(action)
              })
              message.success('保存成功！')
            })
          }else if(typeof res.data == "string"){
            message.error(res.data)
          }
        })
      }
    })
  }
  //重置
  modalReset() {
    this.setState({
      iptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  //取消
  modalCancel() {
    this.setState({
      visible: false
    })
  }

  //获取角色
  getRoleuser(){
    //角色
    axios.post(`/DATA/WebSet/CompanyOrganization.aspx?flag=15`,{
      ParentId:this.state.rowId
    }).then(res=>{
      //console.log(res)
      if(res.data.success){
        this.setState({
          data1:res.data.data,
          selectedRowKeys1:[],
          selectedRows1:[]
        })
      }
    })
      //全部
      axios.post(`/DATA/WebSet/CompanyOrganization.aspx?flag=15`,{
        Name:""
      }).then(res=>{
        //console.log(res)
        if(res.data.success){
          this.setState({
            data2:res.data.data,
            selectedRowKeys2:[],
            selectedRows2:[]
          })
        }
      })
  }
  //查看成员
  viewPersonel(){
    if(this.state.rowId){
      this.setState({
        leftd:600
      },function(){
        $('.organizationmanage .organizationmanager').css("display","block")
      })
    }else{
      message.error('请选择!')
    }
  }

  //表格拉伸
  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  senddata(record) {
    if (this.state.rowId == record.ID) {
      this.setState({
        rowId: "",
        rowObj: {},
      })
    } else {
      var arr = []
      var arr1 = []
      arr.push(record.ID)
      arr1.push(record)
      this.setState({
        rowId: record.ID,
        rowObj: record,
        selectedRowKeys:arr,
        selectedRows:arr1
      },function(){
        this.getRoleuser()
      })
    }
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }

  
  onClose() {
    this.setState({
      visible: false,
      iptobj: {}
    })
  }
  //移出
  ShiftOut(){
    var _this = this
    if(this.state.selectedRowKeys1.length>0){
      confirm({
        title: '确定要移除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post(`/DATA/WebSet/CompanyOrganization.aspx?flag=16`,{
            ParentId:_this.state.rowId,
            ID:_this.state.selectedRowKeys1.join(',')
          }).then(res=>{
            //console.log(res)
            if(res.data.success){
              _this.getRoleuser()
              message.success('操作成功！')
            }else if(typeof res.data =="string"){
              message.error(res.data)
            }
          })
        },
        onCancel() {

        },
      });
    }else{
      message.error('请选择！')
    }
  }
  //移入
  ShiftIn(){
    var _this = this
    if(this.state.selectedRowKeys2.length>0){
      confirm({
        title: '确定要移入吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post(`/DATA/WebSet/CompanyOrganization.aspx?flag=14`,{
            // Name:_this.state.rowObj.Name,
            ParentId:_this.state.rowId,
            ID:_this.state.selectedRowKeys2.join(',')
          }).then(res=>{
            //console.log(res)
            if(res.data.success){
              _this.getRoleuser()
              message.success('操作成功！')
            }else if(typeof res.data =="string"){
              message.error(res.data)
            }
          })
        },
        onCancel() {

        },
      });
    }else{
      message.error('请选择！')
    }
  }

  
  pageChange(key,page, pageSize) {
    //console.log(key,page, pageSize)
    if(key=="列表"){
      this.setState({
        pageIndex:Number(page.current)
      })
    }else if(key=="角色成员"){
      this.setState({
        pageIndex1:Number(page.current)
      })
    }else if(key=="用户"){
      this.setState({
        pageIndex2:Number(page.current)
      })
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientH = store.getState().clientHeight
    const clientW = store.getState().clientWidth
    const {selectedRowKeys, selectedRowKeys1, selectedRowKeys2 } = this.state
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({
          selectedRowKeys:selectedRowKeys,
          selectedRows:selectedRows,
          rowId:selectedRowKeys[selectedRowKeys.length-1],
          rowObj:selectedRows[selectedRows.length-1]
        })
      },
      onSelect: (record, selected, selectedRows) => {
        // //console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        // //console.log(selected, selectedRows, changeRows);
      }
    }
    const rowSelection1 = {
      selectedRowKeys: selectedRowKeys1,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys1:selectedRowKeys,
          selectedRows1:selectedRows
        })
      },
      onSelect: (record, selected, selectedRows) => {
        // //console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        // //console.log(selected, selectedRows, changeRows);
      },
    };
    const rowSelection2 = {
      selectedRowKeys: selectedRowKeys2,
      onChange: (selectedRowKeys, selectedRows) => {
        //console.log(selectedRowKeys, selectedRows)
        this.setState({
          selectedRowKeys2:selectedRowKeys,
          selectedRows2:selectedRows
        })
      }
    };
    return (
      <Fragment>
        <div className="organizationmanage">
          <Drawer
            title={'机构' + this.state.addeditflag}
            onClose={this.onClose.bind(this)}
            visible={this.state.visible}
            className="Drawercommon"
          >
            <div className="Drawercommonaddedit" style={{ height: clientH - 50 - 55 + 'px' }}>
              <Form className="datadictionaryform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="名&nbsp;&nbsp;&nbsp;&nbsp;称:">
                      {getFieldDecorator('Name', {
                        initialValue: this.state.iptobj["Name"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input style={{width:"180px"}}/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="职&nbsp;&nbsp;&nbsp;&nbsp;能:">
                      {getFieldDecorator('Functions', {
                        initialValue: this.state.iptobj["Functions"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <TextArea style={{width:"180px"}} rows={4}/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="formbutton">
                  <Form.Item>
                    <Button htmlType="submit" onClick={this.modalSave.bind(this)} type="primary">
                      保存
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Drawer>

          <Resizable className="box1" axis="x"
            minConstraints={[500, 500]} maxConstraints={[800, 800]}
            width={this.state.leftd}
            height={clientH - 90}
            onResize={(event, { element, size }) => {
              // //console.log(size)
              this.setState({ leftd: size.width, centerd: clientW - size.width });
            }}>

            <div className="organizationmanagel" style={{width:this.state.leftd +'px'}}>
              <div className="listsearch">
                <div className="listsearchl">
                  <Search placeholder="请输入搜索内容" onSearch={this.listSearch.bind(this)}/>
                </div>
                <div className="listactionr">
                  <Button className="addaction" style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.addModal.bind(this)}><span className="listaddtu"></span>添加</Button>
                  <Button className="addaction" style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.editModal.bind(this)}><span className="listaddtu"></span>编辑</Button>
                  <Button className="addaction" style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.delModal.bind(this)}><span className="listaddtu"></span>删除</Button>
                  <Button className="addaction" style={{display:this.state.buttonarr.indexOf('成员')>-1?"inline-block":"none"}} onClick={this.viewPersonel.bind(this)}><span className="listaddtu"></span>成员</Button>
                </div>
              </div>
              <div className="listscroll" style={{ height:this.state.leftd >1300? clientH - 50 - 40 - 30 + 'px' :clientH - 50 - 40 - 20 + 'px',margin:this.state.leftd >1300 ?"10px":""}}>
                <Table
                  rowSelection={rowSelection}
                  rowKey={record => record.ID}
                  columns={this.state.columns}
                  dataSource={this.state.data}
                  size="small"
                  scroll={{y:clientH-50 - 40 -30 -30}}
                  pagination={false }
                  // pagination={{
                  //   showQuickJumper: true,
                  //   pageSize: this.state.pageSize,
                  //   current: this.state.pageIndex,
                  //   showTotal: (total) => `共 ${total} 条`,
                  //   total: Number(this.state.datatotal),
                  // }}
                  // onChange={this.pageChange.bind(this, '列表')}
                  onRow={(record) => {
                    return {
                      onClick: this.senddata.bind(this, record)
                    }
                  }}
                  rowClassName={this.setRowClassName}
                />
              </div>
            </div>
          </Resizable>

          <div className="organizationmanager" style={{ width: clientW - this.state.leftd + 'px' }}>
            <h2>
              <span style={{color:"#f00"}}>{this.state.rowId?this.state.rowObj.Name:""}</span>
              <span 
            onClick={()=>{
              this.setState({
                leftd:store.getState().clientWidth
              },function(){
                $('.organizationmanager').css("display","none")
              })
            }}>关闭</span></h2>
            <div className="organizationmanagercon">
              <div className="organizationmanagerconl">
                <div className="listsearch">
                  <div className="listsearchl">
                    角色成员
                  </div>
                  <div className="listactionr">
                    <Button className="addaction" onClick={this.ShiftOut.bind(this)}><span className="listaddtu"></span>移出</Button>
                  </div>
                </div>
                <div className="listscroll" style={{ height: clientH - 50 - 40 - 30 + 'px' }}>
                  <Table
                    size="small"
                    rowSelection={rowSelection1}
                    rowKey={record => record.ID}
                    dataSource={this.state.data1}
                    columns={this.columnsper}
                    scroll={{ y: clientH - 50 - 70 - 30 - 60 }}
                    pagination={false}
                    // pagination={{
                    //   showQuickJumper: true,
                    //   pageSize: this.state.pageSize,
                    //   current: this.state.pageIndex1,
                    //   showTotal: (total) => `共 ${total} 条`,
                    //   total: Number(this.state.data1total),
                    // }}
                    // onChange={this.pageChange.bind(this, '角色成员')}
                  />
                </div>
              </div>
              <div className="organizationmanagerconr">

                <div className="listsearch">
                  <div className="listsearchl">
                    用户
                  </div>
                  <div className="listactionr">
                    <Button className="addaction" onClick={this.ShiftIn.bind(this)}><span className="listaddtu"></span>移入</Button>
                  </div>
                </div>
                <div className="listscroll" style={{ height: clientH - 50 - 40 - 30 + 'px' }}>
                  <Table
                    size="small"
                    rowSelection={rowSelection2}
                    rowKey={record => record.ID}
                    dataSource={this.state.data2}
                    columns={this.columnsall}
                    scroll={{ y: clientH - 50 - 70 - 30 - 60 }}
                    pagination={false}
                    // pagination={{
                    //   showQuickJumper: true,
                    //   pageSize: this.state.pageSize,
                    //   current: this.state.pageIndex2,
                    //   showTotal: (total) => `共 ${total} 条`,
                    //   total: Number(this.state.data2total),
                    // }}
                    // onChange={this.pageChange.bind(this, '用户')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const OrganizationManageapp = Form.create()(OrganizationManage)
export default OrganizationManageapp;

{/* <Modal
            title={"基础数据-菜单管理-" + this.state.addeditflag}
            visible={this.state.visible}
            footer={null}
            onCancel={this.modalCancel.bind(this)}
            width={686}
            height={400}
          >
            <div className="modalboxcon" >
              <div className="modalactionbutton">
                <Button style={{ display: this.state.addeditflag == "查看" ? "none" : "inline-block" }}
                  disabled={this.state.hasclick} onClick={this.modalSave.bind(this)}>提交</Button>
                <Button style={{ display: this.state.addeditflag == "查看" || this.state.addeditflag == "编辑" ? "none" : "inline-block" }}
                  onClick={this.modalReset.bind(this)}>重置</Button>
                <Button onClick={this.modalCancel.bind(this)}>关闭</Button>
              </div>
              <Form className="datadictionaryform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="权限名称:">
                      {getFieldDecorator('name', {
                        initialValue: this.state.iptobj["name"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="模块与功能:">
                      {getFieldDecorator('type', {
                        initialValue: this.state.iptobj["type"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Radio.Group >
                          <Radio value={0}>目录</Radio>
                          <Radio value={1}>菜单</Radio>
                          <Radio value={2}>按钮</Radio>
                        </Radio.Group >
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="接&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;口:">
                      {getFieldDecorator('url', {
                        initialValue: this.state.iptobj["url"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:">
                      {getFieldDecorator('code', {
                        initialValue: this.state.iptobj["code"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }]
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="textarea4">
                  <Col span={24}>
                    <Form.Item label="备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注:">
                      {getFieldDecorator('remark', {
                        initialValue: this.state.iptobj["remark"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <TextArea rows={4} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
           */}