function run(treeArrs, resData, atr) {
    //treeArrs 第一次排序
    var attr = atr//对象属性
    var resData = resData//数据
    if (resData.length > 0) {
      for (let i = 0; i < treeArrs.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (treeArrs[i].ID === resData[j][attr.ParentId]) {
            let obj = {
              ID: resData[j][attr.ID],
              ParentId: resData[j][attr.ParentId],
              Name: resData[j][attr.Name],
              Code: resData[j][attr.Code],
              ModuleType: resData[j][attr.ModuleType],
              Visable: resData[j][attr.Visable],
              Leaf: resData[j][attr.Leaf],
              RecordVersion: resData[j][attr.RecordVersion],
              Rank: resData[j][attr.Rank],
              IconCls:resData[j][attr.IconCls],
              OnClickIconCls:resData[j][attr.OnClickIconCls],
              children: [],
            };
            treeArrs[i].children.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        run(treeArrs[i].children, resData, attr);
      }
    }
  }
  export function toMenuTreeData(data, atr) {
    // console.log(data, atr)
    let tree = [];
    let resData = data;//数组
    let attr = atr //对象属性
    for (let i = 0; i < resData.length; i++) {
      if (resData[i].ParentId === attr.rootId) {
        let obj = {
          ID: resData[i][attr.ID],
          ParentId: resData[i][attr.ParentId],
          Name: resData[i][attr.Name],
          Code: resData[i][attr.Code],
          ModuleType: resData[i][attr.ModuleType],
          Visable: resData[i][attr.Visable],
          Leaf: resData[i][attr.Leaf],
          RecordVersion: resData[i][attr.RecordVersion],
          Rank: resData[i][attr.Rank],
          IconCls:resData[i][attr.IconCls],
          OnClickIconCls:resData[i][attr.OnClickIconCls],
          children: [],
        };
        tree.push(obj);
        resData.splice(i, 1);
        i--;
      }
    }
    run(tree, resData, attr);
    return tree;
  }


  function run1(treeArrs, resData, atr) {
    //treeArrs 第一次排序
    var attr = atr//对象属性
    var resData = resData//数据
    if (resData.length > 0) {
      for (let i = 0; i < treeArrs.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (treeArrs[i].ID === resData[j][attr.ParentId]) {
            let obj = {
              ID: resData[j][attr.ID],
              ParentId: resData[j][attr.ParentId],
              Name: resData[j][attr.Name],
              Functions: resData[j][attr.Functions],
              Type: resData[j][attr.Type],
              Leaf: resData[j][attr.Leaf],
              CreatePeople: resData[j][attr.CreatePeople],
              CreateTime: resData[j][attr.CreateTime],
              UpdatePeople:resData[j][attr.UpdatePeople],
              UpdateTime:resData[j][attr.UpdateTime],
              children: [],
            };
            treeArrs[i].children.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        run1(treeArrs[i].children, resData, attr);
      }
    }
  }
  export function toOrganTreeData(data, atr) {
    // console.log(data, atr)
    let tree = [];
    let resData = data;//数组
    let attr = atr //对象属性
    for (let i = 0; i < resData.length; i++) {
      if (resData[i].ParentId === attr.rootId) {
        let obj = {
          ID: resData[i][attr.ID],
          ParentId: resData[i][attr.ParentId],
          Name: resData[i][attr.Name],
          Functions: resData[i][attr.Functions],
          Type: resData[i][attr.Type],
          Leaf: resData[i][attr.Leaf],
          CreatePeople: resData[i][attr.CreatePeople],
          CreateTime: resData[i][attr.CreateTime],
          UpdatePeople:resData[i][attr.UpdatePeople],
          UpdateTime:resData[i][attr.UpdateTime],
          children: [],
        };
        tree.push(obj);
        resData.splice(i, 1);
        i--;
      }
    }
    run1(tree, resData, attr);
    return tree;
  }


  function run2(treeArrs, resData, atr) {
    //treeArrs 第一次排序
    var attr = atr//对象属性
    var resData = resData//数据
    if (resData.length > 0) {
      for (let i = 0; i < treeArrs.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (treeArrs[i].ID === resData[j][attr.Type]) {
            let obj = {
              ID: resData[j][attr.ID],
              Type: resData[j][attr.Type],
              Name: resData[j][attr.Name],
              Num: resData[j][attr.Num],
              Code: resData[j][attr.Code],
              Remark: resData[j][attr.Remark],
              children: [],
            };
            treeArrs[i].children.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        run2(treeArrs[i].children, resData, attr);
      }
    }
  }
  export function toDictionaryTreeData(data, atr) {
    // console.log(data, atr)
    let tree = [];
    let resData = data;//数组
    let attr = atr //对象属性
    for (let i = 0; i < resData.length; i++) {
      if (resData[i].Type === attr.rootId) {
        let obj = {
          ID: resData[i][attr.ID],
          Type: resData[i][attr.Type],
          Name: resData[i][attr.Name],
          Num: resData[i][attr.Num],
          Code: resData[i][attr.Code],
          Remark: resData[i][attr.Remark],
          children: [],
        };
        tree.push(obj);
        resData.splice(i, 1);
        i--;
      }
    }
    run2(tree, resData, attr);
    return tree;
  }
  