function run(treeArrs, resData, atr) {
  //treeArrs 第一次排序
  var attr = atr//对象属性
  var resData = resData//数据
  if (resData.length > 0) {
    for (let i = 0; i < treeArrs.length; i++) {
      for (let j = 0; j < resData.length; j++) {
        if (treeArrs[i].id === resData[j][attr.ParentId]) {
          let obj = {
            id: resData[j][attr.id],
            ParentId: resData[j][attr.ParentId],
            CodeName: resData[j][attr.CodeName],
            Rank: resData[j][attr.Rank],
            ReadName: resData[j][attr.ReadName],
            children: [],
          };
          treeArrs[i].children.push(obj);
          resData.splice(j, 1);
          j--;
        }
      }
      run(treeArrs[i].children, resData, attr);
    }
  }
}
export function toProTreeData(data, atr) {
  // console.log(data, atr)
  let tree = [];
  let resData = data;//数组
  let attr = atr //对象属性
  for (let i = 0; i < resData.length; i++) {
    if (resData[i].ParentId === attr.rootId) {
      let obj = {
        id: resData[i][attr.id],
        ParentId: resData[i][attr.ParentId],
        CodeName: resData[i][attr.CodeName],
        ReadName: resData[i][attr.ReadName],
        Rank: resData[i][attr.Rank],
        children: [],
      };
      tree.push(obj);
      resData.splice(i, 1);
      i--;
    }
  }
  run(tree, resData, attr);
  return tree;
}

function run1(treeArrs, resData, atr) {
  //treeArrs 第一次排序
  var attr = atr//对象属性
  var resData = resData//数据
  if (resData.length > 0) {
    for (let i = 0; i < treeArrs.length; i++) {
      for (let j = 0; j < resData.length; j++) {
        if (treeArrs[i].ID === resData[j][attr.ParentId]) {
          let obj = {
            ID: resData[j][attr.ID],
            ParentId: resData[j][attr.ParentId],
            Code: resData[j][attr.Code],
            Rank: resData[j][attr.Rank],
            Name: resData[j][attr.Name],
            Leaf: resData[j][attr.Leaf],
            RecordVersion: resData[j][attr.RecordVersion],
            UpdateTime: resData[j][attr.UpdateTime],
            UpdatePeople: resData[j][attr.UpdatePeople],
            CreateTime: resData[j][attr.CreateTime],
            CreatePeople: resData[j][attr.CreatePeople],
            children: [],
            Flag: resData[j][attr.Flag],
          };
          treeArrs[i].children.push(obj);
          resData.splice(j, 1);
          j--;
        }
      }
      run1(treeArrs[i].children, resData, attr);
    }
  }
}
export function toProTreeData1(data, atr) {
  // console.log(data, atr)
  let tree = [];
  let resData = data;//数组
  let attr = atr //对象属性
  for (let i = 0; i < resData.length; i++) {
    if (resData[i].ParentId === attr.rootId) {
      let obj = {
        ID: resData[i][attr.ID],
        ParentId: resData[i][attr.ParentId],
        Code: resData[i][attr.Code],
        Name: resData[i][attr.Name],
        Rank: resData[i][attr.Rank],
        Leaf: resData[i][attr.Leaf],
        RecordVersion: resData[i][attr.RecordVersion],
        CreatePeople: resData[i][attr.CreatePeople],
        CreateTime: resData[i][attr.CreateTime],
        UpdatePeople: resData[i][attr.UpdatePeople],
        UpdateTime: resData[i][attr.UpdateTime],
        Flag: resData[i][attr.Flag],
        children: [],
      };
      tree.push(obj);
      resData.splice(i, 1);
      i--;
    }
  }
  run1(tree, resData, attr);
  return tree;
}


function run2(treeArrs, resData, atr) {
  //treeArrs 第一次排序
  var attr = atr//对象属性
  var resData = resData//数据
  if (resData.length > 0) {
    for (let i = 0; i < treeArrs.length; i++) {
      for (let j = 0; j < resData.length; j++) {
        if (treeArrs[i].ID === resData[j][attr.ParentId]) {
          let obj = {
            ID: resData[j][attr.ID],
            ParentId: resData[j][attr.ParentId],
            Code: resData[j][attr.Code],
            Rank: resData[j][attr.Rank],
            Name: resData[j][attr.Name],
            Leaf: resData[j][attr.Leaf],
            treepath: treeArrs[i].treepath + '/' + resData[j][attr.Code],
            children: [],
          };
          treeArrs[i].children.push(obj);
          resData.splice(j, 1);
          j--;
        }
      }
      run2(treeArrs[i].children, resData, attr);
    }
  }
}
export function toProTreeData2(data, atr) {
  // console.log(data, atr)
  let tree = [];
  let resData = data;//数组
  let attr = atr //对象属性
  for (let i = 0; i < resData.length; i++) {
    if (resData[i].ParentId === attr.rootId) {
      let obj = {
        ID: resData[i][attr.ID],
        ParentId: resData[i][attr.ParentId],
        Code: resData[i][attr.Code],
        Name: resData[i][attr.Name],
        Rank: resData[i][attr.Rank],
        Leaf: resData[i][attr.Leaf],
        treepath: resData[i][attr.Code],
        children: [],
      };
      tree.push(obj);
      resData.splice(i, 1);
      i--;
    }
  }
  run2(tree, resData, attr);
  return tree;
}


function runList(treeArrs, resData, atr) {
  //treeArrs 第一次排序
  var attr = atr//对象属性
  var resData = resData//数据
  if (resData.length > 0) {
    for (let i = 0; i < treeArrs.length; i++) {
      for (let j = 0; j < resData.length; j++) {
        if (treeArrs[i].Code === resData[j][attr.ParentId]) {
          let obj = {
            ID: resData[j][attr.ID],
            ParentId: resData[j][attr.ParentId],
            Code: resData[j][attr.Code],
            Name: resData[j][attr.Name],
            ComponentModel: resData[j][attr.ComponentModel],
            Guid: resData[j][attr.Guid],
            InsulationMaterial: resData[j][attr.InsulationMaterial],
            InsulationThickness: resData[j][attr.InsulationThickness],
            KKS: resData[j][attr.KKS],
            Length: resData[j][attr.Length],
            Material: resData[j][attr.Material],
            NominalDiameter: resData[j][attr.NominalDiameter],
            RelationModel: resData[j][attr.RelationModel],
            Specification: resData[j][attr.Specification],
            Standard: resData[j][attr.Standard],
            TID: resData[j][attr.TID],
            TITParentIdD: resData[j][attr.TITParentIdD],
            Type: resData[j][attr.Type],
            Unit: resData[j][attr.Unit],
            UpdateTime: resData[j][attr.UpdateTime],
            UpdatePeople: resData[j][attr.UpdatePeople],
            Version: resData[j][attr.Version],
            WallThickness: resData[j][attr.WallThickness],
            children: [],
          };
          treeArrs[i].children.push(obj);
          resData.splice(j, 1);
          j--;
        }
      }
      runList(treeArrs[i].children, resData, attr);
    }
  }
}
export function toListTreeData(data, atr) {
  // console.log(data, atr)
  let tree = [];
  let resData = data;//数组
  let attr = atr //对象属性
  for (let i = 0; i < resData.length; i++) {
    if (resData[i].ParentId === attr.rootId) {
      let obj = {
        ID: resData[i][attr.ID],
        ParentId: resData[i][attr.ParentId],
        Code: resData[i][attr.Code],
        Name: resData[i][attr.Name],
        ComponentModel: resData[i][attr.ComponentModel],
        Guid: resData[i][attr.Guid],
        InsulationMaterial: resData[i][attr.InsulationMaterial],
        InsulationThickness: resData[i][attr.InsulationThickness],
        KKS: resData[i][attr.KKS],
        Length: resData[i][attr.Length],
        Material: resData[i][attr.Material],
        NominalDiameter: resData[i][attr.NominalDiameter],
        RelationModel: resData[i][attr.RelationModel],
        Specification: resData[i][attr.Specification],
        Standard: resData[i][attr.Standard],
        TID: resData[i][attr.TID],
        TITParentIdD: resData[i][attr.TITParentIdD],
        Type: resData[i][attr.Type],
        Unit: resData[i][attr.Unit],
        UpdateTime: resData[i][attr.UpdateTime],
        UpdatePeople: resData[i][attr.UpdatePeople],
        Version: resData[i][attr.Version],
        WallThickness: resData[i][attr.WallThickness],
        children: [],
      };
      tree.push(obj);
      resData.splice(i, 1);
      i--;
    }
  }
  // console.log(tree, resData)
  // if(resData && resData.length>0){
  //   runList(tree, resData, attr);
  // }
  // var obj = { tree:tree,resData:resData }
  // return obj;
  runList(tree, resData, attr);
  return tree;
}