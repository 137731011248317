import React, { Component, Fragment } from 'react';
import {changeuserdata} from '../../../store/actionCreators'
import { Select, Input, Button, Upload, Icon, DatePicker,Tooltip, InputNumber, Drawer, Tree, Table, Modal, Popconfirm, Form, Row, Col, message } from 'antd';
import 'antd/dist/antd.css';
//多条件搜索
import $ from 'jquery'
import moment from 'moment';
import axios from 'axios';
import './style.scss'
import { Resizable } from 'react-resizable';
import Search from 'antd/lib/input/Search';
import store from '../../../store';
import { onCellf } from '../../../comfunc'
import { conversion } from '../../../converse';
import { toMenuTreeData } from '../../../utils/menutree'
import { getButtonLimit } from '../../../utils/getbuttonlimit'
const { Option } = Select;
const { TextArea } = Input
const { TreeNode } = Tree;
const { confirm } = Modal
class UserManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      iptobj: {},
      addeditflag: "",
      visible: false,

      data: [],
      datafu: [],
      datatotal: 0,
      pageIndex: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 50 - 30 - 30) / 28),

      loading: false,
      imageUrl: "",
      imageFlag: "0",

      //初始化
      Departmentinitarr: [],
      Nativeinitarr: [],
      PositionalTitlesinitarr: [],
      Degreeinitarr: [],
    }
    this.columns = [
      {
        dataIndex: 'XuHao',
        key: 'XuHao',
        title: '序号',
        width: 80,
        render: (text, record, index) => {
          return (<span>{this.state.pageSize * (this.state.pageIndex - 1) + index + 1}</span>)
        }
      },
      {
        dataIndex: 'UserNumber',
        key: 'UserNumber',
        title: '账号',
        width: 150,
      },
      {
        dataIndex: 'UserName',
        key: 'UserName',
        title: '姓名',
        width: 150,
      },
      {
        dataIndex: 'AffiliatedCompany',
        key: 'AffiliatedCompany',
        title: '所属公司',
        width: 150,
      },
      {
        dataIndex: 'Department',
        key: 'Department',
        title: '部门',
        width: 250,
        onCell: onCellf,
        render: (text) => <Tooltip placement="left" title={text}>{text}</Tooltip>
      },
      {
        dataIndex: 'Post',
        key: 'Post',
        title: '岗位',
        render: (text) => {
          var arr = this.state.PositionalTitlesinitarr
          var str = ''
          for (let i = 0; i < arr.length; i++) {
            if (text == arr[i].ID) {
              str = arr[i].Name
            }
          }
          return (<span>{str}</span>)
        }
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        title: '操作',
        width: 160,
        render: (text, record) =>
          this.state.data.length >= 1 ? (
            <div className="listoperationa">
              <a style={{display:this.state.buttonarr.indexOf('查看')>-1?"inline-block":"none"}} onClick={this.viewInfo.bind(this, record)}>查看</a>
              <a style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.editModal.bind(this, record)}>编辑</a>
              <a style={{display:this.state.buttonarr.indexOf('重置密码')>-1?"inline-block":"none"}} onClick={() => this.resetPwd(record)}>重置密码</a>
              <a style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={() => this.toDellist(record)}>删除</a>
            </div>
          ) : null,
      }
    ]
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(26)

    this.getListData()

    // axios.get('/DATA/ParaSet/ParaSet.aspx?flag=1').then(data => {
    //   console.log('部门列表', conversion(data))
    //   this.setState({
    //     Departmentinitarr: conversion(data)
    //   })
    // })
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=3', {
      type: 2
    }).then(data => {
      // console.log('学历列表', data.data.data)
      this.setState({
        Degreeinitarr: data.data.data
      })
    })
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=3', {
      type: 5
    }).then(data => {
      // console.log('岗位列表', data.data.data)
      this.setState({
        PositionalTitlesinitarr: data.data.data
      })
    })
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=3', {
      type: 6
    }).then(data => {
      // console.log('民族列表', data.data.data)
      this.setState({
        Nativeinitarr: data.data.data
      })
    })
  }

  getListData() {
    axios.get('/DATA/WebSet/UserInfo.aspx?flag=10').then(data => {
      // console.log('用户数据', conversion(data))
      const action = changeuserdata(conversion(data))
      store.dispatch(action)

      var str = data.data
      this.setState({
        data: conversion(data),
        datafu: conversion(data),
        datatotal: Number(str.substring(12, str.indexOf(','))),
      })
    })
  }
  pageChange(page, pageSize) {
    this.setState({
      pageIndex: Number(page.current)
    })
  }
  shouldComponentUpdate() {
    return true;
  }

  //删除
  toDellist(record) {
    var _this = this
    confirm({
      title: '确定要删除吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post(`/DATA/WebSet/UserInfo.aspx?flag=13`, {
          ID: record.ID
        }).then((res) => {
          // console.log(res)
          if (res.data == true) {
            _this.getListData()
            _this.setState({
              rowId: "",
              rowObj: {}
            })
            message.success('删除成功！')
          }
        })
      },
      onCancel() {

      },
    });
  }
  //查看
  viewInfo(record) {
    // console.log(record)
    this.setState({
      visible: true,
      hasclick: false,
      addeditflag: "查看",
      iptobj: record,
      imageUrl: record.Photo
    }, function () {
      this.props.form.resetFields();
    })
  }
  resetPwd(record) {
    var _this = this
    confirm({
      title: '确定要重置密码吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post(`/DATA/WebSet/UserInfo.aspx?flag=14`, {
          ID: record.ID
        }).then((res) => {
          // console.log(res)
          if (res.data == true) {
            message.success('重置成功!')
          }
        })
      },
      onCancel() {

      },
    });
  }
  //编辑
  editModal(record) {
    // console.log(record)
    this.setState({
      visible: true,
      hasclick: false,
      addeditflag: "编辑",
      iptobj: record,
      imageUrl: record.Photo,
      imageFlag:"0"
    }, function () {
      this.props.form.resetFields();
    })
  }
  //增加
  addModal() {
    var obj = {}
    this.setState({
      visible: true,
      addeditflag: "新增",
      iptobj: obj,
      imageUrl:""
    }, function () {
      this.props.form.resetFields();
    })
  }
  //提交
  modalSave = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      // console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate() + ' ' + value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else if (value._i) {
            values[i] = value._i.split(' ')[1]
          } else {
            values[i] = values[i]
          }
        }
        var flag = 0
        if (this.state.addeditflag == "编辑") {
          flag = 12
          values.Photo = this.state.imageFlag=="1"?this.state.imageUrl:""
          values.ID = this.state.iptobj.ID
        } else if (this.state.addeditflag == "新增") {
          flag = 11
          values.Photo = this.state.imageUrl?this.state.imageUrl:""
        }
        axios.post(`/DATA/WebSet/UserInfo.aspx?flag=${flag}`, values).then((res) => {
          console.log(res)
          if (res.data == true) {
            this.setState({
              visible: false,
              imageUrl:""
            }, function () {
              this.getListData()
              message.success('保存成功！')
            })
          } else if (typeof res.data == "string" && res.data.indexOf('err') > -1) {
            message.error('该账号已存在!')
          }
        })
      }
    })
  }
  //重置
  modalReset() {
    this.setState({
      iptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  //取消
  modalCancel() {
    this.setState({
      visible: false
    })
  }
  senddata(record) {
    if (this.state.rowId == record.ID) {
      this.setState({
        rowId: "",
        rowObj: {},
      })
    } else {
      this.setState({
        rowId: record.ID,
        rowObj: record,
      })
    }
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }


  //图片上传
  getBase64(img, callback) {
    //console.log(img, callback)
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  //限制上传图片的格式和大小
  beforeUpload(file) {
    console.log(file)
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('超过10M限制，不允许上传!');
    }
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    const isBMP = file.type === 'image/bmp';
    if (!(isJPG || isJPEG || isGIF || isPNG || isBMP)) {
      message.error('只能上传JPG 、JPEG 、GIF、 PNG、 BMP格式的图片!')
    }
    return (isBMP || isGIF || isJPEG || isJPG || isPNG) && isLt2M;
  }
  shouldComponentUpdate() {
    return true;
  }
  handletuChange = (info) => {
    console.log(info)
    if (info.file.status === 'uploading') {
      this.setState({ loading: true, imageUrl: "" });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => this.setState({
        imageUrl,
        loading: false,
        imageFlag: '1'
      }, function () {
        console.log(this.state.imageUrl);
        // message.success(`${info.file.name} 上传成功`);
      }));
    } else if (info.file.status === 'error') {
      // message.error(`${info.file.name} 上传失败`);
    }
  }
  onClose() {
    this.setState({
      visible: false,
      iptobj: {},
      imageUrl:"",
      loading:false
    })
  }
  render() {
    const { imageUrl } = this.state
    const { getFieldDecorator } = this.props.form;
    const clientH = store.getState().clientHeight
    return (
      <Fragment>
        <div className="usermanage">

          <Drawer
            title={'用户' + this.state.addeditflag}
            onClose={this.onClose.bind(this)}
            visible={this.state.visible}
            className="Drawercommon DrawerUser"
            width={500}
          >
            <div className="Drawercommonaddedit" style={{ height: clientH - 50 - 55 + 'px' }}>
              <Form className="usermanageform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <div className="avatarpic">
                  <Upload
                    disabled={this.state.addeditflag == "查看" ? true : false}
                    name=""
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handletuChange}
                  >
                    {
                      imageUrl ?
                        <img src={imageUrl} style={{
                          width: "auto", height: "auto",
                          maxHeight: "178px",
                          maxWidth: "178px",
                        }} alt="" />
                        :
                        <div className="clickhereupload" style={{ width: "178px", height: "178px" }}>
                          <Icon type={this.state.loading ? 'loading' : 'plus'} />
                          <div className="ant-upload-text">点击此处上传头像</div>
                        </div>
                    }
                  </Upload>

                </div>
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="用户账号:">
                      {getFieldDecorator('UserNumber', {
                        initialValue: this.state.iptobj["UserNumber"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" || this.state.addeditflag == "编辑" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="用户姓名:">
                      {getFieldDecorator('UserName', {
                        initialValue: this.state.iptobj["UserName"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="联系电话:">
                      {getFieldDecorator('Mobile', {
                        initialValue: this.state.iptobj["Mobile"],
                        rules: [{ required: true, message: () => { return (<span></span>) } },
                        { pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/, message: '格式不正确!' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="所属公司:">
                      {getFieldDecorator('AffiliatedCompany', {
                        initialValue: this.state.iptobj["AffiliatedCompany"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {
                  this.state.addeditflag == "新增" ?
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:">
                          {getFieldDecorator('Password', {
                            initialValue: this.state.iptobj["Password"],
                            rules: [{ required: true, message: '该字段不能为空！' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row> : ""
                }
                <Row gutter={16}>
                  <Col span={36}>
                    <Form.Item label="政治面貌">
                      {getFieldDecorator('PoliticalStatus', {
                        initialValue: this.state.iptobj['PoliticalStatus'],
                        rules: [{ required: false, message: '请输入政治面貌' }],
                      })(
                        <Select disabled={this.state.addeditflag == "查看" ? true : false}>
                          <Option value="群众" key="群众">群众</Option>
                          <Option value="团员" key="团员">团员</Option>
                          <Option value="党员" key="党员">党员</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36}>
                    <Form.Item label="身份证号:">
                      {getFieldDecorator('IdNumber', {
                        initialValue: this.state.iptobj["IdNumber"],
                        rules: [{ required: false, message: () => { return (<span></span>) } },
                        { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '格式不正确!' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                {/*</Row>
                <Row gutter={16}>
                   <Col span={36}>
                    <Form.Item label="部&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门:">
                      {getFieldDecorator('Department', {
                        initialValue: this.state.iptobj['Department'],
                        rules: [{ required: false, message: '请选择所在部门' }],
                      })(
                        <Input disabled/>
                      )}
                    </Form.Item>
                  </Col> */}
                  <Col span={36}>
                    <Form.Item label="岗&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位:">
                      {getFieldDecorator('Post', {
                        initialValue: this.state.iptobj["Post"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Select disabled={this.state.addeditflag == "查看" ? true : false}>
                          {
                            this.state.PositionalTitlesinitarr.map((item, index) => {
                              return (
                                <Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                            })
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36}>
                    <Form.Item label="学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;历:">
                      {getFieldDecorator('Degree', {
                        initialValue: this.state.iptobj["Degree"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Select disabled={this.state.addeditflag == "查看" ? true : false}>
                          {/* <Option value="初中">初中</Option>
                          <Option value="高中">高中</Option>
                          <Option value="中专">中专</Option>
                          <Option value="大专">大专</Option>
                          <Option value="本科">本科</Option>
                          <Option value="硕士">硕士</Option>
                          <Option value="博士">博士</Option> */}

                          {
                            this.state.Degreeinitarr.map((item, index) => {
                              return (
                                <Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                            })
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36}>
                    <Form.Item label="专&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;业:">
                      {getFieldDecorator('Major', {
                        initialValue: this.state.iptobj["Major"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  {/* <Col span={36}>
                    <Form.Item label="职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;务:">
                      {getFieldDecorator('position', {
                        initialValue: this.state.iptobj["position"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col> */}
                  <Col span={36}>
                    <Form.Item label="职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称:">
                      {getFieldDecorator('PositionalTitles', {
                        initialValue: this.state.iptobj["PositionalTitles"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36}>
                    <Form.Item label="民&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;族:">
                      {getFieldDecorator('Native', {
                        initialValue: this.state.iptobj['Native'],
                        rules: [{ required: false, message: '请选择部门' }],
                      })(
                        <Select disabled={this.state.addeditflag == "查看" ? true : false}>
                          {
                            this.state.Nativeinitarr.map((item, index) => {
                              return (
                                <Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                            })
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36}>
                    <Form.Item label="家庭电话:">
                      {getFieldDecorator('Tel', {
                        initialValue: this.state.iptobj["Tel"],
                        rules: [{ required: false, message: '该字段不能为空！' },
                        { pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/, message: '格式不正确!' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="家庭住址:">
                      {getFieldDecorator('Residence', {
                        initialValue: this.state.iptobj["Residence"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="formbutton">
                  <Form.Item>
                    <Button htmlType="submit" onClick={this.modalSave.bind(this)} type="primary">
                      保存
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Drawer>

          <div className="listsearch">
            <div className="listsearchl">
              <Search placeholder="请输入搜索内容" onSearch={(v) => {
                var arr = this.state.datafu
                var newarr = []
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].UserName.indexOf(v) > -1 || arr[i].UserNumber.indexOf(v) > -1 || arr[i].AffiliatedCompany.indexOf(v) > -1
                  ) {
                    newarr.push(arr[i])
                  }
                }
                this.setState({
                  data: newarr,
                  datatotal: newarr.length,
                  pageIndex: 1
                })
              }} />
            </div>
            <div className="listactionr">
              <Button className="addaction" style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.addModal.bind(this)}><span className="listaddtu"></span>添加</Button>
            </div>
          </div>
          <div className="listpage" style={{ height: clientH - 50 - 40 - 30 + 'px' }}>
            <Table
              rowKey={record => record.ID}
              columns={this.columns}
              dataSource={this.state.data}
              size="small"
              // scroll={{y:clientH - 50 - 40 -20 -40}}
              onChange={this.pageChange.bind(this)}
              pagination={{
                simple:true,
                showQuickJumper: true,
                pageSize: this.state.pageSize,
                current: this.state.pageIndex,
                showTotal: (total) => `共 ${total} 条`,
                total: Number(this.state.datatotal),
              }}
              // onRow={(record) => {
              //   return {
              //     onClick: this.senddata.bind(this, record)
              //   }
              // }}
              // rowClassName={this.setRowClassName}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

const UserManageapp = Form.create()(UserManage)
export default UserManageapp;

{/* <Modal
            title={"用户管理-"+this.state.addeditflag}
            visible={this.state.visible}
            footer={null}
            onCancel={this.modalCancel.bind(this)}
            width={680}
            height={400}
          >
            <div className="modalboxcon">
              <div className="modalactionbutton">
                <Button style={{display:this.state.addeditflag=="查看"?"none":"inline-block"}} 
                disabled={this.state.hasclick} onClick={this.modalSave.bind(this)}>提交</Button>
                <Button style={{display:this.state.addeditflag=="查看"|| this.state.addeditflag=="编辑"?"none":"inline-block"}} 
                onClick={this.modalReset.bind(this)}>重置</Button>
                <Button onClick={this.modalCancel.bind(this)}>关闭</Button>
              </div>
              <Form className="usermanageform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <div className="avatarpic">
                  <Upload
                    disabled={this.state.addeditflag == "查看" ? true : false}
                    name=""
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handletuChange}
                  >
                    {
                      imageUrl ?
                        <img src={imageUrl} style={{
                          width: "auto", height: "auto",
                          maxHeight: "134px",
                          maxWidth: "152px",
                        }} alt="" />
                        :
                        <div className="clickhereupload" style={{ width: "130px", height: "150px" }}>
                          <Icon type={this.state.loading ? 'loading' : 'plus'} />
                          <div className="ant-upload-text">点击此处上传头像</div>
                        </div>
                    }
                  </Upload>

                </div>
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="用户账号:">
                      {getFieldDecorator('name', {
                        initialValue: this.state.iptobj["name"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名:">
                      {getFieldDecorator('nickName', {
                        initialValue: this.state.iptobj["nickName"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="身份证号:">
                      {getFieldDecorator('identificationNumber', {
                        initialValue: this.state.iptobj["identificationNumber"],
                        rules: [{ required: true, message: () => { return (<span></span>) } },
                        { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '格式不正确!' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36}>
                    <Form.Item label="出生日期:">
                      {getFieldDecorator('birth', {
                        initialValue: this.state.iptobj["birth"] ? moment(this.state.iptobj["birth"]) : null,
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <DatePicker disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36}>
                    <Form.Item label="性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别:">
                      {getFieldDecorator('sex', {
                        initialValue: this.state.iptobj["sex"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Select disabled={this.state.addeditflag == "查看" ? true : false}>
                          <Option value="男">男</Option>
                          <Option value="女">女</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36}>
                    <Form.Item label="文化程度:">
                      {getFieldDecorator('educationalLevel', {
                        initialValue: this.state.iptobj["educationalLevel"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Select disabled={this.state.addeditflag == "查看" ? true : false}>
                          <Option value="初中">初中</Option>
                          <Option value="高中">高中</Option>
                          <Option value="中专">中专</Option>
                          <Option value="大专">大专</Option>
                          <Option value="本科">本科</Option>
                          <Option value="硕士">硕士</Option>
                          <Option value="博士">博士</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36}>
                    <Form.Item label="专&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;业:">
                      {getFieldDecorator('major', {
                        initialValue: this.state.iptobj["major"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36}>
                    <Form.Item label="学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;制:">
                      {getFieldDecorator('academicYear', {
                        initialValue: this.state.iptobj["academicYear"],
                        rules: [{ required: false, message: '该字段不能为空！' }, { pattern: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/, message: '只能为正数!' }],
                      })(
                        <InputNumber disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36}>
                    <Form.Item label="职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;务:">
                      {getFieldDecorator('position', {
                        initialValue: this.state.iptobj["position"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36}>
                    <Form.Item label="职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称:">
                      {getFieldDecorator('jobTitle', {
                        initialValue: this.state.iptobj["jobTitle"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} >
                  <Col span={36}>
                    <Form.Item label="微&nbsp;信&nbsp;号:">
                      {getFieldDecorator('wechatNumber', {
                        initialValue: this.state.iptobj["wechatNumber"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="联系电话:">
                      {getFieldDecorator('mobile', {
                        initialValue: this.state.iptobj["mobile"],
                        rules: [{ required: true, message: () => { return (<span></span>) } },
                        { pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/, message: '格式不正确!' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="twokindcol">
                  <Col span={36}>
                    <Form.Item label="家庭电话:">
                      {getFieldDecorator('homeTelephone', {
                        initialValue: this.state.iptobj["homeTelephone"],
                        rules: [{ required: false, message: '该字段不能为空！' },
                        { pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/, message: '格式不正确!' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="家庭住址:">
                      {getFieldDecorator('homeAddress', {
                        initialValue: this.state.iptobj["homeAddress"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
           */}