import React, { Component } from 'react';
import {HashRouter,Route,Switch} from 'react-router-dom'
import Login from './routes/login/index';
import Home from './routes/home/index';
class App extends Component {
  componentDidMount(){
  }
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/login" component = {Login}/>
          <Route path="/" component = {Home}/>
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
