// imrc import React, { Component } from 'react';
// ccc 生成constructor代码结构

import React, { Component } from 'react';
import '../../login.css';
import axios from 'axios';
import $ from 'jquery';
import {message,Spin} from 'antd';
import {conversion} from '../../converse'
class App extends Component {
  constructor(props){
    super(props);
    this.state={
      groupdata:[],
      groupname:'',
      companydata:[],
      companyname:'',
      member:[],
      clientHeight:0,
      clientWidth:0,
      counflag:false
    }
    this.winresize=this.winresize.bind(this)
  }
  componentDidMount(){
    window.addEventListener("resize", this.winresize)
    var clientHeight = document.documentElement.clientHeight
    var clientWidth = document.documentElement.clientWidth
    // console.log(clientHeight,clientWidth)
    this.setState({
      clientHeight: clientHeight,
      clientWidth: clientWidth,
    })
    for(let i=0;i<$('.sele').length;i++){
      $('.sele').eq(i).click(function(){
        $('.select').eq(i).toggle()
      })
      $('.sele').eq(0).click(function(){
        $('.select').eq(1).css('display','none')
      })
      $('.sele').eq(1).click(function(){
        $('.select').eq(0).css('display','none')
      })
    }
    //Proxy error: Could not proxy request /DATA/ParaSet/Plant.aspx?flag=1 from localhost:3000 to http://192.168.5.8:6300 (ECONNREFUSED).
    // HTTP Error 400. The request hostname is invalid.
    // "proxy": "http://192.168.5.2:6300",
    axios.get('/DATA/ParaSet/Plant.aspx?flag=1').then(data =>{
      // console.log(conversion(data))
      this.setState({
        groupdata:conversion(data)
      })
    })
  }
  winresize() {
    var clientHeight = document.documentElement.clientHeight
    var clientWidth = document.documentElement.clientWidth
    this.setState({
      clientHeight: clientHeight,
      clientWidth: clientWidth,
    })
  }
  componentWillUnmount(){
    window.removeEventListener('resize',this.winresize)
  }
  //选择集团
  jtselect(data){
    $('.group').val('')
    this.setState({
      groupname:data,
      companyname:''
    },function(){
      $('.group').val(data)
      axios.get('/DATA/ParaSet/Plant.aspx?flag=2&GroupName='+this.state.groupname).then(data =>{
        // console.log(conversion(data))
        this.setState({
          companydata:conversion(data)
        })
      })
      $('.select').css("display",'none');
    })
  }
  //选择公司
  gsselect(data){
    $('.company').val('')
    this.setState({
      companyname:data
    },function(){
      $('.select').css("display",'none');
    })
  }

  loginbtn(){
    this.setState({
      counflag:true
    },function() {
      //  /DATA/ValidateCode.aspx
      axios.post('/DATA/ValidateCode.aspx',{
        group:this.state.groupname,
        company:this.state.companyname,
        userid:$('.username').val(),
        userPwd:$('.userpwd').val(),
      }).then(data =>{
        console.log(data)
        if(data.data && data.data.success === "true"){
          this.setState({
            counflag:false
          })
          sessionStorage.setItem('uname',data.data.username)
          sessionStorage.setItem('unumber',data.data.UserNumber)
          sessionStorage.setItem('service',this.state.groupname)
          sessionStorage.setItem('factory',this.state.companyname)
          message.success('登录成功!')
          this.props.history.push('/')
        }else if(data.data=="BSPPS101"){
          message.error('该用户已登录!')
        }else{
          message.error('用户名或密码输入有误!')
          this.setState({
            counflag:false
          })
        }
      })
    })
  }

  keyLogin(e){
    if(e.keyCode == 13){
      $('.loginbutton').click();
    }
  }
  render() {
    return (
      <div className="login" style={{height:this.state.clientHeight+'px'}} onKeyDown={this.keyLogin.bind(this)}>
        
        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: this.state.clientHeight + 'px' }}>
        </Spin>
        <div className="softname">

        </div>
        <div className="loginbox">
          <div className="jituan">
            <span></span>
            <input placeholder="请选择运营服务商" className="group" value={this.state.groupname} readOnly/>
            <p className="sele"></p>
            <ul className="select">
              {
                this.state.groupdata.map((item,index)=>{
                  return(
                    <li key={index} onClick={this.jtselect.bind(this,item.Groups)}>{item.Groups}</li>
                  )
                })
              }
            </ul>
          </div>
          
          <div className="comp">
            <span></span>
            <p className="sele"></p> 
            <input placeholder="请选择工厂" className="company" value={this.state.companyname} readOnly/>
            <ul className="select">
            {
              this.state.companydata.map((item,index)=>{
                return(
                  <li key={index} onClick={this.gsselect.bind(this,item.CompanyName)}>{item.CompanyName}</li>
                )
              })
            }
            </ul>
          </div>
          <div className="userid"><span></span><input placeholder="请输入用户名" className="username" /></div>
          <div className="username"><span></span><input type="password" placeholder="请输入密码" className="userpwd" /></div> 
          <button className="loginbutton" onClick={this.loginbtn.bind(this)}>登录</button>
        </div>
        
        
        <div className='footer'>
          <div>版权所有 © 杭州伯勒计算机技术有限公司 ·<a href="http://www.beian.miit.gov.cn"  target="_blank">浙ICP备09026386号-5</a></div>
        </div>
      </div>
    );
  }
}

export default App;
