import React, { Fragment } from 'react'
import { Tree, Input, Select, Table, Button, Tabs, message, Spin, Modal, Upload, Tooltip, Form, Row, Col, Drawer, DatePicker } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import Draggable from 'react-draggable'
import { conversion } from '../../converse'
import TextArea from 'antd/lib/input/TextArea'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import moment from 'moment'
import reqwest from 'reqwest'
import "moment/locale/zh-cn"
import { func } from 'prop-types';
import { onCellf } from '../../comfunc'
import { formatDate } from '../../formatDate'
import { getButtonLimit } from '../../utils/getbuttonlimit'
import store from '../../store';
moment.locale('zh-cn')
const confirm = Modal.confirm
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { Search } = Input;
const { TreeNode, DirectoryTree } = Tree;

class InspectionRecord extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr:[],

      counflag: false,
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      UserNumber: '',
      rowIdA: '',
      rowIdAobj: {},
      dataSourceA: [],
      dataSourceAfu: [],

      dataSourceB: [],
      dataSourceBlength: 0,

      pageIndexA: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 50) / 28),

      Searchvalue: '',

      //个人文档树
      treeData: [],
      selectedKeysA: [],
      TaskCodeSele: "",
      equipmentinfo: {},
      equipmentinfoAarr: [],
      equipmentinfoBarr: [],
      equipmentinfoCarr: [],
      imgurl: '',//巡检记录图片

      rowIdAAobj: {},
      rowIdAA: "",
      equipmentinfoAAarr: [],
      equipmentinfoBBarr: [],
      equipmentinfoCCarr: [],
      imgurl2: '',//巡检记录图片
    }
    //文档
    this.columnsA = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndexA - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '设备KKS',
        dataIndex: 'DeviceKKS',
        key: 'DeviceKKS',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '巡检人',
        dataIndex: '巡检人',
        key: 'Remark1',
        width: '16%'
      },
      {
        title: '巡检时间',
        dataIndex: '巡检时间',
        key: 'Remark',
        width: '15%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '设备名称',
        dataIndex: '设备名称',
        key: 'TaskCode',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '设备评价',
        dataIndex: '设备评价',
        key: 'ver',
        width: '25%',
        onCell: onCellf,
        render: this.renderf
      }
    ]
    this.columnsB = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{index + 1}</span>)
        }
      },
      {
        title: '巡检时间',
        dataIndex: '巡检时间',
        width: '26%',
      },
      {
        title: '巡检人',
        dataIndex: '巡检人',
        width: '16%'
      },
      {
        title: '设备名称',
        dataIndex: '设备名称',
        width: '16%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '设备评价',
        dataIndex: 'ver',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      }
    ]
    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(62)

    var UserNumber = sessionStorage.getItem('unumber')
    this.setState({
      UserNumber
    })
    axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=23', {
      date: formatDate(new Date())
    }).then(data => {
      console.log('巡检记录', conversion(data))
      this.setState({
        DateSele: formatDate(new Date()),
        treeData: conversion(data),
        treeDatafu: conversion(data)
      })
    })
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 50) / 28)
    })
  }

  //查看设备记录
  toequiprecord() {
    if (this.state.rowIdA) {
      axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=24', {
        DeviceKKS: this.state.rowIdA
      }).then(data => {
        console.log('巡检记录', conversion(data))
        this.setState({
          dataSourceB: conversion(data)
        }, function () {
          document.querySelector('.dragwindow').style.display = "block"
        })
      })
    } else {
      message.error('请选择设备！')
    }
  }
  //设备
  senddataA(record) {
    console.log(record)
    this.setState({
      rowIdA: record.DeviceKKS,
      rowIdAobj: record
    }, function () {
      axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=25', {
        DeviceKKS: record.DeviceKKS,
        TaskCode: this.state.TaskCodeSele
      }).then(data => {
        console.log(data)
        this.setState({
          imgurl: conversion(data)[0] ? conversion(data)[0].Folder + conversion(data)[0].Filenewname : ""
        })
      })
      //设备参数
      axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=22', {
        DeviceKKS: record.DeviceKKS,
        TaskCode: this.state.TaskCodeSele
      }).then(data => {
        console.log('巡检情况', conversion(data))
        var equipmentinfoarr = conversion(data)
        var equipmentinfoCarr = []
        var equipmentinfoCotherarr = []
        for (let i = 0; i < equipmentinfoarr.length; i++) {
          if (equipmentinfoarr[i].ParameterType == "1") {
            equipmentinfoCarr.push(equipmentinfoarr[i])
          } else {
            equipmentinfoCotherarr.push(equipmentinfoarr[i])
          }
        }
        var equipmentinfoBarr = equipmentinfoCotherarr.slice(6)
        var equipmentinfoAarr = equipmentinfoarr.slice(0, 6);
        this.setState({
          equipmentinfoAarr,
          equipmentinfoBarr,
          equipmentinfoCarr
        })
      })
    })
  }

  setRowClassNameA = (record) => {
    return record.DeviceKKS === this.state.rowIdA ? 'clickRowStyl' : 'clickRowSty';
  }


  //异常
  pagechange(key, page) {
    console.log(key, page)
    if (key == "巡检设备") {
      this.setState({
        pageIndexA: page.current,
      })
    }
  }

  onSelectA(selectedKeys, info) {
    console.log(selectedKeys, info)
    if (selectedKeys[0] == "时间") {

    } else {
      if (selectedKeys[0]) {
        this.setState({
          selectedKeysA: selectedKeys,
          TaskCodeSele: info.node.props.TaskCode,
          counflag: true,
          PathId: info.node.props.PathId
        }, function () {
          axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=26', {
            TaskCode: this.state.TaskCodeSele,
            PathId: info.node.props.PathId
          }).then(data => {
            console.log('设备', conversion(data))
            this.setState({
              dataSourceA: conversion(data),
              pageIndexA: 1,
              dataSourceAfu: conversion(data),
              counflag: false
            })
          })
        })
      } else {
        this.setState({
          selectedKeysA: [],

        })
      }
    }
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  toclosedragwindow() {
    document.querySelector('.dragwindow').style.display = "none"
  }
  Collapsechange(key) {
    this.setState({
      Collapsekey: key
    })
  }

  //巡检记录搜索
  InspectionRecordsearchA(data, dataString) {
    axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=23', {
      date: formatDate(new Date(dataString))
    }).then(data => {
      // console.log('巡检记录', data)
      this.setState({
        DateSele: dataString,
        treeData: conversion(data),
        treeDatafu: conversion(data)
      })
    })
  }


  //人员选中
  senddataB(record) {
    console.log(record)
    this.setState({
      rowIdAA: record.TaskCode,
      rowIdAAobj: record
    }, function () {
      axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=25', {
        DeviceKKS: this.state.rowIdAobj.DeviceKKS,
        TaskCode: record.TaskCode
      }).then(data => {
        console.log(data)
        this.setState({
          imgurl2: conversion(data)[0] ? conversion(data)[0].Folder + conversion(data)[0].Filenewname : ""
        })
      })
      //设备参数
      axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=22', {
        DeviceKKS: this.state.rowIdAobj.DeviceKKS,
        TaskCode: record.TaskCode
      }).then(data => {
        console.log('巡检情况', conversion(data))
        var equipmentinfoarr = conversion(data)
        var equipmentinfoCCarr = []
        var equipmentinfoCotherarr = []
        for (let i = 0; i < equipmentinfoarr.length; i++) {
          if (equipmentinfoarr[i].ParameterType == "1") {
            equipmentinfoCCarr.push(equipmentinfoarr[i])
          } else {
            equipmentinfoCotherarr.push(equipmentinfoarr[i])
          }
        }
        var equipmentinfoBBarr = equipmentinfoCotherarr.slice(6)
        var equipmentinfoAAarr = equipmentinfoarr.slice(0, 6);
        this.setState({
          equipmentinfoAAarr,
          equipmentinfoBBarr,
          equipmentinfoCCarr
        })
      })
    })
  }

  setRowClassNameB = (record) => {
    return record.TaskCode === this.state.rowIdAA ? 'clickRowStyl' : 'clickRowSty';
  }
  //巡检记录搜索
  InspectionRecordsearchB(value) {
    var arr = this.state.treeDatafu
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].TaskName.indexOf(value) > -1 || arr[i].PathCode.indexOf(value) > -1) {
        newarr.push(arr[i])
      }
    }
    this.setState({
      treeData: newarr
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth

    return (
      <div className="InspectionRecord" style={{ height: clientHeight - 50 + 'px' }}>
        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="InspectionRecorddrag dragwindow" ref="InspectionRecorddrag">
            <h2 className="h2title">设备记录
              <span className="h2action">
                <Button onClick={this.toclosedragwindow.bind(this)}>关闭</Button></span>
            </h2>
            <div className="dragwindowcon">
              <div className="dragwindowconl">
                <h2 className="htitle">编号+名称巡检记录 <Search
                  placeholder="(名称)"
                /></h2>
                <div className="listscroll">
                  <Table
                    size="small"
                    dataSource={this.state.dataSourceB}
                    columns={this.columnsB}
                    onRow={(record) => {
                      return {
                        onClick: this.senddataB.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassNameB}
                    pagination={false}
                    scroll={{ y: 500 }}
                  // pagination={{
                  //   pageSize: this.state.pageSize,
                  //   current: this.state.pageIndexA,
                  //   total: Number(this.state.dataSourceA.length)
                  // }}
                  // onChange={this.pagechange.bind(this, '巡检设备')}
                  />
                </div>
              </div>
              <div className="dragwindowconr">
                <h2 className="htitle">编号+名称巡检记录</h2>
                <div className="PDADCS"><span>PDA巡检记录</span><span>DCS记录</span></div>
                <div className="dragwindowconrtableimg">
                  <div className="equipinfo">
                    <table>
                      <tbody>
                        {
                          this.state.equipmentinfoAAarr.length > 0 ? this.state.equipmentinfoAAarr.map((item, index) => {
                            return (<tr><td>{item.ParameterName}</td><td>{item.ParameterName=="设备名称"?this.state.rowIdAobj["设备名称"]:item.ParameterName=="KKS编码"?this.state.rowIdAobj.DeviceKKS:item.ParameterValues}</td></tr>)
                          }) : <Fragment></Fragment>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="pressureinfo">
                    <table>
                      <tbody>
                        {
                          this.state.equipmentinfoBBarr.length > 0 ? this.state.equipmentinfoBBarr.map((item, index) => {
                            return (<tr><td>{item.ParameterName}</td><td>{item.ParameterValues}</td></tr>)
                          }) : <Fragment></Fragment>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="collapseant">
                    <ul>
                      {
                        this.state.equipmentinfoCCarr.length > 0 ?
                          this.state.equipmentinfoCCarr.map((item, index) => {
                            return (
                              <li onClick={this.Collapsechange.bind(this, index + 1)}>
                                <span>{item.ParameterName}</span>
                                <span>{item.ParameterValues}</span>
                                <span><span>{item.Describe}</span><span className={this.state.Collapsekey == "1" ? "tubiao tuhaoactive" : "tubiao"}>附</span></span>
                              </li>)
                          }) : ""
                      }
                    </ul>
                  </div>
                  <div className="imgtu">
                    <img src={this.state.imgurl} alt=""
                      style={{
                        maxHeight: "100px",
                        width: "auto",
                        height: "auto",
                        maxWidth: "100%"
                      }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>

        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
        <div className="InspectionRecordcon">
          <div className="tree">
            <h2 className="h2title">巡检记录</h2>
            <div className="searchcondition">
              <span className="conditionl">
                <DatePicker
                  locale={locale}
                  onChange={this.InspectionRecordsearchA.bind(this)} />
              </span>
              <span className="conditionr"><Search placeholder="关键字搜索" onSearch={this.InspectionRecordsearchB.bind(this)} /></span>
            </div>
            <div className="treedata" style={{ height: clientHeight - 50 - 40 - 60 + 'px' }}>
              <Tree
                selectedKeys={this.state.selectedKeysA}
                onSelect={this.onSelectA.bind(this)}
              >
                <TreeNode title={this.state.DateSele} key="时间">
                  {
                    this.state.treeData.length > 0 ?
                      this.state.treeData.map((item, index) => {
                        return (<TreeNode title={item.TaskCode + '-' + item.PathCode + '-' + item.TaskName} key={item.id} PathId={item.PathId} TaskCode={item.TaskCode} />)
                      }) : ""
                  }
                </TreeNode>
              </Tree>
            </div>
          </div>
         
          <div className="InspectionRecordlist" style={{width:(clientWidth-320)/2 + 200 +'px'}}>
            <h2 className="h2title">巡检设备</h2>
            <div className="listpage"
              style={{ height: clientHeight - 50 - 40 + 'px' }}>
              <Table
                size="small"
                dataSource={this.state.dataSourceA}
                columns={this.columnsA}
                onRow={(record) => {
                  return {
                    onClick: this.senddataA.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassNameA}
                pagination={{
                  pageSize: this.state.pageSize,
                  current: this.state.pageIndexA,
                  total: Number(this.state.dataSourceA.length)
                }}
                onChange={this.pagechange.bind(this, '巡检设备')}
              />
            </div>
          </div>

          <div className="PatrolRouteFuright" ref="PatrolRouteFuright" style={{
            height: clientHeight - 50 + 'px',
            width:(clientWidth-320)/2 - 200 +'px'
          }}>
            <h2 className="h2title">巡检记录 <span className="h2action">
              <Button style={{display:this.state.buttonarr.indexOf('设备记录')>-1?"inline-block":"none"}} onClick={this.toequiprecord.bind(this)}>设备记录</Button></span></h2>
            <div className="PatrolRouteFurightcon" style={{
              height: clientHeight - 50 - 40 + 'px',
            }}>
              <div className="equipinfo">
                <table>
                  <tbody>
                    {
                      this.state.equipmentinfoAarr.length > 0 ? this.state.equipmentinfoAarr.map((item, index) => {
                        return (<tr><td>{item.ParameterName}</td><td>{item.ParameterName=="设备名称"?this.state.rowIdAobj["设备名称"]:item.ParameterName=="KKS编码"?this.state.rowIdAobj.DeviceKKS:item.ParameterValues}</td></tr>)
                      }) : <Fragment></Fragment>
                    }
                  </tbody>
                </table>
              </div>
              <div className="paramaterinfo">
                <table>
                  <tbody>
                    {
                      this.state.equipmentinfoBarr.length > 0 ? this.state.equipmentinfoBarr.map((item, index) => {
                        return (<tr><td>{item.ParameterName}</td><td>{item.ParameterValues}</td></tr>)
                      }) : <Fragment></Fragment>
                    }
                  </tbody>
                </table>
              </div>
              <div className="collapseant">
                <ul>
                  {
                    this.state.equipmentinfoCarr.length > 0 ?
                      this.state.equipmentinfoCarr.map((item, index) => {
                        return (
                          <li onClick={this.Collapsechange.bind(this, index + 1)}>
                            <span>{item.ParameterName}</span>
                            <span>{item.ParameterValues}</span>
                            <span><span>{item.Describe}</span><span className={this.state.Collapsekey == "1" ? "tubiao tuhaoactive" : "tubiao"}>附</span></span>
                          </li>)
                      }) : ""
                  }
                </ul>
              </div>
              <div className="imgtu" style={{ height: clientHeight - 50 - 40 - 140 - 90 - 160 - 30 + 'px' }}>
                <img src={this.state.imgurl} alt=""
                  style={{
                    maxHeight: clientHeight - 50 - 40 - 140 - 90 - 160 - 30 + 'px',
                    width: "auto",
                    height: "auto",
                    maxWidth: "100%"
                  }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const InspectionRecordapp = Form.create()(InspectionRecord);
export default InspectionRecordapp;