import React from 'react';
import {
  Input, Button, Select, Table, Drawer, Form, Col, Row, Tooltip,
  Tabs, Collapse, message, Modal, Radio, DatePicker, TimePicker
} from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import './style.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { onCellf } from '../../comfunc'
import { formatTimeDate } from '../../formatTimeDate'
import store from '../../store';
import { getButtonLimit } from '../../utils/getbuttonlimit'
moment.locale('zh-cn')
const { Panel } = Collapse;
const { Search, TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select
const { confirm } = Modal
class Regularwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      visible: false,
      //定期工作类型初始化
      WorkTypearr: [],

      //配置界面
      dataSource1: [],
      dataSource1fu: [],
      //选择每一类的配置列表
      dataSource1sele: [],

      configtestsele: '',//配置选择进行添加
      detaillistId: '',//配置列表选中修改删除
      iptobj: {},

      periodsele: '单次',//单次 重复
      perioddetailsele: '每日',//日 周 月
      montharr: [],//月份遍历

      weeksele: [],//周选择
      monthsele: [],//月选中

      regular4sele: 0, //单次1 //月 日 周
      regular6sele: '',
      //人员设置初始化
      perinitarr: store.getState().userdata,

      //定期工作类型
      visibleworktype: false

    }
    this.columns = [
      {
        title: '序号', dataIndex: 'xuhao', key: 'xuhao', width: "8%",
        render: (text, record, index) => {
          return `${index + 1}`
        }
      },
      {
        title: '项目', dataIndex: 'WorkContent', key: 'WorkContent', width: "30%",
        onCell: onCellf,
        render: (text) => {
          return (<Tooltip title={text}><div style={{ textAlign: "left" }}> {text}</div></Tooltip>)
        }
      },
      {
        title: '周期', dataIndex: 'Frequency', key: 'Frequency', width: "26%",
        onCell: onCellf,
        render: (text, record) => {
          if (record.TimeType == "1" && record.FrequencyType == "3") {
            return (<span>{'月选 ' + record.Frequency + '/ 时间' + record.OperateTime}</span>)
          } else if (record.TimeType == "1" && record.FrequencyType == "2") {
            return (<span>{'周选 ' + record.Frequency + '/ 时间' + record.OperateTime}</span>)
          } else if (record.TimeType == "1" && record.FrequencyType == "1") {
            return (<span>{'日选  / 时间' + record.OperateTime}</span>)
          } else if (record.TimeType == "0") {
            return (<span>{'单次  /时间 ' + record.OperateTime}</span>)
          }
        }
      },
      {
        title: '负责人', dataIndex: 'Operators', key: 'Operators', width: "15%",
        onCell: onCellf,
        render: (text) => {
          return (<Tooltip title={text}><div style={{ textAlign: "left" }}> {text}</div></Tooltip>)
        }
      },
      {
        title: '备注', dataIndex: 'MajorType', key: 'MajorType', width: "18%",
        onCell: onCellf,
        render: (text) => {
          return (<Tooltip title={text}><div style={{ textAlign: "left" }}> {text}</div></Tooltip>)
        }
      },
    ]

    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" title={text}><div style={{ textAlign: 'left' }}>{text}</div></Tooltip>)
    }
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(58)

    var arr = []
    for (let i = 1; i < 32; i++) {
      arr.push(i + '号')
    }
    this.setState({
      montharr: arr
    })
    //历史定期工作列表
    axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=1').then(data => {
      // //console.log('定期工作列表', conversion(data))
      this.setState({
        dataSource: conversion(data),
        dataSourcefu: conversion(data)
      })
    })
    //获取定期工作类型
    axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=10').then(data => {
      //console.log('定期工作类型',conversion(data))
      this.setState({
        WorkTypearr: conversion(data)
      })
    })
    //定期工作配置
    axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=2').then(data => {
      //console.log('定期工作配置列表', conversion(data))
      this.setState({
        dataSource1: conversion(data),
        dataSource1fu: conversion(data)
      })
    })
  }
  updatedata() {
    //历史定期工作列表
    axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=1').then(data => {
      // //console.log('定期工作列表', conversion(data))
      this.setState({
        dataSource: conversion(data),
        dataSourcefu: conversion(data)
      }, function () {
        message.success('刷新成功！')
      })
    })

  }

  regularworkconfigsearch(value) {
    //console.log(value)
    var arr = this.state.dataSource1fu
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].WorkContent.indexOf(value) > -1 || arr[i].Operators.indexOf(value) > -1) {
        newarr.push(arr[i])
      }
    }
    this.setState({
      dataSource1: newarr
    }, function () {
      var arr = this.state.dataSource1
      var newarr = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].WorkType == this.state.configtestsele) {
          newarr.push(arr[i])
        }
      }
      this.setState({
        dataSource1sele: newarr,
      })
    })
  }

  Collapsecallback(key) {
    //console.log('面板切换选择',key)
    var arr = this.state.dataSource1fu
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].WorkType == key) {
        newarr.push(arr[i])
      }
    }
    //console.log(newarr)
    this.setState({
      dataSource1sele: newarr,
      configtestsele: key
    })
  }
  //配置添加删除修改
  handleAdd() {
    this.setState({
      addedit: '添加',
      visible: true,
      iptobj: {},
      detaillistId: '',
      regular4sele: 0,
      regular6sele: ''
    }, function () {
      this.props.form.resetFields()
    })
  }
  handleEdit() {
    if (this.state.detaillistId) {
      this.setState({
        addedit: '修改',
        visible: true,
      })
    } else {
      message.error('请选择!')
    }
  }
  handleDel() {
    var _this = this
    if (this.state.detaillistId) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/IntelligentDecision/RegularWork.aspx?flag=5', {
            id: _this.state.detaillistId
          }).then(data => {
            //console.log(data)
            if (data.data == true) {
              //定期工作配置
              axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=2').then(data => {
                //console.log('定期工作配置列表', conversion(data))
                _this.setState({
                  dataSource1: conversion(data),
                  dataSource1fu: conversion(data),
                  detaillistId: ""
                }, function () {
                  var arr = _this.state.dataSource1fu
                  var newarr = []
                  for (let i = 0; i < arr.length; i++) {
                    if (arr[i].WorkType == _this.state.configtestsele) {
                      newarr.push(arr[i])
                    }
                  }
                  _this.setState({
                    dataSource1sele: newarr,
                    detaillistId: ''
                  })
                  message.success('删除成功！')
                })
              })
            }
          })
        },
        onCancel() {
          _this.setState({
            detaillistId: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }

  onClose = () => {
    this.setState({
      visible: false,
      detaillistId: ''
    }, function () {
      this.props.form.resetFields();
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      var timeobj = values.OperateTime
      if (this.state.regular4sele == 0) {
        values.OperateTime = formatTimeDate(timeobj._d)
      } else {
        values.OperateTime = timeobj._d.getHours() + ':' + timeobj._d.getMinutes() + ':' + timeobj._d.getSeconds()
      }
      values.Frequency = values.Frequency.length > 0 ? values.Frequency.join(',') : ""
      values.Operators = values.Operators.length > 0 ? values.Operators.join(',') : ""
      if (!err) {
        if (this.state.addedit == "添加") {
          axios.post('/DATA/IntelligentDecision/RegularWork.aspx?flag=3', values).then(data => {
            if (data.data == true) {
              axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=2').then(data => {
                //console.log('定期工作配置列表', conversion(data))
                this.setState({
                  dataSource1: conversion(data),
                  dataSource1fu: conversion(data),
                  visible: false,
                  regular4sele: 0
                }, function () {
                  var arr = this.state.dataSource1fu
                  var newarr = []
                  for (let i = 0; i < arr.length; i++) {
                    if (arr[i].WorkType == this.state.configtestsele) {
                      newarr.push(arr[i])
                    }
                  }
                  this.setState({
                    dataSource1sele: newarr,
                  })
                  message.success('添加成功！')
                })
              })
            }
          })
        } else if (this.state.addedit == "修改") {
          values.id = this.state.iptobj.Id
          values.WorkId = this.state.iptobj.WorkId
          axios.post('/DATA/IntelligentDecision/RegularWork.aspx?flag=4', values).then(data => {
            //console.log(data)
            if (data.data == true) {
              axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=2').then(data => {
                //console.log('定期工作配置列表', conversion(data))
                this.setState({
                  dataSource1: conversion(data),
                  dataSource1fu: conversion(data),
                  visible: false,
                  detaillistId: "",
                  regular4sele: 0,
                }, function () {
                  var arr = this.state.dataSource1fu
                  var newarr = []
                  for (let i = 0; i < arr.length; i++) {
                    if (arr[i].WorkType == this.state.configtestsele) {
                      newarr.push(arr[i])
                    }
                  }
                  this.setState({
                    dataSource1sele: newarr,
                  })
                  message.success('修改成功！')
                })
              })
            }
          })
        }
      }
    });
  };

  //选中
  senddata(record) {
    this.props.form.resetFields()
    this.setState({
      regular4sele: Number(record.TimeType),
      regular6sele: Number(record.FrequencyType),
      detaillistId: record.Id,
      iptobj: record,
    })
  }

  setRowClassName = (record) => {
    return record.Id === this.state.detaillistId ? 'clickRowStyl' : 'clickRowSty';
  }

  //定期工作类型添加
  worktypeadd() {
    if ($('.addworktypeadd').val() == "") {

    } else {
      axios.post('/DATA/IntelligentDecision/RegularWork.aspx?flag=11', {
        name: $('.addworktypeadd').val()
      }).then(data => {
        //console.log(data)
        if (data.data == true) {
          axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=10').then(data => {
            //console.log(conversion(data))
            this.setState({
              WorkTypearr: conversion(data),
              visibleworktype: false
            })
          })
        }
      })
    }
  }
  worktypeaddcancel() {
    this.setState({
      visibleworktype: false
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="regularwork" style={{ width: "100%", height: clientHeight - 50 + 'px' }}>
        <div className="regularworkconfig">
          <div className="regularworkrightsearch">
            <Search
              placeholder="关键字搜索"
              onSearch={this.regularworkconfigsearch.bind(this)}
              style={{ width: 200 }}
            />
            <span className="regularworkconfigaction">
              <Button style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this)}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this)}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this)}>删除</Button>
            </span>
          </div>
          <table className="tableheader">
            <tbody>
              <tr>
                <th>序号</th>
                <th>项目</th>
                <th>周期</th>
                <th>负责人</th>
                <th>备注</th>
              </tr>
            </tbody>
          </table>
          <Collapse accordion onChange={this.Collapsecallback.bind(this)}>
            {
              this.state.WorkTypearr.map((item, index) => {
                return (
                  <Panel header={item.Name} key={item.Name}>
                    <div className="listscroll" style={{ height: clientHeight - 50 - 50 - 20 - 40 * (Number(this.state.WorkTypearr.length)) }}>
                      <Table
                        size="small"
                        showHeader={false}
                        pagination={false}
                        scroll={{ y: clientHeight - 50 - 50 - 20 - 40 * (Number(this.state.WorkTypearr.length)) }}
                        columns={this.columns}
                        onRow={(record) => {
                          return {
                            onClick: this.senddata.bind(this, record)
                          }
                        }}
                        rowClassName={this.setRowClassName}
                        dataSource={this.state.dataSource1sele}
                      />
                    </div>
                  </Panel>
                )
              })
            }
          </Collapse>
          <Modal
            title="定期工作类型"
            visible={this.state.visibleworktype}
            onOk={this.worktypeadd.bind(this)}
            onCancel={this.worktypeaddcancel.bind(this)}
            okText="确认"
            cancelText="取消"
            className="worktypeadd"
          >
            <p className="worktypeaddp">
              定期工作类型：<Input className="addworktypeadd" />
            </p>
          </Modal>
          <Drawer
            title={`定期工作` + this.state.addedit}
            onClose={this.onClose.bind(this)}
            visible={this.state.visible}
            className="Drawercommon"
          >
            <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
              <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
              // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="定期工作类型">
                      {getFieldDecorator('WorkType', {
                        initialValue: this.state.iptobj['WorkType'],
                        rules: [{ required: true, message: '请输入定期工作类型' }],
                      })(
                        <Select showSearch onChange={(value) => {
                          //console.log(value)
                          if (value == "自定义") {
                            this.setState({
                              visibleworktype: true,
                            }, function () {
                              $('.addworktypeadd').val("")
                            })
                          }
                        }}>
                          <Option value="自定义" key={3}>自定义</Option>
                          {
                            this.state.WorkTypearr.map((item, index) => {
                              return (<Option value={item.Name} key={item.ID}>{item.Name}</Option>)
                            })
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="项目名称">
                      {getFieldDecorator('WorkContent', {
                        initialValue: this.state.iptobj['WorkContent'],
                        rules: [{ required: true, message: '请输入项目名称' }],
                      })(
                        <Input />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="周期设置">
                      {getFieldDecorator('TimeType', {
                        initialValue: this.state.addedit == "添加" ? this.state.regular4sele : Number(this.state.iptobj['TimeType']),
                        rules: [{ required: true, message: '请输入周期设置' }],
                      })(
                        <Radio.Group onChange={(e) => {
                          //console.log(e)
                          this.setState({
                            regular4sele: e.target.value
                          })
                        }}>
                          <Radio value={0}>单次</Radio>
                          <Radio value={1}>重复</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                  </Col>
                </Row>
                {
                  
                  this.state.regular4sele == 0?
                  <Row gutter={16} style={{ display: this.state.regular4sele == 0 ? "block" : "none" }}>
                    <Col span={12} >
                      <Form.Item label="时间(单次)" >
                        {getFieldDecorator('OperateTime', {
                          rules: [{ required: this.state.regular4sele == 0 ? true : false, message: '请选择时间' }],
                          initialValue: this.state.iptobj['OperateTime'] ? moment(this.state.iptobj['OperateTime']) : null,
                        })(
                          <DatePicker locale={locale} showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            style={{ display: this.state.regular4sele == 0 ? "block" : "none" }} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>:""
                }

                <Row gutter={16} style={{ display: this.state.regular4sele == 1 ? "block" : "none" }}>
                  <Col span={12} >
                    <Form.Item label="时间(重复)">
                      {getFieldDecorator('FrequencyType', {
                        rules: [{ required: this.state.regular4sele == 1 ? true : false, message: '请选择时间' }],
                        initialValue: this.state.addedit == "添加" ? Number(this.state.regular6sele) : Number(this.state.iptobj['FrequencyType'])
                      })(
                        <Radio.Group onChange={(e) => {
                          //console.log(e)
                          this.setState({
                            regular6sele: e.target.value,
                          }, function () {
                            this.props.form.setFieldsValue({
                              Frequency: [],
                              OperateTime: null
                            })
                          })
                        }}>
                          <Radio value={1}>每日</Radio>
                          <Radio value={2}>每周</Radio>
                          <Radio value={3}>每月</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} style={{ display: this.state.regular4sele == 1 && Number(this.state.regular6sele) == 2 ? "block" : "none" }}>
                  <Col span={12}>
                    <Form.Item label="周选">
                      {getFieldDecorator('Frequency', {
                        rules: [{ required: this.state.regular4sele == 1 && Number(this.state.regular6sele) == 2 ? true : false, message: '请选择' }],
                        initialValue: this.state.iptobj['Frequency'] ?
                          this.state.iptobj['Frequency'].indexOf(',') > -1 ?
                            this.state.iptobj['Frequency'].split(',') :
                            [this.state.iptobj['Frequency']] : [],
                      })(
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="请选择"
                        >
                          <Option value="周一" key="first">周一</Option>
                          <Option value="周二" key="second">周二</Option>
                          <Option value="周三" key="third">周三</Option>
                          <Option value="周四" key="fourth">周四</Option>
                          <Option value="周五" key="fifth">周五</Option>
                          <Option value="周六" key="sixth">周六</Option>
                          <Option value="周日" key="seventh">周日</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} style={{ display: this.state.regular4sele == 1 && Number(this.state.regular6sele) == 3 ? "block" : "none" }}>
                  <Col span={12}>
                    <Form.Item label="月选">
                      {getFieldDecorator('Frequency', {
                        rules: [{ required: this.state.regular4sele == 1 && Number(this.state.regular6sele) == 3 ? true : false, message: '请选择' }],
                        initialValue: this.state.iptobj['Frequency'] ?
                          this.state.iptobj['Frequency'].indexOf(',') > -1 ?
                            this.state.iptobj['Frequency'].split(',') :
                            [this.state.iptobj['Frequency']] : [],
                      })(
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="请选择"
                        >
                          {
                            this.state.montharr.map((item, index) => {
                              return (<Option value={item} key={item}>{item}</Option>)
                            })
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                {
                  this.state.regular4sele == 1?
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="时间选择" >
                        {getFieldDecorator('OperateTime', {
                          rules: [{ required: this.state.regular4sele == 1 ? true : false, message: '请选择时间' }],
                          initialValue: this.state.iptobj['OperateTime'] ? moment(this.state.iptobj['OperateTime'], " 'HH:mm:ss'") : null,
                        })(
                          <TimePicker
                            placeholder="请选择时间"
                          // defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} 
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>:""
                }


                <Row gutter={16} className="personconfig">
                  <Col span={12}>
                    <Form.Item label="人员设置">
                      {getFieldDecorator('Operators', {
                        initialValue: this.state.iptobj['Operators'] ?
                          this.state.iptobj['Operators'].indexOf(',') > -1 ?
                            this.state.iptobj['Operators'].split(',') :
                            [this.state.iptobj['Operators']] : [],
                        rules: [{ required: true, message: '请选择人员设置' }],
                      })(
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="请选择"
                        >
                          {
                            this.state.perinitarr.map((item, index) => {
                              return (<Option value={item.UserName} key={item.UserNumber}>{item.UserName}</Option>)
                            })
                          }
                        </Select>)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="textipt">
                  <Col span={12}>
                    <Form.Item label="备注">
                      {getFieldDecorator('MajorType', {
                        initialValue: this.state.iptobj['MajorType'],
                        rules: [{ required: false, message: '请输入备注' }],
                      })(
                        <TextArea rows={2} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="formbutton">
                  <Form.Item>
                    <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                      保存
                        </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Drawer>
        </div>
      </section>
    )
  }
}
const Regularworkapp = Form.create()(Regularwork);
export default Regularworkapp;
