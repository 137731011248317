import React, { Fragment } from 'react';
import { Table, Input, Icon, Tree, Tooltip, Collapse, Form, Button, Select, Progress, message } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import { onCellf } from '../../comfunc'
import $ from 'jquery'
import echarts from 'echarts';
import moment from 'moment'
import './style.scss'
// import "moment/locale/zh-cn"
// moment.locale('zh-cn')
import Draggable from 'react-draggable';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { Resizable } from 'react-resizable';
import { getButtonLimit } from '../../utils/getbuttonlimit'
import store from '../../store';
const { Option } = Select;
const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;
const { Panel } = Collapse;
class PatrolRouteFuFu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      service: '',//厂

      //巡检任务树
      PatrolRouteFutreeData: [],//专业树数据显示
      PatrolRouteFutreeDatafu: [],//全部
      selectedKeysA: [],//智慧巡检选择
      ModelIdSele: "",
      PathModel: "",

      treeIdsele: '',
      RoadModel: '',//模型加载

      moduleloadframesrc: '',
      begincount: 0,
      loadingcount: 0,

      //拖拽
      activeDrags: 0,
      controlledPosition: {
        x: 0, y: 0
      },
      //附件点击标识
      Collapsekey: '2',
      imgurl: "",

      //KKS树
      KKStreeData: [],
      //巡检情况
      equipmentinfoAarr: [],
      equipmentinfoBarr: [],
      equipmentinfoCarr: [],


      //专业宽高
      assetsviewleftw: 240,
      assetsviewcenterw: 200,
    };
    //基础属性 供应商 责任相关 技术参数  文档关联

    this.historyrecordcolumn = [
      {
        title: '故障日期',
        dataIndex: 'faultDate',
        key: 'faultDate',
        width: '40%', onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>

      },
      {
        title: '故障内容',
        dataIndex: 'faultType',
        key: 'faultType',
        width: '60%', onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>

      },
    ]
    this.receivemess = this.receivemess.bind(this)
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(61)

    var service = sessionStorage.getItem('service')
    this.setState({
      service: service
    })


    axios.get('/DATA/OM/OM_PatrolTask.aspx?flag=21').then(data => {
      // console.log('智慧巡检树', conversion(data))
      var arr = conversion(data)
      var arrfu = conversion(data)
      for (let i = 0; i < arrfu.length; i++) {
        if (arrfu[i].PathId == "") {
          arrfu[i].children = []
          arr[i].children = []
          if (i == arrfu.length - 1) {
            this.setState({
              PatrolRouteFutreeDatafu: arrfu,
              PatrolRouteFutreeData: arr
            })
          }
        }
        else {
          axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=6', {
            Parentid: arrfu[i].PathId
          }).then(data1 => {

            //全部数据
            arrfu[i].children = conversion(data1)
            if (i == arrfu.length - 1) {
              this.setState({
                PatrolRouteFutreeDatafu: arrfu
              })
            }
            // console.log('设备数据',conversion(data))
            //树显示数据
            var arr1 = conversion(data1)
            var newarr1 = []
            for (let j = 0; j < arr1.length; j++) {
              if (arr1[j].type == "1") {
                newarr1.push(arr1[j])
              }
              if (j == arr1.length - 1) {
                arr[i].children = newarr1
              }
              if (i == arrfu.length - 1 && j == arr1.length - 1) {
                this.setState({
                  PatrolRouteFutreeData: arr
                })
              }
            }

          })
        }
      }
    })
    window.addEventListener('message', this.receivemess)
  }

  receivemess(e) {
    console.log(e.data)
    if (e.data == "COMPLETE") {
      var num = this.state.loadingcount
      num = num + 1
      if (num / this.state.begincount == 1) {
        this.setState({
          loadingcount: 0,
          begincount: 0,
        }, function () {
          // document.getElementById('moduleloadframe').contentWindow.viewer3D.highlightComponentsByKey(this.state.PathModel);
          // document.getElementById('moduleloadframe').contentWindow.viewer3D.transparentOtherComponentsByKey(this.state.PathModel);
          this.refs.loadprogress.style.display = "none"
        })
      } else {
        this.setState({
          loadingcount: num
        })
      }
    }
  }

  onSelectA(selectedKeys, info) {
    console.log(selectedKeys, info)
    if(selectedKeys[0]){
      this.refs.loadprogress.style.display = "none"
      this.setState({
        selectedKeysA: selectedKeys,
        PathModel: info.node.props.PathModel,
        TaskCode:info.node.props.TaskCode,
        begincount:0,
        loadingcount:0,
      },function() {
        var arr = this.state.PatrolRouteFutreeData
        // console.log(arr)
        var newarr= []
        for(let i=0;i<arr.length;i++){
          if(arr[i].id == this.state.selectedKeysA[0]){
            var arr1 = arr[i].children
            if(arr1 && arr1.length>0){
              for(let j=0;j<arr1.length;j++){
                newarr.push(arr1[j].ModelId)
              }
            }
          }
        }
        axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=30', {
          id: newarr.join(',')
        }).then(data => {
          // console.log('kks', conversion(data))
          this.setState({
            KKStreeData: conversion(data)
          })
        })
      })
      var str = this.state.treeIdsele
      if (str == "") {
        this.setState({
          treeIdsele: selectedKeys[0],
          moduleloadframesrc: `./BOS3dhtml/areatree.html`
        })
  
        var moduleloadframe = document.getElementById("moduleloadframe")
  
        var _this = this
        moduleloadframe.onload = function () {
          _this.refs.loadprogress.style.display = "block"
  
          var homeyjbtn = document.getElementById("moduleloadframe").contentWindow.document.getElementsByClassName('yj-but')[0]
          homeyjbtn.addEventListener('click', function () {
            document.getElementById("moduleloadframe").contentWindow.viewer3D.resetScene({ visible: false, view: true, selected: true, transparent: true, colorfully: true })
          })
          
          var arrtree = _this.state.PatrolRouteFutreeDatafu
          var data = []
          for (let i = 0; i < arrtree.length; i++) {
            if (arrtree[i].id == selectedKeys[0]) {
              data = arrtree[i].children
            }
          }
          var newdata = []
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].modelKey) {
                newdata.push(data[i])
              }
            }
          }
          _this.setState({
            begincount: Number(newdata.length)
          }, function () {
            //开始总数量
            if (newdata.length == 0) {
              _this.refs.loadprogress.style.display = "none"
            } else {
              for (let i = 0; i < newdata.length; i++) {
                document.getElementById("moduleloadframe").contentWindow.viewer3D.addView(newdata[i].modelKey, "bole");
              }
            }
          })
        }
      } else {
        this.setState({
          treeIdsele: selectedKeys[0]
        })
        var moduleloadframe = document.getElementById("moduleloadframe")
        document.getElementById("moduleloadframe").contentWindow.viewer3D.viewerImpl.modelManager.unloadAll()
  
        var _this = this
        var arrtree = _this.state.PatrolRouteFutreeDatafu
        var data = []
        for (let i = 0; i < arrtree.length; i++) {
          if (arrtree[i].id == selectedKeys[0]) {
            data = arrtree[i].children
          }
        }
        // console.log(data, data.length)
        var newdata = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].modelKey) {
            newdata.push(data[i])
          }
        }
        // console.log(newdata, newdata.length)
        _this.setState({
          begincount: Number(newdata.length)
        }, function () {
          _this.refs.loadprogress.style.display = "block"
          //开始总数量
          if (newdata.length == 0) {
            _this.refs.loadprogress.style.display = "none"
          } else {
            for (let i = 0; i < newdata.length; i++) {
              if (newdata[i].modelKey) {
                document.getElementById("moduleloadframe").contentWindow.viewer3D.addView(newdata[i].modelKey, "bole");
              }
            }
          }
        })
      }
    }else{
      this.setState({
        selectedKeysA:[],
        PathModel:"",
        TaskCode:"",
        begincount:0,
        loadingcount:0,
      })
    }
  }

  //kks树节点选择
  onSelectB(selectedKeys, info) {
    console.log(selectedKeys, info)
    this.setState({
      selectedKeysB: selectedKeys,
      CName:info.node.props.CName
    }, function () {
      axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=25', {
        DeviceKKS: this.state.selectedKeysB[0],
        TaskCode: this.state.TaskCode
      }).then(data => {
        console.log(data)
        this.setState({
          imgurl: conversion(data)[0] ? conversion(data)[0].Folder + conversion(data)[0].Filenewname : ""
        })
      })
      //设备参数
      axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=22', {
        DeviceKKS: this.state.selectedKeysB[0],
        TaskCode: this.state.TaskCode
      }).then(data => {
        console.log('巡检情况', conversion(data))
        var equipmentinfoarr = conversion(data)
        var equipmentinfoCarr = []
        var equipmentinfoCotherarr = []
        for (let i = 0; i < equipmentinfoarr.length; i++) {
          if (equipmentinfoarr[i].ParameterType == "1") {
            equipmentinfoCarr.push(equipmentinfoarr[i])
          } else {
            equipmentinfoCotherarr.push(equipmentinfoarr[i])
          }
        }
        var equipmentinfoBarr = equipmentinfoCotherarr.slice(6)
        var equipmentinfoAarr = equipmentinfoarr.slice(0, 6);
        this.setState({
          equipmentinfoAarr,
          equipmentinfoBarr,
          equipmentinfoCarr
        })
      })
    })
  }
  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDrag(e, ui) {
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  //添加巡检路线
  handleaddPatrolRouteFu() {

  }
  //查看历史记录弹框
  toviewhistoryrecord() {
    this.refs.routehistoryline.style.display = "block"
  }

  //关闭历史记录弹框
  closeroutehistoryline() {
    this.refs.routehistoryline.style.display = "none"
  }

  Collapsechange(key) {
    this.setState({
      Collapsekey: key
    })
  }
  render() {
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="PatrolRouteFu" style={{ height: clientHeight - 50 + 'px' }}>
        {/* 历史记录表信息 */}
        <Draggable
          position={this.state.controlledPosition}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDrag.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="routehistoryline" ref="routehistoryline" style={{ height: clientHeight - 50 - 120 + 'px' }}>
            <div className="routehistorylinetitle">
              历史记录表信息<span onClick={this.closeroutehistoryline.bind(this)}>x</span>
            </div>
            <div className="list" style={{ height: clientHeight - 50 - 40 - 120 + 'px' }}>
              <Table
                size="small"
                // showHeader={false}
                dataSource={this.state.historyrecordarr}
                columns={this.historyrecordcolumn}
                pagination={false}
                scroll={{ y: clientHeight - 50 - 40 - 120 - 40 + 'px' }}
              />
            </div>
          </div>
        </Draggable>

        <div className="PatrolRouteFuleftcenter"  style={{ height: clientHeight - 50 + 'px' }}>
          <Resizable className="box" axis="x" minConstraints={[100, 100]} maxConstraints={[400, 400]} height={clientHeight - 50} width={this.state.assetsviewleftw}
            onResize={(event, { element, size }) => {
              this.setState({ assetsviewleftw: size.width, assetsviewcenterw:440 - size.width });
            }}>
            <div className="PatrolRouteFuleft" ref="PatrolRouteFuleft" 
            style={{ height: clientHeight - 50 + 'px',width:this.state.assetsviewleftw+'px' }}>
              <h2 className="zhuangpeishu">
                <span>巡检任务</span>
              </h2>
              <div className="PatrolRouteFulefttree" style={{ height: clientHeight - 50 - 40 - 20 + 'px' }}>
                <Tree
                  onSelect={this.onSelectA.bind(this)}
                  selectedKeys={this.state.selectedKeysA}
                >
                  {
                    this.state.PatrolRouteFutreeData.map((itm, inde) => {
                      return (<TreeNode title={itm.TaskCode + '-'+ itm.PathCode + '-' + itm.TaskName} depth="1"
                        TaskCode={itm.TaskCode} PathId={itm.PathId} key={itm.id} PathModel={itm.PathModel}>
                        {/* {
                          itm.children && itm.children.length > 0 ?
                            itm.children.map((item, inde) => {
                              return (<TreeNode TaskCode={itm.TaskCode} title={<Tooltip title={item.ModelName}>{item.Code}</Tooltip>} key={item.id} ModelId={item.ModelId} />)
                            }) : ""
                        } */}
                      </TreeNode>)
                    })
                  }
                </Tree>
              </div>
            </div>
          </Resizable>
          <div className="PatrolRouteFucenter" ref="PatrolRouteFucenter" style={{
            height: clientHeight - 50 + 'px',width:this.state.assetsviewcenterw+'px'
          }}>
            <h2>
              <span className="left">巡检设备</span>
            </h2>
            <div className="PatrolRouteFucentertree" style={{ height: clientHeight - 50 - 40 + 'px' }}>
              <Tree
                selectedKeys={this.state.selectedKeysB}
                onSelect={this.onSelectB.bind(this)}
              >
                {
                  this.state.KKStreeData.length>0?
                  this.state.KKStreeData.map((item,index)=>{
                    return (<TreeNode title={item.ReadName} key={item.id} CName={item.CName}/>)
                  }):""
                }
              </Tree>
            </div>
          </div>

        </div>
        
        <div className="PatrolRouteFuright" ref="PatrolRouteFuright" style={{
          height: clientHeight - 50 + 'px',
        }}>
          <h2>巡检记录</h2>
          <div className="PatrolRouteFurightcon" style={{
            height: clientHeight - 50 - 40 + 'px',
          }}>

            <div className="equipinfo">
              <table>
                <tbody>
                  {
                    this.state.equipmentinfoAarr.length > 0 ? this.state.equipmentinfoAarr.map((item, index) => {
                      return (<tr key={index}><td>{item.ParameterName}</td><td>{item.ParameterName=="设备名称"?this.state.CName:item.ParameterName=="KKS编码"?this.state.selectedKeysB[0]:item.ParameterValues}</td></tr>)
                    }) : <Fragment></Fragment>
                  }
                </tbody>
              </table>
            </div>
            <div className="paramaterinfo">
              <table>
                <tbody>
                  {
                    this.state.equipmentinfoBarr.length > 0 ? this.state.equipmentinfoBarr.map((item, index) => {
                      return (<tr key={index}><td>{item.ParameterName}</td><td>{item.ParameterValues}</td></tr>)
                    }) : <Fragment></Fragment>
                  }
                </tbody>
              </table>
            </div>
            <div className="collapseant">
              <ul>
                {
                  this.state.equipmentinfoCarr.length > 0 ?
                    this.state.equipmentinfoCarr.map((item, index) => {
                      return (
                        <li onClick={this.Collapsechange.bind(this, index + 1)}>
                          <span>{item.ParameterName}</span>
                          <span>{item.ParameterValues}</span>
                          <span><span>{item.Describe}</span><span className={this.state.Collapsekey == "1" ? "tubiao tuhaoactive" : "tubiao"}>附</span></span>
                        </li>)
                    }) : ""
                }
              </ul>
            </div>
            <div className="imgtu">
              <img src={this.state.imgurl} alt=""
                style={{
                  maxHeight: '100%',
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%"
                }} />
            </div>
          </div>
        </div>
        <div className="moduleload" style={{ height: clientHeight - 50 + 'px', width: clientWidth + 'px' }}>
          <div className="loadprogress" ref="loadprogress">
            <Progress strokeWidth={15} type="circle" percent={parseInt((this.state.loadingcount / this.state.begincount) * 100)} status={(this.state.loadingcount / this.state.begincount) == 1 ? "success" : "active"} />
          </div>
          <iframe title="myiframe" src={this.state.moduleloadframesrc} id="moduleloadframe" style={{
            width: "100%", height: "100%",
          }}></iframe>
        </div>
        <div className="analoginspection">
          <Button style={{display:this.state.buttonarr.indexOf('模拟巡检')>-1?"inline-block":"none"}}>模拟巡检</Button>
          <Button style={{display:this.state.buttonarr.indexOf('自动巡检')>-1?"inline-block":"none"}}>自动巡检</Button>
        </div>
      </section >
    )
  }
}
export default PatrolRouteFuFu;

