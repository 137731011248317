import React from 'react';
import { Input, Button,Table, Form, Tooltip, message} from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import './style.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { onCellf } from '../../comfunc'
import store from '../../store';
moment.locale('zh-cn')
const { Search } = Input;
class Regularwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 历史定期工作列表分页
      pagecurrent: 1,
      pagesize: parseInt((store.getState().clientHeight - 50 - 50 - 50) / 28),

      dataSource: [],
      dataSourcefu: [],//定期工作列表
    }
    this.columns = [
      {
        title: '序号', dataIndex: 'xuhao', key: 'xuhao', width: "6%",
        render: (text, record, index) => {
          return `${(this.state.pagecurrent - 1) * this.state.pagesize + index + 1}`
        }
      },
      {
        title: '项目', dataIndex: 'WorkContent', key: 'WorkContent', width: "20%",
        onCell: onCellf,
        render: (text) => {
          return (<Tooltip title={text}><div style={{ textAlign: "left" }}> {text}</div></Tooltip>)
        }
      },
      { title: '项目类别', dataIndex: 'WorkType', key: 'WorkType', width: "15%", },
      { title: '时间', dataIndex: 'CreateTime', key: 'CreateTime', width: "20%" },
      {
        title: '状态', dataIndex: 'IsAuditFinished', key: 'IsAuditFinished', width: "10%",
        render: (text) => {
          return (<div> {text == "True" ? "已完成" : "未完成"}</div>)
        }
      },
      {
        title: '操作人', dataIndex: 'TrackAuditUser', key: 'TrackAuditUser', width: "10%"
      },
      {
        title: '备注', dataIndex: 'Remarks', key: 'Remarks', width: "18%",
        onCell: onCellf,
        render: this.renderf
      },
    ]


    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" title={text}><div style={{ textAlign: 'left' }}>{text}</div></Tooltip>)
    }
  }
  componentDidMount() {
    var arr = []
    for (let i = 1; i < 32; i++) {
      arr.push(i + '号')
    }
    this.setState({
      montharr: arr
    })
    //历史定期工作列表
    axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=1').then(data => {
      // //console.log('定期工作列表', conversion(data))
      this.setState({
        dataSource: conversion(data),
        dataSourcefu: conversion(data)
      })
    })
    
  }
  updatedata() {
    //历史定期工作列表
    axios.get('/DATA/IntelligentDecision/RegularWork.aspx?flag=1').then(data => {
      // //console.log('定期工作列表', conversion(data))
      this.setState({
        dataSource: conversion(data),
        dataSourcefu: conversion(data)
      }, function () {
        message.success('刷新成功！')
      })
    })

  }
  componentWillReceiveProps(nextprops) {
    this.setState({
      pagesize: parseInt((store.getState().clientHeight - 50 - 50 - 50) / 28),
    })
  }
  //定期工作列表搜索
  regularworklistsearch(value) {
    //console.log(value)
    //项目 项目类别 
    var arr = this.state.dataSourcefu
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].WorkContent.indexOf(value) > -1 || arr[i].WorkType.indexOf(value) > -1) {
        newarr.push(arr[i])
      }
    }
    this.setState({
      dataSource: newarr
    })
  }

  pagechange(page) {
    this.setState({
      pagecurrent: page.current,
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="regularwork" style={{ width: "100%", height: clientHeight - 50 + 'px' }}>
        <div className="regularworklist">
          <div className="regularworkrightsearch">
            <Search
              placeholder="关键字搜索"
              onSearch={this.regularworklistsearch.bind(this)}
              style={{ width: 200 }}
            />
            <Button onClick={this.updatedata.bind(this)}>刷新</Button>
          </div>
          <div className="listpage" style={{ height: clientHeight - 50 - 50 + 'px' }}>
            <Table
              size="small"
              columns={this.columns}
              dataSource={this.state.dataSource}
              pagination={{
                pageSize: this.state.pagesize,
                current: this.state.pagecurrent,
              }}
              onChange={this.pagechange.bind(this)}
            />
          </div>
        </div>

      </section>
    )
  }
}
const Regularworkapp = Form.create()(Regularwork);
export default Regularworkapp;
