import React, { Component, Fragment  } from 'react';
import { Select, Input, Button, Radio , Tree, Table, Modal,Popconfirm, Form, Row, Col, message } from 'antd';
import 'antd/dist/antd.css';
//多条件搜索
import $ from 'jquery'
import moment from 'moment';
import axios from 'axios';
import './style.scss'
import { Resizable } from 'react-resizable';
import Search from 'antd/lib/input/Search';
import store from '../../../store';
import { conversion } from '../../../converse';
import { toMenuTreeData } from '../../../utils/menutree'
const { Option } = Select;
const { TextArea } = Input
const { TreeNode } = Tree;
class PortalEngine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iptobj: {},
      addeditflag: "",
      visible: false,
      
      data:[],
      datalength:0,
      pageIndex: 1,
      RoleId:"",
      columns: [
        {
          dataIndex: 'XuHao',
          key: 'XuHao',
          title: '序号',
          width: 200,
          render:(text,record,index)=>{
          return(<span>{index+1}</span>)
          }
        },
        {
          dataIndex: 'Name',
          key: 'Name',
          title: '名称',
          width: 260,
        },
        {
          dataIndex: 'ModuleType',
          key: 'ModuleType',
          title: '类型',
          width: 100,
          render:(text, record)=>{
            var str = ""
            if(text==0){
              str = "目录"
            }else if(text==1){
              str = "菜单"
            }else{
              str = "按钮"
            }
            return (<span>{str}</span>)
          }
        },
        {
          dataIndex: 'code',
          key: 'code',
          title: '编码',
          //width: 100,
        },
        {
          dataIndex: 'url',
          key: 'url',
          title: '接口',
          //width: 100,
        },
        {
          dataIndex: 'remark',
          key: 'remark',
          title: '备注',
          //width: 100,
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            title: '操作',
            width: 100,
            render: (text, record) =>
              this.state.data.length >= 1 ? (
                <div className="listoperationa">
                  <a onClick={this.editModal.bind(this, record)}>编辑</a>
                  <Popconfirm title="确定要删除吗?" onConfirm={() => this.toDellist(record)}>
                    <a>删除</a>
                  </Popconfirm>
                </div>
              ) : null,
          }
      ],
      rowId:"",
      rowObj:{},
      attr:{
        ID:'ID',
        rootId:'0',
        Name:'Name',
        ParentId:'ParentId',
        Leaf:'Leaf',
        ModuleType:'ModuleType',
        Visable:'Visable',
        RecordVersion:'RecordVersion'
      }
    }
  }

  componentDidMount() {
    this.getListData()
  }
  
  test (arr ){
    var arr = arr
    for(let i=0;i<arr.length;i++){
      if(arr[i].children.length>0){
        this.test(arr[i].children)
      }else{
        delete arr[i].children
      }
    }
  }
  testfirst(arr){
    this.test(arr)
    return arr
  }
  getListData () {
    axios.get('/DATA/ParaSet/FunctionMenu.aspx?flag=1').then(res=>{
      console.log('菜单树',res,conversion(res))
      var arr = conversion(res)
      var newarr = toMenuTreeData(arr,this.state.attr)
      console.log( newarr )
      this.setState({
        data:this.testfirst(newarr)
      })
    })
  }
  shouldComponentUpdate() {
    return true;
  }

  //删除
  toDellist(record){
    var arr = []
    arr.push(record)
    var _this = this
    axios.post(`${global.requrl.url}/menu/delete`,arr).then((res)=>{
      console.log(res)
      if(res.data.data == 1){
        _this.getListData()
        _this.setState({
          rowId:"",
          rowObj:{}
        })
        message.success('删除成功！')
      }
    })
  }
  //编辑
  editModal(record){
    console.log(record)
    this.setState({
      visible:true,
      hasclick:false,
      addeditflag:"编辑",
      iptobj:record,
    },function(){
      this.props.form.resetFields();
    })
  }
  //增加
  addModal(){
    var obj = {}
    this.setState({
      visible:true,
      addeditflag:"新增",
      iptobj:obj
    },function(){
      this.props.form.resetFields();
    })
  }
   //提交
  modalSave = e =>{
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if(!err){
        if(this.state.addeditflag=="编辑"){
          var obj = this.state.iptobj
          obj.name = values.name
          obj.type = values.type
          obj.url = values.url
          obj.code = values.code
          obj.remark = values.remark
        }else if(this.state.addeditflag=="新增"){
          var obj = values
          obj.id = ""
          obj.createBy = ""
          obj.createTime = ""
          obj.lastUpdateBy = ""
          obj.lastUpdateTime = ""
          obj.plantCode = ""
          obj.serialNumber = ""
          obj.level= ""
          obj.children = []
          obj.parentId = this.state.rowId?this.state.rowId:"0"
          // values.parentName = this.state.rowId?this.state.rowObj.name:""
        }
        obj.systemCode = this.state.systemCode
        obj.systemVersion = this.state.systemVersion

        axios.post(`${global.requrl.url}/menu/save`,obj).then((res)=>{
          console.log(res)
          if(res.data.data == 1){
            this.setState({
              visible:false
            },function(){
              this.getListData()
              message.success('保存成功！')
            })
          }
        })
      }
    })
  }
  //重置
  modalReset(){
    this.setState({
      iptobj:{}
    },function(){
      this.props.form.resetFields();
    })
  }
  //取消
  modalCancel(){
    this.setState({
      visible:false
    })
  }
  //表格拉伸
  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  senddata(record) {
    if(this.state.rowId == record.id){
      this.setState({
        rowId:"",
        rowObj:{},
      })
    }else{
      this.setState({
        rowId:record.id,
        rowObj:record,
      })
    }
  }

  setRowClassName = (record) => {
    return record.id === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }
  render() {
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    const { getFieldDecorator } = this.props.form;
    const clientH = store.getState().clientHeight

    return (
      <Fragment>
        <div className="PortalEngine">
          <Modal
            title={"基础数据-菜单管理-"+this.state.addeditflag}
            visible={this.state.visible}
            footer={null}
            onCancel={this.modalCancel.bind(this)}
            width={1000}
            height={Number(this.props.clientH) > 800 ? 680 : 480}
          >
            <div className="modalboxcon" style={{
                height: Number(this.props.clientH) > 800 ? "600px" : "400px",
            }}>
              <div className="modalactionbutton">
                <Button style={{display:this.state.addeditflag=="查看"?"none":"inline-block"}} 
                disabled={this.state.hasclick} onClick={this.modalSave.bind(this)}>提交</Button>
                <Button style={{display:this.state.addeditflag=="查看"|| this.state.addeditflag=="编辑"?"none":"inline-block"}} 
                onClick={this.modalReset.bind(this)}>重置</Button>
                <Button onClick={this.modalCancel.bind(this)}>关闭</Button>
              </div>
              <Form className="datadictionaryform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="权限名称:">
                      {getFieldDecorator('name', {
                        initialValue: this.state.iptobj["name"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                      })(
                        <Input  />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="模块与功能:">
                      {getFieldDecorator('type', {
                        initialValue: this.state.iptobj["type"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                      })(
                        <Radio.Group >
                          <Radio value={0}>目录</Radio>
                          <Radio value={1}>菜单</Radio>
                          <Radio value={2}>按钮</Radio>
                        </Radio.Group >
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="接&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;口:">
                      {getFieldDecorator('url', {
                        initialValue: this.state.iptobj["url"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                      })(
                        <Input/>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={36} className="formneccessary">
                    <Form.Item label="编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:">
                      {getFieldDecorator('code', {
                        initialValue: this.state.iptobj["code"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }]
                       })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="textarea4">
                  <Col span={24}>
                    <Form.Item label="备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注:">
                      {getFieldDecorator('remark', {
                        initialValue: this.state.iptobj["remark"],
                        rules: [{ required: false, message: '该字段不能为空！' }],
                      })(
                        <TextArea rows={4}/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
          <div className="listsearch">
            <div className="listsearchl">
                <Search placeholder="请输入搜索内容"/>
            </div>
            <div className="listactionr">
              {/* <Button className="sameaction"><span className="listuploadtu"></span>上传</Button>
              <Button className="sameaction"><span className="listsubtu"></span>提交</Button>
              <Button className="sameaction"><span className="listprinttu"></span>打印</Button> */}
              <Button className="addaction" onClick={this.addModal.bind(this)}><span className="listaddtu"></span>添加</Button>
            </div>
          </div>
          <div className="listscroll" style={{ height: clientH - 50 - 40 + 'px' }}>
            <Table
              rowKey={record => record.ID}
              columns={columns}
              dataSource={this.state.data}
              size="small"
              scroll={{y:clientH - 50 - 40 -20 -40}}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: this.senddata.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassName}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

const PortalEngineapp = Form.create()(PortalEngine)
export default PortalEngineapp;