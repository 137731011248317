import React, { Component, Fragment } from 'react';
import {changemoduledata} from '../../../store/actionCreators'
import { Select, Input, Button, Radio, Icon, Tree,Tooltip, Table, Modal, Popconfirm, Form, Row, Col, message, Drawer, Upload } from 'antd';
import 'antd/dist/antd.css';
//多条件搜索
import $ from 'jquery'
import moment from 'moment';
import axios from 'axios';
import './style.scss'
import store from '../../../store';
import { conversion } from '../../../converse';
import { toMenuTreeData } from '../../../utils/menutree'
import { getButtonLimit } from '../../../utils/getbuttonlimit'
import { onCellf } from '../../../comfunc';
const { Option } = Select;
const { TextArea, Search } = Input
const { TreeNode } = Tree;
const { confirm } = Modal;

const renderf = (text) => {
  return (<Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>)
}
class MenuManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],
      iptobj: {},
      addeditflag: "",
      visible: false,

      data: [],
      datalength: 0,
      pageIndex: 1,
      RoleId: "",

      rowId: "",
      rowObj: {},
      attr: {
        ID: 'ID',
        rootId: '0',
        Name: 'Name',
        ParentId: 'ParentId',
        Code:'Code',
        Leaf: 'Leaf',
        ModuleType: 'ModuleType',
        Visable: 'Visable',
        RecordVersion: 'RecordVersion',
        Rank:'Rank',
        IconCls:'IconCls',
        OnClickIconCls:'OnClickIconCls'
      },

      loading: false,
      imageUrl: "",
      imageFlag: "0",
      
      loading1: false,
      imageUrl1: "",
      imageFlag1: "0",
    }
    this.columns = [
      {
        dataIndex: 'Name',
        key: 'Name',
        title: '名称',
        width: 300,
      },
      {
        dataIndex: 'Code',
        key: 'Code',
        title: '编码',
        width: 100,
        oncell:onCellf,
        render:this.renderf
      },
      {
        dataIndex: 'ModuleType',
        key: 'ModuleType',
        title: '类型',
        width: 100,
        render: (text, record) => {
          var str = ""
          if (text == 1) {
            str = "模块"
          } else if (text == 2) {
            str = "功能"
          } else{
            str = "按钮"
          }
          return (<span>{str}</span>)
        }
      },
      {
        dataIndex: 'IconCls',
        key: 'IconCls',
        title: '图片(选中前)',
        width: 300,
      },
      {
        dataIndex: 'OnClickIconCls',
        key: 'OnClickIconCls',
        title: '图片(选中后)',
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        title: '操作',
        width: 100,
        render: (text, record) =>
          this.state.data.length >= 1 ? (
            <div className="listoperationa">
              <a style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.editModal.bind(this, record)}>编辑</a>
              <a style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={() => this.toDellist(record)}>删除</a>
            </div>
          ) : null,
      }
    ]
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(25)

    this.getListData()
  }

  test(arr) {
    var arr = arr
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].children.length > 0) {
        this.test(arr[i].children)
      } else {
        delete arr[i].children
      }
    }
  }
  testfirst(arr) {
    this.test(arr)
    return arr
  }
  getListData() {
    axios.get('/DATA/WebSet/FunctionMenu.aspx?flag=1').then(res => {
      // console.log('菜单树', res, conversion(res))
      
      var arr = conversion(res)
      var newarr = toMenuTreeData(arr, this.state.attr)
      this.setState({
        data: this.testfirst(newarr)
      })
    })
    
    axios.get(`/DATA/WebSet/T_PermitMenu.aspx?flag=2`).then(res=>{
      console.log(res)
      if(res.data.success){
        var arr = res.data.data
        const action = changemoduledata(arr)
        store.dispatch(action)
      }
    })
  }
  shouldComponentUpdate() {
    return true;
  }

  //删除
  toDellist(record) {
    var _this = this
    confirm({
      title: '确定要删除吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post(`/DATA/WebSet/FunctionMenu.aspx?flag=4`,{
          ID:record.ID
        }).then((res) => {
          // console.log(res)
          if (res.data.success) {
            _this.getListData()
            _this.setState({
              rowId: "",
              rowObj: {}
            })
            message.success('删除成功！')
          }
        })
      },
      onCancel() {

      },
    });
  }
  //编辑
  editModal(record) {
    this.setState({
      visible: true,
      hasclick: false,
      addeditflag: "编辑",
      iptobj: record,
      imageUrl:record.IconCls,
      imageUrl1:record.OnClickIconCls,
      imageFlag:"0",
      imageFlag1:"0"
    }, function () {
      this.props.form.resetFields();
    })
  }
  //增加
  addModal() {
    var obj = {}
    obj.ModuleType=1
    this.setState({
      visible: true,
      addeditflag: "新增",
      iptobj: obj,
      imageUrl:"",
      imageUrl1:"",
    }, function () {
      this.props.form.resetFields();
    })
  }
  //提交
  modalSave = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      // console.log(err, values)
      if (!err) {
        var flag = 0
        if (this.state.addeditflag == "编辑") {
          var obj = this.state.iptobj
          values.ID = obj.ID
          values.IconCls =this.state.imageFlag=="1" ?this.state.imageUrl:""
          values.OnClickIconCls =this.state.imageFlag1=="1" ? this.state.imageUrl1:""
          flag = 3
        } else if (this.state.addeditflag == "新增") {
          values.ParentId = this.state.rowId ? this.state.rowId : "0"
          values.Rank = this.state.rowId?Number(this.state.rowObj.Rank)+1:"0"
          values.IconCls = this.state.imageUrl?this.state.imageUrl:""
          values.OnClickIconCls = this.state.imageUrl1?this.state.imageUrl1:""
          flag = 2
        }
        axios.post(`/DATA/WebSet/FunctionMenu.aspx?flag=${flag}`, values).then((res) => {
          // console.log(res)
          if (res.data.success) {
            this.setState({
              visible: false
            }, function () {
              this.getListData()
              message.success('保存成功！')
            })
          }
        })
      }
    })
  }
  //重置
  modalReset() {
    this.setState({
      iptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  //取消
  modalCancel() {
    this.setState({
      visible: false
    })
  }


  //图片上传
  getBase64(img, callback) {
    //console.log(img, callback)
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  //限制上传图片的格式和大小
  beforeUpload(file) {
    // console.log(file)
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('超过10M限制，不允许上传!');
    }
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    const isBMP = file.type === 'image/bmp';
    if (!(isJPG || isJPEG || isGIF || isPNG || isBMP)) {
      message.error('只能上传JPG 、JPEG 、GIF、 PNG、 BMP格式的图片!')
    }
    return (isBMP || isGIF || isJPEG || isJPG || isPNG) && isLt2M;
  }
  shouldComponentUpdate() {
    return true;
  }
  handletuChange = (key,info) => {
    // console.log(info)
    if(key=="图片"){
      if (info.file.status === 'uploading') {
        this.setState({ loading: true, imageUrl: "" });
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.getBase64(info.file.originFileObj, imageUrl => this.setState({
          imageUrl,
          loading: false,
          imageFlag: '1'
        }, function () {
          // console.log(this.state.imageUrl);
          // message.success(`${info.file.name} 上传成功`);
        }));
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} 上传失败`);
      }
    }else if(key=="点击图片"){
      if (info.file.status === 'uploading') {
        this.setState({ loading1: true, imageUrl1: "" });
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.getBase64(info.file.originFileObj, imageUrl1 => this.setState({
          imageUrl1:imageUrl1,
          loading1: false,
          imageFlag1: '1'
        }, function () {
          // console.log(this.state.imageUrl1);
          // message.success(`${info.file.name} 上传成功`);
        }));
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} 上传失败`);
      }
    }
  }
  senddata(record) {
    if (this.state.rowId == record.ID) {
      this.setState({
        rowId: "",
        rowObj: {}
      })
    } else {
      this.setState({
        rowId: record.ID,
        rowObj: record
      })
    }
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }
  onClose() {
    this.setState({
      visible: false,
      iptobj: {}
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientH = store.getState().clientHeight
    const { imageUrl,imageUrl1 } = this.state
    return (
      <Fragment>
        <div className="menumanage">
          <Drawer
            title={'菜单' + this.state.addeditflag}
            onClose={this.onClose.bind(this)}
            visible={this.state.visible}
            className="Drawercommon"
          >
            <div className="Drawercommonaddedit" style={{ height: clientH - 50 - 55 + 'px' }}>
              <Form className="datadictionaryform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="名&nbsp;&nbsp;&nbsp;&nbsp;称:">
                      {getFieldDecorator('Name', {
                        initialValue: this.state.iptobj["Name"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input style={{width:"180px"}}/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="编&nbsp;&nbsp;&nbsp;&nbsp;码:">
                      {getFieldDecorator('Code', {
                        initialValue: this.state.iptobj["Code"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input style={{width:"180px"}}/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="类&nbsp;&nbsp;&nbsp;&nbsp;型:">
                      {getFieldDecorator('ModuleType', {
                        initialValue: Number(this.state.iptobj["ModuleType"]),
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Radio.Group >
                          <Radio value={1}>模块</Radio>
                          <Radio value={2}>功能</Radio>
                          <Radio value={3}>按钮</Radio>
                        </Radio.Group >
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="图&nbsp;&nbsp;&nbsp;&nbsp;片:">
                      {getFieldDecorator('IconCls', {
                        // initialValue: this.state.iptobj["IconCls"],
                        rules: [{ required: false, message: () => { return (<span></span>) } }],
                      })(
                        <div className="menuimg">
                          <div className="image">
                            <Upload
                              name=""
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              beforeUpload={this.beforeUpload}
                              onChange={this.handletuChange.bind(this,'图片')}
                            >
                              {
                                imageUrl ?
                                  <img src={imageUrl} style={{
                                    width: "auto", height: "auto",
                                    maxHeight: "120px",
                                    maxWidth: "120px",
                                    minHeight: "80px",
                                    minWidth: "80px",
                                  }} alt="" />
                                  :
                                  <div>
                                    <Icon type={this.state.loading ? 'loading' : 'plus'} />
                                    <div className="ant-upload-text">点击此处上传照片</div>
                                  </div>
                              }
                            </Upload>
                          </div>
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="添加图片:">
                      {getFieldDecorator('OnClickIconCls', {
                        // initialValue: this.state.iptobj["OnClickIconCls"],
                        rules: [{ required: false, message: () => { return (<span></span>) } }],
                      })(
                        <div className="menuimg">
                          <div className="image">
                            <Upload
                              name=""
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              beforeUpload={this.beforeUpload}
                              onChange={this.handletuChange.bind(this,'点击图片')}
                            >
                              {
                                imageUrl1 ?
                                  <img src={imageUrl1} style={{
                                    width: "auto", height: "auto",
                                    maxHeight: "120px",
                                    maxWidth: "120px",
                                    minHeight: "80px",
                                    minWidth: "80px",
                                  }} alt="" />
                                  :
                                  <div>
                                    <Icon type={this.state.loading1 ? 'loading' : 'plus'} />
                                    <div className="ant-upload-text">点击此处上传照片</div>
                                  </div>
                              }
                            </Upload>
                          </div>
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="formbutton">
                  <Form.Item>
                    <Button htmlType="submit" onClick={this.modalSave.bind(this)} type="primary">
                      保存
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Drawer>

          <div className="listsearch">
            <div className="listsearchl">
              {/* <Search placeholder="请输入搜索内容" /> */}
            </div>
            <div className="listactionr">
              <Button className="addaction" style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.addModal.bind(this)}><span className="listaddtu"></span>添加</Button>
            </div>
          </div>
          <div className="listscroll" style={{ height: clientH - 50 - 40 - 30 + 'px' }}>
            <Table
              rowKey={record => record.ID}
              columns={this.columns}
              dataSource={this.state.data}
              size="small"
              scroll={{ y: clientH - 50 - 40 - 20 - 40 }}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: this.senddata.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassName}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

const MenuManageapp = Form.create()(MenuManage)
export default MenuManageapp;

{/* <Modal
            title={"菜单管理-"+this.state.addeditflag}
            visible={this.state.visible}
            footer={null}
            onCancel={this.modalCancel.bind(this)}
            width={680}
            height={400}
          >
            <div className="modalboxcon" >
              <div className="modalactionbutton">
                <Button style={{display:this.state.addeditflag=="查看"?"none":"inline-block"}} 
                disabled={this.state.hasclick} onClick={this.modalSave.bind(this)}>提交</Button>
                <Button style={{display:this.state.addeditflag=="查看"|| this.state.addeditflag=="编辑"?"none":"inline-block"}} 
                onClick={this.modalReset.bind(this)}>重置</Button>
                <Button onClick={this.modalCancel.bind(this)}>关闭</Button>
              </div>
              <Form className="datadictionaryform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <Row gutter={16}>
                  <Col span={24} className="formneccessary">
                    <Form.Item label="权限名称:">
                      {getFieldDecorator('name', {
                        initialValue: this.state.iptobj["name"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                      })(
                        <Input  />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24} className="formneccessary">
                    <Form.Item label="模块与功能:">
                      {getFieldDecorator('type', {
                        initialValue: this.state.iptobj["type"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                      })(
                        <Radio.Group >
                          <Radio value={0}>模块</Radio>
                          <Radio value={1}>功能</Radio>
                        </Radio.Group >
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24} className="formneccessary">
                    <Form.Item label="接&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;口:">
                      {getFieldDecorator('url', {
                        initialValue: this.state.iptobj["url"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                      })(
                        <Input/>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24} className="formneccessary">
                    <Form.Item label="图&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;片:">
                      {getFieldDecorator('img', {
                        initialValue: this.state.iptobj["img"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                      })(
                        <Input/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24} className="formneccessary">
                    <Form.Item label="编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:">
                      {getFieldDecorator('code', {
                        initialValue: this.state.iptobj["code"],
                        rules: [{ required: true, message: ()=>{return (<span></span>)} }]
                       })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
           */}