import React from 'react';
import { Input, Spin, message, Button, Select, Radio, Drawer, Form, Col, Row, Badge, Tabs, Tooltip, Table, DatePicker, Modal, InputNumber, TimePicker } from 'antd';
import axios from 'axios';
import { conversion } from '../../../converse'
import $ from 'jquery'
import locale from 'antd/lib/date-picker/locale/zh_CN'
// 引入折现图
import echarts from 'echarts';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import './style.scss'
import moment from 'moment'
import Draggable from 'react-draggable';
import 'moment/locale/zh-cn'
import { element } from 'prop-types';
import { timingSafeEqual } from 'crypto';
import { onCellf } from '../../../comfunc'
import store from '../../../store';
import { getButtonLimit } from '../../../utils/getbuttonlimit'
moment.locale('zh-cn')
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { TextArea } = Input
const confirm = Modal.confirm
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<InputNumber ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
            children
          )}
      </td>
    );
  }
}

class PerformanceAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr: [],

      startValue: null,
      endValue: null,
      endOpen: false,

      //计算状态
      counflag: false,
      uname: '',
      //页签
      activeKey: "1",
      iptobj: {},
      addedit: "添加",


      //排班设置
      turnclass: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      turnclassselect: 0,//轮班天数 
      turnclassselectdata: [],
      classesselect: 0,//每天班次 1 2 3
      classesselectdata: [],
      classgroupselect: 0,//班组 1 2 3 4 5 6 7 8
      classgroupselectdata: [],

      data1: [],//考核版本记录
      verdetailid: '',
      verdetailobj: {},
      data1length: 0,
      data2: [],//考核详情
      examdetailid: '',
      examdetailobj: {},
      data2length: 0,
      data3: [],//指标设置
      indexdetailid: '',
      data3length: 0,
      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 70 - 40 - 50) / 28),
      pageIndex1: 1,
      pageIndex2: 1,
      pageIndex3: 1,


      data11: [],//排班记录
      pageIndex11: 1,
      data11length: 0,
      classdetailid: '',
      classdetailobj: {},

      data12: [],
      pageIndex12: 1,
      data12length: 0,

      //排班详细
      classgroupsdetaildata: [],

      //排班记录查询
      valuesid: 0,

      //测点初始化
      Cedianinitdata: []
    }
    //考核版本记录
    this.data1columns = [
      {
        title: '制度名称',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '50%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '版本号',
        dataIndex: 'VersionNumber',
        key: 'VersionNumber',
        width: '20%',
      },
      {
        title: '状态',
        dataIndex: 'State',
        key: 'State',
        width: '30%',
        render: (text, record) => {
          if (text == "2") {
            return (<span>废弃使用</span>)
          } else if (text == "0") {
            return (<span>准备就绪</span>)
          } else if (text == "1") {
            return (<span>正在使用</span>)
          }
        }
      }
    ]
    //考核详情
    this.data2columns = [
      {
        title: '序号',
        dataIndex: 'IndexId',
        key: 'IndexId',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex2 - 1) * this.state.pageSize + index + 1}</span>)
        }
      }, {
        title: '指标名称',
        dataIndex: 'IndexName',
        key: 'IndexName',
        width: '40%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '权重%',
        dataIndex: 'Weights',
        key: 'Weights',
        width: '10%',
      },
      {
        title: '测点号',
        dataIndex: 'Testid',
        key: 'Testid',
        width: '15%',
      },
      {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '10%',
      },
      {
        title: '数据来源',
        dataIndex: 'Sources',
        key: 'Sources',
        width: '15%',
        render: (text) => {
          if (text == "0") {
            return (<span>数据录入</span>)
          } else if (text == "1") {
            return (<span>数据导入</span>)
          }
        }
      }
    ]
    //指标设置
    this.data3columns = [
      {
        title: '序号',
        dataIndex: 'IndexId',
        key: 'IndexId',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex3 - 1) * this.state.pageSize + index + 1}</span>)
        }
      }, {
        title: '上限',
        dataIndex: 'Highlimit',
        key: 'Highlimit',
        width: '25%',
      },
      {
        title: '下限',
        dataIndex: 'Lowerlimit',
        key: 'Lowerlimit',
        width: '25%',
      },
      {
        title: '时长(5s)',
        dataIndex: 'Duration',
        key: 'Duration',
        width: '25%',
      },
      {
        title: '得分',
        dataIndex: 'Score',
        key: 'Score',
        width: '15%',
      }
    ]
    //排班列表
    this.data11columns = [
      {
        title: '序号',
        dataIndex: 'IndexId',
        key: 'IndexId',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex11 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '排班名称',
        dataIndex: 'name',
        key: 'name',
        width: '60%',
      },
      {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '30%',
        render: (text) => {
          if (text == "1") {
            return (<span>启用中</span>)
          } else if (text == "0") {
            return (<span>未启用</span>)
          } else if(text=="2"){
            return (<span>废弃</span>)
          }
        }
      },
    ]
    //排班详情
    this.data12columns = [
      {
        title: '序号',
        dataIndex: 'IndexId',
        key: 'IndexId',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex12 - 1) * this.state.pageSize + index + 1}</span>)
        }
      }, {
        title: '日期',
        dataIndex: 'dateshift',
        key: 'dateshift',
        width: '40%',
      },
      {
        title: '早班',
        dataIndex: 'morning',
        key: 'morning',
        width: '25%',
      },
      {
        title: '晚班',
        dataIndex: 'night',
        key: 'night',
        width: '25%',
      },
      // {
      //   title: '中班',
      //   dataIndex: 'Score2',
      //   key: 'Score2',
      //   width: '20%',
      // }
    ]
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    // console.log('按钮权限', res)
    this.setState({
      buttonarr: res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(131)

    //日历
    var uname = sessionStorage.getItem('uname')
    this.setState({
      uname
    })
    axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=2', {
      pageSize: this.state.pageSize,
      pageIndex: this.state.pageIndex1
    }).then(data => {
      //console.log('考核版本记录', conversion(data))
      var str = data.data
      this.setState({
        data1: conversion(data),
        data1length: str.substring(12, str.indexOf(','))
      }, function () {
        var arr = this.state.data1
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].State == "1") {
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=4', {
              Vid: arr[i].id,
              pageSize: this.state.pageSize,
              pageIndex: this.state.pageIndex2
            }).then(data => {
              var str = data.data
              //console.log('考核详情', conversion(data))
              this.setState({
                data2: conversion(data),
                data2length: str.substring(12, str.indexOf(',')),
                verdetailid: arr[i].id,
                verdetailobj: arr[i],
                examdetailid: conversion(data)[conversion(data).length - 1].id,
                examdetailobj: conversion(data)[conversion(data).length - 1],
              }, function () {
                var arr1 = this.state.data2
                axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=9', {
                  Vid: this.state.verdetailid,
                  Wid: this.state.examdetailid,
                  pageSize: this.state.pageSize,
                  pageIndex: this.state.pageIndex3
                }).then(data => {
                  var str = data.data
                  //console.log('指标设置', conversion(data))
                  this.setState({
                    data3: conversion(data),
                    data3length: str.substring(12, str.indexOf(','))
                  })
                })
              })
            })
          }
        }
      })
    })

    //测点初始化
    axios.get('/DATA/IntelligentDecision/PerformanceAppraisal/ImportIndex.aspx?flag=14').then(data => {
      //console.log(conversion(data))
      this.setState({
        Cedianinitdata: conversion(data)
      })
    })
  }
  componentWillReceiveProps(nextprops) {
    //////console.log(nextprops)
    this.setState({
      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 70 - 40 - 50) / 28),
    })
  }


  //选中
  senddata1(record) {
    //console.log(record)
    this.setState({
      verdetailid: record.id,
      verdetailobj: record,
    }, function () {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=4', {
        Vid: this.state.verdetailid,
        pageSize: this.state.pageSize,
        pageIndex: 1
      }).then(data => {
        var str = data.data
        //console.log('考核详情', conversion(data))
        this.setState({
          data2: conversion(data),
          pageIndex2: 1,
          data2length: str.substring(12, str.indexOf(',')),
          examdetailid: '',
          examdetailobj: {},
        })
      })
    })
  }
  setRowClassName1 = (record) => {
    return record.id === this.state.verdetailid ? 'clickRowStyl' : 'clickRowSty';
  }
  senddata2(record) {
    //console.log(record)
    this.props.form.resetFields()
    this.setState({
      examdetailid: record.id,
      examdetailobj: record,
    }, function () {
      axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=9', {
        Vid: this.state.verdetailid,
        Wid: this.state.examdetailid,
        pageSize: this.state.pageSize,
        pageIndex: 1
      }).then(data => {
        var str = data.data
        //console.log('指标设置', conversion(data))
        this.setState({
          data3: conversion(data),
          pageIndex3: 1,
          data3length: str.substring(12, str.indexOf(','))
        })
      })

    })
  }
  setRowClassName2 = (record) => {
    return record.id === this.state.examdetailid ? 'clickRowStyl' : 'clickRowSty';
  }
  senddata3(record) {
    //console.log(record)
    this.setState({
      indexdetailid: record.id,
      indexdetailobj: record,
    })
  }
  setRowClassName3 = (record) => {
    return record.id === this.state.indexdetailid ? 'clickRowStyl' : 'clickRowSty';
  }

  //添加
  handleAdd(key) {
    if (key == "考核版本") {
      this.setState({
        addedit: "添加",
        addeditname: key,
        iptobj: {},
        visible: true,
      }, function () {
        this.props.form.resetFields()
        //this.refs.formhandleadd.style.display = "block"
      })
    } else if (key == "考核详情") {
      if (this.state.verdetailid) {
        this.setState({
          addedit: "添加",
          addeditname: key,
          iptobj: {},
          visible: true,
        }, function () {
          this.props.form.resetFields()
          //this.refs.formhandleadd.style.display = "block"
        })
      } else {
        message.error('请选择考核版本！')
      }
    } else if (key == "指标设置") {
      //console.log(this.state.examdetailid)
      if (this.state.examdetailid) {
        this.setState({
          addedit: "添加",
          addeditname: key,
          iptobj: {},
          visible: true,
        }, function () {
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择指标！')
      }
    } else if (key == "排班记录") {
      this.setState({
        addedit: "添加",
        addeditname: key,
        classgroupselectdata: [],
        classesselectdata: [],
        turnclassselectdata: [],
        visible: true,
      }, function () {
        this.props.form.resetFields()
      })
    }
  }
  //编辑
  handleEdit(key) {
    if (key == "考核详情") {
      if (this.state.examdetailid) {
        var obj = this.state.examdetailobj
        this.setState({
          addedit: "编辑",
          addeditname: key,
          iptobj: obj,
          visible: true,
        }, function () {
          //this.refs.formhandleadd.style.display = "block"
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "指标设置") {
      if (this.state.indexdetailid) {
        var obj = this.state.indexdetailobj
        this.setState({
          addedit: "编辑",
          addeditname: key,
          iptobj: obj,
          visible: true,
        }, function () {
          //this.refs.formhandleadd.style.display = "block"
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "排班记录") {
      if (this.state.classdetailid) {
        var obj = this.state.classdetailobj
        this.setState({
          addedit: "编辑",
          addeditname: key,
          iptobj: obj,
          visible: true,
        }, function () {
          //this.refs.formhandleadd.style.display = "block"
        })
      } else {
        message.error('请选择！')
      }
    }
  }
  //保存
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate() + ' ' + value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else if (value._i) {
            values[i] = value._i.split(' ')[1]
          } else {
            values[i] = values[i]
          }
        }
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "考核版本") {
            values.username = this.state.uname
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=1', values).then(data => {
              //console.log(data)
              if (data.data == true) {
                axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=2', {
                  pageSize: this.state.pageSize,
                  pageIndex: 1
                }).then(data => {
                  //console.log('考核版本记录', conversion(data))
                  this.setState({
                    data1: conversion(data),
                    pageIndex1: 1,
                    visible: false
                  }, function () {
                    message.success('添加成功！')
                  })
                })
              }
            })
          } else if (this.state.addeditname == "考核详情") {
            values.Vid = this.state.verdetailid
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=8', values).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=4', {
                  Vid: this.state.verdetailid,
                  pageSize: this.state.pageSize,
                  pageIndex: this.state.pageIndex2
                }).then(data => {
                  //console.log('考核详情', conversion(data))
                  this.setState({
                    data2: conversion(data),
                    visible: false
                  }, function () {
                    message.success('添加成功!')

                  })
                })
              }
            })
          } else if (this.state.addeditname == "指标设置") {
            values.Vid = this.state.verdetailid
            values.Wid = this.state.examdetailid
            console.log('指标设置', values)
            if (values.Highlimit > values.Lowerlimit) {
              axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=12', values).then(data => {
                //console.log(data)
                if (data.data == true || data.data.indexOf("true") > -1) {
                  axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=9', {
                    Vid: this.state.verdetailid,
                    Wid: this.state.examdetailid,
                    pageSize: this.state.pageSize,
                    pageIndex: this.state.pageIndex3
                  }).then(data => {
                    //console.log('指标设置', conversion(data))
                    this.setState({
                      data3: conversion(data),
                      visible: false
                    }, function () {
                      message.success('添加成功！')
                    })
                  })
                }
              })
            } else {
              message.error('上限必须大于下限！')
            }
          } else if (this.state.addeditname == "排班记录") {
            axios.post(`/URL/Decision/Operate.aspx?flag=1`, {
              name: values.name
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/Decision/PD_Schedual.aspx', {
                  pageSize: this.state.pageSize,
                  pageIndex: this.state.pageIndex11
                }).then(data => {
                  //console.log('排班列表', conversion(data))
                  this.setState({
                    data11: conversion(data),
                    visible: false
                  }, function () {
                    message.success('添加成功！')

                  })
                })
              }
            })
          }
        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "考核详情") {
            values.id = this.state.examdetailid
            values.Type = this.state.examdetailobj.Type
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=20', values).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=4', {
                  Vid: this.state.verdetailid,
                  pageSize: this.state.pageSize,
                  pageIndex: this.state.pageIndex2
                }).then(data => {
                  //console.log('考核详情', conversion(data))
                  this.setState({
                    data2: conversion(data),
                    visible: false
                  }, function () {
                    message.success('修改成功！')

                  })
                })
              }
            })
          } else if (this.state.addeditname == "指标设置") {
            values.id = this.state.indexdetailid
            console.log('指标设置', values)
            if (values.Highlimit > values.Lowerlimit) {
              axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=22', values).then(data => {
                //console.log(data)
                if (data.data == true || data.data.indexOf("true") > -1) {
                  axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=9', {
                    Vid: this.state.verdetailid,
                    Wid: this.state.examdetailid,
                    pageSize: this.state.pageSize,
                    pageIndex: this.state.pageIndex3
                  }).then(data => {
                    //console.log('指标设置', conversion(data))
                    this.setState({
                      data3: conversion(data),
                      visible: false
                    }, function () {
                      message.success('修改成功！')

                    })
                  })
                }
              })
            } else {
              message.error('上限必须大于下限！')
            }
          } else if (this.state.addeditname == "排班记录") {
            var arr = []
            var len = values.cycleday

            for (let i = 0; i < len; i++) {
              var obj = { "a1": 0, "a2": 0, "a3": 0, "a4": 0, "a5": 0, 'a6': 0, 'a7': 0, 'a8': 0, 'b1': 0, "b2": 0, "b3": 0, 'b4': 0, "b5": 0, "b6": 0, "b7": 0, "b8": 0, "c1": 0, "c2": 0, "c3": 0, "c4": 0, "c5": 0, "c6": 0, "c7": 0, "c8": 0 }
              for (let key in values) {
                if (key.indexOf('-') > -1 && (key.split('-')[1] == (i + 1))) {
                  //console.log(key)
                  obj[key.split('-')[0]] = 1
                }
              }
              //console.log(obj)
              arr.push(obj)
            }
            axios.post(`/URL/Decision/Operate.aspx?flag=2`, {
              id: this.state.classdetailid,
              name: this.state.classdetailobj.name,
              usedate: values.usedate,
              cycleday: values.cycleday,
              oldcycleday: this.state.classdetailobj.cycleday,
              teamnum: values.teamnum,
              dayshift: values.dayshift,
              atime: values.atime ? values.atime.split(" ")[1] : "",
              btime: values.btime ? values.btime.split(" ")[1] : "",
              ctime: values.ctime ? values.ctime.split(" ")[1] : "",
              ary: JSON.stringify(arr)
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                this.setState({
                  classdetailid: '',
                  classdetailobj: {},
                  visible: false
                }, function () {
                  message.success('保存成功！')

                })
              }
            })
          }
        }
      }

    });
  };
  //删除
  handleDel(key) {
    var _this = this
    if (key == "考核详情") {
      if (this.state.examdetailid) {
        var key = this.state.examdetailid
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=21', {
              id: _this.state.examdetailid
            }).then(data => {
              ////console.log(data)
              if (data.data.indexOf('true')) {
                const dataSource = [..._this.state.data2];
                _this.setState({ data2: dataSource.filter(item => item.id !== key), examdetailid: "", examdetailobj: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择！')
      }
    } else if (key == "指标设置") {
      if (this.state.indexdetailid) {
        var key = this.state.indexdetailid
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=23', {
              id: _this.state.indexdetailid
            }).then(data => {
              ////console.log(data)
              if (data.data.indexOf('true')) {
                const dataSource = [..._this.state.data3];
                _this.setState({ data3: dataSource.filter(item => item.id !== key), indexdetailid: "", indexdetailobj: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择！')
      }
    } else if (key == "排班记录") {
      if (this.state.classdetailid) {
        var key = this.state.classdetailid
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post(`/URL/Decision/Operate.aspx?flag=4&id=${key}`).then(data => {
              ////console.log(data)
              if (data.data.indexOf('true') || data.data == true) {
                const dataSource = [..._this.state.data11];
                _this.setState({ data11: dataSource.filter(item => item.id !== key), classdetailid: "", classdetailobj: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择！')
      }
    }
  }
  onClose = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      classdetailid: '',
      classdetailobj: {},
      classesselect: 0,
      iptobj: {},
      classgroupselectdata: [],
      classesselectdata: [],
      turnclassselectdata: [],
    })
  };

  //考核版本启用
  handletoUse(key) {
    if (key == "考核版本") {
      if(this.state.verdetailid){
        axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=3', {
          id: this.state.verdetailid
        }).then(data => {
          //console.log(data)
          if ( (typeof data.data == "string" &&  data.data.indexOf("true") > -1) || data.data == true) {
            axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=2', {
              pageSize: this.state.pageSize,
              pageIndex: this.state.pageIndex1
            }).then(data => {
              //console.log('考核版本记录', conversion(data))
              var str = data.data
              this.setState({
                data1: conversion(data),
                data1length: str.substring(12, str.indexOf(','))
              })
            })
          }
        })
      }else{
        message.error('请选择！')
      }
    } else if (key == "排班记录") {
      if(this.state.classdetailid){
        axios.post('/URL/Decision/Operate.aspx?flag=3', {
          id: this.state.classdetailid,
          usedate: this.state.classdetailobj.usedate
        }).then(data => {
          //console.log(data)
          if ( (typeof data.data == "string" &&  data.data.indexOf("true") > -1) || data.data == true) {
            var arr = this.state.data11
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].id == this.state.classdetailid) {
                arr[i].state = "1"
              }
            }
            this.setState({
              data11: arr
            })
          }
        })
      }else{
        message.error('请选择！')
      }
    }
  }

  //排班记录查询
  classmanagedetail() {
    axios.post('/DATA/Decision/PD_Schedual_Dateshift.aspx?flag=1', {
      valuesid: this.state.valuesid,
      StartDate: this.state.startValue ? this.state.startValue : "",
      EndDate: this.state.endValue ? this.state.endValue : "",
      pageSize: this.state.pageSize,
      pageIndex: 1
    }).then(data => {
      //console.log('排班记录查询', conversion(data))
      this.setState({
        data12: conversion(data),
        pageIndex12: 1
      })
    })
  }
  senddata11(record) {
    console.log(record)

    this.setState({
      classdetailid: record.id,
      classdetailobj: record,
      classesselect: parseInt(record.dayshift)
    }, function () {
      //轮班天数
      var newarr1 = []
      var cycleday = parseInt(this.state.classdetailobj.cycleday)
      for (let i = 0; i < cycleday; i++) {
        newarr1.push(i + 1)
      }
      this.setState({
        turnclassselect: cycleday,
        turnclassselectdata: newarr1
      })

      //班组
      var newarr2 = []
      var teamnum = parseInt(this.state.classdetailobj.teamnum)
      for (let i = 0; i < teamnum; i++) {
        var str = `班组${i + 1}`
        newarr2.push(str)
      }
      this.setState({ classgroupselect: teamnum, classgroupselectdata: newarr2 })

      //每天班次
      var dayshift = this.state.classdetailobj.dayshift
      if (dayshift == "1") {
        this.setState({ classesselect: 1, classesselectdata: ["早班"] })
      } else if (dayshift == "2") {
        this.setState({ classesselect: 2, classesselectdata: ["早班", "晚班"] })
      } else if (dayshift == "3") {
        this.setState({ classesselect: 3, classesselectdata: ["早班", "中班", "晚班"] })
      }

      axios.post('/DATA/Decision/PD_Schedual_Team.aspx', {
        scheid: this.state.classdetailid,
        teamnum: this.state.classdetailobj.teamnum,
        cycleday: this.state.classdetailobj.cycleday,
        dayshift: this.state.classdetailobj.dayshift,
        pageSize: this.state.pageSize,
        pageIndex: this.state.pageIndex12
      }).then(data => {
        var arr = conversion(data)
        var newarr = []
        for (let i = 0; i < arr.length; i++) {
          var obj = {}
          for (var key in arr[i]) {
            if (arr[i][key] == "1") {
              obj[key.substring(0, 1)] = key.substring(1)
            }
          }
          newarr.push(obj)
        }
        console.log(newarr)
        this.setState({
          classgroupsdetaildata: newarr
        })
      })
    })
  }
  setRowClassName11 = (record) => {
    return record.id === this.state.classdetailid ? 'clickRowStyl' : 'clickRowSty';
  }

  //考核设置分页

  pagechange(key, page) {
    //console.log(page)
    if (key == "考核版本") {
      this.setState({
        pageIndex1: page.current,
      }, function () {
        axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=2', {
          pageSize: this.state.pageSize,
          pageIndex: this.state.pageIndex1
        }).then(data => {
          //console.log('考核版本记录', conversion(data))
          this.setState({
            data1: conversion(data)
          })
        })
      })
    } else if (key == "考核详情") {
      this.setState({
        pageIndex2: page.current,
      }, function () {
        axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=4', {
          Vid: this.state.verdetailid,
          pageSize: this.state.pageSize,
          pageIndex: this.state.pageIndex2
        }).then(data => {
          //console.log('考核详情', conversion(data))
          this.setState({
            data2: conversion(data)
          })
        })
      })
    } else if (key == "指标设置") {
      this.setState({
        pageIndex3: page.current,
      }, function () {
        axios.post('/DATA/IntelligentDecision/PerformanceAppraisal/AssessmentSet.aspx?flag=9', {
          Vid: this.state.verdetailid,
          Wid: this.state.examdetailid,
          pageSize: this.state.pageSize,
          pageIndex: this.state.pageIndex3
        }).then(data => {
          //console.log('指标设置', conversion(data))
          this.setState({
            data3: conversion(data)
          })
        })
      })
    } else if (key == "排班列表") {
      this.setState({
        pageIndex11: page.current,
      }, function () {
        axios.post('/DATA/Decision/PD_Schedual.aspx', {
          pageSize: this.state.pageSize,
          pageIndex: this.state.pageIndex11
        }).then(data => {
          //console.log('排班列表', conversion(data))
          this.setState({
            data11: conversion(data)
          })
        })
      })
    } else if (key == "排班记录") {
      this.setState({
        pageIndex12: page.current,
      }, function () {
        axios.post('/DATA/Decision/PD_Schedual_Dateshift.aspx?flag=1', {
          valuesid: this.state.valuesid,
          StartDate: this.state.startValue,
          EndDate: this.state.endValue,
          pageSize: this.state.pageSize,
          pageIndex: this.state.pageIndex12
        }).then(data => {
          //console.log('排班记录查询', conversion(data))
          this.setState({
            data12: conversion(data)
          })
        })
      })
    }
  }

  //页签
  tabschange = (key) => {
    this.setState({ activeKey: key }, function () {
      var id = key == "1" ? 131 : 132
      this.getButtonLimitarr(id)

      if(key=="2"){
        axios.post('/DATA/Decision/PD_Schedual.aspx', {
          pageSize: this.state.pageSize,
          pageIndex: this.state.pageIndex11
        }).then(data => {
          //console.log('排班列表', conversion(data))
          var str = data.data
          this.setState({
            data11: conversion(data),
            data11length: str.substring(12, str.indexOf(','))
          })
        })
      }
    })
  }


  //起始 终止

  //起始终止
  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChange = value => {
    this.onChange('startValue', value);
  };

  onEndChange = value => {
    this.onChange('endValue', value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };
  render() {
    const { startValue, endValue, endOpen } = this.state;

    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return (
      <section className="performanceassessment" style={{ height: clientHeight - 50 + 'px' }}>
        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>

        <Tabs type="card" onChange={this.tabschange.bind(this)} activeKey={this.state.activeKey}>
          <TabPane tab="考核设置" key="1">
            <div className="examconfig" style={{ height: clientHeight - 50 - 36 + 'px' }}>
              <div className="examconfigleft">
                <h2 className="examtitle">考核版本记录</h2>
                <span className="examaction">
                  <Button style={{ display: this.state.buttonarr.indexOf('添加') > -1 ? "inline-block" : "none" }} onClick={this.handleAdd.bind(this, '考核版本')}>添加</Button>
                  {
                    this.state.verdetailobj.State == "0" ?
                      <Button style={{ display: this.state.buttonarr.indexOf('提交') > -1 ? "inline-block" : "none" }} onClick={this.handletoUse.bind(this, '考核版本')}>提交</Button> :
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('提交') > -1 ? "inline-block" : "none" }}>提交</Button>
                  }
                </span>
                <div className="listpage" style={{ height: clientHeight - 50 - 70 - 50 }}>
                  <Table
                    size="small"
                    dataSource={this.state.data1}
                    columns={this.data1columns}
                    // pagination={false}
                    pagination={{
                      pageSize: Number(this.state.pageSize),
                      current: this.state.pageIndex1,
                      total: Number(this.state.data1length)
                    }}
                    onChange={this.pagechange.bind(this, '考核版本')}
                    onRow={(record) => {
                      return {
                        onClick: this.senddata1.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassName1}
                  // scroll={{ y: clientHeight - 50 - 70 - 30 - 60 - 2 }}
                  />
                </div>
              </div>
              <div className="examconfigcenter">
                <h2 className="examtitle">考核详情</h2>
                {
                  this.state.verdetailobj.State && this.state.verdetailobj.State == "0" ?
                    <span className="examaction">
                      <Button style={{ display: this.state.buttonarr.indexOf('添加-考核详情') > -1 ? "inline-block" : "none" }} onClick={this.handleAdd.bind(this, '考核详情')}>添加</Button>
                      <Button style={{ display: this.state.buttonarr.indexOf('编辑-考核详情') > -1 ? "inline-block" : "none" }} onClick={this.handleEdit.bind(this, '考核详情')}>编辑</Button>
                      <Button style={{ display: this.state.buttonarr.indexOf('删除-考核详情') > -1 ? "inline-block" : "none" }} onClick={this.handleDel.bind(this, '考核详情')}>删除</Button>
                    </span> :
                    <span className="examaction">
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('添加-考核详情') > -1 ? "inline-block" : "none" }}>添加</Button>
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('编辑-考核详情') > -1 ? "inline-block" : "none" }}>编辑</Button>
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('删除-考核详情') > -1 ? "inline-block" : "none" }}>删除</Button>
                    </span>
                }
                <div className="listpage" style={{ height: clientHeight - 50 - 70 - 50 }}>
                  <Table
                    size="small"
                    dataSource={this.state.data2}
                    columns={this.data2columns}
                    // pagination={false}
                    pagination={{
                      pageSize: this.state.pageSize,
                      current: this.state.pageIndex2,
                      total: Number(this.state.data2length)
                    }}
                    onChange={this.pagechange.bind(this, '考核详情')}
                    onRow={(record) => {
                      return {
                        onClick: this.senddata2.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassName2}
                  // scroll={{ y: clientHeight - 50 - 70 - 30 - 60 - 2 }}
                  />
                </div>

              </div>
              <div className="examconfigright">
                <h2 className="examtitle">指标设置</h2>
                {
                  this.state.verdetailobj.State && this.state.verdetailobj.State == "0" ?
                    <span className="examaction">
                      <Button style={{ display: this.state.buttonarr.indexOf('添加-指标设置') > -1 ? "inline-block" : "none" }} onClick={this.handleAdd.bind(this, '指标设置')}>添加</Button>
                      <Button style={{ display: this.state.buttonarr.indexOf('编辑-指标设置') > -1 ? "inline-block" : "none" }} onClick={this.handleEdit.bind(this, '指标设置')}>编辑</Button>
                      <Button style={{ display: this.state.buttonarr.indexOf('删除-指标设置') > -1 ? "inline-block" : "none" }} onClick={this.handleDel.bind(this, '指标设置')}>删除</Button>
                    </span> :
                    <span className="examaction">
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('添加-指标设置') > -1 ? "inline-block" : "none" }}>添加</Button>
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('编辑-指标设置') > -1 ? "inline-block" : "none" }}>编辑</Button>
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('删除-指标设置') > -1 ? "inline-block" : "none" }}>删除</Button>
                    </span>
                }
                <div className="listpage" style={{ height: clientHeight - 50 - 70 - 50 }}>
                  <Table
                    size="small"
                    dataSource={this.state.data3}
                    columns={this.data3columns}
                    pagination={false}
                    pagination={{
                      pageSize: this.state.pageSize,
                      current: this.state.pageIndex3,
                      total: Number(this.state.data3length)
                    }}
                    onChange={this.pagechange.bind(this, '指标设置')}
                    onRow={(record) => {
                      return {
                        onClick: this.senddata3.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassName3}
                  // scroll={{ y: clientHeight - 50 - 70 - 30 - 60 - 2 }}
                  />
                </div>

              </div>
            </div>
          </TabPane>
          <TabPane tab="排班设置" key="2">
            <div className="classmanage" style={{ height: clientHeight - 50 - 36 + 'px' }}>
              <div className="classmanageleft">
                <h2 className="examtitle">排班记录</h2>
                {
                  this.state.classdetailobj ?
                    // && this.state.classdetailobj.state == "0"
                    <div className="examaction">
                      <Button style={{ display: this.state.buttonarr.indexOf('添加') > -1 ? "inline-block" : "none" }} onClick={this.handleAdd.bind(this, '排班记录')}>添加</Button>
                      <Button style={{ display: this.state.buttonarr.indexOf('提交') > -1 ? "inline-block" : "none" }} onClick={this.handletoUse.bind(this, '排班记录')}>提交</Button>
                      <Button style={{ display: this.state.buttonarr.indexOf('编辑') > -1 ? "inline-block" : "none" }} onClick={this.handleEdit.bind(this, '排班记录')}>编辑</Button>
                      <Button style={{ display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }} onClick={this.handleDel.bind(this, '排班记录')}>删除</Button>
                    </div>
                    :
                    <div className="examaction">
                      <Button style={{ display: this.state.buttonarr.indexOf('添加') > -1 ? "inline-block" : "none" }} onClick={this.handleAdd.bind(this, '排班记录')}>添加</Button>
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('提交') > -1 ? "inline-block" : "none" }}>提交</Button>
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('编辑') > -1 ? "inline-block" : "none" }}>编辑</Button>
                      <Button style={{ background: "#ccc", display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }}>删除</Button>
                    </div>
                }

                <div className="listpage" style={{ height: clientHeight - 50 - 70 - 50 }}>
                  <Table
                    size="small"
                    dataSource={this.state.data11}
                    columns={this.data11columns}
                    // pagination={false}
                    pagination={{
                      pageSize: this.state.pageSize,
                      current: this.state.pageIndex11,
                      total: Number(this.state.data11length)
                    }}
                    onChange={this.pagechange.bind(this, '排班列表')}
                    onRow={(record) => {
                      return {
                        onClick: this.senddata11.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassName11}
                  // scroll={{ y: clientHeight - 50 - 70 - 30 - 60 - 24 }}
                  />
                </div>
              </div>
              <div className="classmanageright">
                <h2 className="examtitle">排班详情</h2>
                <span className="classgroupdetail">
                  <span>起始日期
                  <DatePicker
                      locale={locale}
                      disabledDate={this.disabledStartDate}
                      format="YYYY-MM-DD"
                      value={startValue}
                      placeholder="请选择"
                      onChange={this.onStartChange}
                      onOpenChange={this.handleStartOpenChange}
                    /></span>
                  <span>终止日期
                          <DatePicker
                      locale={locale}
                      disabledDate={this.disabledEndDate}
                      format="YYYY-MM-DD"
                      value={endValue}
                      placeholder="请选择"
                      onChange={this.onEndChange}
                      open={endOpen}
                      onOpenChange={this.handleEndOpenChange}
                    /></span>
                  <span>
                    <Select style={{ width: "140px" }} value={this.state.valuesid} onChange={(v) => { this.setState({ valuesid: v, }) }}>
                      <Option value={0} key={0}>全部</Option>
                      <Option value={1} key={1}>1</Option>
                      <Option value={2} key={2}>2</Option>
                      <Option value={3} key={3}>3</Option>
                    </Select></span>
                  <Button onClick={this.classmanagedetail.bind(this, '排班记录')}>搜索</Button>
                </span>
                <div className="listpage" style={{ height: clientHeight - 50 - 70 - 50 }}>
                  <Table
                    size="small"
                    dataSource={this.state.data12}
                    columns={this.data12columns}
                    // pagination={false}
                    pagination={{
                      pageSize: this.state.pageSize,
                      current: this.state.pageIndex12,
                      total: Number(this.state.data12length)
                    }}
                    onChange={this.pagechange.bind(this, '排班记录')}
                  // scroll={{ y: clientHeight - 50 - 70 - 30 - 60 - 24 }}
                  />
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>

        <Drawer
          title={this.state.addeditname + '' + this.state.addedit}
          width={720}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            {
              this.state.addeditname == "考核版本" ?
                <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  {/* <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="版本号">
            {getFieldDecorator('StartDate1')(
              <Input value="自动编号" />
            )}
          </Form.Item>
        </Col>
      </Row> */}
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="复制旧版本号">
                        {getFieldDecorator('Copy_VersionNumber', {
                          rules: [{ required: false, message: '请选择复制旧版本号' }],
                        })(
                          <Select>
                            {
                              this.state.data1.map((item, index) => {
                                return (
                                  <Option value={item.VersionNumber} key={index}>{item.VersionNumber}</Option>)
                              })
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="textipt">
                    <Col span={12}>
                      <Form.Item label="备注">
                        {getFieldDecorator('Remark', {
                          rules: [{ required: true, message: '请输入备注' }],
                        })(
                          <TextArea rows={4} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
                : this.state.addeditname == "考核详情" ?
                  <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="指标名称">
                          {getFieldDecorator('IndexName', {
                            initialValue: this.state.iptobj['IndexName'],
                            rules: [{ required: true, message: '请输入指标名称' }],
                          })(
                            // onClick={()=>{$('#IndexName').focus()}}
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="权重%">
                          {getFieldDecorator('Weights', {
                            initialValue: this.state.iptobj['Weights'],
                            rules: [{ required: true, message: '请输入权重' }],
                          })(
                            <InputNumber />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="测点号">
                          {getFieldDecorator('Testid', {
                            initialValue: this.state.iptobj['Testid'],
                            rules: [{ required: false, message: '请选择测点号' }],
                          })(
                            <Select>
                              {
                                this.state.Cedianinitdata.map((item, index) => {
                                  return (
                                    <Option value={item.Number} key={item.Number}>{item.Number}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="数据来源">
                          {getFieldDecorator('Sources', {
                            initialValue: this.state.iptobj['Sources'],
                            rules: [{ required: false, message: '请选择单位' }],
                          })(
                            <Select>
                              <Option value="1" key="1">数据导入</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="单位">
                          {getFieldDecorator('Unit', {
                            initialValue: this.state.iptobj['Unit'],
                            rules: [{ required: false, message: '请选择单位' }],
                          })(
                            <Select>
                              <Option value="℃" key={0}>℃</Option>
                              <Option value="Mpa" key={1}>Mpa</Option>
                              <Option value="Pa" key={1}>Pa</Option>
                              <Option value="mm" key={1}>mm</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
          </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                  : this.state.addeditname == "指标设置" && this.state.addedit == "添加" ?
                    <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="上限">
                            {getFieldDecorator('Highlimit', {
                              initialValue: this.state.iptobj['Highlimit'],
                              rules: [{ required: true, message: '请输入' }],
                            })(
                              <InputNumber />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="下限">
                            {getFieldDecorator('Lowerlimit', {
                              initialValue: this.state.iptobj['Lowerlimit'],
                              rules: [{ required: true, message: '请输入' }],
                            })(
                              <InputNumber />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="区间段个数">
                            {getFieldDecorator('counts', {
                              initialValue: this.state.iptobj['counts'],
                              rules: [{ required: true, message: '请输入' }],
                            })(
                              <InputNumber />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16} className="formbutton">
                        <Form.Item>
                          <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                            保存
                          </Button>
                        </Form.Item>
                      </Row>
                    </Form>
                    : this.state.addeditname == "指标设置" && this.state.addedit == "编辑" ?
                      <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                      >
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="上限">
                              {getFieldDecorator('Highlimit', {
                                initialValue: this.state.iptobj['Highlimit'],
                                rules: [{ required: true, message: '请输入' }],
                              })(
                                <InputNumber />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="下限">
                              {getFieldDecorator('Lowerlimit', {
                                initialValue: this.state.iptobj['Lowerlimit'],
                                rules: [{ required: true, message: '请输入' }],
                              })(
                                <InputNumber />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="时长(5s)">
                              {getFieldDecorator('Duration', {
                                initialValue: this.state.iptobj['Duration'],
                                rules: [{ required: true, message: '请输入' }],
                              })(
                                <InputNumber />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="得分">
                              {getFieldDecorator('Score', {
                                initialValue: this.state.iptobj['Score'],
                                rules: [{ required: true, message: '请输入' }],
                              })(
                                <InputNumber />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16} className="formbutton">
                          <Form.Item>
                            <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                              保存
                            </Button>
                          </Form.Item>
                        </Row>
                      </Form>
                      : this.state.addeditname == "排班记录" && this.state.addedit == "编辑" ?
                        <Form className="form1 assignclassrecord" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                        // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                        >
                          <Row gutter={16}>
                            <Col span={12}>
                              <Form.Item label="排班名称">
                                {getFieldDecorator('name', {
                                  initialValue: this.state.iptobj['name'],
                                  rules: [{ required: true, message: '请输入排班名称' }],
                                })(
                                  <Input />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <Form.Item label="启用时间">
                                {getFieldDecorator('usedate', {
                                  initialValue: this.state.iptobj['usedate'] ? moment(this.state.iptobj['usedate']) : "",
                                  rules: [{ required: true, message: '请选择启用时间' }],
                                })(
                                  <DatePicker
                                    locale={locale}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    placeholder="请选择"
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <Form.Item label="轮班天数">
                                {getFieldDecorator('cycleday', {
                                  initialValue: this.state.iptobj['cycleday'],
                                  rules: [{ required: true, message: '请选择轮班天数' }],
                                })(
                                  <Select onChange={(v) => {
                                    var arr = []
                                    for (let i = 0; i < v; i++) {
                                      arr.push(i + 1)
                                    }
                                    this.setState({
                                      turnclassselect: v,
                                      turnclassselectdata: arr
                                    })
                                  }}>
                                    {
                                      this.state.turnclass.map((item, index) => {
                                        return (
                                          <Option value={item} key={item}>{item}</Option>)
                                      })
                                    }
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <Form.Item label="每天班次">
                                {getFieldDecorator('dayshift', {
                                  initialValue: this.state.iptobj['dayshift'],
                                  rules: [{ required: true, message: '请选择每天班次' }],
                                })(
                                  <Select onChange={(v) => {
                                    if (v == 1) {
                                      this.setState({ classesselect: v, classesselectdata: ["早班"] })
                                    } else if (v == 2) {
                                      this.setState({ classesselect: v, classesselectdata: ["早班", "晚班"] })
                                    } else if (v == 3) {
                                      this.setState({ classesselect: v, classesselectdata: ["早班", "中班", "晚班"] })
                                    }
                                  }}>
                                    <Option value={1} key={1}>1</Option>
                                    <Option value={2} key={2}>2</Option>
                                    <Option value={3} key={3}>3</Option>
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <Form.Item label="班组数量">
                                {getFieldDecorator('teamnum', {
                                  initialValue: this.state.iptobj['teamnum'],
                                  rules: [{ required: true, message: '请选择班组数量' }],
                                })(
                                  <Select onChange={(v) => {
                                    var arr = []
                                    for (let i = 0; i < v; i++) {
                                      var str = `班组${i + 1}`
                                      arr.push(str)
                                    }
                                    this.setState({ classgroupselect: v, classgroupselectdata: arr })
                                  }}>
                                    {/* <Option value={1} key={1}>1</Option> */}
                                    <Option value={2} key={2}>2</Option>
                                    <Option value={3} key={3}>3</Option>
                                    <Option value={4} key={4}>4</Option>
                                    <Option value={5} key={5}>5</Option>
                                    <Option value={6} key={6}>6</Option>
                                    <Option value={7} key={7}>7</Option>
                                    <Option value={8} key={8}>8</Option>
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                          {
                            this.state.classesselect == 3 || this.state.classesselect == 2 || this.state.classesselect == 1 ?
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item label="早班">
                                    {getFieldDecorator('atime', {
                                      initialValue: this.state.iptobj['atime'] ? moment(this.state.iptobj['atime'], " 'HH:mm:ss'") : "",
                                      rules: [{ required: true, message: '请选择早班' }],
                                    })(
                                      <TimePicker
                                        locale={locale} defaultValue={moment('12:08:23', 'HH:mm:ss')} placeholder="请选择" />
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                              : ""
                          }

                          {
                            this.state.classesselect == 3 ?
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item label="中班">
                                    {getFieldDecorator('btime', {
                                      initialValue: this.state.iptobj['btime'] ? moment(this.state.iptobj['btime'], " 'HH:mm:ss'") : "",
                                      rules: [{ required: true, message: '请选择中班' }],
                                    })(
                                      <TimePicker
                                        locale={locale} defaultValue={moment('12:08:23', 'HH:mm:ss')} placeholder="请选择" />
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row> : ""
                          }
                          {
                            this.state.classesselect == 3 || this.state.classesselect == 2 ?
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item label="晚班">
                                    {getFieldDecorator('ctime', {
                                      initialValue: this.state.iptobj['ctime'] ? moment(this.state.iptobj['ctime'], " 'HH:mm:ss'") : "",
                                      rules: [{ required: true, message: '请选择晚班' }],
                                    })(
                                      <TimePicker
                                        locale={locale} defaultValue={moment('12:08:23', 'HH:mm:ss')} placeholder="请选择" />
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row> : ""
                          }

                          <ul className="classconfig" style={{ height: clientHeight - 50 - 55 - 60 - 36 * 8 + 'px', overflow: "auto" }}>
                            {
                              this.state.turnclassselectdata.map((item, index) => {
                                return (
                                  <li>
                                    <div className="dayselectnum">{'第' + item + '天'}</div>
                                    {
                                      this.state.classesselectdata.map((itm, inde) => {
                                        return (
                                          <Row gutter={16}>
                                            <Col span={14}>
                                              <Form.Item label={itm}>
                                                {
                                                  itm == "早班" ?
                                                    getFieldDecorator(`a${inde + 1}-${item}`, {
                                                      initialValue: this.state.classgroupsdetaildata[index] ? parseInt(this.state.classgroupsdetaildata[index][`a`]) : "",
                                                      rules: [{ required: false, message: `请选择${itm}的班组` }],
                                                    })(
                                                      <Radio.Group>
                                                        {
                                                          this.state.classgroupselectdata.map((it, ind) => {
                                                            return (
                                                              <Radio value={ind + 1}>{it}</Radio>)
                                                          })
                                                        }
                                                      </Radio.Group>
                                                    ) : itm == "晚班" ?
                                                      getFieldDecorator(`c${inde + 1}-${item}`, {
                                                        initialValue: this.state.classgroupsdetaildata[index] ? parseInt(this.state.classgroupsdetaildata[index][`c`]) : "",
                                                        rules: [{ required: false, message: `请选择${itm}的班组` }],
                                                      })(
                                                        <Radio.Group>
                                                          {
                                                            this.state.classgroupselectdata.map((it, ind) => {
                                                              return (
                                                                <Radio value={ind + 1}>{it}</Radio>)
                                                            })
                                                          }
                                                        </Radio.Group>
                                                      ) : itm == "中班" ?
                                                        getFieldDecorator(`b${inde + 1}-${item}`, {
                                                          initialValue: this.state.classgroupsdetaildata[index] ? parseInt(this.state.classgroupsdetaildata[index][`b`]) : "",
                                                          rules: [{ required: false, message: `请选择${itm}的班组` }],
                                                        })(
                                                          <Radio.Group>
                                                            {
                                                              this.state.classgroupselectdata.map((it, ind) => {
                                                                return (
                                                                  <Radio value={ind + 1}>{it}</Radio>)
                                                              })
                                                            }
                                                          </Radio.Group>
                                                        ) : ""
                                                }
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        )
                                      })
                                    }
                                  </li>
                                )
                              })
                            }
                          </ul>
                          <Row gutter={16} className="formbutton">
                            <Form.Item>
                              <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                                保存
                </Button>
                            </Form.Item>
                          </Row>
                        </Form>
                        : this.state.addeditname == "排班记录" && this.state.addedit == "添加" ?
                          <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                          // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                          >
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item label="排班名称">
                                  {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '请输入排班名称' }],
                                  })(
                                    <Input onClick={() => { $('#name').focus() }} />
                                  )}
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16} className="formbutton">
                              <Form.Item>
                                <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                                  保存
                </Button>
                              </Form.Item>
                            </Row>
                          </Form>
                          : ""
            }

          </div>
        </Drawer>
      </section>
    )
  }
}
const PerformanceAssessmentapp = Form.create()(PerformanceAssessment);
export default PerformanceAssessmentapp;

