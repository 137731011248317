import React, { Component } from 'react';
import { Table, Button,Input,Drawer,Form, Row, Col,Modal ,message } from 'antd';
import store from '../../store';
import axios from 'axios'
import { conversion } from '../../converse'
import { toProTreeData1 } from '../../utils/toprojecttreedata'
import {testfirst} from '../../utils/delchildren'
import { getButtonLimit } from '../../utils/getbuttonlimit'

const confirm = Modal.confirm
class ProjectTree extends Component {
  constructor() {
    super();
    this.state = {
      buttonarr:[],

      attrA: {
        ID: 'ID',
        ParentId: 'ParentId',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        RecordVersion:'RecordVersion',
        UpdateTime:'UpdateTime',
        UpdatePeople:'UpdatePeople',
        CreatePeople:'CreatePeople',
        CreateTime:'CreateTime',
        Flag:'Flag'
      },
      columns : [
        {
          title: '编码',
          dataIndex: 'Code',
          key: 'Code',
          width:200,
        },
        {
          title: '名称',
          dataIndex: 'Name',
          key: 'Name',
          width:200,
        },
        {
          title: '创建人',
          dataIndex: 'CreatePeople',
          key: 'CreatePeople',
          width:140,
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          key: 'CreateTime',
          width:150,
        },
        {
          title: '修改人',
          dataIndex: 'UpdatePeople',
          key: 'UpdatePeople',
          width:140,
        },
        {
          title: '修改时间',
          dataIndex: 'UpdateTime',
          key: 'UpdateTime',
          width:150,
        },
        {
          title: '是否关联模型',
          dataIndex: 'Flag',
          key: 'Flag',
          width:140,
          render:(text)=>{
          return (<span>{text=="1"?"是":""}</span>)
          }
        },
      ],
      data : [],
      rowId:"",
      rowIdobj:{},
      addedit: '',
      visibledraw: false,
      formiptobj: {}
    }
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(35)
  
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res=>{
      //console.log(conversion(res))
      let attr = this.state.attrA;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('专业树数据',arr)
      this.setState({
        data: testfirst(arr)
      })
    })
  }

  senddata(record) {
    //console.log(record)
    if(this.state.rowId && this.state.rowId==record.ID){
      this.setState({
        rowId: "",
        rowIdobj:{}
      })
    }else{
      this.setState({
        rowId: record.ID,
        rowIdobj:record
      })
    }
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }
  handleAdd() {
    this.setState({
      addedit: '添加',
      visibledraw: true,
      formiptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  handleEdit() {
    var obj = this.state.rowIdobj
    if (this.state.rowId) {
      this.setState({
        addedit: '编辑',
        formiptobj:obj,
        visibledraw: true
      }, function () {
        this.props.form.resetFields();
      })
    } else {
      message.error('请选择！')
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      //console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate() + ' ' + value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else {
            values[i] = values[i]
          }
        }
        if (this.state.addedit == "添加") {
          values.ParentId = this.state.rowId ? this.state.rowId : "0"
          values.Rank = this.state.rowIdobj.Rank ? Number(this.state.rowIdobj.Rank) + 1 : "1"
          values.Leaf = this.state.rowIdobj.Leaf == "true" ? "1" : "0"
          axios.post('/DATA/ParaSet/ParaSet.aspx?flag=1', values).then(data => {
            //console.log(data)
            if (data.data == true || data.data.indexOf('true') > -1) {
              axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res=>{
                //console.log(conversion(res))
                var resarr = conversion(res)
                var addobj = {}
                for(let i=0;i<resarr.length;i++){
                  if(resarr[i].Code==values.Code){
                    addobj = resarr[i]
                  }
                }
                let attr = this.state.attrA;
                let arr = toProTreeData1(conversion(res), attr)
                //console.log('专业树数据',arr)
                this.setState({
                  data: testfirst(arr),
                  visibledraw:false,
                  rowId:addobj.ID,
                  rowIdobj:addobj
                })
              })
            }else if(typeof data.data=="string" &&data.data.indexOf("err")>-1){
              message.error('此编码已存在!')
            }else{
              message.error(data.data)
            }
          })
        } else if (this.state.addedit == "编辑") {
          values.ID = this.state.rowId
          values.RecordVersion = this.state.rowIdobj.RecordVersion
          axios.post('/DATA/ParaSet/ParaSet.aspx?flag=2', values).then(data => {
            //console.log(data)
            if (data.data == true) {
              axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res=>{
                //console.log(conversion(res))
                var resarr = conversion(res)
                var rowobj = {}
                for(let i=0;i<resarr.length;i++){
                  if(resarr[i].ID==this.state.rowId){
                    rowobj = resarr[i]
                  }
                }
                let attr = this.state.attrA;
                let arr = toProTreeData1(conversion(res), attr)
                //console.log('专业树数据',arr)
                this.setState({
                  data: testfirst(arr),
                  visibledraw:false,
                  rowIdobj:rowobj
                })
              })
            }else if(typeof data.data=="string" && data.data.indexOf("数据版本已发生变化")>-1){
              message.error('数据版本已发生变化，请刷新后重试!')
            }else if(typeof data.data=="string" && data.data.indexOf("err")>-1){
              message.error('此编码已存在!')
            }else{
              message.error(data.data)
            }
          })
        }
      }
    });
  };
  onClose() {
    this.setState({
      visibledraw: false,
      formiptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  handleDel() {
    var _this = this
    if(this.state.rowId){
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/ParaSet/ParaSet.aspx?flag=3', {
            ID: _this.state.rowId
          }).then(data => {
            //console.log(data)
            if (data.data == true || data.data.indexOf('true') > -1) {
              axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res=>{
                //console.log(conversion(res))
                let attr = _this.state.attrA;
                let arr = toProTreeData1(conversion(res), attr)
                //console.log('专业树数据',arr)
                _this.setState({
                  data: testfirst(arr),
                  rowId:"",
                  rowIdobj:{}
                },function(){
                  message.success('删除成功！')
                })
              })
            }else if(data.data.indexOf('不允许删除')>-1){
              message.error(data.data)
            }
          })
        },
        onCancel() {
          _this.setState({
          })
        },
      });
    }
  }

  handleUp(){

  }
  handleDown(){

  }
  render() {
    const { getFieldDecorator } = this.props.form;
  
    const clientHeight = store.getState().clientHeight
    return (
      <div className="projecttree">
        <Drawer
          title={'专业树'+this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visibledraw}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{height:clientHeight-50-55+'px'}}>
            <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="专业编码">
                    {getFieldDecorator('Code', {
                      initialValue: this.state.formiptobj["Code"],
                      rules: [{ required: true, message: '请输入专业编码' }],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="专业名称">
                    {getFieldDecorator('Name', {
                      initialValue: this.state.formiptobj["Name"],
                      rules: [{ required: true, message: '请输入专业名称' }],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="formbutton">
                <Form.Item>
                  <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                    保存
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </Drawer>

        <h2 className="projecttreetitle">专业列表
         <div className="actionbutton">
           <Button style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this)}>添加</Button>
           <Button style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}}  onClick={this.handleEdit.bind(this)}>编辑</Button>
           <Button style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}}  onClick={this.handleDel.bind(this)}>删除</Button>
           {/* <Button onClick={this.handleUp.bind(this)}>上移</Button>
           <Button onClick={this.handleDown.bind(this)}>下移</Button> */}
         </div>
        </h2>
        <div className="configmanagementlist">
          <Table 
            rowKey={record => record.ID}
            columns={this.state.columns} 
            pagination={false} 
            dataSource={this.state.data}
            scroll={{y:clientHeight-140}}
            onRow={(record) => {
              return {
                onClick: this.senddata.bind(this, record)
              }
            }}
            rowClassName={this.setRowClassName}
            />
        </div>
      </div>
    )
  }
}

const ProjectTreeapp = Form.create()(ProjectTree);
export default ProjectTreeapp;