import React from 'react'
import { Input, Table, Button, Tooltip, DatePicker } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import echarts from 'echarts';
import { conversion } from '../../../converse'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';
import store from '../../../store';
import "moment/locale/zh-cn"
import { getButtonLimit } from '../../../utils/getbuttonlimit'
const { Search } = Input;

class AccessLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr:[],

      pageSize: parseInt(((store.getState().clientHeight - 50 - 40) * 0.7 - 50) / 28),
      pageIndex: 1,
      //文档列表
      dataSource: [],
      dataSourcefu: [],
      dataSourcelength: 0,
      rowId: '',
      rowObj: {},

      endtime: '',
      starttime: '',
      username: ''
    }
    //文档
    this.columns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '5%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '登录时间',
        dataIndex: 'loginTime',
        key: 'loginTime',
        width: '15%'
      },
      {
        title: '用户ID',
        dataIndex: 'userId',
        key: 'userId',
        width: '10%',
      },
      {
        title: '用户名',
        dataIndex: 'userName',
        key: 'userName',
        width: '15%',
      },
      {
        title: '登录IP',
        dataIndex: 'extenalIpAddress',
        key: 'extenalIpAddress',
        width: '10%'
      },
      {
        title: '登录地区',
        dataIndex: 'ipprovince',
        key: 'ipprovince',
        width: '20%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>

      },
      {
        title: '机器名',
        dataIndex: 'loginCPName',
        key: 'loginCPName',
        width: '25%'
      }
    ]
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(32)

    axios.post('/DATA/ParaSet/SystemLog.aspx?flag=2', {
      starttime: '',
      endtime: '',
      username: '',
    }).then(data => {
      var str = data.data
      console.log(conversion(data))
      this.setState({
        dataSource: conversion(data),
        dataSourcefu: conversion(data),
        dataSourcelength: Number(str.substring(12, str.indexOf(','))),
      })
    })
    axios.get('/DATA/ParaSet/SystemLog.aspx?flag=1').then(res => {
      console.log('图数据', conversion(res))
      var arr = conversion(res)
      var dataAxis = []
      var data = []

      for (let i = arr.length - 1; i >= 0; i--) {
        dataAxis.push(arr[i].Date)
        data.push((parseFloat(arr[i].Value)).toFixed(2))
      }

      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '15%',
          right: '6%',
          top: '20%',
          bottom: '20%',
          scontainLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: dataAxis,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            },
          }
        ],
        yAxis: [
          {
            name: "访问量",
            type: 'value',
            nameTextStyle: {
              color: "#fff",
              fontSize: 16,
              paddingLeft: 10
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            },
          }
        ],
        series: [
          {
            name: '访问量',
            type: 'bar',
            barWidth: 24,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#2378f7' },
                    { offset: 0.5, color: '#2378f7' },
                    { offset: 1, color: '#2378f7' }
                  ]
                )
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#3cecfc' },
                    { offset: 0.7, color: '#3cecfc' },
                    { offset: 1, color: '#3cecfc' }
                  ]
                )
              }
            },
            data: data
          }
        ]
      };
      this.setState({
        option: option
      }, function () {
        //基于准备好的dom，初始化echarts实例
        var myChart1 = echarts.init(document.getElementById('linetu'));
        // 绘制图表
        myChart1.setOption(this.state.option);

      })
    })
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt(((store.getState().clientHeight- 50 - 40) * 0.7 - 50) / 28),
    })
  }

  senddata(record) {
    console.log(record)
    this.setState({
      rowId: record.id,
      rowObj: record,
    })
  }

  setRowClassName = (record) => {
    return record.id === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }

  onSearch(value) {
    console.log(value)
    var arr = this.state.dataSourcefu
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].filenewname.indexOf(value) > -1) {
        newarr.push(arr[i])
      }
    }
    this.setState({
      dataSource: newarr
    })
  }

  onlianheSearch() {
    axios.post('/DATA/ParaSet/SystemLog.aspx?flag=2', {
      starttime: this.state.starttime,
      endtime: this.state.endtime,
      username: this.state.username,
    }).then(data => {
      this.setState({
        dataSource: conversion(data)
      })
    })
  }

  resetsearchvalue() {
    var arr = this.state.dataSourcefu
    this.setState({
      username: '',
      starttime: '',
      endtime: '',
      dataSource: arr
    })
  }

  pagechange(page) {
    this.setState({
      pageIndex: page.current,
    })
  }
  render() {
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div className="logmanage" style={{ height: clientHeight - 50 + 'px' }}>
        <div className="listsearch">
          <div className="listsearchl">
            <span className="listsearchls">
              <span className="listsearchcondition">用户名 </span>
              <span className="searchcondition">
                <Search
                  id="username"
                  placeholder="(用户名)"
                  value={this.state.username}
                  onChange={(e) => {
                    this.setState({
                      username: $('#username').val()
                    })
                  }}
                  onSearch={(value) => {
                    var arr = this.state.dataSourcefu
                    var newarr = []
                    for (let i = 0; i < arr.length; i++) {
                      if (arr[i].UserName.indexOf(value) > -1) {
                        newarr.push(arr[i])
                      }
                    }
                    this.setState({
                      dataSource: newarr
                    })
                  }}
                />
              </span>
            </span>
            <span className="listsearchls">
              <span className="listsearchcondition">起始日期 </span>
              <span className="searchcondition">
                <DatePicker showTime locale={locale}
                  value={this.state.starttime ? moment(this.state.starttime) : null}
                  placeholder="起始日期"
                  onChange={(data, dataString) => {
                    this.setState({
                      starttime: dataString
                    })
                  }} /></span>
            </span>
            <span className="listsearchls">
              <span className="listsearchcondition">终止日期 </span>
              <span className="searchcondition">
                <DatePicker showTime locale={locale}
                  value={this.state.endtime ? moment(this.state.endtime) : null}
                  placeholder="终止日期"
                  onChange={(data, dataString) => {
                    this.setState({
                      endtime: dataString
                    })
                  }} /></span>
            </span>
          </div>
          <span className="listsearchr">
            <Button style={{display:this.state.buttonarr.indexOf('搜索')>-1?"inline-block":"none"}} onClick={this.onlianheSearch.bind(this)}>搜索</Button>
            <Button style={{display:this.state.buttonarr.indexOf('重置')>-1?"inline-block":"none"}} onClick={this.resetsearchvalue.bind(this)}>重置</Button>
          </span>
        </div>
        <div className="listpage"
          style={{ height: (clientHeight - 50 - 40) * 0.7 + 'px' }}>
          <Table
            size="small"
            dataSource={this.state.dataSource}
            columns={this.columns}
            pagination={{
              pageSize: this.state.pageSize,
              current: this.state.pageIndex,
              total: this.state.dataSourcelength
            }}
            onChange={this.pagechange.bind(this)}
          />
        </div>
        <div className="linetu" style={{ height: (clientHeight - 50 - 40) * 0.3 + 'px' }}>
          <div id="linetu" style={{ height: "90%", width: "70%" }}>

          </div>
        </div>
      </div>
    )
  }
}
export default AccessLog;