import React from 'react';
import { Table, Input, Tabs, Tree, Spin,Tooltip, Collapse, Form, Button, Select, Progress, message, Popover, Carousel, InputNumber } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import echarts from 'echarts';
import './style.scss'
import moment from 'moment'
// import "moment/locale/zh-cn"
// moment.locale('zh-cn')
import Draggable from 'react-draggable';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { Resizable } from 'react-resizable';
import { onCellf } from '../../comfunc'
import store from '../../store';
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;
const { Option } = Select;
const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;
const EditableContext = React.createContext();
var timercalculate ;
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = (record) => {
    //控制可不可以编辑
    //console.log(record)
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });

  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<InputNumber ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit.bind(this, record)}
        >
          {children}
        </div>
      );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
            children
          )}
      </td>
    );
  }
}

class WisdomDiagnosis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counflag:false,

      service: '',//厂
      //诊断模型树数据
      wisdomdiagnosistreeData: [],
      // checkedKeysA: [],//诊断模型复选
      // checkedKeysAfu: [],
      treeIdsele: '',

      selectedKeysA: [],//诊断模型选择
      equipmentName: '',//诊断设备名称
      EquipmentCode: '',//诊断设备编码

      //计算工具
      Software: [], //0: {SoftwareName: "B-BPDs"}1: {FilePath: "C:\QY_Boiler.bpd"}

      //工况下拉框选择
      equipmentinitarr: [],//初始化数据
      equipmentselekey: '',

      //模型加载iframe
      moduleloadframesrc: '',
      //测点
      selectedKeysB: [],

      axiosgetcedianarr: [],//点击获取的单个测点组
      axiosgetcedian: '',

      testpointtreeData: [],
      testpointtreeDataobj: {},//测点选择obj

      //原理数据id点
      theorydataid: [],
      //原理数据计算结果
      theorydataanswer: [],

      //测点Guid 定位
      CedianGuidarr: [],//{Guid: "M1556089457019_*u003f*u003f*u003f*u003f*u003f.dgn.i.dgn*u0020-*u0020Default*u0020-*u0020142704"}
      //测点单位和编号
      CedianUnitandCode: [], //{PrincipleData: "MTSuperheater_Steam_In_P", MatchingGroupUnit: "bar(a) "}

      //工况选择计算列表
      Calcucatearr: [],

      //故障记录
      ErrorRecordarr: [],
      // 数据看板列表
      DataKanbanarr: [],
      DataKanbanarrfu: [],
      activetabsKeykanban: "",


      //5个模块
      activetabsKey: '1',
      //模型加载
      begincount: 0,
      loadingcount: 0,
      //拖拽
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      activeDragsB: 0,
      controlledPositionB: {
        x: 0, y: 0
      },
      activeDragsC:0,
      controlledPositionC: {
        x: 0, y: 0
      },
      //测点搜索
      searchValue: '',
      //测点是否选择过
      Cedianname: '',
      per_choose: '',
      KKS: '',
      CPostition: '',
      OutSqlName: '',
      OutSqlNamefu: '',
      MatchingGroupUnit: '',
      //测点图
      cedianseleoption: {},
      cedianseleoptionfu: {},

      rowId: '',

      //流向系统
      datacaseobj: {},


      //专业宽高
      assetsviewleftw: 200,
      assetsviewcenterw: 220,

      //历史曲线
      timehistory:[],
      DCSValueshistory:[],
      JSValueshistory:[],
      IdealValuehistory:[]
    };
    //基础属性 供应商 责任相关 技术参数  文档关联
    this.calcucatecolumn = [
      {
        title: '参数名称',
        dataIndex: 'InputName',
        key: 'InputName',
        width: '40%', onCell:onCellf,
        render: (text) => <Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '参数值',
        editable: true,
        dataIndex: 'Value',
        key: 'Value',
        width: '35%', onCell:onCellf,
        render: (text) => <Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>

      },
      {
        title: '单位',
        dataIndex: 'InputUnit',
        key: 'InputUnit',
        width: '20%', onCell: onCellf,
        render: (text) => <Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>
      }
    ]

    this.ErrorRecordcolumn = [
      {
        title: '序号',
        dataIndex: 'f',
        key: 'f',
        width: '10%',
        render: (text, record, index) => {
          return `${index + 1}`
        }
      },
      {
        title: '故障日期',
        dataIndex: 'faultDate',
        key: 'faultDate',
        width: '20%',
      },
      {
        title: '故障类型',
        dataIndex: 'faultType',
        key: 'faultType',
        width: '20%',
      },
      {
        title: '故障持续时间(S)',
        dataIndex: 'failureDuration',
        key: 'failureDuration',
        width: '15%',
      },
      {
        title: '故障状态',
        dataIndex: 'faultCondition',
        key: 'faultCondition',
        width: '35%',
      },
    ]

    this.DataKanbancolumn = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '10%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{index + 1}</span>
        }
      },
      {
        title: '匹配组编号',
        dataIndex: 'MatchingGroupNum',
        key: 'MatchingGroupNum',
        width: '16%',
        onCell:onCellf,
        render: (text) => <Tooltip placement="bottomLeft" className="managetooltip tabledatacenter" title={text}>{text}</Tooltip>
      },
      {
        title: '匹配组名称',
        dataIndex: 'MatchingGroupName',
        key: 'MatchingGroupName',
        width: '22%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="bottomLeft" className="tabledatacenter managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '单位',
        dataIndex: 'MatchingGroupUnit',
        key: 'MatchingGroupUnit',
        width: '12%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: 'DCS',
        dataIndex: 'DCSValue',
        key: 'DCSValue',
        width: '10%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '理论值',
        dataIndex: 'JSsValue',
        key: 'JSsValue',
        width: '10%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '理想值',
        dataIndex: 'IdealValue',
        key: 'IdealValue',
        width: '10%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
    ]
    this.receivemess = this.receivemess.bind(this)
    this.keydownevent = this.keydownevent.bind(this)
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.content !== this.props.content) {
  //     return true
  //   } else {
  //     return false
  //   }

  // }
  //单元格编辑保存
  handleSave = row => {
    ////console.log(row)
    const newData = [...this.state.Calcucatearr];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ Calcucatearr: newData }, function () {
      ////console.log(this.state.Calcucatearr)
    });
  };
  componentDidMount() {
    var service = sessionStorage.getItem('service')
    this.setState({
      service: service
    })
    axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=1').then(data => {
      ////console.log('诊断模型树', conversion(data))
      this.setState({
        wisdomdiagnosistreeData: conversion(data)
      })
    })
    window.addEventListener('message', this.receivemess)
  }
  //componentWillReceiveProps
  getDerivedStateFromProps(nextprops) {
    
  }
  receivemess(e) {
    ////console.log(e.data)
    if (e.data == "COMPLETE") {
      var num = this.state.loadingcount
      num = num + 1
      if (num / this.state.begincount == 1) {
        this.setState({
          loadingcount: 0,
          begincount: 0,
        }, function () {
          this.refs.loadprogress.style.display = "none"
        })
      } else {
        this.setState({
          loadingcount: num
        }, function () {
          //////console.log(this.state.loadingcount / this.state.begincount)
          if (this.state.loadingcount / this.state.begincount == 1) {
            this.setState({
              loadingcount: 0,
              begincount: 0,
            }, function () {
              this.refs.loadprogress.style.display = "none"
            })
          }
        })
      }
    }
  }

  onSelectA(selectedKeys, info) {
    //console.log(selectedKeys, info)
    //诊断模型加载
    if (info.node.props.title == "流向系统") {
      this.setState({
        moduleloadframesrc: './BOS3dhtml/case/index.html',
        begincount: 0,
        loadingcount: 0
      })
      var moduleloadframe = document.getElementById("moduleloadframe")
      var _this = this
      moduleloadframe.onload = function () {
        _this.refs.loadprogress.style.display = "none"
      }
    } else {
      if (this.state.moduleloadframesrc == "./BOS3dhtml/wisdomdiagnosis.html") {
        this.refs.loadprogress.style.display = "block"
        document.getElementById("moduleloadframe").contentWindow.viewer3D.viewerImpl.modelManager.unloadAll()
        axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=4&ID=${selectedKeys[0]}`).then(data => {
          ////console.log(conversion(data))
          var data = conversion(data)
          //开始总数量
          if (data.length == 0) {
            this.refs.loadprogress.style.display = "none"
          } else {
            this.setState({
              begincount: data.length
            }, function () {
              for (let i = 0; i < data.length; i++) {
                document.getElementById("moduleloadframe").contentWindow.viewer3D.addView(data[i].ModelKey, "bole");
              }
            })
          }
        })
      } else {
        //诊断模型加载
        this.setState({
          moduleloadframesrc: `./BOS3dhtml/wisdomdiagnosis.html`
        })

        var moduleloadframe = document.getElementById("moduleloadframe")
        var _this = this
        moduleloadframe.onload = function () {
          _this.refs.loadprogress.style.display = "block"
          
          var homeyjbtn = document.getElementById("moduleloadframe").contentWindow.document.getElementsByClassName('yj-but')[0]
          homeyjbtn.addEventListener('click', function () {
            document.getElementById("moduleloadframe").contentWindow.viewer3D.resetScene({ visible: false, view: true, selected: true, transparent: true, colorfully: true })
          })
          var toolele = document.getElementById('moduleloadframe').contentWindow.document.getElementsByClassName('yj-tool')[0]
          toolele.style.display = "none"
          axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=4&ID=${selectedKeys[0]}`).then(data => {
            ////console.log(conversion(data))
            var data = conversion(data)
            //开始总数量
            if (data.length == 0) {
              _this.refs.loadprogress.style.display = "none"
            } else {
              _this.setState({
                begincount: data.length
              }, function () {
                for (let i = 0; i < data.length; i++) {
                  document.getElementById("moduleloadframe").contentWindow.viewer3D.addView(data[i].ModelKey, "bole");
                }
              })
            }
          })
        }
      }
    }

    this.setState({
      selectedKeysA: selectedKeys,//id
      equipmentName: info.node.props.title,
      EquipmentCode: info.node.props.Code,
    }, function () {
      //测点数据
      axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=2', {
        ID: this.state.selectedKeysA[0]
      }).then(data => {
        //console.log('测点上级', conversion(data))
        this.setState({
          testpointtreeData: conversion(data)
        })
      })
      //获取计算设备的软件名称，以及计算文件
      axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=1', {
        EquipmentCode: this.state.EquipmentCode
      }).then(data => {
        //console.log('SoftwareName', conversion(data))
        this.setState({
          Software: conversion(data)
        }, function () {
          if (this.state.activetabsKey == "2") {
            //获取原理数据id点
            axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=4', {
              ID: this.state.selectedKeysA[0]
            }).then(data => {
              //console.log('获取原理数据id点', data)
              var arr = conversion(data);
              var theorydataidlen = arr.length;
              //获取原理数据计算结果
              axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=5', {
                ID: this.state.selectedKeysA[0],
                SoftwareCode: this.state.Software[0].SoftwareName
              }).then(data => {
                //console.log('获取原理数据计算结果', data)
                if(data.data.indexOf('&')>-1){
                  var arr1 = data.data.split('&')
                  var theorydataanswerlen = arr1.length;

                  var datacaseobj = {}
                  // for (let i = 0; i < arr1.length; i++) {
                  datacaseobj[arr1[arr1.length - 1].split(',')[1]] = arr1[arr1.length - 1].split(',')[0]
                  datacaseobj[arr1[arr1.length - 2].split(',')[1]] = arr1[arr1.length - 2].split(',')[0]
                  datacaseobj[arr1[arr1.length - 3].split(',')[1]] = arr1[arr1.length - 3].split(',')[0]
                  datacaseobj[arr1[arr1.length - 4].split(',')[1]] = arr1[arr1.length - 4].split(',')[0]
                  datacaseobj[arr1[arr1.length - 5].split(',')[1]] = arr1[arr1.length - 5].split(',')[0]
                  // }
                  //console.log(datacaseobj)
                  this.setState({
                    datacaseobj: datacaseobj
                  })



                  for (let i = 0; i < theorydataidlen; i++) {
                    var c_p = arr1[i].split(',')[1] //编码
                    var datac = arr1[i].split(',')[0] //数据
                    var order = arr1[i].split(',')[2] //排序
                    if (document.getElementById('Diagramshow')) {
                      var obj = document.getElementById('Diagramshow').contentWindow.document.getElementById(c_p)
                      // //console.log(obj)
                      if(obj && obj.textContent){
                        if (c_p == "QY2019201" || c_p == "QY2019203" || c_p == "QY2019205" || c_p == "QY2019207" || c_p == "QY2019209") {
                          var text = obj.textContent.split(",");
                          text[0] = datac
                          showdata = text[0]
                          obj.textContent = showdata
                        } else {
                          if (obj != null) {
                            var text = obj.textContent.split(",");
                            if (order == 1) {
                              text[0] = datac
                            }
                            if (order == 2) {
                              text[1] = datac
                            }
                            if (order == 3) {
                              text[2] = datac
                            }
                            var showdata = "";
                            if (text.length == 1) {
                              showdata = text[0] + ',' + "" + ',' + ""
                            } else {
                              for (var j = 0; j < 3; j++) {
                                if (text[j] == "undefined")
                                  text[j] = ''
                                if (j < 2) {
                                  showdata += text[j] + ','
                                } else {
                                  showdata += text[j]
                                }
                              }
                            }
                            obj.textContent = showdata
                          }
                        }
                      }
                    }
                  }
                  this.setState({
                    theorydataidlen: theorydataidlen,
                    theorydataid: arr,
  
                    theorydataanswerlen: theorydataanswerlen,
                    theorydataanswer: arr1,
                  })
                }
              })
            })
          }
        })
      })
      //工况数据
      axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=2', {
        ID: this.state.selectedKeysA[0]
      }).then(data => {
        // //console.log('工况下拉框初始化数据', conversion(data))
        this.setState({
          equipmentinitarr: conversion(data)
        })
      })

      //数据看板
      axios.post('/DATA/MonitoringAndDiagnosis/ShowDataBoard.aspx?flag=1', {
        ID: this.state.selectedKeysA[0],
        EquipmentCode: this.state.EquipmentCode
      }).then(data => {
        ////console.log('数据看板', data)
        this.setState({
          DataKanbanarr: conversion(data),
          DataKanbanarrfu: conversion(data)
        })
      })
    })
  }
  //测点选择
  onSelectB(selectedKeys, info) {
    //console.log(selectedKeys, info)
    if (selectedKeys[0] == "0-0") {

    } else {
      this.setState({
        selectedKeysB: selectedKeys
      })
      if (info.node.props.Name) {
        //获取测点
        axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=1', {
          ProfessionalTreeId:selectedKeys[0]
        }).then(data => {
          ////console.log('测点', conversion(data))
          this.setState({
            axiosgetcedianarr: conversion(data),
            axiosgetcedian: selectedKeys[0]
          })
          var arr = this.state.testpointtreeData
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].ProfessionalTreeId == selectedKeys[0]) {
              arr[i].children = conversion(data)
            }
          }
          this.setState({
            testpointtreeData: arr,
          })
        })
      } else if (info.node.props.KKS) {
        this.refs.cepointechartstuzhu.style.display = "none"
        this.setState({
          KKS: info.node.props.KKS,
          Cedianname: info.node.props.title,
          CPostition: info.node.props.CPostition,
          OutSqlNamefu: info.node.props.OutSqlName,
          MatchingGroupUnit: info.node.props.MatchingGroupUnit,
          cedianseleoption: {}
        })

        //测点Guid 定位3D模型
        axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=10', {
          KKS: info.node.props.KKS
        }).then(data => {
          ////console.log('测点Guid', conversion(data))
          this.setState({
            CedianGuidarr: conversion(data)
          }, function () {
            if (this.state.CedianGuidarr[0]) {
              document.getElementById('moduleloadframe').contentWindow.viewer3D.transparentOtherComponentsByKey(this.state.CedianGuidarr[0].Guid)
              document.getElementById('moduleloadframe').contentWindow.viewer3D.highlightComponentsByKey(this.state.CedianGuidarr[0].Guid)
              document.getElementById('moduleloadframe').contentWindow.viewer3D.adaptiveSize(this.state.CedianGuidarr[0].Guid);
            }
          })
        })

        //原理数据图
        if (this.state.activetabsKey == "2") {
          var obj = document.getElementById('Diagramshow').contentWindow.document.getElementById(info.node.props.CPostition)
          if (this.state.per_choose == null || this.state.per_choose == '') {
            this.setState({
              per_choose: obj
            })
            obj.style.cssText = "fill:red;"
          }
          else {
            this.state.per_choose = this.state.per_choose.style.cssText = "fill:black;"
            this.setState({
              per_choose: obj
            })
            obj.style.cssText = "fill:red;"
          }
        }



      }
    }
  }

  //测点列表搜索
  searchkks(value) {
    ////console.log(value)
    if (this.state.axiosgetcedian) {
      var arr = this.state.axiosgetcedianarr
      var newarr = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].Name.indexOf(value) > -1) {
          newarr.push(arr[i])
        }
      }
      var arr1 = this.state.testpointtreeData
      ////console.log(arr1)
      for (let j = 0; j < arr1.length; j++) {
        if (arr1[j].ID == this.state.axiosgetcedian) {
          arr1[j].children = newarr
        }
      }
      this.setState({
        searchValue: value,
        testpointtreeData: arr1
      })
    } else {

    }
  }
  //装配树收缩
  zhuangpeishushou() {
    this.refs.wisdomdiagnosisleft.style.display = "none"
    this.refs.treeshoufangzhang.style.display = "block"
    if ($('.wisdomdiagnosiscenter').css("display") == "block") {
      $('.treeshoufang').css("left", this.state.assetsviewcenterw + 10 + 'px')
    } else if ($('.wisdomdiagnosiscenter').css("display") == "none") {
      $('.treeshoufang').css("left", "10px")
    }
  }
  zhuangpeishufang() {
    this.refs.wisdomdiagnosisleft.style.display = "block"
    this.refs.treeshoufangzhang.style.display = "none"
    if ($('.wisdomdiagnosiscenter').css("display") == "none") {
      $('.treeshoufang').css("left", this.state.assetsviewleftw + 10 + 'px')
    }
  }
  kksshushou() {
    this.refs.wisdomdiagnosiscenter.style.display = "none"
    this.refs.treeshoufangkks.style.display = "block"
    if ($('.wisdomdiagnosisleft').css("display") == "block") {
      $('.treeshoufang').css("left", this.state.assetsviewleftw + 10 + 'px')
    } else if ($('.wisdomdiagnosisleft').css("display") == "none") {
      $('.treeshoufang').css("left", "10px")
    }
  }
  kksshufang() {
    this.refs.wisdomdiagnosiscenter.style.display = "block"
    this.refs.treeshoufangkks.style.display = "none"
    if ($('.wisdomdiagnosisleft').css("display") == "none") {
      $('.treeshoufang').css("left", this.state.assetsviewcenterw + 10 + 'px')
    }
  }

  //页签切换
  activetabsKeychange(key) {
    ////console.log(key)
    if (key == "2") {
      this.setState({
        activetabsKey: key,
        activetabsKeykanban: ""
      })
      if (this.state.selectedKeysA[0]) {
        //获取原理数据id点
        axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=4', {
          ID: this.state.selectedKeysA[0]
        }).then(data => {
          //console.log('获取原理数据id点', data)
          var arr = conversion(data);
          var theorydataidlen = arr.length;
          //获取原理数据计算结果
          axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=5', {
            ID: this.state.selectedKeysA[0],
            SoftwareCode: this.state.Software[0].SoftwareName
          }).then(data => {
            //console.log('获取原理数据计算结果', data)
            if(data.data.indexOf('&')>-1){
              var arr1 = data.data.split('&')
              var theorydataanswerlen = arr1.length;
              var datacaseobj = {}
              // for (let i = 0; i < arr1.length; i++) {
              datacaseobj[arr1[arr1.length - 1].split(',')[1]] = arr1[arr1.length - 1].split(',')[0]
              datacaseobj[arr1[arr1.length - 2].split(',')[1]] = arr1[arr1.length - 2].split(',')[0]
              datacaseobj[arr1[arr1.length - 3].split(',')[1]] = arr1[arr1.length - 3].split(',')[0]
              datacaseobj[arr1[arr1.length - 4].split(',')[1]] = arr1[arr1.length - 4].split(',')[0]
              datacaseobj[arr1[arr1.length - 5].split(',')[1]] = arr1[arr1.length - 5].split(',')[0]
              // }
              //console.log(datacaseobj)
              this.setState({
                datacaseobj: datacaseobj
              })
  
              for (let i = 0; i < theorydataidlen; i++) {
                var c_p = arr1[i].split(',')[1] //编码
                var datac = arr1[i].split(',')[0] //数据
                var order = arr1[i].split(',')[2] //排序
                if (document.getElementById('Diagramshow') && document.getElementById('Diagramshow').contentWindow) {
                  // //console.log(document.getElementById('Diagramshow'))
                  var obj = document.getElementById('Diagramshow').contentWindow.document.getElementById(c_p)
                  // //console.log(obj)
                  if(obj && obj.textContent){
                    if (c_p == "QY2019201" || c_p == "QY2019203" || c_p == "QY2019205" || c_p == "QY2019207" || c_p == "QY2019209") {
                      // var text = obj.textContent.split(",");
                      // text[0] = datac
                      // showdata = text[0]
                      // obj.textContent = showdata
                    } else {
                      if (obj != null) {
                        var text = obj.textContent.split(",");
                        if (order == 1) {
                          text[0] = datac
                        }
                        if (order == 2) {
                          text[1] = datac
                        }
                        if (order == 3) {
                          text[2] = datac
                        }
                        var showdata = "";
                        if (text.length == 1) {
                          showdata = text[0] + ',' + "" + ',' + ""
                        } else {
                          for (var j = 0; j < 3; j++) {
                            if (text[j] == "undefined")
                              text[j] = ''
                            if (j < 2) {
                              showdata += text[j] + ','
                            } else {
                              showdata += text[j]
                            }
                          }
                        }
                        obj.textContent = showdata
                      }
                    }
                  }
                }
              }
  
              this.setState({
                theorydataidlen: theorydataidlen,
                theorydataid: arr,
  
                theorydataanswerlen: theorydataanswerlen,
                theorydataanswer: arr1,
              })
            }
          })
        })
      }
      if (this.state.CPostition) {
        //原理数据图
        var obj = document.getElementById('Diagramshow').contentWindow.document.getElementById(this.state.CPostition)
        if (this.state.per_choose == null || this.state.per_choose == '') {
          this.setState({
            per_choose: obj
          })
          obj.style.cssText = "fill:red;"
        }
        else {
          this.state.per_choose = this.state.per_choose.style.cssText = "fill:black;"
          this.setState({
            per_choose: obj
          })
          obj.style.cssText = "fill:red;"
        }
      }
    }
  }

  //打开计算
  tocalcucate() {
    if (this.state.selectedKeysA.length > 0) {
      this.refs.tocalcucate.style.display = "block"
    } else {
      message.error('请选择设备！')
    }
  }
  keydownevent (e){
    if(e.keyCode == 27){
      clearInterval(timercalculate)
      this.setState({
        counflag:false
      })
    }
  }
  //计算按钮
  suretocalcucate() {
    if (this.state.equipmentselekey) {
      var _this = this
      document.addEventListener('keydown',this.keydownevent)
      this.setState({
        counflag:true
      },function(){
        var arr = this.state.Calcucatearr
        var GroupInputNumstr = ''
        var GroupValuestr = ''
        for (let i = 0; i < arr.length; i++) {
          if (i == arr.length - 1) {
            GroupInputNumstr = GroupInputNumstr + arr[i].InputNum
            GroupValuestr = GroupValuestr + arr[i].Value
          } else {
            GroupInputNumstr = GroupInputNumstr + arr[i].InputNum + ','
            GroupValuestr = GroupValuestr + arr[i].Value + ','
          }
        }
        axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=7', {
          SoftwareCode: this.state.Software[0].SoftwareName,
          FilePath: this.state.Software[0].FilePath,
          WDId: this.state.equipmentselekey,
          GroupInputNum: GroupInputNumstr,
          GroupValue: GroupValuestr,
          ID: this.state.selectedKeysA[0]
        }).then(data => {
          //console.log(data)
          if (data.data.success) {
            var str = data.data
            timercalculate = setInterval(function() {
              axios.post('/DATA/AnalysisAndDiagnosis/Workingdata.aspx?flag=31',{
                SoftwareCode: _this.state.Software[0].SoftwareName,
                ID:data.data.data
              }).then(data=>{
                if(data.data.success==true){
                  _this.setState({
                    counflag:false,
                    equipmentselekey: '',
                    Calcucatearr: []
                  })
                  _this.refs.tocalcucate.style.display = "none"
                  clearInterval(timercalculate)
                  message.success('计算成功！')
                }
              })
            },10000)
          }
        })
      })
    } else {
      message.error('请选择工况！')
    }
  }
  //关闭计算
  closetocalcucate() {
    this.refs.tocalcucate.style.display = "none"
    this.setState({
      equipmentselekey: '',
      Calcucatearr: []
    })
  }
  //打开测点对应图线
  cepointechartstuzhu() {
    if (this.state.KKS) {
      //获取测点图表数据
      if (this.state.OutSqlNamefu) {
        this.setState({
          OutSqlName: ''
        }, function () {
          this.refs.cepointechartstuzhu.style.display = "block"
        })
        axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=1', {
          OutSqlName: this.state.OutSqlNamefu,
          SoftwareCode: this.state.Software[0].SoftwareName,
          ID: this.state.selectedKeysA[0]
        }).then(data => {
          //console.log(data)
          var DCSdata = conversion(data)[0].DCSValues
          var JSdata = conversion(data)[0].JSValue
          var LXdata = conversion(data)[0].IdealValue
          var cedianseleoption = {
            grid: {
              left: '15%',
              right: '10%',
              top: '10%',
              bottom: '10%',
              scontainLabel: true
            },
            tooltip: {
              trigger: 'item',
              // position: [50, 20],
              formatter: "{b} {c}"
            },
            // title: {
            //   text: '特性示例：渐变色 阴影 点击缩放',
            //   subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
            // },
            xAxis: {
              show: false,
              data: ['DCS', '理论值', '理想值'],
              axisLabel: {
                inside: true,
                textStyle: {
                  color: '#fff'
                }
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              z: 10
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: `{value}(${this.state.MatchingGroupUnit})`,
                textStyle: {
                  color: '#fff'
                }
              }
            },
            dataZoom: [
              {
                type: 'inside'
              }
            ],
            series: [
              {
                type: 'bar',
                barWidth: 30,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(
                      0, 0, 0, 1,
                      [
                        { offset: 0, color: '#3cecfc' },
                        { offset: 0.5, color: '#3cecfc' },
                        { offset: 1, color: '#3cecfc' }
                      ]
                    )
                  },
                  emphasis: {
                    color: new echarts.graphic.LinearGradient(
                      0, 0, 0, 1,
                      [
                        { offset: 0, color: '#2378f7' },
                        { offset: 0.7, color: '#2378f7' },
                        { offset: 1, color: '#83bff6' }
                      ]
                    )
                  }
                },
                data: [DCSdata, JSdata, LXdata]
              }
            ]
          };
          this.setState({
            cedianseleoption: cedianseleoption
          }, function () {
            //基于准备好的dom，初始化echarts实例
            var myChart1 = echarts.init(document.getElementById('echartstuzhucedian'));
            myChart1.clear();
            // 绘制图表
            myChart1.setOption(this.state.cedianseleoption, true);
          })
        })
      }
    } else {
      message.error('请选择测点！')
    }
  }
  //关闭测点柱状图
  closeechartstuzhu() {
    this.refs.cepointechartstuzhu.style.display = "none"
  }

  //关闭折线图历史曲线
  closeechartstuhistory() {
    this.refs.cepointechartstuhistory.style.display = "none"
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  handleStartB() {
    this.setState({ activeDragsB: ++this.state.activeDragsB });
  }
  handleStopB() {
    this.setState({ activeDragsB: --this.state.activeDragsB });
  }
  handleDragB(e, ui) {
    const { x, y } = this.state.controlledPositionB;
    this.setState({
      controlledPositionB: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  handleStartC() {
    this.setState({ activeDragsC: ++this.state.activeDragsC });
  }
  handleStopC() {
    this.setState({ activeDragsC: --this.state.activeDragsC });
  }
  handleDragC(e, ui) {
    const { x, y } = this.state.controlledPositionC;
    this.setState({
      controlledPositionC: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  //页签点击
  tabclickchangekey(key) {
    ////console.log(key)
    if (key == "3") {
      $('.equipmentevaluation').css('display', "none")
      $('.DataKanban').toggle()
      if ($('.DataKanban').css('display') == "block") {
        this.refs.wisdomdiagnosisleft.style.display = "block"
        this.refs.wisdomdiagnosiscenter.style.display = "none"
        this.refs.treeshoufangkks.style.display = "block"
        this.refs.treeshoufangzhang.style.display = "none"
        $('.treeshoufang').css("left", "17%")
        this.setState({
          activetabsKeykanban: "3",
          activetabsKey: "1"
        })
      } else {
        this.setState({
          activetabsKeykanban: "",
          activetabsKey: "1"
        })
        // this.refs.wisdomdiagnosiscenter.style.display = "block"
        // this.refs.wisdomdiagnosisleft.style.display = "block"
        // this.refs.treeshoufangkks.style.display = "none"
        // this.refs.treeshoufangzhang.style.display = "none"
      }
    } else if (key == "4") {
      $('.DataKanban').css('display', "none")
      $('.equipmentevaluation').toggle()
      if ($('.equipmentevaluation').css('display') == "block") {
        this.refs.wisdomdiagnosisleft.style.display = "block"
        this.refs.wisdomdiagnosiscenter.style.display = "none"
        this.refs.treeshoufangkks.style.display = "block"
        this.refs.treeshoufangzhang.style.display = "none"
        $('.treeshoufang').css("left", "17%")
        this.setState({
          activetabsKeykanban: "4",
          activetabsKey: "1"
        })
      } else {
        this.setState({
          activetabsKeykanban: "",
          activetabsKey: "1"
        })
        // this.refs.wisdomdiagnosiscenter.style.display = "block"
        // this.refs.wisdomdiagnosisleft.style.display = "block"
        // this.refs.treeshoufangkks.style.display = "none"
        // this.refs.treeshoufangzhang.style.display = "none"
      }
    } else if (key == "5") {
      //获取工具栏元素
      var toolele = document.getElementById('moduleloadframe').contentWindow.document.getElementsByClassName('yj-tool')[0]
      if (toolele) {
        if (toolele.style.display == "flex") {
          toolele.style.display = "none"
          if (toolele.style.display == "none") {
            this.setState({
              activetabsKeykanban: "",
              activetabsKey: "1"
            })
          }
        } else {
          toolele.style.display = "flex"
          if (toolele.style.display == "flex") {
            this.setState({
              activetabsKeykanban: "5",
              activetabsKey: "1"
            })
          }
        }
      }

    } else if (key == "1" || key == "2") {
      this.setState({
        activetabsKey: key,
        activetabsKeykanban: ""
      })
      var toolele = document.getElementById('moduleloadframe').contentWindow.document.getElementsByClassName('yj-tool')[0]
      if (toolele) {
        toolele.style.display = "none"
      }
      this.refs.wisdomdiagnosiscenter.style.display = "block"
      this.refs.wisdomdiagnosisleft.style.display = "block"
      this.refs.treeshoufangkks.style.display = "none"
      this.refs.treeshoufangzhang.style.display = "none"
      $('.equipmentevaluation').css('display', "none")
      $('.DataKanban').css('display', "none")
    }
  }
  //获取柱状图
  getechartstu(OutSqlName) {
    axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=1', {
      OutSqlName: OutSqlName,
      SoftwareCode: this.state.Software[0].SoftwareName,
      ID: this.state.selectedKeysA[0]
    }).then(data => {
      //console.log(data)
      // var JSdata = data.data;
      // var DCSdata = (JSdata * (Math.random() * 1 + 0.5)).toFixed(2);
      // var LXdata = (JSdata * (Math.random() * 1 + 0.5)).toFixed(2);
        var DCSdata = conversion(data)[0].DCSValues
        var JSdata = conversion(data)[0].JSValue
        var LXdata = conversion(data)[0].IdealValue
      var cedianseleoptionfu = {
        grid: {
          left: '15%',
          right: '10%',
          top: '10%',
          bottom: '10%',
          scontainLabel: true
        },
        tooltip: {
          trigger: 'item',
          // position: [50, 20],
          formatter: "{b} {c}"
        },
        // title: {
        //   text: '特性示例：渐变色 阴影 点击缩放',
        //   subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
        // },
        xAxis: {
          show: false,
          data: ['DCS', '理论值', '理想值'],
          axisLabel: {
            inside: true,
            textStyle: {
              color: '#fff'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          z: 10
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: `{value}(${this.state.MatchingGroupUnit})`,
            textStyle: {
              color: '#fff'
            }
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: 30,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#3cecfc' },
                    { offset: 0.5, color: '#3cecfc' },
                    { offset: 1, color: '#3cecfc' }
                  ]
                )
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#2378f7' },
                    { offset: 0.7, color: '#2378f7' },
                    { offset: 1, color: '#83bff6' }
                  ]
                )
              }
            },
            data: [DCSdata, JSdata, LXdata]
          }
        ]
      }
      this.setState({
        cedianseleoptionfu: cedianseleoptionfu
      }, function () {
        var cedianseleoptionfu = this.state.cedianseleoptionfu
        //基于准备好的dom，初始化echarts实例
        $('#cepointechartstuzhu').removeAttr('_echarts_instance_');
        var myChart2 = echarts.init(document.getElementById('echartstuzhucedianfu'));
        myChart2.clear();
        // 绘制图表
        myChart2.setOption(cedianseleoptionfu, true);
      })
    })
  }
  leftbuttonecharts(){
    var DataKanbanarr = this.state.DataKanbanarr
    var leftobj = {}
    for(let i=0;i<DataKanbanarr.length;i++){
      if(DataKanbanarr[i].MatchingGroupName == this.state.rowId){
        if(i>=1){
          leftobj = DataKanbanarr[i-1]
        }
      }
    }
    if(leftobj.OutSqlName){
      this.setState({
        OutSqlName: leftobj.OutSqlName,
        OutSqlNamefu: '',
        rowId: leftobj.MatchingGroupName,
        MatchingGroupUnit: leftobj.MatchingGroupUnit,
        OutSqlNamefu: '',
        Cedianname: leftobj.MatchingGroupName
      },function(){
        this.getechartstu(leftobj.OutSqlName)
        this.getechartstuhistory()
      })
    }
  }
  rightbuttonecharts(){
    var DataKanbanarr = this.state.DataKanbanarr
    var leftobj = {}
    for(let i=0;i<DataKanbanarr.length;i++){
      if(DataKanbanarr[i].MatchingGroupName == this.state.rowId){
        if(i<DataKanbanarr.length-1){
          leftobj = DataKanbanarr[i+1]
        }
      }
    }
    if(leftobj.OutSqlName){
      this.setState({
        OutSqlName: leftobj.OutSqlName,
        OutSqlNamefu: '',
        rowId: leftobj.MatchingGroupName,
        MatchingGroupUnit: leftobj.MatchingGroupUnit,
        OutSqlNamefu: '',
        Cedianname: leftobj.MatchingGroupName
      },function(){
        this.getechartstu(leftobj.OutSqlName)
        this.getechartstuhistory()
      })
    }
  }
  //测点点击---曲线
  senddata(record) {
    //console.log(record)
    this.setState({
      OutSqlName: record.OutSqlName,
      OutSqlNamefu: '',
      rowId: record.MatchingGroupName,
      MatchingGroupUnit: record.MatchingGroupUnit,
      OutSqlNamefu: '',
      Cedianname: record.MatchingGroupName
    }, function () {
      this.refs.cepointechartstuzhu.style.display = "block"
      this.refs.cepointechartstuhistory.style.display = "none"
      this.getechartstu(this.state.OutSqlName)
    })
  }

  setRowClassName = (record) => {
    return record.MatchingGroupName === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }
  
  getechartstuhistory(){
  
    var DataKanbanarr = this.state.DataKanbanarr
    // //console.log(DataKanbanarr,this.state.rowId)
    var IdealValue = ""
    for(let i=0;i<DataKanbanarr.length;i++){
      if(DataKanbanarr[i].MatchingGroupName === this.state.rowId ){
        IdealValue = DataKanbanarr[i].IdealValue
      }
    }
    //console.log('理想值',IdealValue)
    axios.post('/DATA/MonitoringAndDiagnosis/ShowDataBoard.aspx?flag=3',{
      OutSqlName:this.state.OutSqlName
    }).then(res=>{
      // //console.log(res)
      var arr = conversion(res)
      var data = []
      var data1 = []
      for(let i=0;i<arr.length;i++){
        data.push(arr[i].DCSValues)
        data1.push(IdealValue)
      }
      this.setState({
        DCSValueshistory:data,
        IdealValuehistory:data1
      },function(){
        
        var historyechart = {
          tooltip: {
            trigger: 'axis',
            position: function (pt) {
              return [pt[0], '10%'];
            }
          },
          legend: {
            data:['DCS', '理论值', '理想值'],
            icon: "rect",
            textStyle: { color: 'white' },
          },
          color:['#ff6600','#2db7f5','#51effe'],
          toolbox: {
            show: false,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.state.timehistory,
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            min: function (value) {
              return value.min*0.8;
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          dataZoom: [{
            type: 'inside',
            start: 0,
            end: 10
          }, {
            start: 0,
            end: 10,
            handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
            handleSize: '80%',
            handleStyle: {
              color: '#fff',
              shadowBlur: 3,
              shadowColor: 'rgba(0, 0, 0, 0.6)',
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          }],
          series: [
            {
              name: 'DCS',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: '#ff6600'
              },
              data: this.state.DCSValueshistory
            },
            {
              name: '理论值',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: '#2db7f5'
              },
              data: this.state.JSValueshistory
            },
            {
              name: '理想值',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: '#51effe'
              },
              data: this.state.IdealValuehistory
            }
          ]
        }

        this.setState({
          historyechart: historyechart,
        }, function () {
          //基于准备好的dom，初始化echarts实例
          var myChart11 = echarts.init(document.getElementById('echartstuhistory'));
          // 绘制图表
          myChart11.setOption(this.state.historyechart,true);
        })
      })
    })
    axios.post('/DATA/MonitoringAndDiagnosis/ShowDataBoard.aspx?flag=2',{
      OutSqlName:this.state.OutSqlName
    }).then(res=>{
      // //console.log(res)
      var arr = conversion(res)
      var data = []
      var time = []
      var data1 = []
      for(let i=0;i<arr.length;i++){
        time.push(arr[i].JSDate)
        data.push(arr[i].JSValues)
        data1.push(IdealValue)
      }
      this.setState({
        JSValueshistory:data,
        timehistory:time,
        IdealValuehistory:data1
      },function(){
        // var historyechart = {
        //   tooltip: {
        //     trigger: 'item',
        //     // position: [50, 20],
        //     formatter: " {c} "
        //   },
        //   legend: {
        //     data:['DCS', '理论值', '理想值'],
        //     icon: "rect",
        //     textStyle: { color: 'white' },
        //   },
        //   color:['#ff6600','#2db7f5','#657382'], 
        //   // grid: {
        //   //   left: '5%',   // 与容器左侧的距离
        //   //   top: "4%"
        //   // },
        //   xAxis: {
        //     type: 'category',
        //     boundaryGap: false,
        //     data: this.state.timehistory,
        //     axisLabel: {
        //       show: true,
        //       textStyle: {
        //         color: '#fff',  //更改坐标轴文字颜色
        //         fontSize: 12      //更改坐标轴文字大小
        //       }
        //     },
        //     axisLine: {
        //       lineStyle: {
        //         color: '#fff' //更改坐标轴颜色
        //       }
        //     }
        //   },
        //   yAxis: {
        //     axisLabel: {
        //       show: true,
        //       textStyle: {
        //         color: '#fff',  //更改坐标轴文字颜色
        //         fontSize: 12      //更改坐标轴文字大小
        //       }
        //     },
        //     axisLine: {
        //       lineStyle: {
        //         color: '#fff' //更改坐标轴颜色
        //       }
        //     },
        //     splitLine: {
        //       show: false
        //     },
        //     min: function (value) {
        //       return value.min - 1;
        //     },
        //     // show: false,
        //     type: 'value',
        //   },
        //   series: [{
        //     data: this.state.DCSValueshistory,
        //     type: 'line',
        //     name: "DCS",
        //     smooth: 0.5,
        //   },{
        //     data: this.state.JSValueshistory,
        //     type: 'line',
        //     name: "理论值",
        //     smooth: 0.5,
        //   },{
        //     data: this.state.IdealValuehistory,
        //     type: 'line',
        //     name: "理想值",
        //     smooth: 0.5,
        //   }]
        // };
        var historyechart = {
          tooltip: {
            trigger: 'axis',
            position: function (pt) {
              return [pt[0], '10%'];
            }
          },
          legend: {
            data:['DCS', '理论值', '理想值'],
            icon: "rect",
            textStyle: { color: 'white' },
          },
          color:['#ff6600','#2db7f5','#51effe'],
          toolbox: {
            show: false,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.state.timehistory,
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            min: function (value) {
              if(value>0){
                return value.min*0.8;
              }else{
                return value.min*1.2;
              }
            },
            axisLine: {
              lineStyle: {
                color: '#fff' //更改坐标轴颜色
              }
            }
          },
          dataZoom: [{
            type: 'inside',
            start: 0,
            end: 10
          }, {
            start: 0,
            end: 10,
            handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
            handleSize: '80%',
            handleStyle: {
              color: '#fff',
              shadowBlur: 3,
              shadowColor: 'rgba(0, 0, 0, 0.6)',
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          }],
          series: [
            {
              name: 'DCS',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: '#ff6600'
              },
              data: this.state.DCSValueshistory
            },
            {
              name: '理论值',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: '#2db7f5'
              },
              data: this.state.JSValueshistory
            },
            {
              name: '理想值',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                color: '#51effe'
              },
              data: this.state.IdealValuehistory
            }
          ]
        }

        this.setState({
          historyechart: historyechart,
        }, function () {
          //基于准备好的dom，初始化echarts实例
          var myChart11 = echarts.init(document.getElementById('echartstuhistory'));
          // 绘制图表
          myChart11.setOption(this.state.historyechart,true);
        })
      })
    })
  }
  //历史曲线
  toviewhistoryline() {
    this.getechartstuhistory()
    this.refs.cepointechartstuzhu.style.display = "none"
    this.refs.cepointechartstuhistory.style.display = "block"
  }
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const calcucatecolumn = this.calcucatecolumn.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (

      <section className="wisdomdiagnosis" style={{ height: clientHeight - 50 + 'px' }}>
        {/* 计算 */}
        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="tocalcucate dragwindow" ref="tocalcucate">
            <h2>当前环境及燃料数据 <span onClick={this.closetocalcucate.bind(this)}>x</span></h2>
            <div className="calcucatedata">
              <h3>
                <span>
                  选择工况：
                  <Select style={{ width: "200px", height: "32px", background: "none" }}
                    labelInValue
                    value={{ key: this.state.equipmentselekey }}
                    onChange={(value) => {
                      ////console.log(value)
                      //工况列表             
                      axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=3', {
                        WDId: value.key,
                        SoftwareCode: this.state.Software[0].SoftwareName,
                        Code:this.state.EquipmentCode
                      }).then(data => {
                        //console.log('工况数据列表', conversion(data))
                        this.setState({
                          equipmentselekey: value.key,
                          Calcucatearr: conversion(data)
                        })
                      })
                    }}>
                    {
                      this.state.equipmentinitarr.map((item, index) => {
                        return (<Option value={item.ID} key={item.ID}>{item.WorkingConditionName}</Option>)
                      })
                    }
                  </Select>
                </span>
              </h3>
              <div className="listscroll" style={{height:"350px"}}>
                <Table
                  size="small"
                  components={components}
                  rowClassName={() => 'editable-row'}
                  // bordered
                  dataSource={this.state.Calcucatearr}
                  columns={calcucatecolumn}
                  pagination={false}
                  scroll={{ y: 320 }}
                />
              </div>
              <span><Button onClick={this.suretocalcucate.bind(this)}>计算</Button></span>
            </div>
          </div>
        </Draggable>
        {/* 历史曲线 */}
        {/* <Draggable
          position={this.state.controlledPositionC}
          onStart={this.handleStartC.bind(this)}
          onDrag={this.handleDragC.bind(this)}
          onStop={this.handleStopC.bind(this)}> */}
          <div className="cepointechartstuhistory dragwindow" ref="cepointechartstuhistory">
            <h2>{this.state.Cedianname}历史曲线图示 <span onClick={this.closeechartstuhistory.bind(this)}>
            
              x</span></h2>
              <div id="echartstuhistory">
              
            </div>
            <span onClick={this.leftbuttonecharts.bind(this)} className="leftbuttonhis"> {'<'} </span>
            <span onClick={this.rightbuttonecharts.bind(this)} className="rightbuttonhis">{'>'}</span>
            
          </div>
        {/* </Draggable> */}
        {/* 曲线 */}
        <Draggable
          position={this.state.controlledPositionB}
          onStart={this.handleStartB.bind(this)}
          onDrag={this.handleDragB.bind(this)}
          onStop={this.handleStopB.bind(this)}>
          <div className="cepointechartstuzhu dragwindow" ref="cepointechartstuzhu">
            <h2>{this.state.Cedianname}图示 <span>
              
            <Button onClick={this.toviewhistoryline.bind(this)} style={{marginRight:"20px",height:"28px"}}>历史曲线</Button>
              <span onClick={this.closeechartstuzhu.bind(this)}>x</span></span></h2>

            <div id="echartstuzhucedian" style={{ display: this.state.OutSqlNamefu == "" ? "none" : "block" }}>

            </div>
            <div id="echartstuzhucedianfu" style={{ display: this.state.OutSqlName == "" ? "none" : "block" }}>

            </div>
            <div style={{ background: "rgba(0, 0, 0, 0.6)", color: "#fff", textAlign: "center", paddingTop: "50px", height: "340px", display: this.state.OutSqlName == "" && this.state.OutSqlNamefu == "" ? "block" : "none" }}>暂无数据</div>
            <span onClick={this.leftbuttonecharts.bind(this)} className="leftbutton"> {'<'} </span>
            <span onClick={this.rightbuttonecharts.bind(this)} className="rightbutton">{'>'}</span>
          </div>
        </Draggable>

        <div className="wisdomdiagnosisleftcenter" style={{ height: clientHeight - 50 + 'px' }}>
          <Resizable className="box" axis="x" minConstraints={[100, 100]} maxConstraints={[300, 300]} height={clientHeight - 50} width={this.state.assetsviewleftw}
            onResize={(event, { element, size }) => {
              this.setState({ assetsviewleftw: size.width, assetsviewcenterw: 420 - size.width });
            }}>
            <div className="wisdomdiagnosisleft" ref="wisdomdiagnosisleft"
              style={{ height: clientHeight - 50 + 'px', width: this.state.assetsviewleftw + 'px' }}>
              <h2 className="zhuangpeishu">
                <span>诊断模型</span>
                <span className="right">
                  <span className="right1" onClick={this.tocalcucate.bind(this)}><img src={require('../../assets/img1/计算.png')} /></span>
                  <span onClick={this.zhuangpeishushou.bind(this)} className="zhuangpeishushousuo"></span>
                </span>
              </h2>
              <div className="wisdomdiagnosislefttree" style={{ height: clientHeight - 50 + 'px' }}>
                <Tree
                  // checkable
                  // onCheck={this.onCheckA.bind(this)}
                  // checkedKeys={this.state.checkedKeysA}
                  onSelect={this.onSelectA.bind(this)}
                  selectedKeys={this.state.selectedKeysA}
                >
                  {
                    this.state.wisdomdiagnosistreeData.map((item, index) => {
                      return (<TreeNode title={item.Name} key={item.ID} Code={item.Code}></TreeNode>)
                    })
                  }
                </Tree>
              </div>
            </div>
          </Resizable>


          <div className="wisdomdiagnosiscenter" ref="wisdomdiagnosiscenter" style={{
            height: clientHeight - 50 + 'px', width: this.state.assetsviewcenterw + 'px'
          }}>
            <h2>
              <span className="left">测点列表</span>
              <span className="right">
                {/* <span className="right1" onClick={this.tocalcucate.bind(this)}><img src={require('../../assets/img1/计算.png')} /></span> */}
                <span className="right2" onClick={this.cepointechartstuzhu.bind(this)}><img src={require('../../assets/img1/曲线.png')} /></span>
                <span className="kksshousuo" onClick={this.kksshushou.bind(this)}>
                </span>
              </span>
            </h2>

            {/* <div className="cediansearch">
              <Search
                placeholder="请输入关键字搜索"
                onSearch={this.searchkks.bind(this)}
                style={{ width: "100%", height: '30px', background: "none" }}
              />
            </div> */}
            <div className="tree" style={{ height: clientHeight - 50 - 60 + 'px', overflow: "auto" }}>
              <DirectoryTree
                onSelect={this.onSelectB.bind(this)}
                selectedKeys={this.state.selectedKeysB}
              >
                {
                  this.state.equipmentName ?
                    <TreeNode title={this.state.equipmentName} key="0-0">
                      {
                        this.state.testpointtreeData.map((item, index) => {
                          return (
                            <TreeNode title={<Tooltip placement="right" title={item.Name}>{item.Code}</Tooltip>}
                              key={item.ProfessionalTreeId} Name={item.Name}
                              className={item.Flag == "1" ? "flageq1" : ""}
                            >
                              {
                                item.children && item.children.length > 0 ?
                                  item.children.map((item, index) => {
                                    return (
                                      <TreeNode title={item.MatchingGroupName}
                                        style={{ color: item.MatchingGroupName.indexOf(this.state.searchValue) > -1 ? "#f00" : "#fff" }}
                                        key={item.ID} KKS={item.KKS}
                                        OutSqlName={item.OutSqlName}
                                        MatchingGroupUnit={item.MatchingGroupUnit}
                                        CPostition={item.CPostition}></TreeNode>
                                    )
                                  }) : ""
                              }
                            </TreeNode>
                          )
                        })
                      }
                    </TreeNode>
                    : ""
                }
              </DirectoryTree>
            </div>
          </div>

        </div>
        
        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
        
        <Tabs tabPosition="bottom" className="wisdomdiagnosiscon" activeKey={this.state.activetabsKey}
          style={{ height: clientHeight - 50 + 'px' }}
          onChange={this.activetabsKeychange.bind(this)}
          onTabClick={this.tabclickchangekey.bind(this)}
        >
          <TabPane tab={<span className="wisdomdiagnosiscontent" style={{ height: clientHeight - 50 + 'px' }}>
            {
              this.state.activetabsKey == "1" && this.state.activetabsKeykanban == "" ?
                <img src={require('../../assets/img1/3D模型浏览2.png')} /> :
                <img src={require('../../assets/img1/3D模型浏览.png')} />
            }
            {
              this.state.activetabsKey == "1" && this.state.activetabsKeykanban == "" ?
                <span style={{ color: '#6ea0e2' }}>3D模型浏览</span> :
                <span>3D模型浏览</span>
            }

          </span>} key="1"
          >
            <div className="moduleload wisdomdiagnosiscontents" style={{ height: clientHeight - 50 + 'px' }}>
              <div className="loadprogress" ref="loadprogress">
                <Progress strokeWidth={15} type="circle" percent={parseInt((this.state.loadingcount / this.state.begincount) * 100)} status={(this.state.loadingcount / this.state.begincount) == 1 ? "success" : "active"} />
              </div>
              <iframe title="myiframe" src={this.state.moduleloadframesrc} id="moduleloadframe" style={{
                width: "100%", height: "100%",
              }}></iframe>

              <div className="DataKanban" style={{ height: clientHeight - 50 - 80 - 60 + 'px' }}>
                <div className="DataKanbansearch">
                  <span>
                    数据匹配组
                        {/* <Select style={{ width: "154px", height: "30px", background: "none" }} onChange={(value) => {
                        this.setState({
                          DataKanbanselekey: value
                        })
                      }}>
                        <Option value="压力" key="压力">压力</Option>
                        <Option value="流量" key="流量">流量</Option>
                        <Option value="温度" key="温度">温度</Option>
                        <Option value="系统参数" key="系统参数">系统参数</Option>
                      </Select> */}
                  </span>
                  <span>
                    <Search
                      placeholder="请输入关键字"
                      onSearch={(value) => {
                        var arr = this.state.DataKanbanarrfu
                        var newarr = []
                        for (let i = 0; i < arr.length; i++) {
                          if (arr[i].MatchingGroupNum.indexOf(value) > -1 || arr[i].MatchingGroupName.indexOf(value) > -1) {
                            newarr.push(arr[i])
                          }
                        }
                        this.setState({
                          DataKanbanarr: newarr
                        })
                      }}
                      style={{ width: 200 }}
                    />
                  </span>
                </div>
                <div className="listscroll" style={{ height: clientHeight - 50 - 40 - 80 - 60 + 'px' }}>
                  <Table
                    size="small"
                    dataSource={this.state.DataKanbanarr}
                    columns={this.DataKanbancolumn}
                    pagination={false}
                    onRow={(record) => {
                      return {
                        onClick: this.senddata.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassName}
                    scroll={{ y: clientHeight - 50 - 40 - 40 - 80 - 60 + 'px' }}
                  />
                </div>
              </div>

              <div className="equipmentevaluation" style={{ height: clientHeight - 50 - 80 - 60 + 'px' }}>
                <ul className="equipmentevaluationdata">
                  <li>
                    <h2>专家意见</h2>
                    <div>
                      <p><span>[故障现象]:</span>中温过热器出口烟温过高。</p>
                      <p><span>[处理措施]:</span>①减少给煤量，使得炉内放热适应外界变化;②调节送风量，保持合理的风煤比。</p>
                    </div>
                  </li>
                </ul>
                <Carousel style={{ height: clientHeight - 50 - 80 - 180 - 60 + 'px' }}>
                  <div className="evaluationall" style={{ height: clientHeight - 50 - 80 - 180 - 60 + 'px' }}>
                    <h3>
                      <span>状态评估</span>
                      <div style={{ height: clientHeight - 50 - 80 - 180 - 40 - 60 + 'px' }}>
                        <p><span>[状态评估]:</span>①减少给煤量，使得炉内放热适应外界变化;②调节送风量，保持合理的风煤比。</p>
                      </div>
                    </h3>
                  </div>
                  <div className="evaluationall" style={{ height: clientHeight - 50 - 80 - 180 - 60 + 'px' }}>
                    <h3>
                      <span>风险评估</span>
                      <div style={{ height: clientHeight - 50 - 80 - 180 - 40 - 60 + 'px' }}>
                        <p><span>[风险评估]:</span>①减少给煤量，使得炉内放热适应外界变化;②调节送风量，保持合理的风煤比。</p>
                      </div></h3>
                  </div>
                  <div className="evaluationall" style={{ height: clientHeight - 50 - 80 - 180 - 60 + 'px' }}>
                    <h3>
                      <span>寿命评估</span>
                      <div style={{ height: clientHeight - 50 - 80 - 180 - 40 - 60 + 'px' }}>
                        <p><span>[寿命评估]:</span>①减少给煤量，使得炉内放热适应外界变化;②调节送风量，保持合理的风煤比。</p>
                      </div></h3>
                  </div>
                  <div className="evaluationall" style={{ height: clientHeight - 50 - 80 - 180 - 60 + 'px' }}>
                    <h3>
                      <span>经济性评估</span>
                      <div style={{ height: clientHeight - 50 - 80 - 180 - 40 - 60 + 'px' }}>
                        <p><span>[经济性评估]:</span>①减少给煤量，使得炉内放热适应外界变化;②调节送风量，保持合理的风煤比。</p>
                      </div></h3>
                  </div>
                </Carousel>
              </div>
            </div>
          </TabPane>
          <TabPane tab={<span className="wisdomdiagnosiscontent" style={{ height: clientHeight - 50 + 'px' }}>
            {
              this.state.activetabsKey == "2" ?
                <img src={require('../../assets/img1/原理数据2.png')} /> :
                <img src={require('../../assets/img1/原理数据.png')} />
            }
            {
              this.state.activetabsKey == "2" ?
                <span style={{ color: '#6ea0e2' }}>原理数据</span> :
                <span>原理数据</span>
            }
          </span>} key="2"
          >
            <div className="Principledata wisdomdiagnosiscontents" style={{ height: clientHeight - 50 + 'px',width:clientWidth-426+'px' }}>
              <table className="datacasetable">
                <tbody>
                  <tr>
                    <td>正平衡效率</td>
                    <td>反平衡效率</td>
                    <td>输入热功率</td>
                    <td>输出热功率</td>
                    <td>排烟热损失</td>
                  </tr>
                  <tr>
                    <td>ZPHXL-1</td>
                    <td>FPHXL-1</td>
                    <td>SRRXL-1</td>
                    <td>SSRXL-1</td>
                    <td>PYRSS-1</td>
                  </tr>
                  <tr>
                    <td>{this.state.datacaseobj["QY2019201"]}</td>
                    <td>{this.state.datacaseobj["QY2019203"]}</td>
                    <td>{this.state.datacaseobj["QY2019205"]}</td>
                    <td>{this.state.datacaseobj["QY2019207"]}</td>
                    <td>{this.state.datacaseobj["QY2019209"]}</td>
                  </tr>
                </tbody>
              </table>
              {
                this.state.service == "庆元琦丰新能源有限公司" ?
                  this.state.equipmentName == "#1锅炉" ?
                    <iframe title="myiframe" src='qingyuan/qingyuan_GL.html' id="Diagramshow" style={{
                      width: "100%", height: clientHeight - 50 - 120 + 'px'
                    }}></iframe> :
                    this.state.equipmentName == "#2锅炉" ?
                      <iframe title="myiframe" src='qingyuan/qingyuan_GL.html' id="Diagramshow" style={{
                        width: "100%", height: clientHeight - 50 - 120 + 'px'
                      }}></iframe> :
                      this.state.equipmentName == "冷端系统" ?
                        <iframe title="myiframe" src='qingyuan/qingyuan_QJ.html' id="Diagramshow" style={{
                          width: "100%", height: clientHeight - 50 - 120 + 'px'
                        }}></iframe> :
                        this.state.equipmentName == "回热系统" ?
                          <iframe title="myiframe" src='qingyuan/qingyuan_QJ.html' id="Diagramshow" style={{
                            width: "100%", height: clientHeight - 50 - 120 + 'px'
                          }}></iframe> :
                          this.state.equipmentName == "汽轮发电机组" ?
                            <iframe title="myiframe" src='qingyuan/qingyuan_QJ.html' id="Diagramshow" style={{
                              width: "100%", height: clientHeight - 50 - 120 + 'px'
                            }}></iframe> :
                            this.state.equipmentName == "风烟系统" ?
                              <iframe title="myiframe" src='qingyuan/qingyuan_FY.html' id="Diagramshow" style={{
                                width: "100%", height: clientHeight - 50 - 120 + 'px'
                              }}></iframe> :
                              this.state.equipmentName == "流向系统" ?
                                <iframe title="myiframe" src='qingyuan/qingyuan_GL.html' style={{ width: "100%", height: clientHeight - 50 - 120 + 'px' }} id="Diagramshow"></iframe>
                                : "" : ""
              }

            </div>
          </TabPane>
          <TabPane tab={<span className="wisdomdiagnosiscontent" style={{ height: clientHeight - 50 + 'px' }}>
            {
              this.state.activetabsKeykanban == "3" ?
                <img src={require('../../assets/img1/数据看板2.png')} /> :
                <img src={require('../../assets/img1/数据看板.png')} />
            }
            {
              this.state.activetabsKeykanban == "3" ?
                <span style={{ color: '#6ea0e2' }}>数据看板</span> :
                <span>数据看板</span>
            }
          </span>} key="3"
          >
            <div className="wisdomdiagnosiscontents">

            </div>
          </TabPane>
          <TabPane tab={<span className="wisdomdiagnosiscontent" style={{ height: clientHeight - 50 + 'px' }}>
            {
              this.state.activetabsKeykanban == "4" ?
                <img src={require('../../assets/img1/设备评估2.png')} /> :
                <img src={require('../../assets/img1/设备评估.png')} />
            }
            {
              this.state.activetabsKeykanban == "4" ?
                <span style={{ color: '#6ea0e2' }}>设备评估</span> :
                <span>设备评估</span>
            }
          </span>} key="4"
          >
            <div className="wisdomdiagnosiscontents" style={{ height: clientHeight - 50 + 'px' }}>
            </div>
          </TabPane>

          <TabPane tab={<span className="wisdomdiagnosiscontent" style={{ height: clientHeight - 50 + 'px' }}>
            {
              this.state.activetabsKeykanban == "5" ?
                <img src={require('../../assets/img1/故障记录2.png')} /> :
                <img src={require('../../assets/img1/故障记录.png')} />
            }
            {
              this.state.activetabsKeykanban == "5" ?
                <span style={{ color: '#6ea0e2' }}>工具栏</span> :
                <span>工具栏</span>
            }
          </span>} key="5"
          >
            <div className="wisdomdiagnosiscontents" style={{ height: clientHeight - 50 + 'px' }}>
            </div>
          </TabPane>

        </Tabs>
        <div className="treeshoufang">
          <span ref="treeshoufangzhang" onClick={this.zhuangpeishufang.bind(this)}>诊断模型</span>
          <span ref="treeshoufangkks" onClick={this.kksshufang.bind(this)}>测点列表</span>
        </div>
      </section >
    )
  }
}
export default WisdomDiagnosis;

