import React, { Fragment } from 'react';
import './style.scss'
import { Icon, Upload, message } from 'antd';
import {changeconfigimage} from '../../store/actionCreators'
import '../../store'
import { conversion } from '../../converse';
import axios from 'axios'
import store from '../../store';
import $ from 'jquery'
class FactoryConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl:store.getState().CompanyconfigimageUrl,
      loading: false,
      imageFlag: "0",
      factoryinfo:""
    }
    this.getBase64 = this.getBase64.bind(this)
    this.beforeUpload = this.beforeUpload.bind(this)
  }

  componentDidMount() {
    var factoryinfo = sessionStorage.getItem('factory')
    this.setState({
      factoryinfo
    })
  }
   //图片上传
   getBase64(img, callback) {
    //console.log(img, callback)
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  //限制上传图片的格式和大小
  beforeUpload(file) {
    console.log(file)
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('超过10M限制，不允许上传!');
    }
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    const isBMP = file.type === 'image/bmp';
    if (!(isJPG || isJPEG || isGIF || isPNG || isBMP)) {
      message.error('只能上传JPG 、JPEG 、GIF、 PNG、 BMP格式的图片!')
    }else{
      return (isBMP || isGIF || isJPEG || isJPG || isPNG) && isLt2M;
    }
  }
  handletuChange = (info) => {
    console.log(info)
    if (info.file.status === 'uploading') {
      this.setState({ loading: true, imageUrl: "" });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => this.setState({
        imageUrl,
        imageFlag: '1'
      }, function () {
        console.log(this.state.imageUrl);
        
        axios.get('/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=1').then(res=>{
          console.log(conversion(res))
          if(conversion(res)[0] && conversion(res)[0].CompletPathProjectPhoto){
            var ProjectconfigimageUrl = conversion(res)[0].CompletPathProjectPhoto
            var CompanyconfigimageUrl = conversion(res)[0].CompletPathCompanyPhoto
            var configID =  conversion(res)[0].ID
            var configVersion =  conversion(res)[0].RecordVersion
            const action = changeconfigimage(ProjectconfigimageUrl,CompanyconfigimageUrl,configID,configVersion)
            store.dispatch(action)
          }
        })
        this.setState({
          loading: false
        })
        message.success(`${info.file.name} 上传成功`);

        // axios.post('/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=2',{
        //   CompletPathCompanyPhoto:this.state.imageUrl,
        //   ID:store.getState().configID,
        //   RecordVersion:store.getState().configVersion
        // }).then(res=>{
        //   console.log(res)
        //   if(res.data==true){
        //     axios.get('/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=1').then(res=>{
        //       console.log(conversion(res))
        //       if(conversion(res)[0] && conversion(res)[0].CompletPathProjectPhoto){
        //         var ProjectconfigimageUrl = conversion(res)[0].CompletPathProjectPhoto
        //         var CompanyconfigimageUrl = conversion(res)[0].CompletPathCompanyPhoto
        //         var configID =  conversion(res)[0].ID
        //         var configVersion =  conversion(res)[0].RecordVersion
        //         const action = changeconfigimage(ProjectconfigimageUrl,CompanyconfigimageUrl,configID,configVersion)
        //         store.dispatch(action)
        //       }
        //     })
        //     this.setState({
        //       loading: false
        //     })
        //     message.success(`${info.file.name} 上传成功`);
        //   }else{
        //     message.error(`${info.file.name} 上传失败`);
        //   }
        // })
      }));
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 上传失败`);
    }
  }
  render() {
    const  imageUrl  = store.getState().CompanyconfigimageUrl
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    if(imageUrl){
      $('.imageupload .ant-upload').css("border","none")
    }else{
      $('.imageupload .ant-upload').css("border","1px solid #d9d9d9")
    }
    return (
      <div className="configmanage">
        <div className="imageupload" style={{
          width:clientHeight>900?"600px":clientHeight>600?"400px":"200px",
          height:clientHeight>900?"500px":clientHeight>600?"300px":"100px"}}>
        <Upload
                  name=""
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={'/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=2&CompletPathCompanyPhoto=1&ID='+store.getState().configID+'&RecordVersion='+store.getState().configVersion}
                  beforeUpload={this.beforeUpload}
                  onChange={this.handletuChange}
                >{
              this.state.imageUrl ?
                <img src={this.state.imageUrl} alt="" />
                :
               
                        <div>
                          <Icon type={this.state.loading ? 'loading' : 'plus'} />
                          <div className="ant-upload-text">点击此处上传照片</div>
                        </div>
            }
                </Upload>
        </div>
        {
          this.state.factoryinfo.indexOf("9MW生物质热电联产")>-1 ?
          <Fragment>
             <div style={{width:clientHeight>900?clientWidth-700 +'px':clientHeight>600?clientWidth-500 +'px':clientWidth-300 +'px'}}>
                <h1>庆元县屏都综合新区生物质热电联供项目</h1>
                <h2>一、项目概况</h2>
                <h3>1、工程名称：庆元县屏都综合新区生物质热电联供项目</h3>
                <h3>2、工程规模：建设2台（1用1备）50t/h高温高压循环流化床生物质锅炉</h3>
                <h3>1台C9MW高温高压抽汽凝汽式汽轮发电机组及相关配套设施。</h3>
                <h3>本工程年消耗生物质7.35万吨，机组年利用小时数为7200小时。</h3>
                <h3>3、工程地址：庆元县屏都综合新区</h3>
                <h3>4、工程建设单位：庆元琦丰新能源有限公司</h3>
                <h3>5、工程总投资：14856万元</h3>
                <h2>二、设计依据</h2>
                <h3>1、文件依据</h3>
                </div>
                <h4>（1）、庆元县发展和改革局《关于庆元县屏都综合新区生物质热电联供项目核准的通知》；</h4>
                <h4>（2）、庆元县环保局《关于庆元县屏都综合新区生物质热电联供项目环境影响报告书的审查意见》；</h4>
                <h4>（3）、浙江省庆元县电力公司《关于庆元县屏都综合新区生物质热电联供项目接入系统设计的审查意见》（浙电发展[2012]）108号；</h4>
                <h4>（4）、浙江省经济和信息化委员会《关于庆元县屏都综合新区生物质热电联供项目节能评估报告的审查意见》（浙经信资源 [2012] 568号）</h4>
                <h4>（5）、建设项目选址意见书</h4>
                <h4>（6）、庆元县水利局《关于庆元县屏都综合新区低丘缓坡开发二期工程水土持方案报告的批复》；</h4>
                <h3>2、规范、规程</h3>
                <h4>（1）《秸秆发电厂设计规范》（GB50762-2012）；</h4>
                <h4>（2）《小型火力发电厂设计规范》（GB50049-2011）；</h4>
                <h4>（3）《建筑设计防火规范》（GB50016-2006）；</h4>
                <h4>（4）《火力发电厂与变电所设计防火规范》（GB50229-2006）；</h4>
                <h4>（5）《工程建设标准强制性条文》(电力工程部分) (2006年版)；</h4>
                <h4>（6）《火力发电厂初步设计文件内容深度规定》（DL/T5427-2009）；</h4>
                <h4>（7）《锅炉大气污染物排放标准》（GB13271-2014）。</h4>
                <h3>3、其他</h3>
                <h4>（1）本项目的可行性研究报告；</h4>
                <h4>（2）业主提供的三大主机订货资料及其他相关资料。</h4>
                <h2>三、设计范围</h2>
                <h3>本工程建设1×50t/h高温高压循环流化床生物质锅炉，配套1台C9MW高温高压抽汽凝汽式汽轮发电机组，初步设计范围包括：</h3>
                <h3>（1）燃烧系统、热力系统、燃料储运输送、除灰渣、烟气净化、电气、热控、化水、水工、暖通、土建等专业的工程设计。</h3>
                <h3>（2）本项目的投资概算。</h3>
                <h3>（3）环境保护、消防、劳动安全及工业卫生、节约能源专篇(章)。</h3>
                <h2>另外，本初步设计内容不包括电力接入系统和厂外生物质储运系统，费用不列入本工程总投资内。</h2>
             
           
          </Fragment>
           :
            this.state.factoryinfo.indexOf("临沂汇宝新能源有限公司高效煤粉锅炉房")>-1 ?
           <Fragment>
<div style={{width:clientHeight>900?clientWidth-700 +'px':clientHeight>600?clientWidth-500 +'px':clientWidth-300 +'px'}}>
                  <h1>临沂汇宝新能源有限公司</h1>
                  <h2>一、工程概况</h2>
                  <h3>临沂汇宝新能源年有限公司有限公司因生产工艺生产用汽的需要，决定新建一台21t/h高效煤粉锅炉，本工程主要包括:</h3>
                  <h3>1、新建一台21t/h高效煤粉锅炉；</h3>
                  <h3>2、锅炉尾部烟气处理设备：布袋除尘器、脱硫系统、脱硝系统；</h3>
                  <h3>3、新建旋膜式除氧器一台。</h3>
                  <h3>4、相应的煤粉系统、点火系统、汽水系统、烟风系统等。</h3>
                  </div> 
                  <h2>二、设计依据</h2>
                  <h3>1、临沂汇宝新能源有限公司与上海工业锅炉有限公司签订的合同及技术协议</h3>
                  <h3>2、业主提供的有关资料或联系函；</h3>
                  <h3>3、本项目业主筹建组与我公司工作组数次协调的商定意见</h3>
                  <h3>4、有关的规程、规定</h3>
                  <h4>（1）、《锅炉房设计规范》（GB50041-2015）</h4>
                  <h4>（2）、《工业锅炉房设计规范》（GBJ41－79）</h4>
                  <h4>（3）、《工业锅炉水质》（DL/T1576-2008）</h4>
                  <h4>（4）、《火力发电厂汽水管道设计技术规定》（DL/T5054-2016）等</h4>
                  <h3>5、 锅炉厂提供的锅炉资料及其他辅助设备资料。</h3>
                  <h4>锅 炉 台 数：  1台</h4>
                  <h4>生  产  厂：    上海工业锅炉有限公司</h4>
                  <h4>锅 炉 型 号：   GG21-3.82-M</h4>
                  <h4>额定蒸发量：    21t/h</h4>
                  <h4>额定蒸汽压力：  3.82MPa</h4>
                  <h4>额定蒸汽温度：  450°C（饱和蒸汽）</h4>
                  <h4>给 水 温 度：   104°C</h4>
                  <h4>排 烟 温 度：   142.1°C</h4>
                  <h4>锅 炉 效 率：   87.5%</h4>
                
           
           </Fragment>:
              this.state.factoryinfo.indexOf("山东金城医药股份有限公司高效煤粉锅炉房")>-1 ?
              <div style={{width:clientHeight>900?clientWidth-700 +'px':clientHeight>600?clientWidth-500 +'px':clientWidth-300 +'px'}}>
                    <h1>山东金城医药股份有限公司</h1>
                    <h2>一、工程概况</h2>
                    <h3>山东金城医药股份有限公司为提高锅炉效率、减少锅炉尾气污染物的排放，决定新建一台20t/h高效煤粉锅炉，本工程主要包括:</h3>
                    <h3>1、新建一台20t/h高效煤粉锅炉；</h3>
                    <h3>2、锅炉尾部烟气处理设备：布袋除尘器、脱硫系统、脱硝系统；</h3>
                    <h3>3、增加旋膜式除氧器一台；</h3>
                    <h3>4、相应的煤粉系统、点火系统、汽水系统、烟风系统等。</h3>
                    <h2>二、设计依据</h2>
                    <h3>1、山东金城医药股份有限公司与上海工业锅炉有限公司签订的合同；</h3>
                    <h3>2、业主提供的有关资料或联系函；</h3>
                    <h3>3、本项目业主筹建组与我公司工作组数次协调的商定意见</h3>
                    <h3>4、有关的规程、规定</h3>
                    <h4>（1）、《锅炉房设计规范》（GB50041-2008）</h4>
                    <h4>（2）、《工业锅炉房设计规范》（GBJ41－79）</h4>
                    <h4>（3）、《工业锅炉水质》（DL/T1576-2008）</h4>
                    <h4>（4）、火力发电厂汽水管道设计技术规定（DL/T5054-1996）等</h4>
                    <h3>5、 锅炉厂提供的锅炉资料及其他辅助设备资料。</h3>
                    <h4>锅 炉 台 数：   1台</h4>
                    <h4>生  产   厂：   上海工业锅炉有限公司</h4>
                    <h4>锅 炉 型 号：   GG20-1.25-AⅡ</h4>
                    <h4>额定蒸发 量：   20t/h</h4>
                    <h4>额定蒸汽压力：  1.25MPa</h4>
                    <h4>额定蒸汽温度：  194°C（饱和蒸汽）</h4>
                    <h4>给 水 温 度：   104°C</h4>
                    <h4>排 烟 温 度：   ~145°C</h4>
                    <h4>锅 炉 效 率：   87.56%</h4>
                </div> : ""
        }
      </div>
    )
  }
}
export default FactoryConfig;