import React from 'react'
import { Tree, Input, Select,Spin, Table, Button, Tabs, message, Tooltip, Form, Row, Col, Drawer, DatePicker, Modal } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import Draggable from 'react-draggable'
import { conversion } from '../../converse'
import TextArea from 'antd/lib/input/TextArea'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import moment from 'moment'
import reqwest from 'reqwest'
import "moment/locale/zh-cn"
import { onCellf } from '../../comfunc'
import store from '../../store';
import { getButtonLimit } from '../../utils/getbuttonlimit'
moment.locale('zh-cn')
const TabPane = Tabs.TabPane;
const confirm = Modal.confirm
const Option = Select.Option;
const { Search } = Input;
const { TreeNode, DirectoryTree } = Tree;

class ConfigManagement1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr:[],

      counflag:false,
      rowIdA: '',
      rowIdB: '',
      rowIdAobj: {},
      dataSourceA: [],
      dataSourceAfu: [],
      dataSourceAlength: 0,

      dataSourceB: [],
      dataSourceBlength: 0,

      pageIndexA: 1,
      pageIndexB: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 30 - 50) / 28),

      formiptobj: {},
      addedit: '',
      addeditname: '',
      visibledraw: false
    }
    //文档
    this.columnsA = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '8%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndexA - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '符号',
        dataIndex: 'ClassificationName',
        key: 'ClassificationName',
        width: '15%',
      },
      {
        title: '创建单位',
        dataIndex: 'Establish',
        key: 'Establish',
        width: '30%'
      },
      {
        title: '备注',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '57%', onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      }
    ]
    this.columnsB = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '12%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndexB - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '参数名称',
        dataIndex: 'ParameterName',
        key: 'ParameterName',
        width: '30%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>

      },
      {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '20%'
      },
      {
        title: '备注',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '37%', onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>

      }
    ]
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(84)

    axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10',{timeout:1000}).then(data => {
      console.log(data)
      // console.log('设备类别列表', conversion(data))
      var str = data.data
      this.setState({
        dataSourceA: conversion(data),
        dataSourceAfu: conversion(data),
        dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
        rowIdA:conversion(data)[0].ID,
        rowIdAobj:conversion(data)[0]
      },function(){
        axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=11', {
          ParentId: this.state.rowIdA
        }).then(data => {
          // console.log('详细', conversion(data))
          var str = data.data
          this.setState({
            dataSourceB: conversion(data),
            dataSourceBfu: conversion(data),
            dataSourceBlength: Number(str.substring(12, str.indexOf(',')))
          })
        })
      })
    })
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 30 - 50 ) / 28)
    })
  }

  //搜索
  onSearch(value) {
    console.log(value)
    var arr = this.state.dataSourceAfu
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].ClassificationName.indexOf(value) > -1 ||arr[i].Establish.indexOf(value) > -1) {
        newarr.push(arr[i])
      }
    }
    this.setState({
      dataSourceA: newarr
    })
  }

  //异常
  pagechange(key, page) {
    console.log(key, page)
    if (key == "列表") {
      this.setState({
        pageIndexA: page.current,
      }, function () {

      })
    } else if (key == "详情") {
      this.setState({
        pageIndexB: page.current,
      }, function () {

      })
    }
  }

  //选中
  senddataA(record) {
    console.log(record)
    this.setState({
      rowIdA: record.ID,
      formiptobj: record,
      counflag:true
    }, function () {
      axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=11', {
        ParentId: this.state.rowIdA
      }).then(data => {
        console.log('详细', conversion(data))
        var str = data.data
        this.setState({
          dataSourceB: conversion(data),
          dataSourceBfu: conversion(data),
          dataSourceBlength: Number(str.substring(12, str.indexOf(','))),
          counflag:false,
          pageIndexB:1,
        })
      })
    })
  }

  setRowClassNameA = (record) => {
    return record.ID === this.state.rowIdA ? 'clickRowStyl' : 'clickRowSty';
  }
  senddataB(record) {
    console.log(record)
    this.setState({
      rowIdB: record.ID,
      formiptobj: record
    })
  }

  setRowClassNameB = (record) => {
    return record.ID === this.state.rowIdB ? 'clickRowStyl' : 'clickRowSty';
  }


  handleAdd(key) {
    if (key == "参数详情") {
      if (this.state.rowIdA) {
        this.setState({
          addedit: '添加',
          addeditname: key,
          visibledraw: true,
          formiptobj:{}
        }, function () {
          this.props.form.resetFields();
        })
      } else {
        message.error('请选择类别！')
      }
    } else {
      this.setState({
        addedit: '添加',
        addeditname: key,
        visibledraw: true,
        formiptobj:{}
      }, function () {
        this.props.form.resetFields();
      })
    }
  }
  handleEdit(key) {
    if (key == "设备类别") {
      if (this.state.rowIdA) {
        var obj = this.state.rowIdAobj
        this.setState({
          addedit: '编辑',
          addeditname: key,
          visibledraw: true,
          formiptobj:obj
        })
      } else {
        message.error('请选择！')
      }
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate() + ' ' + value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else {
            values[i] = values[i]
          }
        }
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "设备类别") {
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=12', values).then(data => {
              //console.log(data)
              if (data.data == true || data.data.success) {
                //考核结果列表
                axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
                  console.log('设备类别列表', conversion(data))
                  var str = data.data
                  this.setState({
                    dataSourceA: conversion(data),
                    dataSourceAfu: conversion(data),
                    dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
                    visibledraw: false
                  })
                })
              }
            })
          } else if (this.state.addeditname == "参数详情") {
            values.ParentId = this.state.rowIdA
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=15', values).then(data => {
              //console.log(data)
              if (data.data == true || data.data.success) {
                //考核结果列表
                axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=11', {
                  ParentId: this.state.rowIdA
                }).then(data => {
                  console.log('详细', conversion(data))
                  var str = data.data
                  this.setState({
                    dataSourceB: conversion(data),
                    dataSourceBfu: conversion(data),
                    dataSourceBlength: Number(str.substring(12, str.indexOf(','))),
                    visibledraw: false
                  })
                })
              }
            })
          }
        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "设备类别") {
            values.ID = this.state.rowIdA
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=14', values).then(data => {
              //console.log(data)
              if (data.data == true || data.data.success) {
                //考核结果列表
                axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
                  console.log('设备类别列表', conversion(data))
                  var str = data.data
                  this.setState({
                    dataSourceA: conversion(data),
                    dataSourceAfu: conversion(data),
                    dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
                    visibledraw: false
                  })
                })
              }
            })
          }
        }
      }
    });
  };
  handleDel(key) {
    var _this = this
    if (key == "设备类别") {
      if (this.state.rowIdA) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=13', {
              ID: _this.state.rowIdA
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                //考核结果列表
                axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
                  console.log('设备类别列表', conversion(data))
                  var str = data.data
                  _this.setState({
                    dataSourceA: conversion(data),
                    dataSourceAfu: conversion(data),
                    dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
                    visibledraw: false,
                    pageIndexA:1
                  }, function () {
                    message.success('删除成功！')
                  })
                })
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistIdexam: ''
            })
          },
        });

      } else {
        message.error('请选择!')
      }
    } else if (key == "参数详情") {
      if (this.state.rowIdB) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=16', {
              ID: _this.state.rowIdB
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=11', {
                  ParentId: _this.state.rowIdA
                }).then(data => {
                  console.log('详细', conversion(data))
                  var str = data.data
                  _this.setState({
                    dataSourceB: conversion(data),
                    dataSourceBfu: conversion(data),
                    dataSourceBlength: Number(str.substring(12, str.indexOf(','))),
                    pageIndexB:1
                  },function(){
                    message.success('删除成功！')
                  })
                })
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistIdexam: ''
            })
          },
        });

      } else {
        message.error('请选择!')
      }
    }
  }
  onClose() {
    this.setState({
      visibledraw: false
    }, function () {
      this.props.form.resetFields();
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div className="configmanagement1" style={{ height: clientHeight - 50 + 'px' }}>
        <Drawer
          title={this.state.addedit + '' + this.state.addeditname}
          onClose={this.onClose.bind(this)}
          visible={this.state.visibledraw}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{height:clientHeight-50-55+'px'}}>
            {
              this.state.addeditname == "设备类别" ?
                <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="符号">
                        {getFieldDecorator('ClassificationName', {
                          initialValue: this.state.formiptobj["ClassificationName"],
                          rules: [{ required: true, message: '请输入符号' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="创建单位">
                        {getFieldDecorator('Establish', {
                          initialValue: this.state.formiptobj["Establish"],
                          rules: [{ required: true, message: '请输入创建单位' }],
                        })(
                          <Input 
                          // disabled={this.state.addedit == "编辑" ? true : false} 
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="textipt">
                    <Col span={12}>
                      <Form.Item label="备注">
                        {getFieldDecorator('Remark', {
                          initialValue: this.state.formiptobj["Remark"],
                          rules: [{ required: true, message: '请输入备注' }],
                        })(
                          <TextArea rows={2} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
                :
                this.state.addeditname == "参数详情" ?
                  <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="参数名称">
                          {getFieldDecorator('ParameterName', {
                            initialValue: this.state.formiptobj["ParameterName"],
                            rules: [{ required: true, message: '请输入参数名称' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="单位">
                          {getFieldDecorator('Unit', {
                            initialValue: this.state.formiptobj["Unit"],
                            rules: [{ required: true, message: '请输入单位' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="textipt">
                      <Col span={12}>
                        <Form.Item label="备注">
                          {getFieldDecorator('Remark', {
                            initialValue: this.state.formiptobj["Remark"],
                            rules: [{ required: true, message: '请输入备注' }],
                          })(
                            <TextArea rows={4} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
                      </Button>
                      </Form.Item>
                    </Row>
                  </Form> :
                  ""}
          </div>
        </Drawer>

        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
        <div className="paralist">
          <h2 className="h2title">设备类别列表
          <div className="listsearch">
            <div className="listsearchl">
              <span className="listsearchls">
                <span className="searchcondition">
                  <Search
                    id="UserSearch"
                    placeholder="(设备类别)"
                    onSearch={this.onSearch.bind(this)}
                  />
                </span>
              </span>
            </div>
            <span className="listsearchr">
              <Button style={{display:this.state.buttonarr.indexOf('添加-类别')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '设备类别')}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑-类别')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this, '设备类别')}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除-类别')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this, '设备类别')}>删除</Button>
            </span>
          </div>
          
          </h2>
          <div className="listpage"
            style={{ height: clientHeight - 50 - 30 -20 + 'px' }}>
            <Table
              size="small"
              dataSource={this.state.dataSourceA}
              columns={this.columnsA}
              onRow={(record) => {
                return {
                  onClick: this.senddataA.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassNameA}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndexA,
                total: this.state.dataSourceAlength
              }}
              onChange={this.pagechange.bind(this, '列表')}
            />
          </div>

        </div>
        <div className="paradetail">
          <h2 className="h2title">参数详情设置
          <div className="listsearch">
            <div className="listsearchl">

            </div>
            <span className="listsearchr">
              <Button style={{display:this.state.buttonarr.indexOf('添加-详细')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '参数详情')}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除-详细')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this, '参数详情')}>删除</Button>
            </span>
          </div></h2>
          <div className="listpage"
            style={{ height: clientHeight - 50 - 30 - 20 + 'px' }}>
            <Table
              size="small"
              dataSource={this.state.dataSourceB}
              columns={this.columnsB}
              onRow={(record) => {
                return {
                  onClick: this.senddataB.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassNameB}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndexB,
                total: this.state.dataSourceBlength
              }}
              onChange={this.pagechange.bind(this, '详情')}
            />
          </div>
        </div>
      </div>
    )
  }
}
const ConfigManagement1app = Form.create()(ConfigManagement1);
export default ConfigManagement1app;