export function onCellf (){
  return {
    style: {
      maxWidth: "10px",
      maxHeight:"26px",
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      cursor: 'pointer'
    }
  }
}