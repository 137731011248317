import React from 'react';
import './style.scss'

class Seconddata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    document.getElementById('media').src= "../../../shipin.mp4"
  }
  render() {
    return (
      <div className="mediapage">
        <video id='media'
          ref='media'
          className="video"
          controls={true}
          style={{width: "100%" ,height: "100%"}}
          type='video/mp4'
          src="">
        </video>
      </div>
    )
  }
}
export default Seconddata;