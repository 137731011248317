import {createStore} from 'redux';
import reducer from './reducer'
const store = createStore(reducer)
export default store

//使用redux-chunk -(action对象 改成 一个函数 可以在actionCreators.js里面写业务逻辑 请求数据(axios)) （借助applyMiddleware）npm install --save redux-thunk

//官方提供 但不能he redux-dev-tools同时使用 
// const store = createStore(
//     reducer,
//     applyMiddleware(thunk)
// ) 

//解决办法：使用增强函数compose
// import { createStore , applyMiddleware ,compose } from 'redux'  //  引入createStore方法
// import reducer from './reducer'    
// import thunk from 'redux-thunk'

// const composeEnhancers =   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}):compose

// const enhancer = composeEnhancers(applyMiddleware(thunk))

// const store = createStore( reducer, enhancer) // 创建数据存储仓库
// export default store   //暴露出去