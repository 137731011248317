export function conversion(data){
    if(data){
        data = data.data;
        if(data){
            data = data.split("data:")[1];
            data = data.substring(0, data.lastIndexOf('}'));
            var ConData = JSON.parse(data);
        }else{
            var ConData = [];
        }
        return ConData;
    }
    
}

export function updateRowObj(arr,ID){
    var arr = arr
    var obj = {}
    for(let i=0;i<arr.length;i++){
        if(arr[i].ID == ID){
            obj = arr[i]
        }
    }
    return obj
}