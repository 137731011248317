import React from 'react';
import './style.scss'
import $ from 'jquery'
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Tooltip } from 'antd';

class Seconddata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientHeight: 0,
      clientWidth: 0,
      //甘特图数据源
      // data定义甘特图中的任务 { id:任务id, text:任务描述, start_date:任务开始日期,progress:任务完成度, duration:在当前时间刻度下的任务持续周期, parent:父任务的id   }
      // links定义甘特图中的任务关联线 { id:关联线id,source: 数据源任务的id,target:目标源任务的id, type:关联线类型(0:结束到开始,1:开始到开始,2:结束到结束) }
      tasks :{
        data:[
            {id:1,number:'0', text:"项目1", start_date:"2016-01-04",end_date:"2017-06-08",duration:365,duration1:0, progress:0.6, open:true ,
            // users:[{resource_id:2,value:8},{resource_id:3,value:4}]
            users:"Jane"
            },
            {id:2,number:'1', text:"施工辅助工程", start_date:"2016-01-04",end_date:"2017-06-08", duration:365,duration1:0, open:true,
            users:"Jane1",parent:1},
            {id:3,number:'1.1', text:"施工水流控制", start_date:"2016-03-01",end_date:"2017-05-11", duration:305, progress:0.5, open:true,
            users:"Jane2",parent:2},
            {id:4,number:'1.1.1' , text:"截流施工准备(截流模型试验)", start_date:"2016-03-01",end_date:"2017-01-20", duration:228, progress:0.5, open:true,
            users:"Jane3",parent:3},
            {id:5,number:'1.1.2' , text:"截流预进占及合龙", start_date:"2016-10-17",end_date:"2016-11-11",  duration:19, progress:1, open:true,
            users:"Jane4",parent:3},
            {id:6,number:'1.1.3' , text:"2016年11月5日,择期完成大江截流", start_date:"2016-11-07 00:00:00",end_date:"2016-11-08 00:00:00", duration:1, progress:1, open:true,
            users:"Jane5",parent:3},
            {id:7,number:'1.1.4' , text:"上游围堰防渗墙施工平台填筑及导向", start_date:"2016-11-07",end_date:"2016-12-09", duration:24, progress:0.5, open:true,
            users:"Jane6",parent:3},
            {id:8,number:'1.1.5' , text:"上游围堰防渗墙施工(完成50%)", start_date:"2016-12-01",end_date:"2017-01-13", duration:30, progress:0.5, open:true,
            users:"Jane6",parent:3},
            {id:9,number:'1.1.6' , text:"下游围堰防渗墙施工平台填筑,防渗墙", start_date:"2016-12-01",end_date:"2017-01-13", duration:30, progress:0, open:true,
            users:"Jane6",parent:3},
            {id:10,number:'1.2', text:"施工营地工程", start_date:"2016-01-04",end_date:"2017-06-08", duration:365, progress:0.5, open:true,
            users:"Jane7",parent:2},
            {id:11,number:'1.2.1', text:"总包部临时营地(含临时医务室)", start_date:"2016-01-04",end_date:"2016-05-09", duration:90, progress:0.5, open:true,
            users:"Jane8",parent:10},
            {id:12,number:'1.2.2', text:"各工区临时营地建设", start_date:"2016-01-04",end_date:"2016-08-03", duration:151, progress:0.5, open:true,
            users:"Jane8",parent:10},
            {id:13,number:'1.2.3', text:"总包部临时营地(含临时医务室)", start_date:"2016-01-04",end_date:"2016-05-09", duration:90, progress:0.5, open:true,
            users:"Jane8",parent:10},
            {id:14,number:'1.2.4', text:"各工区临时营地建设", start_date:"2016-01-04",end_date:"2016-08-03", duration:151, progress:0.5, open:true,
            users:"Jane8",parent:10},
            {id:15,number:'1.2.5', text:"总包部临时营地(含临时医务室)", start_date:"2016-01-04",end_date:"2016-05-09", duration:90, progress:0.5, open:true,
            users:"Jane8",parent:10},
            {id:16,number:'1.2.6', text:"各工区临时营地建设", start_date:"2016-01-04",end_date:"2016-08-03", duration:151, progress:0.5, open:true,
            users:"Jane8",parent:10},
        ],
        links:[
            // {id:1, source:1, target:2, type:"1"},
            // {id:2, source:1, target:3, type:"1"},
            // {id:3, source:3, target:4, type:"1"},
            // {id:4, source:4, target:5, type:"0"},
            // {id:5, source:5, target:6, type:"0"}
        ]
      },
      tasks1:{
        data: [
          { id: 1, text: 'Task1', start_date: '2020-02-12', duration: 3, progress: 0.6 , user:'admin' },
          { id: 2, text: 'Task2', start_date: '2020-02-16', duration: 3, progress: 0.4 , user:'admin' }
        ],
        links: [
          { id: 1, source: 1, target: 2, type: '0' }
        ]
      },
      zoom:'Days'
    }
    this.winresize = this.winresize.bind(this)
    this.initzoom()
  }
  componentDidMount() {
    var ganttType = "3"
    if("1"==ganttType){	   	    
      //年		    	
      gantt.config.scale_unit = "year";	    	
      gantt.config.step = 1;	    	
      gantt.config.date_scale = "%Y";      	
    }else if("2"==ganttType){	    	 
      //月	        
      gantt.config.scale_unit = "year";	    	
      gantt.config.date_scale = "%Y";		    	
      gantt.config.scale_height = 50;		    	
      gantt.config.subscales = [	    		{unit:"month", step:1, date:"%F" }	    	];     	
    }else if("3"==ganttType){	    	
      //日	    	
      gantt.config.scale_unit = "month";			
      gantt.config.date_scale = "%F, %Y";					
      gantt.config.scale_height = 50;			
      gantt.config.subscales = [				{unit:"day", step:1, date:"%j" }			];			
      gantt.config.min_column_width = 20;     	
    }else if("4"==ganttType){		
      //周	    	
      var weekScaleTemplate = function(date){			
      var dateToStr = gantt.date.date_to_str("%d %M");				
      var endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");				
      return dateToStr(date) + " - " + dateToStr(endDate);			
    };				
    gantt.config.scale_unit = "week";			
    gantt.config.step = 1;			
    gantt.templates.date_scale = weekScaleTemplate;			
    gantt.config.subscales = [				{unit:"day", step:1, date:"%D" }			];			
    gantt.config.min_column_width = 20;			
    gantt.config.scale_height = 50;    	
  }


    window.addEventListener("resize", this.winresize)
    var clientHeight = document.documentElement.clientHeight
    var clientWidth = document.documentElement.clientWidth
    this.setState({
      clientHeight: clientHeight,
      clientWidth: clientWidth,
    })
    // document.getElementById('media').src= "../../../shipin.mp4"
    
    //初始化容器
    gantt.config.autofit = false //表格列宽自适应 默认false
    gantt.config.autoscroll = false //把任务或连线拖拽到浏览器屏幕外时,自动触发滚动效果,相当于拖动滚动条 默认false
    // gantt.config.autoscroll_speed = 50 //自动滚动的速度(ms) 
    // gantt.config.autosize = "xy" //自适应甘特图的尺寸大小,使得在不出现滚动条的情况下,显示全部任务。
    // gantt.config.autosize_min_width = 800 //使用图表宽度自适应模式(auto = 'x' or 'xy')时,图表的最小宽度
   
    // gantt.config.buttons_right=["gantt_delete_btn"]
    // gantt.config.buttons_left = ["gantt_save_btn","gantt_cancel_btn"]
    gantt.attachEvent("onGanttReady", function(){
      gantt.config.buttons_right = ["gantt_cancel_btn"];   
      gantt.config.buttons_left = [];               
   });
    // gantt.locale.labels["gantt_delete_btn"] = "删除";
    // gantt.locale.labels["gantt_save_btn"] = "保存";
    // gantt.locale.labels["gantt_cancel_btn"] = "取消";
    // gantt.config.min_column_width = 100;
    // gantt.config.scale_unit = 'month';
    // gantt.config.date_scale = '%F';

    gantt.attachEvent("onLightboxButton", function(button_id, node, e){
      // if(button_id == "complete_button"){
      //     var id = gantt.getState().lightbox;
      //     gantt.getTask(id).progress = 1;
      //     gantt.updateTask(id);
      //     gantt.hideLightbox();
      // }
    });

    //汉化配置
    gantt.locale={
      date: {
          month_full: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
          month_short: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
          day_full: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
          day_short: ["日", "一", "二", "三", "四", "五", "六"]
      },
      labels: {
          dhx_cal_today_button: "今天",
          day_tab: "日",
          week_tab: "周",
          month_tab: "月",
          new_event: "新建日程",
          icon_save: "保存",
          icon_cancel: "关闭",
          icon_details: "详细",
          icon_edit: "编辑",
          icon_delete: "删除",
          confirm_closing: "请确认是否撤销修改!", //Your changes will be lost, are your sure?
          confirm_deleting: "是否删除日程?",
          section_description: "描述",
          section_time: "时间范围",
          section_type: "类型",
  
          /* grid columns */
  
          column_text: "任务名",
          column_start_date: "开始时间",
          column_duration: "持续时间",
          column_add: "",
  
          /* link confirmation */
  
          link: "关联",
          confirm_link_deleting: "将被删除",
          link_start: " (开始)",
          link_end: " (结束)",
  
          type_task: "任务",
          type_project: "项目",
          type_milestone: "里程碑",
  
          minutes: "分钟",
          hours: "小时",
          days: "天",
          weeks: "周",
          months: "月",
          years: "年"
      }
    };



    gantt.config.columns = [
      {name:'number', label:'编码简称',resize :true, width: 90, tree:true},
      {name:'text', label:'名称',resize :true,width:100 },
      {name:'start_date', label:'计划开始', resize :true, align:'center',width:80},
      {name:'end_date', label:'计划结束', resize :true, align:'center',width:80},
      {name:'duration', label:'计划持续',resize :true,  align:'center',width:80},
      {name:'duration1', label:'依然持续',resize :true,  align:'center',width:80},
      // {name:'add', label:""}
    ]

    gantt.config.lightbox.sections=[
      // {name:"description", height:70, map_to:"text", type:"textarea"},
      {name:"time",  height:72, map_to:"auto", type:"duration",readonly:true}
    ];


    //设置时间格式,让输出的数据格式与dhtmlxGantt的格式相匹配
    gantt.config.xml_date = "%Y-%m-%d %H:%i" 
    gantt.config.drag_resize = true;//两边不可拖动 
    gantt.config.readonly = false;//只读模式


    // gantt.templates对象(定义模板的代码应该写在甘特图初始化之前)
    gantt.templates.task_text = function (start,end,task){
      // return "<b>名称:</b>"+task.text+",<b>Holders:</b>"+task.users;
      return "<b>名称：</b>"+task.text;
    }
    gantt.templates.task_class = function(start, end, task){
      return "taskcolor"
    }
    //控制不想添加子级任务
    gantt.templates.grid_row_class = function (start,end,task){
      if(task.$level > 0 ){
        return "nested_task"
      }
      if(task.id==2){
        return "bgColor"
      }
      return ""
    }
    //表格头部列表题背景
    // gantt.templates.grid_header_class = function(columnName, column){
    //   if(columnName == 'duration' ||columnName == 'text')
    //     return "updColor";
    // };



    //添加事件处理器 attachEvent方法 
    //事件名称大小写敏感 可以为同一个事件添加多个处理器
    // gantt.attachEvent("onTaskClick",function(id,e){
    //   // alert('点击行id是'+id)
    //   //在处理器内部访问gantt对象
    //   var parentId = this.getTask(id).parent
    //   console.log(parentId)
    // })
    //检查特定事件是否添加任何处理程序
    var res = gantt.checkEvent("onTaskClick");
    console.log(res) //true
    //移除事件处理器 detachEvents方法
    // var eventId = gantt.attachEvent("onTaskClick",function(id,e){
    //   alert('点击行id是'+id)
    // })
    // gantt.detachEvent(eventId)
    //移除所有事件处理器
    // gantt.detachAllEvents();

    gantt.init("gantt")
    gantt.parse(this.state.tasks);

    // var arr = $('.gantt_grid .gantt_grid_data .gantt_row_task')
    // for(let i=0 ;i<arr.length;i++){
    //   arr[i].style.top = i*34 +'px'
    // }

    // gantt.load(url,'json',function(data){
    //   //获取数据成功后,数据会自动渲染到页面
    //   console.log()
    // })
    
    //创建,修改,删除任务和关联关系
    var dp = gantt.createDataProcessor({
      task: {
        create: function(data){
          //新增任务
          // gantt.ajax.post(this.serverUrl+'/createData',data)
        },
        update: function(data,id){
          //更新任务
          // gantt.ajax.put(this.serverUrl+'/updateData',data)
        },
        delete: function(id){
          //删除任务
          // gantt.ajax.del(this.serverUrl+'/deleteData?id='+id)
        }
      },

      link: {
        create: function(data){

        },
        update: function(data,id){

        },
        delete: function(id){

        }
      }
    })
  }
  winresize() {
    var clientHeight = document.documentElement.clientHeight
    var clientWidth = document.documentElement.clientWidth

    this.setState({
      clientHeight: clientHeight,
      clientWidth: clientWidth,
    })
  }

  initzoom(){
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Hours',
          scale_height: 60,
          min_column_width: 30,
          scales: [
            { unit:'day', step:1,format:'%d %M' },
            { unit:'hour', step:1,format:'%H'}
          ]
        },
        {
          name: 'Days',
          scale_height:60,
          min_column_width: 70,
          scales: [
            { unit:'week', step:1, format: 'week #%w'},
            { unit:'day', step:1, format:'%d %M'}
          ]
        },
        {
          name: 'Months',
          scale_height:60,
          min_column_width: 70,
          scales: [
            { unit:'month', step:1, format:'%F'},
            { unit: 'week', step:1, format:'#%W'}
          ]
        }
      ]
    })
  }
  setZoom(value){
    gantt.ext.zoom.setLevel(value)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.winresize)
  }
  render() {
    const zoomRadios = ['Hours', 'Days', 'Months'].map((value) => {
      const isActive = this.state.zoom === value;
      return (
        <label key={ value } className={ `radio-label ${isActive ? 'radio-label-active': ''}` }>
          <input type='radio'
            checked={ isActive }
            onChange={(e)=>{ console.log(this.state.tasks); this.setState({zoom:e.target.value},function(){ this.setZoom(this.state.zoom)}) }}
            value={ value }/>
          { value }
        </label>
      );
    });
    return (
      <div className="constructionpage">
        {/* <div className="toolbar">
          { zoomRadios }
        </div> */}
        <h2>施工年度计划表</h2>
        <div className="constructionprogress">
          <div className="mygantt" id="gantt" ></div>
        </div>
      </div>
    )
  }
}
export default Seconddata;