export function MergeArray(arr1,arr2){
    var _arr = new Array();
    for(var i=0;i<arr1.length;i++){
       _arr.push(arr1[i]);
    }
    for(var i=0;i<arr2.length;i++){
        var flag = true;
        for(var j=0;j<arr1.length;j++){
            if(arr2[i]==arr1[j]){
                flag=false;
                break;
            }
        }
        if(flag){
            _arr.push(arr2[i]);
        }
    }
    return _arr;
}


export function MergeArray1(arr1,arr2){
    let length1 = arr1.length;
    let length2 = arr2.length;
    for (let i = 0; i < length1; i++) {
        for (let j = 0; j < length2; j++) 
        {
            //判断添加的数组是否为空了
            if (arr1.length > 0) {
                if (arr1[i]["ID"] === arr2[j]["ID"]) {
                    arr1.splice(i, 1); //利用splice函数删除元素，从第i个位置，截取长度为1的元素
                    length1--; 
                }
            }
        }

    } 
    
    // for (let n = 0; n < arr2.length; n++) {
    //     arr1.push(arr2[n]);
    // }
    return arr1
}

