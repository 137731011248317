import React from 'react';
import { Table, Input, Tabs, Tree, Tooltip, Collapse, Form, Button, Select, Progress, message, Popover, DatePicker } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import echarts from 'echarts';
import moment from 'moment'
import './style.scss'
// import "moment/locale/zh-cn"
// moment.locale('zh-cn')
import Draggable from 'react-draggable';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { Resizable } from 'react-resizable';
import store from '../../store';
const { Option } = Select;
const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;
var timeer;
var tmerspeed;

var alltmerspeed;
class ConditionInspection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: '',//厂
      //诊断模型树数据
      wisdomdiagnosistreeData: [],
      // checkedKeysA: [],//诊断模型复选
      // checkedKeysAfu: [],
      treeIdsele: '',

      selectedKeysA: [],//诊断模型选择
      equipmentName: '',//诊断设备名称
      EquipmentCode: '',//诊断设备编码

      //计算工具
      Software: [], //0: {softwareName: "B-BPDs"}1: {filePath: "C:\QY_Boiler.bpd"}

      //工况下拉框选择
      equipmentinitarr: [],//初始化数据
      equipmentselekey: '',

      //模型加载iframe
      moduleloadframesrc: '',
      //测点
      selectedKeysB: [],

      axiosgetcedianarr: [],//点击获取的单个测点组
      axiosgetcedian: '',

      testpointtreeData: [],
      testpointtreeDataobj: {},//测点选择obj

      //原理数据id点
      theorydataid: [],
      //原理数据计算结果
      theorydataanswer: [],

      //测点Guid 定位
      CedianGuidarr: [],//{Guid: "M1556089457019_*u003f*u003f*u003f*u003f*u003f.dgn.i.dgn*u0020-*u0020Default*u0020-*u0020142704"}
      CedianGuidsele: '',//测点Guid
      //测点单位和编号
      CedianUnitandCode: [], //{PrincipleData: "MTSuperheater_Steam_In_P", MatchingGroupUnit: "bar(a) "}

      //故障记录
      ErrorRecordarr: [],

      //5个模块
      activetabsKey: '1',
      //模型加载
      begincount: 0,
      loadingcount: 0,
      //拖拽
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      controlledPositionB: {
        x: 0, y: 0
      },

      //测点搜索
      searchValue: '',
      //测点是否选择过
      Cedianname: '',
      per_choose: '',
      KKS: '',
      CPostition: '',
      OutSqlName: '',
      MatchingGroupUnit: '',
      speed: '',
      speedtoline: '',
      //测点图
      cedianselexdata: [],
      cedianseledata: [],
      //实时曲线 历史曲线标识
      linebtnflag: '实时',
      cedianseleoption: {},
      cedianselehistoryoption: {},
      iddata: '',


      //专业宽高
      assetsviewleftw: 200,
      assetsviewcenterw: 220,

      //状态检测实时数据
      realtimedata: [],
      realtimedataposition: [[900, 0], [900, 70], [900, 140], [900, 210], [900, 280], [900, 350], [900, 420]],

      //定时器ID
      tmerspeedIDarr: [],
      Nameclicknum: 0,//第一级点击的次数

      //所有测点GUid
      alltmerspeedIDarr: [],
      allGuidarr: [],
      titleName: ""
    };
    //基础属性 供应商 责任相关 技术参数  文档关联
    this.receivemess = this.receivemess.bind(this)
    this.createElerender = this.createElerender.bind(this)
  }

  componentDidMount() {
    var service = sessionStorage.getItem('service')
    this.setState({
      service: service
    })
    axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=1').then(data => {
      //console.log('诊断模型树', conversion(data))
      this.setState({
        wisdomdiagnosistreeData: conversion(data)
      })
    })
    window.addEventListener('message', this.receivemess)
  }
  componentWillReceiveProps(nextprops) {
    ////console.log(nextprops)
    this.setState({
      clientHeight: nextprops.clientHeight,
      clientWidth: nextprops.clientWidth,
    })
  }
  receivemess(e) {
    //console.log(e.data)
    if (e.data == "COMPLETE") {
      var num = this.state.loadingcount
      num = num + 1
      if (num / this.state.begincount == 1) {
        this.setState({
          loadingcount: 0,
          begincount: 0,
        }, function () {
          this.refs.loadprogress.style.display = "none"
        })
      } else {
        this.setState({
          loadingcount: num
        }, function () {
          ////console.log(this.state.loadingcount / this.state.begincount)
          if (this.state.loadingcount / this.state.begincount == 1) {
            this.setState({
              loadingcount: 0,
              begincount: 0,
            }, function () {
              this.refs.loadprogress.style.display = "none"
            })
          }
        })
      }
    }
  }



  onSelectA(selectedKeys, info) {
    var arr1 = this.state.tmerspeedIDarr
    for (let i = 0; i < arr1.length; i++) {
      clearInterval(arr1[i])
    }
    var arr11 = this.state.alltmerspeedIDarr
    for (let i = 0; i < arr11.length; i++) {
      clearInterval(arr11[i])
    }

    var arr2 = this.state.realtimedata
    for (let j = 0; j < arr2.length; j++) {
      document.getElementById("moduleloadframe").contentWindow.DOMMark.remove(arr2[j].id);
    }
    //console.log(selectedKeys, info)
    //模型加载
    if (info.node.props.title == "流向系统") {
      this.setState({
        moduleloadframesrc: './BOS3dhtml/case/index.html',
        begincount: 0,
        loadingcount: 0
      })
      var moduleloadframe = document.getElementById("moduleloadframe")
      var _this = this
      moduleloadframe.onload = function () {
        _this.refs.loadprogress.style.display = "none"
      }
    } else {

      if (this.state.moduleloadframesrc == "./BOS3dhtml/areatree.html") {
        this.refs.loadprogress.style.display = "block"
        document.getElementById("moduleloadframe").contentWindow.viewer3D.viewerImpl.modelManager.unloadAll()
        axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=4&id=${selectedKeys[0]}`).then(data => {
          ////console.log(conversion(data))
          var data = conversion(data)
          //开始总数量
          if (data.length == 0) {
            this.refs.loadprogress.style.display = "none"
          } else {
            this.setState({
              begincount: data.length
            }, function () {
              for (let i = 0; i < data.length; i++) {
                document.getElementById("moduleloadframe").contentWindow.viewer3D.addView(data[i].ModelKey, "bole");
              }
            })
          }

        })
      } else {
        //诊断模型加载
        this.setState({
          moduleloadframesrc: `./BOS3dhtml/areatree.html`
        })

        var moduleloadframe = document.getElementById("moduleloadframe")
        var _this = this
        moduleloadframe.onload = function () {
          _this.refs.loadprogress.style.display = "block"

          var homeyjbtn = document.getElementById("moduleloadframe").contentWindow.document.getElementsByClassName('yj-but')[0]
          homeyjbtn.addEventListener('click', function () {
            document.getElementById("moduleloadframe").contentWindow.viewer3D.resetScene({ visible: false, view: true, selected: true, transparent: true, colorfully: true })
          })
          // var toolele = document.getElementById('moduleloadframe').contentWindow.document.getElementsByClassName('yj-tool')[0]
          // toolele.style.display = "none"
          axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=4&ID=${selectedKeys[0]}`).then(data => {
            ////console.log(conversion(data))
            var data = conversion(data)
            //开始总数量
            if (data.length == 0) {
              _this.refs.loadprogress.style.display = "none"
            } else {
              _this.setState({
                begincount: data.length
              }, function () {
                for (let i = 0; i < data.length; i++) {
                  document.getElementById("moduleloadframe").contentWindow.viewer3D.addView(data[i].ModelKey, "bole");
                }
              })
            }

          })
        }
      }
    }
    //选择
    this.setState({
      selectedKeysA: selectedKeys,//id
      equipmentName: info.node.props.title,
      EquipmentCode: info.node.props.code,
      tmerspeedIDarr: [],//定时器
      realtimedata: [],//检测实时数据
    }, function () {
      if (this.state.selectedKeysA[0]) {
        //测点数据
        axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=2', {
          ID: this.state.selectedKeysA[0]
        }).then(data => {
          // console.log('测点上级', conversion(data))
          this.setState({
            testpointtreeData: conversion(data)
          })
        })
        //获取计算设备的软件名称，以及计算文件
        axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=1', {
          EquipmentCode: this.state.EquipmentCode
        }).then(data => {
          // console.log('SoftwareName', conversion(data))
          this.setState({
            Software: conversion(data)
          }, function () {
            if (this.state.activetabsKey == "2") {
              //获取原理数据id点
              axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=4', {
                ID: this.state.selectedKeysA[0]
              }).then(data => {
                // console.log('获取原理数据id点', data)
                var arr = conversion(data);
                var theorydataidlen = arr.length;
                //获取原理数据计算结果
                axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=5', {
                  ID: this.state.selectedKeysA[0],
                  SoftwareCode: this.state.Software[0].SoftwareName
                }).then(data => {
                  // console.log('获取原理数据计算结果', data)
                  if (data.data.indexOf('&') > -1) {
                    var arr1 = data.data.split('&')
                    var theorydataanswerlen = arr1.length;
                    for (let i = 0; i < theorydataidlen; i++) {
                      var c_p = arr1[i].split(',')[1] //编码
                      var datac = arr1[i].split(',')[0] //数据
                      var order = arr1[i].split(',')[2] //排序
                      if (document.getElementById('Diagramshow')) {
                        var obj = document.getElementById('Diagramshow').contentWindow.document.getElementById(c_p)
                        // console.log(obj)
                        if (obj && obj.textContent) {
                          if (c_p == "QY2019201" || c_p == "QY2019203" || c_p == "QY2019205" || c_p == "QY2019207" || c_p == "QY2019209") {
                            var text = obj.textContent.split(",");
                            text[0] = datac
                            showdata = text[0]
                            obj.textContent = showdata
                          } else {
                            if (obj != null) {
                              var text = obj.textContent.split(",");
                              if (order == 1) {
                                text[0] = datac
                              }
                              if (order == 2) {
                                text[1] = datac
                              }
                              if (order == 3) {
                                text[2] = datac
                              }
                              var showdata = "";
                              if (text.length == 1) {
                                showdata = text[0] + ',' + "" + ',' + ""
                              } else {
                                for (var j = 0; j < 3; j++) {
                                  if (text[j] == "undefined")
                                    text[j] = ''
                                  if (j < 2) {
                                    showdata += text[j] + ','
                                  } else {
                                    showdata += text[j]
                                  }
                                }
                              }
                              obj.textContent = showdata
                            }
                          }
                        }
                      }
                    }
                    this.setState({
                      theorydataidlen: theorydataidlen,
                      theorydataid: arr,

                      theorydataanswerlen: theorydataanswerlen,
                      theorydataanswer: arr1,
                    })
                  }
                })
              })
            }
          })
        })
      }
    })
  }

  // 数组去重
  arruniq(array) {
    var temp = [];
    var index = [];
    var l = array.length;
    for (var i = 0; i < l; i++) {
      for (var j = i + 1; j < l; j++) {
        if (array[i] === array[j]) {
          i++;
          j = i;
        }
      }
      temp.push(array[i]);
      index.push(i);
    }
    return temp;
  }

  //测点选择
  onSelectB(selectedKeys, info) {
    // console.log(selectedKeys, info)
    if (selectedKeys[0] == "0-0") {

    } else {
      if (selectedKeys[0] && selectedKeys[0] == this.state.selectedKeysB[0]) { }
      else {
        if (selectedKeys[0]) {
          //高亮选择模块
          document.getElementById("moduleloadframe").contentWindow.viewer3D.resetScene({ colorfully: true, selected: true, transparent: true });

          if (info.node.props.Name) {
            var arr11 = this.state.alltmerspeedIDarr
            if (this.arruniq(arr11).length == this.state.Nameclicknum) {
              var Nameclicknum = this.state.Nameclicknum + 1
              this.setState({
                Nameclicknum: Nameclicknum
              })
              //清除单个测点
              var arr1 = this.state.tmerspeedIDarr
              if (arr1.length > 0) {
                for (let i = 0; i < arr1.length; i++) {
                  clearInterval(arr1[i])
                }
              }
              for (let i = 0; i < 10; i++) {
                document.getElementById("moduleloadframe").contentWindow.DOMMark.remove(i);
              }


              var arr11 = this.state.alltmerspeedIDarr
              if (arr11.length > 0) {
                for (let i = 0; i < arr11.length; i++) {
                  clearInterval(arr11[i])
                }
              }
              this.setState({
                selectedKeysB: selectedKeys,
                realtimedata: [],
                titleName: info.node.props.Name
              }, function () {

                //获取测点
                axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=1', {
                  ProfessionalTreeId: selectedKeys[0]
                }).then(data => {
                  //console.log('测点', conversion(data))
                  var arr = this.state.testpointtreeData
                  for (let i = 0; i < arr.length; i++) {
                    if (arr[i].ProfessionalTreeId == selectedKeys[0]) {
                      arr[i].children = conversion(data)
                    }
                  }
                  this.setState({
                    testpointtreeData: arr,
                    axiosgetcedianarr: conversion(data),
                    axiosgetcedian: selectedKeys[0]
                  }, function () {

                    //所有GUID
                    var allGuidarr = []

                    var axiosgetcedianarr = this.state.axiosgetcedianarr
                    var objdataarr = []
                    for (let i = 0; i < axiosgetcedianarr.length; i++) {
                      axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=10', {
                        KKS: axiosgetcedianarr[i].KKS
                      }).then(res => {
                        allGuidarr.push(conversion(res)[0].Guid)

                        if (conversion(res)[0].Guid) {
                          axios.get(`http://sppsapi.bolesoftware.com/GetInstantaneousvValues?id=${axiosgetcedianarr[i].OPCId}`).then(res1 => {
                            var jsspeeddata = conversion(res1)[0].Value
                            var objdata = {
                              key: axiosgetcedianarr[i].ID,
                              OPCId: axiosgetcedianarr[i].OPCId,
                              id: 0,
                              speed: jsspeeddata,
                              MatchingGroupUnit: axiosgetcedianarr[i].MatchingGroupUnit,
                              CedianGuidsele: conversion(res)[0].Guid,
                              Cedianname: axiosgetcedianarr[i].MatchingGroupName,
                              position: [900, 210]
                            }
                            objdataarr.push(objdata)

                            if (objdataarr.length == axiosgetcedianarr.length) {
                              // console.log('请求完毕', objdataarr)
                              var domElement = document.createElement("div")
                              domElement.style.whiteSpace = "pre"
                              domElement.style.padding = "10px"
                              domElement.style.fontSize = "12px"
                              var str = ``
                              for (let m = 0; m < objdataarr.length; m++) {
                                str = str + `\n ${objdataarr[m].Cedianname + ' : ' + objdataarr[m].speed + '' + objdataarr[m].MatchingGroupUnit}`
                              }
                              domElement.textContent = str
                              var xyz = document.getElementById("moduleloadframe").contentWindow.viewer3D.getBoxByComponentsKey(objdataarr[0].CedianGuidsele)

                              var options = {
                                id: 0,
                                title: this.state.titleName,
                                color: [99, 99, 99],
                                colorLine: [0, 134, 139],
                                startPosition: [(xyz.max.x + xyz.min.x) / 2, (xyz.max.y + xyz.min.y) / 2, (xyz.max.z + xyz.min.z) / 2],
                                endPosition: objdata.position,
                                domElement: domElement
                              }
                              document.getElementById("moduleloadframe").contentWindow.DOMMark.add(options, function (a) {
                                var s = `labelTitle${a}`;
                                document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.color = "#fff"
                                document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.width = "176px"
                                document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.fontSize = "14px"
                                document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.padding = "10px 10px 0 10px"
                                document.getElementById("moduleloadframe").contentWindow.document.getElementById(`0`).style.color = "#fff"
                              });

                              this.setState({
                                allGuidarr: allGuidarr
                              }, function () {
                                var _this = this
                                alltmerspeed = setInterval(function () {

                                  var alltmerspeedIDarr = _this.state.alltmerspeedIDarr

                                  if (alltmerspeed) {
                                    alltmerspeedIDarr.push(alltmerspeed)
                                    _this.setState({
                                      alltmerspeedIDarr: alltmerspeedIDarr
                                    })
                                  }
                                  var axiosgetcedianarrtimer = _this.state.axiosgetcedianarr
                                  var allGuidarr = _this.state.allGuidarr
                                  var objdataarr1 = []
                                  for (let j = 0; j < axiosgetcedianarrtimer.length; j++) {
                                    axios.get(`http://sppsapi.bolesoftware.com/GetInstantaneousvValues?id=${axiosgetcedianarrtimer[j].OPCId}`).then(res2 => {
                                      var jsspeeddata = conversion(res2)[0].Value
                                      var objdata1 = {
                                        key: axiosgetcedianarrtimer[j].ID,
                                        OPCId: axiosgetcedianarrtimer[j].OPCId,
                                        id: 0,
                                        speed: jsspeeddata,
                                        MatchingGroupUnit: axiosgetcedianarrtimer[j].MatchingGroupUnit,
                                        CedianGuidsele: allGuidarr[j],
                                        Cedianname: axiosgetcedianarrtimer[j].MatchingGroupName,
                                        position: [900, 210]
                                      }
                                      objdataarr1.push(objdata1)

                                      if (objdataarr1.length == axiosgetcedianarrtimer.length) {
                                        var domElement = document.createElement("div")
                                        domElement.style.whiteSpace = "pre"
                                        domElement.style.padding = "10px"
                                        domElement.style.fontSize = "12px"
                                        var str = ``
                                        for (let n = 0; n < objdataarr1.length; n++) {
                                          str = str + `\n ${objdataarr1[n].Cedianname + ' : ' + objdataarr1[n].speed + '' + objdataarr1[n].MatchingGroupUnit}`
                                        }
                                        domElement.textContent = str
                                        if (document.getElementById("moduleloadframe")) {
                                          var xyz1 = document.getElementById("moduleloadframe").contentWindow.viewer3D.getBoxByComponentsKey(objdataarr1[0].CedianGuidsele)

                                          document.getElementById("moduleloadframe").contentWindow.DOMMark.remove(0);
                                          var options1 = {
                                            id: 0,
                                            title: _this.state.titleName,
                                            color: [99, 99, 99],
                                            colorLine: [0, 134, 139],
                                            startPosition: [(xyz1.max.x + xyz1.min.x) / 2, (xyz1.max.y + xyz1.min.y) / 2, (xyz1.max.z + xyz1.min.z) / 2],
                                            endPosition: objdata1.position,
                                            domElement: domElement
                                          }
                                          document.getElementById("moduleloadframe").contentWindow.DOMMark.add(options1, function (a) {
                                            var s = `labelTitle${a}`;
                                            document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.color = "#fff"
                                            document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.width = "176px"
                                            document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.fontSize = "14px"
                                            document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.padding = "10px 10px 0 10px"
                                            document.getElementById("moduleloadframe").contentWindow.document.getElementById(`0`).style.color = "#fff"
                                          });
                                        }

                                      }
                                    })
                                  }
                                }, 6000)
                              })
                            }
                          })
                        }
                      })
                    }
                  })
                })
                //定位 
                axios.get(`/DATA/TdModel/OperateShowModel.aspx?flag=7&ID=${selectedKeys[0]}`).then(res => {
                  // console.log(res)
                  if (conversion(res)[0] && conversion(res)[0].ModelKey) {
                    document.getElementById("moduleloadframe").contentWindow.viewer3D.transparentOtherModelsByKey(conversion(res)[0].ModelKey);
                  }
                })
              })
            }

          } else if (info.node.props.KKS) {
            var arr11 = this.state.alltmerspeedIDarr
            // console.log('一级定时器', arr11, this.arruniq(arr11), this.state.Nameclicknum)
            if (this.arruniq(arr11).length == this.state.Nameclicknum) {
              for (let i = 0; i < arr11.length; i++) {
                clearInterval(arr11[i])
              }
              this.setState({
                selectedKeysB: selectedKeys,
              }, function () {
                this.refs.cepointechartstuzhu.style.display = "none"
                this.setState({
                  KKS: info.node.props.KKS,
                  Cedianname: info.node.props.title,
                  CPostition: info.node.props.CPostition,
                  OutSqlName: info.node.props.OutSqlName,
                  MatchingGroupUnit: info.node.props.MatchingGroupUnit,
                  OPCId: info.node.props.OPCId
                }, function () {
                  if (this.state.OPCId == "") {
                    //console.log('空')
                  }
                  else {

                    //判断是否选择过
                    var realtimedataarr = this.state.realtimedata
                    var flag = 0
                    if (realtimedataarr.length > 0) {
                      for (let i = 0; i < realtimedataarr.length; i++) {
                        if (realtimedataarr[i].key == this.state.selectedKeysB[0]) {
                          flag = 1
                        }
                      }
                    }

                    if (flag == 0) {
                      //测点Guid 定位3D模型
                      axios.post('/DATA/TdModel/RelevantInformation.aspx?flag=10', {
                        KKS: info.node.props.KKS
                      }).then(data => {
                        //console.log('测点Guid', conversion(data))
                        this.setState({
                          CedianGuidarr: conversion(data),
                          CedianGuidsele: conversion(data)[0] ? conversion(data)[0].Guid : ""
                        }, function () {
                          if (this.state.CedianGuidarr[0]) {
                            document.getElementById('moduleloadframe').contentWindow.viewer3D.transparentOtherComponentsByKey(this.state.CedianGuidsele)
                            document.getElementById('moduleloadframe').contentWindow.viewer3D.highlightComponentsByKey(this.state.CedianGuidsele)
                            document.getElementById('moduleloadframe').contentWindow.viewer3D.zoomToBox(document.getElementById('moduleloadframe').contentWindow.viewer3D.getBoxByComponentsKey(this.state.CedianGuidsele), 2888, 1);


                            axios.get(`http://sppsapi.bolesoftware.com/GetInstantaneousvValues?id=${this.state.OPCId}`).then(data => {
                              //console.log(conversion(data)[0].Value)
                              var jsspeeddata = conversion(data)[0].Value
                              var arr = this.state.realtimedata
                              var arrposition = this.state.realtimedataposition

                              if (arr.length == 7) {
                                arr.shift()
                                for (let i = 0; i < arr.length; i++) {
                                  arr[i].id = arr[i].id - 1
                                  arr[i].position = arrposition[i]
                                }
                                var objdata = {
                                  key: selectedKeys[0],
                                  OPCId: info.node.props.OPCId,
                                  id: 7,
                                  speed: jsspeeddata,
                                  MatchingGroupUnit: info.node.props.MatchingGroupUnit,
                                  CedianGuidsele: this.state.CedianGuidsele,
                                  Cedianname: info.node.props.title,
                                  position: arrposition[6]
                                }
                                arr.push(objdata)
                              } else {
                                var objdata = {
                                  key: selectedKeys[0],
                                  OPCId: info.node.props.OPCId,
                                  id: Number(arr.length) + 1,
                                  speed: jsspeeddata,
                                  MatchingGroupUnit: info.node.props.MatchingGroupUnit,
                                  CedianGuidsele: this.state.CedianGuidsele,
                                  Cedianname: info.node.props.title,
                                  position: arrposition[Number(arr.length)]
                                }
                                arr.push(objdata)
                              }

                              this.setState({
                                speed: jsspeeddata,
                                speedtoline: jsspeeddata,
                                realtimedata: arr
                              }, function () {
                                var _this = this
                                //console.log(this.state.CedianGuidsele)
                                if (this.state.CedianGuidsele) {
                                  this.createElerender(objdata)
                                  tmerspeed = setInterval(function () {
                                    var arr1 = _this.state.realtimedata
                                    // console.log('定时器',arr1)
                                    for (let i = 0; i < arr1.length; i++) {
                                      axios.get(`http://sppsapi.bolesoftware.com/GetInstantaneousvValues?id=${arr1[i].OPCId}`).then(data => {
                                        arr1[i].speed = conversion(data)[0].Value
                                        _this.setState({
                                          realtimedata: arr1
                                        }, function () {
                                          _this.createElerender(arr1[i])
                                        })
                                      })
                                    }
                                  }, 6000)
                                }
                              })
                            })
                          }
                        })
                      })
                    }
                  }
                })
              })
            }
          }
        } else {
          this.setState({
            selectedKeysB: []
          })
        }
      }
    }
  }
  createElerender(obj) {
    document.getElementById("moduleloadframe").contentWindow.DOMMark.remove(0);

    var domElement = document.createElement("div")
    domElement.style.padding = "10px"
    domElement.style.fontSize = "12px"
    domElement.textContent = `${obj.speed + '' + obj.MatchingGroupUnit}`
    // domElement.style.color="#fff"
    var xyz = document.getElementById("moduleloadframe").contentWindow.viewer3D.getBoxByComponentsKey(obj.CedianGuidsele)

    document.getElementById("moduleloadframe").contentWindow.DOMMark.remove(obj.id);

    var options = {
      id: obj.id,
      // title: obj.Cedianname.substring(0, obj.Cedianname.length - 2),
      title: obj.Cedianname,
      color: [99, 99, 99],
      colorLine: [0, 134, 139],
      startPosition: [(xyz.max.x + xyz.min.x) / 2, (xyz.max.y + xyz.min.y) / 2, (xyz.max.z + xyz.min.z) / 2],
      endPosition: obj.position,
      domElement: domElement
    }
    document.getElementById("moduleloadframe").contentWindow.DOMMark.add(options, function (a) {
      var s = `labelTitle${a}`;
      document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.color = "#fff"
      document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.width = "176px"
      document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.fontSize = "14px"
      document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${s}`).style.padding = "10px 10px 0 10px"
      document.getElementById("moduleloadframe").contentWindow.document.getElementById(`${obj.id}`).style.color = "#fff"
    });
    // console.log('ID', tmerspeed)
    var tmerspeedIDarr = this.state.tmerspeedIDarr
    if (tmerspeed) {
      tmerspeedIDarr.push(tmerspeed)
      this.setState({
        tmerspeedIDarr: tmerspeedIDarr
      })
    }

  }
  //测点列表搜索
  searchkks(value) {
    //console.log(value)
    if (this.state.axiosgetcedian) {
      var arr = this.state.axiosgetcedianarr
      var newarr = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].Name.indexOf(value) > -1) {
          newarr.push(arr[i])
        }
      }
      var arr1 = this.state.testpointtreeData
      //console.log(arr1)
      for (let j = 0; j < arr1.length; j++) {
        if (arr1[j].ID == this.state.axiosgetcedian) {
          arr1[j].children = newarr
        }
      }
      this.setState({
        searchValue: value,
        testpointtreeData: arr1
      })
    } else {

    }
  }
  //装配树收缩
  zhuangpeishushou() {
    this.refs.wisdomdiagnosisleft.style.display = "none"
    this.refs.treeshoufangzhang.style.display = "block"
    if ($('.wisdomdiagnosiscenter').css("display") == "block") {
      $('.treeshoufang').css("left", this.state.assetsviewcenterw + 10 + 'px')
    } else if ($('.wisdomdiagnosiscenter').css("display") == "none") {
      $('.treeshoufang').css("left", "10px")
    }
  }
  zhuangpeishufang() {
    this.refs.wisdomdiagnosisleft.style.display = "block"
    this.refs.treeshoufangzhang.style.display = "none"
    if ($('.wisdomdiagnosiscenter').css("display") == "none") {
      $('.treeshoufang').css("left", this.state.assetsviewleftw + 10 + 'px')
    }
  }
  kksshushou() {
    this.refs.wisdomdiagnosiscenter.style.display = "none"
    this.refs.treeshoufangkks.style.display = "block"
    if ($('.wisdomdiagnosisleft').css("display") == "block") {
      $('.treeshoufang').css("left", this.state.assetsviewleftw + 10 + 'px')
    } else if ($('.wisdomdiagnosisleft').css("display") == "none") {
      $('.treeshoufang').css("left", "10px")
    }
  }
  kksshufang() {
    this.refs.wisdomdiagnosiscenter.style.display = "block"
    this.refs.treeshoufangkks.style.display = "none"
    if ($('.wisdomdiagnosisleft').css("display") == "none") {
      $('.treeshoufang').css("left", this.state.assetsviewcenterw + 10 + 'px')
    }
  }

  //打开测点对应图线
  cepointechartstuzhu() {
    if (this.state.selectedKeysB[0]) {
      if (this.state.OPCId) {
        axios.get(`http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=${this.state.OPCId}&num=10`).then(data => {
          var arr = conversion(data)
          var data = []
          var datax = []
          for (let i = arr.length - 1; i >= 0; i--) {
            data.push(arr[i].Value)
            datax.push(arr[i].Time.split(' ')[1])
          }
          var cedianseleoption = {
            grid: {
              left: '15%',
              right: '10%',
              top: '12%',
              bottom: '16%',
              scontainLabel: true
            },
            tooltip: {
              trigger: 'item',
              // position: [50, 20],
              formatter: "{c}"
            },
            // title: {
            //   text: '特性示例：渐变色 阴影 点击缩放',
            //   subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
            // },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: datax,
              axisLabel: {
                interval: 0,
                show: true,
                textStyle: {
                  color: '#fff',  //更改坐标轴文字颜色
                  fontSize: 12      //更改坐标轴文字大小
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              }
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#fff',  //更改坐标轴文字颜色
                  fontSize: 12      //更改坐标轴文字大小
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              },
              min: function (value) {
                return value.min - 1;
              }
            },
            dataZoom: [
              {
                type: 'inside'
              }
            ],
            series: [
              {
                name: this.state.Cedianname,
                type: 'line',
                stack: '总量1',
                data: data
              },
            ]
          };
          this.setState({
            cedianseleoption: cedianseleoption,
            cedianselexdata: datax,
            cedianseledata: data
          }, function () {
            this.refs.cepointechartstuzhu.style.display = "block"
            //基于准备好的dom，初始化echarts实例
            var myChart1 = echarts.init(document.getElementById('echartstulinecedian'));
            // 绘制图表
            myChart1.setOption(this.state.cedianseleoption);
            // //console.log(arrdata,timearr)
            var _this = this
            timeer = setInterval(function () {
              axios.get(`http://sppsapi.bolesoftware.com/GetInstantaneousvValues?id=${_this.state.OPCId}`).then(data => {
                var arrx = _this.state.cedianselexdata
                var arr = _this.state.cedianseledata
                arrx.shift()
                arr.shift()
                arrx.push(conversion(data)[0].Time.split(' ')[1])
                arr.push(conversion(data)[0].Value)
                var cedianseleoption = {
                  grid: {
                    left: '15%',
                    right: '10%',
                    top: '12%',
                    bottom: '16%',
                    scontainLabel: true
                  },
                  tooltip: {
                    trigger: 'item',
                    // position: [50, 20],
                    formatter: "{c}"
                  },
                  // title: {
                  //   text: '特性示例：渐变色 阴影 点击缩放',
                  //   subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
                  // },
                  xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: arrx,
                    axisLabel: {
                      interval: 0,
                      show: true,
                      textStyle: {
                        color: '#fff',  //更改坐标轴文字颜色
                        fontSize: 12      //更改坐标轴文字大小
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#fff' //更改坐标轴颜色
                      }
                    }
                  },
                  yAxis: {
                    type: 'value',
                    axisLabel: {
                      show: true,
                      textStyle: {
                        color: '#fff',  //更改坐标轴文字颜色
                        fontSize: 12      //更改坐标轴文字大小
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#fff' //更改坐标轴颜色
                      }
                    },
                    min: function (value) {
                      return value.min - 1;
                    }
                  },
                  dataZoom: [
                    {
                      type: 'inside'
                    }
                  ],
                  series: [
                    {
                      name: _this.state.Cedianname,
                      type: 'line',
                      stack: '总量1',
                      data: arr
                    },
                  ]
                };
                _this.setState({
                  cedianseleoption: cedianseleoption,
                  cedianselexdata: arrx,
                  cedianseledata: arr
                }, function () {
                  //基于准备好的dom，初始化echarts实例
                  var myChart1 = echarts.init(document.getElementById('echartstulinecedian'));
                  // 绘制图表
                  myChart1.setOption(_this.state.cedianseleoption);
                })
              })
            }, 6000)
          })

        })
      } else {
        message.error('OPC不存在！')
      }
    } else {
      message.error('请选择测点！')
    }
  }

  componentWillUnmount() {

    var arr1 = this.state.tmerspeedIDarr
    for (let i = 0; i < arr1.length; i++) {
      clearInterval(arr1[i])
    }
    var arr11 = this.state.alltmerspeedIDarr
    for (let i = 0; i < arr11.length; i++) {
      clearInterval(arr11[i])
    }
    clearInterval(timeer)
    clearInterval(tmerspeed)
    clearInterval(alltmerspeed)
  }
  //关闭测点柱状图
  closeechartstuzhu() {
    this.refs.cepointechartstuzhu.style.display = "none"
    clearInterval(timeer)
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  handleDragB(e, ui) {
    const { x, y } = this.state.controlledPositionB;
    this.setState({
      controlledPositionB: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  toviewhistoryline() {

  }

  tochangelinebtn(key) {
    this.setState({
      linebtnflag: key
    }, function () {
      if (this.state.linebtnflag == "历史") {
        // .toUTCString()
        var currentDate = (new Date())
        var fronttwoHour = (new Date(new Date().getTime() - 2 * 60 * 60 * 1000));
        //console.log(currentDate, fronttwoHour)
        var currenttime = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + '-' + currentDate.getDate() + ' ' + currentDate.getHours() + ':' + currentDate.getMinutes() + ":" + currentDate.getSeconds()
        var twohouragotime = fronttwoHour.getFullYear() + "-" + (fronttwoHour.getMonth() + 1) + '-' + fronttwoHour.getDate() + ' ' + fronttwoHour.getHours() + ':' + fronttwoHour.getMinutes() + ":" + fronttwoHour.getSeconds()

        axios.get(`http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyTime?id=${this.state.OPCId}&time1=${twohouragotime}&time2=${currenttime}`).then(data => {
          //console.log('历史数据', conversion(data))
          var arr = conversion(data)
          var data = []
          var datax = []
          for (let i = 0; i < arr.length; i++) {
            data.push(arr[i].Value)
            datax.push(arr[i].Time)
          }

          var cedianselehistoryoption = {
            tooltip: {
              trigger: 'axis',
              position: function (pt) {
                return [pt[0], '10%'];
              }
            },
            toolbox: {
              show: false,
              feature: {
                dataZoom: {
                  yAxisIndex: 'none'
                },
                restore: {},
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: datax,
              axisLabel: {
                textStyle: {
                  color: '#fff'
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              }
            },
            yAxis: {
              type: 'value',
              boundaryGap: [0, '100%'],
              axisLabel: {
                textStyle: {
                  color: '#fff'
                }
              },
              min: function (value) {
                return value.min - 1;
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              }
            },
            dataZoom: [{
              type: 'inside',
              start: 0,
              end: 10
            }, {
              start: 0,
              end: 10,
              handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
              handleSize: '80%',
              handleStyle: {
                color: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowOffsetX: 2,
                shadowOffsetY: 2
              }
            }],
            series: [
              {
                // name: '模拟数据',
                type: 'line',
                smooth: true,
                symbol: 'none',
                sampling: 'average',
                itemStyle: {
                  color: 'rgb(255, 70, 131)'
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgb(255, 158, 68)'
                  }, {
                    offset: 1,
                    color: 'rgb(255, 70, 131)'
                  }])
                },
                data: data
              }
            ]
          };
          this.setState({
            cedianselehistoryoption
          }, function () {
            var myChart2 = echarts.init(document.getElementById('echartstulinecedianother'));
            // 绘制图表
            myChart2.setOption(this.state.cedianselehistoryoption);

          })
        })
        $('#echartstulinecedian').css("display", "none")
        $('#echartstulinecedianother').css("display", "block")
      } else {
        $('#echartstulinecedian').css("display", "block")
        $('#echartstulinecedianother').css("display", "none")
      }
    })
  }
  render() {
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (

      <section className="conditioninspection" style={{ height: clientHeight - 50 + 'px' }}>
        {/* 曲线 */}
        <Draggable
          position={this.state.controlledPositionB}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragB.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="cepointechartstuline" ref="cepointechartstuzhu">
            <h2>{this.state.Cedianname}图示 <span onClick={this.closeechartstuzhu.bind(this)}>x</span></h2>
            <span className="linebtn">
              {
                this.state.linebtnflag == "实时" ?
                  <Button style={{ background: '#00a0e9' }} onClick={this.tochangelinebtn.bind(this, '实时')}>实时曲线</Button> :
                  <Button onClick={this.tochangelinebtn.bind(this, '实时')}>实时曲线</Button>
              }
              {/* {
                this.state.linebtnflag == "历史" ?
                  <Button style={{ background: '#00a0e9' }} onClick={this.tochangelinebtn.bind(this, '历史')}>历史曲线</Button> :
                  <Button onClick={this.tochangelinebtn.bind(this, '历史')}>历史曲线</Button>
              } */}
            </span>
            <div id="echartstulinecedian">

            </div>
            <div id="echartstulinecedianother">

            </div>
          </div>
        </Draggable>

        <div className="wisdomdiagnosisleftcenter" style={{ height: clientHeight - 50 + 'px' }}>
          <Resizable className="box" axis="x" minConstraints={[100, 100]} maxConstraints={[300, 300]} height={clientHeight - 50} width={this.state.assetsviewleftw}
            onResize={(event, { element, size }) => {
              this.setState({ assetsviewleftw: size.width, assetsviewcenterw: 420 - size.width });
            }}>
            <div className="wisdomdiagnosisleft" ref="wisdomdiagnosisleft" style={{ height: clientHeight - 50 + 'px', width: this.state.assetsviewleftw + 'px' }}>
              <h2 className="zhuangpeishu">
                <span>诊断模型</span>
                <span onClick={this.zhuangpeishushou.bind(this)} className="zhuangpeishushousuo"></span>
              </h2>
              <div className="wisdomdiagnosislefttree" style={{ height: clientHeight - 50 - 40 + 'px' }}>
                <Tree
                  // checkable
                  // onCheck={this.onCheckA.bind(this)}
                  // checkedKeys={this.state.checkedKeysA}
                  onSelect={this.onSelectA.bind(this)}
                  selectedKeys={this.state.selectedKeysA}
                >
                  {
                    this.state.wisdomdiagnosistreeData.map((item, index) => {
                      return (<TreeNode title={item.Name} key={item.ID} Code={item.Code}></TreeNode>)
                    })
                  }
                </Tree>
              </div>
            </div>

          </Resizable>

          <div className="wisdomdiagnosiscenter" ref="wisdomdiagnosiscenter" style={{
            height: clientHeight - 50 + 'px', width: this.state.assetsviewcenterw + 'px'
          }}>
            <h2>
              <span className="left">测点列表</span>
              <span className="right">
                <span className="right2" onClick={this.cepointechartstuzhu.bind(this)}><img src={require('../../assets/img1/曲线.png')} /></span>
                <span className="kksshousuo" onClick={this.kksshushou.bind(this)}>
                </span>
              </span>
            </h2>

            {/* <div className="cediansearch">
              <Search
                placeholder="请输入关键字搜索"
                onSearch={this.searchkks.bind(this)}
                style={{ width: "100%", height: '30px', background: "none" }}
              />
            </div> */}
            <div className="tree" style={{ height: clientHeight - 50 - 50 + 'px', overflow: "auto" }}>
              <Tree
                onSelect={this.onSelectB.bind(this)}
                selectedKeys={this.state.selectedKeysB}
              >
                {
                  this.state.equipmentName ?
                    <TreeNode title={this.state.equipmentName} key="0-0">
                      {
                        this.state.testpointtreeData.map((item, index) => {
                          return (
                            <TreeNode title={<Tooltip placement="right" title={item.Name}>{item.Code}</Tooltip>}
                              key={item.ProfessionalTreeId} Name={item.Name}
                              className={item.Flag == "1" ? "flageq1" : ""}
                            >
                              {
                                item.children && item.children.length > 0 ?
                                  item.children.map((item, index) => {
                                    return (
                                      <TreeNode title={item.MatchingGroupName}
                                        style={{ color: item.MatchingGroupName.indexOf(this.state.searchValue) > -1 ? "#f00" : "#fff" }}
                                        key={item.ID} KKS={item.KKS}
                                        OutSqlName={item.OutSqlName}
                                        MatchingGroupUnit={item.MatchingGroupUnit}
                                        OPCId={item.OPCId}
                                        CPostition={item.CPostition}></TreeNode>
                                    )
                                  }) : ""
                              }
                            </TreeNode>
                          )
                        })
                      }
                    </TreeNode>
                    : ""
                }
              </Tree>
            </div>
          </div>

        </div>

        <div className="moduleload" style={{ height: clientHeight - 50 + 'px', width: clientWidth + 'px' }}>
          <div className="loadprogress" ref="loadprogress">
            <Progress strokeWidth={15} type="circle" percent={parseInt((this.state.loadingcount / this.state.begincount) * 100)} status={(this.state.loadingcount / this.state.begincount) == 1 ? "success" : 'active'} />
          </div>
          <iframe title="myiframe" src={this.state.moduleloadframesrc} id="moduleloadframe" style={{
            width: "100%", height: "100%",
          }}></iframe>
        </div>

        <div className="treeshoufang">
          <span ref="treeshoufangzhang" onClick={this.zhuangpeishufang.bind(this)}>诊断模型</span>
          <span ref="treeshoufangkks" onClick={this.kksshufang.bind(this)}>测点列表</span>
        </div>
      </section >
    )
  }
}
export default ConditionInspection;

