import React, { Fragment } from 'react';
import './style.scss'
import { Icon, Upload, message } from 'antd';
import {changeconfigimage} from '../../store/actionCreators'
import '../../store'
import { conversion } from '../../converse';
import axios from 'axios'
import store from '../../store';
import $ from 'jquery'
class ProConfig extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      loading: false,
      imageUrl:store.getState().ProjectconfigimageUrl,
      imageFlag: "0",
      factoryinfo:""
    }
    this.getBase64 = this.getBase64.bind(this)
    this.beforeUpload = this.beforeUpload.bind(this)
    this.checkImageWH = this.checkImageWH.bind(this)
  }

  componentDidMount() {
    var factoryinfo = sessionStorage.getItem('factory')
    this.setState({
      factoryinfo
    })
  }
   //图片上传
   getBase64(img, callback) {
    //console.log(img, callback)
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  //限制上传图片的格式和大小
  beforeUpload(file) {
    console.log(file)
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('超过10M限制，不允许上传!');
    }
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    const isBMP = file.type === 'image/bmp';
    if (!(isJPG || isJPEG || isGIF || isPNG || isBMP)) {
      message.error('只能上传JPG 、JPEG 、GIF、 PNG、 BMP格式的图片!')
    }else{
      return (isBMP || isGIF || isJPEG || isJPG || isPNG) && isLt2M && this.checkImageWH(file);
    }
  }
  //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
  checkImageWH(file) {    
    let self = this;    
    return new Promise(function(resolve, reject) {      
      let filereader = new FileReader();      
      filereader.onload = e => {        
        let src = e.target.result;        
        const image = new Image();        
        image.onload = function() {          
          // 获取图片的宽高，并存放到file对象中          
          console.log('file width :' + this.width);          
          console.log('file height :' + this.height);          
          file.width = this.width;          
          file.height = this.height;          
          resolve();        
        };        
        image.onerror = reject;        
        image.src = src;      
      };      
      filereader.readAsDataURL(file);    
    });  
  }

  handletuChange = (info) => {
    console.log(info)
    if (info.file.status === 'uploading') {
      this.setState({ loading: true, imageUrl: "" });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => this.setState({
        imageUrl,
        imageFlag: '1'
      }, function () {
        console.log(this.state.imageUrl)
        axios.get('/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=1').then(res=>{
          console.log(conversion(res))
          if(conversion(res)[0] && conversion(res)[0].CompletPathProjectPhoto){
            var ProjectconfigimageUrl = conversion(res)[0].CompletPathProjectPhoto
            var CompanyconfigimageUrl = conversion(res)[0].CompletPathCompanyPhoto
            var configID =  conversion(res)[0].ID
            var configVersion =  conversion(res)[0].RecordVersion
            const action = changeconfigimage(ProjectconfigimageUrl,CompanyconfigimageUrl,configID,configVersion)
            store.dispatch(action)
          }
        })
        this.setState({
          loading: false,
        })
        message.success(`${info.file.name} 上传成功`);
        // axios.post('/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=2',{
        //   CompletPathProjectPhoto:this.state.imageUrl,
        //   ID:store.getState().configID,
        //   RecordVersion:store.getState().configVersion
        // }).then(res=>{
        //   console.log(res)
        //   if(res.data==true){
        //     axios.get('/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=1').then(res=>{
        //       console.log(conversion(res))
        //       if(conversion(res)[0] && conversion(res)[0].CompletPathProjectPhoto){
        //         var ProjectconfigimageUrl = conversion(res)[0].CompletPathProjectPhoto
        //         var CompanyconfigimageUrl = conversion(res)[0].CompletPathCompanyPhoto
        //         var configID =  conversion(res)[0].ID
        //         var configVersion =  conversion(res)[0].RecordVersion
        //         const action = changeconfigimage(ProjectconfigimageUrl,CompanyconfigimageUrl,configID,configVersion)
        //         store.dispatch(action)
        //       }
        //     })
        //     this.setState({
        //       loading: false,
        //     })
        //     message.success(`${info.file.name} 上传成功`);
        //   }else{
        //     message.error(`${info.file.name} 上传失败`);
        //   }
        // })
      }));
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 上传失败`);
    }
  }
  render() {
    const  imageUrl  = store.getState().ProjectconfigimageUrl
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    if(imageUrl){
      $('.imageupload .ant-upload').css("border","none")
    }else{
      $('.imageupload .ant-upload').css("border","1px solid #d9d9d9")
    }
    return (
      <div className="configmanage">
      <div className="imageupload" style={{
        width:clientHeight>900?"600px":clientHeight>600?"400px":"200px",
        height:clientHeight>900?"500px":clientHeight>600?"300px":"200px"}}>
             <Upload
                  name=""
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={'/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=2&CompletPathProjectPhoto=1&ID='+store.getState().configID+'&RecordVersion='+store.getState().configVersion}
                  beforeUpload={this.beforeUpload}
                  onChange={this.handletuChange}
                >{
              this.state.imageUrl ?
                <img src={this.state.imageUrl} alt="" />
                :
               
                        <div>
                          <Icon type={this.state.loading ? 'loading' : 'plus'} />
                          <div className="ant-upload-text">点击此处上传照片</div>
                        </div>
            }
                </Upload>
        </div>
        {
          this.state.factoryinfo.indexOf("9MW生物质热电联产")>-1 ?
            <Fragment>
              <div style={{width:clientHeight>900?clientWidth-700 +'px':clientHeight>600?clientWidth-500 +'px':clientWidth-300 +'px'}}>
                <h1>庆元县屏都综合新区生物质热电联供项目</h1>
                <h2>一、建设规模</h2>
                <h3>主要建设2台50吨/时高温高压生物质直燃循环流化床锅炉和1台高温高压9兆瓦纯抽汽凝汽式汽轮发电机组及附属设施。</h3>
                <h2>二、建设地点</h2>
                <h3>项目位于庆元县屏都综合新区025-02-07-04号地块。厂址北侧为南一路，西侧临靠规划的竹园大道，南侧为山体。</h3>
                <h3>总用地面积46681.41平方米。</h3>
                <h2>三、总平面布置</h2>
                <h3>原则同意厂区总平面布置方案。主厂房区布置在厂区南部，水工系统、电气配电装置布置在主厂房西侧，
                  </h3><h3>厂区东部布置生物质燃料的堆场和加工车间，化水系统位于主厂房北侧，厂前区布置在厂区东北侧。
                    </h3><h3>主厂房布置为汽机房、除氧间、给料间、锅炉岛呈四列式布置格局。
                      </h3><h3>锅炉侧面布置风机、炉后设置除尘系统及引风机，炉前布置生物质料仓。锅炉采用露天布置。</h3>
                  </div><h2>四、设备选型</h2>
                <h3>原则同意选用江联重工股份有限公司生产的JG-50/9.8-T型高压（9.81兆帕）高温（540℃）、蒸发量为50吨/时直燃生物质循环流化床锅炉2台、
                  </h3><h3>西安陕鼓汽轮机有限公司生产的额定出力为9兆瓦的C9-8.83/1.176抽汽凝汽式汽轮机1台和济南发电设备厂生产的QFN-9-2型汽轮发电机1台。</h3>
            
                <h2>五、接入系统</h2>
                <h3>接入系统按国网丽水供电公司的批复（丽电发展[2015]106号文）设计，发电机组以35千伏电压1回线T接于北蓬3681线进入110千伏庆元变。</h3>
                <h2>六、热控部分</h2>
                <h3>原则同意本项目采用机炉电实行集中控制方式，采用分散控制系统（DCS），控制系统机柜按照物理分散方式进行布置。</h3>
                <h3>原则同意在除氧给料间运转层的#3-#7柱之间设立一座集中控制室，设置8个操作员站、单独的工程师站。</h3>
                <h2>七、水工部分（含消防）</h2>
                <h3>（一）生产用水及生活用水由市政自来水供给。机组采用二次循环冷却方式。</h3>
                <h3>（二）排水实行雨污分流、清污分流，所有废水、污水经处理后达标外排。</h3>
                <h3>（三）原则同意消防设计，按照消防法规和技术规范进行施工图设计，并报消防部门审查。</h3>
                <h2>八、化学部分</h2>
                <h3>同意本项目化学水处理采用过滤＋一级反渗透＋混床的系统。设置出力为40吨/时的反渗透装置2套以及容量为100立方米的除盐水箱2座。设置1套汽水自动集中取样装置。</h3>
                <h2>九、除灰渣部分</h2>
                <h3>原则同意锅炉灰渣采用分除、干式方式的除灰渣系统。设置1座有效容积为300立方米钢筋混凝土储灰罐，贮存飞灰，在储灰罐下部设置干灰卸料机和打包机，所产生的灰渣外运进行综合利用。</h3>
                <h2>十、建筑结构</h2>
                <h3>本项目建筑物设计使用年限为50年，安全等级为二级，抗震设防按6度设防。主厂房除汽机房屋盖采用单坡轻钢结构外，其余部分均采用现浇钢筋混凝土结构。</h3>
                <h2>十一、热力网部分</h2>
                <h3>（一）本工程对外供热参数为1.3兆帕/250℃，供热凝结水暂不考虑回收，由热用户自行回收利用。</h3>
                <h3>（二）配套建设一条向屏都综合新区热用户供汽管网，供热干管DN250约2.2公里。</h3>
                <h3>（三）热网管道采用单管制、枝状管网，以自然补偿为主。主要在道路的绿化带以低支架（主要为独立的T型管道支墩）方式进行敷设，穿越道路采用钢结构桁架跨越。</h3>
                <h2>十二、环保及劳动安全</h2>
                <h3>（一）按庆元县水利局《项目水土保持登记表》（庆水保登字[2014]021号）的要求，落实有关水土保持的措施。</h3>
                <h3>（二）工程建设要严格按丽水市环保局丽环建[2015]15号文对项目的环境影响报告书审查批复，做到“三同时”，
                  </h3><h3>并设立取样平台，烟气中的二氧化硫、烟尘等污染物的在线监测系统应进入DCS，并与当地环保部门联网。</h3>
                <h3>（三）严格执行建设项目安全设施“三同时”制度，并及时将建设项目“三同时”报送相关部门备案；同时加强建设项目安全监管，确保工程安全。</h3>
                <h2>十三、节能部分</h2>
                <h3>原则同意节能和节水设计措施，采用高参数的蒸汽轮机机组，低损耗和节能型设备，对锅炉风机及给水泵等设备采用变频调速，做好建筑节能设计、设备及管道采用优质保温，对各种能源实行三级计量。</h3>
                <h2>十四、概算</h2>
                <h3>核定工程总概算14718万元。所需建设资金由项目业主自筹解决。</h3>
                <h3>请你单位完成该项目审批事项后及时录入相关审批信息。
                  </h3><h3>项目建设单位在符合《国务院办公厅关于加强和规范新开工项目管理的通知》（国办发[2007]64号）要求的八项开工条件后，及时录入实施进展信息，并报送当地统计部门。</h3>
              
            </Fragment>
             :
            this.state.factoryinfo.indexOf("临沂汇宝新能源有限公司高效煤粉锅炉房")>-1 ?
            <div style={{width:clientHeight>900?clientWidth-700 +'px':clientHeight>600?clientWidth-500 +'px':clientWidth-300 +'px'}}>
                  <h1>临沂汇宝新能源有限公司</h1>
                  <h2>一、工程概况</h2>
                  <h3>临沂汇宝新能源年有限公司有限公司因生产工艺生产用汽的需要，决定新建一台21t/h高效煤粉锅炉，本工程主要包括:</h3>
                  <h3>1、新建一台21t/h高效煤粉锅炉；</h3>
                  <h3>2、锅炉尾部烟气处理设备：布袋除尘器、脱硫系统、脱硝系统；</h3>
                  <h3>3、新建旋膜式除氧器一台。</h3>
                  <h3>4、相应的煤粉系统、点火系统、汽水系统、烟风系统等。</h3>
                  <h2>二、设计依据</h2>
                  <h3>1、临沂汇宝新能源有限公司与上海工业锅炉有限公司签订的合同及技术协议</h3>
                  <h3>2、业主提供的有关资料或联系函；</h3>
                  <h3>3、本项目业主筹建组与我公司工作组数次协调的商定意见</h3>
                  <h3>4、有关的规程、规定</h3>
                  <h4>（1）、《锅炉房设计规范》（GB50041-2015）</h4>
                  <h4>（2）、《工业锅炉房设计规范》（GBJ41－79）</h4>
                  <h4>（3）、《工业锅炉水质》（DL/T1576-2008）</h4>
                  <h4>（4）、《火力发电厂汽水管道设计技术规定》（DL/T5054-2016）等</h4>
                  <h3>5、 锅炉厂提供的锅炉资料及其他辅助设备资料。</h3>
                  <h4>锅 炉 台 数：  1台</h4>
                  <h4>生  产  厂：    上海工业锅炉有限公司</h4>
                  <h4>锅 炉 型 号：   GG21-3.82-M</h4>
                  <h4>额定蒸发量：    21t/h</h4>
                  <h4>额定蒸汽压力：  3.82MPa</h4>
                  <h4>额定蒸汽温度：  450°C（饱和蒸汽）</h4>
                  <h4>给 水 温 度：   104°C</h4>
                  <h4>排 烟 温 度：   142.1°C</h4>
                  <h4>锅 炉 效 率：   87.5%</h4>
                </div> :
              this.state.factoryinfo.indexOf("山东金城医药股份有限公司高效煤粉锅炉房")>-1 ?
                <div> 
                    <h1>山东金城医药股份有限公司</h1>
                    <h2>一、工程概况</h2>
                    <h3>山东金城医药股份有限公司为提高锅炉效率、减少锅炉尾气污染物的排放，决定新建一台20t/h高效煤粉锅炉，本工程主要包括:</h3>
                    <h3>1、新建一台20t/h高效煤粉锅炉；</h3>
                    <h3>2、锅炉尾部烟气处理设备：布袋除尘器、脱硫系统、脱硝系统；</h3>
                    <h3>3、增加旋膜式除氧器一台；</h3>
                    <h3>4、相应的煤粉系统、点火系统、汽水系统、烟风系统等。</h3>
                    <h2>二、设计依据</h2>
                    <h3>1、山东金城医药股份有限公司与上海工业锅炉有限公司签订的合同；</h3>
                    <h3>2、业主提供的有关资料或联系函；</h3>
                    <h3>3、本项目业主筹建组与我公司工作组数次协调的商定意见</h3>
                    <h3>4、有关的规程、规定</h3>
                    <h4>（1）、《锅炉房设计规范》（GB50041-2008）</h4>
                    <h4>（2）、《工业锅炉房设计规范》（GBJ41－79）</h4>
                    <h4>（3）、《工业锅炉水质》（DL/T1576-2008）</h4>
                    <h4>（4）、火力发电厂汽水管道设计技术规定（DL/T5054-1996）等</h4>
                    <h3>5、 锅炉厂提供的锅炉资料及其他辅助设备资料。</h3>
                    <h4>锅 炉 台 数：   1台</h4>
                    <h4>生  产   厂：   上海工业锅炉有限公司</h4>
                    <h4>锅 炉 型 号：   GG20-1.25-AⅡ</h4>
                    <h4>额定蒸发 量：   20t/h</h4>
                    <h4>额定蒸汽压力：  1.25MPa</h4>
                    <h4>额定蒸汽温度：  194°C（饱和蒸汽）</h4>
                    <h4>给 水 温 度：   104°C</h4>
                    <h4>排 烟 温 度：   ~145°C</h4>
                    <h4>锅 炉 效 率：   87.56%</h4>
                </div> : ""
        }
      </div>
    )
  }
}
export default ProConfig;