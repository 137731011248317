import React, { Fragment } from 'react'
import { Tree, Input, Spin, Modal, Table, Button, Upload, message, Tooltip, Form, Row, Col, Select } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import { conversion } from '../../converse'
import { onCellf } from '../../comfunc'
import { toProTreeData1 } from '../../utils/toprojecttreedata'
import Draggable from 'react-draggable';
import reqwest from 'reqwest';
import TextArea from 'antd/lib/input/TextArea';
import store from '../../store';
import { getButtonLimit } from '../../utils/getbuttonlimit'

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { TreeNode, DirectoryTree } = Tree;
class DigitalAssembly extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr:[],

      fileList: [],
      uploading: false,

      iptobj: {},//设置kks

      activeDrags: 0,
      controlledPosition: {
        x: 0, y: 0
      },
      controlledPositionA: {
        x: 0, y: 0
      },
      treeDataproject: [],
      treeDataarea: [],
      treeDataconstrut: [],
      treeDataequipasset: [],

      draggableflag: "",//上传模型  装配模型
      assemblydata: [],//装配方式
      treeData: [],
      assemblysele: "专业",
      selectedKeysA: [],
      treeDataB: [],
      assemblysele1: "专业",
      selectedKeysB: [],

      //bos3d模型上传
      rowId: "",
      rowObj: {},
      treeDatadrag: [],
      selectedKeysB: [],
      dataSource: [],
      dataSourcelen: 0,
      pageIndex: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 80 - 50) / 28),
      //加载
      counflag: false,
      attr: {
        ID: 'ID',
        ParentId: 'ParentId',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        RecordVersion: 'RecordVersion',
        UpdateTime: 'UpdateTime',
        UpdatePeople: 'UpdatePeople',
        CreatePeople: 'CreatePeople',
        CreateTime: 'CreateTime'
      },
    }
    //文档
    this.columns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '6%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '文件编号',
        dataIndex: 'Code',
        key: 'Code',
        width: '16%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '状态',
        dataIndex: 'State',
        key: 'State',
        width: '8%',
        render: (text) => {
          if (text == "0") {
            return (<span>未解析</span>)
          } else {
            return (<span>已解析</span>)
          }
        }
      },
      {
        title: '名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '10%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '大小',
        dataIndex: 'FileSize',
        key: 'FileSize',
        width: '8%'
      },
      {
        title: '专业',
        dataIndex: 'ProfessionalName',
        key: 'ProfessionalName',
        width: '9%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '区域',
        dataIndex: 'RegionalName',
        key: 'RegionalName',
        width: '9%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '施工包',
        dataIndex: 'ConstructionPackageName',
        key: 'ConstructionPackageName',
        width: '10%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '设备资产',
        dataIndex: 'EquipmentAssetsName',
        key: 'EquipmentAssetsName',
        width: '10%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '上传时间',
        dataIndex: 'CreateTime',
        key: 'CreateTime',
        width: '10%',
        onCell: onCellf,
        render: (text)=>{
          return (<span>{text.split(' ')[0]}</span>)
        }
      }
    ]
    this.renderf = this.renderf.bind(this)
    this.receivemess = this.receivemess.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="bottom" className="managetooltip" title={text}>{text}</Tooltip>)
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(45)

    //获取装配方式
    axios.get('/DATA/ParaSet/AssemblyMode.aspx').then(res => {
      //console.log('装配方式', conversion(res))
      this.setState({
        assemblydata: conversion(res)
      })
    })
    window.addEventListener('message', this.receivemess)
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('专业树数据', arr)
      this.setState({
        treeData: arr,
        treeDataB: arr,
        treeDataproject: arr
      })
    })
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=8').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('区域树数据', arr)
      this.setState({
        treeDataarea: arr
      })
    })
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=63').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('施工包树数据', arr)
      this.setState({
        treeDataconstrut: arr
      })
    })
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=73').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('设备资产树数据', arr)
      this.setState({
        treeDataequipasset: arr
      })
    })
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 80 - 50) / 28),
    })
  }

  receivemess(e) {
    //console.log('接收到的值', e.data, typeof e.data)
    if (typeof e.data == "string" && e.data.indexOf("KKSCode1")>-1) {
      var obj = JSON.parse(e.data)
      if (obj.guid) {
        this.setState({
          iptobj: obj
        })
      } else {
        message.error('guid值不存在！')
      }
    }
  }
  senddata(record) {
    //console.log(record)
    this.setState({
      rowId: record.ID,
      rowObj: record,
    })
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }


  onTreeSelect(selectedKeys, info) {
    //console.log(selectedKeys, info)
    if(selectedKeys[0]){
      this.setState({
        selectedKeysA: selectedKeys,
        counflag: true
      }, function () {
        var assemblysele = this.state.assemblysele
        axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=11', {
          Num: assemblysele == "专业" ? "1" : assemblysele == "区域" ? "2" : assemblysele == "施工包" ? "3" : "4",
          TreeId: this.state.selectedKeysA[0],
          pageIndex: 1,
          pageSize: this.state.pageSize
        }).then(data => {
          // console.log(data)
          var str = data.data.slice(2).split(',')[0].split(':')[1]
          // console.log(str)
          this.setState({
            dataSource: conversion(data),
            dataSourcelen: Number(str),
            counflag: false,
            pageIndex: 1,
            rowId: "",
            rowObj: {}
          })
        })
      })
    }else{
      this.setState({
        selectedKeysA: selectedKeys,
        counflag: false,
        dataSource:[],
        rowId: "",
        rowObj: {},
        dataSourcelen:0,
      })
    }
  }
  onTreeSelectB(selectedKeys, info) {
    //console.log(selectedKeys, info)
    this.setState({
      selectedKeysB: selectedKeys
    })
  }
  //BOS
  BOS3dupload() {
    if (this.state.selectedKeysA[0]) {
      this.setState({
        draggableflag: "上传模型"
      })
    } else {
      message.error('请选择！')
    }
  }
  //上传文件
  handleUpload = () => {
    if (document.getElementById("file").value == "") {
      message.error('请选择文件！')
    } else {
      this.setState({
        uploading: true
      })
      var _this = this
      var url = "http://47.97.127.28:8081/api/bole/files?";
      var fileObj = document.getElementById("file").files[0];
      //console.log(fileObj)
      var filesize = fileObj.size
      var filename = fileObj.name
      var fileType = fileObj.type;
      var formData = new FormData();
      //console.log(fileType)
      if (fileType.indexOf("zip") > -1) {
        fileType = "IMODEL"
      } else {
        fileType = "IFC"
      }

      formData.append("file", fileObj); // 文件对象  
      formData.append("type", fileType);

      var xhr = new XMLHttpRequest()
      xhr.open("post", url, true)
      //console.log('form对象', formData)
      //开始上传，发送form数据
      xhr.send(formData);

      xhr.onload = function uploadComplete(text) {
        //console.log('成功', text, JSON.parse(text.currentTarget.response))
        var obj = JSON.parse(text.currentTarget.response)
        //console.log(obj)
        axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=1', {
          // pid: _this.state.selectedKeysA[0],
          ProfessionalTreeId: _this.state.assemblysele == "专业" ? _this.state.selectedKeysA[0] : "",
          RegionalTreeId: _this.state.assemblysele == "区域" ? _this.state.selectedKeysA[0] : "",
          ConstructionPackageTreeId: _this.state.assemblysele == "施工包" ? _this.state.selectedKeysA[0] : "",
          EquipmentAssetsTreeId: _this.state.assemblysele == "设备资产" ? _this.state.selectedKeysA[0] : "",
          BosServer: "http://47.97.127.28:8080/v1-2/",
          BosUserName: "aileen",
          BosUserPwd: "aileen1234",
          BosUserKey: "efe4dfe58ba347248412cd3e0557aa49",
          Name: "",
          FileName: filename,
          FileSize: filesize,
          FileCode: obj.data.key,
          // positionid: _this.state.selectedKeysB[0],
        }).then(data => {
          //console.log(data)
          if (data.data == true) {
            var assemblysele = _this.state.assemblysele
            axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=11', {
              Num: assemblysele == "专业" ? "1" : assemblysele == "区域" ? "2" : assemblysele == "施工包" ? "3" : "4",
              TreeId: _this.state.selectedKeysA[0],
              pageIndex: 1,
              pageSize: _this.state.pageSize
            }).then(data => {
              // console.log(data)
              var str = data.data.slice(2).split(',')[0].split(':')[1]
              // console.log(str)
              _this.closedragwiddow()
              _this.setState({
                dataSource: conversion(data),
                dataSourcelen: Number(str)
              }, function () {
                message.success('上传成功！')
              })
            })
          }
        })
      };
      //请求完成
      xhr.onerror = function uploadFailed(err) {
        //console.log('失败', err)
      };
      //请求失败
      // xhr.upload.onprogress = function progressFunction(text) {
      //   console.log(text)
      // } //【上传进度调用方法实现】
      // xhr.upload.onloadstart = function () {//上传开始执行方法
      //   var ot = new Date().getTime();   //设置上传开始时间
      //   var oloaded = 0;//设置上传开始时，以上传的文件大小为0
      // };
    }
  };
  //解析模型
  analysismodel() {
    var _this = this
    if (this.state.rowId) {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": "http://47.97.127.28:8081/api/bole/parses/files",
        "method": "POST",
        "headers": {
          "Content-Type": "application/json",
          "cache-control": "no-cache",
          "Postman-Token": "a886afc4-f27d-44cd-ae50-0deb5ee44c20"
        },
        "processData": false,
        "data": "{\n\t\"fileKey\":\"" + _this.state.rowObj.FileCode + "\",\n\t\"priority\":\"1\"\n}"
      }

      $.ajax(settings).done(function (response) {
        //console.log(response);
        if (response.message.indexOf("成功") > -1) {
          axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=3', {
            ID: _this.state.rowId,
            ModelKey: response.data.modelKey
          }).then(data => {
            //console.log(data)
            if (data.data == true) {
              var arr = _this.state.dataSource
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].ID == _this.state.rowId) {
                  arr[i].State = "1"
                  arr[i].ModelKey = response.data.modelKey
                  arr[i].Note = "1"
                }
              }
              _this.setState({
                dataSource: arr
              }, function () {
                message.success('解析成功！')
              })
            }
          })
        } else {
          message.error(response.message)
        }
      });
    } else {
      message.error('请选择模型！')
    }
  }
  //创建场景
  createviewpoint() {
    if (this.state.rowId) {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": "http://47.97.127.28:8081/api/bole/scenes",
        "method": "POST",
        "headers": {
          "Content-Type": "application/json",
          "cache-control": "no-cache",
          "Postman-Token": "94a31505-5515-4051-bb51-99ecdbe96afc"
        },
        "processData": false,
        "data": "{\n\t\"modelKey\": \"" + this.state.rowObj.ModelKey + "\"\n}"
      }

      $.ajax(settings).done(function (response) {
        //console.log(response);
        if (response.data == null) {
          message.info("提示信息:" + response.message + ',' + ' 请稍后再试' + "!"); //这个是需要的。解析是多进程的
        } else if (response.message == "参数（modelKey）不能为空") {
          message.error("提示信息:" + response.message)
        } else if (response.data.remark) {
          message.info("提示信息:" + response.message + ',' + response.data.remark + "!");
        } else {
          message.info("提示信息:" + response.message + "!");
        }
      });
    } else {
      message.error('请选择模型！')
    }
  }
  //删除模型
  handleDeletemodel() {
    var _this = this
    if (this.state.rowId) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=7', {
            ID: _this.state.rowId
          }).then(data => {
            //console.log(data)
            if (data.data == true || typeof data.data == "string" && data.data.indexOf('true') > -1) {

              var assemblysele = _this.state.assemblysele
              axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=11', {
                Num: assemblysele == "专业" ? "1" : assemblysele == "区域" ? "2" : assemblysele == "施工包" ? "3" : "4",
                TreeId: _this.state.selectedKeysA[0],
                pageIndex: 1,
                pageSize: _this.state.pageSize
              }).then(data => {
                // console.log(data)
                var str = data.data.slice(2).split(',')[0].split(':')[1]
                // console.log(str)
                _this.setState({
                  dataSource: conversion(data),
                  dataSourcelen: Number(str),
                  pageIndex: 1,
                  rowId: "",
                  rowObj: {}
                }, function () {
                  message.success('删除成功！')
                })
              })
            }
          })
        },
        onCancel() {
          _this.setState({
            // detaillistId: ''
          })
        },
      });
    } else {
      message.error('请选择！')
    }
  }
  //生成kks树
  handleCreatetree() {
    var _this = this
    if (this.state.rowId) {
      //获取所有guid
      var data = null;
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.open("GET", 'http://47.97.127.28:8081/api/bole/components/keys?modelKey=' + _this.state.rowObj.ModelKey);
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.setRequestHeader("Postman-Token", "0ae2bfc6-3fb6-4957-b733-a07e2d9d3944");
      xhr.send(data);

      xhr.onload = function uploadComplete(text) {
        //console.log('获取guid成功', text, JSON.parse(text.currentTarget.response))
        var resobj = JSON.parse(text.currentTarget.response)
        if (resobj.message == "请求处理成功" || resobj.code == "SUCCESS") {
          var modelKey = _this.state.rowObj.ModelKey
          var getdata = resobj.data
          axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=4', {
            ModelKey: modelKey,
            Modelid: _this.state.rowId,
            guidgroup: JSON.stringify(getdata)
          }).then(data => {
            //console.log('获取guid', data)
          })
        }
      };
      //请求完成
      xhr.onerror = function uploadFailed(err) {
        //console.log('失败', err)
      };

      //获取kks

      var data2 = JSON.stringify({
        "model": _this.state.rowObj.ModelKey,
        "condition": [
          {
            "type": "filter",
            "field": "attribute['KKS']",
            "operator": "like",
            "value": "Code1"
          }
        ],
        "select": [
          "KKS"
        ]
      });

      var xhr2 = new XMLHttpRequest();
      xhr2.withCredentials = true;


      xhr2.open("POST", "http://47.97.127.28:8081/api/bole/queries/attributes");
      xhr2.setRequestHeader("Content-Type", "application/json");
      xhr2.setRequestHeader("cache-control", "no-cache");
      xhr2.setRequestHeader("Postman-Token", "3c9a6acd-a9c5-4810-8ce8-692cdf607d65");

      xhr2.send(data2);

      xhr2.onload = function uploadComplete(text) {
        //console.log('获取kks成功', text, JSON.parse(text.currentTarget.response))
        var resobj = JSON.parse(text.currentTarget.response)
        var seltectguid2 = JSON.stringify(resobj.data)
        $.ajax({
          type: "POST",
          url: "http://47.97.127.28:8081/api/bole/components/batch?return=attribute",
          contentType: "application/json",
          headers: {
            "content-type": "application/json"
          },
          "data": '{\r\n\"components\":' + seltectguid2 + '\r\n}',

          success: function (Data) {
            //console.log('获取kks', Data)
            if (Data.code == "SUCCESS") {
              var Dataresarr = Data.data
              var Code1 = [], Code2 = [], Code3 = []
              for (var i = 0; i < Dataresarr.length; i++) {
                Code1[i] = Dataresarr[i].attribute.KKS.Code1
                Code2[i] = Dataresarr[i].attribute.KKS.Code2
                Code3[i] = Dataresarr[i].attribute.KKS.Code3
              }
              var Code4 = JSON.stringify(Code1);
              var Code5 = JSON.stringify(Code2);
              var Code6 = JSON.stringify(Code3);
              axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=5', {
                Code4: Code4,
                Code5: Code5,
                Code6: Code6,
                Modelid: _this.state.rowId,
                ModelKey: _this.state.rowObj.ModelKey,
                guid2: seltectguid2
              }).then(data => {
                //console.log('获取kks flag=5', data)
                if (data.data == true) {
                  axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=6', {
                    Modelid: _this.state.rowId,
                  }).then(data => {
                    //console.log('获取kks flag=6', data)
                    if (data.data == true) {
                      message.success('操作成功！')
                      var arr = _this.state.dataSource
                      for (let i = 0; i < arr.length; i++) {
                        arr[i].Note = "2"
                      }
                      _this.setState({
                        dataSource: arr
                      })
                    } else {
                      message.success('操作失败！')
                    }
                  })
                } else {
                  message.success('操作失败！')
                }
              })
            }
          },
          error: function (err) {
            message.error('操作失败！')
          }
        })
      };
      //请求完成
      xhr2.onerror = function uploadFailed(err) {
        //console.log('失败', err)
      };

    } else {
      message.error('请选择模型！')
    }
  }
  //模型预览
  handleModuleview() {
    var _this = this
    if (this.state.selectedKeysA[0] && this.state.rowId) {
      if (this.state.rowObj.ModelKey) {
        document.querySelector('.digitalassemblymoduleload').style.display = "block"
        var digitalassemblymodule = document.getElementById("digitalassemblymodule")
        digitalassemblymodule.src = `./BOS3dhtml/areatree.html`
        digitalassemblymodule.onload = function () {
          document.getElementById("digitalassemblymodule").contentWindow.viewer3D.addView(_this.state.rowObj.ModelKey, "bole");
        }
      } else {
        message.error('请先解析模型！')
      }
    } else {
      message.error('请选择！')
    }
  }
  //设备清单检测
  // equiplistdetect() {

  // }
  pagechange(page) {
    this.setState({
      pageIndex: page.current,
      counflag: true
    }, function () {
      var assemblysele = this.state.assemblysele
      axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=11', {
        Num: assemblysele == "专业" ? "1" : assemblysele == "区域" ? "2" : assemblysele == "施工包" ? "3" : "4",
        TreeId: this.state.selectedKeysA[0],
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize
      }).then(data => {
        // console.log(data)
        var str = data.data.slice(2).split(',')[0].split(':')[1]
        // console.log(str)
        this.setState({
          dataSource: conversion(data),
          dataSourcelen: Number(str),
          counflag: false
        })
      })
    })
  }
  //装配方式选中
  assemblysele(key, value) {
    //console.log(value)
    if (key == "装配模型") {
      var valuestate = this.state.assemblysele1
      if (value != valuestate) {
        var arr = value == "专业" ? this.state.treeDataproject : value == "区域" ? this.state.treeDataarea :
          value == "施工包" ? this.state.treeDataconstrut : this.state.treeDataequipasset
        this.setState({
          assemblysele1: value,
          selectedKeysB:[],
          treeDataB: arr
        })
      }
    } else {
      var valuestate = this.state.assemblysele
      if (value != valuestate) {
        var arr = value == "专业" ? this.state.treeDataproject : value == "区域" ? this.state.treeDataarea :
          value == "施工包" ? this.state.treeDataconstrut : this.state.treeDataequipasset
        this.setState({
          assemblysele: value,
          selectedKeysA:[],
          dataSource: [],
          dataSourcelen: 0,
          rowId: "",
          rowObj: {},
          treeData: arr
        })
      }
    }
  }
  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDrag(e, ui) {
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  //装配模型
  assemblymodel() {
    if (this.state.rowId) {
      this.setState({
        draggableflag: "装配模型"
      })
    }else{
      message.error('请选择！')
    }
  }
  //装配模型保存
  assemblymodelsave(){
    if(this.state.selectedKeysB[0]){
      var assemblysele = this.state.assemblysele1
      axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=12', {
        Num: assemblysele == "专业" ? "1" : assemblysele == "区域" ? "2" : assemblysele == "施工包" ? "3" : "4",
        TreeId: this.state.selectedKeysB[0],
        ID:this.state.rowId
      }).then(data => {
        //console.log(data)
        if (data.data == true || typeof data.data == "string" && data.data.indexOf('true') > -1) {
          var assemblysele = this.state.assemblysele
          axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=11', {
            Num: assemblysele == "专业" ? "1" : assemblysele == "区域" ? "2" : assemblysele == "施工包" ? "3" : "4",
            TreeId: this.state.selectedKeysA[0],
            pageIndex: 1,
            pageSize: this.state.pageSize
          }).then(data => {
            // console.log(data)
            var str = data.data.slice(2).split(',')[0].split(':')[1]
            // console.log(str)
            this.setState({
              dataSource: conversion(data),
              dataSourcelen: Number(str),
              pageIndex: 1,
              rowId: "",
              rowObj: {},
              selectedKeysB:[],
              draggableflag:""
            },function(){
              message.success('保存成功！')
            })
          })
        }
      })
    }
  }
  //文件上传窗口关闭
  closedragwiddow() {
    if(this.state.draggableflag == "上传模型"){
      document.getElementById("file").value = ""
    }
    this.setState({
      uploading: false,
      draggableflag:"",
      selectedKeysB:[]
    })
  }
  toclosedigitalassemblymoduleload() {
    this.setState({
      iptobj: {}
    })
    this.props.form.resetFields();
    document.querySelector('.digitalassemblymoduleload').style.display = "none"
  }
  //重新生成kks树
  reCreatekkstree() {
    axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=10', {
      TM_Modelid: this.state.rowId
    }).then(data => {
      //console.log(data)
      if (data.data == true || data.data.indexOf("true") > -1) {
        message.success('重新生成kks成功！')
      }
    })
  }

  //设置kks保存
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      //console.log(err, values)
      if (!err) {
        values.TM_Modelid = this.state.rowId
        values.kkscode1 = values.kkscode
        axios.post('/DATA/TdModel/DigitalAssembly.aspx?flag=9', values).then(data => {
          if (data.data == true || data.data.indexOf("true") > -1) {
            message.success('保存成功！')
          }
        })
      }
    });
  };
  render() {
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        const iszip = file.type === 'zip'
        const isifc = file.type === "ifc"
        if (!(isifc || iszip)) {
          message.error('只能上传zip、ifc格式的文件')
        } else {
          this.setState(state => ({
            fileList: [...state.fileList, file],
          }));
          return false;
        }
      },
      // multiple: true,
      fileList,
    };

    const { getFieldDecorator } = this.props.form;
    const loop = data =>
      data.map(item => {
        if (item.children) {
          return (
            // title={<Tooltip title={item.ReadName}>{item.CodeName+'-'+item.ReadName}</Tooltip>}
            <TreeNode key={item.ID} title={item.Code + '-' + item.Name}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} title={item.Code + '-' + item.Name} />;
      });
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div className="digitalassembly" style={{ height: clientHeight - 50 + 'px' }}>



        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>

        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="dragwindow digitalassemblymoduleload" ref="digitalassemblymoduleload"
            style={{
              width: '80%', height: clientHeight - 100 + 'px'
            }}>
            <h2>模型预览<Button onClick={this.toclosedigitalassemblymoduleload.bind(this)}>关闭</Button></h2>
            <div className="digitalassemblymoduleloadcon" style={{
              width: '100%', height: clientHeight - 150 + 'px'
            }} onMouseDown={(e) => { e.stopPropagation() }} >
              <iframe title="myiframe" src="" id="digitalassemblymodule" style={{
                width: '70%', height: clientHeight - 150 + 'px'
              }}></iframe>
              <div className="formdan">
                <div className="actionall">
                  {/* <Button onClick={this.handleSubmit.bind(this)}>保存</Button>
                  <Button onClick={this.reCreatekkstree.bind(this)}>重新生成KKS树</Button> */}
                </div>
                <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="GUID">
                        {getFieldDecorator('guid', {
                          initialValue: this.state.iptobj["guid"],
                          rules: [{ required: true, message: '请输入' }],
                        })(
                          <TextArea rows={3} readOnly onMouseDown={(e) => { e.stopPropagation() }} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="KKS编码(工艺码)">
                        {getFieldDecorator('KKSCode1', {
                          initialValue: this.state.iptobj["KKSCode1"],
                          rules: [{ required: true, message: '请输入' }],
                        })(
                          <Input readOnly onMouseDown={(e) => { e.stopPropagation() }} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="KKS编码(位置码)">
                        {getFieldDecorator('KKSCode2', {
                          initialValue: this.state.iptobj["KKSCode2"],
                          rules: [{ required: true, message: '请输入' }],
                        })(
                          <Input readOnly onMouseDown={(e) => { e.stopPropagation() }} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="KKS编码(场所码)">
                        {getFieldDecorator('KKSCode3', {
                          initialValue: this.state.iptobj["KKSCode3"],
                          rules: [{ required: true, message: '请输入' }],
                        })(
                          <Input readOnly onMouseDown={(e) => { e.stopPropagation() }} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Draggable>

        <Draggable
          position={this.state.controlledPosition}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDrag.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <Fragment>
            {
              this.state.draggableflag == "上传模型" ?
                <div className="dragwindow docmentupload">
                  <h2>文件上传 <span className="close" onClick={this.closedragwiddow.bind(this)}>x</span></h2>
                  {/* <div className="tree" onMouseDown={(e) => { e.stopPropagation() }}>
              <DirectoryTree
                selectedKeys={this.state.selectedKeysB}
                onSelect={this.onTreeBSelect.bind(this)}
              >
                {loop(this.state.treeDatadrag)}
              </DirectoryTree >
            </div> */}
                  <div className="uploaddocument">
                    <input type="file" id="file" name="myfile" />
                    <Button
                      className="uploadpicture"
                      type="primary"
                      onClick={this.handleUpload.bind(this)}
                      loading={uploading}
                    >
                      {uploading ? '上传中' : '上传'}
                    </Button>
                    {/* <Upload {...props} className="selectpicture">
                <Button>
                  <Icon type="upload" />
                  选择文件
                          </Button>
              </Upload>
              <Button
                className="uploadpicture"
                type="primary"
                onClick={this.handleUpload.bind(this)}
                disabled={fileList.length === 0}
                loading={uploading}
              >
                {uploading ? '上传中' : '上传'}
              </Button> */}
                  </div>
                </div>
                :
                this.state.draggableflag == "装配模型" ?
                  <div className="dragwindow docmentupload1">
                    <h2>装配模型 <span className="close" onClick={this.closedragwiddow.bind(this)}>x</span></h2>
                    <div className="assemblysele1">
                      <span>装配方式</span>
                      <span>
                        <Select value={this.state.assemblysele1} onChange={this.assemblysele.bind(this, '装配模型')}>
                          {
                            this.state.assemblydata.length > 0 && this.state.assemblydata.map((item, index) => {
                              return (<Option value={item.Name} key={item.Name}>{item.Name}</Option>)
                            })
                          }
                        </Select>
                      </span>
                    </div>
                    <Button className="assemblymodelsave" onClick={this.assemblymodelsave.bind(this)}>保存</Button>
                    <div className="tree">
                      <Tree
                        selectedKeys={this.state.selectedKeysB}
                        onSelect={this.onTreeSelectB.bind(this)}
                      >
                        {loop(this.state.treeDataB)}
                      </Tree>
                    </div>
                  </div> : ""
            }
          </Fragment>
        </Draggable>


        <div className="digitalassemblyleft">
          <h2 className="h2title">装配列表</h2>
          <div className="actionall">
            <span>装配方式</span>
            <span>
              <Select value={this.state.assemblysele} onChange={this.assemblysele.bind(this, '装配列表')}>
                {
                  this.state.assemblydata.length > 0 && this.state.assemblydata.map((item, index) => {
                    return (<Option value={item.Name} key={item.Name}>{item.Name}</Option>)
                  })
                }
              </Select>
            </span>
          </div>
          <div className="tree" style={{ height: clientHeight - 50 - 80 + 'px' }}>
            <Tree
              selectedKeys={this.state.selectedKeysA}
              onSelect={this.onTreeSelect.bind(this)}
            >
              {loop(this.state.treeData)}
            </Tree>
          </div>
        </div>
        <div className="digitalassemblyright">
          <h2 className="h2title">已装配模型</h2>
          <div className="actionall">
            <span className="actionleft">
              <Button className="twoword" style={{display:this.state.buttonarr.indexOf('上传模型')>-1?"inline-block":"none"}} disabled={this.state.selectedKeysA[0] ? false : true} onClick={this.BOS3dupload.bind(this)}>上传模型</Button>
              <Button className="twoword" style={{display:this.state.buttonarr.indexOf('解析模型')>-1?"inline-block":"none"}} disabled={this.state.rowId && this.state.rowObj.State > 0 || this.state.rowId == "" ? true : false} onClick={this.analysismodel.bind(this)}>解析模型</Button>
              <Button className="twoword" style={{display:this.state.buttonarr.indexOf('创建场景')>-1?"inline-block":"none"}} disabled={this.state.rowId && this.state.rowObj.State > 0 ? false : true} onClick={this.createviewpoint.bind(this)}>创建场景</Button>
              <Button className="twoword" style={{display:this.state.buttonarr.indexOf('删除模型')>-1?"inline-block":"none"}} disabled={this.state.rowId ? false : true} onClick={this.handleDeletemodel.bind(this)}>删除模型</Button>
              <Button className="twoword" style={{display:this.state.buttonarr.indexOf('生成KKS树')>-1?"inline-block":"none"}} disabled={this.state.rowId == "" || this.state.rowId && this.state.rowObj.Note != "1" ? true : false} onClick={this.handleCreatetree.bind(this)}>生成KKS树</Button>
              <Button className="twoword" style={{display:this.state.buttonarr.indexOf('模型预览')>-1?"inline-block":"none"}} disabled={this.state.rowId ? false : true} onClick={this.handleModuleview.bind(this)}>模型预览</Button>
              <Button className="twoword" style={{display:this.state.buttonarr.indexOf('装配模型')>-1?"inline-block":"none"}} disabled={this.state.rowId ? false : true} onClick={this.assemblymodel.bind(this)}>装配模型</Button>
            </span>
            {/* <span className="actionright">
              <Button className="twoword" onClick={this.equiplistdetect.bind(this)}>设备清单完整性检查</Button>
            </span> */}
          </div>
          <div className="listpage"
            style={{ height: clientHeight - 50 - 80 + 'px' }}>
            <Table
              rowKey={record => record.key}
              size="small"
              dataSource={this.state.dataSource}
              columns={this.columns}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndex,
                total: this.state.dataSourcelen
              }}
              onChange={this.pagechange.bind(this)}
              onRow={(record) => {
                return {
                  onClick: this.senddata.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassName}
            />
          </div>
        </div>
      </div>
    )
  }
}
const DigitalAssemblyapp = Form.create()(DigitalAssembly);
export default DigitalAssemblyapp;