import React, { Fragment } from 'react'
import { Tree, Input, Spin, Modal, Table, Button, Checkbox, Upload, message, Tooltip, Form, Row, Col, DatePicker, Select, Drawer } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import { conversion } from '../../converse'
import { onCellf } from '../../comfunc'
import { toProTreeData1 } from '../../utils/toprojecttreedata'
import Draggable from 'react-draggable';
import reqwest from 'reqwest';
import TextArea from 'antd/lib/input/TextArea';
import store from '../../store';
import * as XLSX from 'xlsx';
import SearchCondition from '../../utils/searchcondition'
import { formatTimeDate } from '../../formatTimeDate'
import { getButtonLimit } from '../../utils/getbuttonlimit'
import { Resizable } from 'react-resizable';

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const { TreeNode, DirectoryTree } = Tree;
const { RangePicker } = DatePicker;

const renderf = (text) => {
  return (<Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>)
}

var plainOptions = []

var defcolumns = []

var CheckboxGrouparr = []

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};


class ListManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr: [],
      //当前使用的版本
      versionObj: {},

      activeDrags: 0,
      controlledPosition: {
        x: 0, y: 0
      },
      controlledPositionA: {
        x: 0, y: 0
      },
      treeData: [],
      selectedKeysA: [],
      expandedKeys: [],
      TreeCodeA: "",
      Leaf: "true",
      checkedKeysA: [],

      rowId: "",
      rowObj: {},
      dataSource: [],
      dataSourcelen: 0,
      pageIndex: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 50 - 28) / 28),

      doclistidsele: [],
      doclistsele: [],
      //加载
      counflag: false,
      attr: {
        ID: 'ID',
        ParentId: 'ParentId',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        RecordVersion: 'RecordVersion',
        UpdateTime: 'UpdateTime',
        UpdatePeople: 'UpdatePeople',
        CreatePeople: 'CreatePeople',
        CreateTime: 'CreateTime'
      },
      // attr: {
      //   Code: 'Code',
      //   ComponentModel: 'ComponentModel',
      //   Guid: 'Guid',
      //   ID: 'ID',
      //   InsulationMaterial: 'InsulationMaterial',
      //   InsulationThickness: 'InsulationThickness',
      //   KKS: 'KKS',
      //   Length: 'Length',
      //   Material: 'Material',
      //   Name: 'Name',
      //   NominalDiameter: 'NominalDiameter',
      //   ParentId: 'ParentId',
      //   RelationModel: 'RelationModel',
      //   Specification: 'Specification',
      //   Standard: 'Standard',
      //   TID: 'TID',
      //   TITParentIdD: 'TITParentIdD',
      //   Type: 'Type',
      //   Unit: 'Unit',
      //   UpdateTime: 'UpdateTime',
      //   UpdatePeople: 'UpdatePeople',
      //   Version: 'Version',
      //   rootId: "0",
      //   WallThickness: 'WallThickness',
      // },
      // columns: [
      //   {
      //     title: '序号',
      //     dataIndex: 'xu',
      //     key: 'xu',
      //     width: 70,
      //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      //       <div className="workticketdropdown">
      //         <CheckboxGroup options={plainOptions} defaultValue={
      //           ['Name', 'ComponentModel', 'Specification', 'Material', 'Unit', 'Length', 'Weight', 'Code', 'Version', 'RelationModel']}
      //           onChange={this.onColumnsChange()} />
      //       </div>
      //     ),
      //     render: (text, record, index) => {
      //       return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
      //     },
      //     onCell: onCellf,
      //   },
      //   {
      //     title: '名称',
      //     dataIndex: 'Name',
      //     key: 'Name',
      //     onCell: onCellf,
      //     width:60,
      //     render: renderf
      //   },
      //   {
      //     title: '型号',
      //     dataIndex: 'ComponentModel',
      //     key: 'ComponentModel',
      //     onCell: onCellf,
      //     render: renderf,
      //     width:50,
      //   },
      //   {
      //     title: '规格',
      //     dataIndex: 'Specification',
      //     key: 'Specification',
      //     onCell: onCellf,
      //     render: renderf,
      //     width:50,
      //   },
      //   {
      //     title: '材料',
      //     dataIndex: 'Material',
      //     key: 'Material',
      //     onCell: onCellf,
      //     width:50,
      //     render: renderf
      //   },
      //   {
      //     title: '单位',
      //     dataIndex: 'Unit',
      //     key: 'Unit',
      //     width: 50
      //   },
      //   {
      //     title: '数量',
      //     dataIndex: 'Length',
      //     key: 'Length',
      //     width: 50
      //   },
      //   {
      //     title: '重量',
      //     dataIndex: 'Weight',
      //     key: 'Weight',
      //     width: 50
      //   },
      //   {
      //     title: '版本',
      //     dataIndex: 'Version',
      //     key: 'Version',
      //     width: 50
      //   },
      //   {
      //     title: '关联BIM模型',
      //     dataIndex: 'RelationModel',
      //     key: 'RelationModel',
      //     width: 80,
      //     render: (text) => {
      //       if (text == "0") {
      //         return (<img style={{ left: "45%" }} src={require('../../assets/cha.png')} />)
      //       } else {
      //         return (<img style={{ left: "45%" }} src={require('../../assets/duigou.png')} />)
      //       }
      //     }
      //   }
      // ],
      iptobj: {},
      columns: [],
      visible: false,
      addeditflag: "",
      addeditname: "",
      Versionaddflag: false,
      //日志/版本
      equipmentFlag: "",

      columns1: [
        {
          title: '序号',
          dataIndex: 'xu',
          key: 'xu',
          width: '6%',
          render: (text, record, index) => {
            return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
          }
        },
        {
          title: '表名',
          dataIndex: 'TableName',
          key: 'TableName',
          width: '18%'
        },
        {
          title: '业务名称',
          dataIndex: 'BusinessName',
          key: 'BusinessName',
          width: '16%'
        },
        {
          title: '账号',
          dataIndex: 'CreatePeople',
          key: 'CreatePeople',
          width: '20%',
          onCell: onCellf,
          render: renderf
        },
        {
          title: '操作类型',
          dataIndex: 'OperateType',
          key: 'OperateType',
        },
        {
          title: '操作时间',
          dataIndex: 'CreateTime',
          key: 'CreateTime',
          width: '16%',
        },
        // {
        //   title: '业务主键值',
        //   dataIndex: 'PrimaryKeyValue',
        //   key: 'PrimaryKeyValue',
        //   width: '10%'
        // }
      ],
      dataSource1: [],
      pageIndex1: 1,
      dataSourcelen1: 0,

      columns11: [
        {
          title: '序号',
          dataIndex: 'xu',
          key: 'xu',
          width: '6%',
          render: (text, record, index) => {
            return (<span>{(this.state.pageIndex11 - 1) * 8 + index + 1}</span>)
          }
        },
        {
          title: '字段',
          dataIndex: 'ColumnName',
          key: 'ColumnName',
          width: '20%'
        },
        {
          title: '名称',
          dataIndex: 'ColumnText',
          key: 'ColumnText',
          width: '20%'
        },
        {
          title: '旧值',
          dataIndex: 'OldValues',
          key: 'OldValues',
          width: '26%'
        },
        {
          title: '新值',
          dataIndex: 'NewValues',
          key: 'NewValues',
          width: '26%'
        }
      ],
      dataSource11: [],
      pageIndex11: 1,
      dataSourcelen11: 0,

      columns2: [
        {
          title: '序号',
          dataIndex: 'xu',
          key: 'xu',
          width: '6%',
          render: (text, record, index) => {
            return (<span>{(this.state.pageIndex2 - 1) * this.state.pageSize + index + 1}</span>)
          }
        },
        {
          title: '版本号',
          dataIndex: 'Version',
          key: 'Version',
          width: '12%',
        },
        {
          title: '状态',
          dataIndex: 'Flag',
          key: 'Flag',
          width: '10%',
          render: (text) => {
            return (<span>{text == "1" ? "启用中" : ""}</span>)
          }
        },
        {
          title: '版本描述',
          dataIndex: 'VersionRemark',
          key: 'VersionRemark',
          width: '70%',
          onCell: onCellf,
          render: renderf
        },
      ],
      pageIndex2: 1,
      dataSource2: [],
      dataSourcelen2: 0,

      //搜索
      columnssearch: [
        { key: 'KKS', value: 'KKS编码' },
        { key: 'Name', value: '名称' },
        { key: 'Specification', value: '规格' },
        { key: 'ComponentModel', value: '型号' },
        { key: 'NominalDiameter', value: '公称直径' },
        { key: 'WallThickness', value: '壁厚' },
        { key: 'Material', value: '材料' },
        { key: 'Length', value: '数量' },
        { key: 'Weight', value: '重量' },
        { key: 'Standard', value: '标准' },
        { key: 'InsulationThickness', value: '保温层厚度' },
        { key: 'InsulationMaterial', value: '保温层材料' }
      ],
      selectvalue: "",
      inputvalue: "",
      logicalvalue: "or",

      //高级搜索
      morecondition: [{ selectvalue: "", inputvalue: "", logicalvalue: "or" }],
      moresearchcondsflag: false,

      //日志搜索时间
      startendtime: [],

      //模型预览
      KKSarr: [],
      ModelKeyarrlength: 0,//模型个数
      isNumCOMPLETE: 0

    }
    this.receivemess = this.receivemess.bind(this)
  }
  onColumnsChange = (checkedValues) => (checkedValues) => {
    // console.log(checkedValues)
    axios.post('/DATA/WebSet/GridColumnSetting.aspx?flag=2', {
      ModelName: 'EquipmentList',
      Value: checkedValues.join(','),
    }).then(res => {
      // console.log(res)
      if (res.data.success) {
        let data = [{
          title: '序号',
          dataIndex: 'xu',
          key: 'xu',
          width: 90,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className="workticketdropdown">
              <CheckboxGroup options={plainOptions} defaultValue={checkedValues} onChange={this.onColumnsChange()} />
            </div>
          ),
          render: (text, record, index) => {
            return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
          },
          onCell: onCellf,
        }]
        defcolumns.forEach((r, index) => {
          checkedValues.forEach(rs => {
            if (r.key == rs) {
              data.push(r)
            }
          })
        })
        this.setState({ columns: data })
      }
    })
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限', res)
    this.setState({
      buttonarr: res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(44)
    this.initPageData()
    window.addEventListener('message', this.receivemess)
  }

  initPageData(iskey) {
    //获取当前使用的版本号
    axios.get('/DATA/ParaSet/EquipmentList.aspx?flag=5').then(res => {
      //console.log(res)
      if (res.data.indexOf('Version') > -1) {
        this.setState({
          versionObj: conversion(res)[0],
          counflag: true
        }, function () {
          axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res => {
            //console.log(conversion(res))
            let attr = this.state.attr;
            let arr = toProTreeData1(conversion(res), attr)
            // console.log('专业树数据', arr)
            var selectedKeysA = [], expandedKeys = []
            if (arr[0] && arr[0].children && arr[0].children[0] && arr[0].children[0].children
              && arr[0].children[0].children[0] && arr[0].children[0].children[0].Code) {
              selectedKeysA.push(arr[0].children[0].children[0].Code)
              expandedKeys.push(arr[0].Code)
              expandedKeys.push(arr[0].children[0].Code)
              expandedKeys.push(arr[0].children[0].children[0].Code)
            }
            this.setState({
              treeData: arr,
              expandedKeys: expandedKeys,
              selectedKeysA: selectedKeysA
            }, function () {
              //动态获取表格列表标题
              axios.post('/DATA/WebSet/GridColumnSetting.aspx?flag=1', {
                ModelName: 'EquipmentList'
              }).then(res => {
                // console.log(res.data.data)
                if (res.data.success) {
                  var arrcolumn = res.data.data
                  var newarrcolumn = []

                  var defcolumnsfu = [], plainOptionsfu = [], CheckboxGrouparrfu = [], searchcolumn = []
                  for (let i = 0; i < arrcolumn.length; i++) {
                    var obj1 = {}
                    obj1.label = arrcolumn[i].Label
                    obj1.value = arrcolumn[i].Value

                    var obj = {}
                    obj.Flag = arrcolumn[i].Flag
                    obj.title = arrcolumn[i].Label
                    obj.dataIndex = arrcolumn[i].Value
                    obj.key = arrcolumn[i].Value
                    obj.onCell = onCellf
                    obj.width = Number(arrcolumn[i].Width)
                    

                    if (arrcolumn[i].Value == "RelationModel") {
                      obj.render = (text) => {
                        return (<span>{text == "0" ? "否" : "是"}</span>)
                      }
                    } else {
                      obj.render = renderf
                    }

                    if (obj.dataIndex == "ParentId") {
                      defcolumnsfu.unshift(obj)
                      if (obj.Flag == "1") {
                        newarrcolumn.unshift(obj)
                      }
                    } else {
                      defcolumnsfu.push(obj)
                      if (obj.Flag == "1") {
                        newarrcolumn.push(obj)
                      }
                    }
                    
                    
                    plainOptionsfu.push(obj1)
                    if (arrcolumn[i].Flag == "1") {
                      var searchcolumnobj = {}
                      searchcolumnobj.key = arrcolumn[i].Value
                      searchcolumnobj.value = arrcolumn[i].Label
                      searchcolumn.push(searchcolumnobj)

                      CheckboxGrouparrfu.push(arrcolumn[i].Value)
                    }
                  }

                  defcolumns = defcolumnsfu
                  plainOptions = plainOptionsfu
                  CheckboxGrouparr = CheckboxGrouparrfu


                  var XuHaoobj = {
                    title: '序号',
                    dataIndex: 'xu',
                    key: 'xu',
                    width: 90,
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                      <div className="workticketdropdown">
                        <CheckboxGroup options={plainOptions} defaultValue={CheckboxGrouparr}
                          onChange={this.onColumnsChange()} />
                      </div>
                    ),
                    render: (text, record, index) => {
                      return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
                    },
                    onCell: onCellf,
                  }
                  newarrcolumn.unshift(XuHaoobj)
                  this.setState({
                    columns: newarrcolumn,
                    columnssearch: searchcolumn
                  }, function () {
                    this.getListData(iskey)
                  })
                } else {
                  message.error(res.data.msg)
                }
              })
            })
          })
        })
      } else {
        message.error(res.data)
      }
    })
  }

  getListData(iskey) {
    axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=1', {
      ParentId: this.state.selectedKeysA[0],
      Columns: this.state.selectvalue,
      SearchKey: this.state.inputvalue,
      Logical: this.state.logicalvalue,
      PageIndex: this.state.pageIndex,
      PageSize: this.state.pageSize,
      Version: this.state.versionObj.Version
    }).then(res => {
      // //console.log(res)
      var str = res.data.slice(2).split(',')[0].split(':')[1]
      this.setState({
        counflag: false,
        dataSource: conversion(res),
        dataSourcelen: Number(str),
      }, function () {
        if (iskey == "启用刷新") {
          message.success('启用成功！')
        }
      })
    })
  }
  receivemess(e) {
    //console.log(e.data)
    var isNumCOMPLETE = this.state.isNumCOMPLETE
    if (e.data == "COMPLETE") {
      this.setState({
        isNumCOMPLETE: Number(isNumCOMPLETE) + 1
      }, function () {
        if (this.state.isNumCOMPLETE == this.state.ModelKeyarrlength) {
          this.getGuid(this.state.KKSarr)
        }
      })
    }
  }

  isarrRepeat(arr) {
    var hash = {};
    for (var i in arr) {
      if (hash[arr[i]]) {
        return true;
      }
      // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
      hash[arr[i]] = true;
    }
    return false;
  }
  onImportExcel(file) {
    // 获取上传的文件对象
    const { files } = file.target;
    if(files[0]){
      // 通过FileReader对象读取文件        
      const fileReader = new FileReader();
      var _this = this
      // fileReader.readAsDataURL(files[0]);  
      fileReader.onload = event => {
        try {
          const { result } = event.target;
          // 以二进制流方式读取得到整份excel表格对象                
          const workbook = XLSX.read(result, { type: 'binary' });
          // 存储获取到的数据                
          let data = [];
          // 遍历每张工作表进行读取（这里默认只读取第一张表）                
          for (const sheet in workbook.Sheets) {
            // esline-disable-next-line       
            console.log(sheet,workbook.Sheets)             
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              // 利用 sheet_to_json 方法将 excel 转成 json 数据
              data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
              // break; 
              // 如果只取第一张表，就取消注释这行                    
            }
          }
          console.log('获取到上传文件的数据',data)
          var KKSarr = []
          var NameisNull = false
          var NameNullarr = []
          var ParentIdisNull = false
          var ParentIdNullarr = []
          if (data.length == 0) {
            message.error('所选文件暂无数据！')
          } else {
            for (let i = 0; i < data.length; i++) {
              if (!data[i].Name) {
                NameisNull = true
                NameNullarr.push(data[i].__rowNum__ + 1)
              }
              if (!data[i].ParentId) {
                ParentIdisNull = true
                ParentIdNullarr.push(data[i].__rowNum__ + 1)
              }
              KKSarr = _this.ishaskey(KKSarr, 'KKS', data[i])
            }
            if(NameisNull || ParentIdisNull || this.isarrRepeat(KKSarr)){
              var str = ''
              if(NameisNull){
                var NameisNullstr = NameNullarr.join(',')
                str = `名称在第${NameisNullstr}行有为空`+ '\n'
              }
              if(ParentIdisNull){
                var ParentIdNullstr = ParentIdNullarr.join(',')
                str = str +`父节点在第${ParentIdNullstr}行有为空`+ '\n'
              }
              if(this.isarrRepeat(KKSarr)){
                str = str + 'KKS编码数据有重复'+ '\n' 
              }
              if(str){
                str = str + '请检查后再导入！'
                this.setState({
                  uploadreturninfo:str
                },function(){
                  document.getElementById("fileform") && document.getElementById("fileform").reset();
                })
              }
            }
          }
  
          if (!NameisNull && !ParentIdisNull && !this.isarrRepeat(KKSarr)) {
            console.log('获取到的excel数据', data)
            var KKS = [], Guid = [], Name = [], Specification = [], ComponentModel = [], WallThickness = [],
              InsulationThickness = [], NominalDiameter = [], Weight = [], InsulationMaterial = [], Standard = [],
              Material = [], Length = [], Unit = [], ParentId = [], MaterialNumber = []
  
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                KKS = _this.ishaskey(KKS, 'KKS', data[i])
                Guid = _this.ishaskey(Guid, 'Guid', data[i])
                Name = _this.ishaskey(Name, 'Name', data[i])
                Specification = _this.ishaskey(Specification, 'Specification', data[i])
                ComponentModel = _this.ishaskey(ComponentModel, 'ComponentModel', data[i])
                WallThickness = _this.ishaskey(WallThickness, 'WallThickness', data[i])
                InsulationThickness = _this.ishaskey(InsulationThickness, 'InsulationThickness', data[i])
                NominalDiameter = _this.ishaskey(NominalDiameter, 'NominalDiameter', data[i])
                Weight = _this.ishaskey(Weight, 'Weight', data[i])
                InsulationMaterial = _this.ishaskey(InsulationMaterial, 'InsulationMaterial', data[i])
                Standard = _this.ishaskey(Standard, 'Standard', data[i])
                Material = _this.ishaskey(Material, 'Material', data[i])
                Length = _this.ishaskey(Length, 'Length', data[i])
                Unit = _this.ishaskey(Unit, 'Unit', data[i])
                ParentId = _this.ishaskey(ParentId, 'ParentId', data[i])
                MaterialNumber = _this.ishaskey(MaterialNumber, 'MaterialNumber', data[i])
              }
              axios.post('/DATA/ParaSet/ImportEquipmentList.aspx?flag=1', {
                KKS: KKS.join(','),
                Guid: Guid.join(','),
                Name: Name.join(','),
                Specification: Specification.join(','),
                ComponentModel: ComponentModel.join(','),
                WallThickness: WallThickness.join(','),
                InsulationThickness: InsulationThickness.join(','),
                NominalDiameter: NominalDiameter.join(','),
                Weight: Weight.join(','),
                InsulationMaterial: InsulationMaterial.join(','),
                Standard: Standard.join(','),
                Material: Material.join(','),
                Length: Length.join(','),
                Unit: Unit.join(','),
                ParentId: ParentId.join(','),
                Version: _this.state.versionObj.Version,
                MaterialNumber: MaterialNumber.join(',')
              }).then(res => {
                console.log(res)
                if (res.data.success) {
                  if (this.state.selectedKeysA[0]) {
                    _this.getListData()
                  }
                  // $('.listmupload').css('display', "none")
                  this.setState({
                    addeditname: "",
                    visible: false
                  })
                  message.success('导入成功！')
                } else {
                  var str = res.data.data
                  console.log(str)
                  this.setState({
                    uploadreturninfo:str
                  })
                }
              })
            }
  
          }
  
          // 最终获取到并且格式化后的 json 数据              
          // *****此处写需要上传的地址，即：接受数据的地方**************            
        }
        catch (e) {
          // 这里可以抛出文件类型错误不正确的相关提示               
          message.error('文件类型不正确！');
        }
      };
      // 以二进制方式打开文件        
      fileReader.readAsBinaryString(files[0]);
    }
  }

  ishaskey(arr, key, obj) {
    if (obj[key]) {
      arr.push(obj[key])
    } else {
      arr.push('')
    }
    return arr;
  }
  //下载文件
  downloadModule() {
    window.open('/DATA/ParaSet/ImportEquipmentList.aspx?flag=2')
  }
  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 50 - 28) / 28),
    })
  }

  senddata(record) {
    //console.log(record)
    var doclistid = []
    var doclist = []
    doclistid.push(record.ID)
    doclist.push(record)
    this.setState({
      rowId: record.ID,
      rowObj: record,
      doclistsele: doclist,
      doclistidsele: doclistid
    })
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }
  senddata1(record) {
    //console.log(record)
    this.setState({
      rowId1: record.ID,
      rowObj1: record,
    })
    $('.logdetail').css("display", "none")
  }
  setRowClassName1 = (record) => {
    return record.ID === this.state.rowId1 ? 'clickRowStyl' : 'clickRowSty';
  }
  senddata2(record) {
    //console.log(record)
    this.setState({
      rowId2: record.ID,
      rowObj2: record,
    })
  }
  setRowClassName2 = (record) => {
    return record.ID === this.state.rowId2 ? 'clickRowStyl' : 'clickRowSty';
  }


  // getafterKKS(selectedKKS, arr) {
  //   if (arr.length > 0) {
  //     for (let i = 0; i < arr.length; i++) {
  //       selectedKKS.push(arr[i].key)
  //       if (arr[i].props.children) {
  //         this.getafterKKS(selectedKKS, arr[i].props.children)
  //       }
  //     }
  //   }
  // }

  // firstKKS(selectedKKS, arr) {
  //   if (arr.length > 0) {
  //     for (let i = 0; i < arr.length; i++) {
  //       selectedKKS.push(arr[i].key)
  //       if (arr[i].props.children) {
  //         this.getafterKKS(selectedKKS, arr[i].props.children)
  //       }
  //     }
  //   }
  //   return selectedKKS;
  // }
  //数组合并去重
  MergeArray(arr1, arr2) {
    var _arr = new Array();
    for (var i = 0; i < arr1.length; i++) {
      _arr.push(arr1[i]);
    }
    for (var i = 0; i < arr2.length; i++) {
      var flag = true;
      for (var j = 0; j < arr1.length; j++) {
        if (arr2[i] == arr1[j]) {
          flag = false;
          break;
        }
      }
      if (flag) {
        _arr.push(arr2[i]);
      }
    }
    return _arr;
  }
  //去除一个数组中与另一个数组中的值相同的元素
  array_diff(a, b) {
    for (var i = 0; i < b.length; i++) {
      for (var j = 0; j < a.length; j++) {
        if (a[j] == b[i]) {
          a.splice(j, 1);
          j = j - 1;
        }
      }
    }
    return a;
  }

  onTreeSelect(selectedKeys, info) {
    // console.log(selectedKeys, info)
    if (selectedKeys[0]) {
      this.setState({
        selectedKeysA: selectedKeys,
        Leaf: info.node.props.Leaf,
        TreeCodeA: selectedKeys[0]
      }, function () {
        this.getListData()
      })
    } else {
      this.setState({
        selectedKeysA: selectedKeys,
        Leaf: "",
        TreeCodeA: "",
        dataSource: [],
        dataSourcelen: 0
      })
    }
  }
  pagechange(key, page) {
    //console.log(key, page)
    if (key == "清单") {
      this.setState({
        pageIndex: page.current
      }, function () {
        this.getListData()
      })
    } else if (key == "日志") {
      this.setState({
        pageIndex1: page.current
      }, function () {
        this.logFunc()
      })
    } else if (key == "版本") {
      this.setState({
        pageIndex2: page.current
      })
    } else if (key == "日志详情") {
      this.setState({
        pageIndex11: page.current
      }, function () {
        this.lookFunc()
      })
    }
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDrag(e, ui) {
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  tocloselistmanagementmoduleload() {
    this.setState({
      iptobj: {}
    })
    this.props.form.resetFields();
    document.querySelector('.listmanagementmoduleload').style.display = "none"
  }
  //
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      //console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          }
        }
        if (this.state.addeditname == "构件") {
          values.Version = this.state.versionObj.Version
          if (this.state.addeditflag == "添加") {
            values.ParentId = this.state.TreeCodeA
            axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=2', values).then(data => {
              //console.log(data)
              if (data.data.success) {
                this.setState({
                  selectvalue: "",
                  inputvalue: "",
                  visible: false,
                  rowId: "",
                  rowObj: {},
                  doclistidsele: [],
                  doclistsele: [],
                }, function () {
                  message.success('保存成功！')
                  this.getListData()
                })
              } else if (typeof data.data == "string" && data.data.indexOf('err') > -1) {
                message.error('此编码已存在！')
              }
            })
          } else {
            values.ID = this.state.rowId
            values.ParentId = this.state.rowObj.ParentId
            axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=3', values).then(data => {
              if (data.data.success) {
                this.setState({
                  selectvalue: "",
                  inputvalue: "",
                  rowId: "",
                  rowObj: {},
                  visible: false,
                  doclistidsele: [],
                  doclistsele: [],
                }, function () {
                  message.success('保存成功！')
                  this.getListData()
                })
              } else if (typeof data.data == "string" && data.data.indexOf('err') > -1) {
                message.error('此编码已存在！')
              }
            })
          }
        } else if (this.state.addeditname == "版本") {
          if (this.state.addeditflag == "添加") {
            axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=8', values).then(data => {
              //console.log(data)
              if (data.data.success) {
                if (!this.state.versionObj.Version) {
                  this.setState({
                    versionObj: values
                  })
                }
                this.setState({
                  visible: false,
                  rowId2: "",
                  rowObj2: {}
                }, function () {
                  message.success('保存成功！')
                  this.versionFunc()
                })
              } else if (typeof data.data == "string" && data.data.indexOf('err') > -1) {
                message.error('此版本已存在！')
              }
            })
          } else {
            values.ID = this.state.rowId2
            axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=11', values).then(data => {
              if (data.data.success) {
                this.setState({
                  visible: false,
                  rowId2: "",
                  rowObj2: {}
                }, function () {
                  message.success('保存成功！')
                  this.versionFunc()
                })
              } else if (typeof data.data == "string" && data.data.indexOf('err') > -1) {
                message.error('此版本已存在！')
              }
            })
          }
        }
      }
    });
  };
  //搜索
  searchFunc() {
    if (this.state.versionObj.Version) {
      if (this.state.moresearchcondsflag) {
        this.setState({
          counflag: true
        }, function () {
          var selectvaluearr = []
          var inputvaluearr = []
          var logicalvaluearr = []
          if (this.state.selectvalue && this.state.inputvalue) {
            selectvaluearr.push(this.state.selectvalue)
            inputvaluearr.push(this.state.inputvalue)
            logicalvaluearr.push(this.state.logicalvalue)
          }
          var arr = this.state.morecondition
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].selectvalue && arr[i].inputvalue) {
              selectvaluearr.push(arr[i].selectvalue)
              inputvaluearr.push(arr[i].inputvalue)
              logicalvaluearr.push(arr[i].logicalvalue)
            }
          }
          axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=1', {
            ParentId: this.state.selectedKeysA[0],
            Columns: selectvaluearr.join(','),
            SearchKey: inputvaluearr.join(','),
            Logical: logicalvaluearr.join(','),
            PageIndex: 1,
            PageSize: this.state.pageSize,
            Version: this.state.versionObj.Version
          }).then(res => {
            //console.log(res)
            var str = 0
            if (conversion(res).length > 0) {
              str = res.data.slice(2).split(',')[0].split(':')[1]
            } else {
              str = 0
            }
            //console.log(res, str)
            this.setState({
              dataSource: conversion(res),
              dataSourcelen: Number(str),
              pageIndex: 1,
              counflag: false,
              rowId: "",
              rowObj: {}
            })
          })
        })
      } else {
        if (this.state.selectvalue && this.state.inputvalue) {
          this.setState({
            pageIndex: 1,
            counflag: true
          }, function () {
            this.getListData()
          })
        } else {
          message.error('请输入条件！')
        }
      }
    } else {
      message.error('请先创建版本！')
    }
  }
  //高级搜索
  highsearchFunc() {
    var moresearchcondsflag = this.state.moresearchcondsflag
    this.setState({
      moresearchcondsflag: !moresearchcondsflag,
    })
  }
  //搜索重置
  resetsearchFunc() {
    this.setState({
      selectvalue: "",
      inputvalue: "",
      morecondition: [{ selectvalue: "", inputvalue: "", logicalvalue: "or" }],
      pageIndex: 1
    }, function () {
      this.getListData()
    })
  }
  //上传
  uploadFunc() {
    this.setState({
      addeditname: "上传构件清单",
      visible: true,
      uploadreturninfo:""
    })
    // $('.listmupload').css('display', "block")
  }
  //文件上传窗口关闭
  closedragwiddow() {
    $('.listmupload').css('display', "none")
    $('.logdetail').css('display', "none")
  }
  onClose() {
    this.setState({
      iptobj: {},
      visible: false,
      addeditname: "",
      addeditflag: "",
      Versionaddflag: false
    })
  }
  //添加
  addFunc() {
    if (this.state.selectedKeysA[0]) {
      this.setState({
        iptobj: {},
        visible: true,
        addeditflag: "添加",
        addeditname: '构件'
      }, function () {
        this.props.form.resetFields()
      })
    } else {
      message.error('请选择！')
    }
  }
  editFunc(key) {
    if ((key == "构件" && this.state.rowId) || (key == "版本" && this.state.rowId2)) {
      this.setState({
        iptobj: key == "构件" ? this.state.rowObj : key == "版本" ? this.state.rowObj2 : {},
        visible: true,
        addeditflag: "编辑",
        addeditname: key
      }, function () {
        this.props.form.resetFields()
      })
    } else {
      message.error('请选择！')
    }
  }
  deleteFunc(key) {
    //console.log(this.state.doclistidsele)
    if (key == "清单") {
      if (this.state.doclistidsele.length > 0) {
        var _this = this
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=4', {
              ID: _this.state.doclistidsele.join(',')
            }).then(data => {
              //console.log(data)
              if (data.data.success) {
                _this.getListData()
                message.success('删除成功！')
              } else {
                message.error(data.data.data)
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择!')
      }
    } else if (key == "版本") {
      if (this.state.rowId2) {
        var _this = this
        if (_this.state.rowObj2.Flag == "0") {
          confirm({
            title: '确定要删除吗?',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=12', {
                Version: _this.state.rowObj2.Version
              }).then(data => {
                //console.log(data)
                if (data.data.success) {
                  _this.versionFunc()
                  message.success('删除成功！')
                } else {
                  message.error(data.data.data)
                }
              })
            },
            onCancel() {
              _this.setState({
                // detaillistId: ''
              })
            },
          });
        } else {
          message.error('激活版本不允许删除！')
        }
      } else {
        message.error('请选择!')
      }
    }
  }
  //预览
  reviewFunc() {
    if (this.state.doclistsele.length > 0) {
      var arr = this.state.doclistsele
      var KKSarr = []
      for (let i = 0; i < arr.length; i++) {
        KKSarr.push(arr[i].KKS)
      }
      axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=14', {
        KKS: KKSarr.join(','),
      }).then(res => {
        this.setState({
          KKSarr: KKSarr,
          ModelKeyarrlength: conversion(res).length
        })
        //console.log(res)
        var ModelKeyarr = conversion(res)
        if (ModelKeyarr.length > 0) {
          document.querySelector('.listmanagementmoduleload').style.display = "block"
          var listmanagementmodule = document.getElementById("listmanagementmodule")
          listmanagementmodule.src = `./BOS3dhtml/areatree.html`

          var _this = this
          listmanagementmodule.onload = function () {
            for (let j = 0; j < ModelKeyarr.length; j++) {
              listmanagementmodule.contentWindow.viewer3D.addView(ModelKeyarr[j].ModelKey, "bole");
            }
          }
        } else {
          message.error('无加载的构件模型！')
        }
      })
    } else {
      message.error('请选择！')
    }
  }

  getGuid(KKSarr) {
    var listmanagementmodule = document.getElementById("listmanagementmodule")
    //获取KKS
    var guidgroup = [];
    axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=15', {
      KKS: KKSarr.join(',')
    }).then(res1 => {
      var guidarr = conversion(res1)
      if (guidarr.length > 0) {
        for (let m = 0; m < guidarr.length; m++) {
          guidgroup.push(guidarr[m].Guid)
        }
        listmanagementmodule.contentWindow.viewer3D.transparentOtherComponentsByKey(guidgroup)
        listmanagementmodule.contentWindow.viewer3D.highlightComponentsByKey(guidgroup)
        listmanagementmodule.contentWindow.viewer3D.adaptiveSize(guidgroup);
      }
    })
  }


  //导出报表
  importFunc() {
    if (this.state.selectedKeysA[0]) {
      var factory = sessionStorage.getItem('factory')
      var ParentId = this.state.selectedKeysA[0]
      var Version = this.state.versionObj.Version ? this.state.versionObj.Version : ""
      if (Version) {
        window.open(`http://47.97.112.136:8080/WebReport/ReportServer?reportlet=B-SPPs/CheckEquipmentList.cpt&company=${encodeURI(factory)}&ParentId=${ParentId}&Version=${Version}`)
      } else {
        message.error('请創建版本！')
      }
    } else {
      message.error('请选择！')
    }
  }
  logFunc() {
    var startendtime = this.state.startendtime
    var StartTime = ""
    var EndTime = ""
    if (startendtime.length > 0) {
      StartTime = startendtime[0]
      EndTime = startendtime[1]
      // StartTime = formatTimeDate(startendtime[0])
      // EndTime = formatTimeDate(startendtime[1])
    }
    axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=6', {
      PageIndex: this.state.pageIndex1,
      PageSize: this.state.pageSize,
      StartTime: StartTime,
      EndTime: EndTime,
    }).then(res => {
      //console.log(res)
      var str = res.data.slice(2).split(',')[0].split(':')[1]
      this.setState({
        equipmentFlag: "日志",
        dataSource1: conversion(res),
        dataSourcelen1: Number(str)
      })
    })
  }
  versionFunc() {
    axios.get('/DATA/ParaSet/EquipmentList.aspx?flag=9').then(res => {
      //console.log(res)
      this.setState({
        equipmentFlag: "版本",
        dataSource2: conversion(res),
        dataSourcelen2: conversion(res).length
      })
    })
  }
  //日志 查阅
  lookFunc() {
    if (this.state.rowId1) {
      axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=7', {
        ParentId: this.state.rowId1,
        PageIndex: this.state.pageIndex11,
        PageSize: 12
      }).then(res => {
        //console.log('详情', res)
        var str = res.data.slice(2).split(',')[0].split(':')[1]
        this.setState({
          dataSource11: conversion(res),
          dataSourcelen11: Number(str)
        })
        $('.logdetail').css("display", "block")
      })
    } else {
      message.error('请选择！')
    }
  }

  closeLogFunc() {
    this.setState({
      equipmentFlag: ""
    })
  }

  //版本操作
  startVerFunc() {
    if (this.state.rowId2) {
      axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=10', {
        Version: this.state.rowObj2.Version
      }).then(res => {
        //console.log(res)
        if (res.data.success) {
          if ((this.state.versionObj.Version && this.state.versionObj.Version != this.state.rowObj2.Version) ||
            !this.state.versionObj.Version) {
            this.setState({
              versionObj: this.state.rowObj2,
              checkedKeysA: [],
              dataSource: [],
              pageIndex2: 1
            }, function () {
              this.initPageData('启用刷新')
              this.versionFunc()
            })
          }
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  addVerFunc() {
    var obj = {}
    var Versiondata = this.state.dataSource2
    if (Versiondata.length > 0) {
      obj.Version = Number(Versiondata[Versiondata.length - 1].Version) + 1
    } else {
      obj.Version = 1
    }
    this.setState({
      visible: true,
      Versionaddflag: true,
      addeditflag: '添加',
      addeditname: "版本",
      iptobj: obj
    }, function () {
      this.props.form.resetFields()
    })
  }

  //高级搜索

  conditon1change(key, value) {
    //console.log(key, value)
    var arr = this.state.morecondition
    arr[key].selectvalue = value
    this.setState({
      morecondition: arr
    })
  }
  conditon2change(key, e) {
    //console.log(key, e)
    var arr = this.state.morecondition
    arr[key].inputvalue = e.target.value
    this.setState({
      morecondition: arr
    })
  }
  conditon3change(key, v) {
    var arr = this.state.morecondition
    arr[key].logicalvalue = v
    this.setState({
      morecondition: arr
    })
  }
  addcondition(key) {
    var arr = this.state.morecondition
    var obj = { selectvalue: '', inputvalue: "", logicalvalue: "or" }
    arr.splice(key + 1, 0, obj);
    this.setState({
      morecondition: arr
    })
  }
  delcondition(key) {
    var arr = this.state.morecondition
    var newarr = arr.filter(function (element, index) {
      return key != index;
    });
    this.setState({
      morecondition: newarr
    })
  }
  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  render() {
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    const components = {
      header: {
        cell: ResizeableTitle
      }
    }
    const rowSelection = {
      selectedRowKeys: this.state.doclistidsele,
      onChange: (selectedRowKeys, selectedRows) => {
        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        var arr = selectedRows
        var newarr = []
        for (let i = 0; i < arr.length; i++) {
          newarr.push(arr[i].ID)
        }
        this.setState({
          doclistidsele: newarr,
          doclistsele: arr
        })
      },
      onSelect: (record, selected, selectedRows) => {
        //console.log(record, selected, selectedRows);
        if (selected) {
          this.setState({
            rowId: record.ID,
            rowObj: record
          })
        } else {
          if (record.ID == this.state.rowId) {
            this.setState({
              rowId: "",
              rowObj: {}
            })
          }
        }
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        //console.log(selected, selectedRows, changeRows);
      },
    };
    const { getFieldDecorator } = this.props.form;
    const loop = data =>
      data.map(item => {
        if (item.children) {
          return (
            <TreeNode key={item.Code} title={item.Code + '-' + item.Name} Name={item.Name} Leaf={item.Leaf}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.Code} title={item.Code + '-' + item.Name} Name={item.Name} Leaf={item.Leaf} />;
      });
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div className="listmanagement" style={{ height: clientHeight - 50 + 'px' }}>
        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>

        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>

          <div className="dragwindow listmanagementmoduleload" ref="listmanagementmoduleload"
            style={{
              width: '70%', height: clientHeight - 100 + 'px'
            }}>
            <h2>模型预览<Button onClick={this.tocloselistmanagementmoduleload.bind(this)}>关闭</Button>
            </h2>
            <div className="listmanagementmoduleloadcon" style={{
              width: '100%', height: clientHeight - 150 + 'px'
            }} onMouseDown={(e) => { e.stopPropagation() }} >
              <iframe title="myiframe" src="" id="listmanagementmodule" style={{
                width: '100%', height: clientHeight - 150 + 'px'
              }}></iframe>
            </div>
          </div>
        </Draggable>

        <Draggable
          position={this.state.controlledPosition}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDrag.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <Fragment>
            <div className="dragwindow listmupload">
              <h2>上传构件清单 <span className="close" onClick={this.closedragwiddow.bind(this)}>x</span></h2>
              <div className="uploaddocument">
                <span className="importexcel">
                  <span>导入文件</span>
                  <form id="fileform">
                    <input type='file' disabled={!this.state.versionObj.Version ? true : false} accept='.xlsx, .xls' onChange={this.onImportExcel.bind(this)} />
                  </form>
                </span>
                <Button onClick={this.downloadModule.bind(this)}>
                  下载模板
                </Button>
              </div>
            </div>
            <div className="dragwindow logdetail">
              <h2>查看日志详细内容 <span className="close" onClick={this.closedragwiddow.bind(this)}>x</span></h2>
              <div className="logdetaillist listpage">
                <Table
                  rowKey={record => record.ID}
                  size="small"
                  dataSource={this.state.dataSource11}
                  columns={this.state.columns11}
                  pagination={{
                    pageSize: 12,
                    current: this.state.pageIndex11,
                    total: this.state.dataSourcelen11
                  }}
                  onChange={this.pagechange.bind(this, '日志详情')}
                />
              </div>
            </div>
          </Fragment>
        </Draggable>

        <Drawer
          title={this.state.addeditflag + this.state.addeditname}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className={this.state.addeditname == "构件" ? "Drawercommon DrawerUser" : "Drawercommon"}
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            {
              this.state.addeditname == "构件" ?
                <Form className="usermanageform" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="名称">
                        {getFieldDecorator('Name', {
                          initialValue: this.state.iptobj["Name"],
                          rules: [{ required: true, message: () => { return; } }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={36}>
                      <Form.Item label="KKS编码">
                        {getFieldDecorator('KKS', {
                          initialValue: this.state.iptobj["KKS"],
                          rules: [{ required: false, message: () => { return; } }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="物料编码">
                        {getFieldDecorator('MaterialNumber', {
                          initialValue: this.state.iptobj["MaterialNumber"],
                          rules: [{ required: false, message: () => { return; } },],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={36}>
                      <Form.Item label="规格">
                        {getFieldDecorator('Specification', {
                          initialValue: this.state.iptobj["Specification"],
                          rules: [{ required: false, message: () => { return (<span></span>) } }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="型号">
                        {getFieldDecorator('ComponentModel', {
                          initialValue: this.state.iptobj["ComponentModel"],
                          rules: [{ required: false, message: () => { return; } },],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={36}>
                      <Form.Item label="单位">
                        {getFieldDecorator('Unit', {
                          initialValue: this.state.iptobj["Unit"],
                          rules: [{ required: false, message: () => { return; } },],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="数量">
                        {getFieldDecorator('Length', {
                          initialValue: this.state.iptobj["Length"],
                          rules: [{ required: false, message: () => { return; } }, {
                            pattern: /^(\-|\+)?\d+(\.\d+)?$/, message: '格式不正确'
                          }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={36}>
                      <Form.Item label="重量">
                        {getFieldDecorator('Weight', {
                          initialValue: this.state.iptobj["Weight"],
                          rules: [{ required: false, message: () => { return; } }, {
                            pattern: /^(\-|\+)?\d+(\.\d+)?$/, message: '格式不正确'
                          }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="标准">
                        {getFieldDecorator('Standard', {
                          initialValue: this.state.iptobj["Standard"],
                          rules: [{ required: false, message: () => { return; } },],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={36}>
                      <Form.Item label="材料">
                        {getFieldDecorator('Material', {
                          initialValue: this.state.iptobj["Material"],
                          rules: [{ required: false, message: () => { return; } },],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="壁厚">
                        {getFieldDecorator('WallThickness', {
                          initialValue: this.state.iptobj["WallThickness"],
                          rules: [{ required: false, message: () => { return; } }, {
                            pattern: /^(\-|\+)?\d+(\.\d+)?$/, message: '格式不正确'
                          }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={36}>
                      <Form.Item label="保温层厚度">
                        {getFieldDecorator('InsulationThickness', {
                          initialValue: this.state.iptobj["InsulationThickness"],
                          rules: [{ required: false, message: () => { return; } }, {
                            pattern: /^(\-|\+)?\d+(\.\d+)?$/, message: '格式不正确'
                          }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="公称直径">
                        {getFieldDecorator('NominalDiameter', {
                          initialValue: this.state.iptobj["NominalDiameter"],
                          rules: [{ required: false, message: () => { return; } }, {
                            pattern: /^(\-|\+)?\d+(\.\d+)?$/, message: '格式不正确'
                          }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={36}>
                      <Form.Item label="保温层材料">
                        {getFieldDecorator('InsulationMaterial', {
                          initialValue: this.state.iptobj["InsulationMaterial"],
                          rules: [{ required: false, message: () => { return; } },],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                        </Button>
                    </Form.Item>
                  </Row>
                </Form> :
                this.state.addeditname == "版本" ?

                  <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="版本号">
                          {getFieldDecorator('Version', {
                            initialValue: this.state.iptobj["Version"],
                            rules: [{ required: true, message: () => { return; } }],
                          })(
                            <Input disabled />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    {
                      this.state.Versionaddflag ?
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="复制老版本号">
                              {getFieldDecorator('CopyVersion', {
                                initialValue: this.state.iptobj["CopyVersion"],
                                rules: [{ required: false, message: () => { return; } }],
                              })(
                                <Select>
                                  {
                                    this.state.dataSource2.map((item, index) => {
                                      return (<Option value={item.Version} key={item.Version}>{item.Version}</Option>)
                                    })
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row> : ""
                    }
                    {/* <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="版本名称">
                          {getFieldDecorator('VersionName', {
                            initialValue: this.state.iptobj["VersionName"],
                            rules: [{ required: false, message: () => { return (<span></span>) } }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row> */}
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="版本描述">
                          {getFieldDecorator('VersionRemark', {
                            initialValue: this.state.iptobj["VersionRemark"],
                            rules: [{ required: false, message: () => { return; } },],
                          })(
                            <TextArea rows={2} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
                      </Button>
                      </Form.Item>
                    </Row>
                  </Form> :
                  this.state.addeditname == "上传构件清单" ?
                    <div className="listmoduleupload">
                      <span className="importexcel">
                        <span>导入文件</span>
                        <form id="fileform">
                          <input type='file' disabled={!this.state.versionObj.Version ? true : false} 
                          onClick={(event)=> { event.target.value = null ;this.setState({uploadreturninfo:""})}}
                          accept='.xlsx, .xls' onChange={this.onImportExcel.bind(this)} />
                        </form>
                      </span>
                        {this.state.uploadreturninfo?
                        <p>
                          <img src={require('../../assets/jinggaotishi.png')} />
                         {this.state.uploadreturninfo}
                      </p>:""
                        }
                      <Button onClick={this.downloadModule.bind(this)}>
                        下载模板
                        </Button>
                    </div> : ""

            }
          </div>
        </Drawer>

        <div className="listmanagementleft">
          <h2 className="h2title">设备清单管理</h2>
          <div className="actionall">
            <span>专业树</span>
          </div>
          <div className="tree" style={{ height: clientHeight - 50 - 80 + 'px' }}>
            <Tree
              // checkable
              // onCheck={this.onCheckA.bind(this)}
              // checkedKeys={this.state.checkedKeysA}
              expandedKeys={this.state.expandedKeys}
              onExpand={(expandedKeys) => { this.setState({ expandedKeys }) }}
              selectedKeys={this.state.selectedKeysA}
              onSelect={this.onTreeSelect.bind(this)}
            >
              {loop(this.state.treeData)}
            </Tree>
          </div>
        </div>
        <div className="listmanagementright" style={{width:clientWidth -200 +'px'}}>
          <div className="moresearchconds" style={{ display: this.state.moresearchcondsflag ? "block" : "none" }}>
            {
              this.state.morecondition.map((item, index) => {
                return (<div className="moresearchcond">
                  <Select value={item.selectvalue} onChange={this.conditon1change.bind(this, index)}>
                    {
                      this.state.columnssearch.map((item, index) => {
                        return (<Option key={item.key} value={item.key}>{item.value}</Option>)
                      })
                    }
                  </Select>
                  <Input value={item.inputvalue} onChange={this.conditon2change.bind(this, index)} />
                  <Select className="logicalselect" value={item.logicalvalue} onChange={this.conditon3change.bind(this, index)}>
                    <Option value="or" key="or">或</Option>
                    <Option value="and" key="and">并</Option>
                  </Select>
                  <span className="addrow"><img onClick={this.addcondition.bind(this, index)} src={require('../../assets/jiahao.png')} /></span>
                  {
                    index > 0 ?
                      <span className="deleterow"><img onClick={this.delcondition.bind(this, index)} src={require('../../assets/jianhao.png')} /></span> : ""
                  }
                </div>)
              })
            }
          </div>
          <div className="actionall">
            <span className="actionleft">
              <Select placeholder="名称" value={this.state.selectvalue} onChange={(v) => { this.setState({ selectvalue: v }) }}>
                {
                  this.state.columnssearch.map((item, index) => {
                    return (<Option key={item.key} value={item.key}>{item.value}</Option>)
                  })
                }
              </Select>
              <Input value={this.state.inputvalue} onChange={(e) => { this.setState({ inputvalue: e.target.value }) }} />
              <Select className="logicalselect" value={this.state.logicalvalue} onChange={(v) => { this.setState({ logicalvalue: v }) }}>
                <Option value="or" key="or">或</Option>
                <Option value="and" key="and">并</Option>
              </Select>
              <img onClick={this.searchFunc.bind(this)} src={require('../../assets/sousuo.png')} />
              <Button className="twoword" onClick={this.resetsearchFunc.bind(this)}>重置</Button>
              <Button className="twoword" onClick={this.highsearchFunc.bind(this)}>高级搜索</Button>
            </span>
            <span className="actionright">
              <Button className="twoword" style={{ display: this.state.buttonarr.indexOf('上传') > -1 ? "inline-block" : "none" }} disabled={!this.state.versionObj.Version ? true : false}
                onClick={this.uploadFunc.bind(this)}>上传</Button>
              <Button className="twoword" style={{ display: this.state.buttonarr.indexOf('添加') > -1 ? "inline-block" : "none" }}
                disabled={!this.state.versionObj.Version ? true : false} onClick={this.addFunc.bind(this)}>添加</Button>
              <Button className="twoword" style={{ display: this.state.buttonarr.indexOf('修改') > -1 ? "inline-block" : "none" }} onClick={this.editFunc.bind(this, '构件')}>修改</Button>
              <Button className="twoword" style={{ display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }} onClick={this.deleteFunc.bind(this, '清单')}>删除</Button>
              {/* <Button className="twoword" onClick={this.checkFunc.bind(this)}>校对</Button> */}
              <Button className="twoword" style={{ display: this.state.buttonarr.indexOf('预览') > -1 ? "inline-block" : "none" }} onClick={this.reviewFunc.bind(this)}>预览</Button>
              <Button className="twoword" style={{ display: this.state.buttonarr.indexOf('导出') > -1 ? "inline-block" : "none" }} onClick={this.importFunc.bind(this)}>导出</Button>
              {/* <Button className="twoword" style={{ display: this.state.buttonarr.indexOf('日志') > -1 ? "inline-block" : "none" }} onClick={this.logFunc.bind(this)}>日志</Button> */}
              <Button className="twoword" style={{ display: this.state.buttonarr.indexOf('版本') > -1 ? "inline-block" : "none" }} onClick={this.versionFunc.bind(this)}>版本</Button>
            </span>
          </div>
          <div className="listpagescroll listmanagetable"
            style={{ height: clientHeight - 50 - 40 + 'px' }}>
            <Table
              rowSelection={rowSelection}
              rowKey={record => record.ID}
              size="small"
              dataSource={this.state.dataSource}
              // bordered 
              components={components}
              columns={columns}
              pagination={{
                showQuickJumper: true,
                pageSize: this.state.pageSize,
                current: this.state.pageIndex,
                total: this.state.dataSourcelen
              }}
              scroll = {{x:1600}}
              onChange={this.pagechange.bind(this, '清单')}
              onRow={(record) => {
                return {
                  onClick: this.senddata.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassName}
            />
          </div>
        </div>

        {
          this.state.equipmentFlag == "日志" ?
            <div className="loglistcon" style={{ height: clientHeight - 50 + 'px' }}>
              <div className="actionall">
                <span className="actionleft">
                  <span className="logtitle">日志列表</span> &nbsp;  搜索日期:
                  <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(value, dateString) => { this.setState({ startendtime: dateString }) }}
                  />
                  <img onClick={() => { this.logFunc() }} src={require('../../assets/sousuo.png')} />
                  {/* <RangePicker value={this.state.startendtime} onChange={(v) => { this.setState({ startendtime: v }) }} /> */}
                </span>
                <span className="actionright">
                  <Button className="twoword" onClick={this.lookFunc.bind(this)}>查看</Button>
                  <Button className="twoword" onClick={this.closeLogFunc.bind(this)}>关闭</Button>
                </span>
              </div>
              <div className="listpage"
                style={{ height: clientHeight - 50 - 50 + 'px' }}>
                <Table
                  rowKey={record => record.ID}
                  size="small"
                  dataSource={this.state.dataSource1}
                  columns={this.state.columns1}
                  pagination={{
                    pageSize: this.state.pageSize,
                    current: this.state.pageIndex1,
                    total: this.state.dataSourcelen1
                  }}
                  onChange={this.pagechange.bind(this, '日志')}
                  onRow={(record) => {
                    return {
                      onClick: this.senddata1.bind(this, record)
                    }
                  }}
                  rowClassName={this.setRowClassName1}
                />
              </div>
            </div> :
            this.state.equipmentFlag == "版本" ?
              <div className="verlistcon" style={{ height: clientHeight - 50 + 'px' }}>
                <div className="actionall">
                  <span className="actionleft">
                    <span className="vertitle">版本列表</span> &nbsp;
                {/* 搜索日期: <RangePicker /> */}
                  </span>
                  <span className="actionright">
                    <Button className="twoword" onClick={this.startVerFunc.bind(this)}>启用</Button>
                    <Button className="twoword" onClick={this.addVerFunc.bind(this)}>创建</Button>
                    <Button className="twoword" onClick={this.editFunc.bind(this, '版本')}>修改</Button>
                    <Button className="twoword" onClick={this.deleteFunc.bind(this, '版本')}>删除</Button>
                    <Button className="twoword" onClick={this.closeLogFunc.bind(this)}>关闭</Button>
                  </span>
                </div>
                <div className="listpage"
                  style={{ height: clientHeight - 50 - 50 + 'px' }}>
                  <Table
                    rowKey={record => record.ID}
                    size="small"
                    dataSource={this.state.dataSource2}
                    columns={this.state.columns2}
                    pagination={{
                      pageSize: this.state.pageSize,
                      current: this.state.pageIndex2,
                      total: this.state.dataSourcelen2
                    }}
                    onChange={this.pagechange.bind(this, '版本')}
                    onRow={(record) => {
                      return {
                        onClick: this.senddata2.bind(this, record)
                      }
                    }}
                    rowClassName={this.setRowClassName2}
                  />
                </div>
              </div> : ""
        }
      </div>
    )
  }
}
const ListManagementapp = Form.create()(ListManagement);
export default ListManagementapp;