import React from 'react';
import { Input, Spin, message, Button, Select, Radio, Drawer, Form, Col, Row, Badge, Tabs, Tooltip, Table, DatePicker, Modal, InputNumber, TimePicker } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import Draggable from 'react-draggable';
import $ from 'jquery'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import './style.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { onCellf } from '../../comfunc'
import store from '../../store';
import { getButtonLimit } from '../../utils/getbuttonlimit'
moment.locale('zh-cn')
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { TextArea, Search } = Input
const confirm = Modal.confirm

class Inspectioncategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr: [],

      counflag: false,
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      controlledPositionB: {
        x: 0, y: 0
      },
      uname: '',

      iptobj: {},
      addedit: "添加",

      data1: [],
      verdetailid: '',
      verdetailidobj: {},

      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 70 - 50) / 28),
      pageIndex1: 1,
      pageIndexA: 1,

      selectedRowKeysA: [],
      idliststrout: '',
      //移入的数据
      selectedRowKeysB: [],
      selectedRowsA: [],
      idliststrin: '',
      codeliststrin: '',
      nameliststrin: '',
      //所有专业
      majorarr: [],
      majorselect: "",

      data2: [],
      data3: [],
      dataSourceall: [],//所有专业得数据
      dataSourceout: [],


      //banben
      dataA: [],

      //巡检任务定期
      montharr: [],//月份遍历

      weeksele: [],//周选择
      monthsele: [],//月选中

      regular4sele: 0,
    }
    //巡检路径
    this.data1columns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '10%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '路径编号',
        dataIndex: 'PathCode',
        key: 'PathCode',
        width: '45%',
      },
      {
        title: '路径名称',
        dataIndex: 'PathName',
        key: 'PathName',
        width: '45%',
      },
    ]

    this.data2columns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '12%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{index + 1}</span>
        }
      }, {
        title: '设备编号',
        dataIndex: 'Code',
        key: 'Code',
        width: '38%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '设备名称',
        dataIndex: 'ModelName',
        key: 'ModelName',
        width: '38%',
        onCell: onCellf,
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      }
    ]

    this.data3columns = [
      {
        title: '编号',
        dataIndex: 'Code',
        key: 'Code',
        width: '45%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '45%',
        onCell: onCellf,
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
    ]

    this.dataAcolumns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '6%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndexA - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '版本号',
        dataIndex: 'PathVersions',
        key: 'PathVersions',
        width: '33%',
      },
      {
        title: '版本名称',
        dataIndex: 'VersionsName',
        key: 'VersionsName',
        width: '33%',
      },
      {
        title: '创建时间',
        dataIndex: 'CreateTime',
        key: 'CreateTime',
        width: '20%',
      },
      {
        title: '状态',
        dataIndex: 'PathTag',
        key: 'PathTag',
        width: '8%',
        render: (text) => {
          if (text == "1") {
            return (<div style={{ display: "flex", justifyContent: "center" }}>
              <span style={{ width: "10px", height: "10px", background: "rgb(54, 180, 197)", display: "block" }}></span>

            </div>)
          } else {
            return (<div style={{ display: "flex", justifyContent: "center" }}>
              <span style={{ width: "10px", height: "10px", background: "#ccc", display: "block" }}></span>
            </div>)
          }
        }
      },
    ]
    this.dataAtaskcolumns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '8%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '任务名称',
        dataIndex: 'TaskName',
        key: 'TaskName',
        width: '15%',
      },
      {
        title: '关联路径信息',
        dataIndex: 'PathName',
        key: 'PathName',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '任务周期',
        dataIndex: 'TaskCycle',
        key: 'TaskCycle',
        width: '16%',
      },
      {
        title: '任务捐派角色',
        dataIndex: 'AssignedRole',
        key: 'AssignedRole',
        width: '18%',
        onCell: onCellf,
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '任务要求',
        dataIndex: 'InspectionRequirements',
        key: 'InspectionRequirements',
        width: '16%',
      }]
    this.timetostandard = this.timetostandard.bind(this)
    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="bottom" className="managetooltip" title={text}>{text}</Tooltip>)
    }
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr: res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(60)

    var arr = []
    for (let i = 1; i < 32; i++) {
      arr.push(i + '号')
    }
    this.setState({
      montharr: arr
    })
    //日历
    var uname = sessionStorage.getItem('uname')
    this.setState({
      uname
    })
    axios.get('/DATA/OM/OM_PatrolPath.aspx?flag=1').then(data => {
      console.log('路径', conversion(data))
      this.setState({
        data1: conversion(data)
      })
    })

    //获取专业
    axios.get('/DATA/Paraset/Paraset.aspx?flag=100').then(data => {
      console.log('专业', conversion(data))
      if (conversion(data).length > 0) {
        this.setState({
          majorarr: conversion(data),
          majorselect: conversion(data)[0].Code
        }, function () {
          //专业树数据
          axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res => {
            //console.log(conversion(res))
            let attr = this.state.attr;
            var arr = conversion(res)
            var newarr = []
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].Code.length > 8 && arr[i].Code.indexOf(this.state.majorselect) > -1) {
                newarr.push(arr[i])
              }
            }
            // let arr1 = toProTreeData1(arr, attr)
            //console.log('专业树数据', arr)
            this.setState({
              data3: newarr,
              dataSourceall: arr,
            })
          })
        })
      }
    })

    axios.get('/DATA/UserInfo/UserInfo.aspx?flag=20').then(data => {
      console.log('角色', conversion(data))
      this.setState({
        rolearr: conversion(data)
      })
    })
  }
  componentWillReceiveProps(nextprops) {
    ////console.log(nextprops)
    this.setState({
      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 70 - 50) / 28),
    })
  }


  //选中
  senddata1(record) {
    console.log(record)
    this.setState({
      verdetailid: record.id,
      verdetailidobj: record,
      counflag: true
    }, function () {
      axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=6', {
        Parentid: this.state.verdetailid
      }).then(data => {
        console.log('路径绑定下得模型', conversion(data))
        this.setState({
          data2: conversion(data),
          counflag: false
        })
      })
    })
  }
  setRowClassName1 = (record) => {
    return record.id === this.state.verdetailid ? 'clickRowStyl' : 'clickRowSty';
  }
  setRowClassName22 = (record) => {
    return record.type == "1" ? "patrolbindequiptype1" : ""
  }
  senddata2(record) {
    console.log(record)
    this.setState({
      rowIdB: record.id,
      rowIdBobj: record,
    })
  }
  setRowClassName2 = (record) => {
    return record.id === this.state.rowIdB ? 'clickRowStyl' : 'clickRowSty';
  }

  //添加
  handleAdd(key) {
    if (key == "巡检路径") {
      this.setState({
        addedit: "添加",
        addeditname: key,
        iptobj: {},
        visible: true,
      }, function () {
        this.props.form.resetFields()
      })
    } else if (key == "版本管理") {
      if (this.state.rowIdB) {
        this.setState({
          addedit: "升级",
          addeditname: key,
          iptobj: {},
          visible: true,
        }, function () {
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "巡检任务") {
      if (this.state.verdetailid) {
        this.setState({
          addedit: "添加",
          addeditname: key,
          iptobj: {},
          visible: true,
        }, function () {
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择巡检设备！')
      }
    }
  }
  //编辑
  handleEdit(key) {
    this.props.form.resetFields();
    if (key == "巡检路径") {
      if (this.state.verdetailid) {
        var obj = this.state.verdetailidobj
        this.setState({
          addedit: "编辑",
          addeditname: key,
          iptobj: obj,
          visible: true,
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "版本管理") {
      if (this.state.rowIdB) {
        var obj = this.state.rowIdBobj
        this.setState({
          addedit: "编辑",
          addeditname: key,
          iptobj: obj,
          visible: true,
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "巡检任务") {
      if (this.state.rowIdtask) {
        var obj = this.state.rowIdtaskobj
        this.setState({
          addeditname: "巡检任务",
          addedit: "编辑",
          iptobj: obj,
          visible: true,
        })
      } else {
        message.error('请选择！')
      }
    }
  }
  //保存
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d && !value._i) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
          } else if (value._f == "HH:mm:ss") {
            values[i] = value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else if (value._f == "YYYY-MM-DD") {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
          } else {
            values[i] = values[i]
          }
        }
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "巡检路径") {
            values.CreatePeople = this.state.uname
            axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=2', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.get('/DATA/OM/OM_PatrolPath.aspx?flag=1').then(data => {
                  console.log('路径', conversion(data))
                  this.setState({
                    data1: conversion(data),
                    visible: false
                  })
                })
              }
            })
          } else if (this.state.addeditname == "巡检任务") {
            values.CreatePeople = this.state.uname
            values.PathId = this.state.verdetailidobj.id
            values.PathCode = this.state.verdetailidobj.PathCode
            values.PathName = this.state.verdetailidobj.PathName
            values.PathVersions = this.state.verdetailidobj.PathVersions
            values.PathVersionsName = this.state.verdetailidobj.VersionsName
            if (values.TaskCycle == 2) {
              values.TaskDate = values.TaskDate1.join(',')
            } else if (values.TaskCycle == 3) {
              values.TaskDate = values.TaskDate2.join(',')
            }
            axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=11', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.get('/DATA/OM/OM_PatrolTask.aspx?flag=12').then(data => {
                  console.log('巡检任务', conversion(data))
                  this.setState({
                    datatask: conversion(data),
                    visible: false
                  })
                })
              }
            })
          }
        } else if (this.state.addedit == "升级") {
          if (this.state.addeditname == "版本管理") {
            values.PathCode = this.state.verdetailidobj.PathCode
            values.PathModel = this.state.verdetailidobj.PathModel
            values.PathName = this.state.verdetailidobj.PathName
            values.CreatePeople = this.state.uname
            values.id = this.state.rowIdB
            console.log(values)
            axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=9', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=10', {
                  PathCode: this.state.verdetailidobj.PathCode
                }).then(data => {
                  console.log('路径版本', conversion(data))
                  this.setState({
                    dataA: conversion(data),
                    visible: false
                  })
                })
              }
            })
          }
        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "巡检路径") {
            values.id = this.state.verdetailid
            axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=3', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.get('/DATA/OM/OM_PatrolPath.aspx?flag=1').then(data => {
                  console.log('路径', conversion(data))
                  this.setState({
                    data1: conversion(data),
                    visible: false
                  })
                })
              }
            })
          } else if (this.state.addeditname == "版本管理") {
            values.id = this.state.rowIdB
            axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=11', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=10', {
                  PathCode: this.state.verdetailidobj.PathCode
                }).then(data => {
                  console.log('路径版本', conversion(data))
                  this.setState({
                    dataA: conversion(data),
                    visible: false
                  })
                })
              }
            })
          } else if (this.state.addeditname == "巡检任务") {
            values.CreatePeople = this.state.uname
            values.PathId = this.state.verdetailidobj.id
            values.PathCode = this.state.verdetailidobj.PathCode
            values.PathName = this.state.verdetailidobj.PathName
            values.PathVersions = this.state.verdetailidobj.PathVersions
            values.PathVersionsName = this.state.verdetailidobj.VersionsName
            values.id = this.state.rowIdtask
            if (values.TaskCycle == 2) {
              values.TaskDate = values.TaskDate1.join(',')
            } else if (values.TaskCycle == 3) {
              values.TaskDate = values.TaskDate2.join(',')
            }
            axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=14', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf("true") > -1) {
                axios.get('/DATA/OM/OM_PatrolTask.aspx?flag=12').then(data => {
                  console.log('巡检任务', conversion(data))
                  this.setState({
                    datatask: conversion(data),
                    visible: false
                  })
                })
              }
            })
          }
        }
      }
    });
  };
  //删除
  handleDel(key) {
    var _this = this
    if (key == "巡检路径") {
      if (this.state.verdetailid) {
        var key = this.state.verdetailid
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=4', {
              id: key
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true')) {
                const dataSource = [..._this.state.data1];
                _this.setState({ data1: dataSource.filter(item => item.id !== key), verdetailid: "", verdetailidobj: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择！')
      }
    } else if (key == "版本管理") {
      if (this.state.rowIdB) {
        var key = this.state.rowIdB
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=4', {
              id: _this.state.rowIdB
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true')) {
                const dataSource = [..._this.state.dataA];
                _this.setState({ dataA: dataSource.filter(item => item.id !== key), rowIdB: "", rowIdBobj: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择！')
      }
    } else if (key == "巡检任务") {

      if (this.state.rowIdtask) {
        var key = this.state.rowIdtask
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=15', {
              id: _this.state.rowIdtask
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                const dataSource = [..._this.state.datatask];
                _this.setState({ datatask: dataSource.filter(item => item.id !== key), rowIdtask: {}, rowIdtaskobj: "" }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择！')
      }
    }
  }

  onClose = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      iptobj: {},
    })
  };


  //分页
  pagechange(key, page) {
    console.log(page)
    if (key == "巡检路径") {
      this.setState({
        pageIndex1: page.current,
      })
    } else if (key == "版本管理") {
      this.setState({
        pageIndexA: page.current,
      })
    } else if (key == "巡检任务") {
      this.setState({
        pageIndexAtask: page.current,
      })
    }
  }
  sortCode(obj1, obj2) {
    var val1 = obj1.Code;
    var val2 = obj2.Code;
    if (val1 < val2) {
      return -1;
    } else if (val1 > val2) {
      return 1;
    } else {
      return 0;
    }
  }

  handleSelect(value) {
    console.log(value)
    //全部专业列表
    var arr = this.state.dataSourceall
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].Code.length > 8 && arr[i].Code.indexOf(value) > -1) {
        newarr.push(arr[i])
      }
    }
    this.setState({
      majorselect: value,
      data3: newarr.sort(this.sortCode)
    })
  }

  tobind() {
    if (this.state.verdetailid && this.state.idliststrin) {
      axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=5', {
        Parentid: this.state.verdetailid,
        idlist: this.state.idliststrin,
        codelist: this.state.codeliststrin,
        namelist: this.state.nameliststrin
      }).then(data => {
        console.log(data)
        if (data.data == true || data.data.indexOf("true") > -1) {
          //装配模型
          axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=6', {
            Parentid: this.state.verdetailid
          }).then(data => {
            console.log('路径绑定下得模型', conversion(data))
            this.setState({
              data2: conversion(data),
              selectedRowKeysA: [],
              selectedRowKeysB: []
            }, function () {
              message.success('绑定成功！')
            })
          })
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  tojiebind() {
    if (this.state.verdetailid && this.state.idliststrout) {
      axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=7', {
        idlist: this.state.idliststrout,
      }).then(data => {
        console.log(data)
        if (data.data == true || data.data.indexOf("true") > -1) {
          //装配模型
          axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=6', {
            Parentid: this.state.verdetailid
          }).then(data => {
            console.log('路径绑定下得模型', conversion(data))
            this.setState({
              data2: conversion(data),
              selectedRowKeysA: [],
              selectedRowKeysB: []
            }, function () {
              message.success('解绑成功！')
            })
          })
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  //设置模型路径
  setmoduleroute() {
    if (this.state.verdetailid) {
      if (this.state.selectedRowKeysA.length == 1) {
        console.log(this.state.selectedRowsA)
        var arr = this.state.selectedRowsA
        axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=8', {
          Parentid: this.state.verdetailid,
          Id: arr[0].id,
          ModelId: arr[0].ModelId,
        }).then(data => {
          console.log(data)
          if (data.data == true || data.data.indexOf("true") > -1) {
            message.success('设置模型路径成功！')
            this.setState({
              selectedRowKeysA: [],
              selectedRowsA: [],
              idliststrout: ""
            })
          }
        })
      } else {
        message.error('请选择一条数据！')
      }
    } else {
      message.error('请选择路径！')
    }
  }
  //设置模型类别
  setmoduletype() {
    if (this.state.verdetailid) {
      if (this.state.selectedRowKeysA.length == 1) {
        console.log(this.state.selectedRowsA)
        var arr = this.state.selectedRowsA
        axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=13', {
          id: arr[0].id,
          type: arr[0].type
        }).then(data => {
          console.log(data)
          if (data.data == true || data.data.indexOf("true") > -1) {

            axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=6', {
              Parentid: this.state.verdetailid
            }).then(data => {
              console.log('路径绑定下得模型', conversion(data))
              this.setState({
                data2: conversion(data),
                selectedRowKeysA: [],
                selectedRowsA: [],
                idliststrout: ""
              }, function () {
                message.success('设置模型类别成功！')
              })
            })
          }
        })
      } else {
        message.error('请选择一条数据！')
      }
    } else {
      message.error('请选择路径！')
    }
  }
  //时间转为HH:mm:ss
  timetostandard(str) {
    var arr = str.split(":")
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].length == 1) {
        arr[i] = '0' + arr[i]
      }
    }
    var newstr = arr.join(':')
    return newstr
  }
  //模型预览
  modulepreview() {
    if (this.state.verdetailid) {
      document.querySelector('.inspectionmoduleload').style.display = "block"
      var inspectionmodule = document.getElementById("inspectionmodule")
      inspectionmodule.src = `./BOS3dhtml/areatree.html`
      var arr = this.state.data2
      inspectionmodule.onload = function () {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].modelKey != "") {
            document.getElementById("inspectionmodule").contentWindow.viewer3D.addView(arr[i].modelKey, "bole");
          }
        }
      }
    } else {
      message.error('请选择！')
    }
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  handleDragB(e, ui) {
    const { x, y } = this.state.controlledPositionB;
    this.setState({
      controlledPositionB: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  tocloseinspectionmoduleload() {
    document.querySelector('.inspectionmoduleload').style.display = "none"
  }
  //启用
  handletoStart() {
    if (this.state.verdetailid) {
      axios.get('/DATA/OM/OM_PatrolTask.aspx?flag=12').then(data => {
        console.log('巡检任务', conversion(data))
        this.setState({
          datatask: conversion(data),
          datataskfu: conversion(data)
        }, function () {
          this.refs.InspectionVer.style.display = "block"
        })
      })
    } else {
      message.error('请选择路径！')
    }
  }
  //版本管理
  handleVermanage() {
    if (this.state.verdetailid) {
      axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=10', {
        PathCode: this.state.verdetailidobj.PathCode
      }).then(data => {
        console.log('路径版本', conversion(data))
        this.setState({
          dataA: conversion(data),
          versiontaskflag: "version"
        }, function () {
          this.refs.InspectionVer.style.display = "block"
        })
      })
    } else {
      message.error('请选择！')
    }
  }
  //关闭版本管理
  tocloseInspectionVer() {
    this.refs.InspectionVer.style.display = "none"
  }
  //标记
  toversionmark() {
    if (this.state.rowIdB) {
      axios.post('/DATA/OM/OM_PatrolPath.aspx?flag=12',
        {
          id: this.state.rowIdB, PathCode: this.state.verdetailidobj.PathCode
        }).then(data => {
          console.log(data)
          if (data.data == true || data.data.indexOf("true") > -1) {
            message.success('标记成功！')
          }
        })
    }
  }

  //启用/停用
  handleStartEnd() {
    if (this.state.rowIdtask) {
      axios.post('/DATA/OM/OM_PatrolTask.aspx?flag=16', {
        id: this.state.rowIdtask,
        TaskStatus: this.state.rowIdtaskobj.TaskStatus
      }).then(data => {
        if (data.data == true || data.data.indexOf("true") > -1) {
          var arr = this.state.datataskfu
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == this.state.rowIdA) {
              arr[i].TaskStatus = arr[i].TaskStatus == "1" ? "0" : arr[i].TaskStatus == "0" ? "1" : ""
            }
          }
          this.setState({
            datatask: arr
          }, function () {
            message.success('操作成功！')
          })
        }
      })
    } else {
      message.error('请选择！')
    }
  }

  //巡检任务选中
  senddatatask(record) {
    console.log(record)
    this.setState({
      rowIdtask: record.id,
      rowIdtaskobj: record,
    })
  }
  setRowClassNametask = (record) => {
    return record.id === this.state.rowIdtask ? 'clickRowStyl' : 'clickRowSty';
  }
  render() {
    const { selectedRowKeysA, selectedRowKeysB } = this.state;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    const rowSelectionA = {
      selectedRowKeys: selectedRowKeysA,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys, selectedRows);
        var idliststr = ''
        for (let i = 0; i < selectedRows.length; i++) {
          if (i == selectedRows.length - 1) {
            idliststr = idliststr + selectedRows[i].id
          } else {
            idliststr = idliststr + selectedRows[i].id + ','
          }
        }
        this.setState({
          selectedRowKeysA: selectedRowKeys,
          selectedRowsA: selectedRows,
          idliststrout: idliststr
        })
      },
    };
    const rowSelectionB = {
      selectedRowKeys: selectedRowKeysB,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys, selectedRows);
        var idliststr = ''
        var codeliststr = ''
        var nameliststr = ''
        for (let i = 0; i < selectedRows.length; i++) {
          if (i == selectedRows.length - 1) {
            idliststr = idliststr + selectedRows[i].ID
            codeliststr = codeliststr + selectedRows[i].Code
            nameliststr = nameliststr + selectedRows[i].Name
          } else {
            idliststr = idliststr + selectedRows[i].ID + ','
            codeliststr = codeliststr + selectedRows[i].Code + ','
            nameliststr = nameliststr + selectedRows[i].Name + ','
          }
        }
        this.setState({
          selectedRowKeysB: selectedRowKeys,
          idliststrin: idliststr,
          codeliststrin: codeliststr,
          nameliststrin: nameliststr
        })
      },
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <section className="Inspectioncategory" style={{ height: clientHeight - 50 + 'px' }}>
        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
        <div className="Inspectioncategorycon">
          <div className="Inspectioncategoryconleft">
            <h2 className="h2title">巡检路径列表</h2>
            <span className="action">
              <Button style={{ display: this.state.buttonarr.indexOf('添加-路径') > -1 ? "inline-block" : "none" }} onClick={this.handleAdd.bind(this, '巡检路径')}>添加</Button>
              <Button style={{ display: this.state.buttonarr.indexOf('编辑-路径') > -1 ? "inline-block" : "none" }} onClick={this.handleEdit.bind(this, '巡检路径')}>编辑</Button>
              <Button style={{ display: this.state.buttonarr.indexOf('删除-路径') > -1 ? "inline-block" : "none" }} onClick={this.handleDel.bind(this, '巡检路径')}>删除</Button>
              {/* <Button onClick={this.handletoStart.bind(this)}>启用</Button> */}
              <Button style={{ display: this.state.buttonarr.indexOf('版本管理-路径') > -1 ? "inline-block" : "none" }} onClick={this.handleVermanage.bind(this)}>版本管理</Button>
            </span>
            <div className="listpage" style={{ height: clientHeight - 50 - 80 }}>
              <Table
                size="small"
                dataSource={this.state.data1}
                columns={this.data1columns}
                pagination={{
                  pageSize: this.state.pageSize,
                  current: this.state.pageIndex1,
                  total: this.state.data1length
                }}
                onChange={this.pagechange.bind(this, '巡检路径')}
                onRow={(record) => {
                  return {
                    onClick: this.senddata1.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassName1}
              />
            </div>
          </div>
          <div className="Inspectioncategoryconright">
            <h2 className="h2title">巡检设备</h2>
            <div className="Inspectioncategoryconrightcon">
              <div className="left">
                <span className="action">
                  <Button style={{ display: this.state.buttonarr.indexOf('解绑-设备') > -1 ? "inline-block" : "none" }} onClick={this.tojiebind.bind(this)}>解绑</Button>
                  <Button style={{ display: this.state.buttonarr.indexOf('设置模型路径-设备') > -1 ? "inline-block" : "none" }} onClick={this.setmoduleroute.bind(this)}>设置模型路径</Button>
                  <Button style={{ display: this.state.buttonarr.indexOf('模型预览-设备') > -1 ? "inline-block" : "none" }} onClick={this.modulepreview.bind(this)}>模型预览</Button>
                  <Button style={{ display: this.state.buttonarr.indexOf('设置模型类别-设备') > -1 ? "inline-block" : "none" }} onClick={this.setmoduletype.bind(this)}>设置模型类别</Button>
                </span>
                <div className="listscrollcheckbox" style={{ height: clientHeight - 50 - 70 }}>
                  <Table
                    size="small"
                    rowSelection={rowSelectionA}
                    dataSource={this.state.data2}
                    columns={this.data2columns}
                    pagination={false}
                    rowClassName={this.setRowClassName22}
                    scroll={{ y: clientHeight - 50 - 30 - 30 - 30 - 30 - 15 + 'px' }}
                  />
                </div>

              </div>
              <div className="right">
                <span className="action">
                  <span>
                    <Select onChange={this.handleSelect.bind(this)} value={this.state.majorselect}>
                      {
                        this.state.majorarr.map((item, index) => {
                          return (<Option value={item.Code} key={item.Code}>{item.Code}</Option>)
                        })
                      }
                    </Select>
                  </span>
                  <span><Button style={{ display: this.state.buttonarr.indexOf('绑定-设备') > -1 ? "inline-block" : "none" }} onClick={this.tobind.bind(this)}>绑定</Button></span>
                </span>
                <div className="listscrollcheckbox" style={{ height: clientHeight - 50 - 70 }}>
                  <Table
                    rowSelection={rowSelectionB}
                    size="small"
                    dataSource={this.state.data3}
                    columns={this.data3columns}
                    pagination={false}
                    scroll={{ y: clientHeight - 50 - 30 - 30 - 30 - 30 - 15 + 'px' }}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>

        <Draggable
          position={this.state.controlledPositionB}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragB.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="InspectionVer dragwindow" ref="InspectionVer"
            style={{
              width: clientWidth + 'px', height: clientHeight - 50 + 'px'
            }}>
            {
              this.state.versiontaskflag == "version" ?
                <div className="Inspectionroutecon">
                  <h2 className="h2title">巡检路径版本列表<span className="action"><Button onClick={this.tocloseInspectionVer.bind(this)}>关闭</Button></span></h2>
                  <span className="Inspectionaction">
                    <span>对{' ' + this.state.verdetailidobj.PathCode + ' - ' + this.state.verdetailidobj.PathName + ' '}版本管理</span>
                    <span>
                      <Button onClick={this.handleAdd.bind(this, '版本管理')}>升级</Button>
                      <Button onClick={this.handleEdit.bind(this, '版本管理')}>编辑</Button>
                      <Button onClick={this.handleDel.bind(this, '版本管理')}>删除</Button>
                      <Button onClick={this.toversionmark.bind(this)}>标记</Button>
                    </span>
                  </span>
                  <div className="listpage" style={{ height: clientHeight - 50 - 90 }}>
                    <Table
                      size="small"
                      dataSource={this.state.dataA}
                      columns={this.dataAcolumns}
                      pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageIndexA,
                        total: Number(this.state.dataA.length)
                      }}
                      onChange={this.pagechange.bind(this, '版本管理')}
                      // scroll={{ y: clientHeight - 50 - 70 - 50  + 'px' }}
                      onRow={(record) => {
                        return {
                          onClick: this.senddata2.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassName2}
                    />
                  </div>
                </div> :
                <div className="Inspectionroutecon">
                  <h2 className="h2title">巡检任务列表<span className="action"><Button onClick={this.tocloseInspectionVer.bind(this)}>关闭</Button></span></h2>
                  <span className="Inspectionaction">
                    <span>任务名称：<Search onSearch={(v) => {
                      var arr = this.state.datataskfu
                      var newarr = []
                      for (let i = 0; i < arr.length; i++) {
                        if (arr[i].TaskName.indexOf(v) > -1) {
                          newarr.push(arr[i])
                        }
                      }
                      this.setState({
                        datatask: newarr
                      })
                    }} /></span>
                    <span>
                      <Button onClick={this.handleAdd.bind(this, '巡检任务')}>添加</Button>
                      <Button onClick={this.handleEdit.bind(this, '巡检任务')}>编辑</Button>
                      <Button onClick={this.handleDel.bind(this, '巡检任务')}>删除</Button>
                      <Button onClick={this.handleStartEnd.bind(this)}>启用/停用</Button>
                    </span>
                  </span>
                  <div className="listpage" style={{ height: clientHeight - 50 - 70 }}>
                    <Table
                      size="small"
                      dataSource={this.state.datatask}
                      columns={this.dataAtaskcolumns}
                      pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageIndexAtask,
                        total: Number(this.state.dataA.length)
                      }}
                      onChange={this.pagechange.bind(this, '巡检任务')}
                      // scroll={{ y: clientHeight - 50 - 70 - 50  + 'px' }}
                      onRow={(record) => {
                        return {
                          onClick: this.senddatatask.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassNametask}
                    />
                  </div>
                </div>

            }

          </div>
        </Draggable>

        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="inspectionmoduleload" ref="inspectionmoduleload"
            style={{
              width: '80%', height: clientHeight - 100 + 'px'
            }}>
            <h2>模型预览<Button onClick={this.tocloseinspectionmoduleload.bind(this)}>关闭</Button></h2>
            <iframe title="myiframe" src="" id="inspectionmodule" style={{
              width: '100%', height: clientHeight - 150 + 'px'
            }}></iframe>
          </div>
        </Draggable>

        <Drawer
          title={this.state.addeditname + '' + this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            {
              this.state.addeditname == "巡检路径" ?
                <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="路径编码">
                        {getFieldDecorator('PathCode', {
                          initialValue: this.state.iptobj['PathCode'],
                          rules: [{ required: true, message: '请输入路径编码' }],
                        })(
                          <Input disabled={this.state.addedit == "编辑" ? true : false}
                            style={{ background: this.state.addedit == "编辑" ? "#ccc" : "none" }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="路径名称">
                        {getFieldDecorator('PathName', {
                          initialValue: this.state.iptobj['PathName'],
                          rules: [{ required: true, message: '请输入路径名称' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                    </Button>
                    </Form.Item>
                  </Row>
                </Form>
                : this.state.addeditname == "版本管理" ?
                  <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="版本名称">
                          {getFieldDecorator('VersionsName', {
                            initialValue: this.state.iptobj['VersionsName'],
                            rules: [{ required: true, message: '请输入版本名称' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
          </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                  : this.state.addeditname == "巡检任务" ?
                    <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                    // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="任务名称">
                            {getFieldDecorator('TaskName', {
                              initialValue: this.state.iptobj["TaskName"]
                              // rules: [{ required: false, message: '请选择复制旧版本号' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="任务要求">
                            {getFieldDecorator('InspectionRequirements', {
                              initialValue: this.state.iptobj["InspectionRequirements"]
                              // rules: [{ required: false, message: '请选择复制旧版本号' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="任务角色">
                            {getFieldDecorator('AssignedRole', {
                              initialValue: this.state.iptobj["AssignedRole"]
                              // rules: [{ required: false, message: '请选择复制旧版本号' }],
                            })(
                              <Select >
                                {
                                  this.state.rolearr.map((item, index) => {
                                    return (<Option value={item.RoleId} key={item.RoleId}>{item.RoleName}</Option>)
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="任务周期">
                            {getFieldDecorator('TaskCycle', {
                              initialValue: this.state.addedit == "添加" ? this.state.regular4sele : Number(this.state.iptobj['TaskCycle']),
                              rules: [{ required: false, message: '请输入任务周期' }],
                            })(
                              <Radio.Group onChange={(e) => {
                                console.log(e)
                                this.setState({
                                  regular4sele: e.target.value
                                })
                              }}>
                                <Radio value={0} key={0}>单次</Radio>
                                <Radio value={1} key={1}>每日</Radio>
                                <Radio value={2} key={2}>每周</Radio>
                                <Radio value={3} key={3}>每月</Radio>
                              </Radio.Group>)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12} >
                          <Form.Item label="时间">
                            {getFieldDecorator('TaskTime', {
                              initialValue: this.state.iptobj['TaskTime'] ? moment(this.timetostandard(this.state.iptobj['TaskTime']), "HH:mm:ss") : null,
                            })(
                              <TimePicker defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} locale={locale} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* 单次 */}
                      <Row gutter={16} style={{ display: this.state.regular4sele == 0 ? "block" : "none" }}>
                        <Col span={12} >
                          <Form.Item label="单次">
                            {getFieldDecorator('TaskDate', {
                              initialValue: this.state.iptobj['TaskDate'] ? moment(this.state.iptobj['TaskDate'], 'YYYY-MM-DD') : null,
                            })(
                              <DatePicker locale={locale} defaultOpenValue={moment('2019-01-01', 'YYYY-MM-DD')} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* 日 */}
                      <Row gutter={16} style={{ display: this.state.regular4sele == 1 ? "block" : "none" }}>
                        <Col span={12}>
                          <Form.Item label="间隔">
                            {getFieldDecorator('TaskInterval', {
                              initialValue: this.state.iptobj['TaskInterval'],
                            })(
                              <Select>
                                <Option value="1" key="1">1小时</Option>
                                <Option value="2" key="2">2小时</Option>
                                <Option value="3" key="3">3小时</Option>
                                <Option value="4" key="4">4小时</Option>
                                <Option value="6" key="6">6小时</Option>
                                <Option value="8" key="8">8小时</Option>
                                <Option value="12" key="12">12小时</Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* 周 */}
                      <Row gutter={16} style={{ display: this.state.regular4sele == 2 ? "block" : "none" }}>
                        <Col span={12}>
                          <Form.Item label="周选">
                            {getFieldDecorator('TaskDate1', {
                              initialValue: this.state.iptobj['TaskDate'] ? this.state.iptobj['TaskDate'].indexOf(',') > -1 ? this.state.iptobj['TaskDate'].split(',') :
                                this.state.iptobj['TaskDate'] == "" ? [] : [this.state.iptobj['TaskDate']] : [],
                            })(
                              <Select
                                style={{ display: this.state.regular4sele == 2 ? "block" : "none" }}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="请选择"
                              // value={this.state.weeksele}
                              // onChange={(value) => { console.log(value); this.setState({ weeksele: value }) }}
                              >
                                <Option value="周一" key="周一">周一</Option>
                                <Option value="周二" key="周二">周二</Option>
                                <Option value="周三" key="周三">周三</Option>
                                <Option value="周四" key="周四">周四</Option>
                                <Option value="周五" key="周五">周五</Option>
                                <Option value="周六" key="周六">周六</Option>
                                <Option value="周日" key="周日">周日</Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* 月 */}
                      <Row gutter={16} style={{ display: this.state.regular4sele == 3 ? "block" : "none" }}>
                        <Col span={12}>
                          <Form.Item label="月选">
                            {getFieldDecorator('TaskDate2', {
                              initialValue: this.state.iptobj['TaskDate'] ? this.state.iptobj['TaskDate'].indexOf(',') > -1 ? this.state.iptobj['TaskDate'].split(',') :
                                this.state.iptobj['TaskDate'] == "" ? [] : [this.state.iptobj['TaskDate']] : [],
                            })(
                              <Select
                                style={{ display: this.state.regular4sele == 3 ? "block" : "none" }}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="请选择"
                              // value={this.state.monthsele}
                              // onChange={(value) => { console.log(value); this.setState({ monthsele: value }) }}
                              >
                                {
                                  this.state.montharr.map((item, index) => {
                                    return (<Option value={item} key={item}>{item}</Option>)
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16} className="formbutton">
                        <Form.Item>
                          <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                            保存
                      </Button>
                        </Form.Item>
                      </Row>
                    </Form>
                    : ""
            }
          </div>
        </Drawer>
      </section>
    )
  }
}
const Inspectioncategoryapp = Form.create()(Inspectioncategory);
export default Inspectioncategoryapp;

