import React from 'react';
import { Input, message, Button, Select, Checkbox, Tree, Form, Col, Row, Table, Tooltip, DatePicker, Modal } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import './style.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { onCellf } from '../../comfunc'
import SearchCondition from '../../utils/searchcondition'
import { todatetime } from '../../utils/todatatime'
import { toProTreeData1, toProTreeData } from '../../utils/toprojecttreedata'
import store from '../../store/index'
import { getButtonLimit } from '../../utils/getbuttonlimit'
moment.locale('zh-cn')
const Option = Select.Option;
const { TextArea, Search } = Input
const confirm = Modal.confirm
const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker;
const { TreeNode, DirectoryTree } = Tree;
const plainOptions = [
  { label: '状态', value: 'State' },
  { label: '编号', value: 'TicketNum' },
  { label: '工作内容', value: 'JobContent' },
  { label: '两票类型', value: 'TicketTypeid' },
  { label: '接收人', value: 'ProUser' },
  { label: '指派给', value: 'NextAuditUsers' }
];

const defcolumns = [{
  title: '状态',
  dataIndex: 'State',
  key: 'State',
  width: '12%',
},
{
  title: '编号',
  dataIndex: 'TicketNum',
  key: 'TicketNum',
  width: '13%',
},
{
  title: '工作内容',
  dataIndex: 'JobContent',
  key: 'JobContent',
},
{
  title: '两票类型',
  dataIndex: 'TicketTypeid',
  key: 'TicketTypeid',
  width: '12%',
},
{
  title: '接收人',
  dataIndex: 'ProUser',
  key: 'ProUser',
  width: '12%',
},
{
  title: '指派给',
  dataIndex: 'NextAuditUsers',
  key: 'NextAuditUsers',
  width: '12%',
},];


class WisdomTwoticket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr: [],

      dangzhiban: '',
      //权限
      limitticket: [
        '',
        '热力机械票,流转',//
        '驳回,流转',
        '流转',
        '审批,流转,作废',//审批
        '必须采取,人员补充,流转',//通知
        '流转',
        '变更,延期,试运,流转',
        '流转',
        '驳回,流转',
        '流转,结束,总结',
        '流转,结束,总结',
        '流转',
        '流转',
        '流转',
        '流转',
      ],
      limitticketnum: 0,
      Version: '',
      uname: '',
      pageIndex: 1,
      pageIndex1: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 30 - 50) / 28),
      searchcon: ['=', '!=', '<', '<=', '>', '>=', '包含', '不包含', '从属于'],
      wconditon4: '',
      wconditon3: '',
      wconditon2: '',
      wconditon1: '',


      iptobj: {},
      addedit: "",
      //工作票
      data: [{ TicketStatus: '1' }],
      columns: [
        {
          title: '序号',
          dataIndex: 'xu',
          key: 'xu',
          width: '8%',
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className="workticketdropdown">
              <CheckboxGroup options={plainOptions} defaultValue={['State', 'TicketNum', 'JobContent', 'TicketTypeid', 'ProUser', 'NextAuditUsers']} onChange={this.onColumnsChange()} />
            </div>
          ),
          render: (text, record, index) => {
            return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
          }
        },
        {
          title: '状态',
          dataIndex: 'State',
          key: 'State',
          width: '12%',
        },
        {
          title: '编号',
          dataIndex: 'TicketNum',
          key: 'TicketNum',
          width: '13%',
        },
        {
          title: '工作内容',
          dataIndex: 'JobContent',
          key: 'JobContent',
          onCell: onCellf,
          render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        },
        {
          title: '两票类型',
          dataIndex: 'TicketTypeid',
          key: 'TicketTypeid',
          width: '12%',
          render: (text) => {
            return text == "1" ? "工作票" : text == "2" ? "操作票" : "其它票"
          }
        },
        {
          title: '接收人',
          dataIndex: 'ProUser',
          key: 'ProUser',
          width: '12%',
          onCell: onCellf,
          render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        },
        {
          title: '指派给',
          dataIndex: 'NextAuditUsers',
          key: 'NextAuditUsers',
          width: '12%',
          onCell: onCellf,
          render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          render: (text, record) => {
            return (<div className="listoperation">
              <span style={{ display: this.state.buttonarr.indexOf('编辑') > -1 ? "inline-block" : "none" }} onClick={this.handleEdit.bind(this, '编辑票', record)}><img src={require('../../assets/两票icon/bianji.png')} alt="" /></span>
              <span style={{ display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }} onClick={this.handleDel.bind(this, '两票删除', record)}><img src={require('../../assets/两票icon/shanchu.png')} alt="" /></span>
              {/* <span onClick={this.handlePrint.bind(this, '两票打印', record)}><img src={require('../../assets/两票icon/print.png')} alt="" /></span> */}
              <span style={{ display: this.state.buttonarr.indexOf('详情') > -1 ? "inline-block" : "none" }} onClick={this.handleDetail.bind(this, '两票详情', record)}><img src={require('../../assets/两票icon/xiangxi.png')} alt="" /></span>
            </div>)
          }
        }],

      //历史票数据
      historydata: [],
      //流程详情
      processdata: [],

      //设备列表数据
      equipdata: [],
      flagname: "",
      worktickettype: [],//工作票类型
      workticketdata: [],//工作票数据
      workticketeddata: [],//历史票
      //新建票 所属类型 名称 及流程
      workticketflowarr: [],//流程初始化
      workticketid: '',//票id
      newworktickettype: '',
      newworkticketname: '',
      newworkticketflow: '',

      //流程类型初始化
      Processinitarr: [],
      //区域树数据初始化
      Areatreearr: [],
      //人员初始化
      Userarr: store.getState().userdata,

      //历史票列表选择行
      rowId: '',
      rowIdobj: {},

      //票行选择
      rowIdA: '',
      rowIdAobj: {},

      //热力机械工作票
      ticketiptobj: {},
      //安全措施
      rowIdA1: "",
      rowIdAobj1: {},
      rowIdA2: "",
      rowIdAobj2: {},

      //审批
      auditdataobj: {},
      //变更
      changedataobj: {},
      //延期
      delaydataobj: {},
      //试运
      tryrundataarr: [],
      //结束
      endthingdataarr: [],
      //总结
      summarydataobj: {},
      FinalTime: "",//总结时间

      //设备kks
      KKSdata: [],
      searchValue: "",
      autoExpandParent: true,
      expandedKeys: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectedRowsfu: [],
      selectedRowssele: [],
      //选择的设备名称及kks
      DeviceKKS: '',
      DeviceName: '',
      //安全措施
      runningmessuredata1: [],
      runningmessuredata2: [],
      runningmessuredata1: [],
      runningmessuredata2: [],

      //版本
      Version: '',
      //变更 延期 试运 保存按钮显示权限
      Control: '',
      //8模块界面默认展示
      ControlColor: '',
      //下一个处理人初始化
      NextUserarr: [],



      attr: {
        ID: 'ID',
        ParentId: 'ParentId',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        RecordVersion: 'RecordVersion',
        UpdateTime: 'UpdateTime',
        UpdatePeople: 'UpdatePeople',
        CreatePeople: 'CreatePeople',
        CreateTime: 'CreateTime'
      },
    }
    this.historycolumns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '8%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '状态',
        dataIndex: 'TicketStatus',
        key: 'TicketStatus',
        width: '12%',
      },
      {
        title: '编号',
        dataIndex: 'TicketNum',
        key: 'TicketNum',
        width: '13%',
      },
      {
        title: '工作内容',
        dataIndex: 'TicketStatus2',
        key: 'TicketStatus2',
        width: '24%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '两票类型',
        dataIndex: 'TicketTypeid',
        key: 'TicketTypeid',
        width: '12%',
        render: (text) => {
          return text == "1" ? "工作票" : text == "2" ? "操作票" : "其它票"
        }
      },
      {
        title: '接收人',
        dataIndex: 'TicketFlowid',
        key: 'TicketFlowid',
        width: '12%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '指派给',
        dataIndex: 'TicketSign',
        key: 'TicketSign',
        width: '12%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      }]
    //流程详情
    this.processcolumns = [{
      title: '审批人',
      dataIndex: 'TicketStatus',
      key: 'TicketStatus',
      width: '16%',
    },
    {
      title: '审批意见',
      dataIndex: 'TicketNum',
      key: 'TicketNum',
      width: '16%',
    }, {
      title: '审批时间',
      dataIndex: 'TicketStatus2',
      key: 'TicketStatus2',
      width: '26%',
    },
    {
      title: '备注',
      dataIndex: 'TicketTypeid',
      key: 'TicketTypeid',
      width: '42%',
    }]
    //设备列表
    this.equipcolumns = [{
      title: 'KKS',
      dataIndex: 'KKS',
      key: 'KKS',
      width: '46%',
      onCell: onCellf,
      render: this.renderf
    },
    {
      title: '名称',
      dataIndex: 'Name',
      key: 'Name',
      width: '46%',
      onCell: onCellf,
      render: this.renderf
    }]
    //安全措施
    this.runningmessurecolumns1 = [{
      title: '序号',
      dataIndex: 'xu',
      key: 'xu',
      width: '6%',
      render: (text, record, index) => {
        return (<span>{index + 1}</span>)
      }
    }, {
      title: '安全措施',
      dataIndex: 'ContentOfMeasures',
      key: 'ContentOfMeasures',
      width: '46%',
      onCell: onCellf,
      render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
    },
    {
      title: '执行',
      dataIndex: 'Implement',
      key: 'Implement',
      width: '6%',
      render: (text, record, index) => {
        return (<Checkbox
          checked={this.state.runningmessuredata1[index].Implement == "true" ? true : false}
          onChange={(e) => {
            var arr = this.state.runningmessuredata1
            for (let i = 0; i < arr.length; i++) {
              if (i == index) {
                arr[i].Implement = `${e.target.checked}`
              }
            }
            this.setState({
              runningmessuredata1: arr
            })
          }}
          disabled={this.state.limitticketnum == 5 ? false : true}
        ></Checkbox>)
      }
    },
    {
      title: '挂牌',
      dataIndex: 'Listing',
      key: 'Listing',
      width: '6%',
      render: (text, record, index) => {
        // //console.log(text,record,index)
        return (<Checkbox className={`Listing${index}`}
          checked={this.state.runningmessuredata1[index].Listing == "true" ? true : false}
          onChange={(e) => {
            var arr = this.state.runningmessuredata1
            for (let i = 0; i < arr.length; i++) {
              if (i == index) {
                arr[i].Listing = `${e.target.checked}`
              }
            }
            this.setState({
              runningmessuredata1: arr
            })
          }}
          disabled={this.state.limitticketnum == 5 ? false : true}
        ></Checkbox>)
      }
    },
    {
      title: '挂牌号',
      dataIndex: 'ListingNum',
      key: 'ListingNum',
      width: '10%',
      render: (text, record, index) => {
        var ele = $(`.Listing${index}`).children().hasClass('ant-checkbox-checked')
        if (ele && this.state.limitticketnum == 5) {
          return (<Input style={{ height: "26px", background: "none", color: "#fff" }}
            value={this.state.runningmessuredata1[index].ListingNum}
            onChange={(e) => {
              var arr = this.state.runningmessuredata1
              for (let i = 0; i < arr.length; i++) {
                if (i == index) {
                  arr[i].ListingNum = e.target.value
                }
              }
              this.setState({
                runningmessuredata1: arr
              })
            }}
          />)
        } else {
          return (<span>{text}</span>)
        }
      }
    },
    {
      title: '上锁',
      dataIndex: 'Locking',
      key: 'Locking',
      width: '6%',
      render: (text, record, index) => {
        return (<Checkbox className={`Locking${index}`}
          checked={this.state.runningmessuredata1[index].Locking == "true" ? true : false}
          disabled={this.state.limitticketnum == 5 ? false : true}
          onChange={(e) => {
            var arr = this.state.runningmessuredata1
            for (let i = 0; i < arr.length; i++) {
              if (i == index) {
                arr[i].Locking = `${e.target.checked}`
              }
            }
            this.setState({
              runningmessuredata1: arr
            })
          }}
        ></Checkbox>)
      }
    },
    {
      title: '上锁号',
      dataIndex: 'LockingNum',
      key: 'LockingNum',
      width: '10%',
      render: (text, record, index) => {
        var ele = $(`.Locking${index}`).children().hasClass('ant-checkbox-checked')
        if (ele && this.state.limitticketnum == 5) {
          return (<Input style={{ height: "26px", background: "none", color: "#fff" }}
            value={this.state.runningmessuredata1[index].LockingNum}
            onChange={(e) => {
              var arr = this.state.runningmessuredata1
              for (let i = 0; i < arr.length; i++) {
                if (i == index) {
                  arr[i].LockingNum = e.target.value
                }
              }
              this.setState({
                runningmessuredata1: arr
              })
            }}
          />)
        } else {
          return (<span>{text}</span>)
        }
      }
    }
    ]
    this.runningmessurecolumns2 = [{
      title: '序号',
      dataIndex: 'xu',
      key: 'xu',
      width: '6%',
      render: (text, record, index) => {
        return (<span>{index + 1}</span>)
      }
    }, {
      title: '安全措施',
      dataIndex: 'ContentOfMeasures',
      key: 'ContentOfMeasures',
      width: '46%',
      onCell: onCellf,
      render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
    },
    {
      title: '执行',
      dataIndex: 'AImplement',
      key: 'AImplement',
      width: '6%',
      render: (text, record, index) => {
        return (<Checkbox
          checked={this.state.runningmessuredata2[index].AImplement == "true" ? true : false}
          onChange={(e) => {
            var arr = this.state.runningmessuredata2
            for (let i = 0; i < arr.length; i++) {
              if (i == index) {
                arr[i].AImplement = `${e.target.checked}`
              }
            }
            this.setState({
              runningmessuredata2: arr
            })
          }}
          disabled={this.state.limitticketnum == 5 ? false : true}
        ></Checkbox>)
      }
    },
    {
      title: '挂牌',
      dataIndex: 'AListing',
      key: 'AListing',
      width: '6%',
      render: (text, record, index) => {
        // //console.log(text,record,index)
        return (<Checkbox className={`AListing${index}`}
          checked={this.state.runningmessuredata2[index].AListing == "true" ? true : false}
          onChange={(e) => {
            var arr = this.state.runningmessuredata2
            for (let i = 0; i < arr.length; i++) {
              if (i == index) {
                arr[i].AListing = `${e.target.checked}`
              }
            }
            this.setState({
              runningmessuredata2: arr
            })
          }}
          disabled={this.state.limitticketnum == 5 ? false : true}
        ></Checkbox>)
      }
    },
    {
      title: '挂牌号',
      dataIndex: 'AListingNum',
      key: 'AListingNum',
      width: '10%',
      render: (text, record, index) => {
        var ele = $(`.AListing${index}`).children().hasClass('ant-checkbox-checked')
        if (ele && this.state.limitticketnum == 5) {
          return (<Input style={{ height: "26px", background: "none", color: "#fff" }}
            value={this.state.runningmessuredata2[index].AListingNum}
            onChange={(e) => {
              var arr = this.state.runningmessuredata2
              for (let i = 0; i < arr.length; i++) {
                if (i == index) {
                  arr[i].AListingNum = e.target.value
                }
              }
              this.setState({
                runningmessuredata2: arr
              })
            }}
          />)
        } else {
          return (<span>{text}</span>)
        }
      }
    },
    {
      title: '上锁',
      dataIndex: 'ALocking',
      key: 'ALocking',
      width: '6%',
      render: (text, record, index) => {
        return (<Checkbox className={`ALocking${index}`}
          checked={this.state.runningmessuredata2[index].ALocking == "true" ? true : false}
          disabled={this.state.limitticketnum == 5 ? false : true}
          onChange={(e) => {
            var arr = this.state.runningmessuredata2
            for (let i = 0; i < arr.length; i++) {
              if (i == index) {
                arr[i].ALocking = `${e.target.checked}`
              }
            }
            this.setState({
              runningmessuredata2: arr
            })
          }}
        ></Checkbox>)
      }
    },
    {
      title: '上锁号',
      dataIndex: 'ALockingNum',
      key: 'ALockingNum',
      width: '10%',
      render: (text, record, index) => {
        var ele = $(`.ALocking${index}`).children().hasClass('ant-checkbox-checked')
        if (ele && this.state.limitticketnum == 5) {
          return (<Input style={{ height: "26px", background: "none", color: "#fff" }}
            value={this.state.runningmessuredata2[index].ALockingNum}
            onChange={(e) => {
              var arr = this.state.runningmessuredata2
              for (let i = 0; i < arr.length; i++) {
                if (i == index) {
                  arr[i].ALockingNum = e.target.value
                }
              }
              this.setState({
                runningmessuredata2: arr
              })
            }}
          />)
        } else {
          return (<span>{text}</span>)
        }
      }
    }
    ]
    //试运
    this.tryworkcolumns = [{
      title: '允许试运时间',
      dataIndex: 'TrialOperationTime',
      key: 'TrialOperationTime',
      width: '16%',
      render: (text) => {
        return (<span>{text.indexOf("1900-01-01") > -1 ? "" : text}</span>)
      }
    },
    {
      title: '工作许可人',
      dataIndex: 'Licensor',
      key: 'Licensor',
      width: '15%',
    },
    {
      title: '时间',
      dataIndex: 'LicensorTime',
      key: 'LicensorTime',
      width: '16%',
      render: (text) => {
        return (<span>{text.indexOf("1900-01-01") > -1 ? "" : text}</span>)
      }
    },
    {
      title: '工作负责人',
      dataIndex: 'DutyPeople',
      key: 'DutyPeople',
      width: '15%',
      // render: (text) => {
      //   var arr = this.state.Userarr
      //   for (let i = 0; i < arr.length; i++) {
      //     if (arr[i].UserId == text) {
      //       return (<span>{arr[i].UserName}</span>)
      //     }
      //   }
      // }
    },
    {
      title: '时间',
      dataIndex: 'DutyPeopleTime',
      key: 'DutyPeopleTime',
      width: '16%',
      render: (text) => {
        return (<span>{text.indexOf("1900-01-01") > -1 ? "" : text}</span>)
      }
    },
      // {
      //   title: '操作',
      //   dataIndex: 'TicketFlowid',
      //   key: 'TicketFlowid',
      //   width: '22%',
      //   render() {
      //     return (<div className="listoperation">
      //       <span onClick={this.handleAdd.bind(this, '试运')}><img src={require('../../assets/两票icon/3.png')} alt="" /></span>
      //       <span onClick={this.handleDel.bind(this, '试运')}><img src={require('../../assets/两票icon/4.png')} alt="" /></span>
      //       <span onClick={this.handleEdit.bind(this, '试运')}><img src={require('../../assets/两票icon/5.png')} alt="" /></span>
      //     </div>)
      //   }
      // }
    ]
    this.endthingcolumns = [{
      title: '允许恢复时间',
      dataIndex: 'CompleteOperationTime',
      key: 'CompleteOperationTime',
      width: '16%',
      render: (text) => {
        return (<span>{text.indexOf("1900-01-01") > -1 ? "" : text}</span>)
      }
    },
    {
      title: '工作许可人',
      dataIndex: 'Licensor',
      key: 'Licensor',
      width: '15%',
    },
    {
      title: '时间',
      dataIndex: 'LicensorTime',
      key: 'LicensorTime',
      width: '16%',
      render: (text) => {
        return (<span>{text.indexOf("1900-01-01") > -1 ? "" : text}</span>)
      }
    },
    {
      title: '工作负责人',
      dataIndex: 'DutyPeople',
      key: 'DutyPeople',
      width: '15%',
    },
    {
      title: '时间',
      dataIndex: 'DutyPeopleTime',
      key: 'DutyPeopleTime',
      width: '16%',
      render: (text) => {
        return (<span>{text.indexOf("1900-01-01") > -1 ? "" : text}</span>)
      }
    }
    ]
    //巡检任务
    this.renderf = this.renderf.bind(this)
    this.axiosgetlist = this.axiosgetlist.bind(this)
    this.comparetwoarr = this.comparetwoarr.bind(this)
    this.toexiteditpage = this.toexiteditpage.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
    }
  }
  onColumnsChange = (checkedValues) => (checkedValues) => {
    let data = [{
      title: '序号',
      dataIndex: 'xu',
      key: 'xu',
      width: '8%',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="workticketdropdown">
          <CheckboxGroup options={plainOptions} defaultValue={['State', 'TicketNum', 'JobContent', 'TicketTypeid', 'ProUser', 'NextAuditUsers']} onChange={this.onColumnsChange()} />
        </div>
      ),
      render: (text, record, index) => {
        return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
      }
    }]
    defcolumns.forEach((r, index) => {
      checkedValues.forEach(rs => {
        if (r.key == rs) {
          data.push(r)
        }
      })
    })
    var obj = {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: (text, record) => {
        return (<div className="listoperation">
          <span style={{ display: this.state.buttonarr.indexOf('编辑') > -1 ? "inline-block" : "none" }} onClick={this.handleEdit.bind(this, '编辑票', record)}><img src={require('../../assets/两票icon/bianji.png')} alt="" /></span>
          <span style={{ display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }} onClick={this.handleDel.bind(this, '两票删除', record)}><img src={require('../../assets/两票icon/shanchu.png')} alt="" /></span>
          {/* <span onClick={this.handlePrint.bind(this, '两票打印', record)}><img src={require('../../assets/两票icon/print.png')} alt="" /></span> */}
          <span style={{ display: this.state.buttonarr.indexOf('详情') > -1 ? "inline-block" : "none" }} onClick={this.handleDetail.bind(this, '两票详情', record)}><img src={require('../../assets/两票icon/xiangxi.png')} alt="" /></span>
        </div>)
      }
    }
    data.push(obj)
    this.setState({ columns: data })
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
    }
  }
  axiosgetlist() {//获取工作票
    axios.get('/Data/OM/OM_TicketInformation.aspx?flag=1').then(data => {
      if (data.data.success == true) {
        this.setState({
          workticketdata: data.data.data,
          workticketeddata: data.data.data,
          data: ''
        })
      } else {
        message.error(data.data.describe)
      }
    })
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr: res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(56)

    //当值数据
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=10').then(data => {
      // //console.log('当值数据',data)
      this.setState({
        dangzhiban: data.data
      })
    })
    var uname = sessionStorage.getItem('uname')
    this.setState({
      uname
    })
    this.axiosgetlist()
    //获取工作票类型
    axios.get('/Data/OM/OM_TicketType.aspx?flag=1').then(data => {

      if (data.data.success == true) {
        this.setState({
          worktickettype: data.data.data
        })
      } else {
        message.error(data.data.describe)
      }
    })

    // //区域树(工作地点)
    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=8').then(res => {
      //console.log(conversion(res))
      // let attr = this.state.attr;
      // let arr = toProTreeData1(conversion(res), attr)
      this.setState({
        Areatreearr: conversion(res)
      })
    })

    //流程类型
    axios.get('/DATA/WorkFlow/WorkFlow.aspx?flag=43').then(data => {
      //console.log('流程类型初始化', data)
      if (data.data.success == true) {
        this.setState({
          Processinitarr: data.data.data
        })
      }
    })

  }
  //两票打印
  handlePrint() {

  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 30 - 50) / 28),
    })
  }

  //选中
  senddata(record) {
    //console.log(record)
    this.setState({
      rowId: record.id,
      rowIdobj: record,
    })
  }
  setRowClassName = (record) => {
    return record.id === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }


  //运行必须采取安全措施选中
  senddata1(record) {
    //console.log(record)
    this.setState({
      rowIdA1: record.id,
      rowIdAobj1: record,
    })
  }
  setRowClassName1 = (record) => {
    return record.id === this.state.rowIdA1 ? 'clickRowStyl' : 'clickRowSty';
  }
  //运行补充采取安全措施选中
  senddata2(record) {
    //console.log(record)
    this.setState({
      rowIdA2: record.id,
      rowIdAobj2: record,
    })
  }
  setRowClassName2 = (record) => {
    return record.id === this.state.rowIdA2 ? 'clickRowStyl' : 'clickRowSty';
  }  //运行补充采取安全措施选中
  senddata3(record) {
    //console.log(record)
    this.setState({
      rowIdA3: record.id,
      rowIdAobj3: record,
    })
  }
  setRowClassName3 = (record) => {
    return record.id === this.state.rowIdA3 ? 'clickRowStyl' : 'clickRowSty';
  }  //运行补充采取安全措施选中
  senddata4(record) {
    //console.log(record)
    this.setState({
      rowIdA4: record.id,
      rowIdAobj4: record,
    })
  }
  setRowClassName4 = (record) => {
    return record.id === this.state.rowIdA4 ? 'clickRowStyl' : 'clickRowSty';
  }

  //新建工作票
  handleAdd(key) {
    this.setState({
      flagname: key,
      runningmessure: "",//运行措施
      iptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  //从历史票新建
  toselefromhistory() {
    this.setState({
      flagname: ""
    }, function () {
      this.refs.WisdomTwoticketcon.style.display = "none"
      this.refs.historytickets.style.display = "block"
    })
  }
  //从历史票导入保存
  handleImport() {
    if (this.state.rowId) {
      axios.post('/Data/OM/OM_TicketInformation.aspx?flag=2', {
        TicketTypeid: this.state.rowIdobj.TicketTypeid,
        TicketFlowid: this.state.rowIdobj.TicketFlowid
      }).then(data => {
        if (data.data.success) {
          this.axiosgetlist()
          //关闭历史记录票
          this.handleCancelImport()
        } else {
          message.error(data.data.describe)
        }
      })
    } else {
      message.error('请选择数据！')
    }
  }
  //从历史票取消保存
  handleCancelImport() {
    this.setState({
      rowId: "",
      rowIdobj: {}
    })
    this.refs.WisdomTwoticketcon.style.display = "block"
    this.refs.historytickets.style.display = "none"
  }

  //从模板库选择
  toselefrommodel() {
    axios.get('/Data/OM/OM_TicketTypeFlow.aspx?flag=4').then(data => {
      console.log(data)
      if (data.data.success) {
        this.setState({
          workticketflowarr: data.data.data,
          flagname: "从模板库选择",
          newworktickettype: "",
          newworkticketflow: ""
        })
      } else {
        message.error(data.data.describe)
      }
    })
  }
  //从模板库新建保存
  tosureselesave() {
    if (this.state.newworktickettype && this.state.newworkticketflow) {
      if (this.state.flagname == "从模板库选择") {
        //新建票
        axios.post('/Data/OM/OM_TicketInformation.aspx?flag=3', {
          TicketTypeid: this.state.newworktickettype,
          FlowBaseID: this.state.newworkticketflow,
        }).then(data => {

          if (data.data.success == true) {
            this.axiosgetlist()
            this.setState({
              flagname: ""
            })
          } else {
            message.error(data.data.describe)
          }
        })
      } else if (this.state.flagname == "编辑票") {
        axios.post('/Data/OM/OM_TicketInformation.aspx?flag=4', {
          TicketTypeid: this.state.newworktickettype,
          TicketFlowid: this.state.newworkticketflow,
          id: this.state.workticketid
        }).then(data => {

          if (data.data.success == true) {
            this.axiosgetlist()
            this.setState({
              flagname: ""
            })
          } else {
            message.error(data.data.describe)
          }
        })
      }
    }
  }

  //关闭弹框
  toclosedragwindow() {
    this.setState({
      flagname: ""
    })
  }


  //编辑票类型及流程类型
  handleEdit(key, record) {
    if (key == "编辑票") {
      this.setState({
        flagname: key,
        workticketid: record.id,
        newworktickettype: record.TicketTypeid,
        newworkticketflow: record.TicketFlowid
      })
    } else if (key == "运行必须采取的安全措施编辑") {
      if (this.state.rowIdA1) {
        var obj = this.state.rowIdAobj1
        this.setState({
          runningmessure: obj.ContentOfMeasures,
          flagname: key
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "运行人员补充的安全措施编辑") {
      if (this.state.rowIdA2) {
        var obj = this.state.rowIdAobj2
        this.setState({
          runningmessure: obj.ContentOfMeasures,
          flagname: key
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "试运编辑") {
      if (this.state.rowIdA3) {
        var obj = this.state.rowIdAobj3
        this.setState({
          flagname: key,
          iptobj: obj
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "终结编辑") {
      if (this.state.rowIdA4) {
        var obj = this.state.rowIdAobj4
        this.setState({
          flagname: key,
          iptobj: obj
        })
      } else {
        message.error('请选择！')
      }
    }
  }

  //删除
  handleDel(key, record) {
    var _this = this
    if (key == "两票删除") {
      if (record.FlowInstanceID) {
        message.error('该票正在进行中,不允许删除!')
      } else {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/Data/OM/OM_TicketInformation.aspx?flag=5', {
              id: record.id
            }).then(data => {

              if (data.data.success) {
                const dataSource = [..._this.state.workticketdata];
                _this.setState({ workticketdata: dataSource.filter(item => item.id !== record.id) }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {

          },
        });
      }
    } else if (key == "运行必须采取的安全措施") {
      if (this.state.rowIdA1) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=4', {
              id: _this.state.rowIdA1
            }).then(data => {

              if (data.data.success) {
                const dataSource = [..._this.state.runningmessuredata1];
                _this.setState({ runningmessuredata1: dataSource.filter(item => item.id !== _this.state.rowIdA1), rowIdA1: "", rowIdAobj1: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {

          },
        });
      }
    } else if (key == "运行人员补充的安全措施") {
      if (this.state.rowIdA2) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=4', {
              id: _this.state.rowIdA2
            }).then(data => {

              if (data.data.success) {
                const dataSource = [..._this.state.runningmessuredata2];
                _this.setState({ runningmessuredata2: dataSource.filter(item => item.id !== _this.state.rowIdA2), rowIdA2: "", rowIdAobj2: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {

          },
        });
      } else {
        message.error('请选择！')
      }
    } else if (key == "试运") {
      if (this.state.rowIdA3) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/Data/OM/OM_TrialOperation.aspx?flag=3', {
              id: _this.state.rowIdA3
            }).then(data => {

              if (data.data.success) {
                const dataSource = [..._this.state.tryrundataarr];
                _this.setState({ tryrundataarr: dataSource.filter(item => item.id !== _this.state.rowIdA3), rowIdA3: "", rowIdAobj3: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {

          },
        });
      } else {
        message.error('请选择！')
      }
    } else if (key == "终结") {
      if (this.state.rowIdA4) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/Data/OM/OM_CompleteOperation.aspx?flag=3', {
              id: _this.state.rowIdA4
            }).then(data => {

              if (data.data.success) {
                const dataSource = [..._this.state.endthingdataarr];
                _this.setState({ endthingdataarr: dataSource.filter(item => item.id !== _this.state.rowIdA4), rowIdA4: "", rowIdAobj4: {} }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {

          },
        });
      } else {
        message.error('请选择！')
      }
    }
  }

  //两票详情
  handleDetail(key, record) {
    if (key == "两票详情") {
      //获取流程节点权限
      axios.post('/Data/OM/OM_TicketTypeFlow.aspx?flag=1', {
        IFlowID: record.FlowInstanceID ? record.FlowInstanceID : ""
      }).then(data => {
        console.log('获取流程节点权限', data)
        if (data.data.success == true) {
          var NodeIndex = Number(data.data.data.NodeIndex)
          this.setState({
            rowIdA: record.id,
            rowIdAobj: record,
            limitticketnum: NodeIndex,
            Version: data.data.data.Version,
            //变更 延期 试运 权限
            Control: data.data.data.Control,
            // //8模块默认展示
            ControlColor: data.data.data.ControlColor,
            ticketsave1: false,
            ticketsave2: false,
            ticketsave3: false,
            ticketsave4: false,
            ticketsave5: false,
            ticketsave6: false,
            ticketsave9: false,
          }, function () {
            //热力机械工作票
            axios.post('/Data/OM/OM_TicketInformationDetails.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success == true) {
                //str设备名称
                var str = data.data.data[0] && data.data.data[0].DeviceName ? data.data.data[0].DeviceName : ""
                var str1 = data.data.data[0] && data.data.data[0].DeviceKKS ? data.data.data[0].DeviceKKS : ""
                if (str == "") {
                  this.setState({
                    ticketiptobj: data.data.data[0] ? data.data.data[0] : {},
                    DeviceName: "",
                    selectedRowssele: [],
                    selectedRowsfu: []
                  })
                } else {
                  var arr = str.split(',')
                  var arr1 = str1.split(',')
                  var newarr = []
                  for (let i = 0; i < arr.length; i++) {
                    var obj = { Name: arr[i], KKS: arr1[i] }
                    newarr.push(obj)
                  }
                  //console.log(newarr)
                  this.setState({
                    ticketiptobj: data.data.data[0] ? data.data.data[0] : {},
                    DeviceName: str,
                    selectedRowssele: arr,
                    selectedRowsfu: newarr
                  })
                }
              } else {
                message.error(data.data.describe)
              }
            })
            //运行必须采取的安全措施
            axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata1: data.data.data,
                })
              } else {
                message.error(data.data.describe)
              }
            })
            //运行人员补充的安全措施
            axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {
              if (data.data.success) {
                this.setState({
                  runningmessuredata2: data.data.data,
                })
              } else {
                message.error(data.data.describe)
              }
            })
            //审批
            axios.post('/Data/OM/OM_ApprovalInformation.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  auditdataobj: data.data.data[0] ? data.data.data[0] : {}
                })
              } else {
                message.error(data.data.describe)
              }
            })
            //变更
            axios.post('/Data/OM/OM_ChangeInformation.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  changedataobj: data.data.data[0] ? data.data.data[0] : {}
                })
              } else {
                // message.error(data.data.describe)
              }
            })
            //延期 
            axios.post('/Data/OM/OM_ChangeTime.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  delaydataobj: data.data.data[0] ? data.data.data[0] : {}
                })
              } else {
                message.error(data.data.describe)
              }
            })
            //试运
            axios.post('/Data/OM/OM_TrialOperation.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  tryrundataarr: data.data.data
                })
              } else {
                message.error(data.data.describe)
              }
            })
            //终结
            axios.post('/Data/OM/OM_CompleteOperation.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  endthingdataarr: data.data.data
                })
              } else {
                message.error(data.data.describe)
              }
            })
            //总结
            axios.post('/Data/OM/OM_FinalTicket.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {
              if (data.data.success) {
                this.setState({
                  summarydataobj: data.data.data[0] ? data.data.data[0] : {},
                })
              } else {
                message.error(data.data.describe)
              }
            })

            if (NodeIndex == 1 || NodeIndex == 2) {
              this.toShowOrHide('热力机械工作票', 0)
            } else if (NodeIndex == 3) {
              this.toShowOrHide('运行人员补充的安全措施', 2)
            } else if (NodeIndex == 4 || NodeIndex == 6) {
              this.toShowOrHide('审批', 3)
            } else if (NodeIndex == 5) {
              this.toShowOrHide('运行必须采取的安全措施', 1)
            } else if (NodeIndex == 7 || NodeIndex == 8) {
              if (this.state.ControlColor.indexOf('5') > -1
                && this.state.ControlColor.indexOf('6') == -1
                && this.state.ControlColor.indexOf('7') == -1
              ) {
                this.toShowOrHide('变更', 4)
              } else if (this.state.ControlColor.indexOf('6') > -1
                && this.state.ControlColor.indexOf('5') == -1
                && this.state.ControlColor.indexOf('7') == -1
              ) {
                this.toShowOrHide('延期', 5)
              } else if (this.state.ControlColor.indexOf('7') > -1
                && this.state.ControlColor.indexOf('5') == -1
                && this.state.ControlColor.indexOf('6') == -1
              ) {
                this.toShowOrHide('试运', 6)
              } else {
                this.toShowOrHide('试运', 6)
              }
            } else if (NodeIndex == 10 || NodeIndex == 11 || NodeIndex == 12) {
              this.toShowOrHide('总结', 8)
            }
            this.refs.editworkticket.style.display = "block"
            this.refs.WisdomTwoticketcon.style.display = "none"
          })
        }
      })
    }
  }

  //保存
  handleSubmit = (key, e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d && !value._i) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
          } else if (value._f == "HH:mm:ss") {
            values[i] = value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else if (value._f == "YYYY-MM-DD") {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
          } else {
            values[i] = values[i]
          }
        }
        console.log(key)
        //编辑工作票保存
        if (key == "热力机械工作票") {
          var devicearr = this.state.selectedRowsfu
          var devicearrsele = values.DeviceName
          var devicekks = []
          for (let i = 0; i < devicearrsele.length; i++) {
            for (let j = 0; j < devicearr.length; j++) {
              if (devicearrsele[i] == devicearr[j].Name) {
                devicekks.push(devicearr[j].KKS)
              }
            }
          }
          values.Ticketid = this.state.rowIdA
          values.StartTime = todatetime(values.StartEndTime[0]._d)
          values.EndTime = todatetime(values.StartEndTime[1]._d)
          values.TeamPeoples = values.TeamPeoples.join(',')
          values.DeviceName = values.DeviceName.join(',')
          values.DeviceKKS = devicekks.join(',')
          values.userName = this.state.uname
          values.DutyPeople = values.CreatePeople
          if (values.DeviceName && values.DeviceKKS) {
            axios.post('/Data/OM/OM_TicketInformationDetails.aspx?flag=2', values).then(data => {

              if (data.data.success) {
                this.setState({
                  ticketsave1: true
                })

                axios.post('/Data/OM/OM_TicketInformationDetails.aspx?flag=1', {
                  Ticketid: this.state.rowIdA
                }).then(data => {

                  if (data.data.success == true) {
                    //str设备名称
                    var str = data.data.data[0] && data.data.data[0].DeviceName ? data.data.data[0].DeviceName : ""
                    var str1 = data.data.data[0] && data.data.data[0].DeviceKKS ? data.data.data[0].DeviceKKS : ""
                    if (str == "") {
                      this.setState({
                        ticketiptobj: data.data.data[0] ? data.data.data[0] : {},
                        DeviceName: "",
                        selectedRowssele: [],
                        selectedRowsfu: []
                      })
                    } else {
                      var arr = str.split(',')
                      var arr1 = str1.split(',')
                      var newarr = []
                      for (let i = 0; i < arr.length; i++) {
                        var obj = { Name: arr[i], KKS: arr1[i] }
                        newarr.push(obj)
                      }
                      //console.log(newarr)
                      this.setState({
                        ticketiptobj: data.data.data[0] ? data.data.data[0] : {},
                        DeviceName: str,
                        selectedRowssele: arr,
                        selectedRowsfu: newarr
                      })
                    }
                  } else {
                    message.error(data.data.describe)
                  }
                })
                message.success('保存成功！')
              }
            })
          }
        } else if (key == "审批") {
          values.Ticketid = this.state.rowIdA
          values.ApprovedStartTime = todatetime(values.ApprovedSETime[0]._d)
          values.ApprovedEndTime = todatetime(values.ApprovedSETime[1]._d)
          values.ApprovedTime = this.state.auditdataobj.ApprovedTime ? this.state.auditdataobj.ApprovedTime : null
          values.PermittedWorkTime = values.PermittedWorkTime ? values.PermittedWorkTime : null
          values.userName = this.state.uname
          axios.post('/Data/OM/OM_ApprovalInformation.aspx?flag=2', values).then(data => {
            if (data.data.success) {
              this.setState({
                ticketsave4: true
              })

              axios.post('/Data/OM/OM_ApprovalInformation.aspx?flag=1', {
                Ticketid: this.state.rowIdA
              }).then(data => {
                if (data.data.success) {
                  this.setState({
                    auditdataobj: data.data.data[0] ? data.data.data[0] : {}
                  })
                } else {
                  message.error(data.data.describe)
                }
              })
              message.success('保存成功！')
            }
          })
        } else if (key == "变更") {
          values.Ticketid = this.state.rowIdA
          values.IssueTime = this.state.changedataobj.IssueTime ? this.state.changedataobj.IssueTime : null
          values.LicensorTime = this.state.changedataobj.LicensorTime ? this.state.changedataobj.LicensorTime : null
          values.userName = this.state.uname
          axios.post('/Data/OM/OM_ChangeInformation.aspx?flag=2', values).then(data => {

            if (data.data.success) {
              axios.post('/Data/OM/OM_TicketTypeFlow.aspx?flag=1', {
                IFlowID: this.state.rowIdAobj.FlowInstanceID
              }).then(data => {
                //console.log('获取流程节点权限', data)
                if (data.data.success == true) {
                  var NodeIndex = Number(data.data.data.NodeIndex)
                  this.setState({
                    limitticketnum: NodeIndex,
                    ticketsave5: true
                    //变更 延期 试运 权限
                    // Control:data.data.data.Control,
                    // //8模块默认展示
                    // ControlColor:data.data.data.ControlColor,
                  })
                }
              })

              axios.post('/Data/OM/OM_ChangeInformation.aspx?flag=1', {
                Ticketid: this.state.rowIdA
              }).then(data => {

                if (data.data.success) {
                  this.setState({
                    changedataobj: data.data.data[0] ? data.data.data[0] : {}
                  })
                } else {
                  // message.error(data.data.describe)
                }
              })
              message.success('保存成功！')
            }
          })
        } else if (key == "延期") {
          values.Ticketid = this.state.rowIdA
          values.LicensorTime = this.state.delaydataobj.LicensorTime ? this.state.delaydataobj.LicensorTime : null
          values.ShiftTime = this.state.delaydataobj.ShiftTime ? this.state.delaydataobj.ShiftTime : null
          values.userName = this.state.uname
          axios.post('/Data/OM/OM_ChangeTime.aspx?flag=2', values).then(data => {
            if (data.data.success) {
              axios.post('/Data/OM/OM_TicketTypeFlow.aspx?flag=1', {
                IFlowID: this.state.rowIdAobj.FlowInstanceID
              }).then(data => {
                //console.log('获取流程节点权限', data)
                if (data.data.success == true) {
                  var NodeIndex = Number(data.data.data.NodeIndex)
                  this.setState({
                    limitticketnum: NodeIndex,
                    ticketsave6: true,
                    //变更 延期 试运 权限
                    Control: data.data.data.Control,
                    // //8模块默认展示
                    ControlColor: data.data.data.ControlColor,
                  })

                  axios.post('/Data/OM/OM_ChangeTime.aspx?flag=1', {
                    Ticketid: this.state.rowIdA
                  }).then(data => {

                    if (data.data.success) {
                      this.setState({
                        delaydataobj: data.data.data[0] ? data.data.data[0] : {}
                      })
                    } else {
                      message.error(data.data.describe)
                    }
                  })
                }
              })
              message.success('保存成功！')
            }
          })
        } else if (key == "总结") {
          values.userName = this.state.uname
          values.Ticketid = this.state.rowIdA
          values.DutyPeopleTime = this.state.summarydataobj.DutyPeopleTime ? this.state.summarydataobj.DutyPeopleTime : ""
          values.LicensorTime = this.state.summarydataobj.LicensorTime ? this.state.summarydataobj.LicensorTime : ""
          axios.post('/Data/OM/OM_FinalTicket.aspx?flag=2', values).then(data => {

            if (data.data.success) {
              this.setState({
                ticketsave9: true
              })

              axios.post('/Data/OM/OM_FinalTicket.aspx?flag=1', {
                Ticketid: this.state.rowIdA
              }).then(data => {
                if (data.data.success) {
                  this.setState({
                    summarydataobj: data.data.data[0] ? data.data.data[0] : {},
                  })
                } else {
                  message.error(data.data.describe)
                }
              })
              message.success('保存成功！')
            }
          })
        } else if (key == "试运添加") {
          values.Ticketid = this.state.rowIdA
          values.userName = this.state.uname
          values.TrialOperationTime = ""
          values.Licensor = ""
          values.LicensorTime = ""
          values.DutyPeople = ""
          values.DutyPeopleTime = ""
          axios.post('/Data/OM/OM_TrialOperation.aspx?flag=2', values).then(data => {

            if (data.data.success) {
              axios.post('/Data/OM/OM_TrialOperation.aspx?flag=1', {
                Ticketid: this.state.rowIdA
              }).then(data => {

                if (data.data.success) {
                  this.setState({
                    tryrundataarr: data.data.data,
                  })
                  message.success('添加成功！')
                } else {
                  message.error(data.data.describe)
                }
              })
            }
          })
        } else if (key == "试运编辑") {
          values.id = this.state.rowIdA3
          values.Ticketid = this.state.rowIdA
          values.userName = this.state.uname
          values.TrialOperationTime = values.TrialOperationTime
          values.Licensor = ""
          values.LicensorTime = ""
          values.DutyPeople = ""
          values.DutyPeopleTime = ""
          axios.post('/Data/OM/OM_TrialOperation.aspx?flag=4', values).then(data => {

            if (data.data.success) {
              axios.post('/Data/OM/OM_TrialOperation.aspx?flag=1', {
                Ticketid: this.state.rowIdA
              }).then(data => {

                if (data.data.success) {
                  this.setState({
                    tryrundataarr: data.data.data,
                    flagname: "",
                  })
                  // message.success('保存成功！')
                  this.props.form.resetFields();
                } else {
                  message.error(data.data.describe)
                }
              })
            }
          })
        } else if (key == "终结添加") {
          values.Ticketid = this.state.rowIdA
          values.userName = this.state.uname
          values.CompleteOperationTime = ""
          values.Licensor = ""
          values.LicensorTime = ""
          values.DutyPeople = ""
          values.DutyPeopleTime = ""
          axios.post('/Data/OM/OM_CompleteOperation.aspx?flag=2', values).then(data => {

            if (data.data.success) {
              axios.post('/Data/OM/OM_CompleteOperation.aspx?flag=1', {
                Ticketid: this.state.rowIdA
              }).then(data => {

                if (data.data.success) {
                  this.setState({
                    endthingdataarr: data.data.data,
                  })
                  message.success('添加成功！')
                } else {
                  message.error(data.data.describe)
                }
              })
            }
          })
        } else if (key == "终结编辑") {
          values.id = this.state.rowIdA4
          values.Ticketid = this.state.rowIdA
          values.userName = this.state.uname
          values.CompleteOperationTime = values.CompleteOperationTime
          values.Licensor = ""
          values.LicensorTime = ""
          values.DutyPeople = ""
          values.DutyPeopleTime = ""
          axios.post('/Data/OM/OM_CompleteOperation.aspx?flag=4', values).then(data => {

            if (data.data.success) {
              axios.post('/Data/OM/OM_CompleteOperation.aspx?flag=1', {
                Ticketid: this.state.rowIdA
              }).then(data => {

                if (data.data.success) {
                  this.setState({
                    endthingdataarr: data.data.data,
                    flagname: "",
                  })
                  this.props.form.resetFields();
                } else {
                  message.error(data.data.describe)
                }
              })
            }
          })
        } else if (key == "选择下一个处理人") {
          if (this.state.limitticketnum == 1) {
            axios.post('/Data/OM/OM_TicketTypeFlow.aspx?flag=2', {
              FlowID: this.state.rowIdAobj.FlowBaseID,
              Ticketid: this.state.rowIdA,
              NextDutyPerson: values.NextDutyPerson
            }).then(data => {

              if (data.data.success) {
                this.axiosgetlist()
                message.success('操作成功！')
                this.toexiteditpage()
              } else {
                message.error(data.data.describe)
              }
            })
          } else {
            axios.post('/Data/OM/OM_TicketTypeFlow.aspx?flag=3', {
              AuditUsers: values.NextDutyPerson,
              AuditOpinion: "0",
              Version: this.state.Version,
              IFlowId: this.state.rowIdAobj.FlowInstanceID,
            }).then(data => {

              if (data.data.success) {
                this.axiosgetlist()
                message.success('操作成功！')
                this.toexiteditpage()
              } else {
                message.error(data.data.describe)
              }
            })
          }
        }
      }
    });
  }

  //安全措施保存
  tosavetwoticketedit(key) {
    if (key == "必须采取") {
      var arr = this.state.runningmessuredata1
      //console.log(arr)
      axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=7', {
        data: JSON.stringify(arr),
        userName: this.state.uname
      }).then(data => {

        if (data.data.success) {
          this.setState({
            ticketsave2: true
          })
          message.success('操作成功！')
        } else {
          message.error(data.data.describe)
        }
      })
    } else if (key == "人员补充") {
      var arr = this.state.runningmessuredata2
      axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=7', {
        data: JSON.stringify(arr),
        userName: this.state.uname
      }).then(data => {

        if (data.data.success) {
          this.setState({
            ticketsave3: true
          })
          message.success('操作成功！')
        } else {
          message.error(data.data.describe)
        }
      })
    }
  }

  pagechange(key, page) {
    if (key == "工作票") {
      this.setState({
        pageIndex: page.current
      })
    } else if (key == "历史票") {
      this.setState({
        pageIndex1: page.current
      })
    }
  }

  //退出工作票编辑页面
  toexiteditpage() {
    this.setState({
      flagname: "",
      FlagNameEdit: ""
    })
    this.axiosgetlist();
    var workticketseditele = document.querySelectorAll('.editworkticket >div')
    for (let i = 0; i < workticketseditele.length; i++) {
      $('.editworkticket >div').eq(i).removeClass('divselectsstyle')
      $('.editworkticket >div .h3title').eq(i).removeClass('h3titleselectstyle')
    }
    this.refs.editworkticket.style.display = "none"
    this.refs.WisdomTwoticketcon.style.display = "block"
  }
  //工作票返回
  tocanceltwoticketedit() {
    this.toexiteditpage()
  }
  //工作票返回
  tocanceltwoticketedit1() {
    if (this.state.limitticketnum == 1) {
      if (this.state.ticketsave1) {
        this.toexiteditpage()
      } else {
        message.error('请先保存票数据！')
      }
    }
    //审批
    else if (this.state.limitticketnum == 4 || this.state.limitticketnum == 6) {
      if (this.state.ticketsave4) {
        this.toexiteditpage()
      } else {
        message.error('请先保存票数据！')
      }
    }
    //通知 执行
    else if (this.state.limitticketnum == 5) {
      if (this.state.ticketsave2 && this.state.ticketsave3) {
        this.toexiteditpage()
      } else {
        message.error('请先保存票数据！')
      }
    }
    else if (this.state.limitticketnum == 7) {
      if (this.state.ticketsave5 || this.state.ticketsave6) {
        this.toexiteditpage()
      } else {
        message.error('请先保存票数据！')
      }
    }
    else if (this.state.limitticketnum == 11) {
      if (this.state.ticketsave9) {
        this.toexiteditpage()
      } else {
        message.error('请先保存票数据！')
      }
    }
    else {
      this.toexiteditpage()
    }
  }
  //工作票驳回
  toReturnBack() {
    var _this = this
    confirm({
      title: '确定要驳回吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post('/Data/OM/OM_TicketTypeFlow.aspx?flag=3', {
          // TicketTypeid:_this.state.rowIdAobj.TicketTypeid,
          // Ticketid:_this.state.rowIdA,
          AuditOpinion: "1",
          Version: _this.state.Version,
          IFlowId: _this.state.rowIdAobj.FlowInstanceID,
          AuditUsers: ""
        }).then(data => {

          if (data.data.success) {
            message.success('操作成功！')
            _this.toexiteditpage()
          } else {
            message.error(data.data.describe)
          }
        })
      },
      onCancel() {

      },
    });
  }
  //工作票流转 选择下一个处理人 
  toTurnNext() {
    //1热力机械工作票 必须采取措施 2签票 3接票 4审批 5通知 6确认 7申请试运/变更/延期 8同意试运
    //开始
    axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=51', {
      ID: this.state.rowIdAobj.FlowInstanceID ? this.state.rowIdAobj.FlowInstanceID : "",
      FlowID: this.state.rowIdAobj.FlowBaseID ? this.state.rowIdAobj.FlowBaseID : ""
    }).then(data => {
      if (data.data.success) {
        this.setState({
          NextUserarr: data.data.data
        })
      } else {
        message.error(data.data.describe)
      }
    })
    if (this.state.limitticketnum == 1) {
      if ((this.state.ticketiptobj.DeviceName != "" || this.state.ticketsave1) && this.state.runningmessuredata1.length > 0) {
        this.setState({
          flagname: '选择下一个处理人'
        })
      } else {
        message.error('请填写票内容并保存！')
      }
    }
    //审批
    else if (this.state.limitticketnum == 4) {
      if (this.state.auditdataobj.ApprovedStartTime || this.state.ticketsave4) {
        this.setState({
          flagname: '选择下一个处理人'
        })
      } else {
        message.error('请填写票内容并保存！')
      }
    }
    else if (this.state.limitticketnum == 6) {
      if (this.state.auditdataobj.PermittedWorkTime || this.state.ticketsave4) {
        this.setState({
          flagname: '选择下一个处理人'
        })
      } else {
        message.error('请填写票内容并保存！')
      }
    }
    //通知 执行
    else if (this.state.limitticketnum == 5) {
      var arr1 = this.state.runningmessuredata1
      var arr1flag = true
      if (arr1.length > 0) {
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i].Implement != "true") {
            arr1flag = false
          }
        }
      }
      var arr2 = this.state.runningmessuredata2
      var arr2flag = true
      if (arr2.length > 0) {
        for (let i = 0; i < arr2.length; i++) {
          if (arr2[i].AImplement != "true") {
            arr2flag = false
          }
        }
      } else {
        arr2flag = true
      }
      if ((this.state.ticketsave2 || arr1flag) && arr2flag) {
        this.setState({
          flagname: '选择下一个处理人'
        })
      } else {
        message.error('请填写票内容并保存！')
      }
    }
    else if (this.state.limitticketnum == 7) {
      if (this.state.changedataobj.DutyPeople || this.state.ticketsave5 || this.state.delaydataobj.EndTime || this.state.ticketsave6 || this.state.tryrundataarr.length > 0) {
        this.setState({
          flagname: '选择下一个处理人'
        })
      } else {
        message.error('请填写票内容并保存！')
      }
    }
    else if (this.state.limitticketnum == 8) {
      var arr = this.state.tryrundataarr
      var flag = true
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].TrialOperationTime == "" || arr[i].TrialOperationTime.indexOf('1900-01-01') > -1) {
          flag = false
        }
      }
      if (flag) {
        this.setState({
          flagname: '选择下一个处理人'
        })
      } else {
        message.error('请填写票内容并保存！')
      }
    }
    else if (this.state.limitticketnum == 11) {
      if (this.state.ticketsave9 || this.state.summarydataobj.FinalTime
        || this.state.endthingdataarr.length > 0) {
        this.setState({
          flagname: '选择下一个处理人'
        })
      } else {
        message.error('请填写票内容并保存！')
      }
    } else if (this.state.limitticketnum == 12) {
      this.setState({
        flagname: ''
      })
      axios.post('/Data/OM/OM_TicketTypeFlow.aspx?flag=3', {
        AuditUsers: this.state.uname,
        AuditOpinion: "0",
        Version: this.state.Version,
        IFlowId: this.state.rowIdAobj.FlowInstanceID,
      }).then(data => {
        if (data.data.success) {
          this.axiosgetlist()
          message.success('操作成功！')
          this.toexiteditpage()
        } else {
          message.error(data.data.describe)
        }
      })
    }
    else {
      this.setState({
        flagname: '选择下一个处理人'
      })
    }
  }
  //下一个处理人取消选择
  tocancelselenextdealier() {
    this.setState({
      flagname: "",
    }, function () {
      document.querySelector('.WisdomTwoticketdrag').style.display = "none"
    })
  }
  //工作票做废
  toDisablethis() {
    var _this = this
    confirm({
      title: '确定要作废吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post('/Data/OM/OM_TicketTypeFlow.aspx?flag=3', {
          AuditOpinion: "2",
          Version: _this.state.Version,
          IFlowId: _this.state.rowIdAobj.FlowInstanceID,
          AuditUsers: ""
        }).then(data => {
          if (data.data.success) {
            message.success('操作成功！')
            _this.toexiteditpage()
          } else {
            message.error(data.data.describe)
          }
        })
      },
      onCancel() {

      },
    });
  }

  //工作票模块显示模块
  toShowOrHide(key, index) {
    var workticketseditele = document.querySelectorAll('.editworkticket >div')
    for (let i = 0; i < workticketseditele.length; i++) {
      $('.editworkticket >div').eq(i).removeClass('divselectsstyle')
      $('.editworkticket >div .h3title').eq(i).removeClass('h3titleselectstyle')
    }
    var FlagNameEditname = this.state.FlagNameEdit
    if (FlagNameEditname == key) {
      this.setState({
        FlagNameEdit: ""
      })
    } else {
      this.setState({
        FlagNameEdit: key
      })
      $('.editworkticket >div').eq(index).addClass('divselectsstyle')
      $('.editworkticket >div .h3title').eq(index).addClass('h3titleselectstyle')
    }
  }

  //安全措施添加编辑保存
  tosuremessuresave() {
    if (this.state.flagname == "运行必须采取的安全措施添加") {
      if (this.state.runningmessure) {
        axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=2', {
          Ticketid: this.state.rowIdA,
          ContentOfMeasures: this.state.runningmessure
        }).then(data => {
          if (data.data.success) {
            axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata1: data.data.data,
                  runningmessuredata1: data.data.data,
                  flagname: "",
                })
              } else {
                message.error(data.data.describe)
              }
            })
          }
        })
      }
    } else if (this.state.flagname == "运行必须采取的安全措施编辑") {
      if (this.state.runningmessure) {
        axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=3', {
          id: this.state.rowIdA1,
          ContentOfMeasures: this.state.runningmessure
        }).then(data => {
          if (data.data.success) {
            axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata1: data.data.data,
                  runningmessuredata1: data.data.data,
                  flagname: ""
                })
              } else {
                message.error(data.data.describe)
              }
            })
          }
        })
      }
    } else if (this.state.flagname == "运行人员补充的安全措施添加") {
      if (this.state.runningmessure) {
        axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=2', {
          Ticketid: this.state.rowIdA,
          ContentOfMeasures: this.state.runningmessure
        }).then(data => {
          if (data.data.success) {
            axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata2: data.data.data,
                  runningmessuredata2: data.data.data,
                  flagname: ""
                })
              } else {
                message.error(data.data.describe)
              }
            })
          }
        })
      }
    } else if (this.state.flagname == "运行人员补充的安全措施编辑") {
      if (this.state.runningmessure) {
        axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=3', {
          id: this.state.rowIdA2,
          ContentOfMeasures: this.state.runningmessure
        }).then(data => {
          if (data.data.success) {
            axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata2: data.data.data,
                  runningmessuredata2: data.data.data,
                  flagname: ""
                })
              } else {
                message.error(data.data.describe)
              }
            })
          }
        })
      }
    }
  }
  //安全措施添加编辑保存取消
  tocancelmessuresave() {
    this.setState({
      flagname: ""
    })
  }

  //安全措施 上下移
  toUpshift(key) {
    if (key == "运行必须采取的安全措施") {
      var selerowid = this.state.rowIdA1
      var arr = this.state.runningmessuredata1
      if (arr[0].id == selerowid) {
        message.error('无法操作！')
      } else {
        var upobj = {}
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == selerowid) {
            upobj = arr[i - 1]
          }
        }
        axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=5', {
          id1: selerowid,
          num1: this.state.rowIdAobj1.Num,
          id2: upobj.id,
          num2: upobj.Num
        }).then(data => {
          if (data.data.success) {
            axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata1: data.data.data,
                  flagname: ""
                }, function () {
                  message.success('操作成功！')
                })
              } else {
                message.error(data.data.describe)
              }
            })
          }
        })
      }
    } else if (key == "运行人员补充的安全措施") {
      var selerowid = this.state.rowIdA2
      var arr = this.state.runningmessuredata2
      if (arr[0].id == selerowid) {
        message.error('无法操作！')
      } else {
        var upobj = {}
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == selerowid) {
            upobj = arr[i - 1]
          }
        }
        axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=5', {
          id1: selerowid,
          num1: this.state.rowIdAobj2.Num,
          id2: upobj.id,
          num2: upobj.Num
        }).then(data => {
          if (data.data.success) {
            axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata2: data.data.data,
                  flagname: ""
                }, function () {
                  message.success('操作成功！')
                })
              } else {
                message.error(data.data.describe)
              }
            })
          }
        })
      }
    }
  }
  toDownshift(key) {
    if (key == "运行必须采取的安全措施") {
      var selerowid = this.state.rowIdA1
      var arr = this.state.runningmessuredata1
      if (arr[arr.length - 1].id == selerowid) {
        message.error('无法操作！')
      } else {
        var upobj = {}
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == selerowid) {
            upobj = arr[i + 1]
          }
        }
        axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=5', {
          id1: selerowid,
          num1: this.state.rowIdAobj1.Num,
          id2: upobj.id,
          num2: upobj.Num
        }).then(data => {
          if (data.data.success) {
            axios.post('/Data/OM/OM_TicketMustBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata1: data.data.data,
                  flagname: ""
                }, function () {
                  message.success('操作成功！')
                })
              } else {
                message.error(data.data.describe)
              }
            })
          }
        })
      }
    } else if (key == "运行人员补充的安全措施") {
      var selerowid = this.state.rowIdA2
      var arr = this.state.runningmessuredata2
      if (arr[arr.length - 1].id == selerowid) {
        message.error('无法操作！')
      } else {
        var upobj = {}
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == selerowid) {
            upobj = arr[i + 1]
          }
        }
        axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=5', {
          id1: selerowid,
          num1: this.state.rowIdAobj2.Num,
          id2: upobj.id,
          num2: upobj.Num
        }).then(data => {
          if (data.data.success) {
            axios.post('/Data/OM/OM_TicketAddBeTake.aspx?flag=1', {
              Ticketid: this.state.rowIdA
            }).then(data => {

              if (data.data.success) {
                this.setState({
                  runningmessuredata2: data.data.data,
                  flagname: ""
                }, function () {
                  message.success('操作成功！')
                })
              } else {
                message.error(data.data.describe)
              }
            })
          }
        })
      }
    }
  }

  //多级条件搜索框
  toshowMore(e) {
    $('.showhide').css("display", "block")
  }
  //两票列表搜索
  handleSearch(key) {
    var arr = this.refs.mySearchCondition.state.allcondition
    var display = $('.showhide').css("display")
    if (display == "none") {
      if (this.state.wconditon1 && this.state.wconditon2 && this.state.wconditon3) {
        var arrnone = [{
          conditon1: this.state.wconditon1, conditon2: this.state.wconditon2,
          conditon3: this.state.wconditon3, conditon4: this.state.wconditon4
        }]
        axios.post('/Data/OM/OM_TicketInformation.aspx?flag=1', {
          data: JSON.stringify(arrnone)
        }).then(data => {
          if (data.data.success == true) {

            if (key == "两票") {
              this.setState({
                workticketdata: data.data.data
              })
            } else if (key == "历史票") {
              this.setState({
                workticketeddata: data.data.data
              })
            }
          } else {
            message.error(data.data.describe)
          }
        })
      }
    } else {
      var newarr = []
      if (this.state.wconditon1 && this.state.wconditon2 && this.state.wconditon3 && this.state.wconditon4) {
        var obj = {
          conditon1: this.state.wconditon1, conditon2: this.state.wconditon2,
          conditon3: this.state.wconditon3, conditon4: this.state.wconditon4
        }
        newarr.push(obj)
      }

      for (let i = 0; i < arr.length; i++) {
        if (i != arr.length - 1 && arr[i].conditon1 && arr[i].conditon2 && arr[i].conditon3 && arr[i].conditon4) {
          newarr.push(arr[i])
        } else if (i == arr.length - 1 && arr[i].conditon1 && arr[i].conditon2 && arr[i].conditon3) {
          newarr.push(arr[i])
        }
      }
      if (newarr.length > 0) {
        axios.post('/Data/OM/OM_TicketInformation.aspx?flag=1', {
          data: JSON.stringify(newarr)
        }).then(data => {
          if (data.data.success == true) {

            if (key == "两票") {
              this.setState({
                workticketdata: data.data.data
              })
            } else if (key == "历史票") {
              this.setState({
                workticketeddata: data.data.data
              })
            }
          } else {
            message.error(data.data.describe)
          }
        })
      }
    }
  }

  //热力机械工作票 选择设备
  toSeleequipment() {
    this.setState({
      flagname: '选择设备',
      //清空设备选择里的数据
      equipdata: [],
      selectedKeys: [],
      selectedRows: [],
      selectedRowKeys: []
    }, function () {
      axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(data => {
        console.log(data)
        var attrA = {
          ID: 'ID',
          ParentId: 'ParentId',
          Code: 'Code',
          Name: 'Name',
          rootId: "0",
          Leaf: 'Leaf',
          Rank: 'Rank',
          RecordVersion: 'RecordVersion',
          UpdateTime: 'UpdateTime',
          UpdatePeople: 'UpdatePeople',
          CreatePeople: 'CreatePeople',
          CreateTime: 'CreateTime'
        }
        if (conversion(data).length > 0) {
          let newarr = toProTreeData1(conversion(data), attrA)
          console.log(newarr)
          var expandedKeysarr = []
          if (newarr.length > 0) {
            for (let i = 0; i < newarr.length; i++) {
              expandedKeysarr.push(newarr[i].ID)
            }
          }
          this.setState({
            KKSdata: newarr,
            expandedKeys: expandedKeysarr
          })
        } else {
          this.setState({
            KKSdata: [],
            expandedKeys: []
          })
        }
      })
    })
  }
  //KKS树选择获取设备
  onSelect(selectedKeys, info) {
    if (selectedKeys[0] == "0" || info.node.props.pos.split('-').length == 3) {
      this.setState({
        selectedKeys: []
      })
    } else {
      this.setState({
        selectedKeys: selectedKeys
      }, function () {
        var arr = this.state.equipdata
        axios.post('/DATA/ParaSet/ParaSet.aspx?flag=40', {
          ID: this.state.selectedKeys[0]
        }).then(data => {
          console.log('kks', conversion(data))
          var arr1 = conversion(data)
          var newarr = [...new Set([...arr1, ...arr])]
          //console.log(newarr)
          var newarrfilter = newarr.filter(function(e){
            return e.KKS.length>8
          })
          this.setState({
            equipdata: newarrfilter
          })
        })
      })
    }
  }
  //比较俩数组 求并集
  // comparetwoarr(arr1, arr2) {
  //   var temp = []; //临时数组1  
  //   var temparray = [];//临时数组2  
  //   for (let i = 0; i < arr2.length; i++) {
  //     temp[arr2[i]] = true;//巧妙地方：把数组B的值当成临时数组1的键并赋值为真  
  //   }
  //   for (let i = 0; i < arr1.length; i++) {
  //     if (!temp[arr1[i]]) {
  //       temparray.push(arr1[i]);//巧妙地方：同时把数组A的值当成临时数组1的键并判断是否为真，如果不为真说明没重复，就合并到一个新数组里，这样就可以得到一个全新并无重复的数组  
  //     }
  //   }
  //   return temparray
  // }
  comparetwoarr(arr1,arr2){
    var tempKeys = []
    for(var item of arr1){
      if(tempKeys.indexOf(item['KKS']) == -1){
        tempKeys.push(item['KKS'])
      }
    }
    for(var item of arr2){
      let sign = true
      for(var key of tempKeys){
        if(key == item['KKS']){
          sign = false
        }
      }
      if(sign){
        arr1.push(item)
      }
    }
    return arr1
  }
  //删除设备
  todelselekks(key) {
    var arr = this.state.selectedRowssele
    for (let i = 0; i < arr.length; i++) {
      if (i == key) {
        arr.splice(i, 1)
      }
    }
    var str = ''
    var kksstr = ''
    for (let i = 0; i < arr.length; i++) {
      if (i == arr.length - 1) {
        str = str + arr[i].Name
        kksstr = kksstr + arr[i].KKS
      } else {
        str = str + arr[i].Name + ','
        kksstr = kksstr + arr[i].KKS + ','
      }
    }
    this.setState({
      selectedRowssele: arr,
      DeviceName: str,
      DeviceKKS: kksstr,
    })
    // var a = this.state.equipdata
    // var b = this.state.selectedRows
    // for(var i=0;i<b.length;i++)
    // { 
    //     for(var j=0;j<a.length;j++) 
    //     { 
    //       if(a[j].id==b[i].id){ 
    //       a.splice(j,1); 
    //       j=j-1; 
    //     } 
    //   } 
    // } 
    // this.setState({
    //   equipdata:a,
    //   selectedRows:[],
    //   selectedRowKeys:[]
    // })
  }

  

  //添加设备保存
  tosaveselekks() {
    // var arr = this.state.equipdata
    var arr = this.state.selectedRows
    var arrfu = this.state.selectedRowsfu
    var newarr = this.comparetwoarr(arr,arrfu)
    console.log(newarr)

    var selectedRowssele = []
    var str = ''
    var kksstr = ''
    for (let i = 0; i < newarr.length; i++) {
      selectedRowssele.push(newarr[i].Name)
      if (i == newarr.length - 1) {
        str = str + newarr[i].Name
        kksstr = kksstr + newarr[i].KKS
      } else {
        str = str + newarr[i].Name + ','
        kksstr = kksstr + newarr[i].KKS + ','
      }
    }
    this.setState({
      DeviceName: str,
      DeviceKKS: kksstr,
      flagname: "",
      //清空设备选择里的数据
      equipdata: [],
      selectedKeys: [],
      selectedRows: [],
      selectedRowKeys: [],
      selectedRowssele: selectedRowssele,
      selectedRowsfu: newarr
    }, function () {
      this.props.form.resetFields(`DeviceName`, selectedRowssele);
    })
  }
  //取消设备保存
  tocancelselekks() {
    this.setState({
      flagname: "",
      //清空设备选择里的数据
      equipdata: [],
      selectedKeys: [],
      selectedRows: [],
      selectedRowKeys: []
    })
  }
  // KKS树展开节点
  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  //点击关闭搜索
  toclosesearchcon() {
    $('.showhide').css("display", "none")
  }

  render() {
    const { searchValue, autoExpandParent, expandedKeys, selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({  selectedRowKeys, selectedRows});
      }
    }
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth

    const { getFieldDecorator } = this.props.form;
    const confun = (text) => {
      if (this.state.FlagNameEdit == text) {
        return (<img src={require('../../assets/两票icon/2.png')} alt="" />)
      } else {
        return (<img src={require('../../assets/两票icon/1.png')} alt="" />)
      }
    }
    const loop = data =>
      data.map(item => {
        const index = item.Code.indexOf(searchValue);
        const beforeStr = item.Code.substr(0, index);
        const afterStr = item.Code.substr(index + searchValue.length);

        const index1 = item.Name.indexOf(searchValue);
        const beforeStr1 = item.Name.substr(0, index);
        const afterStr1 = item.Name.substr(index + searchValue.length);

        const title = searchValue == "" ? (<span>{item.Code + '-' + item.Name}</span>) :
          index > -1 ? (<span>
            {beforeStr}
            <span style={{ color: '#f50' }}>{searchValue}</span>
            {afterStr}
            <span>{'-' + item.Name}</span>
          </span>) :
            index1 > -1 ? (<span>
              <span>{item.Code + '-'}</span>
              {beforeStr1}
              <span style={{ color: '#f50' }}>{searchValue}</span>
              {afterStr1}
            </span>) :
              (<span>{item.Code + '-' + item.Name}</span>)

        if (item.children) {
          return (
            <TreeNode key={item.ID} title={title}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} title={title} />;
      });
    return (
      <section className="WisdomTwoticket" style={{ height: clientHeight - 50 + 'px' }}>
        {/* 弹框 */}
        <div className="WisdomTwoticketdrag dragwindow" ref="WisdomTwoticketdrag"
          style={{
            display: this.state.flagname && this.state.flagname != "选择下一个处理人" &&
              this.state.flagname.indexOf('试运') == -1 && this.state.flagname.indexOf('终结') == -1 ? "block" : "none"
          }}>
          {
            this.state.flagname == "两票详情" ?
              <div className="twoticketdetail">
                <h2 className="h2title">
                  <span>流程详情</span>
                  <span className="dragwindowclose" onClick={this.toclosedragwindow.bind(this)}><img src={require('../../assets/两票icon/guanbi.png')} alt="" /></span>
                </h2>
                <div className="listscroll">
                  <Table
                    size="small"
                    dataSource={this.state.processdata}
                    columns={this.processcolumns}
                    scroll={{ y: 300 }}
                    pagination={false}
                  />
                </div>
              </div>
              :
              this.state.flagname == "新建票" ?
                <div className="createworkticket">
                  <h2 className="h2title">
                    <span>新建工作票</span>
                    <span className="dragwindowclose" onClick={this.toclosedragwindow.bind(this)}><img src={require('../../assets/两票icon/guanbi.png')} alt="" /></span>
                  </h2>
                  <div className="workticketsele">
                    <Button onClick={this.toselefromhistory.bind(this)}>从历史票选择</Button>
                    <Button onClick={this.toselefrommodel.bind(this, '从模板库选择')}>从模板库选择</Button>
                  </div>
                </div>
                :
                this.state.flagname == "从模板库选择" || this.state.flagname == "编辑票" ?
                  <div className="seletwotickettype">
                    <h2 className="h2title">
                      <span>{this.state.flagname}</span>
                      <span className="dragwindowclose" onClick={this.toclosedragwindow.bind(this)}><img src={require('../../assets/两票icon/guanbi.png')} alt="" /></span>
                    </h2>
                    <div className="toseletype">
                      <table>
                        <tbody>
                          <tr>
                            <td>选择票类型：</td>
                            <td>
                              <Select showSearch value={this.state.newworktickettype}
                                onChange={(v) => { this.setState({ newworktickettype: v }) }}
                              >
                                <Option value={1} key={1}>工作票</Option>
                                <Option value={2} key={2}>操作票</Option>
                                <Option value={3} key={3}>其它票</Option>
                              </Select>
                            </td>
                          </tr>
                          <tr>
                            <td>选择流程：</td>
                            <td>
                              <Select showSearch value={this.state.newworkticketflow}
                                onChange={(v) => { this.setState({ newworkticketflow: v }) }}
                              >
                                {
                                  this.state.workticketflowarr.length > 0 ?
                                    this.state.workticketflowarr.map((item, index) => {
                                      return (<Option value={item.FlowID} key={item.FlowID}>{item.TicketName}</Option>)
                                    }) : ""
                                }
                              </Select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="seletosave">
                      <Button onClick={this.tosureselesave.bind(this)}>确定</Button>
                      <Button onClick={this.toclosedragwindow.bind(this)}>取消</Button>
                    </div>
                  </div>
                  :
                  this.state.flagname == "选择设备" ?
                    <div className="toseleequip">
                      <h2 className="h2title">
                        <span>选择设备</span>
                        <span className="dragwindowclose" onClick={this.toclosedragwindow.bind(this)}><img src={require('../../assets/两票icon/guanbi.png')} alt="" /></span>
                      </h2>
                      <div className="toseleequipcon">
                        <div className="toseleequipconl">
                          <h2 className="h2title">设备KKS树</h2>
                          <div className="toseleequipconlsearch">
                            <Search placeholder="kks编码" onSearch={(value) => { this.setState({ searchValue: value }) }} />
                            {/* <Button onClick={this.toaddselekks.bind(this)}>添加</Button> */}
                          </div>
                          <div className="kkstree">
                            <Tree
                              onExpand={this.onExpand.bind(this)}
                              expandedKeys={expandedKeys}
                              autoExpandParent={autoExpandParent}
                              onSelect={this.onSelect.bind(this)}
                              selectedKeys={this.state.selectedKeys}
                            >
                            {loop(this.state.KKSdata)}
                            </Tree>
                          </div>
                        </div>
                        <div className="toseleequipconr">
                          <h2 className="h2title">设备列表</h2>
                          <div className="action">
                            {/* <Button onClick={this.todelselekks.bind(this)}>删除</Button> */}
                            <Button onClick={this.tosaveselekks.bind(this)}>保存</Button>
                            <Button onClick={this.tocancelselekks.bind(this)}>取消</Button>
                          </div>
                          <div className="listscroll">
                            <Table
                              size="small"
                              dataSource={this.state.equipdata}
                              columns={this.equipcolumns}
                              scroll={{ y: 300 }}
                              pagination={false}
                              rowSelection={rowSelection}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    : this.state.flagname && this.state.flagname.indexOf("安全措施") > -1 ?
                      <div className="runningmessure">
                        <h2 className="h2title">
                          <span>{this.state.flagname}</span>
                          <span className="dragwindowclose" onClick={this.toclosedragwindow.bind(this)}><img src={require('../../assets/两票icon/guanbi.png')} alt="" /></span>
                        </h2>
                        <div className="runningmessurecon">
                          安全措施：<TextArea rows={6} value={this.state.runningmessure} onChange={(e) => { this.setState({ runningmessure: e.target.value }) }} />
                        </div>
                        <div className="seletosave">
                          <Button onClick={this.tosuremessuresave.bind(this)}>确定</Button>
                          <Button onClick={this.toclosedragwindow.bind(this)}>取消</Button>
                        </div>
                      </div>

                      : ""
          }
        </div>
        {

          this.state.flagname == "试运编辑" ?
            <div className="nextdealier">
              <h2 className="h2title">
                <span>{this.state.flagname}</span>
                <span className="dragwindowclose" onClick={this.toclosedragwindow.bind(this)}><img src={require('../../assets/两票icon/guanbi.png')} alt="" /></span>
              </h2>
              <div className="nextdealiercon">
                <Form onSubmit={this.handleSubmit.bind(this, '试运编辑')} layout="vertical">
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="允许试运时间:">
                        {getFieldDecorator('TrialOperationTime', {
                          initialValue: this.state.iptobj["TrialOperationTime"] && this.state.iptobj["TrialOperationTime"].indexOf("1900-01-01") == -1 ? moment(this.state.iptobj["TrialOperationTime"]) : null,
                          rules: [{ required: true, message: '该字段不能为空' }],
                        })(
                          <DatePicker showTime placeholder="选择时间" />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="seletosave">
                <Button onClick={this.handleSubmit.bind(this, '试运编辑')}>确定</Button>
                <Button onClick={this.tocancelselenextdealier.bind(this)}>取消</Button>
              </div>
            </div>
            : ""
        }
        {

          this.state.flagname == "终结编辑" ?
            <div className="nextdealier">
              <h2 className="h2title">
                <span>{this.state.flagname}</span>
                <span className="dragwindowclose" onClick={this.toclosedragwindow.bind(this)}><img src={require('../../assets/两票icon/guanbi.png')} alt="" /></span>
              </h2>
              <div className="nextdealiercon">
                <Form onSubmit={this.handleSubmit.bind(this, '终结编辑')} layout="vertical">
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="允许恢复时间:">
                        {getFieldDecorator('CompleteOperationTime', {
                          initialValue: this.state.iptobj["CompleteOperationTime"] && this.state.iptobj["CompleteOperationTime"].indexOf("1900-01-01") == -1 ? moment(this.state.iptobj["CompleteOperationTime"]) : null,
                          rules: [{ required: true, message: '该字段不能为空' }],
                        })(
                          <DatePicker showTime placeholder="选择时间" />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="seletosave">
                <Button onClick={this.handleSubmit.bind(this, '终结编辑')}>确定</Button>
                <Button onClick={this.tocancelselenextdealier.bind(this)}>取消</Button>
              </div>
            </div>
            : ""
        }
        {
          this.state.flagname == "选择下一个处理人" ?
            <div className="nextdealier">
              <h2 className="h2title">
                <span>{this.state.flagname}</span>
                <span className="dragwindowclose" onClick={this.toclosedragwindow.bind(this)}><img src={require('../../assets/两票icon/guanbi.png')} alt="" /></span>
              </h2>
              <div className="nextdealiercon">
                <Form onSubmit={this.handleSubmit.bind(this, '选择下一个处理人')} layout="vertical">
                  <Row gutter={16}>
                    <Col span={36}>
                      <Form.Item label="下一个处理人:">
                        {getFieldDecorator('NextDutyPerson', {
                          rules: [{ required: true, message: '该字段不能为空' }],
                        })(
                          <Select showSearch>
                            {
                              this.state.NextUserarr.length > 0 && this.state.NextUserarr.map((item, index) => {
                                return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                              })
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="seletosave">
                <Button onClick={this.handleSubmit.bind(this, '选择下一个处理人')}>确定</Button>
                <Button onClick={this.tocancelselenextdealier.bind(this)}>取消</Button>
              </div>
            </div>
            : ""
        }


        {/* 票编辑 */}
        <div className="editworkticket" ref="editworkticket" style={{ height: clientHeight - 60 + 'px', overflow: "auto" }}>
          {/* 热力机械工作票 */}
          <div className="workticketsedit">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('1') > -1 ? "#33a8cc" : "#fff" }}>热力机械工作票</span>
              <span>
                <div
                  style={{ display: this.state.limitticketnum == 1 && this.state.Control.indexOf('热力机械') > -1 ? "block" : "none" }}
                  className="workticketaction">
                  <Button onClick={this.handleSubmit.bind(this, '热力机械工作票')}>保存</Button>
                </div>
                <span onClick={this.toShowOrHide.bind(this, '热力机械工作票', 0)}>{
                  confun("热力机械工作票")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "热力机械工作票" ?
                <div className="workticketseditcon" >
                  <Form onSubmit={this.handleSubmit.bind(this, '热力机械工作票')} layout="vertical">
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作票编号:">
                          {getFieldDecorator('TicketNum', {
                            initialValue: this.state.rowIdAobj["TicketNum"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Input placeholder="系统自动生成" readOnly disabled />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={36}>
                        <Form.Item label="工作负责人:">
                          {getFieldDecorator('CreatePeople', {
                            initialValue: this.state.rowIdAobj["CreatePeople"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch readOnly disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={36}>
                        <Form.Item label="工作地点:">
                          {getFieldDecorator('WorkRegion', {
                            initialValue: this.state.ticketiptobj["WorkRegion"],
                            rules: [{ required: true, message: '该字段不能为空' }],
                          })(
                            <Select showSearch disabled={this.state.limitticketnum == 1 && this.state.Control.indexOf('热力机械') > -1 ? false : true}>
                              {
                                this.state.Areatreearr.length > 0 && this.state.Areatreearr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作班组:">
                          {getFieldDecorator('Department', {
                            initialValue: this.state.dangzhiban + '班',
                            // rules: [{ required: true, message: '该字段不能为空' }],
                          })(
                            <Input disabled />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="工作班人员:">
                          {getFieldDecorator('TeamPeoples', {
                            initialValue: this.state.ticketiptobj['TeamPeoples'] ?
                              this.state.ticketiptobj['TeamPeoples'].indexOf(',') > -1 ?
                                this.state.ticketiptobj['TeamPeoples'].split(',') :
                                [this.state.ticketiptobj['TeamPeoples']]
                              : [],
                            rules: [{ required: true, message: '该字段不能为空' }],
                          })(
                            <Select showSearch mode="multiple" disabled={this.state.limitticketnum == 1 && this.state.Control.indexOf('热力机械') > -1 ? false : true}>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="设备名称:">
                          {getFieldDecorator('DeviceName', {
                            initialValue: this.state.selectedRowssele,
                            rules: [{ required: true, message: '该字段不能为空' }],
                          })(
                            <Select showSearch mode="multiple" disabled={this.state.limitticketnum == 1 && this.state.Control.indexOf('热力机械') > -1 ? false : true}>
                              {
                                this.state.selectedRowsfu.length > 0 && this.state.selectedRowsfu.map((item, index) => {
                                  return (<Option value={item.Name} key={item.KKS}>{item.Name}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      {
                        this.state.limitticketnum == 1 && this.state.Control.indexOf('热力机械') > -1 ?
                          <span className="selekksimg" onClick={this.toSeleequipment.bind(this)}><img src={require('../../assets/两票icon/xiangxi.png')} /></span>
                          : ""
                      }
                    </Row>
                    <Row gutter={16} className="textarea">
                      <Col span={12}>
                        <Form.Item label="工作内容:">
                          {getFieldDecorator('JobContent', {
                            initialValue: this.state.ticketiptobj["JobContent"],
                            rules: [{ required: true, message: '该字段不能为空' }],
                          })(
                            <TextArea rows={2} disabled={this.state.limitticketnum == 1 && this.state.Control.indexOf('热力机械') > -1 ? false : true} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item label="计划工作时间:">
                          {getFieldDecorator('StartEndTime', {
                            initialValue: [this.state.ticketiptobj["StartTime"] ? moment(this.state.ticketiptobj["StartTime"]) : null
                              , this.state.ticketiptobj["EndTime"] ? moment(this.state.ticketiptobj["EndTime"]) : null],
                            rules: [{ required: true, message: '该字段不能为空' }],
                          })(
                            <RangePicker
                              showTime={{ format: 'HH:mm:ss' }}
                              format="YYYY-MM-DD HH:mm:ss"
                              placeholder={['开始时间', '结束时间']}
                              disabled={this.state.limitticketnum == 1 && this.state.Control.indexOf('热力机械') > -1 ? false : true}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
                : ""
            }
          </div>
          {/* 运行必须采取的安全措施 style={{ height: clientHeight - 50 - 40 - 35 * 8 - 10 + 'px' }}*/}
          <div className="runningmessures">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('2') > -1 ? "#33a8cc" : "#fff" }}>运行必须采取的安全措施</span>
              <span>
                <div
                  style={{ display: this.state.limitticketnum == 5 && this.state.Control.indexOf('必须采取') > -1 ? "block" : "none" }}
                  className="workticketaction">
                  <Button onClick={this.tosavetwoticketedit.bind(this, '必须采取')}>保存</Button>
                </div>
                <span
                  onClick={this.toShowOrHide.bind(this, '运行必须采取的安全措施', 1)}>{confun("运行必须采取的安全措施")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "运行必须采取的安全措施" ?
                <div className="runningmustmessures" >
                  {
                    this.state.limitticketnum == 1 ?
                      <div className="action">
                        <Button onClick={this.handleAdd.bind(this, '运行必须采取的安全措施添加')}>添加</Button>
                        <Button onClick={this.handleEdit.bind(this, '运行必须采取的安全措施编辑')}>编辑</Button>
                        <Button onClick={this.handleDel.bind(this, '运行必须采取的安全措施', {})}>删除</Button>
                        <Button onClick={this.toUpshift.bind(this, '运行必须采取的安全措施')}>上移</Button>
                        <Button onClick={this.toDownshift.bind(this, '运行必须采取的安全措施')}>下移</Button>
                      </div> :
                      <div className="action">
                        <Button className="disabledbutton">添加</Button>
                        <Button className="disabledbutton">编辑</Button>
                        <Button className="disabledbutton">删除</Button>
                        <Button className="disabledbutton">上移</Button>
                        <Button className="disabledbutton">下移</Button>
                      </div>
                  }
                  <div className="listscroll">
                    <Table
                      size="small"
                      dataSource={this.state.runningmessuredata1}
                      columns={this.runningmessurecolumns1}
                      scroll={{ y: 300 }}
                      pagination={false}
                      onRow={(record) => {
                        return {
                          onClick: this.senddata1.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassName1}
                    />
                  </div>
                  <div className="footer">
                    <table>
                      <tbody>
                        <tr>
                          <td>签票人</td>
                          <td>{this.state.rowIdAobj.Issuer ? this.state.rowIdAobj.Issuer : ""}</td>
                          <td>时间</td>
                          <td>{this.state.rowIdAobj.IssueTime ? this.state.rowIdAobj.IssueTime : ""}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> : ""
            }
          </div>
          {/* 运行人员补充的安全措施 */}
          <div className="runningpermessures">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('3') > -1 ? "#33a8cc" : "#fff" }}>运行人员补充的安全措施</span>
              <span>
                <div className="workticketaction"
                  style={{ display: this.state.limitticketnum == 5 && this.state.Control.indexOf('人员补充') > -1 ? "block" : "none" }} >
                  <Button onClick={this.tosavetwoticketedit.bind(this, '人员补充')}>保存</Button>
                </div>
                <span
                  onClick={this.toShowOrHide.bind(this, '运行人员补充的安全措施', 2)}>{confun("运行人员补充的安全措施")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "运行人员补充的安全措施" ?
                <div className="runningpermessures" >
                  {
                    this.state.limitticketnum == 3 ?
                      <div className="action">
                        <Button onClick={this.handleAdd.bind(this, '运行人员补充的安全措施添加')}>添加</Button>
                        <Button onClick={this.handleEdit.bind(this, '运行人员补充的安全措施编辑')}>编辑</Button>
                        <Button onClick={this.handleDel.bind(this, '运行人员补充的安全措施', {})}>删除</Button>
                        <Button onClick={this.toUpshift.bind(this, '运行人员补充的安全措施')}>上移</Button>
                        <Button onClick={this.toDownshift.bind(this, '运行人员补充的安全措施')}>下移</Button>
                      </div> :
                      <div className="action">
                        <Button className="disabledbutton">添加</Button>
                        <Button className="disabledbutton">编辑</Button>
                        <Button className="disabledbutton">删除</Button>
                        <Button className="disabledbutton">上移</Button>
                        <Button className="disabledbutton">下移</Button>
                      </div>
                  }
                  <div className="listscroll">
                    <Table
                      size="small"
                      dataSource={this.state.runningmessuredata2}
                      columns={this.runningmessurecolumns2}
                      scroll={{ y: 300 }}
                      pagination={false}

                      onRow={(record) => {
                        return {
                          onClick: this.senddata2.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassName2}
                    />
                  </div>
                  <div className="footer">
                    <table>
                      <tbody>
                        <tr>
                          <td>接票人</td>
                          <td>{this.state.rowIdAobj.TicketHolder ? this.state.rowIdAobj.TicketHolder : ""}</td>
                          <td>时间</td>
                          <td>{this.state.rowIdAobj.TicketHolderTime ? this.state.rowIdAobj.TicketHolderTime : ""}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> : ""
            }
          </div>
          {/* 审批 */}
          <div className="workticketaudit">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('4') > -1 ? "#33a8cc" : "#fff" }}>审批</span>
              <span>
                <div className="workticketaction"
                  style={{ display: (this.state.limitticketnum == 4 || this.state.limitticketnum == 6) && this.state.Control.indexOf('审批') > -1 ? "block" : "none" }}  >
                  <Button onClick={this.handleSubmit.bind(this, '审批')}>保存</Button>
                </div>
                <span
                  onClick={this.toShowOrHide.bind(this, '审批', 3)}>{confun("审批")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "审批" ?
                <div className="workticketauditcon" >
                  <Form onSubmit={this.handleSubmit.bind(this, '审批')} layout="vertical">
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item label="批准工作时间:">
                          {getFieldDecorator('ApprovedSETime', {
                            initialValue: [this.state.auditdataobj["ApprovedStartTime"] ? moment(this.state.auditdataobj["ApprovedStartTime"]) : null
                              , this.state.auditdataobj["ApprovedEndTime"] ? moment(this.state.auditdataobj["ApprovedEndTime"]) : null],
                            rules: [{ required: this.state.limitticketnum == 4 && this.state.Control.indexOf('审批') > -1 ? true : false, message: '该字段不能为空' }],
                          })(
                            <RangePicker
                              showTime={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                              format="YYYY-MM-DD HH:mm:ss"
                              placeholder={['开始时间', '结束时间']}
                              disabled={this.state.limitticketnum == 4 && this.state.Control.indexOf('审批') > -1 ? false : true}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="值长:">
                          {getFieldDecorator('ShiftSupervisor', {
                            initialValue: this.state.auditdataobj["ShiftSupervisor"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch
                              disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <span style={{ display: this.state.auditdataobj.ApprovedTime && this.state.auditdataobj.ApprovedTime.indexOf('1900-01-01') == -1 ? "block" : "none" }} className="readsystemtime">审批时间: <span>{this.state.auditdataobj.ApprovedTime}</span></span>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="许可开工时间:">
                          {getFieldDecorator('PermittedWorkTime', {
                            initialValue: this.state.auditdataobj["PermittedWorkTime"] && this.state.auditdataobj["PermittedWorkTime"].indexOf('1900-01-01') == -1 ? moment(this.state.auditdataobj["PermittedWorkTime"]) : null,
                            rules: [{ required: this.state.limitticketnum == 6 && this.state.Control.indexOf('审批') > -1 ? true : false, message: '该字段不能为空' }],
                          })(
                            <DatePicker
                              format="YYYY-MM-DD HH:mm:ss"
                              showTime
                              disabled={this.state.limitticketnum == 6 && this.state.Control.indexOf('审批') > -1 ? false : true} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作票许可人:">
                          {getFieldDecorator('Licensor', {
                            initialValue: this.state.auditdataobj["Licensor"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作票负责人:">
                          {getFieldDecorator('DutyPeople', {
                            initialValue: this.state.auditdataobj["DutyPeople"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
                : ""
            }
          </div>
          {/* 变更 */}
          <div className="workticketchange">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('5') > -1 ? "#33a8cc" : "#fff" }}>变更</span>
              <span>
                <div className="workticketaction"
                  style={{ display: this.state.limitticketnum == 7 && this.state.Control.indexOf('变更') > -1 ? "block" : "none" }}
                >
                  <Button onClick={this.handleSubmit.bind(this, '变更')}>保存</Button>
                </div>
                <span
                  onClick={this.toShowOrHide.bind(this, '变更', 4)}>{confun("变更")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "变更" ?
                <div className="workticketchangecon" >
                  <Form onSubmit={this.handleSubmit.bind(this, '变更')} layout="vertical">
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="变更负责人:">
                          {getFieldDecorator('DutyPeople', {
                            initialValue: this.state.changedataobj["DutyPeople"],
                            rules: [{ required: true, message: '该字段不能为空' }],
                          })(
                            <Select showSearch
                              disabled={this.state.limitticketnum == 7 && this.state.Control.indexOf('变更') > -1 ? false : true}
                            >
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作票签发人:">
                          {getFieldDecorator('Issuer', {
                            initialValue: this.state.changedataobj["Issuer"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <span style={{ display: this.state.changedataobj.IssueTime && this.state.changedataobj.IssueTime.indexOf('1900-01-01') == -1 ? "block" : "none" }} className="readsystemtime">审批时间:
                      <span>{this.state.changedataobj.IssueTime ? this.state.changedataobj.IssueTime : ""}</span></span>
                    </Row>

                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作票许可人:">
                          {getFieldDecorator('Licensor', {
                            initialValue: this.state.changedataobj["Licensor"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <span style={{ display: this.state.changedataobj.LicensorTime && this.state.changedataobj.LicensorTime.indexOf('1900-01-01') == -1 ? "block" : "none" }} className="readsystemtime">审批时间: <span>{this.state.changedataobj.LicensorTime ? this.state.changedataobj.LicensorTime : ""}</span></span>
                    </Row>
                  </Form>
                </div> : ""
            }
          </div>
          {/* 延期 */}
          <div className="workticketchange">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('6') > -1 ? "#33a8cc" : "#fff" }}>延期</span>
              <span>
                <div className="workticketaction"
                  style={{ display: this.state.limitticketnum == 7 && this.state.Control.indexOf('延期') > -1 ? "block" : "none" }}
                >
                  <Button onClick={this.handleSubmit.bind(this, '延期')}>保存</Button>
                </div>
                <span
                  onClick={this.toShowOrHide.bind(this, '延期', 5)}>{confun("延期")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "延期" ?
                <div className="workticketchangecon" >
                  <Form onSubmit={this.handleSubmit.bind(this, '延期')} layout="vertical">
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作票延期:">
                          {getFieldDecorator('EndTime', {
                            initialValue: this.state.delaydataobj["EndTime"] ? moment(this.state.delaydataobj["EndTime"]) : null,
                            rules: [{ required: true, message: '该字段不能为空' }],
                          })(
                            // <span className="readsystemtime">有效期延长至: <span>2019/11/17</span></span>
                            <DatePicker showTime placeholder=""
                              disabled={this.state.limitticketnum == 7 && this.state.Control.indexOf('延期') > -1 ? false : true}
                              format="YYYY-MM-DD HH:mm:ss"
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作负责人:">
                          {getFieldDecorator('DutyPeople', {
                            initialValue: this.state.delaydataobj["DutyPeople"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作许可人:">
                          {getFieldDecorator('Licensor', {
                            initialValue: this.state.delaydataobj["Licensor"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <span style={{ display: this.state.delaydataobj.LicensorTime && this.state.delaydataobj.LicensorTime.indexOf('1900-01-01') == -1 ? "block" : "none" }}
                        className="readsystemtime">审批时间: <span>{this.state.delaydataobj.LicensorTime ? this.state.delaydataobj.LicensorTime : ""}</span></span>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="值长:">
                          {getFieldDecorator('ShiftSupervisor', {
                            initialValue: this.state.delaydataobj["ShiftSupervisor"],
                            rules: [{ required: false, message: '请选择值长' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <span style={{ display: this.state.delaydataobj.ShiftTime && this.state.delaydataobj.ShiftTime.indexOf('1900-01-01') == -1 ? "block" : "none" }}
                        className="readsystemtime">审批时间: <span>{this.state.delaydataobj.ShiftTime ? this.state.delaydataobj.ShiftTime : ""}</span></span>
                    </Row>
                  </Form>
                </div> : ""
            }
          </div>
          {/* 试运 */}
          <div className="trywork">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('7') > -1 ? "#33a8cc" : "#fff" }}>试运</span>
              <span>
                {/* <div className="workticketaction"
                style={{display:this.state.limitticketnum==7 && this.state.Control.indexOf('试运')>-1?"block":"none"}} 
                >
                  <Button onClick={this.tosavetwoticketedit.bind(this)}>保存</Button>
                </div> */}
                <span
                  onClick={this.toShowOrHide.bind(this, '试运', 6)}>{confun("试运")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "试运" ?
                <div className="tryworkcon" >
                  <div className="action">
                    检修设备需试运(工作票交回,所列安全措施已拆出,可以试运)：
                  </div>
                  <div className="action"
                    style={{ display: this.state.limitticketnum == 7 && this.state.Control.indexOf('试运') > -1 ? "block" : "none" }}
                  >
                    <Button onClick={this.handleSubmit.bind(this, '试运添加')}>添加</Button>
                    {/* <Button onClick={this.handleEdit.bind(this, '试运编辑')}>编辑</Button> */}
                    <Button onClick={this.handleDel.bind(this, '试运', {})}>删除</Button>
                  </div>
                  <div className="action"
                    style={{ display: this.state.limitticketnum == 8 && this.state.Control.indexOf('流转') > -1 ? "block" : "none" }}
                  >
                    <Button onClick={this.handleEdit.bind(this, '试运编辑')}>编辑</Button>
                  </div>
                  <div className="listscroll">
                    <Table
                      size="small"
                      dataSource={this.state.tryrundataarr}
                      columns={this.tryworkcolumns}
                      scroll={{ y: 300 }}
                      pagination={false}
                      onRow={(record) => {
                        return {
                          onClick: this.senddata3.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassName3}
                    />
                  </div>
                </div> : ""
            }
          </div>
          {/* 终结 */}
          <div className="workending">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('8') > -1 ? "#33a8cc" : "#fff" }}>终结</span>
              <span>
                {/* <div className="workticketaction"
                style={{display:this.state.limitticket[limitticketnum].indexOf('结束')>-1?"block":"none"}} >
                  <Button onClick={this.tosavetwoticketedit.bind(this)}>保存</Button>
                </div> */}
                <span
                  onClick={this.toShowOrHide.bind(this, '终结', 7)}>{confun("终结")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "终结" ?
                <div className="workendingcon" >
                  <div className="action">
                    检修设备需试运后，工作票所列安全措施已全部执行，可以重新工作：
                  </div>
                  <div className="action"
                    style={{ display: this.state.limitticketnum == 10 && this.state.Control.indexOf('终结') > -1 ? "block" : "none" }}
                  >
                    <Button onClick={this.handleSubmit.bind(this, '终结添加')}>添加</Button>
                    <Button onClick={this.handleDel.bind(this, '终结', {})}>删除</Button>
                  </div>
                  <div className="action"
                    style={{ display: this.state.limitticketnum == 11 && this.state.Control.indexOf('终结') > -1 ? "block" : "none" }}
                  >
                    <Button onClick={this.handleEdit.bind(this, '终结编辑')}>编辑</Button>
                  </div>
                  <div className="listscroll">
                    <Table
                      size="small"
                      dataSource={this.state.endthingdataarr}
                      columns={this.endthingcolumns}
                      scroll={{ y: 300 }}
                      pagination={false}
                      onRow={(record) => {
                        return {
                          onClick: this.senddata4.bind(this, record)
                        }
                      }}
                      rowClassName={this.setRowClassName4}
                    />
                  </div>
                </div> : ""
            }
          </div>
          {/* 总结 */}
          <div className="summary">
            <h3 className="h3title">
              <span style={{ color: this.state.ControlColor.indexOf('9') > -1 ? "#33a8cc" : "#fff" }}>总结</span>
              <span>
                <div className="workticketaction"
                  style={{ display: this.state.limitticketnum == 11 && this.state.Control.indexOf('总结') > -1 ? "block" : "none" }}
                >
                  <Button onClick={this.handleSubmit.bind(this, '总结')}>保存</Button>
                </div>
                <span
                  onClick={this.toShowOrHide.bind(this, '总结', 8)}>{confun("总结")}</span>
              </span>
            </h3>
            {
              this.state.FlagNameEdit == "总结" ?
                <div className="workendingcon" >
                  <div className="workendingconfooter">
                    <table>
                      <tbody>
                        <tr>
                          <td>工作总结：</td>
                          <td style={{ paddingLeft: 10 }}>工作人员已全部撤离，现场已清理完毕。</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <Form onSubmit={this.handleSubmit.bind(this, '总结')} layout="vertical">
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="结束时间:">
                          {getFieldDecorator('FinalTime', {
                            initialValue: this.state.summarydataobj["FinalTime"] ? moment(this.state.summarydataobj["FinalTime"]) : null,
                            rules: [{ required: this.state.limitticketnum == 11 && this.state.Control.indexOf('总结') > -1 ? true : false, message: '该字段不能为空' }],
                          })(
                            <DatePicker showTime
                              format="YYYY-MM-DD HH:mm:ss"
                              disabled={this.state.limitticketnum == 11 && this.state.Control.indexOf('总结') > -1 ? false : true} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作负责人:">
                          {getFieldDecorator('DutyPeople', {
                            initialValue: this.state.summarydataobj["DutyPeople"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <span style={{ display: this.state.summarydataobj.DutyPeopleTime && this.state.summarydataobj.DutyPeopleTime.indexOf('1900-01-01') == -1 ? "block" : "none" }}
                        className="readsystemtime">批准时间: <span>{this.state.summarydataobj.DutyPeopleTime ? this.state.summarydataobj.DutyPeopleTime : ""}</span></span>
                    </Row>
                    <Row gutter={16}>
                      <Col span={36}>
                        <Form.Item label="工作许可人:">
                          {getFieldDecorator('Licensor', {
                            initialValue: this.state.summarydataobj["Licensor"],
                            rules: [{ required: false, message: '该字段不能为空' }],
                          })(
                            <Select showSearch className="selectdelstyle" disabled>
                              {
                                this.state.Userarr.length > 0 && this.state.Userarr.map((item, index) => {
                                  return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <span style={{ display: this.state.summarydataobj.LicensorTime && this.state.summarydataobj.LicensorTime.indexOf('1900-01-01') == -1 ? "block" : "none" }}
                        className="readsystemtime">批准时间: <span>{this.state.summarydataobj.LicensorTime ? this.state.summarydataobj.LicensorTime : ""}</span></span>
                    </Row>
                  </Form>

                </div> : ""
            }
          </div>
          <div className="fixedtools">
            <span className="fixedtool" onClick={this.tocanceltwoticketedit1.bind(this)}>
              <img src={require('../../assets/两票icon/back.png')} alt="" /><span>返回</span>
            </span>

            <span className="fixedtool" onClick={this.toReturnBack.bind(this)}
              style={{ display: this.state.Control.indexOf('驳回') > -1 ? "flex" : "none" }}
            >
              <img src={require('../../assets/两票icon/xiangzuo.png')} alt="" /><span>驳回</span>
            </span>
            <span className="fixedtool" onClick={this.toTurnNext.bind(this)}
              style={{ display: this.state.Control.indexOf('流转') > -1 ? "flex" : "none" }}
            ><span>流转</span>
              <img src={require('../../assets/两票icon/xiangyou.png')} alt="" />
            </span>
            <span className="fixedtool" onClick={this.toDisablethis.bind(this)}
              style={{ display: this.state.Control.indexOf('作废') > -1 ? "flex" : "none" }}
            >
              <img src={require('../../assets/两票icon/shanchu.png')} alt="" /><span>作废</span>
            </span>
          </div>
        </div>

        {/* 历史票 */}
        <div className="historytickets" ref="historytickets">
          <h2 className="h2title">历史票列表</h2>
          <div className="action">
            <div className="conditions">
              <span className="conditon1">
                <Select showSearch
                  onChange={(v) => {
                    this.setState({
                      wconditon1: v
                    })
                  }} value={this.state.wconditon1}>
                  {
                    this.state.columns.map((itm, inde) => {
                      if (inde == 0 || inde == this.state.columns.length - 1) {

                      } else {
                        return (<Option value={itm.dataIndex} key={itm.dataIndex}>{itm.title}</Option>)
                      }
                    })
                  }
                </Select>
              </span>
              <span className="conditon2">
                <Select
                  onChange={(v) => {
                    this.setState({
                      wconditon2: v
                    })
                  }} value={this.state.wconditon2}>
                  {
                    this.state.searchcon.map((item, index) => {
                      return (<Option value={item} key={item}>{item}</Option>)
                    })
                  }
                </Select>
              </span>
              <span className="conditon3">
                <Input value={this.state.wconditon3} onChange={(e) => { this.setState({ wconditon3: e.target.value }) }} />
              </span>
              <span className="conditon4">
                <Select
                  onChange={(v) => {
                    console.log(v)
                    this.setState({
                      wconditon4: v
                    })
                  }}
                  value={this.state.wconditon4}>
                  <Option value="或" key="或">或</Option>
                  <Option value="并" key="并">并</Option>
                </Select>
              </span>
            </div>
            <div className="conditionsearch">
              <span className="moreconditions" onClick={this.toshowMore.bind(this)}>
                <img src={require('../../assets/两票icon/more.png')} alt="" />
              </span>
              <Button onClick={this.handleSearch.bind(this, '历史票')}>搜索</Button>
              <Button onClick={this.handleImport.bind(this)}>导入</Button>
              <Button onClick={this.handleCancelImport.bind(this)}>取消</Button>
            </div>
          </div>
          <div className="showhide" ref="showhideele">
            <SearchCondition ref="mySearchCondition" datacondition={defcolumns} />
          </div>
          <div className="listpage" onClick={this.toclosesearchcon.bind(this)} style={{ height: clientHeight - 50 - 80 }}>
            <Table
              size="small"
              dataSource={this.state.workticketeddata}
              columns={this.historycolumns}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndex1,
              }}
              onChange={this.pagechange.bind(this, '历史票')}
              onRow={(record) => {
                return {
                  onClick: this.senddata.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassName}
            />
          </div>

        </div>

        <div className="WisdomTwoticketcon" ref="WisdomTwoticketcon">
          {/* <h2 className="h2title">两票列表</h2> */}
          <div className="action">
            <div className="conditions">
              <span className="conditon1">
                <Select showSearch
                  onChange={(v) => {
                    this.setState({
                      wconditon1: v
                    })
                  }} value={this.state.wconditon1}>
                  {
                    this.state.columns.map((itm, inde) => {
                      if (inde == 0 || inde == this.state.columns.length - 1) {

                      } else {
                        return (<Option value={itm.dataIndex} key={itm.dataIndex}>{itm.title}</Option>)
                      }
                    })
                  }
                </Select>
              </span>
              <span className="conditon2">
                <Select
                  onChange={(v) => {
                    this.setState({
                      wconditon2: v
                    })
                  }} value={this.state.wconditon2}>
                  {
                    this.state.searchcon.map((item, index) => {
                      return (<Option value={item} key={item}>{item}</Option>)
                    })
                  }
                </Select>
              </span>
              <span className="conditon3">
                <Input value={this.state.wconditon3} onChange={(e) => { this.setState({ wconditon3: e.target.value }) }} />
              </span>
              <span className="conditon4">
                <Select
                  onChange={(v) => {
                    console.log(v)
                    this.setState({
                      wconditon4: v
                    })
                  }}
                  value={this.state.wconditon4}>
                  <Option value="或" key="或">或</Option>
                  <Option value="并" key="并">并</Option>
                </Select>
              </span>
            </div>
            <div className="conditionsearch">
              <span className="moreconditions" onClick={this.toshowMore.bind(this)}>
                <img src={require('../../assets/两票icon/more.png')} alt="" />
              </span>
              <Button onClick={this.handleSearch.bind(this, '两票')}>搜索</Button>
              <Button style={{ display: this.state.buttonarr.indexOf('新建') > -1 ? "inline-block" : "none" }} onClick={this.handleAdd.bind(this, '新建票')}>新建</Button>
            </div>
          </div>
          <div className="showhide" ref="showhideele">
            <SearchCondition ref="mySearchCondition" datacondition={defcolumns} />
          </div>
          <div className="listpage" onClick={this.toclosesearchcon.bind(this)} style={{ height: clientHeight - 50 - 40 }}>
            <Table
              size="small"
              dataSource={this.state.workticketdata}
              columns={this.state.columns}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndex,
              }}
              onChange={this.pagechange.bind(this, '工作票')}
            />
          </div>
        </div>
      </section>
    )
  }
}
const WisdomTwoticketapp = Form.create()(WisdomTwoticket);
export default WisdomTwoticketapp;