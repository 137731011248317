import React from 'react';
import { Input, Select, Button, } from 'antd';
import $ from 'jquery'
const { Option } = Select;

class SearchCondition extends React.Component {
  constructor(props) {
    super(props);
    var arr = this.props.datacondition ?this.props.datacondition:[]
    this.state = {
      variabledata: this.props.variabledata,
      searchcon: ['=', '!=', '<', '<=', '>', '>=', '包含', '不包含', '从属于'],
      datacondition: arr,
      allcondition: [{ conditon1: '', conditon2: '', conditon3: '', conditon4:'并'}]
    }
  }
  componentDidMount() {

  }

  conditon1change(key, value) {
    var arr = this.state.allcondition
    arr[key].conditon1 = value
    this.setState({
      allcondition: arr
    })
  }
  conditon2change(key, value) {
    console.log(key, value)
    var arr = this.state.allcondition
    arr[key].conditon2 = value
    this.setState({
      allcondition: arr
    })
  }
  conditon3change(key, e) {
    var arr = this.state.allcondition
    arr[key].conditon3 = e.target.value
    this.setState({
      allcondition: arr
    })
  }
  conditon4change(key, value) {
    var arr = this.state.allcondition
    arr[key].conditon4 = value
    this.setState({
      allcondition: arr
    })
  }
  addcondition(key) {
    var arr = this.state.allcondition
    var obj = { conditon1: '', conditon2: '', conditon3: '' , conditon4: '并' }
    arr.splice(key + 1, 0, obj);
    this.setState({
      allcondition: arr
    })
  }
  delcondition(key) {
    var arr = this.state.allcondition
    var newarr = arr.filter(function (element, index) {
      return key != index;
    });
    this.setState({
      allcondition: newarr
    })
  }
  render() {
    return (
      <div className="searchconditionm" style={{ height: "100%" }}>
        <table>
          <tbody>
            <tr>
              <th>字段</th>
              <th>方式</th>
              <th>数值</th>
              <th>操作</th>
              <th></th>
            </tr>
          </tbody>
        </table>


        <span className="searchconditionmcon">
          <table>
            <tbody>
              {
                this.state.allcondition.length > 0 ?
                  this.state.allcondition.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="conditon1">
                            <Select showSearch onChange={this.conditon1change.bind(this, index)} value={item.conditon1}>
                              {
                                this.state.datacondition.map((itm, inde) => {
                                  return (<Option value={itm.dataIndex} key={itm.dataIndex}>{itm.title}</Option>)
                                })
                              }
                            </Select>
                          </span></td>
                        <td>
                          <span className="conditon2">
                            <Select value={item.conditon2} onChange={this.conditon2change.bind(this, index)}>
                              {
                                this.state.searchcon.map((item, index) => {
                                  return (<Option value={item} key={item}>{item}</Option>)
                                })
                              }
                            </Select>
                          </span></td>
                        <td>
                          <span className="conditon3" >
                            <Input value={item.conditon3} onChange={this.conditon3change.bind(this, index)} />
                          </span>
                        </td>
                        <td>
                          {
                            index == 0 ?
                             <Button onClick={this.addcondition.bind(this, index)}>增加</Button>:
                             <Button onClick={this.delcondition.bind(this, index)}>删除</Button>
                              // <span onClick={this.addcondition.bind(this, index)}><img src={require('../assets/两票icon/1.png')} alt="" /></span> :
                              // <span onClick={this.delcondition.bind(this, index)}><img src={require('../assets/两票icon/2.png')} alt="" /></span>
                          }
                        </td>
                        <td>
                          <span className="conditon4">
                            <Select value={item.conditon4} onChange={this.conditon4change.bind(this, index)}>
                              <Option value="或" key="或">或</Option>
                              <Option value="并" key="并">并</Option>
                            </Select>
                          </span>
                        </td>
                      </tr>
                    )
                  }) : ""
              }
            </tbody>
          </table>
        </span>

      </div>
    )
  }
}
export default SearchCondition