import React from 'react'
import { Input, Select, Table, Button, Tabs, InputNumber, Form, Tooltip, Popconfirm, DatePicker, message } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import Draggable from 'react-draggable'
import { onCellf } from '../../comfunc'
import TextArea from 'antd/lib/input/TextArea'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import moment from 'moment'
import store from '../../store';
import "moment/locale/zh-cn"
moment.locale('zh-cn')
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { Search } = Input;

const FormItem = Form.Item;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
            children
          )}
      </td>
    );
  }
}
class WorkListComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      //拖拽
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      dataSourceuser: [],
      UserNumber: '',
      rowIdA: '',
      rowIdAobj: {},
      rowIdB: '',
      rowIdBobj: {},
      dataSourceA: [],
      dataSourceAfu: [],
      dataSourceAlength: 0,

      pageSize: parseInt((store.getState().clientHeight - 50 - 50 - 30 - 50) / 28),
      pageIndexA: 1,
      pageIndexFlow: 1,


      FlowTypeNameA: '',
      FlowTypeIDA: '',
      StartTtimeA: '',
      EndTimeA: '',
   
      visible: false,
      addeditflag: "",
      formiptobj: {},
      detaillistId: '',

      createdata: [],//表单外框
      formconarrobj: {},//表单数据
      Version: '',

      dataSourceFlow: [],//审批记录 流程跟踪

      // createdata: [{ name: '检验单', field: 'dan', type: 'select', require: 'y', value: '0' },
      // { name: '检验日期', field: 'dandate', type: 'date', require: 'y', value: '2019-10-11' },
      // { name: '数量', field: 'num', type: 'number', require: 'y', value: 3 },
      // { name: '检验标准', field: 'standard', type: 'input', require: 'y', value: '标准' },
      // { name: '检验单', field: 'dan', type: 'select', require: 'y', value: '0' },
      // { name: '检验日期', field: 'dandate', type: 'date', require: 'y', value: '2019-10-11' },
      // { name: '数量', field: 'num', type: 'number', require: 'y', value: 3 },
      // { name: '检验标准', field: 'standard', type: 'input', require: 'y', value: '标准' },
      // { name: '检验结论', field: 'answer', type: 'text', require: 'n', value: '恩才能成就大傅就' }]

      dataSourceconA: [],
      dataSourceconB: [],
      countA: 1,
      countB: 1,

      //流程类型初始化
      Processinitarr: []
    }
    this.columnsconA =
      [
        {
          title: '序号',
          // dataIndex: 'key',
          // key: 'key',
          dataIndex: 'MustNumber',
          key: 'MustNumber',
          width: '12%',
          // render: (text, record, index) => {
          //   return (<span>{index + 1}</span>)
          // }
        },
        {
          title: '必须采取的安全措施',
          dataIndex: 'MustMeasure',
          key: 'MustMeasure',
          width: '37%', editable: true,
          onCell: onCellf,
          render: this.renderf
        },
        {
          title: '措施执行情况',
          dataIndex: 'MustDo',
          key: 'MustDo',
          width: '33%', editable: true,
          onCell: onCellf,
          render: this.renderf
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          render: (text, record) =>
            this.state.dataSourceconA.length >= 1 ? (
              <Popconfirm title="确定要删除吗?" okText="是"
                cancelText="否" onConfirm={() => this.handleDelete(record.MustNumber, '常')}>
                <a style={{ color: "#fff" }}>删除</a>
              </Popconfirm>
            ) : null,
        },
      ]
    this.columnsconB = [
      {
        title: '序号',
        // dataIndex: 'key',
        // key: 'key',
        dataIndex: 'SupplementNumber',
        key: 'SupplementNumber',
        width: '10%',
        // render: (text, record, index) => {
        //   return (<span>{index + 1}</span>)
        // }
      },
      {
        title: '运行值班人员补充的安全措施',
        dataIndex: 'SupplementMeasure',
        key: 'SupplementMeasure',
        width: '42%', editable: true,
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '补充措施执行情况',
        dataIndex: 'SupplementDo',
        key: 'SupplementDo',
        width: '30%', editable: true,
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: '18%',
        render: (text, record) =>
          this.state.dataSourceconB.length >= 1 ? (
            <Popconfirm title="确定要删除吗?" okText="是"
              cancelText="否" onConfirm={() => this.handleDelete(record.SupplementNumber, '补')}>
              <a style={{ color: "#fff" }}>删除</a>
            </Popconfirm>
          ) : null,
      },
    ]
    //
    this.columnsA = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '4%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndexA - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '流程类型',
        dataIndex: 'FlowTypeID',
        key: 'FlowTypeID',
        width: '6%'
      },
      {
        title: '流程主题',
        dataIndex: 'FlowInstanceName',
        key: 'FlowInstanceName',
        width: '10%',
        onCell: onCellf,
        render: (text) => {
          var str = text.match(/[^0123456789]/g)
          return (<span>{str.join('')}</span>)
        }
      },
      {
        title: '待审核节点名称',
        dataIndex: 'NodeName',
        key: 'NodeName',
        width: '8%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '待审节点审核人',
        dataIndex: 'NextAuditUsers',
        key: 'NextAuditUsers',
        width: '8%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '上个节点名称',
        dataIndex: 'curNodeName',
        key: 'curNodeName',
        width: '10%',
      },
      {
        title: '上个节点审核人',
        dataIndex: 'TrackAuditUser',
        key: 'TrackAuditUser',
        width: '10%',
      },
      {
        title: '上个节点审核时间',
        dataIndex: 'TrackAuditTime',
        key: 'TrackAuditTime',
        width: '10%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '上个节点审核结果',
        dataIndex: 'AuditResult',
        key: 'AuditResult',
        width: '10%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '上个节点审核理由',
        dataIndex: 'AuditReason',
        key: 'AuditReason',
        width: '10%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '发起时间',
        dataIndex: 'CreateTime',
        key: 'CreateTime',
        width: '10%',
        onCell: onCellf,
        render: this.renderf
      }
    ]


    this.columnsFlowA = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '5%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndexFlow - 1) * 8 + index + 1}</span>)
        }
      },
      {
        title: '节点名称',
        dataIndex: 'NodeName',
        key: 'NodeName',
        width: '14%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '节点审核人',
        dataIndex: 'TrackAuditUser',
        key: 'TrackAuditUser',
        width: '18%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '节点审核结果',
        dataIndex: 'AuditResult',
        key: 'AuditResult',
        width: '10%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '备注',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '16%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '创建时间',
        dataIndex: 'CreateTime',
        key: 'CreateTime',
        width: '18%',
        onCell: onCellf,
        render: this.renderf
      }]
    //记录
    this.columnsFlowB = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '5%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndexFlow - 1) * 8 + index + 1}</span>)
        }
      },
      {
        title: '节点名称',
        dataIndex: 'NodeName',
        key: 'NodeName',
        width: '15%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '审核人',
        dataIndex: 'TrackAuditUser',
        key: 'TrackAuditUser',
        width: '15%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '审核结果',
        dataIndex: 'AuditResult',
        key: 'AuditResult',
        width: '25%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '审核理由',
        dataIndex: 'AuditReason',
        key: 'AuditReason',
        width: '25%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '审核时间',
        dataIndex: 'TrackAuditTime',
        key: 'TrackAuditTime',
        width: '15%',
        onCell: onCellf,
        render: this.renderf
      }]
    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
    }
  }

  handleDelete = (key, flag) => {
    if (flag == "常") {
      const dataSource = [...this.state.dataSourceconA];
      this.setState({ dataSourceconA: dataSource.filter(item => item.MustNumber !== key) });
    } else if (flag == "补") {
      const dataSource = [...this.state.dataSourceconB];
      this.setState({ dataSourceconB: dataSource.filter(item => item.SupplementNumber !== key) });
    }
  };

  handleAdd = (keyflag) => {
    console.log(keyflag)
    if (keyflag == "常") {
      const { countA, dataSourceconA } = this.state;
      const newData = {
        MustNumber: countA,
        MustMeasure: "内容",
        MustDo: "内容",
      };
      this.setState({
        dataSourceconA: [...dataSourceconA, newData],
        countA: countA + 1,
      }, function () {
        console.log(this.state.dataSourceconA)
      });
    } else if (keyflag == "补") {
      const { countB, dataSourceconB } = this.state;
      const newData = {
        SupplementNumber: countB,
        SupplementMeasure: "内容",
        SupplementDo: "内容",
      };
      this.setState({
        dataSourceconB: [...dataSourceconB, newData],
        countB: countB + 1,
      });
    }
  };

  handleSave = (keyflag, row) => {
    if (keyflag == "常") {
      const newData = [...this.state.dataSourceconA];
      const index = newData.findIndex(item => row.MustNumber === item.MustNumber);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      this.setState({ dataSourceconA: newData });
    } else if (keyflag == "补") {
      const newData = [...this.state.dataSourceconB];
      const index = newData.findIndex(item => row.SupplementNumber === item.SupplementNumber);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      this.setState({ dataSourceconB: newData });
    }
  };


  componentDidMount() {
    var UserNumber = sessionStorage.getItem('unumber')
    this.setState({
      UserNumber
    })
    axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=38', {
      StartTime: "",
      EndTime: "",
      FlowTypeID: "",
      FlowTypeName: ""
    }).then(data => {
      console.log('待办事项', data.data.data)
      this.setState({
        dataSourceA: data.data.data,
        dataSourceAfu: data.data.data,
        dataSourceAlength: data.data.data ? data.data.data.length : 0,
      })
    })

    axios.get('/DATA/WorkFlow/WorkFlow.aspx?flag=43').then(data => {
      console.log('流程类型初始化', data)
      this.setState({
        Processinitarr: data.data.data
      })
    })
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 50 - 30 - 50) / 28),
    })
  }


  //人员选中
  senddataA(record) {
    this.setState({
      rowIdA: record.ID,
      rowIdAobj: record
    })
  }

  setRowClassNameA = (record) => {
    return record.ID === this.state.rowIdA ? 'clickRowStyl' : 'clickRowSty';
  }



  //异常
  pagechange(key, page) {
    console.log(key, page)
    if (key == "待办") {
      this.setState({
        pageIndexA: page.current,
      })
    } else if (key == "已办") {
      this.setState({
        pageIndexB: page.current,
      })
    } else if (key == "流程") {
      this.setState({
        pageIndexC: page.current,
      })
    } else if (key == "审批") {
      this.setState({
        pageIndexFlow: page.current
      })
    }
  }

  //联合搜索
  onlianheSearch(key) {
    if (key == "待办") {
      axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=38', {
        FlowTypeName: this.state.FlowTypeNameA,
        FlowTypeID: this.state.FlowTypeIDA,
        StartTime: this.state.StartTtimeA,
        EndTime: this.state.EndTimeA
      }).then(data => {
        this.setState({
          dataSourceA: data.data.data,
          dataSourceAfu: data.data.data,
          dataSourceAlength: data.data.data ? data.data.data.length : 0,
        })
      })
    }
  }

  //审核事项
  todealevent() {

    if (this.state.rowIdA) {
      axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=51', {
        ID: this.state.rowIdAobj.ID
      }).then(data => {
        if (data.data.success) {
          this.setState({
            dataSourceuser: data.data.data
          })
        }
        else {
          message.error(data.data.describe)
        }
      })
      axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=39', {
        ID: this.state.rowIdA
      }).then(data => {
        console.log(data.data)
        if (data.data.success == true) {
          var formdataarr = eval(data.data.data.HtmlData)
          if (data.data.data.JsonData.indexOf('{') > -1) {
            var formconarr = JSON.parse(data.data.data.JsonData)
          } else {
            var formconarr = {}
          }
          console.log(formdataarr, formconarr)
          this.setState({
            NextUsers: data.data.data.NextUsers,
            visible: true,
            addeditflag: "审核",
            createdata: formdataarr,
            Version: data.data.data.Version,
            formconarrobj: formconarr,
            dataSourceconA: formconarr.MustMeasures ? formconarr.MustMeasures : [],
            dataSourceconB: formconarr.SupplementMeasures ? formconarr.SupplementMeasures : [],
          }, function () {
            document.querySelector('.workplacemanageaddedit').style.display = "block"
          })
        } else if (data.data.success == false) {
          message.error(data.data.describe)
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  //审批记录
  viewdealrecord(key) {
    var FlowID = ''
    var IFlowID = ''
    if (key == "待办") {
      IFlowID = this.state.rowIdA
      FlowID = this.state.rowIdAobj.FlowID
    } else if (key == "已办") {
      IFlowID = this.state.rowIdB
      FlowID = this.state.rowIdBobj.FlowID
    }
    if (IFlowID) {
      axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=41', {
        FlowID: FlowID,
        IFlowID: IFlowID
      }).then(data => {
        console.log('审批记录', data)
        if (data.data.success == true) {
          this.setState({
            flowrecordflag: "审批记录",
            dataSourceFlow: data.data.data
          }, function () {
            document.querySelector('.nodeflowrecord').style.display = "block"
          })
        } else if (data.data.success == false) {
          message.error(data.data.describe)
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  //流程节点
  viewprocesstrack(key) {
    var FlowID = ''
    var IFlowID = ''
    if (key == "待办") {
      IFlowID = this.state.rowIdA
      FlowID = this.state.rowIdAobj.FlowID
    } else if (key == "已办") {
      IFlowID = this.state.rowIdB
      FlowID = this.state.rowIdBobj.FlowID
    }
    axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=42', {
      FlowID: FlowID,
      IFlowID: IFlowID
    }).then(data => {
      console.log('流程节点', data)
      if (data.data.success == true) {
        this.setState({
          flowrecordflag: "流程节点",
          dataSourceFlow: data.data.data
        }, function () {
          document.querySelector('.nodeflowrecord').style.display = "block"
        })
      } else if (data.data.success == false) {
        message.error(data.data.describe)
      }
    })
  }


  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      for (var i in values) {
        var value = values[i]
        if (value == undefined) {
          values[i] = ""
        } else if (value.label) {
          values[i] = value.label
        } else if (value._d && !value._i) {
          values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
        } else if (value._f == "HH:mm:ss") {
          values[i] = value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
        } else if (value._f == "YYYY-MM-DD") {
          values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
        } else {
          values[i] = values[i]
        }
      }
      if (!err) {
        if (this.state.addeditflag == "发起") {
          // var str = JSON.stringify(values)
          // console.log(str ,typeof str)
          if (this.state.rowIdCobj.FlowTypeID == "3") {
            values.MustMeasures = this.state.dataSourceconA
            values.SupplementMeasures = this.state.dataSourceconB
          } else { }
          values.PlanStartTime = $('#PlanStartTime .ant-input').val()
          values.PlanEndTime = $('#PlanEndTime .ant-input').val()
          console.log(values)
          axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=31', {
            FormDataId: this.state.rowIdCobj.FormID,
            FlowID: this.state.rowIdCobj.ID,
            FormData: values,
            // formtable1:this.state.dataSourceconA,
            // formtable2:this.state.dataSourceconB
          }).then(data => {
            console.log(data)
            if (data.data.success == true) {
              document.querySelector('.workplacemanageaddedit').style.display = "none"
            } else if (data.data.success == false) {
              message.error(data.data.describe)
            }
          })
        } else if (this.state.addeditflag == "审核") {
          var arr = values.AuditUsers
          var AuditUsersstr = ''
          if (arr.length > 0) {
            AuditUsersstr = arr.join(',')
          } else {
            AuditUsersstr = ''
          }
          var AuditResult = values.AuditResult
          var AuditOpinion = values.AuditOpinion
          var formobj = values
          delete formobj.AuditResult
          delete formobj.AuditOpinion
          delete formobj.AuditUsers
          formobj.MustMeasures = this.state.dataSourceconA
          formobj.SupplementMeasures = this.state.dataSourceconB
          if (this.state.rowIdAobj.FlowID != "cae7557e-912b-4c5b-af30-ee75ce88d2b4") {
            delete formobj.MustMeasures
            delete formobj.SupplementMeasures
          }
          axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=32', {
            FlowId: this.state.rowIdA,
            Version: this.state.Version,
            FormData: formobj,
            // formtable1:this.state.dataSourceconA,
            // formtable2:this.state.dataSourceconB,
            AuditResult: AuditResult,
            AuditUsers: AuditUsersstr,
            AuditOpinion: AuditOpinion,
          }).then(data => {
            console.log(data)
            if (data.data.success == true) {
              axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=38', {
                FlowTypeName: this.state.FlowTypeNameA,
                FlowTypeID: this.state.FlowTypeIDA,
                StartTime: this.state.StartTtimeA,
                EndTime: this.state.EndTimeA
              }).then(data => {
                this.setState({
                  dataSourceA: data.data.data,
                  dataSourceAfu: data.data.data,
                  dataSourceAlength: data.data.data ? data.data.data.length : 0,
                }, function () {
                  document.querySelector('.workplacemanageaddedit').style.display = "none"
                })
              })
            } else if (data.data.success == false) {
              message.error(data.data.describe)
            }
          })
        }
      }
    });
  };
  //审核取消
  handleSubmitcancel() {
    this.props.form.resetFields();
    document.querySelector('.workplacemanageaddedit').style.display = "none"
  }

  //关闭流程记录跟踪
  tocloseflowpage() {
    document.querySelector('.nodeflowrecord').style.display = "none"
  }
  switchItem(item) {
    const type = item.type;
    switch (type) {
      case 'number':
        return <InputNumber style={{ width: '100%' }} />;
        break;
      case 'text':
        return <TextArea rows={3} style={{ width: '100%' }} />;
        break;
      case 'date':
        return <DatePicker showTime locale={locale} style={{ width: '100%' }} />;
        break;
      case 'dateonly':
        return <DatePicker locale={locale} style={{ width: '100%' }} />;
        break;
      case 'select':
        return (
          <Select style={{ width: '100%' }}>
            {/* {
              item.options.map((option, index) => {
                return (<Option key={index} value={option}>{option}</Option>)
              })
            } */}
            <Option key={0} value={0}>{0}</Option>
            <Option key={1} value={1}>{1}</Option>
          </Select>
        )
      default:
        return <Input />;
        break;
    }
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    // formItem css 样式
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      }
    }
    const { dataSourceconA, dataSourceconB } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columnsconA = this.columnsconA.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave.bind(this, '常'),
        }),
      };
    });
    const columnsconB = this.columnsconB.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave.bind(this, '补'),
        }),
      };
    });
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div className="workplace1" style={{ height: clientHeight - 50 + 'px' }}>
        <div className="workplacemanageaddedit" style={{ height: clientHeight - 50 + 'px', width: clientWidth + 'px' }}>
          {
            this.state.addeditflag == "审核" ?
              <div className="preview">
                <div className="workplaceaddeditl">
                  <h2 className="h2title">待审核事项</h2>
                  {

                    this.state.rowIdAobj.FlowID == "cae7557e-912b-4c5b-af30-ee75ce88d2b4" ?
                      <div className="startcon">
                        <div className="startform">
                          <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                          >
                            {
                              this.state.createdata.map((item, index) => {
                                if (item.type == "date" || item.type == "dateonly") {
                                  item.value = this.state.formconarrobj[item.field] ? moment(this.state.formconarrobj[item.field], "YYYY-MM-DD HH:mm:ss") : ""
                                } else {
                                  item.value = this.state.formconarrobj[item.field]
                                }
                                return (
                                  <FormItem
                                    key={index}
                                    {...formItemLayout}
                                    label={item.name}
                                    hasFeedback
                                  >
                                    {getFieldDecorator(item.field, {
                                      initialValue: item.value,
                                      rules: [{
                                        required: item.require == "y" ? true : false,
                                        message: '该字段不能为空'
                                      }],
                                    })(
                                      this.switchItem(item)
                                    )}
                                  </FormItem>
                                )
                              })
                            }
                          </Form>

                        </div>
                        <div className="startcon1">
                          <span className="action"><Button onClick={this.handleAdd.bind(this, '常')}>添加</Button></span>
                          <div className="list">
                            <Table
                              size="small"
                              components={components}
                              rowClassName={() => 'editable-row'}
                              bordered
                              dataSource={dataSourceconA}
                              columns={columnsconA}
                              pagination={false}
                              scroll={{ y: clientHeight - 50 - 50 - 50 - 40 }}
                            />
                          </div>
                        </div>
                        <div className="startcon2">
                          <span className="action"><Button onClick={this.handleAdd.bind(this, '补')}>添加</Button></span>
                          <div className="list">
                            <Table
                              size="small"
                              components={components}
                              rowClassName={() => 'editable-row'}
                              bordered
                              dataSource={dataSourceconB}
                              columns={columnsconB}
                              pagination={false}
                              scroll={{ y: clientHeight - 50 - 50 - 50 - 40 }}
                            />
                          </div>
                        </div>
                      </div>

                      : <div className="startcon">
                        <div className="startformonly">
                          <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                          >
                            {
                              this.state.createdata.map((item, index) => {
                                if (item.type == "date" || item.type == "dateonly") {
                                  item.value = this.state.formconarrobj[item.field] ? moment(this.state.formconarrobj[item.field], "YYYY-MM-DD HH:mm:ss") : ""
                                } else {
                                  item.value = this.state.formconarrobj[item.field]
                                }
                                return (
                                  <FormItem
                                    key={index}
                                    {...formItemLayout}
                                    label={item.name}
                                    hasFeedback
                                  >
                                    {getFieldDecorator(item.field, {
                                      initialValue: item.value,
                                      rules: [{
                                        required: item.require == "y" ? true : false,
                                        message: '该字段不能为空'
                                      }],
                                    })(
                                      this.switchItem(item)
                                    )}
                                  </FormItem>
                                )
                              })
                            }
                          </Form>
                        </div>
                      </div>

                  } </div>
                <div className="workplaceaddeditr">
                  <div className="workplaceaudit">
                    <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                      审核
                </Button>
                    <Button onClick={this.handleSubmitcancel.bind(this)} >
                      取消
                </Button>
                  </div>
                  <h2 className="h2title">审核意见</h2>
                  <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  >
                    <Form.Item label="审核意见">
                      {getFieldDecorator('AuditOpinion', {
                        initialValue: this.state.formiptobj['AuditOpinion'],
                        rules: [{ required: true, message: '请输入审核意见' }],
                      })(
                        <Select showSearch>
                          <Option value={0} key={0}>通过</Option>
                          <Option value={1} key={1}>回退</Option>
                          <Option value={2} key={2}>撤销</Option>
                        </Select>
                      )}
                    </Form.Item>

                    <Form.Item label="审核理由">
                      {getFieldDecorator('AuditResult', {
                        initialValue: this.state.formiptobj['AuditResult'],
                        rules: [{ required: true, message: '请输入审核理由' }],
                      })(
                        <TextArea rows={5} />
                      )}
                    </Form.Item>
                    <Form.Item label="下一个审核人">
                      {getFieldDecorator('AuditUsers', {
                        initialValue: this.state.NextUsers ? this.state.NextUsers.indexOf(',') > -1 ?
                          this.state.NextUsers.split(',') : [this.state.NextUsers] : [],
                        rules: [{ required: false, message: '请输入下一个审核人' }],
                      })(
                        <Select showSearch mode="multiple">
                          {
                            this.state.dataSourceuser.map((item, index) => {
                              return (<Option value={item.UserId} key={item.UserId}>{item.UserName}</Option>)
                            })
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Form>
                </div>
              </div> :
              this.state.addeditflag == "发起" ?
                <div className="start">
                  <h2 className="h2title">发起流程</h2>
                  <div className="startact">
                    <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                      确定
                      </Button>
                    <Button onClick={this.handleSubmitcancel.bind(this)} >
                      取消
                      </Button>
                  </div>
                  {

                    this.state.rowIdCobj.ID == "cae7557e-912b-4c5b-af30-ee75ce88d2b4" ?
                      <div className="startcon">
                        <div className="startform">
                          <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                          >
                            {
                              this.state.createdata.map((item, index) => {
                                return (
                                  <FormItem
                                    key={index}
                                    {...formItemLayout}
                                    label={item.name}
                                    hasFeedback
                                  >
                                    {getFieldDecorator(item.field, {
                                      // initialValue: item.value,
                                      rules: [{
                                        required: item.require == "y" ? true : false,
                                        message: '该字段不能为空'
                                      }],
                                    })(
                                      this.switchItem(item)
                                    )}
                                  </FormItem>
                                )
                              })
                            }
                          </Form>

                        </div>
                        <div className="startcon1">
                          <span className="action"><Button onClick={this.handleAdd.bind(this, '常')}>添加</Button></span>
                          <div className="list">
                            <Table
                              size="small"
                              components={components}
                              rowClassName={() => 'editable-row'}
                              bordered
                              dataSource={dataSourceconA}
                              columns={columnsconA}
                              pagination={false}
                              scroll={{ y: clientHeight - 50 - 50 - 50 - 40 }}
                            />
                          </div>
                        </div>
                        <div className="startcon2">
                          <span className="action"><Button onClick={this.handleAdd.bind(this, '补')}>添加</Button></span>
                          <div className="list">
                            <Table
                              size="small"
                              components={components}
                              rowClassName={() => 'editable-row'}
                              bordered
                              dataSource={dataSourceconB}
                              columns={columnsconB}
                              pagination={false}
                              scroll={{ y: clientHeight - 50 - 50 - 50 - 40 }}
                            />
                          </div>
                        </div>
                      </div>

                      : <div className="startcon">
                        <div className="startformonly">
                          <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                          >
                            {
                              this.state.createdata.map((item, index) => {
                                return (
                                  <FormItem
                                    key={index}
                                    {...formItemLayout}
                                    label={item.name}
                                    hasFeedback
                                  >
                                    {getFieldDecorator(item.field, {
                                      // initialValue: item.value,
                                      rules: [{
                                        required: item.require == "y" ? true : false,
                                        message: '该字段不能为空'
                                      }],
                                    })(
                                      this.switchItem(item)
                                    )}
                                  </FormItem>
                                )
                              })
                            }
                          </Form>
                        </div>
                      </div>
                  }
                </div>
                : ""
          }
        </div>

        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="nodeflowrecord" ref="nodeflowrecord">
            <h2>{this.state.flowrecordflag} <span className="closepage" onClick={this.tocloseflowpage.bind(this)}>关闭</span></h2>
            <div className="listpage">
              <Table
                size="small"
                dataSource={this.state.dataSourceFlow}
                pagination={{
                  pageSize: 10,
                  current: this.state.pageIndexFlow,
                  total: Number(this.state.dataSourceFlow.length)
                }}
                onChange={this.pagechange.bind(this, '审批')}
                columns={this.state.flowrecordflag == "审批记录" ? this.columnsFlowB : this.columnsFlowA}
              />
            </div>
          </div>
        </Draggable>

        <div className="waitingtodeal">
          <h2 className="h2title">待办事项列表
              <div className="h2action">
              <Button onClick={this.todealevent.bind(this)}>审核</Button>
              <Button onClick={this.viewdealrecord.bind(this, '待办')}>审批记录</Button>
              <Button onClick={this.viewprocesstrack.bind(this, '待办')}>流程节点</Button>
            </div>
          </h2>
          <div className="listsearch">
            <div className="listsearchl">
              <span className="listsearchls">
                <span className="listsearchcondition">待办事项名 </span>
                <span className="searchcondition">
                  <Search
                    id="FlowTypeNameA"
                    placeholder="(待办事项名)"
                    value={this.state.FlowTypeName}
                    onChange={(e) => {
                      this.setState({
                        FlowTypeNameA: $('#FlowTypeNameA').val()
                      })
                    }}
                  />
                </span>
              </span>
              <span className="listsearchls">
                <span className="listsearchcondition">流程类型 </span>

                <span className="searchcondition">
                  <Select placeholder="请选择"
                    value={this.state.FlowTypeIDA}
                    onChange={(value) => {
                      this.setState({
                        FlowTypeIDA: value
                      })
                    }}>
                    <Option value="" key="">全部</Option>
                    {
                      this.state.Processinitarr.map((item, index) => {
                        return (<Option value={item.FlowTypeID} key={item.FlowTypeID}>{item.FlowName}</Option>)
                      })
                    }
                  </Select>
                </span>
              </span>
              <span className="listsearchls">
                <span className="listsearchcondition">起始日期 </span>
                <span className="searchcondition">
                  <DatePicker showTime locale={locale}
                    value={this.state.StartTtimeA ? moment(this.state.StartTtimeA) : null}
                    placeholder="起始日期"
                    onChange={(data, dataString) => {
                      this.setState({
                        StartTtimeA: dataString
                      })
                    }} /></span>
              </span>
              <span className="listsearchls">
                <span className="listsearchcondition">终止日期 </span>
                <span className="searchcondition">
                  <DatePicker showTime locale={locale}
                    value={this.state.EndTimeA ? moment(this.state.EndTimeA) : null}
                    placeholder="终止日期"
                    onChange={(data, dataString) => {
                      this.setState({
                        EndTimeA: dataString
                      })
                    }} /></span>
              </span>
            </div>
            <span className="listsearchr">
              <Button onClick={this.onlianheSearch.bind(this, '待办')}>搜索</Button>
            </span>
          </div>

          <div className="listpage"
            style={{ height: clientHeight - 50 - 80 + 'px' }}>
            <Table
              size="small"
              dataSource={this.state.dataSourceA}
              columns={this.columnsA}
              onRow={(record) => {
                return {
                  onClick: this.senddataA.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassNameA}
              // pagination={false}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndexA,
                total: this.state.dataSourceAlength
              }}
              onChange={this.pagechange.bind(this, '待办')}
            />
          </div>
        </div>

      </div >
    )
  }
}
const WorkList = Form.create()(WorkListComp);
export default WorkList;