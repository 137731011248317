import React from 'react';
import './style.scss'
import axios from 'axios'
import { conversion } from '../../../converse';
import store from '../../../store';
import { Table, Select, Input,Tooltip } from 'antd'
import $ from 'jquery'
import { onCellf } from '../../../comfunc'

import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import Highcharts3D from 'highcharts/highcharts-3d';

HighchartsMore(Highcharts)
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);

const Option = Select.Option
class Seconddata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource1: [
        { xu: 1, userName: 'MTC_JS0301-01', userName1: '低压给水管道安装图', userName2: '陈志辉', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 2, userName: 'MTC_CS0301-01', userName1: '灰库安装图', userName2: '陈志辉', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 3, userName: 'MTC_MS0201-01', userName1: '燃料输送系统平面布置图', userName2: '陈志辉', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 4, userName: 'CLH_TS0202-09', userName1: '11.570米梁板配筋图', userName2: '陈志辉', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 5, userName: 'ETB_DS0401-07', userName1: '发电机小室电流互感器', userName2: '陈志辉', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 6, userName: 'ABA_MW0101-01', userName1: '访客登记册', userName2: '陈志辉', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 7, userName: 'ETB_HS0204-01', userName1: '絮凝剂和氧化剂加药装置', userName2: '陈志辉', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        ],
      dataSource:  [
        { xu: 1, userName: 'JS0301-01', userName1: '低压给水管道', userName2: '机务', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 2, userName: 'CS0301-01', userName1: '灰库', userName2: '除灰渣', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 3, userName: 'MS0201-01', userName1: '燃料输送系统', userName2: '燃运', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 4, userName: 'TS0202-09', userName1: '11.570米梁板', userName2: '土建', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 5, userName: 'DS0401-07', userName1: '发电机小室电流互感器', userName2: '电气', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 6, userName: 'HS0204-06', userName1: '卸酸碱泵', userName2: '化水', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        { xu: 7, userName: 'SS0501-10', userName1: '冷却塔安装图', userName2: '水工', userName3: '陈伟', userName4: '2020/4/1 17:24:00' },
        ],
    }
    this.columns = [
      { title: '序号', width: '8%', dataIndex: 'xu', key: 'xu' },
      { title: '模型编码', width: '18%', dataIndex: 'userName', key: 'userName' },
      { title: '模型名称', width: '26%', dataIndex: 'userName1', key: 'userName1' },
      { title: '专业', width: '12%', dataIndex: 'userName2', key: 'userName2' },
      { title: '审核人', width: '12%', dataIndex: 'userName3', key: 'userName3' },
      { title: '提交时间', width: '24%', dataIndex: 'userName4', key: 'userName4' },
    ];
    this.columns1 = [
      { title: '序号', width: '7%', dataIndex: 'xu', key: 'xu',
        render:(text,record,index)=>{
         return (<span>{index+1}</span>)
        }
      },
      { title: '文档编码', width: '24%', dataIndex: 'Code', key: 'Code',onCell:onCellf,
       render:this.renderf
    },
      { title: '图纸名称', width: '30%', dataIndex: 'Name', key: 'Name',onCell:onCellf, render:this.renderf },
      { title: '提交人', width: '15%', dataIndex: 'CreatePeople', key: 'CreatePeople' },
      // { title: '审核人', width: '12%', dataIndex: 'userName3', key: 'userName3' },
      { title: '提交时间', width: '24%', dataIndex: 'CreateTime', key: 'CreateTime' },
    ];
    this.renderf = this.renderf.bind(this)
  }
  renderf(text, record){
    return (<Tooltip placement="top" className="managetooltip" title={text}>{text}</Tooltip>)
  }
  componentDidMount() {
    //近期提交文档列表
    axios.get('/DATA/Document/Document.aspx?flag=40').then(res=>{
      // console.log(res,conversion(res))
      this.setState({
        dataSource1:conversion(res)
      })
    })


    var pieColors = (function () {     // 此处是基础着色，如果设置好颜色，此处就没有看的必要了
      var colors =[],
          base = Highcharts.getOptions().colors[0],
          i;

      for (i = 0; i < 10; i += 1) {
          // Start out with a darkened base color (negative brighten), and end
          // up with a much brighter color
          colors.push(Highcharts.Color(base).brighten((i - 3) / 7).get());
      }
      return colors;
  }());
  $(document).ready(function() {
      var chart = {
          type: 'pie',
          backgroundColor:'none',
          borderColor:'#fff',
          options3d: {
              enabled: true,
              alpha: 65,
              beta: 0,
              //3d图包含柱的面板,x与z形成bottom  y与z形成side  x与y形成back
              //size为厚度 color为面板颜色
              frame:{
                 bottom:{
                   size:1,
                   color:'transparent'
                 },
                 side: {
                  size: 1,
                  color: 'transparent'
                },
                back: {
                    size: 1,
                    color: 'transparent'
                }
              }
          }
      };
      var title = {
          text: ''
      };
      var tooltip = {
          pointFormat: '{series.name}: {point.percentage:.1f}%'
      };
      var colors=['#d09a55','#ecd059','#76b2e6','#45d03d','#9a7aaf','#ea6a4b','#f27db5','#f22db3','#7fb99b','#628c72'];    //设置饼图颜色
      var credits =  {
          enabled: false    //禁用版权url    此处不设置，  会显示highecharts.com
      };
      var plotOptions = {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 25, //饼图厚度
              // color:pieColors,
              dataLabels: {
                  distance: 20,//设置引导线的长度
                  color:'#fff',//全局设置字体颜色
                  enabled: true,
                  // format: '{point.name}',
                  formatter: function() {     //设置字体与引导线和饼图颜色一致
                    return '<span style="color:#fff">['+ this.point.name +' '+ Highcharts.numberFormat(this.percentage, 2)+'%]</span>';
                        
                      // if(this.point.name == '中国'){
                      //     return '<span style="color:#fff">['+ this.point.name +' '+ Highcharts.numberFormat(this.percentage, 2)+'%]</span>';
                      // }else if(this.point.name == '美国'){
                      //     return '<span style="color:#fff">['+ this.point.name +' '+ Highcharts.numberFormat(this.percentage, 2)+'%]</span>';
                      // }else if(this.point.name == '俄罗斯'){
                      //     return '<span style="color:#fff">['+ this.point.name +' '+Highcharts.numberFormat(this.percentage, 2)+'%]</span>';
                      // }else if(this.point.name == '英国'){
                      //     return '<span style="color:#fff">['+ this.point.name +' '+ Highcharts.numberFormat(this.percentage, 2)+'%]</span>';
                      // }else if(this.point.name == '朝鲜'){
                      //     return '<span style="color:#fff">['+ this.point.name +' '+ Highcharts.numberFormat(this.percentage, 2)+'%]</span>';
                      // }else if(this.point.name == '日本'){
                      //     return '<span style="color:#fff">['+ this.point.name +' '+ Highcharts.numberFormat(this.percentage, 2)+'%]</span>';
                      // }
                  },
                   style: {
                       fontSize: '14px',//设置字体大小,
                       textOutline:"none",
                       fontFamily: 'MicrosoftYaHei,Verdana, sans-serif'
                   }
              }
          },
          bar: {
            borderColor:'#fff'
          }
      };
      var series= [{
          type: 'pie',
          name: '',
          startAngle: 260,//调整饼图的角度   方向：顺时针
          data: [
              ['JS',   35.0],
              ['DS',       10.8],
              {
                  name: 'CS',
                  y: 12.8,
                  sliced: true,
                  selected: true
              },
              ['TS',    8.5],
              ['KS',     6.2],
              ['DL',   5.1],
              ['GN',   6.1],
              ['HS',   7.1],
              ['MS',   2.1],
              ['NS',   1.1],
              ['SS',   1.1]
          ]
      }];

      var json = {};
      json.chart = chart;
      json.title = title;
      json.tooltip = tooltip;
      json.colors = colors;   // 设置饼图颜色
      json.credits = credits;
      json.plotOptions = plotOptions;
      json.series = series;

      Highcharts.chart('container',json)
  });
  }
  toOpenpage() {
    var obj = { ID: "33", Name: "项目基本信息配置", Parentid: "8", Leaf: "1", Rank: "3", RecordVersion: "", OrderNum: "1" }
    this.props.opennewframe(obj)
  }
  
  render() {
    const clientHeight = store.getState().clientHeight
    const imageUrl = store.getState().ProjectconfigimageUrl
    return (
      <div className="datacenter">
        <div className="datacentertop">
          <div className="proimg">
            <img src={imageUrl} onClick={this.toOpenpage.bind(this)} />
          </div>
          <div className="uploadmodel">
            <h2 className="datatitlename">近期上传模型</h2>
            <div className="listscroll">
              <Table
                rowKey={record => record.id}
                columns={this.columns}
                scroll={{ y: (clientHeight - 50 - 30 - 40) / 2 - 70 }}
                dataSource={this.state.dataSource}
                pagination={false}
              />
            </div>
          </div>
        </div>
        <div className="datacenterbot">
          <div className="docmodel">
            <h2 className="datatitlename">近期提交文档列表</h2>
            <div className="listscroll">
              <Table
                rowKey={record => record.id}
                columns={this.columns1}
                scroll={{ y: (clientHeight - 50 - 30 - 40) / 2 - 70 }}
                dataSource={this.state.dataSource1}
                pagination={false}
              />
            </div>
          </div>
          <div className="piechart">
            <div className="titlesele">
              <Select value="设计">
                <Option value="设计" key="1">设计</Option>
              </Select>
              <Input value="设计阶段各专业构件数量占比"></Input>
            </div>
            <div id="container"></div>
          </div>
        </div>
      </div>
    )
  }
}
export default Seconddata;