import React, { Component } from 'react'
import { Progress, Select } from 'antd'
import { conversion } from '../../../converse'
// 引入折现图
import echarts from 'echarts';
import 'echarts/lib/chart/bar';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import './style.scss'
import $ from 'jquery'
import store from '../../../store/index'
const Option = Select.Option;
//页数为4得数据
class Fourdata extends Component {
  constructor(props) {
    super(props);
    this.state = {

      dayelectricity: {},
      elecompareline: {},
      elecompare: {}
    }
  }
  componentDidMount() {
    this.setState({

      dayelectricity: {
        tooltip: {
          // trigger: 'item',
          formatter: "{c} "
        },
        legend: {
          orient: 'vertical',
          x: 22,
          y: 20,
          textStyle: { color: 'white' },
          data: ['炉侧厂用电', '机侧厂用电', '环保厂用电']
        },
        series: [
          {
            name: '半径模式',
            type: 'pie',
            radius: [30, 80],
            center: ['50%', '50%'],
            roseType: 'radius',
            // 圆形  -- 环形
            //roseType:'angle' 
            //样式 阴影 透明度 颜色 边框颜色
            itemStyle: {
              // shadowBlur:200,
              // shadowColor:'rgba(0,0,0,0.5)',
              //emphasis是鼠标hover时候的高亮样式
              emphasis: {
                shadowBlur: 200,
                shadowColor: 'rgba(0,0,0,0.5)'
              }
            },
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: true
              }
            },
            lableLine: {
              normal: {
                show: false
              },
              emphasis: {
                show: true
              }
            },
            data: [
              { value: 10, name: '炉侧厂用电',
              itemStyle: {
                normal: {
                  color: "#75bbc8"
                }
              } },
              { value: 12, name: '机侧厂用电' ,
              itemStyle: {
                normal: {
                  color: "#ee5b42"
                }
              }},
              { value: 15, name: '环保厂用电',
              itemStyle: {
                normal: {
                  color: "#f1c40f"
                }
              } },

            ]
          },
        ]
      },
      elecompareline: {
        grid: {
          left: 40,
          right: 60,
          bottom: 30,
          top: 40,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['早班', '晚班'],
          textStyle: { color: "#FFF" }
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              interval: 0
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '早班',
            type: 'line',
            stack: '总量1',
            data: [430.3, 830.1, 860.4, 720.4]
          },
          {
            name: '晚班',
            type: 'line',
            stack: '总量2',
            data: [930.7, 550.1, 820.5, 390.1]
          },
        ]
      },
      elecompare: {
        grid: {
          left: 40,
          right: 60,
          bottom: 30,
          top: 40,
          containLabel: true
        },
        legend: {
          textStyle: { color: "#FFF" }
        },
        tooltip: {},
        dataset: {
          source: [
            ['data', '早班', '晚班'],
            ['数据名称1', 430.3, 930.7],
            ['数据名称2', 830.1, 550.1],
            ['数据名称3', 860.4, 820.5],
            ['数据名称4', 720.4, 390.1]
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: false,
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 10      //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
        },
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 10      //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
        },
        series: [
          {
            type: 'bar', barWidth: 30,
            itemStyle: {
              normal: {
                color: "#659be0"
              }
            }
          },
          {
            type: 'bar', barWidth: 30,
            itemStyle: {
              normal: {
                color: "#ed6b75"
              }
            }
          }
        ]
      },
    }, function () {
      var myChart2 = echarts.init(document.getElementById('dayelectricitydraw'));
      // console.log(myChart2)
      myChart2.setOption(this.state.dayelectricity);

      var myChart3 = echarts.init(document.getElementById('elecompare'));
      myChart3.setOption(this.state.elecompare);
      if(store.getState().clientHeight >700){
        $('.ant-progress-inner').css({"width":"180px","height":"180px"})
      }else{
        $('.ant-progress-inner').css({"width":"120px","height":"120px"})

      }
    })

  }

  handleChange(value) {
    // console.log(value);
    if (value.label == "折线图") {
      var myChart3 = echarts.init(document.getElementById('elecompare'));
      myChart3.setOption(this.state.elecompareline);
    } else if (value.label == "柱状图") {
      var myChart3 = echarts.init(document.getElementById('elecompare'));
      myChart3.setOption(this.state.elecompare);
    }
  }
  componentWillReceiveProps(){
    if(store.getState().clientHeight >700){
      $('.ant-progress-inner').css({"width":"180px","height":"180px"})
    }else{
      $('.ant-progress-inner').css({"width":"120px","height":"120px"})

    }
  }
  render() {
    const clientHeight = store.getState().clientHeight
    return (
      <div className="fourpage">
        <section className="fourpagetop" style={{ height: (clientHeight - 50 - 30) / 2 - 50 + 'px' }}>
          <div className="totaldata" style={{ width: (store.getState().clientWidth - 120 - 40) / 2 + 'px', height: "100%" }}>
            <h2 className="datatitlename">综合数据</h2>
            <div className="totaldatacon" style={{ height: (clientHeight - 50 - 40) / 2 - 50 - 60 + 'px', }}>
              <Progress strokeLinecap="square" strokeWidth={13} strokeColor="#f1c40f" type="circle" percent={80} />
              <div className="totaldatacontext">
                <span className="eletricitysended">已发电80%</span>
                <div className="showele">
                  <h3>年度发电量完成指标</h3>
                  <span>2018年度预期发电量100</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dayelectricity" style={{ width: (store.getState().clientWidth - 120 - 40) / 2 + 'px', height: "100%" }}>
            <h2 className="datatitlename">日综合厂用电</h2>
            <div id="dayelectricitydraw" style={{ height: (clientHeight - 50 - 40) / 2 - 50 -40 +'px', width: '100%' }}>

            </div>
          </div>
        </section>
        <section className="fourpagebot" style={{ height: (clientHeight - 50 - 30) / 2 + 50 + 'px' }}>
          <h2 className="datatitlename">
            早晚班用电对比
            </h2>
          <div className="linetypesele">
            <Select labelInValue defaultValue={{ key: '柱状图' }} style={{ zIndex: '10', position: 'absolute', right: '10px', width: 120 }} onChange={this.handleChange.bind(this)}>
              <Option value="柱状图">柱状图</Option>
              <Option value="折线图">折线图</Option>
            </Select>
          </div>
          <div id="elecompare" style={{ height: (clientHeight - 50 - 40) / 2 + 'px', width: '100%' }}>

          </div>
        </section>
      </div>
    )
  }
}

export default Fourdata;