import React from 'react';
import {
  Table, Input, Tabs, Tree, Tooltip, Collapse, Form, Button,
  Select, Progress, message, Row, Col, Carousel, Drawer, Modal
} from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import echarts from 'echarts';
import './style.scss'
import moment from 'moment'
// import "moment/locale/zh-cn"
// moment.locale('zh-cn')
import Draggable from 'react-draggable';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { getButtonLimit } from '../../utils/getbuttonlimit'

import { onCellf } from '../../comfunc'
import store from '../../store';
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;
const { Option } = Select;
const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;
const confirm = Modal.confirm
class WisdomDiagnosisCeDian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      service: '',//厂
      //诊断模型树数据
      wisdomdiagnosistreeData: [],
      treeIdsele: '',

      selectedKeysA: [],//诊断模型选择
      equipmentName: '',//诊断设备名称
      EquipmentCode: '',//诊断设备编码

      //计算工具
      Software: [], //0: {SoftwareName: "B-BPDs"}1: {filePath: "C:\QY_Boiler.bpd"}

      //工况数据
      equipmentinitarr: [],
      detaillistIdGK: '',//工况选择
      //工况参数数据
      equipmentinitparaarr: [],
      detaillistIdGKPara: '',

      //模型加载iframe
      moduleloadframesrc: '',
      //诊断模型选择
      selectedKeysB: [],

      //测点列表
      axiosgetcedianarr: [],//点击获取的单个测点组
      axiosgetcedian: '',

      testpointtreeData: [],
      testpointtreeDataobj: {},//测点选择obj

      //原理数据id点
      theorydataid: [],
      //原理数据计算结果
      theorydataanswer: [],

      //测点Guid 定位
      CedianGuidarr: [],//{guid: "M1556089457019_*u003f*u003f*u003f*u003f*u003f.dgn.i.dgn*u0020-*u0020Default*u0020-*u0020142704"}
      //测点单位和编号
      CedianUnitandCode: [], //{PrincipleData: "MTSuperheater_Steam_In_P", MatchingGroupUnit: "bar(a) "}


      //故障记录
      ErrorRecordarr: [],
      // 数据看板列表
      DataKanbanarr: [],
      DataKanbanarrfu: [],
      activetabsKeykanban: "",

      //5个模块
      activetabsKey: '1',
      //模型加载
      begincount: 0,
      loadingcount: 0,
      //拖拽
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      controlledPositionB: {
        x: 0, y: 0
      },


      visible: false,
      addedit: '',
      addeditname: '',


      Cedianname: '',
      per_choose: '',
      KKS: '',
      CPostition: '',
      Out_sqlname: '',
      MatchingGroupUnit: '',
      //测点图
      cedianseleoption: '',


      //匹配组数据
      dataSourcematch1: [],
      dataSourcematch2: [],
      dataSourcematch3: [],
      detaillistIdmatch1: '',
      detaillistIdmatch2: '',
      detaillistIdmatch3: '',
      detaillistIdmatch2obj: {},
      detaillistIdmatch3obj: {},

      //流向系统
      datacaseobj: {}
    };
    //测点列表
    this.axiosgetcediancolumns = [
      {
        title: '测点名称',
        dataIndex: 'MatchingGroupName',
        key: 'MatchingGroupName',
        width: '40%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '测点编号',
        dataIndex: 'KKS',
        key: 'KKS',
        width: '25%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip tabledatacenter" title={text}>{text}</Tooltip>
      },
      {
        title: '测点类型',
        dataIndex: 'MatchingGroupType',
        key: 'MatchingGroupType',
        width: '20%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip tabledatacenter" title={text}>{text}</Tooltip>
      },
      {
        title: '测点单位',
        dataIndex: 'MatchingGroupUnit',
        key: 'MatchingGroupUnit',
        width: '15%', onCell: () => {
          return {
            style: {
              maxWidth: "10px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }
          }
        },
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      }
    ]


    //匹配组数据
    this.match1columns = [
      {
        title: '匹配组名称',
        dataIndex: 'MatchingGroupName',
        key: 'MatchingGroupName',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '测点编号',
        dataIndex: 'MatchingGroupNum',
        key: 'MatchingGroupNum',
        width: '30%', onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip tabledatacenter" title={text}>{text}</Tooltip>
      },
      {
        title: '类型',
        dataIndex: 'MatchingGroupType',
        key: 'MatchingGroupType',
        width: '20%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '单位',
        dataIndex: 'MatchingGroupUnit',
        key: 'MatchingGroupUnit',
        width: '20%',
        onCell: onCellf,
        render: this.renderf
      }
    ]

    this.match2columns = [
      {
        title: '测点名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '35%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '测点编号',
        dataIndex: 'KKS',
        key: 'KKS',
        width: '25%', onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip tabledatacenter" title={text}>{text}</Tooltip>
      },
      {
        title: '测点类型',
        dataIndex: 'Type',
        key: 'Type',
        width: '20%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '测点单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '20%',
        onCell: onCellf,
        render: this.renderf
      }
    ]
    this.match3columns = [
      {
        title: '名称',
        dataIndex: 'PrincipleName',
        key: 'PrincipleName',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '原理编号',
        dataIndex: 'PrincipleNum',
        key: 'PrincipleNum',
        width: '30%', onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip tabledatacenter" title={text}>{text}</Tooltip>
      },
      {
        title: '类型',
        dataIndex: 'PrincipleType',
        key: 'PrincipleType',
        width: '20%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '单位',
        dataIndex: 'PrincipleUnit',
        key: 'PrincipleUnit',
        width: '20%',
      },
    ]

    this.receivemess = this.receivemess.bind(this)

    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>)
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(73)

    var service = sessionStorage.getItem('service')
    this.setState({
      service: service
    })
    axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=1').then(data => {
      console.log('诊断模型树', conversion(data))
      this.setState({
        wisdomdiagnosistreeData: conversion(data)
      })
    })
    window.addEventListener('message', this.receivemess)
  }

  receivemess(e) {
    console.log(e.data)
    if (e.data == "COMPLETE") {
      var num = this.state.loadingcount
      num = num + 1
      if (num / this.state.begincount == 1) {
        this.setState({
          loadingcount: 0,
          begincount: 0,
        }, function () {
          this.refs.loadprogress.style.display = "none"
        })
      } else {
        this.setState({
          loadingcount: num
        }, function () {
          //console.log(this.state.loadingcount / this.state.begincount)
          if (this.state.loadingcount / this.state.begincount == 1) {
            this.setState({
              loadingcount: 0,
              begincount: 0,
            }, function () {
              this.refs.loadprogress.style.display = "none"
            })
          }
        })
      }
    }
  }

  onSelectA(selectedKeys, info) {
    console.log(selectedKeys, info)

    this.setState({
      selectedKeysA: selectedKeys,//id
      equipmentName: info.node.props.title,
      EquipmentCode: info.node.props.Code,
    }, function () {
      if (this.state.selectedKeysA[0]) {
        //测点数据
        axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=2', {
          ID: this.state.selectedKeysA[0]
        }).then(data => {
          console.log('测点上级', conversion(data))
          this.setState({
            testpointtreeData: conversion(data)
          })
        })
        //获取计算设备的软件名称，以及计算文件
        axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=1', {
          EquipmentCode: this.state.EquipmentCode
        }).then(data => {
          console.log('SoftwareName', conversion(data))
          this.setState({
            Software: conversion(data)
          })
        })
      }
    })
  }
  //测点选择
  onSelectB(selectedKeys, info) {
    console.log(selectedKeys, info)
    this.setState({
      selectedKeysB: selectedKeys
    })
    if (selectedKeys[0]) {
      //匹配组数据
      axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=5', {
        ProfessionalTreeId: selectedKeys[0]
      }).then(data => {
        console.log('匹配组数据', conversion(data))
        this.setState({
          dataSourcematch1: conversion(data)
        })
      })
      axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=6', {
        ProfessionalTreeId: selectedKeys[0]
      }).then(data => {
        console.log('测点列表', conversion(data))
        this.setState({
          // axiosgetcedianarr: conversion(data),
          dataSourcematch2: conversion(data)
        })
      })
      //获取原理数据
      axios.post('/DATA/MonitoringAndDiagnosis/CSchematicDiagram.aspx?flag=2', {
        ProfessionalTreeId: selectedKeys[0]
      }).then(data => {
        console.log('获取原理数据', conversion(data))
        this.setState({
          dataSourcematch3: conversion(data)
        })
      })
    }
  }
  //工况添加合删除
  handleDel(key) {
    var _this = this
    if (key == "工况") {
      var key = this.state.detaillistIdGK
      if (this.state.detaillistIdGK) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=5', {
              ID: _this.state.detaillistIdGK
            }).then(data => {
              console.log(data)
              if (data.data.success == true) {
                const dataSource = [..._this.state.equipmentinitarr];
                _this.setState({ equipmentinitarr: dataSource.filter(item => item.ID !== key), detaillistIdGK: "" },
                  function () {
                    message.success('删除成功！')
                  });
              }
            })
          },
          onCancel() {
            _this.setState({
              detaillistIdGK: ''
            })
          },
        });

      } else {
        message.error('请选择!')
      }
    } else if (key == "匹配组") {
      var key = this.state.detaillistIdmatch1
      if (this.state.detaillistIdmatch1) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=6', {
              ID: _this.state.detaillistIdmatch1
            }).then(data => {
              console.log(data)
              if (data.data.success == true) {
                const dataSource = [..._this.state.dataSourcematch1];
                _this.setState({ dataSourcematch1: dataSource.filter(item => item.ID !== key), detaillistIdmatch1: "" },
                  function () {
                    message.success('删除成功！')
                  });
              }
            })
          },
          onCancel() {
            _this.setState({
              detaillistIdmatch1: ''
            })
          },
        });

      } else {
        message.error('请选择!')
      }
    }

  }
  onClose = () => {
    this.setState({
      visible: false,
    }, function () {
      this.props.form.resetFields();
    });
  };
  //工况添加
  handleAdd(key) {
    if (this.state.selectedKeysA[0]) {
      if (key == "工况") {
        this.setState({
          visible: true,
          formiptobj: {},
          addeditname: '诊断工况',
          addedit: '添加'
        })
      } else if (key == "匹配组") {
        if (this.state.selectedKeysB[0]) {
          this.setState({
            visible: true,
            formiptobj: {},
            addeditname: '匹配组',
            addedit: '添加'
          })
        }
      } else if (key == "匹配组测点") {
        if (this.state.detaillistIdmatch1 && this.state.detaillistIdmatch2) {
          axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=7', {
            ID: this.state.detaillistIdmatch1,
            TestNum: this.state.detaillistIdmatch2obj.pointNumber
          }).then(data => {
            console.log(data)
            if (data.data.success == true) {
              //匹配组数据
              axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=5', {
                ID: this.state.selectedKeysB[0]
              }).then(data => {
                console.log('匹配组数据', conversion(data))
                this.setState({
                  dataSourcematch1: conversion(data)
                }, function () {
                  message.success(`测点${this.state.detaillistIdmatch2obj.pointNumber}加入成功！`)
                })
              })
            }
          })
        }
      } else if (key == "匹配组原理") {
        if (this.state.detaillistIdmatch1 && this.state.detaillistIdmatch3) {
          axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=8', {
            ID: this.state.detaillistIdmatch1,
            PrincipleNum: this.state.detaillistIdmatch3obj.PrincipleNum
          }).then(data => {
            console.log(data)
            if (data.data.success == true) {
              //匹配组数据
              axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=5', {
                ID: this.state.selectedKeysB[0]
              }).then(data => {
                console.log('匹配组数据', conversion(data))
                this.setState({
                  dataSourcematch1: conversion(data)
                }, function () {
                  message.success(`原理数据${this.state.detaillistIdmatch3obj.PrincipleNum}加入成功！`)
                })
              })
            }
          })
        }
      }
    } else {
      message.error('请选择!')
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values)
      for (var i in values) {
        var value = values[i]
        if (value == undefined) {
          values[i] = ""
        } else if (value.label) {
          values[i] = value.label
        } else if (value._d) {
          values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
        } else {
          values[i] = values[i]
        }
      }

      if (!err) {
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "诊断工况") {
            values.ID = this.state.selectedKeysA[0]
            axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=4', values).then(data => {
              console.log(data)
              if (data.data.success == true) {
                //工况下拉框初始化数据
                axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=2', {
                  ID: this.state.selectedKeysA[0]
                }).then(data => {
                  console.log('工况下拉框初始化数据', conversion(data))
                  this.setState({
                    equipmentinitarr: conversion(data),
                    visible: false
                  }, function () {
                    // message.success('添加成功！')
                  })
                })
              } else if (typeof data.data == "string" && data.data.indexOf('err') > -1) {
                message.error('此编号已存在！')
              }
            })
          } else if (this.state.addeditname == "匹配组") {
            values.ProfessionalTreeId = this.state.selectedKeysB[0]
            axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=4', values).then(data => {
              console.log(data)
              if (data.data.success == true) {
                axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=5', {
                  ProfessionalTreeId: this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('匹配组数据', conversion(data))
                  this.setState({
                    dataSourcematch1: conversion(data),
                    visible: false
                  })
                })
                // if (this.state.selectedKeysB[0] == "0-0") {
                //   axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=5', {
                //     id: this.state.selectedKeysA[0]
                //   }).then(data => {
                //     console.log('匹配组数据', conversion(data))
                //     this.setState({
                //       dataSourcematch1: conversion(data),
                //       visible: false
                //     })
                //   })
                // } else {
                //   axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=4', {
                //     ID: this.state.selectedKeysB[0]
                //   }).then(data => {
                //     console.log('匹配组数据', conversion(data))
                //     this.setState({
                //       dataSourcematch1: conversion(data),
                //       visible: false
                //     })
                //   })
                // }
              } else if (typeof data.data == "string" && data.data.indexOf('err') > -1) {
                message.error('此编码已存在！')
              }
            })

          }
        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "诊断工况参数") {
            values.ID = this.state.detaillistIdGKPara
            axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=6', values).then(data => {
              console.log(data)
              if (data.data.success == true) {
                //工况下拉框初始化数据
                axios.post('/DATA/MonitoringAndDiagnosis/Workingdata.aspx?flag=3', {
                  WDId: this.state.detaillistIdGK,
                  SoftwareCode: this.state.Software[0].SoftwareName,
                }).then(data => {
                  console.log('工况下拉框初始化数据', conversion(data))
                  this.setState({
                    equipmentinitparaarr: conversion(data),
                    visible: false,
                    detaillistIdGKPara: ''
                  }, function () {
                    // message.success('添加成功！')
                  })
                })

              }
            })
          }
        }
      }
    });
  };

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }



  //匹配组数据
  senddatamatch1(record) {
    console.log(record)
    this.setState({
      detaillistIdmatch1: record.ID,
      detaillistIdmatch2: record.TestNum,
      detaillistIdmatch3: record.PrincipleNum,
      formiptobj: record
    }, function () {
      this.props.form.resetFields()
    })
  }

  setRowClassNamematch1 = (record) => {
    return record.ID === this.state.detaillistIdmatch1 ? 'clickRowStyl' : 'clickRowSty';
  }

  senddatamatch2(record) {
    console.log(record)
    var _this = this
    if (this.state.detaillistIdmatch1) {
      if (record.Indexes != this.state.detaillistIdmatch2) {
        confirm({
          title: '是否要更改绑定得测点?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=7', {
              ID: _this.state.detaillistIdmatch1,
              TestNum: record.ID
            }).then(data => {
              console.log(data)
              if (data.data.success == true) {
                //匹配组数据
                axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=5', {
                  ProfessionalTreeId: _this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('匹配组数据', conversion(data))
                  _this.setState({
                    dataSourcematch1: conversion(data),
                    detaillistIdmatch2: record.ID,
                    detaillistIdmatch2obj: record
                  }, function () {
                    message.success(`测点${_this.state.detaillistIdmatch2}加入成功！`)
                  })
                })
              }
            })
          },
          onCancel() {
            _this.setState({

            })
          },
        });
      }
    }
  }

  setRowClassNamematch2 = (record) => {
    return record.Indexes === this.state.detaillistIdmatch2 ? 'clickRowStyl' : 'clickRowSty';
  }
  senddatamatch3(record) {
    console.log(record)
    var _this = this
    if (this.state.detaillistIdmatch1) {
      if (record.ID != this.state.detaillistIdmatch3) {
        confirm({
          title: '是否要更改绑定得原理数据?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=8', {
              ID: _this.state.detaillistIdmatch1,
              PrincipleNum: record.ID
            }).then(data => {
              console.log(data)
              if (data.data.success == true) {
                //匹配组数据
                axios.post('/DATA/MonitoringAndDiagnosis/DataMatchingGroup.aspx?flag=5', {
                  ProfessionalTreeId: _this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('匹配组数据', conversion(data))
                  _this.setState({
                    dataSourcematch1: conversion(data),
                    detaillistIdmatch3: record.ID,
                    detaillistIdmatch3obj: record
                  }, function () {
                    message.success(`原理数据${_this.state.detaillistIdmatch3}加入成功！`)
                  })
                })
              }
            })
          },
          onCancel() {
            _this.setState({

            })
          },
        });
      }
    }
  }

  setRowClassNamematch3 = (record) => {
    return record.ID === this.state.detaillistIdmatch3 ? 'clickRowStyl' : 'clickRowSty';
  }
  //装配树收缩
  zhuangpeishushou() {
    this.refs.wisdomdiagnosiscedianleft.style.display = "none"
    this.refs.treeshoufangzhang.style.display = "block"
    if ($('.wisdomdiagnosiscediancenter').css("display") == "block") {
      $('.wisdomdiagnosiscediancenter').css("left", "0px")
      $('.treeshoufang').css("left", "17%")
    } else if ($('.wisdomdiagnosiscediancenter').css("display") == "none") {
      $('.treeshoufang').css("left", "1%")
    }
  }
  zhuangpeishufang() {
    this.refs.wisdomdiagnosiscedianleft.style.display = "block"
    this.refs.treeshoufangzhang.style.display = "none"
    if ($('.wisdomdiagnosiscediancenter').css("display") == "none") {
      $('.wisdomdiagnosiscedianleft').css("left", "0px")
      $('.treeshoufang').css("left", "16%")
    } else if ($('.wisdomdiagnosiscediancenter').css("display") == "block") {
      $('.wisdomdiagnosiscedianleft').css("left", "0%")
      $('.wisdomdiagnosiscediancenter').css("left", "15%")
    }
  }
  kksshushou() {
    this.refs.wisdomdiagnosiscediancenter.style.display = "none"
    this.refs.treeshoufangkks.style.display = "block"
    if ($('.wisdomdiagnosiscedianleft').css("display") == "block") {
      $('.wisdomdiagnosiscedianleft').css("left", "0")
      $('.treeshoufang').css("left", "17%")
    } else if ($('.wisdomdiagnosiscedianleft').css("display") == "none") {
      $('.treeshoufang').css("left", "1%")
    }
  }
  kksshufang() {
    this.refs.wisdomdiagnosiscediancenter.style.display = "block"
    this.refs.treeshoufangkks.style.display = "none"
    if ($('.wisdomdiagnosiscedianleft').css("display") == "none") {
      $('.wisdomdiagnosiscediancenter').css("left", "0")
      $('.treeshoufang').css("left", "17%")
    } else if ($('.wisdomdiagnosiscedianleft').css("display") == "block") {
      $('.wisdomdiagnosiscedianleft').css("left", "0%")
      $('.wisdomdiagnosiscediancenter').css("left", "15%")
    }
  }
  tabclickchangekey(key) {
    if (key == "5") {
      //获取工具栏元素
      var toolele = document.getElementById('moduleloadframe').contentWindow.document.getElementsByClassName('yj-tool')[0]
      if (toolele) {
        if (toolele.style.display == "flex") {
          toolele.style.display = "none"
          if (toolele.style.display == "none") {
            this.setState({
              activetabsKeykanban: "",
              activetabsKey: "1"
            })
          }
        } else {
          toolele.style.display = "flex"
          if (toolele.style.display == "flex") {
            this.setState({
              activetabsKeykanban: "5",
              activetabsKey: "1"
            })
          }
        }
      }

    } else if (key == "1") {
      this.setState({
        activetabsKey: "1",
        activetabsKeykanban: ""
      }, function () {
        var toolele = document.getElementById('moduleloadframe').contentWindow.document.getElementsByClassName('yj-tool')[0]
        if (toolele) {
          toolele.style.display = "none"
        }
      })
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="wisdomdiagnosiscedian" style={{ height: clientHeight - 50 + 'px' }}>

        <div className="wisdomdiagnosiscedianleft" ref="wisdomdiagnosiscedianleft" style={{ height: clientHeight - 50 + 'px' }}>
          <h2 className="zhuangpeishu">
            <span>设备诊断</span>
            <span onClick={this.zhuangpeishushou.bind(this)} className="zhuangpeishushousuo"></span>
          </h2>
          <div className="wisdomdiagnosislefttree" style={{ height: clientHeight - 50 - 40 + 'px' }}>
            <Tree
              // checkable
              // onCheck={this.onCheckA.bind(this)}
              // checkedKeys={this.state.checkedKeysA}
              onSelect={this.onSelectA.bind(this)}
              selectedKeys={this.state.selectedKeysA}
            >
              {
                this.state.wisdomdiagnosistreeData.map((item, index) => {
                  return (<TreeNode title={item.Name} key={item.ID} Code={item.Code}></TreeNode>)
                })
              }
            </Tree>
          </div>
        </div>

        <div className="wisdomdiagnosiscediancenter" ref="wisdomdiagnosiscediancenter" style={{
          height: clientHeight - 50 + 'px',
        }}>
          <h2>
            <span className="left">诊断模型</span>
            <span className="kksshousuo" onClick={this.kksshushou.bind(this)}></span>
          </h2>
          <div className="tree" style={{ height: clientHeight - 50 - 40 + 'px', overflow: "auto" }}>
            <DirectoryTree
              onSelect={this.onSelectB.bind(this)}
              selectedKeys={this.state.selectedKeysB}
            >
              {
                this.state.equipmentName ?
                  <TreeNode title={this.state.equipmentName} key={this.state.selectedKeysA[0]}>
                    {
                      this.state.testpointtreeData.map((item, index) => {
                        return (
                          <TreeNode title={<Tooltip placement="right" title={item.Name}>{item.Code}</Tooltip>}
                            key={item.ProfessionalTreeId} Name={item.Name}
                            className={item.Flag == "1" ? "flageq1" : ""}
                          >
                            {/* {
                              item.children && item.children.length > 0 ?
                                item.children.map((item, index) => {
                                  return (
                                    <TreeNode title={item.Name}
                                      style={{ color: item.Name.indexOf(this.state.searchValue) > -1 ? "#f00" : "#fff" }}
                                      key={item.ID} KKS={item.KKS}
                                      Out_sqlname={item.Out_sqlname}
                                      MatchingGroupUnit={item.MatchingGroupUnit}
                                      CPostition={item.CPostition}></TreeNode>
                                  )
                                }) : ""
                            } */}
                          </TreeNode>
                        )
                      })
                    }
                  </TreeNode>
                  : ""
              }
            </DirectoryTree>
          </div>
        </div>

        <Drawer
          title={this.state.addeditname + '' + this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 60 + 'px' }}>
            {
              this.state.addeditname == "匹配组" ?
                <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="匹配组编号">
                        {getFieldDecorator('MatchingGroupNum', {
                          initialValue: this.state.formiptobj['MatchingGroupNum'],
                          rules: [{ required: true, message: '请输入匹配组编号' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="匹配组名称">
                        {getFieldDecorator('MatchingGroupName', {
                          initialValue: this.state.formiptobj['MatchingGroupName'],
                          rules: [{ required: true, message: '请输入匹配组名称' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="匹配组单位">
                        {getFieldDecorator('MatchingGroupUnit', {
                          initialValue: this.state.formiptobj['MatchingGroupUnit'],
                          rules: [{ required: true, message: '请输入匹配组单位' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                        </Button>
                    </Form.Item>
                  </Row>
                </Form>
                : this.state.addeditname == "匹配组测点" ?
                  <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="测点编号">
                          {getFieldDecorator('TestNum', {
                            // initialValue: this.state.formiptobj['TestNum'],
                            rules: [{ required: true, message: '请输入测点编号' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
                        </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                  : this.state.addeditname == "匹配组原理" ?
                    <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="原理编号">
                            {getFieldDecorator('PrincipleNum', {
                              // initialValue: this.state.formiptobj['PrincipleNum'],
                              rules: [{ required: true, message: '请输入原理编号' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16} className="formbutton">
                        <Form.Item>
                          <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                            保存
                        </Button>
                        </Form.Item>
                      </Row>
                    </Form>
                    : ""
            }
          </div>
        </Drawer>

        <div className="datakanban">
          <div className="listleft">
            <h2>匹配组数据
                  <span className="treeaction">
                <Button style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '匹配组')}>添加</Button>
                <Button style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this, '匹配组')}>删除</Button>
              </span>
            </h2>
            <div className="listscroll">
              <Table
                size="small"
                dataSource={this.state.dataSourcematch1}
                columns={this.match1columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: this.senddatamatch1.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassNamematch1}
                scroll={{ y: clientHeight - 50 - 40  - 30 }}
              />
            </div>
          </div>
          <div className="listcenter">
            <h2>测点数据
                  <span className="treeaction">
                {/* <Button onClick={this.handleAdd.bind(this, '匹配组测点')}>添加</Button> */}
              </span></h2>
            <div className="listscroll">
              <Table
                size="small"
                dataSource={this.state.dataSourcematch2}
                columns={this.match2columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: this.senddatamatch2.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassNamematch2}
                scroll={{ y: clientHeight - 50 - 40  - 30 }}
              />
            </div>
          </div>
          <div className="listright">
            <h2>原理数据
                  <span className="treeaction">
                {/* <Button onClick={this.handleAdd.bind(this, '匹配组原理')}>添加</Button> */}
              </span>
            </h2>
            <div className="listscroll">
              <Table
                size="small"
                dataSource={this.state.dataSourcematch3}
                columns={this.match3columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: this.senddatamatch3.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassNamematch3}
                scroll={{ y: clientHeight - 50 - 40  - 30 }}
              />
            </div>
          </div>

        </div>

        <div className="treeshoufang">
          <span ref="treeshoufangzhang" onClick={this.zhuangpeishufang.bind(this)}>诊断模型</span>
          <span ref="treeshoufangkks" onClick={this.kksshufang.bind(this)}>测点列表</span>
        </div>
      </section >
    )
  }
}
const WisdomDiagnosisCeDianapp = Form.create()(WisdomDiagnosisCeDian);
export default WisdomDiagnosisCeDianapp;