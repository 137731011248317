import React from 'react'
import {
  Tree, Input, Select, Table, Button, Tabs, message, Tooltip,
  Form, Row, Col, Drawer, Spin, InputNumber, Upload, Modal
} from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import Draggable from 'react-draggable'
import { conversion } from '../../converse'
import TextArea from 'antd/lib/input/TextArea'
import { onCellf } from '../../comfunc'
import store from '../../store';
import { toProTreeData2 } from '../../utils/toprojecttreedata'
import { getButtonLimit } from '../../utils/getbuttonlimit'

const confirm = Modal.confirm
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { Search } = Input;
const { TreeNode, DirectoryTree } = Tree;

class ConfigManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr: [],

      factoryinfo: '',//登录的厂

      addeditname: '',
      addedit: '',
      formiptobj: {},
      formiptobjfu: {},


      rowIdA: '',
      rowIdAobj: {},
      rowIdB: '',
      dataSourceA: [],
      dataSourceAlength: 0,
      dataSourceB: [],
      dataSourceBlength: 0,

      pageIndexA: 1,
      pageIndexB: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 80 - 50) / 28),

      Searchvalue: '',
      handlepartsele: '',
      attrA: {
        ID: 'ID',
        Parentid: 'Parentid',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        treepath: ''
      },

      uploading: false,

      //测点
      treeDataC: [],
      treeselecpath: '',
      selectedKeysC: [],

      //测点分配
      treeDataCC: [],
      selectedKeysCC: [],
      activeDrags: 0,
      controlledPosition: {
        x: 0, y: 0
      },



      //测点列表选择
      selectedRowKeys: [],
      selectedRowKeysstr: '',
      counflag: false
    }
    //测点列表
    this.columnsA = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '5%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndexA - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '测点号',
        dataIndex: 'PointNumber',
        key: 'PointNumber',
        width: '8%',
      },
      {
        title: '描述',
        dataIndex: 'Describe',
        key: 'Describe',
        width: '15%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '索引',
        dataIndex: 'Indexes',
        key: 'Indexes',
        width: '6%'
      },
      {
        title: '名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '14%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '数据类型',
        dataIndex: 'DataType',
        key: 'DataType',
        width: '8%'
      },
      {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '6%'
      },
      {
        title: '小数位数',
        dataIndex: 'DecimalDigit',
        key: 'DecimalDigit',
        width: '6%'
      },
      {
        title: '量程上限',
        dataIndex: 'MeasurementUL',
        key: 'MeasurementUL',
        width: '6%'
      },
      {
        title: '量程下限',
        dataIndex: 'MmeasurementLL',
        key: 'MmeasurementLL',
        width: '6%'
      },
      {
        title: 'KKS编码',
        dataIndex: 'KKS',
        key: 'KKS',
        width: '15%',
        onCell: onCellf,
        render: this.renderf
      }
    ]
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log(res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(39)

    var factoryinfo = sessionStorage.getItem('factory')
    this.setState({
      factoryinfo
    })

    axios.get('/DATA/ParaSet/ImportTestId.aspx?flag=30').then(data => {
      // //console.log('测点树', conversion(data))
      var attrC = this.state.attrA
      let arr = toProTreeData2(conversion(data), attrC);
      //console.log('测点树', arr);
      this.setState({
        treeDataC: arr
      })
    })
    //默认测点列表
    // axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=31', {
    //   id: this.state.selectedKeysC[0],
    //   pageSize: this.state.pageSize,
    //   pageIndex: 1
    // }).then(data => {
    //   var str = data.data
    //   this.setState({
    //     dataSourceA: conversion(data),
    //     pageIndexA: 1,
    //     dataSourceAfu: conversion(data),
    //     dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
    //   })
    // })
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 80 - 50) / 28),
    })
  }

  handleAdd(key) {
    this.props.form.resetFields();
    if (key == "测点") {
      if (this.state.selectedKeysC[0]) {
        this.setState({
          addedit: '添加',
          addeditname: key,
          visibledraw: true,
          formiptobj: {}
        }, function () {
          this.props.form.resetFields();
        })
      } else {
        message.error('请选择！')
      }
    }
  }
  handleEdit(key) {
    if (key == "测点") {
      var obj = this.state.rowIdAobj
      if (this.state.rowIdA) {
        this.setState({
          addedit: '编辑',
          formiptobj: obj,
          addeditname: key,
          visibledraw: true
        }, function () {
          this.props.form.resetFields();
        })
      } else {
        message.error('请选择！')
      }
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      //console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate() + ' ' + value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else {
            values[i] = values[i]
          }
        }
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "测点") {
            values.ID = this.state.selectedKeysC[0]
            axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=32', values).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=31', {
                  ID: this.state.selectedKeysC[0],
                  pageSize: this.state.pageSize,
                  pageIndex: 1
                }).then(data => {
                  var str = data.data
                  //console.log('测点列表', conversion(data))
                  this.setState({
                    dataSourceA: conversion(data),
                    dataSourceAfu: conversion(data),
                    pageIndexA: 1,
                    dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
                    visibledraw: false
                  }, function () {
                    this.props.form.resetFields();
                  })
                })
              } else if (typeof data.data=="string" &&data.data.indexOf('err') > -1) {
                message.error('测点号已存在！')
              }
            })
          }

        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "测点") {
            values.ID = this.state.rowIdA
            axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=33', values).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=31', {
                  ID: this.state.selectedKeysC[0],
                  pageSize: this.state.pageSize,
                  pageIndex: 1
                }).then(data => {
                  var str = data.data
                  //console.log('测点列表', conversion(data))
                  this.setState({
                    dataSourceA: conversion(data),
                    dataSourceAfu: conversion(data),
                    pageIndexA: 1,
                    dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
                    visibledraw: false
                  }, function () {
                    this.props.form.resetFields();
                  })
                })
              } else if (typeof data.data=="string" &&data.data.indexOf('err') > -1) {
                message.error('测点号已存在！')
              }
            })
          }
        }
      }
    });
  };
  onClose() {
    this.setState({
      visibledraw: false,
      formiptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  handleDel(key) {
    var _this = this
    if (key == "测点") {
      if (this.state.rowIdA) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=34', {
              ID: _this.state.rowIdA
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=31', {
                  ID: _this.state.selectedKeysC[0],
                  pageSize: _this.state.pageSize,
                  pageIndex: 1
                }).then(data => {
                  var str = data.data
                  //console.log('测点列表', conversion(data))
                  _this.setState({
                    dataSourceA: conversion(data),
                    dataSourceAfu: conversion(data),
                    pageIndexA: 1,
                    dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
                    rowIdA: ''
                  }, function () {
                    message.success('删除成功！')
                  })
                })
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistIdexam: ''
            })
          },
        });

      } else {
        message.error('请选择!')
      }
    }
  }
  //测点
  senddataA(record) {
    //console.log(record)
    this.setState({
      rowIdA: record.ID,
      rowIdAobj: record
    })
  }

  setRowClassNameA = (record) => {
    return record.ID === this.state.rowIdA ? 'clickRowStyl' : 'clickRowSty';
  }



  onTreeSelectC(selectedKeys, info) {
    //console.log(selectedKeys, info)
    this.setState({
      selectedKeysC: selectedKeys,
      treeselecpath: info.node.props.treepath,
      counflag: true
    }, function () {
      axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=31', {
        ID: this.state.selectedKeysC[0],
        pageSize: this.state.pageSize,
        pageIndex: 1
      }).then(data => {
        var str = data.data
        //console.log('测点列表',str, conversion(data))
        this.setState({
          dataSourceA: conversion(data),
          dataSourceAfu: conversion(data),
          pageIndexA: 1,
          dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
          counflag: false
        })
      })
    })
  }
  onTreeSelectCC(selectedKeys, info) {
    //console.log(selectedKeys, info)
    this.setState({
      selectedKeysCC: selectedKeys
    })
  }


  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }

  handleDrag(e, ui) {
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }

  //测点分配
  cedianassign() {
    if (this.state.selectedRowKeysstr == "" && this.state.rowIdA == "") {
      message.error('请选择测点！')
    } else {
      axios.get('/DATA/ParaSet/ImportTestId.aspx?flag=36').then(data => {
        // //console.log('测点树', conversion(data))
        var attrC = this.state.attrA
        let arr = this.toTreeDataA(conversion(data), attrC);
        // //console.log('测点树', arr);
        this.setState({
          treeDataCC: arr
        }, function () {
          //console.log(this.state.treeDataCC, '测点分配树')
          document.querySelector('.cediantreeseledata').style.display = "block"
        })
      })
    }
  }
  //分配确定
  seledataadd() {
    axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=35', {
      ids: this.state.selectedRowKeysstr ? this.state.selectedRowKeysstr : this.state.rowIdA,
      tid: this.state.selectedKeysCC[0]
    }).then(data => {
      //console.log(data)
      if (data.data == true) {
        axios.post('/DATA/ParaSet/ImportTestId.aspx?flag=31', {
          ID: this.state.selectedKeysC[0],
          pageSize: this.state.pageSize,
          pageIndex: 1
        }).then(data => {
          var str = data.data
          this.setState({
            dataSourceA: conversion(data),
            pageIndexA: 1,
            dataSourceAfu: conversion(data),
            selectedRowKeys: [],
            selectedRowKeysstr: '',
            dataSourceAlength: Number(str.substring(12, str.indexOf(','))),
          }, function () {
            message.success('分配成功！')
            document.querySelector('.cediantreeseledata').style.display = "none"
          })
        })
      }
    })
  }
  //分配取消
  seledataaddcancel() {
    document.querySelector('.cediantreeseledata').style.display = "none"
  }

  render() {
    const { selectedRowKeys } = this.state;

    const { getFieldDecorator } = this.props.form;
    const loopA = data =>
      data.map(item => {
        if (item.children) {
          return (
            <TreeNode key={item.ID} treepath={item.treepath} title={item.Code + '-' + item.Name} leaf={item.Leaf} Rank={item.Rank} ReadName={item.Name} CodeName={item.Code}>
              {loopA(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} treepath={item.treepath} title={item.Code + '-' + item.Name} leaf={item.Leaf} Rank={item.Rank} ReadName={item.Name} CodeName={item.Code} />;
      });
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        //console.log(selectedRowKeys, selectedRows)
        var arr = selectedRows
        var str = ''
        for (let i = 0; i < arr.length; i++) {
          if (i == arr.length - 1) {
            str = str + arr[i].id
          } else {
            str = str + arr[i].id + ','
          }
        }
        this.setState({
          selectedRowKeys: selectedRowKeys,
          selectedRowKeysstr: str
        })
      },
    };
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div className="cedianconfig" style={{ height: clientHeight - 50 + 'px' }}>
        <Drawer
          title={this.state.addedit + '' + this.state.addeditname}
          onClose={this.onClose.bind(this)}
          visible={this.state.visibledraw}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            {
              this.state.addeditname == "测点" ?
                <Form className="cedianform" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="测点号">
                        {getFieldDecorator('PointNumber', {
                          initialValue: this.state.formiptobj["PointNumber"],
                          rules: [{ required: true, message: '请输入测点号' }],
                        })(
                          <InputNumber />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="索引">
                        {getFieldDecorator('Indexes', {
                          initialValue: this.state.formiptobj["Indexes"],
                          rules: [{ required: true, message: '请输入索引' }],
                        })(
                          <InputNumber />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="名称">
                        {getFieldDecorator('Name', {
                          initialValue: this.state.formiptobj["Name"],
                          rules: [{ required: true, message: '请输入名称' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="数据类型">
                        {getFieldDecorator('DataType', {
                          initialValue: this.state.formiptobj["DataType"],
                          rules: [{ required: true, message: '请输入数据类型' }],
                        })(
                          <Select>
                            <Option value="float">float</Option>
                            <Option value="double">double</Option>
                            <Option value="short">short</Option>
                            <Option value="int">int</Option>
                            <Option value="long">long</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="单位">
                        {getFieldDecorator('Unit', {
                          initialValue: this.state.formiptobj["Unit"],
                          rules: [{ required: true, message: '请输入单位' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="小数位数">
                        {getFieldDecorator('DecimalDigit', {
                          initialValue: this.state.formiptobj["DecimalDigit"],
                          rules: [{ required: true, message: '请输入小数位数' }],
                        })(
                          <InputNumber min={0} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="量程上限">
                        {getFieldDecorator('MeasurementUL', {
                          initialValue: this.state.formiptobj["MeasurementUL"],
                          rules: [{ required: true, message: '请输入量程上限' }],
                        })(
                          <InputNumber />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="量程下限">
                        {getFieldDecorator('MmeasurementLL', {
                          initialValue: this.state.formiptobj["MmeasurementLL"],
                          rules: [{ required: true, message: '请输入量程下限' }],
                        })(
                          <InputNumber />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="KKS">
                        {getFieldDecorator('KKS', {
                          initialValue: this.state.formiptobj["KKS"],
                          rules: [{ required: true, message: '请输入KKS' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="textipt">
                    <Col span={12}>
                      <Form.Item label="描述">
                        {getFieldDecorator('Describe', {
                          initialValue: this.state.formiptobj["Describe"],
                          rules: [{ required: true, message: '请输入描述' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                   </Button>
                    </Form.Item>
                  </Row>
                </Form>
                : ""
            }
          </div>
        </Drawer>
        <div className="cediancontent">
          <Draggable
            position={this.state.controlledPosition}
            onStart={this.handleStart.bind(this)}
            onDrag={this.handleDrag.bind(this)}
            onStop={this.handleStop.bind(this)}>
            <div className="cediantreeseledata">
              <h2>重新分配组</h2>
              <DirectoryTree
                multiple
                selectedKeys={this.state.selectedKeysCC}
                onSelect={this.onTreeSelectCC.bind(this)}
              >
                {loopA(this.state.treeDataCC)}
              </DirectoryTree>
              <div className="action">
                <Button onClick={this.seledataadd.bind(this)}>确定</Button>
                <Button onClick={this.seledataaddcancel.bind(this)}>取消</Button>
              </div>
            </div>
          </Draggable>
          <div className="cedianlist">
            <div className="cediantree">
              <h2 className="h2title">设备列表</h2>
              <div className="cediantreedata" style={{ height: clientHeight - 50 - 40 + 'px' }}>
                <DirectoryTree
                  multiple
                  selectedKeys={this.state.selectedKeysC}
                  onSelect={this.onTreeSelectC.bind(this)}
                >
                  {loopA(this.state.treeDataC)}
                </DirectoryTree>
              </div>
            </div>
            <div className="cediandetail">
              <h2 className="h2title">测点详情</h2>
              <div className="listsearch">
                <div className="listsearchl">
                  <span className="listsearchls">
                    <span className="listsearchcondition">测点名 </span>
                    <span className="searchcondition">
                      <Search
                        id="UserSearch"
                        placeholder="(测点号)"
                        onSearch={(value) => {
                          //console.log(value)
                          var arr = this.state.dataSourceAfu
                          var newarr = []
                          for (let i = 0; i < arr.length; i++) {
                            if (arr[i].PointNumber.indexOf(value) > -1) {
                              newarr.push(arr[i])
                            }
                          }
                          this.setState({
                            dataSourceA: newarr
                          })
                        }}
                      />
                    </span>
                  </span>
                </div>

                <div className="actionbutton">
                  {
                    this.state.treeselecpath.indexOf('未分配') > -1 ?
                      <Button style={{ display: this.state.buttonarr.indexOf('分配') > -1 ? "inline-block" : "none" }} onClick={this.cedianassign.bind(this)}>分配</Button>
                      : <Button style={{ display: this.state.buttonarr.indexOf('分配') > -1 ? "inline-block" : "none" }} disabled >分配</Button>
                  }
                  <Button style={{ display: this.state.buttonarr.indexOf('添加') > -1 ? "inline-block" : "none" }} onClick={this.handleAdd.bind(this, '测点')}>添加</Button>
                  <Button style={{ display: this.state.buttonarr.indexOf('编辑') > -1 ? "inline-block" : "none" }} onClick={this.handleEdit.bind(this, '测点')}>编辑</Button>
                  <Button style={{ display: this.state.buttonarr.indexOf('删除') > -1 ? "inline-block" : "none" }} onClick={this.handleDel.bind(this, '测点')}>删除</Button>
                </div>
              </div>
              <div className="listscrollcheckbox"
                style={{ height: clientHeight - 50 - 80 + 'px' }}>
                <Table
                  size="small"
                  rowSelection={rowSelection}
                  dataSource={this.state.dataSourceA}
                  columns={this.columnsA}
                  onRow={(record) => {
                    return {
                      onClick: this.senddataA.bind(this, record)
                    }
                  }}
                  rowClassName={this.setRowClassNameA}
                  scroll={{ y: clientHeight - 50 - 80 - 50 }}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
      </div>
    )
  }
}
const ConfigManagementapp = Form.create()(ConfigManagement);
export default ConfigManagementapp;