 //KKS树
 function run(treeArrs, resData, atr) {
   // ////console.log(attr)
   var attr = atr
   var resData = resData
   // ////console.log(treeArrs,resData)
   if (resData.length > 0) {
     for (let i = 0; i < treeArrs.length; i++) {
       for (let j = 0; j < resData.length; j++) {
         if (treeArrs[i].KKS === resData[j][attr.ParentId]) {
           let obj = {
             KKS: resData[j][attr.KKS],
             ReadName: resData[j][attr.ReadName],
             ParentId: resData[j][attr.ParentId],
             Name: resData[j][attr.Name],
             children: [],
           };
           treeArrs[i].children.push(obj);
           resData.splice(j, 1);
           j--;
         }
       }
       run(treeArrs[i].children, resData, attr);
     }
   }
 }
 export function toKKSTreeData(data, atr) {
    let tree = [];
    let resData = data;
    let attr = atr
    for (let i = 0; i < resData.length; i++) {
      if (resData[i].ParentId === attr.rootId) {
        let obj = {
          KKS: resData[i][attr.KKS],
          ReadName: resData[i][attr.ReadName],
          Name: resData[i][attr.Name],
          ParentId: resData[i][attr.ParentId],
          children: [],
        };
        tree.push(obj);
        resData.splice(i, 1);
        i--;
      }
    }
    run(tree, resData, attr);
    return tree;
  }