
  function test (arr ){
    var arr = arr
    if(arr.length>0){
      for(let i=0;i<arr.length;i++){
        if(arr[i].children){
          if(arr[i].children.length>0){
            test(arr[i].children)
          }else{
            delete arr[i].children
          }
        }
      }
    }
  }
  export function testfirst (arr){
    test(arr)
    return arr
  }