import React from 'react';
import { Input, message, Button, Select, Radio, Drawer, Form, Col, Row, Table, Tooltip, DatePicker, Modal, TimePicker } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import './style.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { onCellf } from '../../comfunc'
import store from '../../store';
import { getButtonLimit } from '../../utils/getbuttonlimit'
moment.locale('zh-cn')
const Option = Select.Option;
const { TextArea, Search } = Input
const confirm = Modal.confirm

class TicketModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      uname: '',

      iptobj: {},
      addedit: "添加",

      data: [],
      datafu: [],
      rowIdA: '',
      rowIdAobj: {},

      pageSize: parseInt(parseFloat(store.getState().clientHeight  - 50 - 40 - 30 - 50) / 28),
      pageIndex1: 1,
    }
    //
    this.columns = [{
      title: '序号',
      dataIndex: 'xu',
      key: 'xu',
      width: '10%',
      render: (text, record, index) => {
        return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
      }
    },
    {
      title: '票名称',
      dataIndex: 'TicketTypeName',
      key: 'TicketTypeName',
      width: '45%',
    },
    {
      title: '票类别',
      dataIndex: 'TicketFlag',
      key: 'TicketFlag',
      width: '45%'
    },
   ]
    this.renderf = this.renderf.bind(this)
    this.axiosgetdata = this.axiosgetdata.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
    }
  }

  axiosgetdata(){
    axios.get('/Data/OM/OM_TicketType.aspx?flag=1').then(data=>{
      // console.log(data)
      if(data.data.success == true){
        this.setState({
          data:data.data.data,
          datafu:data.data.data
        })
      }else{
        message.error(data.data.describe)
      }
    })
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(55)

    //日历
    var uname = sessionStorage.getItem('uname')
    this.setState({
      uname
    })
    this.axiosgetdata()
  }
  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt(parseFloat(store.getState().clientHeight- 50 - 40 - 30 - 50) / 28),
    })
  }
  //选中
  senddata(record) {
    this.setState({
      rowIdA: record.id,
      rowIdAobj: record,
    })
  }
  setRowClassName = (record) => {
    return record.id === this.state.rowIdA ? 'clickRowStyl' : 'clickRowSty';
  }

  //添加
  handleAdd() {
    this.setState({
      addedit: "添加",
      iptobj: {},
      visible: true,
    }, function () {
      this.props.form.resetFields()
    })
  }
  //编辑
  handleEdit() {
    if (this.state.rowIdA) {
      var obj = this.state.rowIdAobj
      // obj.TicketFlag = obj.TicketFlag == "1" ?"工作票" :obj.TicketFlag =="2"?"操作票":"其它票"
      this.setState({
        addedit: "编辑",
        iptobj: obj,
        visible: true,
      })
    } else {
      message.error('请选择！')
    }
  }
  //保存
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          }
        }

        if(this.state.addedit=="添加"){
          axios.post('/Data/OM/OM_TicketType.aspx?flag=2',values).then(data=>{
            if(data.data.success){
              this.axiosgetdata()
              this.setState({
                visible:false
              })
            }
          })
        }else if(this.state.addedit=="编辑"){
          values.id = this.state.rowIdA
          axios.post('/Data/OM/OM_TicketType.aspx?flag=3',values).then(data=>{
            if(data.data.success){
              this.axiosgetdata()
              this.setState({
                visible:false
              })
            }
          })
        }
      }
    });
  }
  //删除
  handleDel() {
    var _this = this
    if (this.state.rowIdA) {
      var key = this.state.rowIdA
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/Data/OM/OM_TicketType.aspx?flag=4', {
            id: _this.state.rowIdA
          }).then(data => {
            //console.log(data)
            if(data.data.success) {
              const dataSource = [..._this.state.data];
              _this.setState({ data: dataSource.filter(item => item.id !== key), rowIdAobj: {}, rowIdA: "" }, function () {
                message.success('删除成功！')
              });
            }
          })
        },
        onCancel() {
          _this.setState({
            // detaillistId: ''
          })
        },
      });
    } else {
      message.error('请选择！')
    }
  }
  onClose = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      iptobj: {},
      rowIdA: '',
      rowIdAobj: {}
    })
  }
  pagechange(page) {
    this.setState({
      pageIndex1: page.current
    })
  }

  //时间转为HH:mm:ss
  timetostandard(str) {
    var arr = str.split(":")
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].length == 1) {
        arr[i] = '0' + arr[i]
      }
    }
    var newstr = arr.join(':')
    return newstr
  }
  render() {
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    const { getFieldDecorator } = this.props.form;
    return (
      <section className="Inspectiontask" style={{ height: clientHeight - 50 + 'px' }}>
        <div className="Inspectiontaskcon">
          {/* <h2 className="h2title">两票模板</h2> */}
          <span className="action">
            <span>模板名称：<Search onSearch={(v) => {
              var arr = this.state.datafu
              var newarr = []
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].TicketTypeName.indexOf(v) > -1) {
                  newarr.push(arr[i])
                }
              }
              this.setState({
                data: newarr
              })
            }} /></span>
            <span className="actionright">
              <Button style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this)}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this)}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this)}>删除</Button>
            </span>
          </span>
          <div className="listpage" style={{ height: clientHeight - 50 - 40 }}>
            <Table
              size="small"
              dataSource={this.state.data}
              columns={this.columns}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndex,
                total: this.state.datalength
              }}
              onChange={this.pagechange.bind(this)}
              onRow={(record) => {
                return {
                  onClick: this.senddata.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassName}
            />
          </div>
        </div>

        <Drawer
          title={'票' + this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55 + 'px' }}>
            <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
            // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="票名称">
                    {getFieldDecorator('TicketTypeName', {
                      initialValue: this.state.iptobj["TicketTypeName"],
                      rules: [{ required: true, message: '此字段不能为空！' }],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="票类别">
                    {getFieldDecorator('TicketFlag', {
                      initialValue: this.state.iptobj["TicketFlag"],
                      rules: [{ required: true, message: '此字段不能为空！' }],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="formbutton">
                <Form.Item>
                  <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                    保存
                      </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </Drawer>

      </section>
    )
  }
}
const TicketModuleapp = Form.create()(TicketModule);
export default TicketModuleapp;