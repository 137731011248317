import React, { Component, Fragment } from 'react';
import { Select, Input, Button, Radio, Tree, Table, Modal,Drawer, Popconfirm, Form, Row, Col, message,Tooltip } from 'antd';
import 'antd/dist/antd.css';
//多条件搜索
import $, { timers } from 'jquery'
import moment from 'moment';
import axios from 'axios';
import './style.scss'
import { Resizable } from 'react-resizable';
import Search from 'antd/lib/input/Search';
import store from '../../../store';
import { conversion } from '../../../converse';
import { toDictionaryTreeData } from '../../../utils/menutree'
import { getButtonLimit } from '../../../utils/getbuttonlimit'
import { onCellf } from '../../../comfunc'
const { Option } = Select;
const { TextArea } = Input
const { TreeNode } = Tree;
const { confirm } = Modal;
class DictionaryManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      iptobj: {},
      addeditflag: "",
      visible: false,

      data: [],
      datalength: 0,

      rowId: "",
      rowObj: {},
      attr: {
        ID: 'ID',
        rootId: '0',
        Name: 'Name',
        Type: 'Type',
        Num: 'Num',
        Code: 'Code',
        Remark: 'Remark'
      },

      treeData: [],
      selectedKeys:[],
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
    }
    this.columns = [
      {
        dataIndex: 'XuHao',
        key: 'XuHao',
        title: '序号',
        render: (text, record, index) => {
          return (<span>{index + 1}</span>)
        },
        width: 80,
      },
      {
        dataIndex: 'Name',
        key: 'Name',
        title: '中文名称',
        width: 140,onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        
      },
      {
        dataIndex: 'Code',
        key: 'Code',
        title: '英文名称',
        width: 140,onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        
      },
      {
        dataIndex: 'ParaValue',
        key: 'ParaValue',
        title: '参数值',
        width: 140,onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        
      },
      {
        dataIndex: 'ParaType',
        key: 'ParaType',
        title: '参数类型',
        width: 140,
      },
      {
        dataIndex: 'Remark',
        key: 'Remark',
        title: '描述',
        //width: 100,
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        
      }, {
        dataIndex: 'operation',
        key: 'operation',
        title: '操作',
        width: 150,
        onCell: onCellf,
        render: (text, record, index) => {
          if (this.state.data.length > 0) {
            return (<div className="listoperationa">
              {/* <a onClick={this.viewInfo.bind(this, record)}>查看</a> */}
              <a  style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.editModal.bind(this, record)}>编辑</a>
              <a  style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.toDellist.bind(this, record)}>删除</a>
              <a style={{ display: index == 0 || this.state.buttonarr.indexOf('上移') == -1 ? "none" : "inline-block" }} onClick={this.upShift.bind(this, record)}>上移</a>
              <a style={{ display: index == Number(this.state.data.length) - 1 || this.state.buttonarr.indexOf('下移') == -1 ? "none" : "inline-block" }} onClick={this.downShift.bind(this, record)}>下移</a>
            </div>)
          } else {
            return null;
          }
        }
      }
    ]
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(29)

    this.getListData(0)
  }
  upShift(record) {
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=4', {
      Type:this.state.selectedKeys[0],
      ID:record.ID
    }).then(res=>{
      if(res.data==true || res.data.success){
        this.getListData(this.state.selectedKeys[0])
        message.success('操作成功！')
      }
    })
  }
  downShift(record) {
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=5', {
      Type:this.state.selectedKeys[0],
      ID:record.ID
    }).then(res=>{
      if(res.data==true || res.data.success){
        this.getListData(this.state.selectedKeys[0])
        message.success('操作成功！')
      }
    })
  }

  getListData(key) {
    axios.post('/DATA/WebSet/DataDictionary.aspx?flag=3', {
      type: key
    }).then(res => {
      console.log('菜单树', res.data.data)
      var arr = res.data.data
      if(key==0){
        var newarr = toDictionaryTreeData(arr, this.state.attr)
        console.log(newarr)
        if(this.state.selectedKeys[0]){
          this.getListData(this.state.selectedKeys[0])
        }else{
          if(newarr.length>0){
            this.getListData(newarr[0].ID)
            this.setState({
              selectedKeys:[newarr[0].ID]
            })
          }
        }
        this.setState({
          treeData: newarr
        })
      }else{
        this.setState({
          data:arr
        })
      }
    })
  }
  shouldComponentUpdate() {
    return true;
  }

  //删除
  toDellist(record) {
    var _this = this
    confirm({
      title: '确定要删除吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post(`/DATA/WebSet/DataDictionary.aspx?flag=2`,{
          ID:record.ID
        }).then((res) => {
          console.log(res)
          if (res.data == true || res.data.success) {
            _this.getListData(_this.state.selectedKeys[0])
            _this.getListData(0)
            _this.setState({
              rowId: "",
              rowObj: {}
            })
            message.success('删除成功！')
          }
        })
      },
      onCancel() {

      },
    });
  }
  viewInfo(record) {
    console.log(record)
    this.setState({
      visible: true,
      hasclick: false,
      addeditflag: "查看",
      iptobj: record,
    }, function () {
      this.props.form.resetFields();
    })
  }
  //编辑
  editModal(record) {
    console.log(record)
    this.setState({
      visible: true,
      hasclick: false,
      addeditflag: "编辑",
      iptobj: record,
    }, function () {
      this.props.form.resetFields();
    })
  }
  //增加
  addModal() {
    if(this.state.selectedKeys[0]){
      var obj = {}
      this.setState({
        visible: true,
        addeditflag: "新增",
        iptobj: obj
      }, function () {
        this.props.form.resetFields();
      })
    }else{
      message.error('请选择！')
    }
  }
  //提交
  modalSave = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          }else {
            values[i] = values[i]
          }
        }
        if (this.state.addeditflag == "编辑") {
          values.ID = this.state.iptobj.ID
        } else if (this.state.addeditflag == "新增") {
          values.ID ="0"
        }
        values.Type = this.state.selectedKeys[0]
        axios.post(`/DATA/WebSet/DataDictionary.aspx?flag=1`, values).then((res) => {
          console.log(res)
          if (res.data==true || res.data.success) {
            this.setState({
              visible: false
            }, function () {
              this.getListData(this.state.selectedKeys[0])
              this.getListData(0)
              message.success('保存成功！')
            })
          }else if(typeof res.data == "string" && res.data.indexOf('err')>-1){
            message.error('此参数名称已存在！')
          }
        })
      }
    })
  }
  //重置
  modalReset() {
    this.setState({
      iptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  //取消
  modalCancel() {
    this.setState({
      visible: false
    })
  }

  senddata(record) {
    if (this.state.rowId == record.ID) {
      this.setState({
        rowId: "",
        rowObj: {},
      })
    } else {
      this.setState({
        rowId: record.ID,
        rowObj: record,
      })
    }
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }

  onTreeSelect(selectedKeys, info) {
    console.log(selectedKeys, info)
    this.getListData(selectedKeys[0])
    this.setState({
      selectedKeys
    })
  }

  findsearchvalue(value, arr, hasarr) {
    var value = value
    var arr = arr
    var hasarr = hasarr
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].Name.indexOf(value) > -1) {
        hasarr.push(Number(arr[i].ID))
      } else {

      }
      if (arr[i].children.length > 0) {
        this.findsearchvalue(value, arr[i].children, hasarr)
      }
    }
  }
  findsearchvaluefirst(value, arr) {
    // console.log(value,arr)
    var hasarr = []
    var arr = arr
    var value = value
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].Name.indexOf(value) > -1) {
        hasarr.push(Number(arr[i].ID))
        if (arr[i].children.length > 0) {
          this.findsearchvalue(value, arr[i].children, hasarr)
        }
      } else {
        if (arr[i].children.length > 0) {
          this.findsearchvalue(value, arr[i].children, hasarr)
        }
      }
    }
    return hasarr;
  }
  onChange = e => {
    const { value } = e.target;
    if (value == "") {
      this.setState({
        expandedKeys: [],
        searchValue: value,
        autoExpandParent: false,
      });
    } else {
      var arr = this.state.treeData
      var newarr = this.findsearchvaluefirst(value, arr)
      // console.log(newarr)
      this.setState({
        expandedKeys: newarr,
        searchValue: value,
        autoExpandParent: true,
      });
    }
  };
  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  onClose() {
    this.setState({
      visible: false,
      iptobj: {}
    })
  }
  render() {
    const { searchValue, expandedKeys, autoExpandParent } = this.state;
    const { getFieldDecorator } = this.props.form;
    const clientH = store.getState().clientHeight
    const loop = data =>
      data.map(item => {
        const index = item.Name.indexOf(searchValue);
        const beforeStr = item.Name.substr(0, index);
        const afterStr = item.Name.substr(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
              <span>{item.Name}</span>
            );
        if (item.children) {
          // return { title, key: item.id, children: loop(item.children) , type:item.type};
          return (
            <TreeNode key={item.ID} title={title} Type={item.Type}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} title={title} Type={item.Type} />
      });
    return (
      <Fragment>
        <div className="dictionarymanage">
          <Drawer
            title={this.state.addeditflag}
            onClose={this.onClose.bind(this)}
            visible={this.state.visible}
            className="Drawercommon"
          >
            <div className="Drawercommonaddedit" style={{ height: clientH - 50 - 55 + 'px' }}>
              <Form className="rolemanageform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="参数名称(中文)">
                      {getFieldDecorator('Name', {
                        initialValue: this.state.iptobj["Name"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="参数名称(英文)">
                      {getFieldDecorator('Code', {
                        initialValue: this.state.iptobj["Code"],
                        rules: [{ required: false, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="参数类型">
                      {getFieldDecorator('ParaType', {
                        initialValue: this.state.iptobj["ParaType"],
                        rules: [{ required: false, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="参数值">
                      {getFieldDecorator('ParaValue', {
                        initialValue: this.state.iptobj["ParaValue"],
                        rules: [{ required: false, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="textarea4">
                  <Col span={12} className="formneccessary">
                    <Form.Item label="描述">
                      {getFieldDecorator('Remark', {
                        initialValue: this.state.iptobj["Remark"],
                        rules: [{ required: false, message: () => { return (<span></span>) } }],
                      })(
                        <TextArea rows={4} disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="formbutton">
                  <Form.Item>
                    <Button htmlType="submit" onClick={this.modalSave.bind(this)} type="primary">
                      保存
                    </Button>
                  </Form.Item>
                </Row>
              </Form>

            </div>
          </Drawer>
          <div className="listsearch">
            <div className="listsearchl">
              <Search placeholder="请输入搜索内容" onChange={this.onChange.bind(this)} />
            </div>
            <div className="listactionr">
              {/* <Button className="sameaction"><span className="listuploadtu"></span>上传</Button>
              <Button className="sameaction"><span className="listsubtu"></span>提交</Button>
              <Button className="sameaction"><span className="listprinttu"></span>打印</Button> */}
              <Button className="addaction"  style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.addModal.bind(this)}><span className="listaddtu"></span>添加</Button>
            </div>
          </div>
          <div className="dictionarymanagecon" style={{height:clientH-50 -40+'px'}}>
            <div className="dictionarymanagel" style={{height:clientH-50 -40-10+'px'}}>
              <Tree
                onSelect={this.onTreeSelect.bind(this)}
                selectedKeys={this.state.selectedKeys}
                onExpand={this.onExpand.bind(this)}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
              >

                {loop(this.state.treeData)}
              </Tree>
            </div>
            <div className="listscroll" style={{ height: clientH - 50 - 40 - 10 + 'px' }}>
              <Table
                rowKey={record => record.ID}
                columns={this.columns}
                dataSource={this.state.data}
                size="small"
                scroll={{ y: clientH - 50 - 40 - 10 - 40 }}
                pagination={false}
                // onRow={(record) => {
                //   return {
                //     onClick: this.senddata.bind(this, record)
                //   }
                // }}
                // rowClassName={this.setRowClassName}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const DictionaryManageapp = Form.create()(DictionaryManage)
export default DictionaryManageapp;