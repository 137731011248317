import React, { Component, Fragment } from 'react';
import { Select, Input, Button, Radio, Tree, Table, Modal,Drawer, Popconfirm, Form, Row, Col, message, Tooltip } from 'antd';
import 'antd/dist/antd.css';
//多条件搜索
import $ from 'jquery'
import moment from 'moment';
import axios from 'axios';
import './style.scss'
import { Resizable } from 'react-resizable';
import Search from 'antd/lib/input/Search';
import store from '../../../store';
import { conversion } from '../../../converse';
import { toMenuTreeData } from '../../../utils/menutree'
import { onCellf } from '../../../comfunc'
import { getButtonLimit } from '../../../utils/getbuttonlimit'
const { Option } = Select;
const { TextArea } = Input
const { TreeNode } = Tree;
const { confirm } = Modal;
class RoleManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      iptobj: {},
      addeditflag: "",
      visible: false,

      pageSize:parseInt((store.getState().clientHeight - 50 - 50 - 30 - 30 )/28),
      data: [],
      datafu:[],
      datatotal: 0,
      pageIndex: 1,

      data1:[],
      data1total:[],
      pageIndex1:1,

      data2:[],
      data2total:[],
      pageIndex2:1,

      selectedRowKeys1:[],
      selectedRowKeys2:[],

      rowId: "",
      rowObj: {},
    }
    this.columns = [
      {
        title: '序号', width: 60, dataIndex: 'xu', key: 'xu',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      { title: '角色名称', dataIndex: 'RoleName', key: 'RoleName', },
      {
        title: '角色描述', dataIndex: 'RoleRemark', key: 'RoleRemark',
        onCell: onCellf,
        render: (text) => <Tooltip placement="left" title={text}>{text}</Tooltip>
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        title: '操作',
        width: 120,
        render: (text, record) =>
          this.state.data.length >= 1 ? (
            <div className="listoperationa">
              <a style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.editModal.bind(this, record)}>编辑</a>
              <a style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={() => this.toDellist(record)}>删除</a>
            </div>
          ) : null,
      }
    ]
    this.columnsper = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '15%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '用户姓名',
        dataIndex: 'UserName',
        key: 'UserName',
        width: '30%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '部门',
        dataIndex: 'Department',
        key: 'Department',
        width: '45%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        // render: (text) => {
        //   var arr = this.state.Departmentinitarr
        //   for (let i = 0; i < arr.length; i++) {
        //     if (text == arr[i].id) {
        //       return (<span>{arr[i].dept}</span>)
        //     }
        //   }
        // }
      }
    ]
    this.columnsall = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '15%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex2 - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '用户姓名',
        dataIndex: 'UserName',
        key: 'UserName',
        width: '30%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
      },
      {
        title: '部门',
        dataIndex: 'Department',
        key: 'Department',
        width: '45%',
        onCell: onCellf,
        render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>
        // render: (text) => {
        //   var arr = this.state.Departmentinitarr
        //   for (let i = 0; i < arr.length; i++) {
        //     if (text == arr[i].id) {
        //       return (<span>{arr[i].dept}</span>)
        //     }
        //   }
        // }
      }
    ]
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(28)
    this.getListData()
  }
  getListData() {
    axios.get('/DATA/WebSet/UserRole.aspx?flag=1').then(res => {
      console.log('角色管理', res, conversion(res))
      this.getRoleUser(conversion(res)[0].ID)
      this.setState({
        data: conversion(res),
        datafu:conversion(res),
        pageIndex:1,
        rowObj:conversion(res)[0],
        rowId:conversion(res)[0].ID
      })
    })
  }
  shouldComponentUpdate() {
    return true;
  }

  //删除
  toDellist(record) {
    var _this = this
    confirm({
      title: '确定要删除吗?',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        axios.post(`/DATA/WebSet/UserRole.aspx?flag=3`,{
          ID:record.ID
        }).then((res) => {
          console.log(res)
          if (res.data == true || res.data.success) {
            _this.getListData()
            _this.setState({
              rowId: "",
              rowObj: {}
            })
            message.success('删除成功！')
          }
        })
      },
      onCancel() {

      },
    });
  }
  //编辑
  editModal(record) {
    console.log(record)
    this.setState({
      visible: true,
      addeditflag: "编辑",
      iptobj: record,
    }, function () {
      this.props.form.resetFields();
    })
  }
  //增加
  addModal() {
    var obj = {}
    this.setState({
      visible: true,
      addeditflag: "新增",
      iptobj: obj
    }, function () {
      this.props.form.resetFields();
    })
  }
  //提交
  modalSave = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          }else {
            values[i] = values[i]
          }
        }
        if (this.state.addeditflag == "编辑") {
          var flag = 8
          values.ID = this.state.iptobj.ID
        } else if (this.state.addeditflag == "新增") {
          var flag = 2
        }

        axios.post(`/DATA/WebSet/UserRole.aspx?flag=${flag}`, values).then((res) => {
          console.log(res)
          if (res.data==true || res.data.success) {
            this.setState({
              visible: false
            }, function () {
              this.getListData()
              message.success('保存成功！')
            })
          }else if(typeof res.data == "string" && res.data.indexOf('err')>-1){
            message.error('该角色已存在！')
          }
        })
      }
    })
  }
  //重置
  modalReset() {
    this.setState({
      iptobj: {}
    }, function () {
      this.props.form.resetFields();
    })
  }
  //取消
  modalCancel() {
    this.setState({
      visible: false
    })
  }
  
  getRoleUser(ID){
    axios.post(`/DATA/WebSet/UserRole.aspx?flag=4`,{
      ID:ID
    }).then(data=>{
      // console.log(data,conversion(data))
      var arr = conversion(data)
      console.log(arr,arr.length)
      this.setState({
        data1:arr,
        pageIndex1:1,
        selectedRows1:[],
        selectedRowKeys1:[],
        data1total:arr.length
      })
    })
    axios.post(`/DATA/WebSet/UserRole.aspx?flag=7`,{
      ID:ID
    }).then(data=>{
      // console.log(data,conversion(data))
      var arr = conversion(data)
      console.log(arr,arr.length)
      this.setState({
        data2:arr,
        pageIndex2:1,
        selectedRows2:[],
        selectedRowKeys2:[],
        data2total:arr.length
      })
    })
  }

  senddata(record) {
    if (this.state.rowId == record.ID) {
      this.setState({
        rowId: "",
        rowObj: {},
      })
    } else {
      this.setState({
        rowId: record.ID,
        rowObj: record,
      })
      this.getRoleUser(record.ID)
    }
  }

  setRowClassName = (record) => {
    return record.ID === this.state.rowId ? 'clickRowStyl' : 'clickRowSty';
  }

  //移出
  ShiftOut(){
    var _this = this
    if(this.state.selectedRowKeys1.length>0){
      confirm({
        title: '确定要移除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post(`/DATA/WebSet/UserRole.aspx?flag=6`,{
            RoleUserIds:_this.state.selectedRowKeys1.join(',')
          }).then(res=>{
            if(res.data == true || res.data.success){
              _this.getRoleUser(_this.state.rowId)
              message.success('移除成功！')
            }
          })
        },
        onCancel() {

        },
      });
    }else{
      message.error('请选择！')
    }
  }
  //移入
  ShiftIn(){
    var _this = this
    if(this.state.selectedRowKeys2.length>0){
      confirm({
        title: '确定要移入吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post(`/DATA/WebSet/UserRole.aspx?flag=5`,{
            UserIds:_this.state.selectedRowKeys2.join(','),
            RoleId:_this.state.rowId
          }).then(res=>{
            if(res.data == true || res.data.success){
              _this.getRoleUser(_this.state.rowId)
              message.success('移除成功！')
            }
          })
        },
        onCancel() {

        },
      });
    }else{
      message.error('请选择！')
    }
  }
  
  pageChange(key,page, pageSize) {
    console.log(key,page, pageSize)
    if(key=="角色列表"){
      this.setState({
        pageIndex:Number(page.current)
      })
    }else if(key=="角色成员"){
      this.setState({
        pageIndex1:Number(page.current)
      })
    }else if(key=="用户"){
      this.setState({
        pageIndex2:Number(page.current)
      })
    }
  }
  onClose() {
    this.setState({
      visible: false,
      iptobj: {}
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientH = store.getState().clientHeight
    const { selectedRowKeys1, selectedRowKeys2 } = this.state
    const rowSelection1 = {
      selectedRowKeys: selectedRowKeys1,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({
          selectedRowKeys1:selectedRowKeys,
          selectedRows1:selectedRows
        })
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
      },
    };
    const rowSelection2 = {
      selectedRowKeys: selectedRowKeys2,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys, selectedRows)
        this.setState({
          selectedRowKeys2:selectedRowKeys,
          selectedRows2:selectedRows
        })
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
      },
    };
    return (
      <Fragment>
        <div className="rolemanage">
         
         
          <Drawer
            title={'角色' + this.state.addeditflag}
            onClose={this.onClose.bind(this)}
            visible={this.state.visible}
            className="Drawercommon"
          >
            <div className="Drawercommonaddedit" style={{ height: clientH - 50 - 55 + 'px' }}>
            <Form className="rolemanageform" onSubmit={this.modalSave.bind(this)} layout="vertical">
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="角色名称:">
                      {getFieldDecorator('RoleName', {
                        initialValue: this.state.iptobj["RoleName"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <Input disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12} className="formneccessary">
                    <Form.Item label="角色描述:">
                      {getFieldDecorator('RoleRemark', {
                        initialValue: this.state.iptobj["RoleRemark"],
                        rules: [{ required: true, message: () => { return (<span></span>) } }],
                      })(
                        <TextArea rows={6} disabled={this.state.addeditflag == "查看" ? true : false} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} className="formbutton">
                  <Form.Item>
                    <Button htmlType="submit" onClick={this.modalSave.bind(this)} type="primary">
                      保存
                    </Button>
                  </Form.Item>
                </Row>
              </Form>

            </div>
          </Drawer>

          <div className="rolemanagel">
            <div className="listsearch">
              <div className="listsearchl">
                <Search placeholder="请输入搜索内容" 
                 onSearch={(v)=>{
                   var arr = this.state.datafu
                   var newarr = []
                   for(let i=0;i<arr.length;i++){
                     if(arr[i].RoleName.indexOf(v)>-1 || arr[i].RoleRemark.indexOf(v)>-1 ){
                       newarr.push(arr[i])
                     }
                   }
                   this.setState({
                     data:newarr,
                     datatotal:newarr.length,
                     pageIndex:1
                   })
                 }}
                />
              </div>
              <div className="listactionr">
                <Button className="addaction" style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.addModal.bind(this)}><span className="listaddtu"></span>添加</Button>
              </div>
            </div>
            <div className="listpage" style={{ height: clientH - 50 - 40 -30 + 'px' }}>
              <Table
                rowKey={record => record.ID}
                columns={this.columns}
                dataSource={this.state.data}
                size="small"
                pagination={{
                  simple:true,
                  // size:'small',
                  showQuickJumper: true,
                  pageSize: this.state.pageSize,
                  current: this.state.pageIndex,
                  showTotal: (total) => `共 ${total} 条`,
                  total: Number(this.state.datatotal),
                }}
                onChange={this.pageChange.bind(this,'角色列表')}
                onRow={(record) => {
                  return {
                    onClick: this.senddata.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassName}
              />
            </div>

          </div>
          <div className="rolemanagec">
            <div className="listsearch">
              <div className="listsearchl">
                角色成员
              </div>
              <div className="listactionr">
                <Button className="addaction" style={{display:this.state.buttonarr.indexOf('移出')>-1?"inline-block":"none"}} onClick={this.ShiftOut.bind(this)}><span className="listaddtu"></span>移出</Button>
              </div>
            </div>
            <div className="listpage" style={{ height: clientH - 50 - 40 -30 + 'px' }}>
              <Table
                size="small"
                rowKey={record => record.ID}
                rowSelection={rowSelection1}
                dataSource={this.state.data1}
                columns={this.columnsper}
                // scroll={{ y: clientH - 50 - 70 - 30 - 60 }}
                pagination={{
                  simple:true,
                  showQuickJumper: true,
                  pageSize: this.state.pageSize,
                  current: this.state.pageIndex1,
                  showTotal: (total) => `共 ${total} 条`,
                  total: Number(this.state.data1total),
                }}
                onChange={this.pageChange.bind(this,'角色成员')}
              />
            </div>
          </div>
          <div className="rolemanager">
            
            <div className="listsearch">
              <div className="listsearchl">
                用户
              </div>
              <div className="listactionr">
                <Button className="addaction" style={{display:this.state.buttonarr.indexOf('移入')>-1?"inline-block":"none"}} onClick={this.ShiftIn.bind(this)}><span className="listaddtu"></span>移入</Button>
              </div>
            </div>
            <div className="listpage" style={{ height: clientH - 50 - 40 -30 + 'px'  }}>
              <Table
                size="small"
                rowKey={record => record.ID}
                rowSelection={rowSelection2}
                dataSource={this.state.data2}
                columns={this.columnsall}
                // scroll={{ y: clientH - 50 - 70 - 30 - 60 }}
                pagination={{
                  simple:true,
                  showQuickJumper: true,
                  pageSize: this.state.pageSize,
                  current: this.state.pageIndex2,
                  showTotal: (total) => `共 ${total} 条`,
                  total: Number(this.state.data2total),
                }}
                onChange={this.pageChange.bind(this,'用户')}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const RoleManageapp = Form.create()(RoleManage)
export default RoleManageapp;