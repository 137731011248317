export function todatetime(stand){
  var time =  stand.getFullYear() + '-' + (stand.getMonth() + 1) + '-' + stand.getDate() + ' ' +stand.getHours() + ':' +stand.getMinutes() + ':' + stand.getSeconds()
  return time; 
}
function change(date) {  
  var y = date.getFullYear();  
  var m = date.getMonth() + 1;  
  m = m < 10 ? '0' + m : m;  
  var d = date.getDate();  
  d = d < 10 ? ('0' + d) : d;  
  return y + '-' + m + '-' + d;  
} 