import React from 'react';
import { Table, Input, Tree, Tooltip, Form, Button, Select, Tabs, Drawer, Modal, Row, Col, InputNumber, Radio, DatePicker, message } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import { onCellf } from '../../comfunc'
import $ from 'jquery'
import echarts from 'echarts';
import './style.scss'
import moment from 'moment'
// import "moment/locale/zh-cn"
// moment.locale('zh-cn')
import Draggable from 'react-draggable';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { getButtonLimit } from '../../utils/getbuttonlimit'
import store from '../../store';
const { Option } = Select;
const TabPane = Tabs.TabPane;
const { TreeNode, DirectoryTree } = Tree;
const { Search, TextArea } = Input;
const { confirm } = Modal

class WisdomDiagnosisTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],
      //拖拽
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      controlledPositionB: {
        x: 0, y: 0
      },
      //树
      treedataA: [],
      selectedKeysA: [],
      CodeNum: '',//选择软件编号
      FunctionIntroduction: '',//选择软件功能描述
      visible: false,
      addedit: '',
      formiptobj: {},
      detaillistId: '',

      //表
      dataSourcein: [],
      dataSourceout: [],
      dataSourcefu: [],


      activeKey: '1',

      dataSourceinit: [],
    };
    //基础属性 供应商 责任相关 技术参数  文档关联
    this.columnsin = [
      {
        title: '参数名称',
        dataIndex: 'InputName',
        key: 'InputName',
        width: '40%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '单位',
        editable: true,
        dataIndex: 'InputUnit',
        key: 'InputUnit',
        width: '15%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '编码',
        dataIndex: 'InputNum',
        key: 'InputNum',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '类型',
        dataIndex: 'InputType',
        key: 'InputType',
        width: '15%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      }
    ]
    this.columnsout = [
      {
        title: '参数名称',
        dataIndex: 'OutputName',
        key: 'OutputName',
        width: '40%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '单位',
        editable: true,
        dataIndex: 'OutputUnit',
        key: 'OutputUnit',
        width: '15%',
        onCell: onCellf,
        render: this.renderf

      },
      {
        title: '编码',
        dataIndex: 'OutputNum',
        key: 'OutputNum',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '类型',
        dataIndex: 'OutputType',
        key: 'OutputType',
        width: '15%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      }
    ]
    this.columnsfu = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '10%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{index + 1}</span>
        }
      },
      {
        title: '设备编号',
        dataIndex: 'ApplicationScopeCode',
        key: 'ApplicationScopeCode',
        width: '45%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '设备名称',
        editable: true,
        dataIndex: 'ApplicationScopeName',
        key: 'ApplicationScopeName',
        width: '45%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
    ]

    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>)
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(75)

    var service = sessionStorage.getItem('service')
    this.setState({
      service: service
    })
    //计算工具树数据
    axios.get('/DATA/MonitoringAndDiagnosis/CalculationsoftwareTree.aspx?flag=1').then(data => {
      console.log('计算工具树数据', conversion(data))
      this.setState({
        treedataA: conversion(data)
      })
    })

    axios.get('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=1').then(data => {
      console.log('装配列表', conversion(data))
      this.setState({
        dataSourceinit: conversion(data)
      })
    })
  }


  onSelectA(selectedKeys, info) {
    console.log(selectedKeys, info)
    if (selectedKeys[0] == "00") {

    } else {
      this.setState({
        selectedKeysA: selectedKeys,
        CodeNum: info.node.props.CodeNum,
        FunctionIntroduction: info.node.props.FunctionIntroduction
      }, function () {
        //输入
        axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=1', {
          CodeNum: info.node.props.CodeNum
        }).then(data => {
          // console.log(conversion(data))
          this.setState({
            dataSourcein: conversion(data)
          })
        })
        //输出
        axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=2', {
          CodeNum: info.node.props.CodeNum
        }).then(data => {
          // console.log(conversion(data))
          this.setState({
            dataSourceout: conversion(data)
          })
        })
        //适用设备
        axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=7', {
          CodeNum: info.node.props.CodeNum
        }).then(data => {
          // console.log(conversion(data))
          this.setState({
            dataSourcefu: conversion(data)
          })
        })
      })
    }
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  handleDragB(e, ui) {
    const { x, y } = this.state.controlledPositionB;
    this.setState({
      controlledPositionB: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  //
  zhuangpeishushou() {

  }

  //树添加 编辑 删除
  //配置添加删除修改
  handleAdd(key) {
    if (key && key == "计算软件") {
      this.setState({
        addedit: '添加',
        addeditname: key,
        visible: true,
        formiptobj: {},
      }, function () {
        this.props.form.resetFields()
      })
    } else {
      if (this.state.CodeNum) {
        if (this.state.activeKey == "1") {
          this.setState({
            addedit: '添加',
            addeditname: "输入",
            visible: true,
            formiptobj: {},
          }, function () {
            this.props.form.resetFields()
          })
        } else if (this.state.activeKey == "2") {
          this.setState({
            addedit: '添加',
            addeditname: "输出",
            visible: true,
            formiptobj: {},
          }, function () {
            this.props.form.resetFields()
          })
        } else if (this.state.activeKey == "3") {
          this.setState({
            addedit: '添加',
            addeditname: "适用设备",
            visible: true,
            formiptobj: {},
          }, function () {
            this.props.form.resetFields()
          })
        }
      } else {
        message.error('请选择！')
      }
    }
  }
  handleEdit() {
    if (this.state.selectedKeysA[0]) {
      var arr = this.state.treedataA
      var obj = {}
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].ID == this.state.selectedKeysA[0]) {
          obj = arr[i]
        }
      }
      this.setState({
        addedit: '修改',
        visible: true,
        addeditname: '计算软件',
        formiptobj: obj
      },function(){
        this.props.form.resetFields()
      })
    } else {
      message.error('请选择!')
    }
  }

  onClose = () => {
    this.setState({
      visible: false,
      detaillistId: ''
    }, function () {
      this.props.form.resetFields();
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values)
      for (var i in values) {
        var value = values[i]
        if (value == undefined) {
          values[i] = ""
        } else if (value.label) {
          values[i] = value.label
        } else if (value._d) {
          values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
        } else {
          values[i] = values[i]
        }
      }

      if (!err) {
        if (this.state.addedit == "添加") {
          //树添加
          if (this.state.addeditname == "计算软件") {
            axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareTree.aspx?flag=2', values).then(data => {
              console.log(data)
              if (data.data.success) {
                //计算工具树数据
                axios.get('/DATA/MonitoringAndDiagnosis/CalculationsoftwareTree.aspx?flag=1').then(data => {
                  console.log('计算工具树数据', conversion(data))
                  this.setState({
                    treedataA: conversion(data),
                    visible: false
                  }, function () {
                    // message.success('添加成功！')
                  })
                })
              }else if(typeof data.data == "string" && data.data.indexOf('err')>-1){
                message.error('该编码已存在！')
              }
            })
          } else if (this.state.addeditname == "输入") {
            values.CodeNum = this.state.CodeNum
            axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=3', values).then(data => {
              console.log(data)
              if (data.data.success) {
                //输入
                axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=1', {
                  CodeNum: this.state.CodeNum
                }).then(data => {
                  console.log(conversion(data))
                  this.setState({
                    dataSourcein: conversion(data),
                    visible: false
                  }, function () {
                    // message.success('添加成功！')
                  })
                })
              }else if(typeof data.data == "string" && data.data.indexOf('err')>-1){
                message.error('该编码已存在！')
              }
            })
          } else if (this.state.addeditname == "输出") {
            values.CodeNum = this.state.CodeNum
            axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=5', values).then(data => {
              console.log(data)
              if (data.data.success) {
                //输入
                axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=2', {
                  CodeNum: this.state.CodeNum
                }).then(data => {
                  console.log(conversion(data))
                  this.setState({
                    dataSourceout: conversion(data),
                    visible: false
                  }, function () {
                    // message.success('添加成功！')
                  })
                })
              }else if(typeof data.data == "string" && data.data.indexOf('err')>-1){
                message.error('该编码已存在！')
              }
            })
          } else if (this.state.addeditname == "适用设备") {
            values.CodeNum = this.state.CodeNum
            axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=8', values).then(data => {
              console.log(data)
              if (data.data.success) {
                //适用设备
                axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=7', {
                  CodeNum: this.state.CodeNum
                }).then(data => {
                  console.log(conversion(data))
                  this.setState({
                    dataSourcefu: conversion(data),
                    visible: false
                  }, function () {
                    // message.success('添加成功！')
                  })
                })
              }else if(typeof data.data == "string" && data.data.indexOf('err')>-1){
                message.error('该编码已存在！')
              }
            })
          }
        } else if (this.state.addedit == "修改") {
          if (this.state.addeditname == "计算软件") {
            axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareTree.aspx?flag=3', values).then(data => {
              console.log(data)
              if (data.data.success) {
                //计算工具树数据
                axios.get('/DATA/MonitoringAndDiagnosis/CalculationsoftwareTree.aspx?flag=1').then(data => {
                  console.log('计算工具树数据', conversion(data))
                  this.setState({
                    treedataA: conversion(data),
                    visible: false
                  })
                })
              }else if(typeof data.data == "string" && data.data.indexOf('err')>-1){
                message.error('该编码已存在！')
              }
            })
          }
        }
      }
    });
  };

  //树删除
  handleDeltree() {
    var _this = this
    if (this.state.selectedKeysA[0]) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareTree.aspx?flag=4', {
            ID: _this.state.selectedKeysA[0]
          }).then(data => {
            console.log(data)
            if (data.data.success) {
              //计算工具树数据
              axios.get('/DATA/MonitoringAndDiagnosis/CalculationsoftwareTree.aspx?flag=1').then(data => {
                console.log('计算工具树数据', conversion(data))
                _this.setState({
                  treedataA: conversion(data),
                  selectedKeysA: []
                }, function () {
                  message.success('删除成功！')
                })
              })
            }
          })
        },
        onCancel() {
          _this.setState({
            selectedKeysA: []
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }
  handleDellist() {
    var _this = this
    var key = this.state.detaillistId
    if (this.state.detaillistId) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          if (_this.state.activeKey == "1") {
            axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=4', {
              ID: _this.state.detaillistId
            }).then(data => {
              console.log(data)
              if (data.data.success) {
                const dataSource = [..._this.state.dataSourcein];
                _this.setState({ dataSourcein: dataSource.filter(item => item.ID !== key), detaillistId: "" },
                  function () {
                    message.success('删除成功！')
                  });
              }
            })

          } else if (_this.state.activeKey == "2") {
            axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=6', {
              ID: _this.state.detaillistId
            }).then(data => {
              console.log(data)
              if (data.data.success) {
                const dataSource = [..._this.state.dataSourceout];
                _this.setState({ dataSourceout: dataSource.filter(item => item.ID !== key), detaillistId: "" },
                  function () {
                    message.success('删除成功！')
                  });
              }
            })

          } else if (_this.state.activeKey == "3") {
            axios.post('/DATA/MonitoringAndDiagnosis/CalculationsoftwareData.aspx?flag=9', {
              ID: _this.state.detaillistId
            }).then(data => {
              console.log(data)
              if (data.data.success) {
                const dataSource = [..._this.state.dataSourcefu];
                _this.setState({ dataSourcefu: dataSource.filter(item => item.ID !== key), detaillistId: "" },
                  function () {
                    message.success('删除成功！')
                  });
              }
            })

          }
        },
        onCancel() {
          _this.setState({
            detaillistId: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }

  //选中
  senddata(record) {
    console.log(record)
    this.setState({
      detaillistId: record.ID,
    })
  }

  setRowClassName = (record) => {
    return record.ID === this.state.detaillistId ? 'clickRowStyl' : 'clickRowSty';
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="wisdomdiagnosistools" style={{ height: clientHeight - 50 + 'px' }}>
        {/* 树添加 */}
        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="treeaddedit" ref="treeaddedit">

          </div>
        </Draggable>

        {/* 表添加 */}
        <Draggable
          position={this.state.controlledPositionB}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragB.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="listaddedit" ref="listaddedit">

          </div>
        </Draggable>

        <Drawer
          title={this.state.addeditname + '' + this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55+ 'px' }}>
            {
              this.state.addeditname == "计算软件" ?
                <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="编码">
                        {getFieldDecorator('CodeNum', {
                          initialValue: this.state.formiptobj['CodeNum'],
                          rules: [{ required: true, message: '请输入编码' }],
                        })(
                          <Input disabled={this.state.addedit == "修改" ? true : false} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="软件名称">
                        {getFieldDecorator('Name', {
                          initialValue: this.state.formiptobj['Name'],
                          rules: [{ required: true, message: '请输入软件名称' }],
                        })(
                          <Input />,
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="版本号">
                        {getFieldDecorator('VersionNum', {
                          initialValue: this.state.formiptobj['VersionNum'],
                          rules: [{ required: false, message: '请输入版本号' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="发布时间">
                        {getFieldDecorator('ReleaseTime', {
                          initialValue: this.state.formiptobj['ReleaseTime'] ? moment(this.state.formiptobj['ReleaseTime']) : null,
                          rules: [{ required: false, message: '请输入发布时间' }],
                        })(
                          <DatePicker />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16} className="textipt">
                    <Col span={12}>
                      <Form.Item label="功能说明">
                        {getFieldDecorator('FunctionIntroduction', {
                          initialValue: this.state.formiptobj['FunctionIntroduction'],
                          rules: [{ required: false, message: '请输入功能说明' }],
                        })(
                          <TextArea rows={4} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                  </Button>
                    </Form.Item>
                  </Row>
                </Form>
                :
                this.state.addeditname == "输入" ?
                  <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="编码">
                          {getFieldDecorator('InputNum', {
                            rules: [{ required: true, message: '请输入编码' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="参数名称">
                          {getFieldDecorator('InputName', {
                            rules: [{ required: true, message: '请输入参数名称' }],
                          })(
                            <Input />,
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="参数类别">
                          {getFieldDecorator('InputType', {
                            rules: [{ required: true, message: '请输入参数类别' }],
                          })(
                            <Input />,
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="单位">
                          {getFieldDecorator('InputUnit', {
                            rules: [{ required: false, message: '请输入单位' }],
                          })(
                            <Input />,
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
                       </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                  :
                  this.state.addeditname == "输出" ?
                    <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="编码">
                            {getFieldDecorator('OutputNum', {
                              rules: [{ required: true, message: '请输入编码' }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="参数名称">
                            {getFieldDecorator('OutputName', {
                              rules: [{ required: true, message: '请输入参数名称' }],
                            })(
                              <Input />,
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="参数类别">
                            {getFieldDecorator('OutputType', {
                              rules: [{ required: true, message: '请输入参数类别' }],
                            })(
                              <Input />,
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="单位">
                            {getFieldDecorator('OutputUnit', {
                              rules: [{ required: false, message: '请输入发布时间' }],
                            })(
                              <Input />,
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16} className="formbutton">
                        <Form.Item>
                          <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                            保存
                         </Button>
                        </Form.Item>
                      </Row>
                    </Form>
                    :
                    this.state.addeditname == "适用设备" ?
                      <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                      >
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="设备编号">
                              {getFieldDecorator('Code', {
                                rules: [{ required: true, message: '请输入编码' }],
                              })(
                                <Select showSearch onChange={(value) => {
                                  console.log(value)
                                  var arr = this.state.dataSourceinit
                                  var data = ''
                                  for (let i = 0; i < arr.length; i++) {
                                    if (arr[i].Code == value) {
                                      data = arr[i].Name
                                    }
                                  }
                                  this.props.form.setFieldsValue({
                                    Name: data,
                                  });
                                }}>
                                  {
                                    this.state.dataSourceinit.map((item, index) => {
                                      return (<Option value={item.Code} key={item.ID}>{item.Code}</Option>)
                                    })
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="设备名称">
                              {getFieldDecorator('Name', {
                                rules: [{ required: true, message: '请输入参数名称' }],
                              })(
                                <Select showSearch onChange={(value) => {
                                  console.log(value)
                                  var arr = this.state.dataSourceinit
                                  var data = ''
                                  for (let i = 0; i < arr.length; i++) {
                                    if (arr[i].Name == value) {
                                      data = arr[i].Code
                                    }
                                  }
                                  this.props.form.setFieldsValue({
                                    Code: data,
                                  });
                                }}>
                                  {
                                    this.state.dataSourceinit.map((item, index) => {
                                      return (<Option value={item.Name} key={item.ID}>{item.Name}</Option>)
                                    })
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16} className="formbutton">
                          <Form.Item>
                            <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                              保存
                  </Button>
                          </Form.Item>
                        </Row>
                      </Form>
                      : ""
            }
          </div>
        </Drawer>

        <div className="wisdomdiagnosistoolsleft" ref="wisdomdiagnosistoolsleft" style={{ height: clientHeight - 50 + 'px' }}>
          <h2 className="h2title">
            <span>计算工具</span>
            <span onClick={this.zhuangpeishushou.bind(this)} className="zhuangpeishushousuo"></span>
          </h2>
          <div className="wisdomdiagnosistoolstree" style={{ height: clientHeight - 50 - 30 + 'px' }}>
            <span className="treeaction">
              <Button style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '计算软件')}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this)}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.handleDeltree.bind(this)}>删除</Button>
            </span>
            <div className="tree" style={{ height: clientHeight - 50 - 30 - 40 + 'px' }}>
              <Tree
                // checkable
                // onCheck={this.onCheckA.bind(this)}
                // checkedKeys={this.state.checkedKeysA}
                onSelect={this.onSelectA.bind(this)}
                selectedKeys={this.state.selectedKeysA}
              >
                <TreeNode title='计算软件' key="00">
                  {
                    this.state.treedataA.length > 0 ? this.state.treedataA.map((item, index) => {
                      return (<TreeNode title={item.CodeNum} key={item.ID} CodeNum={item.CodeNum} FunctionIntroduction={item.FunctionIntroduction}></TreeNode>)
                    }) : ""
                  }
                </TreeNode>
              </Tree>
            </div>
          </div>
        </div>

        <div className="wisdomdiagnosistoolsright" ref="wisdomdiagnosistoolsright" style={{
          height: clientHeight - 50 + 'px',
        }}>
          <div className="wisdomdiagnosistoolsrighttop">
            <h2 className="h2title">
              名称：{this.state.CodeNum}
            </h2>
            <p className="pcontent">
              {this.state.FunctionIntroduction}
            </p>
          </div>
          <span className="listaction">
            <Button style={{display:this.state.activeKey == "1"  && this.state.buttonarr.indexOf('输入添加')>-1 
            || this.state.activeKey == "2"  && this.state.buttonarr.indexOf('输出添加')>-1 
            || this.state.activeKey == "3"  && this.state.buttonarr.indexOf('设备添加')>-1 
            ?"inline-block":"none"}} onClick={this.handleAdd.bind(this)}>添加</Button>
            <Button style={{display:this.state.activeKey == "1"  && this.state.buttonarr.indexOf('输入删除')>-1 
            || this.state.activeKey == "2"  && this.state.buttonarr.indexOf('输出删除')>-1 
            || this.state.activeKey == "3"  && this.state.buttonarr.indexOf('设备删除')>-1 
            ?"inline-block":"none"}} onClick={this.handleDellist.bind(this)}>删除</Button>
          </span>
          <Tabs type="card" onChange={(key) => { this.setState({ activeKey: key }) }} activeKey={this.state.activeKey}>
            <TabPane tab="输入" key="1">
              <div className="listscroll" style={{ height: clientHeight - 50-40 - 30 - 90 - 30 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.dataSourcein}
                  columns={this.columnsin}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: this.senddata.bind(this, record)
                    }
                  }}
                  rowClassName={this.setRowClassName}
                  scroll={{ y: clientHeight- 50-40 - 30 - 90 - 30 -30  + 'px' }}
                />
              </div>
            </TabPane>
            <TabPane tab="输出" key="2">
              <div className="listscroll" style={{ height: clientHeight- 50-40 - 30 - 90 - 30 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.dataSourceout}
                  columns={this.columnsout}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: this.senddata.bind(this, record)
                    }
                  }}
                  rowClassName={this.setRowClassName}
                  scroll={{ y: clientHeight- 50-40 - 30 - 90 - 30 -30 + 'px' }}
                />
              </div>
            </TabPane>
            <TabPane tab="适用设备" key="3">
              <div className="listscroll" style={{ height: clientHeight - 50-40 - 30 - 90 - 30 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.dataSourcefu}
                  columns={this.columnsfu}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: this.senddata.bind(this, record)
                    }
                  }}
                  rowClassName={this.setRowClassName}
                  scroll={{ y: clientHeight - 50-40 - 30 - 90 - 30 -30  + 'px' }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </section >
    )
  }
}
const WisdomDiagnosisToolsapp = Form.create()(WisdomDiagnosisTools);
export default WisdomDiagnosisToolsapp;

