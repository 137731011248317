import React from 'react';
import { Input, Spin, message, Button, Select, Radio, Drawer, Form, Col, Row, Badge, Tabs, Tooltip, Table, DatePicker, Modal, InputNumber, TimePicker } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import './style.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { onCellf } from '../../comfunc'
import store from '../../store';
import { getButtonLimit } from '../../utils/getbuttonlimit'
moment.locale('zh-cn')
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { TextArea } = Input
const confirm = Modal.confirm

class Inspectioncategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      counflag:false,
      uname: '',

      formiptobj: {},
      addedit: "添加",

      data1: [],
      rowIdA: '',
      rowIdAobj: {},
      data2: [],
      rowIdB: '',
      rowIdBobj: {},

      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 70 - 50) / 28),
      pageIndex1: 1,
      pageIndex2: 1,
    }
    //考核版本记录
    this.data1columns = [{
      title: '序号',
      dataIndex: 'xu',
      key: 'xu',
      width: '8%',
      render: (text, record, index) => {
        return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
      }
    },
    {
      title: '编码',
      dataIndex: 'ClassificationName',
      key: 'ClassificationName',
      width: '15%',
    },
    {
      title: '说明',
      dataIndex: 'Remark',
      key: 'Remark',
      width: '30%',
      onCell: onCellf,
      render: this.renderf
    },
    {
      title: '创建单位',
      dataIndex: 'Establish',
      key: 'Establish',
      width: '16%',
    },
    {
      title: '创建时间',
      dataIndex: 'CreateTime',
      key: 'CreateTime',
      width: '18%',
      onCell: onCellf,
      render: (text)=>{
      return (<span>{text.split(" ")[0]}</span>)
      }
    }
    ]
    //考核详情
    this.data2columns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '6%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex2 - 1) * this.state.pageSize + index + 1}</span>)
        }
      }, {
        title: '参数名称',
        dataIndex: 'ParameterName',
        key: 'ParameterName',
        width: '24%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '参数类型',
        dataIndex: 'ParameterType',
        key: 'ParameterType',
        width: '20%',
        render: (text) => {
          if (text == "1") {
            return (<span>选择</span>)
          } else if (text == "0") {
            return (<span>文本</span>)
          } else {
            return (<span></span>)
          }
        }
      },
      {
        title: '选择内容',
        dataIndex: 'OptionContent',
        key: 'OptionContent',
        width: '20%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '10%',
      },
      {
        title: '说明',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '20%',
        onCell: onCellf,
        render: this.renderf
      }
    ] 
    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="bottom" className="managetooltip" title={text}>{text}</Tooltip>)
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(64)

    //日历
    var uname = sessionStorage.getItem('uname')
    this.setState({
      uname
    })

    axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
      console.log('巡检参数类别列表', conversion(data))
      this.setState({
        data1: conversion(data)
      })
    })
  }
  componentWillReceiveProps(nextprops) {
    ////console.log(nextprops)
    this.setState({
      pageSize: parseInt(parseFloat(store.getState().clientHeight - 50 - 70 - 50) / 28),
    })
  }



  //选中
  senddata1(record) {
    console.log(record)
    this.setState({
      rowIdA: record.ID,
      rowIdAobj: record,
      counflag:true
    }, function () {
      axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=30', {
        ParentId: this.state.rowIdA
      }).then(data => {
        console.log('巡检参数详情', conversion(data))
        this.setState({
          data2: conversion(data),
          pageIndex2:1,
          counflag:false
        })
      })
    })
  }
  setRowClassName1 = (record) => {
    return record.ID === this.state.rowIdA ? 'clickRowStyl' : 'clickRowSty';
  }

  senddata2(record) {
    console.log(record)
    this.setState({
      rowIdB: record.ID,
      rowIdBobj: record,
      ParameterTypesele: Number(record.ParameterType)
    })
  }
  setRowClassName2 = (record) => {
    return record.ID === this.state.rowIdB ? 'clickRowStyl' : 'clickRowSty';
  }

  //添加
  handleAdd(key) {
    if (key == "类别列表") {
      this.setState({
        addedit: "添加",
        addeditname: key,
        formiptobj: {},
        visible: true,
      }, function () {
        this.props.form.resetFields()
      })
    } else if (key == "设备参数") {
      if (this.state.rowIdA) {
        this.setState({
          addedit: "添加",
          addeditname: key,
          formiptobj: {},
          ParameterTypesele: 0,
          visible: true,
        }, function () {
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择巡检设备！')
      }
    }
  }
  //编辑
  handleEdit(key) {
    this.props.form.resetFields()
    if (key == "类别列表") {
      if (this.state.rowIdA) {
        var obj = this.state.rowIdAobj
        this.setState({
          addedit: "编辑",
          addeditname: key,
          formiptobj: obj,
          visible: true,
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "设备参数") {
      if (this.state.rowIdB) {
        var obj = this.state.rowIdBobj
        this.setState({
          addedit: "编辑",
          addeditname: key,
          formiptobj: obj,
          visible: true,
        })
      } else {
        message.error('请选择！')
      }
    }
  }
  //保存
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else if (value.label) {
            values[i] = value.label
          } else if (value._d && !value._i) {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
          } else if (value._f == "HH:mm:ss") {
            values[i] = value._d.getHours() + ':' + value._d.getMinutes() + ':' + value._d.getSeconds()
          } else if (value._f == "YYYY-MM-DD") {
            values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
          } else {
            values[i] = values[i]
          }
        }
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "类别列表") {
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=12', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.success) {
                axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
                  console.log('巡检参数类别列表', conversion(data))
                  this.setState({
                    data1: conversion(data),
                    visible: false
                  })
                })
              }
            })
          } else if (this.state.addeditname == "设备参数") {
            values.ParentId = this.state.rowIdA
            if (this.state.ParameterTypesele == 1) {
              values.OptionContent = values.OptionContent
            } else if (this.state.ParameterTypesele == 0) {
              values.OptionContent = ""
            }
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=31', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.success) {
                axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=30', {
                  ParentId: this.state.rowIdA
                }).then(data => {
                  console.log('巡检参数详情', conversion(data))
                  this.setState({
                    data2: conversion(data),
                    visible: false
                  })
                })
              }
            })
          }
        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "类别列表") {
            values.ID = this.state.rowIdA
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=14', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.success) {
                axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
                  console.log('巡检参数类别列表', conversion(data))
                  this.setState({
                    data1: conversion(data),
                    visible: false
                  })
                })
              }
            })
          } else if (this.state.addeditname == "设备参数") {
            values.ParentId = this.state.rowIdA
            values.ID = this.state.rowIdB
            if (this.state.ParameterTypesele == 1) {
              values.OptionContent = values.OptionContent
            } else if (this.state.ParameterTypesele == 0) {
              values.OptionContent = ""
            }
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=32', values).then(data => {
              console.log(data)
              if (data.data == true || data.data.success) {
                axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=30', {
                  ParentId: this.state.rowIdA
                }).then(data => {
                  console.log('巡检参数详情', conversion(data))
                  this.setState({
                    data2: conversion(data),
                    visible: false
                  })
                })
              }
            })
          }
        }
      }

    });
  };
  //删除
  handleDel(key) {
    var _this = this
    if (key == "类别列表") {
      if (this.state.rowIdA) {
        var key = this.state.rowIdA
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=13', {
              ID: _this.state.rowIdA
            }).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                const dataSource = [..._this.state.data1];
                _this.setState({ data1: dataSource.filter(item => item.ID !== key), rowIdAobj: {}, rowIdA: "" ,pageIndex1:1}, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择！')
      }
    } else if (key == "设备参数") {
      if (this.state.rowIdB) {
        var key = this.state.rowIdB
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=33', {
              ID: _this.state.rowIdB
            }).then(data => {
              console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                const dataSource = [..._this.state.data2];
                _this.setState({ data2: dataSource.filter(item => item.ID !== key), rowIdBobj: {}, rowIdB: "",pageIndex2:1 }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择！')
      }
    }
  }

  onClose = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      formiptobj: {},
    })
  };


  //分页
  pagechange(key, page) {
    console.log(page)
    if (key == "类别列表") {
      this.setState({
        pageIndex1: page.current,
      })
    } else if (key == "设备参数") {
      this.setState({
        pageIndex2: page.current,
      })
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <section className="Inspectioncategory" style={{ height: clientHeight - 50 + 'px' }}>

        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
        <div className="Inspectioncategorycon">
          <div className="Inspectioncategoryconleft">
            <h2 className="h2title">巡检设备类别列表</h2>
            <span className="action">
              <Button style={{display:this.state.buttonarr.indexOf('添加-设备类别')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '类别列表')}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑-设备类别')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this, '类别列表')}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除-设备类别')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this, '类别列表')}>删除</Button>
            </span>
            <div className="listpage" style={{ height: clientHeight - 50 - 80 }}>
              <Table
                size="small"
                dataSource={this.state.data1}
                columns={this.data1columns}
                pagination={{
                  pageSize: this.state.pageSize,
                  current: this.state.pageIndex1,
                  total: this.state.data1length
                }}
                onChange={this.pagechange.bind(this, '类别列表')}
                onRow={(record) => {
                  return {
                    onClick: this.senddata1.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassName1}
              />
            </div>
          </div>
          <div className="Inspectioncategoryconright">
            <h2 className="h2title">巡检设备参数列表</h2>
            <span className="action">
              <Button style={{display:this.state.buttonarr.indexOf('添加-参数')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '设备参数')}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑-参数')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this, '设备参数')}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除-参数')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this, '设备参数')}>删除</Button>
            </span>
            <div className="listpage" style={{ height: clientHeight - 50 -80 + 'px' }}>
              <Table
                size="small"
                dataSource={this.state.data2}
                columns={this.data2columns}
                pagination={{
                  pageSize: this.state.pageSize,
                  current: this.state.pageIndex2,
                  total: this.state.data2length
                }}
                onChange={this.pagechange.bind(this, '设备参数')}
                onRow={(record) => {
                  return {
                    onClick: this.senddata2.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassName2}
              />
            </div>
          </div>
        </div>

        <Drawer
          title={this.state.addeditname + '' + this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{height:clientHeight-50-55+'px'}}>
            {
              this.state.addeditname == "类别列表" ?
                <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="编码">
                        {getFieldDecorator('ClassificationName', {
                          initialValue: this.state.formiptobj["ClassificationName"],
                          rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="创建单位">
                        {getFieldDecorator('Establish', {
                          initialValue: this.state.formiptobj["Establish"],
                          rules: [{ required: true, message: ()=>{return (<span></span>)} }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="textipt">
                    <Col span={12}>
                      <Form.Item label="说明">
                        {getFieldDecorator('Remark', {
                          initialValue: this.state.formiptobj["Remark"]
                          // rules: [{ required: true, message: '请输入备注' }],
                        })(
                          <TextArea rows={4} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="formbutton">
                    <Form.Item>
                      <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                        保存
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
                : this.state.addeditname == "设备参数" ?
                  <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
                  // style={{ height: (clientHeight - 40 - 80) / 3 - 50 + 'px' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="参数名称">
                          {getFieldDecorator('ParameterName', {
                            initialValue: this.state.formiptobj['ParameterName'],
                            rules: [{ required: true, message: '请输入参数名称' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="参数类型">
                          {getFieldDecorator('ParameterType', {
                            initialValue: this.state.addedit == "添加" ? this.state.ParameterTypesele : Number(this.state.formiptobj['ParameterType']),
                            rules: [{ required: true, message: '请选择参数类型' }],
                          })(
                            <Select onChange={(v) => { this.setState({ ParameterTypesele: v }) }}>
                              <Option value={0} key={0}>文本</Option>
                              <Option value={1} key={1}>选择</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} style={{ display: this.state.ParameterTypesele == 1 ? "block" : "none" }}>
                      <Col span={12}>
                        <Form.Item label="选项内容">
                          {getFieldDecorator('OptionContent', {
                            initialValue: this.state.formiptobj['OptionContent'],
                            rules: [{ required: this.state.ParameterTypesele == "1" ? true : false, message: '请输入选项内容' }],
                          })(
                            <Input style={{ display: this.state.ParameterTypesele == 1 ? "block" : "none" }} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="单位">
                          {getFieldDecorator('Unit', {
                            initialValue: this.state.formiptobj['Unit'],
                            rules: [{ required: true, message: '请选择单位' }],
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="textipt">
                      <Col span={12}>
                        <Form.Item label="说明">
                          {getFieldDecorator('Remark', {
                            initialValue: this.state.formiptobj['Remark'],
                            // rules: [{ required: true, message: '请输入备注' }],
                          })(
                            <TextArea rows={5} />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="formbutton">
                      <Form.Item>
                        <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                          保存
          </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                  : ""
            }
          </div>
        </Drawer>
      </section>
    )
  }
}
const Inspectioncategoryapp = Form.create()(Inspectioncategory);
export default Inspectioncategoryapp;

