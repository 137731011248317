import React, { Component } from 'react';
import { Tree } from 'antd'
const { TreeNode, DirectoryTree } = Tree;
class Loop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            searchValue : this.props.searchValue
        }
    }
    componentWillReceiveProps(nextprops) {
        this.setState({
            data: nextprops.data,
            searchValue : nextprops.searchValue
        })
    }
    render() { 
        const { searchValue } = this.state
        const loop = data =>
          data.map(item => {
            const index = item.name.indexOf(searchValue);
            const beforeStr = item.name.substr(0, index);
            const afterStr = item.name.substr(index + searchValue.length);
            const title =
              index > -1 ? (
                <span>
                  {beforeStr}
                  <span style={{ color: '#f50' }}>{searchValue}</span>
                  {afterStr}
                </span>
              ) : (
                  <span>{item.name}</span>
                );
            if (item.children) {
              return (
                <TreeNode key={item.id} title={title} leaf={item.leaf} name={item.name}>
                  {loop(item.children)}
                </TreeNode>
              );
            }
            return <TreeNode key={item.id} title={title} leaf={item.leaf} name={item.name} />;
          });
        return (
           <Tree>
               {Loop(this.state.data)}
           </Tree>
        )

    }
}
export default Loop;