import React, { Fragment } from 'react'
import {  Input, Spin, Modal, Table, Button, message, Tooltip, Form, Row, Col, DatePicker, Select, Drawer } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import { conversion } from '../../../converse'
import Draggable from 'react-draggable';
import { onCellf } from '../../../comfunc'
import TextArea from 'antd/lib/input/TextArea';
import store from '../../../store';
import { getButtonLimit } from '../../../utils/getbuttonlimit'

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const renderf = (text) => {
  return (<Tooltip placement="bottomLeft" className="managetooltip" title={text}>{text}</Tooltip>)
}

class OperationLogComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeDrags: 0,
      controlledPosition: {
        x: 0, y: 0
      },
      buttonarr: [],
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 50 ) / 28),
      
      columns1: [
        {
          title: '序号',
          dataIndex: 'xu',
          key: 'xu',
          width: '6%',
          render: (text, record, index) => {
            return (<span>{(this.state.pageIndex1 - 1) * this.state.pageSize + index + 1}</span>)
          }
        },
        {
          title: '表名',
          dataIndex: 'TableName',
          key: 'TableName',
          width: '18%'
        },
        {
          title: '业务名称',
          dataIndex: 'BusinessName',
          key: 'BusinessName',
          width: '16%'
        },
        {
          title: '账号',
          dataIndex: 'CreatePeople',
          key: 'CreatePeople',
          width: '20%',
          onCell: onCellf,
          render: renderf
        },
        {
          title: '操作类型',
          dataIndex: 'OperateType',
          key: 'OperateType',
        },
        {
          title: '操作时间',
          dataIndex: 'CreateTime',
          key: 'CreateTime',
          width: '16%',
        },
        // {
        //   title: '业务主键值',
        //   dataIndex: 'PrimaryKeyValue',
        //   key: 'PrimaryKeyValue',
        //   width: '10%'
        // }
      ],
      dataSource1: [],
      pageIndex1: 1,
      dataSourcelen1: 0,

      columns11: [
        {
          title: '序号',
          dataIndex: 'xu',
          key: 'xu',
          width: '6%',
          render: (text, record, index) => {
            return (<span>{(this.state.pageIndex11 - 1) * 8 + index + 1}</span>)
          }
        },
        {
          title: '字段',
          dataIndex: 'ColumnName',
          key: 'ColumnName',
          width: '20%'
        },
        {
          title: '名称',
          dataIndex: 'ColumnText',
          key: 'ColumnText',
          width: '20%'
        },
        {
          title: '旧值',
          dataIndex: 'OldValues',
          key: 'OldValues',
          width: '26%'
        },
        {
          title: '新值',
          dataIndex: 'NewValues',
          key: 'NewValues',
          width: '26%'
        }
      ],
      dataSource11: [],
      pageIndex11: 1,
      dataSourcelen11: 0,

      //日志搜索时间
      startendtime: [],
    }
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限', res)
    this.setState({
      buttonarr: res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(44)
    this.logFunc()
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 50 ) / 28),
    })
  }


  senddata1(record) {
    ////console.log(record)
    this.setState({
      rowId1: record.ID,
      rowObj1: record,
    })
    $('.logdetail').css("display", "none")
  }
  setRowClassName1 = (record) => {
    return record.ID === this.state.rowId1 ? 'clickRowStyl' : 'clickRowSty';
  }



  pagechange(key, page) {
    //console.log(key, page)
    if (key == "日志") {
      this.setState({
        pageIndex1: page.current
      }, function () {
        this.logFunc()
      })
    } else if (key == "日志详情") {
      this.setState({
        pageIndex11: page.current
      }, function () {
        this.lookFunc()
      })
    }
  }

  logFunc() {
    var startendtime = this.state.startendtime
    var StartTime = ""
    var EndTime = ""
    if (startendtime.length > 0) {
      StartTime = startendtime[0]
      EndTime = startendtime[1]
      // StartTime = formatTimeDate(startendtime[0])
      // EndTime = formatTimeDate(startendtime[1])
    }
    axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=6', {
      PageIndex: this.state.pageIndex1,
      PageSize: this.state.pageSize,
      StartTime: StartTime,
      EndTime: EndTime,
    }).then(res => {
      //console.log(res)
      var str = res.data.slice(2).split(',')[0].split(':')[1]
      this.setState({
        equipmentFlag: "日志",
        dataSource1: conversion(res),
        dataSourcelen1: Number(str)
      })
    })
  }
  //日志 查阅
  lookFunc() {
    if (this.state.rowId1) {
      axios.post('/DATA/ParaSet/EquipmentList.aspx?flag=7', {
        ParentId: this.state.rowId1,
        PageIndex: this.state.pageIndex11,
        PageSize: 8
      }).then(res => {
        //console.log('详情', res)
        var str = res.data.slice(2).split(',')[0].split(':')[1]
        this.setState({
          dataSource11: conversion(res),
          dataSourcelen11: Number(str)
        })
        $('.logdetail').css("display", "block")
      })
    } else {
      message.error('请选择！')
    }
  }


  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDrag(e, ui) {
    const { x, y } = this.state.controlledPosition;
    this.setState({
      controlledPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  //文件上传窗口关闭
  closedragwiddow() {
    $('.logdetail').css('display', "none")
  }
  render() {

    const { getFieldDecorator } = this.props.form;

    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div className="loglistcon" style={{ height: clientHeight - 50 + 'px' }}>
       <Draggable
          position={this.state.controlledPosition}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDrag.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <Fragment>
            <div className="dragwindow logdetail">
              <h2>查看日志详细内容 <span className="close" onClick={this.closedragwiddow.bind(this)}>x</span></h2>
              <div className="logdetaillist listpage">
                <Table
                  rowKey={record => record.ID}
                  size="small"
                  dataSource={this.state.dataSource11}
                  columns={this.state.columns11}
                  pagination={{
                    pageSize: 8,
                    current: this.state.pageIndex11,
                    total: this.state.dataSourcelen11
                  }}
                  onChange={this.pagechange.bind(this, '日志详情')}
                />
              </div>
            </div>
          </Fragment>
        </Draggable>

        <div className="actionall">
          <span className="actionleft">
            <span className="logtitle">日志列表</span> &nbsp;  搜索日期:
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              onChange={(value, dateString) => { this.setState({ startendtime: dateString }) }}
            />
            <img onClick={() => { this.logFunc() }} src={require('../../../assets/sousuo.png')} />
            {/* <RangePicker value={this.state.startendtime} onChange={(v) => { this.setState({ startendtime: v }) }} /> */}
          </span>
          <span className="actionright">
            <Button className="twoword" onClick={this.lookFunc.bind(this)}>查看</Button>
          </span>
        </div>
        <div className="listpage"
          style={{ height: clientHeight - 50 - 50 + 'px' }}>
          <Table
            rowKey={record => record.ID}
            size="small"
            dataSource={this.state.dataSource1}
            columns={this.state.columns1}
            pagination={{
              pageSize: this.state.pageSize,
              current: this.state.pageIndex1,
              total: this.state.dataSourcelen1
            }}
            onChange={this.pagechange.bind(this, '日志')}
            onRow={(record) => {
              return {
                onClick: this.senddata1.bind(this, record)
              }
            }}
            rowClassName={this.setRowClassName1}
          />
        </div>
      </div>

    )
  }
}
const OperationLog = Form.create()(OperationLogComp);
export default OperationLog;