  //树
  function run(treeArrs, resData, atr) {
    //treeArrs 第一次排序
    var attr = atr//对象属性
    var resData = resData//数据
    if (resData.length > 0) {
      for (let i = 0; i < treeArrs.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (treeArrs[i].id === resData[j][attr.parentid]) {
            let obj = {
              id: resData[j][attr.id],
              parentid: resData[j][attr.parentid],
              Name: resData[j][attr.Name],
              Rank: resData[j][attr.Rank],
              ordernum: resData[j][attr.ordernum],
              children: [],
              Leaf: resData[j][attr.Leaf],
              treepath: treeArrs[i].treepath + '/' + resData[j][attr.Name],
            };
            treeArrs[i].children.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        run(treeArrs[i].children, resData, attr);
      }
    }
  }
  export  function toPathTreeData(data, atr) {
    let tree = [];
    let resData = data;//数组
    let attr = atr //对象属性
    for (let i = 0; i < resData.length; i++) {
      if (resData[i].parentid === attr.rootId) {
        let obj = {
          id: resData[i][attr.id],
          parentid: resData[i][attr.parentid],
          Name: resData[i][attr.Name],
          ordernum: resData[i][attr.ordernum],
          children: [],
          Leaf: resData[i][attr.Leaf],
          treepath: resData[i][attr.Name],
        };
        tree.push(obj);
        resData.splice(i, 1);
        i--;
      }
    }
    run(tree, resData, attr);
    return tree;
  }

  
  //文档树
  function runA(treeArrs, resData, atr) {
    //treeArrs 第一次排序
    var attr = atr//对象属性
    var resData = resData//数据
    if (resData.length > 0) {
      for (let i = 0; i < treeArrs.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (treeArrs[i].ID === resData[j][attr.ParentId]) {
            let obj = {
              ID: resData[j][attr.ID],
              ParentId: resData[j][attr.ParentId],
              Code:resData[j][attr.Code],
              Name: resData[j][attr.Name],
              UserNumber: resData[j][attr.UserNumber],
              ParentType: resData[j][attr.ParentType],
              Path: resData[j][attr.Path],
              RecordVersion:resData[j][attr.RecordVersion],
              children: [],
              // Leaf: resData[j][attr.Leaf],
              treepath: treeArrs[i].treepath + '/' + resData[j][attr.Code],
            };
            treeArrs[i].children.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        runA(treeArrs[i].children, resData, attr);
      }
    }
  }
  export  function toTreeDataA(data, atr) {
    let tree = [];
    let resData = data;//数组
    let attr = atr //对象属性
    for (let i = 0; i < resData.length; i++) {
      if (resData[i].ParentId === attr.rootId) {
        let obj = {
          ID: resData[i][attr.ID],
          ParentId: resData[i][attr.ParentId],
          Name: resData[i][attr.Name],
          Code: resData[i][attr.Code],
          UserNumber: resData[i][attr.UserNumber],
          ParentType: resData[i][attr.ParentType],
          Path: resData[i][attr.Path],
          RecordVersion: resData[i][attr.RecordVersion],
          children: [],
          // Leaf: resData[i][attr.Leaf],
          treepath: resData[i][attr.Code],
        };
        tree.push(obj);
        resData.splice(i, 1);
        i--;
      }
    }
    if(resData.length>0){
      runA(tree, resData, attr);
    }
    return tree;
  }

  
  function runB(treeArrs, resData, atr) {
    //treeArrs 第一次排序
    var attr = atr//对象属性
    var resData = resData//数据
    if (resData.length > 0) {
      for (let i = 0; i < treeArrs.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (treeArrs[i].ID === resData[j][attr.ParentId]) {
            let obj = {
              ID: resData[j][attr.ID],
              ParentId: resData[j][attr.ParentId],
              Code:resData[j][attr.Code],
              Name: resData[j][attr.Name],
              UserNumber: resData[j][attr.UserNumber],
              ParentType: resData[j][attr.ParentType],
              Path: resData[j][attr.Path],
              RecordVersion:resData[j][attr.RecordVersion],
              children: [],
              // Leaf: resData[j][attr.Leaf],
              treepath: treeArrs[i].treepath + '/' + resData[j][attr.Code],
            };
            treeArrs[i].children.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        runB(treeArrs[i].children, resData, attr);
      }
    }
  }
  export  function toTreeDataB(data, atr) {
    let tree = [];
    let resData = data;//数组
    let attr = atr //对象属性
    for (let i = 0; i < resData.length; i++) {
      if (resData[i].ParentId === attr.rootId) {
        let obj = {
          ID: resData[i][attr.ID],
          ParentId: resData[i][attr.ParentId],
          Name: resData[i][attr.Name],
          Code: resData[i][attr.Code],
          UserNumber: resData[i][attr.UserNumber],
          ParentType: resData[i][attr.ParentType],
          Path: resData[i][attr.Path],
          RecordVersion: resData[i][attr.RecordVersion],
          children: [],
          // Leaf: resData[i][attr.Leaf],
          treepath: resData[i][attr.Code],
        };
        tree.push(obj);
        resData.splice(i, 1);
        i--;
      }
    }
    if(resData.length>0){
      runB(tree, resData, attr);
    }
    var obj = { tree:tree,resData:resData }
    return obj;
  }