import React from 'react';
import { Input, Icon, Button, Select, Calendar, Drawer, Form, Col, Row, Badge } from 'antd';
import axios from 'axios';
import { conversion } from '../../../converse'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import $ from 'jquery'
// 引入折现图
import echarts from 'echarts';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import './style.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import store from '../../../store';
import { getButtonLimit } from '../../../utils/getbuttonlimit'
moment.locale('zh-cn')

class ReportComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],

      visible: false,
      clientHeight: store.getState().clientHeight,
      monthobjdata: {},
      yearobjdata: {},
      RecordDate: "",
      iptobj: {},
      RecordDatelistarr: [],
      //点击取值
      clickobj: {
        '发电量(万kW•h)': 'GenerationCapacity',
        '上网电量(万kW•h)': 'OnlineEnergy',
        '厂用电量(万kW•h)': 'FactoryPower',
        '平均厂用电率1(%)': 'PRate1',
        '平均厂用电率2(%)': 'PRate2',
        '平均供热比': 'HeatingRatio',
        '平均热电比': 'ThermR',
        '燃料消耗量(t)': 'FuelCT',
        '燃料单耗': 'FuelCo',
        '平均折标媒耗(kg/kW•h)': 'CoaCFS',
        '#1母管供汽(t)': 'MainSS1',
        '#2母管供汽(t)': 'MainSS2',
        '售汽(t)': 'SaleSt',
        '平均管损(%)': 'TubeLo',
        '燃料进量(t)': 'FuelIn',
        // '库存(t)':'Stock',
        // '初始库存(t)':'YearStock',

        '汽机运行时间(h)': 'RunTi1',
        '汽机进汽量(t)': 'InVol1',
        '平均进汽压力(MPa)': 'InPre1',
        '平均进汽温度(℃)': 'InTem1',
        '平均排汽温度(℃)': 'ExTem1',
        '平均真空(kPa)': 'Vacuu1',
        '凝结水平均温度(℃)': 'CondT1',
        '冷却水进口平均温度(℃)': 'InTXW1',
        '冷却水出口平均温度(℃)': 'OuTXW1',
        '冷却水平均温差(℃)': 'TDiff1',
        '冷却水平均端差(℃)': 'TerDi1',
        '平均过冷度(℃)': 'UndeD1',
        '平均汽耗率(kg/kW•h)': 'SteCR1',
        '除盐水补水量(t)': 'MDWSV1',
        '锅炉平均补水率(%)': 'WaRRB1',
        '取水口补水量(t)': 'WaTII1',
        '工业水耗(kg/kW•h)': 'indWC1',



        '锅炉运行时间(h)': 'FurRT1,FurRT2',
        '入炉燃料量(t)': 'FutFC1,FutFC2',
        '入炉平均热值(kcal)': 'CalVF1,CalVF2',
        '锅炉蒸发量(t)': 'EvapB1,EvapB2',
        '平均主蒸汽压力(MPa)': 'MsSPf1,MsSPf2',
        '平均主蒸汽温度(℃)': 'MaSTF1,MaSTF2',
        '给水流量(t)': 'FeWFF1',
        '平均给水温度(℃)': 'FeWTF1,FeWTF2',
        '锅炉平均效率(%)': 'EffiB1,EffiB2',
        '平均排烟温度(℃)': 'ExGTF1,ExGTF2',
        '环境平均温度(℃)': 'EnvTF1,EnvTF2',
        '排烟平均温度差(℃)': 'FFGTD1,FFGTD2',
        '炉渣平均含碳量(%)': 'CaCFS1,CaCFS2',
        '炉灰平均含碳量(%)': 'CaCFA1,CaCFA2',
        '平均氧量(%)': 'OxygC1,OxygC2',
        '平均汽料比(t/t)': 'StCRF1',
        '平均启停燃料调整(t)': 'FSSFA1,FSSFA2',
        '锅炉计数器': 'BoilC1,BoilC2',
        '汽机计数器': 'SteTC1',
      },
      //曲线图
      Baobiaoframeobj: {},
      Baobiaoframeobj1: {},
      ifframeflag: 1,
    }
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(7)
  
    var trs = document.querySelectorAll('table tr')
    for (let i = 0; i < trs.length; i++) {
      trs[i].style.height = this.state.clientHeight < 800 ? "26px" : "36px"
    }

    var date = new Date()
    var datecurrent = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    // console.log(datecurrent)
    this.setState({
      RecordDate: datecurrent
    })
    //输入记录
    axios.get('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=5').then(data => {
      // console.log('输入记录', conversion(data))
      var arr = conversion(data)
      var newarr = []
      for (let i = 0; i < arr.length; i++) {
        newarr.push(arr[i].RecordDate.split(' ')[0])
      }
      this.setState({
        RecordDatelistarr: newarr
      })
    })
    //月记录
    axios.post('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=2', {
      RecordDate: datecurrent
    }).then(data => {
      //console.log('月', conversion(data))
      this.setState({
        monthobjdata: conversion(data)[0] ? conversion(data)[0] : {}
      })
    })
    //年记录
    axios.post('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=3', {
      RecordDate: datecurrent
    }).then(data => {
      // console.log('年', conversion(data))
      this.setState({
        yearobjdata: conversion(data)[0] ? conversion(data)[0] : {}
      })
    })


    var _this = this

    var canclickarr = document.querySelectorAll('.baobiaocon .canclick')
    // console.log(canclickarr.length)
    for (let i = 0; i < canclickarr.length; i++) {
      canclickarr[i].addEventListener('click', function () {
        _this.setState({
          ifframeflag: 1,
          Baobiaoframeobj1: {}
        })
        console.log(canclickarr[i].firstChild.innerHTML)
        var str = canclickarr[i].firstChild.innerHTML
        axios.post('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=6', {
          RecordDate: _this.state.RecordDate,
          ColumnsName: _this.state.clickobj[`${str}`]
        }).then(data => {
          console.log(conversion(data), _this.state.clickobj[`${str}`])
          var conversionarr = conversion(data)
          var xAxisdata = [] //x轴数据
          var yAxisdata = [] //数据
          for (let i = 0; i < conversionarr.length; i++) {
            var str1 = conversionarr[i].RecordDate.split(' ')[0]
            if (str1.indexOf('-') > -1) {
              var strnew = str1.split('-')[1] + '-' + str1.split('-')[2]
            } else if (str1.indexOf('/')) {
              var strnew = str1.split('/')[1] + '-' + str1.split('/')[2]
            }
            xAxisdata.push(strnew)
            yAxisdata.push(conversionarr[i][_this.state.clickobj[`${str}`]])
          }
          _this.setState({
            Baobiaoframeobj: {
              grid: {
                left: '5%',
                right: '5%',
                top: '8%',
                bottom: '20%',
                scontainLabel: true
              },
              title: {
                text: str,
                x: 'center',
                textStyle:{
                  color:"#Fff",
                  fontSize:"14px"
                }
              },
              tooltip: {
                formatter: " 数量: {c} "
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: {
                data: xAxisdata,
                // name:'时间',
                type: 'category',
                boundaryGap: false,
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 10      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  },
                },
              },
              // grid: {
              //   left: str == "平均折标媒耗(kg/kW•h)" ? 80 : 55,
              //   right: 30,
              // },
              yAxis: {
                type: 'value',
                // name: str,
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12,    //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                }
              },
              series: [
                {
                  type: 'line',
                  data: yAxisdata,
                  areaStyle: {
                    color: 'none'
                  },
                  itemStyle: {
                    normal: {
                      color: '#fff', //改变折线点的颜色
                      lineStyle: {
                        color: '#03d8db' //改变折线颜色
                      }
                    }
                  },
                }
              ]
            }
          }, function () {
            //基于准备好的dom，初始化echarts实例
            var myChart1 = echarts.init(document.getElementById('Baobiaoframe'));
            //绘制图表
            myChart1.setOption(_this.state.Baobiaoframeobj);
          })
        })
      })
    }
    var canclickarr1 = document.querySelectorAll('.baobiaocon .canclick1')
    // console.log(canclickarr1.length)
    for (let i = 0; i < canclickarr1.length; i++) {
      canclickarr1[i].addEventListener('click', function () {
        _this.setState({
          ifframeflag: 2,
          Baobiaoframeobj: {}
        })
        console.log(canclickarr1[i].firstChild.innerHTML)
        var str = canclickarr1[i].firstChild.innerHTML
        axios.post('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=6', {
          RecordDate: _this.state.RecordDate,
          ColumnsName: _this.state.clickobj[`${str}`]
        }).then(data => {
          console.log(conversion(data), _this.state.clickobj[`${str}`])

          var conversionarr = conversion(data)
          var clickobjstr = _this.state.clickobj[`${str}`]
          var arr = clickobjstr.split(',')
          var xAxisdata = [], yAxisdata1 = [], yAxisdata2 = []
          for (let i = 0; i < conversionarr.length; i++) {
            var str11 = conversionarr[i].RecordDate.split(' ')[0]
            if (str11.indexOf('-') > -1) {
              var strnew = str11.split('-')[1] + '-' + str11.split('-')[2]
            } else if (str11.indexOf('/')) {
              var strnew = str11.split('/')[1] + '-' + str11.split('/')[2]
            }
            xAxisdata.push(strnew)
            yAxisdata1.push(conversionarr[i][arr[0]])
            yAxisdata2.push(conversionarr[i][arr[1]])
          }
          var Baobiaoframeobj1 = {
            tooltip: {
              formatter: "{c}"
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              data: xAxisdata,
              // name:'时间',
              type: 'category',
              boundaryGap: false,
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#fff',  //更改坐标轴文字颜色
                  fontSize: 10      //更改坐标轴文字大小
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                },
              },
            },

            // grid: {
            //   left: 55,
            //   right: 30,
            // },
            grid: {
              left: '5%',
              right: '5%',
              top: '8%',
              bottom: '20%',
              scontainLabel: true
            },
            title: {
              text: str,
              x: 'center',
              textStyle:{
                color:"#Fff",
                fontSize:"14px"
              }
            },
            yAxis: {
              type: 'value',
              // name: str,
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#fff',  //更改坐标轴文字颜色
                  fontSize: 12,    //更改坐标轴文字大小
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              }
            },
            series: [
              {
                name: arr[0],
                type: 'line',
                stack: '总量1',
                data: yAxisdata1
              },
              {
                name: arr[1],
                type: 'line',
                stack: '总量2',
                data: yAxisdata2
              }]
          }
          _this.setState({
            Baobiaoframeobj1,
          }, function () {
            //基于准备好的dom，初始化echarts实例
            var myChart2 = echarts.init(document.getElementById('Baobiaoframe1'));
            //绘制图表
            myChart2.setOption(_this.state.Baobiaoframeobj1);
          })
        })
      })
    }
  }
  componentWillReceiveProps(nextprops) {
    var trs = document.querySelectorAll('table tr')
    for (let i = 0; i < trs.length; i++) {
      trs[i].style.height = store.getState().clientHeight < 800 ? "26px" : "36px"
    }
  }
  onPanelChange(value, mode) {
    //console.log(value, mode);
  }
  onDateSelect(date) {
    var date1 = date._d.getFullYear() + '-' + (date._d.getMonth() + 1) + '-' + date._d.getDate();
    this.setState({
      RecordDate: date1
    })
    axios.post('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=4', {
      RecordDate: date1
    }).then(data => {
      console.log(data)
      this.setState({
        iptobj: conversion(data)[0] ? conversion(data)[0] : {},
        visible: true
      })
    })
    axios.post('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=2', {
      RecordDate: date1
    }).then(data => {
      //console.log('月', conversion(data))
      this.setState({
        monthobjdata: conversion(data)[0] ? conversion(data)[0] : {}
      })
    })
    axios.post('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=3', {
      RecordDate: date1
    }).then(data => {
      // console.log('年', conversion(data))
      this.setState({
        yearobjdata: conversion(data)[0] ? conversion(data)[0] : {}
      })
    })
  }
  //导出报表
  exportbaobiao() {
    var factory = sessionStorage.getItem('factory')
    var RecordDate = this.state.RecordDate
    window.open(`http://47.97.112.136:8080/WebReport/ReportServer?reportlet=B-SPPs/DailyReport.cpt&company=${encodeURI(factory)}&RecordDate=${RecordDate}`)
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      // console.log(err, values)
      if (!err) {
        // console.log(values);
        for (var i in values) {
          var value = values[i]
          if (value == undefined) {
            values[i] = ""
          } else {
            values[i] = values[i]
          }
        }
        var uname = sessionStorage.getItem('uname')
        values.RecordPeople = uname
        values.RecordDate = this.state.RecordDate
        axios.post('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=1', values).then(data => {
          console.log(data)
          if (data.data == true) {
            //输入记录
            axios.get('/DATA/IntelligentDecision/StatisticsAndReports/DailyReport.aspx?flag=5').then(data => {
              // console.log('输入记录', conversion(data))
              var arr = conversion(data)
              var newarr = []
              for (let i = 0; i < arr.length; i++) {
                newarr.push(arr[i].RecordDate.split(' ')[0])
              }
              this.setState({
                RecordDatelistarr: newarr,
                visible: false,
              }, function () {
                this.props.form.resetFields();
              })
            })
          }
        })
      }
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    }, function () {
      this.props.form.resetFields();
    });
  };

  dateCellRenderfun(value) {
    var arr = this.state.RecordDatelistarr
    for (let i = 0; i < arr.length; i++) {
      if (value.date() == arr[i].split('-')[2] &&
        (value.month() + 1) == arr[i].split('-')[1] &&
        value.year() == arr[i].split('-')[0]) {
        return (
          <Badge status="success" />
        );
      } else if (value.date() == arr[i].split('/')[2] &&
        (value.month() + 1) == arr[i].split('/')[1] &&
        value.year() == arr[i].split('/')[0]) {
        return (
          <Badge status="success" />
        );
      }
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="baobiao">
        <div className="calender">
          <div className="exportbaobiao">
            <Button style={{display:this.state.buttonarr.indexOf('导出报表')>-1?"inline-block":"none"}} onClick={this.exportbaobiao.bind(this)}>导出报表</Button>
          </div>
          <div className="calenderrili">
            <Calendar fullscreen={false}
            locale={locale}
              dateCellRender={this.dateCellRenderfun.bind(this)}
              onPanelChange={this.onPanelChange.bind(this)}
              onSelect={this.onDateSelect.bind(this)}
            />
          </div>
          {
            this.state.ifframeflag == 1 ?

              <div id="Baobiaoframe">

              </div>
              :

              <div id="Baobiaoframe1">

              </div>
          }
        </div>
        <div className="baobiaocon">
          <div className="baobiaocon1">
            <h2>综合经济指标</h2>
            <table>
              <tbody>
                <tr>
                  <th>项目</th>
                  <th>月累计</th>
                  <th>年累计</th>
                </tr>
                <tr className="canclick">
                  <td>发电量(万kW•h)</td>
                  <td>{this.state.monthobjdata.M_GenerationCapacity}</td>
                  <td>{this.state.yearobjdata.Y_GenerationCapacity}</td>
                </tr>
                <tr className="canclick">
                  <td>上网电量(万kW•h)</td>
                  <td>{this.state.monthobjdata.M_OnlineEnergy}</td>
                  <td>{this.state.yearobjdata.Y_OnlineEnergy}</td>
                </tr>
                <tr className="canclick">
                  <td>厂用电量(万kW•h)</td>
                  <td>{this.state.monthobjdata.M_FactoryPower}</td>
                  <td>{this.state.yearobjdata.Y_FactoryPower}</td>
                </tr>
                <tr className="canclick">
                  <td>平均厂用电率1(%)</td>
                  <td>{this.state.monthobjdata.M_PRate1}</td>
                  <td>{this.state.yearobjdata.Y_PRate1}</td>
                </tr>
                <tr className="canclick">
                  <td>平均厂用电率2(%)</td>
                  <td>{this.state.monthobjdata.M_PRate2}</td>
                  <td>{this.state.yearobjdata.Y_PRate2}</td>
                </tr>
                <tr className="canclick">
                  <td>平均供热比</td>
                  <td>{this.state.monthobjdata.M_HeatingRatio}</td>
                  <td>{this.state.yearobjdata.Y_HeatingRatio}</td>
                </tr>
                <tr className="canclick">
                  <td>平均热电比</td>
                  <td>{this.state.monthobjdata.M_ThermR}</td>
                  <td>{this.state.yearobjdata.Y_ThermR}</td>
                </tr>
                <tr className="canclick">
                  <td>燃料消耗量(t)</td>
                  <td>{this.state.monthobjdata.M_FuelCT}</td>
                  <td>{this.state.yearobjdata.Y_FuelCT}</td>
                </tr>
                <tr className="canclick">
                  <td>燃料单耗</td>
                  <td>{this.state.monthobjdata.M_FuelCo}</td>
                  <td>{this.state.yearobjdata.Y_FuelCo}</td>
                </tr>
                <tr className="canclick">
                  <td>平均折标媒耗(g/kW•h)</td>
                  <td>{this.state.monthobjdata.M_CoaCFS}</td>
                  <td>{this.state.yearobjdata.Y_CoaCFS}</td>
                </tr>
                <tr className="canclick">
                  <td>#1母管供汽(t)</td>
                  <td>{this.state.monthobjdata.M_MainSS1}</td>
                  <td>{this.state.yearobjdata.Y_MainSS1}</td>
                </tr>
                <tr className="canclick">
                  <td>#2母管供汽(t)</td>
                  <td>{this.state.monthobjdata.M_MainSS2}</td>
                  <td>{this.state.yearobjdata.Y_MainSS2}</td>
                </tr>
                <tr className="canclick">
                  <td>售汽(t)</td>
                  <td>{this.state.monthobjdata.M_SaleSt}</td>
                  <td>{this.state.yearobjdata.Y_SaleSt}</td>
                </tr>
                <tr className="canclick">
                  <td>平均管损(%)</td>
                  <td>{this.state.monthobjdata.M_TubeLo}</td>
                  <td>{this.state.yearobjdata.Y_TubeLo}</td>
                </tr>
                <tr className="canclick">
                  <td>燃料进量(t)</td>
                  <td>{this.state.monthobjdata.M_FuelIn}</td>
                  <td>{this.state.yearobjdata.Y_FuelIn}</td>
                </tr>
                <tr>
                  <td>库存(t)</td>
                  <td colSpan="2">{this.state.yearobjdata.Stock}</td>
                </tr>
                <tr>
                  <td>初始库存(t)</td>
                  <td colSpan="2">{this.state.yearobjdata.YearStock}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="baobiaocon2">
            <h2>汽机运行情况</h2>
            <table>
              <tbody>
                <tr>
                  <th>项目</th>
                  <th>月累计</th>
                  <th>年累计</th>
                </tr>
              </tbody>
            </table>
            <div className="scrolltable" >
              {/* style={{ height: this.state.clientHeight - 50 - 80 - 40 - 60 + 'px' }} */}
              <table>
                <tbody>
                  <tr className="canclick">
                    <td>汽机运行时间(h)</td>
                    <td>{this.state.monthobjdata.M_RunTi1}</td>
                    <td>{this.state.yearobjdata.Y_RunTi1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>发电量(万kW•h)</td>
                    <td>{this.state.monthobjdata.M_GenerationCapacity}</td>
                    <td>{this.state.yearobjdata.Y_GenerationCapacity}</td>
                  </tr>
                  <tr className="canclick">
                    <td>上网电量(万kW•h)</td>
                    <td>{this.state.monthobjdata.M_OnlineEnergy}</td>
                    <td>{this.state.yearobjdata.Y_OnlineEnergy}</td>
                  </tr>
                  <tr className="canclick">
                    <td>汽机进汽量(t)</td>
                    <td>{this.state.monthobjdata.M_InVol1}</td>
                    <td>{this.state.yearobjdata.Y_InVol1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>平均进汽压力(MPa)</td>
                    <td>{this.state.monthobjdata.M_InPre1}</td>
                    <td>{this.state.yearobjdata.Y_InPre1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>平均进汽温度(℃)</td>
                    <td>{this.state.monthobjdata.M_InTem1}</td>
                    <td>{this.state.yearobjdata.Y_InTem1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>平均排汽温度(℃)</td>
                    <td>{this.state.monthobjdata.M_ExTem1}</td>
                    <td>{this.state.yearobjdata.Y_ExTem1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>平均真空(kPa)</td>
                    <td>{this.state.monthobjdata.M_Vacuu1}</td>
                    <td>{this.state.yearobjdata.Y_Vacuu1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>凝结水平均温度(℃)</td>
                    <td>{this.state.monthobjdata.M_CondT1}</td>
                    <td>{this.state.yearobjdata.Y_CondT1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>冷却水进口平均温度(℃)</td>
                    <td>{this.state.monthobjdata.M_InTXW1}</td>
                    <td>{this.state.yearobjdata.Y_InTXW1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>冷却水出口平均温度(℃)</td>
                    <td>{this.state.monthobjdata.M_OuTXW1}</td>
                    <td>{this.state.yearobjdata.Y_OuTXW1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>冷却水平均温差(℃)</td>
                    <td>{this.state.monthobjdata.M_TDiff1}</td>
                    <td>{this.state.yearobjdata.Y_TDiff1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>冷却水平均端差(℃)</td>
                    <td>{this.state.monthobjdata.M_TerDi1}</td>
                    <td>{this.state.yearobjdata.Y_TerDi1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>平均过冷度(℃)</td>
                    <td>{this.state.monthobjdata.M_UndeD1}</td>
                    <td>{this.state.yearobjdata.Y_UndeD1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>平均汽耗率(kg/kW•h)</td>
                    <td>{this.state.monthobjdata.M_SteCR1}</td>
                    <td>{this.state.yearobjdata.Y_SteCR1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>除盐水补水量(t)</td>
                    <td>{this.state.monthobjdata.M_MDWSV1}</td>
                    <td>{this.state.yearobjdata.Y_MDWSV1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>锅炉平均补水率(%)</td>
                    <td>{this.state.monthobjdata.M_WaRRB1}</td>
                    <td>{this.state.yearobjdata.Y_WaRRB1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>取水口补水量(t)</td>
                    <td>{this.state.monthobjdata.M_WaTII1}</td>
                    <td>{this.state.yearobjdata.Y_WaTII1}</td>
                  </tr>
                  <tr className="canclick">
                    <td>工业水耗(kg/kW•h)</td>
                    <td>{this.state.monthobjdata.M_indWC1}</td>
                    <td>{this.state.yearobjdata.Y_indWC1}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
          <div className="baobiaocon3">
            <h2>锅炉运行情况</h2>
            <table>
              <tbody>
                <tr>
                  <th>项目</th>
                  <th>#1炉月累计</th>
                  <th>#2炉月累计</th>
                  <th>#1炉年累计</th>
                  <th>#2炉年累计</th>
                </tr>
              </tbody>
            </table>

            <div className="scrolltable">
              <table>
                <tbody>
                  <tr className="canclick1">
                    <td>锅炉运行时间(h)</td>
                    <td>{this.state.monthobjdata.M_FurRT1}</td>
                    <td>{this.state.monthobjdata.M_FurRT2}</td>
                    <td>{this.state.yearobjdata.Y_FurRT1}</td>
                    <td>{this.state.yearobjdata.Y_FurRT2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>入炉燃料量(t)</td>
                    <td>{this.state.monthobjdata.M_FutFC1}</td>
                    <td>{this.state.monthobjdata.M_FutFC2}</td>
                    <td>{this.state.yearobjdata.Y_FutFC1}</td>
                    <td>{this.state.yearobjdata.Y_FutFC2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>入炉平均热值(kcal)</td>
                    <td>{this.state.monthobjdata.M_CalVF1}</td>
                    <td>{this.state.monthobjdata.M_CalVF2}</td>
                    <td>{this.state.yearobjdata.Y_CalVF1}</td>
                    <td>{this.state.yearobjdata.Y_CalVF2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>锅炉蒸发量(t)</td>
                    <td>{this.state.monthobjdata.M_EvapB1}</td>
                    <td>{this.state.monthobjdata.M_EvapB2}</td>
                    <td>{this.state.yearobjdata.Y_EvapB1}</td>
                    <td>{this.state.yearobjdata.Y_EvapB2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>平均主蒸汽压力(MPa)</td>
                    <td>{this.state.monthobjdata.M_MsSPf1}</td>
                    <td>{this.state.monthobjdata.M_MsSPf2}</td>
                    <td>{this.state.yearobjdata.Y_MsSPf1}</td>
                    <td>{this.state.yearobjdata.Y_MsSPf2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>平均主蒸汽温度(℃)</td>
                    <td>{this.state.monthobjdata.M_MaSTF1}</td>
                    <td>{this.state.monthobjdata.M_MaSTF2}</td>
                    <td>{this.state.yearobjdata.Y_MaSTF1}</td>
                    <td>{this.state.yearobjdata.Y_MaSTF2}</td>
                  </tr>
                  <tr className="canclick">
                    <td>给水流量(t)</td>
                    <td>{this.state.monthobjdata.M_FeWFF1}</td>
                    <td>{this.state.monthobjdata.M_FeWFF2}</td>
                    <td>{this.state.yearobjdata.Y_FeWFF1}</td>
                    <td>{this.state.yearobjdata.Y_FeWFF2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>平均给水温度(℃)</td>
                    <td>{this.state.monthobjdata.M_FeWTF1}</td>
                    <td>{this.state.monthobjdata.M_FeWTF2}</td>
                    <td>{this.state.yearobjdata.Y_FeWTF1}</td>
                    <td>{this.state.yearobjdata.Y_FeWTF2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>锅炉平均效率(%)</td>
                    <td>{this.state.monthobjdata.M_EffiB1}</td>
                    <td>{this.state.monthobjdata.M_EffiB2}</td>
                    <td>{this.state.yearobjdata.Y_EffiB1}</td>
                    <td>{this.state.yearobjdata.Y_EffiB2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>平均排烟温度(℃)</td>
                    <td>{this.state.monthobjdata.M_ExGTF1}</td>
                    <td>{this.state.monthobjdata.M_ExGTF2}</td>
                    <td>{this.state.yearobjdata.Y_ExGTF1}</td>
                    <td>{this.state.yearobjdata.Y_ExGTF2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>环境平均温度(℃)</td>
                    <td>{this.state.monthobjdata.M_EnvTF1}</td>
                    <td>{this.state.monthobjdata.M_EnvTF2}</td>
                    <td>{this.state.yearobjdata.Y_EnvTF1}</td>
                    <td>{this.state.yearobjdata.Y_EnvTF2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>排烟平均温度差(℃)</td>
                    <td>{this.state.monthobjdata.M_FFGTD1}</td>
                    <td>{this.state.monthobjdata.M_FFGTD2}</td>
                    <td>{this.state.yearobjdata.Y_FFGTD1}</td>
                    <td>{this.state.yearobjdata.Y_FFGTD2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>炉渣平均含碳量(%)</td>
                    <td>{this.state.monthobjdata.M_CaCFS1}</td>
                    <td>{this.state.monthobjdata.M_CaCFS2}</td>
                    <td>{this.state.yearobjdata.Y_CaCFS1}</td>
                    <td>{this.state.yearobjdata.Y_CaCFS2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>炉灰平均含碳量(%)</td>
                    <td>{this.state.monthobjdata.M_CaCFA1}</td>
                    <td>{this.state.monthobjdata.M_CaCFA2}</td>
                    <td>{this.state.yearobjdata.Y_CaCFA1}</td>
                    <td>{this.state.yearobjdata.Y_CaCFA2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>平均氧量(%)</td>
                    <td>{this.state.monthobjdata.M_OxygC1}</td>
                    <td>{this.state.monthobjdata.M_OxygC2}</td>
                    <td>{this.state.yearobjdata.Y_OxygC1}</td>
                    <td>{this.state.yearobjdata.Y_OxygC2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>平均汽料比(t/t)</td>
                    <td colSpan="2">{this.state.monthobjdata.M_StCRF1}</td>
                    {/* <td>{this.state.monthobjdata.M_StCRF2}</td> */}
                    <td colSpan="2">{this.state.yearobjdata.Y_StCRF1}</td>
                    {/* <td>{this.state.yearobjdata.Y_StCRF2}</td> */}
                  </tr>
                  <tr className="canclick1">
                    <td>平均启停燃料调整(t)</td>
                    <td>{this.state.monthobjdata.M_FSSFA1}</td>
                    <td>{this.state.monthobjdata.M_FSSFA2}</td>
                    <td>{this.state.yearobjdata.Y_FSSFA1}</td>
                    <td>{this.state.yearobjdata.Y_FSSFA2}</td>
                  </tr>
                  <tr className="canclick1">
                    <td>锅炉计数器</td>
                    <td>{this.state.monthobjdata.M_BoilC1}</td>
                    <td>{this.state.monthobjdata.M_BoilC2}</td>
                    <td>{this.state.yearobjdata.Y_BoilC1}</td>
                    <td>{this.state.yearobjdata.Y_BoilC2}</td>
                  </tr>
                  <tr className="canclick">
                    <td>汽机计数器</td>
                    <td colSpan="2">{this.state.monthobjdata.M_SteTC1}</td>
                    {/* <td>{this.state.monthobjdata.M_SteTC2}</td> */}
                    <td colSpan="2">{this.state.yearobjdata.Y_SteTC1}</td>
                    {/* <td>{this.state.yearobjdata.Y_SteTC2}</td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Drawer
          title={null}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="inputbaobiao"
        >
          <div className="zonghe">
            <h5 className="h5title">综合经济指标</h5>
            <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="发电量(万kwh)">
                    {getFieldDecorator('GenerationCapacity', {
                      initialValue: this.state.iptobj['GenerationCapacity'],
                      rules: [{ required: false, message: '请输入发电量' }],
                    })(<Input 
                    // placeholder="发电量" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="上网电量(万kwh)">
                    {getFieldDecorator('OnlineEnergy', {
                      initialValue: this.state.iptobj['OnlineEnergy'],
                      rules: [{ required: false, message: '请输入上网电量' }],
                    })(
                      <Input
                        // placeholder="上网电量"

                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="厂用电量(万kwh)">
                    {getFieldDecorator('FactoryPower', {
                      initialValue: this.state.iptobj['FactoryPower'],
                      rules: [{ required: false, message: '请输入厂用电量' }],
                    })(<Input 
                      // placeholder="厂用电量"
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="燃料消耗量(t)">
                    {getFieldDecorator('FuelCT', {
                      initialValue: this.state.iptobj['FuelCT'],
                      rules: [{ required: false, message: '请输入燃料消耗量' }],
                    })(
                      <Input
                        // placeholder="燃料消耗量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="燃料单耗(kg/kwh)">
                    {getFieldDecorator('FuelCo', {
                      initialValue: this.state.iptobj['FuelCo'],
                      rules: [{ required: false, message: '请输入燃料单耗' }],
                    })(<Input 
                    // placeholder="燃料单耗" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#1母管供汽(t)">
                    {getFieldDecorator('MainSS1', {
                      initialValue: this.state.iptobj['MainSS1'],
                      rules: [{ required: false, message: '请输入#1母管供汽' }],
                    })(
                      <Input
                        // placeholder="#1母管供汽"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#2母管供汽(t)">
                    {getFieldDecorator('MainSS2', {
                      initialValue: this.state.iptobj['MainSS2'],
                      rules: [{ required: false, message: '请输入#2母管供汽' }],
                    })(<Input 
                    // placeholder="#2母管供汽" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="售汽(t)">
                    {getFieldDecorator('SaleSt', {
                      initialValue: this.state.iptobj['SaleSt'],
                      rules: [{ required: false, message: '请输入售汽' }],
                    })(
                      <Input
                        // placeholder="售汽"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="燃料进量(t)">
                    {getFieldDecorator('FuelIn', {
                      initialValue: this.state.iptobj['FuelIn'],
                      rules: [{ required: false, message: '请输入燃料进量' }],
                    })(<Input 
                    // placeholder="燃料进量" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="初始库存(t)">
                    {getFieldDecorator('YearStock', {
                      initialValue: this.state.iptobj['YearStock'],
                      rules: [{ required: false, message: '请输入初始库存' }],
                    })(<Input 
                      // placeholder="初始库存"
                      readOnly={this.state.iptobj['YearStock'] ? true : false} />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>

          <div className="qijiyunxing">
            <h5 className="h5title">汽机运行情况</h5>
            <Form className="form2" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
            // style={{ height: (this.state.clientHeight - 40 - 80) / 3 - 50 + 'px' }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="运行时间(h)">
                    {getFieldDecorator('RunTi1', {
                      initialValue: this.state.iptobj['RunTi1'],
                      rules: [{ required: false, message: '请输入运行时间' }],
                    })(<Input 
                    // placeholder="运行时间" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="汽机进汽量(t)">
                    {getFieldDecorator('InVol1', {
                      initialValue: this.state.iptobj['InVol1'],
                      rules: [{ required: false, message: '请输入汽机进汽量' }],
                    })(
                      <Input
                        // placeholder="汽机进汽量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="进汽压力(MPa)">
                    {getFieldDecorator('InPre1', {
                      initialValue: this.state.iptobj['InPre1'],
                      rules: [{ required: false, message: '请输入进汽压力' }],
                    })(<Input 
                    // placeholder="进汽压力" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="进汽温度(℃)">
                    {getFieldDecorator('InTem1', {
                      initialValue: this.state.iptobj['InTem1'],
                      rules: [{ required: false, message: '请输入进汽温度' }],
                    })(
                      <Input
                        // placeholder="进汽温度"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="排汽温度(℃)">
                    {getFieldDecorator('ExTem1', {
                      initialValue: this.state.iptobj['ExTem1'],
                      rules: [{ required: false, message: '请输入排汽温度' }],
                    })(<Input 
                    // placeholder="排汽温度" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="真空(kPa)">
                    {getFieldDecorator('Vacuu1', {
                      initialValue: this.state.iptobj['Vacuu1'],
                      rules: [{ required: false, message: '请输入真空' }],
                    })(
                      <Input
                        // placeholder="真空"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="凝结水温度(℃)">
                    {getFieldDecorator('CondT1', {
                      initialValue: this.state.iptobj['CondT1'],
                      rules: [{ required: false, message: '请输入凝结水温度' }],
                    })(<Input
                    //  placeholder="凝结水温度" 
                     />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="冷却水进口温度(℃)">
                    {getFieldDecorator('InTXW1', {
                      initialValue: this.state.iptobj['InTXW1'],
                      rules: [{ required: false, message: '请输入冷却水进口温度' }],
                    })(
                      <Input
                        // placeholder="冷却水进口温度"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="冷却水出口温度(℃)">
                    {getFieldDecorator('OuTXW1', {
                      initialValue: this.state.iptobj['OuTXW1'],
                      rules: [{ required: false, message: '请输入冷却水出口温度' }],
                    })(<Input 
                    // placeholder="冷却水出口温度" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="除盐水补水量(t)">
                    {getFieldDecorator('MDWSV1', {
                      initialValue: this.state.iptobj['MDWSV1'],
                      rules: [{ required: false, message: '请输入除盐水补水量' }],
                    })(
                      <Input
                        // placeholder="除盐水补水量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="取水口补水量(t)">
                    {getFieldDecorator('WaTII1', {
                      initialValue: this.state.iptobj['WaTII1'],
                      rules: [{ required: false, message: '请输入取水口补水量' }],
                    })(<Input 
                    // placeholder="取水口补水量" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="汽机计数器">
                    {getFieldDecorator('SteTC1', {
                      initialValue: this.state.iptobj['SteTC1'],
                      rules: [{ required: false, message: '请输入汽机计数器' }],
                    })(
                      <Input
                        // placeholder="汽机计数器"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>

          <div className="guolu" style={{ height: this.state.clientHeight -50-180-210- 60 + 'px' }}>
            <h5 className="h5title">锅炉运行情况</h5>
            <Form className="form3" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
              style={{ height: this.state.clientHeight -50-180-210- 60-30  + 'px' ,overflowY:"auto"}}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉运行时间(h)">
                    {getFieldDecorator('FurRT1', {
                      initialValue: this.state.iptobj['FurRT1'],
                      rules: [{ required: false, message: '请输入#1炉运行时间' }],
                    })(<Input 
                    // placeholder="#1炉运行时间" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉运行时间(h)">
                    {getFieldDecorator('FurRT2', {
                      initialValue: this.state.iptobj['FurRT2'],
                      rules: [{ required: false, message: '请输入#2炉运行时间' }],
                    })(
                      <Input
                        // placeholder="#2炉运行时间"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉入炉燃料量(t)">
                    {getFieldDecorator('FutFC1', {
                      initialValue: this.state.iptobj['FutFC1'],
                      rules: [{ required: false, message: '请输入#1炉入炉燃料量' }],
                    })(<Input 
                    // placeholder="#1炉入炉燃料量"
                     />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉入炉燃料量(t)">
                    {getFieldDecorator('FutFC2', {
                      initialValue: this.state.iptobj['FutFC2'],
                      rules: [{ required: false, message: '请输入#2炉入炉燃料量' }],
                    })(
                      <Input
                        // placeholder="#2炉入炉燃料量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉入炉热值(kcal)">
                    {getFieldDecorator('CalVF1', {
                      initialValue: this.state.iptobj['CalVF1'],
                      rules: [{ required: false, message: '请输入#1炉入炉热值' }],
                    })(<Input 
                    // placeholder="#1炉入炉热值" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉入炉热值(kcal)">
                    {getFieldDecorator('CalVF2', {
                      initialValue: this.state.iptobj['CalVF2'],
                      rules: [{ required: false, message: '请输入#2炉入炉热值' }],
                    })(
                      <Input
                        // placeholder="#2炉入炉热值"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉锅炉蒸发量(t)">
                    {getFieldDecorator('EvapB1', {
                      initialValue: this.state.iptobj['EvapB1'],
                      rules: [{ required: false, message: '请输入#1炉锅炉蒸发量' }],
                    })(<Input 
                    // placeholder="#1炉锅炉蒸发量" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉锅炉蒸发量(t)">
                    {getFieldDecorator('EvapB2', {
                      initialValue: this.state.iptobj['EvapB2'],
                      rules: [{ required: false, message: '请输入#2炉锅炉蒸发量' }],
                    })(
                      <Input
                        // placeholder="#2炉锅炉蒸发量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉主蒸汽压力(MPa)">
                    {getFieldDecorator('MsSPf1', {
                      initialValue: this.state.iptobj['MsSPf1'],
                      rules: [{ required: false, message: '请输入#1炉主蒸汽压力' }],
                    })(<Input 
                    // placeholder="#1炉主蒸汽压力" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉主蒸汽压力(MPa)">
                    {getFieldDecorator('MsSPf2', {
                      initialValue: this.state.iptobj['MsSPf2'],
                      rules: [{ required: false, message: '请输入#2炉主蒸汽压力' }],
                    })(
                      <Input
                        // placeholder="#2炉主蒸汽压力"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉主蒸汽温度(℃)">
                    {getFieldDecorator('MaSTF1', {
                      initialValue: this.state.iptobj['MaSTF1'],
                      rules: [{ required: false, message: '请输入#1炉主蒸汽温度' }],
                    })(<Input 
                    // placeholder="#1炉主蒸汽温度" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉主蒸汽温度(℃)">
                    {getFieldDecorator('MaSTF2', {
                      initialValue: this.state.iptobj['MaSTF2'],
                      rules: [{ required: false, message: '请输入#2炉主蒸汽温度' }],
                    })(
                      <Input
                        // placeholder="#2炉主蒸汽温度"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉给水流量(t)">
                    {getFieldDecorator('FeWFF1', {
                      initialValue: this.state.iptobj['FeWFF1'],
                      rules: [{ required: false, message: '请输入#1炉给水流量' }],
                    })(<Input 
                    // placeholder="#1炉给水流量" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉给水流量(t)">
                    {getFieldDecorator('FeWFF2', {
                      initialValue: this.state.iptobj['FeWFF2'],
                      rules: [{ required: false, message: '请输入#2炉给水流量' }],
                    })(
                      <Input
                        // placeholder="#2炉给水流量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉给水温度(℃)">
                    {getFieldDecorator('FeWTF1', {
                      initialValue: this.state.iptobj['FeWTF1'],
                      rules: [{ required: false, message: '请输入#1炉给水温度' }],
                    })(<Input 
                    // placeholder="#1炉给水温度" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉给水温度(℃)">
                    {getFieldDecorator('FeWTF2', {
                      initialValue: this.state.iptobj['FeWTF2'],
                      rules: [{ required: false, message: '请输入#2炉给水温度' }],
                    })(
                      <Input
                        // placeholder="#2炉给水温度"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉锅炉效率(%)">
                    {getFieldDecorator('EffiB1', {
                      initialValue: this.state.iptobj['EffiB1'],
                      rules: [{ required: false, message: '请输入#1炉锅炉效率' }],
                    })(<Input 
                    // placeholder="#1炉锅炉效率" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉锅炉效率(%)">
                    {getFieldDecorator('EffiB2', {
                      initialValue: this.state.iptobj['EffiB2'],
                      rules: [{ required: false, message: '请输入#2炉锅炉效率' }],
                    })(
                      <Input
                        // placeholder="#2炉锅炉效率"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉排烟温度(℃)">
                    {getFieldDecorator('ExGTF1', {
                      initialValue: this.state.iptobj['ExGTF1'],
                      rules: [{ required: false, message: '请输入#1炉排烟温度' }],
                    })(<Input 
                    // placeholder="#1炉排烟温度" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉排烟温度(℃)">
                    {getFieldDecorator('ExGTF2', {
                      initialValue: this.state.iptobj['ExGTF2'],
                      rules: [{ required: false, message: '请输入#2炉排烟温度' }],
                    })(
                      <Input
                        // placeholder="#2炉排烟温度"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉环境温度(℃)">
                    {getFieldDecorator('EnvTF1', {
                      initialValue: this.state.iptobj['EnvTF1'],
                      rules: [{ required: false, message: '请输入#1炉环境温度' }],
                    })(<Input 
                    // placeholder="#1炉环境温度" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉环境温度(℃)">
                    {getFieldDecorator('EnvTF2', {
                      initialValue: this.state.iptobj['EnvTF2'],
                      rules: [{ required: false, message: '请输入#2炉环境温度' }],
                    })(
                      <Input
                        // placeholder="#2炉环境温度"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉炉渣含碳量(%)">
                    {getFieldDecorator('CaCFS1', {
                      initialValue: this.state.iptobj['CaCFS1'],
                      rules: [{ required: false, message: '请输入#1炉炉渣含碳量' }],
                    })(<Input 
                    // placeholder="#1炉炉渣含碳量" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉炉渣含碳量(%)">
                    {getFieldDecorator('CaCFS2', {
                      initialValue: this.state.iptobj['CaCFS2'],
                      rules: [{ required: false, message: '请输入#2炉炉渣含碳量' }],
                    })(
                      <Input
                        // placeholder="#2炉炉渣含碳量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉炉灰含碳量(%)">
                    {getFieldDecorator('CaCFA1', {
                      initialValue: this.state.iptobj['CaCFA1'],
                      rules: [{ required: false, message: '请输入#1炉炉灰含碳量' }],
                    })(<Input 
                    // placeholder="#1炉炉灰含碳量" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉炉灰含碳量(%)">
                    {getFieldDecorator('CaCFA2', {
                      initialValue: this.state.iptobj['CaCFA2'],
                      rules: [{ required: false, message: '请输入#2炉炉灰含碳量' }],
                    })(
                      <Input
                        // placeholder="#2炉炉灰含碳量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉氧量(%)">
                    {getFieldDecorator('OxygC1', {
                      initialValue: this.state.iptobj['OxygC1'],
                      rules: [{ required: false, message: '请输入#1炉氧量' }],
                    })(<Input 
                    // placeholder="#1炉氧量" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉氧量(%)">
                    {getFieldDecorator('OxygC2', {
                      initialValue: this.state.iptobj['OxygC2'],
                      rules: [{ required: false, message: '请输入#2炉氧量' }],
                    })(
                      <Input
                        // placeholder="#2炉氧量"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1炉启停燃料调整(t)">
                    {getFieldDecorator('FSSFA1', {
                      initialValue: this.state.iptobj['FSSFA1'],
                      rules: [{ required: false, message: '请输入#1炉启停燃料调整' }],
                    })(<Input 
                    // placeholder="#1炉启停燃料调整" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2炉启停燃料调整(t)">
                    {getFieldDecorator('FSSFA2', {
                      initialValue: this.state.iptobj['FSSFA2'],
                      rules: [{ required: false, message: '请输入#2炉启停燃料调整' }],
                    })(
                      <Input
                        // placeholder="#2炉启停燃料调整"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="#1锅炉计数器">
                    {getFieldDecorator('BoilC1', {
                      initialValue: this.state.iptobj['BoilC1'],
                      rules: [{ required: false, message: '请输入#1锅炉计数器' }],
                    })(<Input 
                    // placeholder="#1锅炉计数器" 
                    />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="#2锅炉计数器">
                    {getFieldDecorator('BoilC2', {
                      initialValue: this.state.iptobj['BoilC2'],
                      rules: [{ required: false, message: '请输入#2锅炉计数器' }],
                    })(
                      <Input
                        // placeholder="#2锅炉计数器"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>

          <div className="formbtn">
            <Button htmlType="submit" style={{display:this.state.buttonarr.indexOf('保存')>-1?"inline-block":"none"}} onClick={this.handleSubmit.bind(this)} className="login-form-button" type="primary">
              保存
            </Button>
          </div>
        </Drawer>
      </div>
    )
  }
}
const Report = Form.create()(ReportComp);
export default Report;
