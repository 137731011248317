import React from 'react'
import { Tree, Input, Spin, Select, Modal, Table, Button, Upload, message, Tooltip, Form, Row, Col, Drawer, InputNumber } from 'antd';
import './style.scss'
import $ from 'jquery'
import axios from 'axios'
import Draggable from 'react-draggable';
import { conversion } from '../../converse'
import { toProTreeData1 } from '../../utils/toprojecttreedata'
import { toKKSTreeData } from '../../utils/tokkstreedata'
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import reqwest from 'reqwest';
import "moment/locale/zh-cn"
import { onCellf } from '../../comfunc'
import FileViewer from 'react-file-viewer';
import Loop from '../../loopfunc'
import { Resizable } from 'react-resizable';
import store from '../../store';
import { getButtonLimit } from '../../utils/getbuttonlimit'
moment.locale('zh-cn')
const Option = Select.Option;
const confirm = Modal.confirm
const { Search } = Input;
const { TreeNode, DirectoryTree } = Tree;

class Documentmanage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonarr:[],

      counflag: false,
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      //设备树
      treeDataA: [],
      selectedKeysA: [],

      //kks树
      treeDataB: [],
      selectedKeysB: [],
      seleBReadName: '',
      //文档列表
      dataSource: [],
      dataSourcefu: [],
      dataSourcelength: 0,
      rowIdA: '',
      rowIdAobj: {},

      formiptobj: {},
      addedit: "",
      addeditname: '',
      visibledraw: false,

      //树属性

      attr: {
        ID: 'ID',
        ParentId: 'ParentId',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        RecordVersion: 'RecordVersion',
        UpdateTime: 'UpdateTime',
        UpdatePeople: 'UpdatePeople',
        CreatePeople: 'CreatePeople',
        CreateTime: 'CreateTime'
      },

      attrB: {
        KKS: 'KKS',
        ParentId: 'ParentId',
        ReadName: 'ReadName',
        rootId: "0",
        Name: 'Name'
      },
      pageSize: parseInt((store.getState().clientHeight - 50 - 80 - 50) / 28),
      pageIndex: 1,

      //是否初始化标志
      initialflag: 0,

      //弹框类别列表
      dataSourceB: [],
      dataSourceBfu: [],


      //专业宽高
      assetsviewleftw: 200,
      assetsviewcenterw: this.props.clientWidth - 200 - 200,
    }
    //文档
    this.columns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '8%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '参数名称',
        dataIndex: 'ParameterName',
        key: 'ParameterName',
        width: '22%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '参数类型',
        dataIndex: 'ParameterType',
        key: 'ParameterType',
        width: '10%',
        render: (text) => {
          if (text == "0") {
            return (<span>文本</span>)
          } else {
            return (<span>选择</span>)
          }
        }
      },
      {
        title: '选项内容',
        dataIndex: 'OptionContent',
        key: 'OptionContent',
        width: '20%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: '10%'
      },
      {
        title: '说明',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
    ]
    this.columnsB = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '8%',
        render: (text, record, index) => {
          return (<span>{index + 1}</span>)
        }
      },
      {
        title: '编码',
        dataIndex: 'ClassificationName',
        key: 'ClassificationName',
        width: '15%',
      },
      {
        title: '说明',
        dataIndex: 'Remark',
        key: 'Remark',
        width: '30%',
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '创建人',
        dataIndex: 'Makeman',
        key: 'Makeman',
        width: '16%',
      },
      {
        title: '创建时间',
        dataIndex: 'MakeDate',
        key: 'MakeDate',
        width: '18%',
        onCell: onCellf,
        render: this.renderf
      }
    ]

    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="bottom" className="managetooltip" title={text}>{text}</Tooltip>)
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    //console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(63)

    axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res => {
      //console.log(conversion(res))
      let attr = this.state.attr;
      let arr = toProTreeData1(conversion(res), attr)
      //console.log('专业树数据', arr)
      this.setState({
        treeDataA: arr
      })
    })
    axios.get('/DATA/ParaSet/EquipmentClassInfo.aspx?flag=10').then(data => {
      console.log('巡检参数类别列表', conversion(data))
      this.setState({
        dataSourceB: conversion(data),
        dataSourceBfu: conversion(data)
      })
    })
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 80 - 50) / 28),
    })
  }

  senddata(record) {
    console.log(record)
    this.setState({
      rowIdA: record.id,
      rowIdAobj: record,
      ParameterTypesele: Number(record.ParameterType)
    })
  }

  setRowClassName = (record) => {
    return record.id === this.state.rowIdA ? 'clickRowStyl' : 'clickRowSty';
  }

  //删除
  handleDelete(key) {
    var _this = this
    if (key == "参数详情") {
      if (this.state.rowIdA) {
        var key = this.state.rowIdA
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=5', {
              id: _this.state.rowIdA
            }).then(data => {
              //console.log(data)
              if (data.data == true || data.data.indexOf('true') > -1) {
                const dataSource = [..._this.state.dataSource];
                _this.setState({ dataSource: dataSource.filter(item => item.id !== key), rowIdAobj: {}, rowIdA: "" }, function () {
                  message.success('删除成功！')
                });
              }
            })
          },
          onCancel() {
            _this.setState({
              // detaillistId: ''
            })
          },
        });
      } else {
        message.error('请选择!')
      }
    }
  }

  handleAdd(key) {
    if (key == "参数详情") {
      this.setState({
        addedit: "添加",
        addeditname: key,
        formiptobj: {},
        visibledraw: true,
        ParameterTypesele: 0
      }, function () {
        this.props.form.resetFields()
      })
    }
  }
  //编辑
  handleEdit(key) {
    this.props.form.resetFields()
    if (this.state.rowIdA) {
      var obj = this.state.rowIdAobj
      this.setState({
        addeditname: key,
        addedit: "编辑",
        visibledraw: true,
        formiptobj: obj
      })
    } else {
      message.error('请选择!')
    }
  }
  //设备树节点选择
  onTreeSelectA(selectedKeys, info) {
    // console.log(selectedKeys, info)
    this.setState({
      selectedKeysA: selectedKeys
    }, function () {
      //kks树
      axios.post(`/DATA/ParaSet/ParaSet.aspx?flag=40`, {
        ID: this.state.selectedKeysA[0]
      }).then(res => {
        // console.log('kks树',conversion(res))
        let attr = this.state.attrB;
        let arr = toKKSTreeData(conversion(res), attr)
        //console.log('设备资产树数据', arr)
        this.setState({
          treeDataB: arr
        })
      })
    })
  }
  //kks树节点选择
  onTreeSelectB(selectedKeys, info) {
    console.log(selectedKeys, info)
    if (selectedKeys.length == 0) {

    } else {
      if (selectedKeys[0].length >= 10) {
        this.setState({
          selectedKeysB: selectedKeys,
          seleBReadName: info.node.props.title,
          counflag: true
        }, function () {
          //设备参数
          axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=1', {
            DeviceKKS: this.state.selectedKeysB[0]
          }).then(data => {
            console.log('巡检设备参数', conversion(data))
            this.setState({
              dataSource: conversion(data),
              pageIndex: 1,
              counflag: false
            })
          })
          //判断是否初始化
          axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=3', {
            DeviceKKS: this.state.selectedKeysB[0],
          }).then(data => {
            console.log(data)
            this.setState({
              initialflag: data.data
            })
          })
        })
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (this.state.ParameterTypesele == "1") {
        values.OptionContent = values.OptionContent
      } else {
        values.OptionContent = ""
      }

      if (!err) {
        if (this.state.addedit == "添加") {
          if (this.state.addeditname == "参数详情") {
            values.DeviceKKS = this.state.selectedKeysB[0]
            values.DeviceName = this.state.seleBReadName
            axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=4', values).then(data => {
              if (data.data == true || data.data.indexOf("true") > -1) {

                axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=1', {
                  DeviceKKS: this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('巡检设备参数', conversion(data))
                  this.setState({
                    dataSource: conversion(data),
                    visibledraw: false
                  })
                })
              }
            })
          }
        } else if (this.state.addedit == "编辑") {
          if (this.state.addeditname == "参数详情") {
            values.DeviceKKS = this.state.selectedKeysB[0]
            values.DeviceName = this.state.seleBReadName
            values.id = this.state.rowIdA
            axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=5', values).then(data => {
              if (data.data == true || data.data.indexOf("true") > -1) {

                axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=1', {
                  DeviceKKS: this.state.selectedKeysB[0]
                }).then(data => {
                  console.log('巡检设备参数', conversion(data))
                  this.setState({
                    dataSource: conversion(data),
                    visibledraw: false
                  })
                })
              }
            })
          }
        }
      }
    });
  };
  onClose() {
    this.setState({
      visibledraw: false
    }, function () {
      this.props.form.resetFields();
    })
  }

  pagechange(page) {
    console.log(page, this.state.pageSize)
    this.setState({
      pageIndex: page.current,
    })
  }

  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  //初始化
  initialValue() {
    this.setState({
      rowIdB: ""
    }, function () {
      document.querySelector('.dragwindow').style.display = "block"
    })
  }
  //确定初始化
  initialcategoryValue() {
    if (this.state.rowIdB) {
      axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=2', {
        Parentid: this.state.rowIdB,
        DeviceKKS: this.state.selectedKeysB[0],
        DeviceName: this.state.seleBReadName,
      }).then(data => {
        console.log('初始化', data)
        if (data.data == true || data.data.indexOf("true") > -1) {
          axios.post('/DATA/OM/OM_DeviceParameter.aspx?flag=1', {
            DeviceKKS: this.state.selectedKeysB[0]
          }).then(data => {
            console.log('巡检设备参数', conversion(data))
            this.setState({
              dataSource: conversion(data)
            }, function () {
              message.success('初始化成功！')
              document.querySelector('.dragwindow').style.display = "none"
            })
          })
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  //取消初始化
  cancelinitialcategoryValue() {
    document.querySelector('.dragwindow').style.display = "none"
  }

  senddataA(record) {
    console.log(record)
    this.setState({
      rowIdB: record.ID
    })
  }
  setRowClassNameA = (record) => {
    return record.ID === this.state.rowIdB ? 'clickRowStyl' : 'clickRowSty';
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const searchValue = ""


    const loop = data =>
      data.map(item => {
        if (item.children) {
          return (
            // title={<Tooltip title={item.ReadName}>{item.CodeName+'-'+item.ReadName}</Tooltip>}
            <TreeNode key={item.ID} title={item.Code + '-' + item.Name}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.ID} title={item.Code + '-' + item.Name} />;
      });

    const loop1 = data =>
      data.map(item => {
        if (item.children) {
          return (
            <TreeNode key={item.KKS} title={item.ReadName}>
              {loop1(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.KKS} title={item.ReadName} />;
      });
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    return (
      <div className="inspectionspec" style={{ height: clientHeight - 50 + 'px' }}>

        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>
        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="inspectionspecdrag dragwindow" ref="inspectionspecdrag">
            <h2 className="h2title">参数类别初始化
              <span className="h2action">
                {/* <Button onClick={this.toclosedragwindow.bind(this)}>关闭</Button> */}
              </span>
            </h2>
            <h2 className="htitle">参数类别 <Search
              onMouseDown={(e) => { e.stopPropagation() }}
              placeholder="(名称)"
              onSearch={(v) => {
                var arr = this.state.dataSourceBfu
                var newarr = []
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].ClassificationName.indexOf(v) > -1) {
                    newarr.push(arr[i])
                  }
                }
                this.setState({
                  dataSourceB: newarr
                })
              }}
            /></h2>
            <div className="listscroll inspectionspecdraglist">
              <Table
                size="small"
                dataSource={this.state.dataSourceB}
                columns={this.columnsB}
                onRow={(record) => {
                  return {
                    onClick: this.senddataA.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassNameA}
                pagination={false}
                scroll={{ y: 290 }}
              />
            </div>
            <div className="action">
              <Button onClick={this.initialcategoryValue.bind(this)}>确定</Button>
              <Button onClick={this.cancelinitialcategoryValue.bind(this)}>取消</Button>
            </div>
          </div>
        </Draggable>


        <Drawer
          title={this.state.addeditname}
          onClose={this.onClose.bind(this)}
          visible={this.state.visibledraw}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit"
            style={{ height: clientHeight - 50 - 55 + 'px' }}>
            <Form className="form1" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="参数名称">
                    {getFieldDecorator('ParameterName', {
                      initialValue: this.state.formiptobj['ParameterName'],
                      rules: [{ required: true, message: '请输入参数名称' }],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="参数类型">
                    {getFieldDecorator('ParameterType', {
                      initialValue: this.state.addedit == "添加" ? this.state.ParameterTypesele : Number(this.state.formiptobj['ParameterType']),
                      rules: [{ required: true, message: '请选择参数类型' }],
                    })(
                      <Select onChange={(v) => { this.setState({ ParameterTypesele: v }) }}>
                        <Option value={0} key={0}>文本</Option>
                        <Option value={1} key={1}>选择</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ display: this.state.ParameterTypesele == 1 ? "block" : "none" }}>
                <Col span={12}>
                  <Form.Item label="选项内容">
                    {getFieldDecorator('OptionContent', {
                      initialValue: this.state.formiptobj['OptionContent'],
                      rules: [{ required: this.state.ParameterTypesele == "1" ? true : false, message: '请输入选项内容' }],
                    })(
                      <Input style={{ display: this.state.ParameterTypesele == 1 ? "block" : "none" }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="单位">
                    {getFieldDecorator('Unit', {
                      initialValue: this.state.formiptobj['Unit'],
                      rules: [{ required: true, message: '请选择单位' }],
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="textipt">
                <Col span={12}>
                  <Form.Item label="说明">
                    {getFieldDecorator('Remark', {
                      initialValue: this.state.formiptobj['Remark'],
                      // rules: [{ required: true, message: '请输入备注' }],
                    })(
                      <TextArea rows={4} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="formbutton">
                <Form.Item>
                  <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                    保存
          </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </Drawer>

        <div className="tree">
          <h2 className="h2title">设备树 </h2>
          <div className="treedata" style={{ height: clientHeight - 50 - 50 + 'px' }}>
            <DirectoryTree
              multiple
              selectedKeys={this.state.selectedKeysA}
              onSelect={this.onTreeSelectA.bind(this)}
            >
              {loop(this.state.treeDataA)}
            </DirectoryTree>
          </div>
        </div>
        <div className="inspectionspeclist" style={{ width: clientWidth - 200 + 'px' }}>
          <h2 className="h2title">运维规范配置 </h2>
          <div className="inspectionspeclistcon">
            <Resizable className="box" axis="x" minConstraints={[100, 100]} maxConstraints={[500, 500]} height={clientHeight - 50} width={this.state.assetsviewleftw}
              onResize={(event, { element, size }) => {
                this.setState({ assetsviewleftw: size.width, assetsviewcenterw: clientWidth - size.width - 200 });
              }}>
              <div className="kkstree" style={{ width: this.state.assetsviewleftw + 'px' }}>
                <h2 className="h2title">KKS树</h2>
                <div className="kkstreedata" style={{ height: clientHeight - 50 - 40 - 50 + 'px' }}>
                  <Tree
                    selectedKeys={this.state.selectedKeysB}
                    onSelect={this.onTreeSelectB.bind(this)}
                  >
                    {loop1(this.state.treeDataB)}
                  </Tree>
                </div>
              </div>
            </Resizable>
            <div className="inspectionspecdata" style={{ width: this.state.assetsviewcenterw + 'px' }}>

              <div className="action">
                {
                  this.state.initialflag == 0 ?
                    <Button style={{display:this.state.buttonarr.indexOf('初始化')>-1?"inline-block":"none"}} onClick={this.initialValue.bind(this)}>初始化</Button> :
                    <Button style={{ background: "#CCC",display:this.state.buttonarr.indexOf('初始化')>-1?"inline-block":"none" }}>初始化</Button>
                }
                <Button style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '参数详情')}>添加</Button>
                <Button style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this, '参数详情')}>编辑</Button>
                <Button style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.handleDelete.bind(this, '参数详情')}>删除</Button>
              </div>
              <div className="listpage"
                style={{ height: clientHeight - 50 - 80 + 'px' }}>
                <Table
                  size="small"
                  dataSource={this.state.dataSource}
                  columns={this.columns}
                  pagination={{
                    pageSize: this.state.pageSize,
                    current: this.state.pageIndex,
                    total: this.state.dataSourcelength
                  }}
                  onChange={this.pagechange.bind(this)}
                  onRow={(record) => {
                    return {
                      onClick: this.senddata.bind(this, record)
                    }
                  }}
                  rowClassName={this.setRowClassName}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
const Documentmanageapp = Form.create()(Documentmanage);
export default Documentmanageapp;