import React from 'react';
import { Table, Input, Tree, Tooltip, Form, Button, Select, Tabs, Drawer, Spin, Modal, Row, Col, Steps, Radio, DatePicker, message } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import $ from 'jquery'
import echarts from 'echarts';
import './style.scss'
import moment from 'moment'
// import "moment/locale/zh-cn"
// moment.locale('zh-cn')
import Draggable from 'react-draggable';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { onCellf } from '../../comfunc'
import { getButtonLimit } from '../../utils/getbuttonlimit'
import store from '../../store/index'
const { Option } = Select;
const TabPane = Tabs.TabPane;
const { TreeNode, DirectoryTree } = Tree;
const { Search, TextArea } = Input;
const { confirm } = Modal
const { Step } = Steps
class ProcessManagement extends React.Component {
  constructor(props) {
    super(props);
    console.log(store.getState())
    this.state = {
      buttonarr:[],

      counflag: false,//loading

      dataSourcedepartment: store.getState().deptdata,//部门数据
      //拖拽
      activeDrags: 0,
      controlledPositionA: {
        x: 0, y: 0
      },
      //树
      treedata: [],
      selectedKeys: [],
      addflowname: '',
      attr: {
        ID: 'ID',
        FlowTypeName: 'FlowTypeName',
        rootId: 0,
        leaf: 'leaf',
        Remark: 'Remark',
        ParentTypeID: 'ParentTypeID',
      },

      visible: false,
      addedit: '',
      addeditname: '工作票',
      addflownamefu: '工作票',

      // formiptdatabobj:{ NodeName:"起点",NodeIndex:"0",NodeTypeID:"类型",PreviousNodeID:"",SaveFunction:"",BackFunction:"",CancelFunction:"",Authority_Roles:"",Authority_UserIDS:"",Remark:"" },

      // formiptdataobj:{ 
      // ID:'1',FlowName:"2",FlowTypeID:"3",MasterTable:"4",MasterTableKeyField:"5",Authority_CorpPositions:"6" ,
      // Nodes: [ { NodeName:"起点",NodeIndex:"0",NodeTypeID:"类型",PreviousNodeID:"1",SaveFunction:"1",BackFunction:"1",CancelFunction:"1",Authority_Roles:"1",Authority_UserIDS:"1",Remark:"cce" }, 
      // { NodeName:"审核1",NodeIndex:"1",NodeTypeID:"类型",PreviousNodeID:"1",SaveFunction:"1",BackFunction:"1",CancelFunction:"1",Authority_Roles:"1",Authority_UserIDS:"1",Remark:"cce" }, 
      // { NodeName:"审核2",NodeIndex:"2",NodeTypeID:"类型",PreviousNodeID:"1",SaveFunction:"1",BackFunction:"1",CancelFunction:"1",Authority_Roles:"1",Authority_UserIDS:"1",Remark:"cce" },
      // { NodeName:"终点",NodeIndex: 'z',NodeTypeID:"类型",PreviousNodeID:"1",SaveFunction:"1",BackFunction:"1",CancelFunction:"1",Authority_Roles:"1",Authority_UserIDS:"1",Remark:"cce"}] },

      formiptdatabobj: {},
      formiptdataobj: {},
      stepcon: [],
      stepcurrent: 0,

      detaillistId: '',

      //表
      dataSource: [],
      pageIndex: 1,
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 40 - 50) / 28),


      //用户数据
      dataSourceuser: store.getState().userdata,
      dataSourcerole: [],

      IsCondition:true,
      IsAssignNode:true,
      //编辑界面
      HtmlData:
        `[{ name: '检验单', field: 'dan', type: 'input', require: 'y', value: '0' },{ name: '检验日期', field: 'dandate', type: 'date', require: 'y', value: '2019-10-11' },{ name: '数量', field: 'num', type: 'number', require: 'y', value: 3 },{ name: '检验标准', field: 'standard', type: 'input', require: 'y', value: '标准' },{ name: '检验结论', field: 'answer', type: 'text', require: 'n', value: '恩才能成就大傅就' }]`
    };
    //基础属性 供应商 责任相关 技术参数  文档关联
    this.columns = [
      {
        title: '序号',
        dataIndex: 'xu',
        key: 'xu',
        width: '6%',
        render: (text, record, index) => {
          return (<span>{(this.state.pageIndex - 1) * this.state.pageSize + index + 1}</span>)
        }
      },
      {
        title: '流程编号',
        dataIndex: 'FlowNumber',
        key: 'FlowNumber',
        width: '28%', 
        onCell: onCellf,
        render: this.renderf
      },
      {
        title: '流程名称',
        dataIndex: 'FlowName',
        key: 'FlowName',
        width: '28%', onCell: onCellf,
        render: this.renderf
      },
      {
        title: '关联主表',
        dataIndex: 'MasterTable',
        key: 'MasterTable',
        width: '12%', onCell: onCellf,
        render: this.renderf
      },
      {
        title: '创建人',
        dataIndex: 'CreateUser',
        key: 'CreateUser',
        width: '10%',
      },
      {
        title: '创建时间',
        dataIndex: 'CreateTime',
        key: 'CreateTime',
        width: '16%',
      }
    ]
    this.renderf = this.renderf.bind(this)
    this.toTreeData = this.toTreeData.bind(this)
    this.run = this.run.bind(this)

    this.arrtostr = this.arrtostr.bind(this)
  }
  renderf(text) {
    if (text && text.indexOf('T') > -1 && text.indexOf(':') > -1) {
      var str = text.split('T')[0] + ' ' + text.split('T')[1]
      return (<span>{str}</span>)
    } else {
      return (<Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip>)
    }
  }
  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(30)

    //审核部门初始化   
    // axios.get('/DATA/WebSet/WebSet.aspx?flag=1').then(data => {
    //   console.log('审核部门初始化', data)
    //   this.setState({
    //     dataSourcedepartment: conversion(data)
    //   })
    // })

    axios.get('/DATA/WebSet/UserRole.aspx?flag=1').then(data => {
      // console.log('角色数据', conversion(data))
      this.setState({
        dataSourcerole: conversion(data)
      })
    })

    var service = sessionStorage.getItem('service')
    this.setState({
      service: service
    })

    //流程树数据
    axios.get('/DATA/WorkFlow/WorkFlow.aspx?flag=1').then(data => {
      console.log('流程树数据',data)
      if (data.data.success == true) {
        var arr = data.data.data
        var newarr = this.toTreeData(arr, this.state.attr)
        // console.log('组成树',newarr)
        if (newarr.length > 0) {
          var selectedKeys = []
          selectedKeys.push(newarr[0].ID.toString())
          this.setState({
            treedata: newarr,
            selectedKeys:selectedKeys
          })
          axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=11', {
            FlowTypeID: newarr[0].ID
          }).then(data => {
            if (data.data.success == true) {
              this.setState({
                dataSource: data.data.data,
              })
            } else if (data.data.success == false) {
              message.error(data.data.describe)
            }
          })
        }
      } else if (data.data.success == false) {
        message.error(data.data.describe)
      }
    })

  }
  //树
  toTreeData(data, atr) {
    let tree = [];
    let resData = data;//数组
    let attr = atr //对象属性
    for (let i = 0; i < resData.length; i++) {
      if (resData[i].ParentTypeID === attr.rootId) {
        let obj = {
          ID: resData[i][attr.ID],
          ParentTypeID: resData[i][attr.ParentTypeID],
          FlowTypeName: resData[i][attr.FlowTypeName],
          Remark: resData[i][attr.Remark],
          children: [],
          leaf: resData[i][attr.leaf],
        };
        tree.push(obj);
        resData.splice(i, 1);
        i--;
      }
    }
    this.run(tree, resData, attr);
    return tree;
  }
  run(treeArrs, resData, atr) {
    //treeArrs 第一次排序
    var attr = atr//对象属性
    var resData = resData//数据
    if (resData.length > 0) {
      for (let i = 0; i < treeArrs.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (treeArrs[i].ID === resData[j][attr.ParentTypeID]) {
            let obj = {
              ID: resData[j][attr.ID],
              ParentTypeID: resData[j][attr.ParentTypeID],
              FlowTypeName: resData[j][attr.FlowTypeName],
              Remark: resData[j][attr.Remark],
              children: [],
              leaf: resData[j][attr.leaf],
            };
            treeArrs[i].children.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        this.run(treeArrs[i].children, resData, attr);
      }
    }
  }
  componentWillReceiveProps(nextprops) {
    //console.log(nextprops)
    this.setState({
      pageSize: parseInt((store.getState().clientHeight - 50 - 40 - 40 - 50) / 28)
    })
  }

  onSelectA(selectedKeys, info) {
    this.setState({
      selectedKeys: selectedKeys,
      addflowname: info.node.props.title,
      addflownamefu: info.node.props.title
    }, function () {
      axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=11', {
        FlowTypeID: this.state.selectedKeys[0]
      }).then(data => {
        if (data.data.success == true) {
          this.setState({
            dataSource: data.data.data,
            pageIndex: 1
          })
        } else if (data.data.success == false) {
          message.error(data.data.describe)
        }
      })
    })
  }
  onClicktocanceltreenode() {
    this.setState({
      selectedKeys: []
    })
  }
  handleStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }
  handleStop() {
    this.setState({ activeDrags: --this.state.activeDrags });
  }
  handleDragA(e, ui) {
    const { x, y } = this.state.controlledPositionA;
    this.setState({
      controlledPositionA: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  }
  //删除
  handleDel(key) {
    var _this = this
    if (key == "删除分类") {
      if (this.state.selectedKeys[0]) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=4', {
              id: _this.state.selectedKeys[0],
            }).then(data => {
              console.log(data)
              if (data.data.success == true) {
                axios.get('/DATA/WorkFlow/WorkFlow.aspx?flag=1').then(data => {
                  // console.log('流程树数据',data)
                  var arr = data.data.data
                  var newarr = _this.toTreeData(arr, _this.state.attr)
                  // console.log('组成树',newarr)
                  if (newarr.length > 0) {
                    _this.setState({
                      treedata: newarr,
                      selectedKeys: []
                    }, function () {
                      message.success('删除成功！')
                    })
                  }
                })
              } else if (data.data.success == false) {
                message.error(data.data.describe)
              }
            })
          },
          onCancel() {
            _this.setState({

            })
          },
        });
      } else {
        message.error('请选择！')
      }
    } else if (key == "删除流程") {
      if (this.state.detaillistId) {
        confirm({
          title: '确定要删除吗?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=14', {
              FlowID: _this.state.detaillistId,
            }).then(data => {
              // console.log(data)
              if (data.data.success == true) {
                const dataSource = [..._this.state.dataSource];
                _this.setState({ dataSource: dataSource.filter(item => item.ID !== _this.state.detaillistId), detaillistId: "" }
                  , function () {
                    message.success('删除成功！')
                  });
              } else if (data.data.success == false) {
                message.error(data.data.describe)
              }
            })
          },
          onCancel() {
            _this.setState({

            })
          },
        });
      } else {
        message.error('请选择！')
      }
    }
  }
  handleAdd(key) {
    if (key == "添加流程") {
      var formiptdataobj = {
        ID: '', FlowName: "", FlowTypeID: this.state.selectedKeys[0], MasterTable: "", MasterTableKeyField: "", Authority_CorpPositions: "",
        Nodes: [
          {
            NodeName: "起点", NodeIndex: "0", NodeTypeID: "0", PreviousNodeID: "", SaveFunction: "", BackFunction: "",
            CancelFunction: "", Authority_Roles: "", Authority_UserIDS: "", Authority_CorpPositions: "", Remark: ""
          },
          {
            NodeName: "审核", NodeIndex: "1", NodeTypeID: "1", PreviousNodeID: "", SaveFunction: "", BackFunction: "",
            CancelFunction: "", Authority_Roles: "", Authority_UserIDS: "", Authority_CorpPositions: "", Remark: ""
          },
          {
            NodeName: "终点", NodeIndex: '2', NodeTypeID: "2", PreviousNodeID: "", SaveFunction: "", BackFunction: "",
            CancelFunction: "", Authority_Roles: "", Authority_UserIDS: "", Authority_CorpPositions: "", Remark: ""
          }]
      }

      var formiptdatabobj = {
        NodeName: "起点", NodeIndex: "0", NodeTypeID: "0", PreviousNodeID: "", SaveFunction: "", BackFunction: "",
        CancelFunction: "", Authority_Roles: "", Authority_UserIDS: "", Authority_CorpPositions: "", Remark: "",
        IsCondition:true,IsAssignNode:true
      }

      if (this.state.selectedKeys[0]) {
        this.setState({
          visible: true,
          addedit: "添加",
          formiptdataobj,
          formiptdatabobj,
          stepcurrent: 0,
          stepcon: formiptdataobj.Nodes
        }, function () {
          document.querySelector('.processmanagementaddedit').style.display = "block"
          this.props.form.resetFields()
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "添加分类") {
      this.setState({
        addeditname: "流程分类",
        addedit: "添加",
        addflowname: ""
      }, function () {
        this.refs.processmanagedrag.style.display = "block"
      })
    }
  }
  handleEdit(key) {
    if (key == "编辑流程") {
      if (this.state.detaillistId) {
        this.setState({
          visible: true,
          addedit: "编辑",
        }, function () {
          document.querySelector('.processmanagementaddedit').style.display = "block"
        })
      } else {
        message.error('请选择！')
      }
    } else if (key == "编辑分类") {
      if (this.state.selectedKeys[0]) {
        var str = this.state.addflownamefu
        this.setState({
          addeditname: "流程分类",
          addedit: "编辑",
          addflowname: str
        }, function () {
          this.refs.processmanagedrag.style.display = "block"
        })
      } else {
        message.error('请选择！')
      }
    }
  }
  //流程分类添加保存
  auditcontentsave() {
    this.setState({
      counflag: true
    }, function () {
      if (this.state.addedit == "添加") {
        axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=2', {
          parentId: this.state.selectedKeys[0] ? this.state.selectedKeys[0] : 0,
          cFlowTypeName: this.state.addflowname
        }).then(data => {
          console.log(data)
          if (data.data.success == true) {
            axios.get('/DATA/WorkFlow/WorkFlow.aspx?flag=1').then(data => {
              // console.log('流程树数据',data)
              var arr = data.data.data
              var newarr = this.toTreeData(arr, this.state.attr)
              // console.log('组成树',newarr)
              if (newarr.length > 0) {
                this.setState({
                  treedata: newarr,
                  counflag: false
                }, function () {
                  this.refs.processmanagedrag.style.display = "none"
                })
              }
            })
          } else if (data.data.success == false) {
            message.error(data.data.describe)
          }
        })
      } else if (this.state.addedit == "编辑") {
        axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=3', {
          id: this.state.selectedKeys[0],
          FlowTypeName: this.state.addflowname
        }).then(data => {
          console.log(data)
          if (data.data.success == true) {
            axios.get('/DATA/WorkFlow/WorkFlow.aspx?flag=1').then(data => {
              // console.log('流程树数据',data)
              var arr = data.data.data
              var newarr = this.toTreeData(arr, this.state.attr)
              // console.log('组成树',newarr)
              if (newarr.length > 0) {
                this.setState({
                  treedata: newarr,
                  counflag: false
                }, function () {
                  this.refs.processmanagedrag.style.display = "none"
                })
              }
            })
          } else if (data.data.success == false) {
            message.error(data.data.describe)
          }
        })
      }
    })
  }
  
  onClose = () => {
    document.querySelector('.processmanagementaddedit').style.display = "none"
    this.setState({
      visible: false,
      detaillistId: ''
    }, function () {
      this.props.form.resetFields();
    });
  };
  arrtostr(arr) {
    console.log(arr)
    var str = ''
    var arr = arr
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (i == arr.length - 1) {
          str = str + arr[i]
        } else {
          str = str + arr[i] + ','
        }
      }
    } else {
      str = ''
    }
    return str
  }
  handleSubmitok = () => {

    this.props.form.validateFields((err, values) => {
      console.log(err, values)
      if (!err) {
        var deptsele = values.Authority_CorpPositions1
        this.onStepChange(this.state.stepcurrent)

        var formiptdataobj = this.state.formiptdataobj

        formiptdataobj.HtmlData = this.state.HtmlData
        formiptdataobj.FlowNumber = values.FlowNumber
        formiptdataobj.Authority_CorpPositions = deptsele
        delete formiptdataobj.Authority_CorpPositions1
        console.log(formiptdataobj)
        this.setState({
          counflag: true
        }, function () {
          if (formiptdataobj.FlowName == "") {
            message.error('流程名称不能为空！')
          } else {
            if (this.state.addedit == "编辑") {
              axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=13', {
                Flow: formiptdataobj
              }).then(data => {
                console.log(data)
                if (data.data.success == true) {
                  axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=11', {
                    FlowTypeID: this.state.selectedKeys[0]
                  }).then(data => {
                    this.setState({
                      dataSource: data.data.data,
                      counflag: false,
                      detaillistId: "",
                      recordobj: {}
                    }, function () {
                      document.querySelector('.processmanagementaddedit').style.display = "none"
                    })
                  })
                } else if (data.data.success == false) {
                  message.error(data.data.describe)
                }
              })
            } else if (this.state.addedit == "添加") {
              formiptdataobj.FlowTypeID = this.state.selectedKeys[0]
              formiptdataobj.ID = ""
              axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=12', {
                Flow: formiptdataobj
              }).then(data => {
                console.log(data)
                if (data.data.success == true) {
                  axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=11', {
                    FlowTypeID: this.state.selectedKeys[0]
                  }).then(data => {
                    this.setState({
                      dataSource: data.data.data,
                      counflag: false
                    }, function () {
                      document.querySelector('.processmanagementaddedit').style.display = "none"
                    })
                  })
                } else if (data.data.success == false) {
                  message.error(data.data.describe)
                }
              })
            }
          }
        })
      }
    })
  }
  //流程编辑取消
  handleSubmitcancel() {
    document.querySelector('.processmanagementaddedit').style.display = "none"
    this.props.form.resetFields();
  }

  //选中
  senddata(record) {
    this.setState({
      detaillistId: record.ID,
      recordobj: record
    }, function () {
      axios.post('/DATA/WorkFlow/WorkFlow.aspx?flag=15', {
        ID: this.state.detaillistId
      }).then(data => {
        console.log('原始数据', data.data)
        if (data.data.success == true) {
          var arr = data.data.data.Nodes ? data.data.data.Nodes : []
          this.setState({
            formiptdataobj: data.data.data,
            stepcon: arr,
            IsAssignNode:arr[0].IsAssignNode,
            IsCondition:arr[0].IsCondition,
            stepcurrent: 0,
            formiptdatabobj: data.data.data.Nodes ? data.data.data.Nodes[0] : {},
            HtmlData: data.data.data.HtmlData
          })
        } else if (data.data.success == false) {
          message.error(data.data.describe)
        }
      })
    })
  }

  setRowClassName = (record) => {
    return record.ID === this.state.detaillistId ? 'clickRowStyl' : 'clickRowSty';
  }

  pagechange(page) {
    this.setState({
      pageIndex: page.current,
    })
  }
  onStepChange(current) {
    // console.log('节点位置', current)
    // console.log('获取的表单值',this.props.form.getFieldsValue())

    //全部数据
    var formiptdataobj = this.state.formiptdataobj
    //节点数据
    var formiptdataNodesarr = formiptdataobj.Nodes

    // console.log('上级的节点值',formiptdataNodesarr[this.state.stepcurrent])
    var formobj = formiptdataNodesarr[this.state.stepcurrent]
    formobj.NodeName = this.props.form.getFieldValue("NodeName")
    formobj.NodeIndex = this.props.form.getFieldValue("NodeIndex")
    formobj.NodeTypeID = this.props.form.getFieldValue("NodeTypeID")
    formobj.PreviousNodeID = this.props.form.getFieldValue("PreviousNodeID").label ?
      this.props.form.getFieldValue("PreviousNodeID").key : this.props.form.getFieldValue("PreviousNodeID")

    formobj.SaveFunction = this.props.form.getFieldValue("SaveFunction")
    formobj.BackFunction = this.props.form.getFieldValue("BackFunction")
    formobj.CancelFunction = this.props.form.getFieldValue("CancelFunction")
    formobj.Authority_Roles = this.props.form.getFieldValue("Authority_Roles").length == 0 ? "" :
      this.props.form.getFieldValue("Authority_Roles").length == 1 ? this.props.form.getFieldValue("Authority_Roles")[0] :
        this.props.form.getFieldValue("Authority_Roles").join(',')

    formobj.Authority_UserIDS = this.props.form.getFieldValue("Authority_UserIDS").length == 0 ? "" :
      this.props.form.getFieldValue("Authority_UserIDS").length == 1 ? this.props.form.getFieldValue("Authority_UserIDS")[0] :
        this.props.form.getFieldValue("Authority_UserIDS").join(',')

    formobj.Authority_CorpPositions = this.props.form.getFieldValue("Authority_CorpPositions").length == 0 ? "" :
      this.props.form.getFieldValue("Authority_CorpPositions").length == 1 ? this.props.form.getFieldValue("Authority_CorpPositions")[0] :
        this.props.form.getFieldValue("Authority_CorpPositions").join(',')

        formobj.IsCondition = this.props.form.getFieldValue("IsCondition")
        formobj.Condition = this.props.form.getFieldValue("Condition")
        formobj.IsAssignNode = this.props.form.getFieldValue("IsAssignNode")
        formobj.AssignNode = this.props.form.getFieldValue("AssignNode")

    formobj.Remark = this.props.form.getFieldValue("Remark")

    formiptdataNodesarr.splice(this.state.stepcurrent, 1, formobj)

    formiptdataobj.Nodes = formiptdataNodesarr
    formiptdataobj.Authority_CorpPositions = this.props.form.getFieldValue("Authority_CorpPositions1")
    formiptdataobj.FlowName = this.props.form.getFieldValue("FlowName")
    // formiptdataobj.FlowTypeID = this.props.form.getFieldValue("FlowTypeID")
    formiptdataobj.MasterTable = this.props.form.getFieldValue("MasterTable")
    formiptdataobj.MasterTableKeyField = this.props.form.getFieldValue("MasterTableKeyField")


    // console.log('修改后的所有数据',formiptdataobj)

    this.setState({
      formiptdatabobj: {},
    }, function () {
      this.props.form.resetFields()
      var obj = this.state.formiptdataobj
      var newobj = obj.Nodes[current]
      this.setState({
        stepcurrent: current,
        formiptdatabobj: newobj,
        IsCondition:newobj.IsCondition,
        IsAssignNode:newobj.IsAssignNode,

        formiptdataobj: formiptdataobj,
      });
    })

  };
  //节点的添加
  handleAddjiedian() {
    this.onStepChange(this.state.stepcurrent)
    var dataobj = this.state.formiptdataobj
    var dataarrlen = dataobj.Nodes.length
    if (this.state.stepcurrent == dataarrlen - 1) {
      message.error('结束节点之后不能添加节点！')
    } else {
      this.setState({
        formiptdatabobj: {}
      }, function () {
        this.props.form.resetFields()
        //formiptdataobj a和b内容
        var curnum = this.state.stepcurrent
        var formiptdataobj = this.state.formiptdataobj
        //formiptdatabarr b内容
        var formiptdatabarr = formiptdataobj.Nodes

        var newobj = {
          NodeName: `审核new`, NodeIndex: 0, NodeTypeID: "1", PreviousNodeID: this.state.formiptdatabobj.ID,
          SaveFunction: "", BackFunction: "", CancelFunction: "", Authority_Roles: "", Authority_UserIDS: "",
          Authority_CorpPositions: "", Remark: "",IsCondition:false,Condition:"",IsAssignNode:false,AssignNode:""
        }

        formiptdatabarr.splice(curnum + 1, 0, newobj)
        //b内容
        formiptdataobj.Nodes = formiptdatabarr
        console.log('添加节点后数据', formiptdatabarr)
        this.setState({
          IsCondition:false,
          IsAssignNode:false,
          formiptdataobj: formiptdataobj,
          formiptdatabobj: newobj,
          stepcon: formiptdataobj.Nodes,
          stepcurrent: curnum + 1
        })
      })
    }
  }
  handleDeljiedian() {
    var curnum = this.state.stepcurrent
    var formiptdataobj = this.state.formiptdataobj
    var formiptdatabarr = formiptdataobj.Nodes
    if (curnum == 0 || curnum == formiptdatabarr.length - 1) {
      message.error('该节点不能删除!')
    } else {
      this.setState({
        formiptdatabobj: {}
      }, function () {
        this.props.form.resetFields()
        formiptdatabarr.splice(curnum, 1)
        console.log(formiptdatabarr)
        formiptdataobj.Nodes = formiptdatabarr

        var obj = formiptdataobj.Nodes[0]
        this.setState({
          stepcon: formiptdataobj.Nodes,
          formiptdataobj: formiptdataobj,
          stepcurrent: 0,
          formiptdatabobj: obj,
          IsAssignNode:obj.IsAssignNode,
          IsCondition:obj.IsCondition
        })
      })
    }
  }
  //编辑审核流程表单界面
  handleEditAuditpage() {
    this.setState({
      addeditname: "审核"
    }, function () {
      this.refs.processmanagedrag.style.display = "block"
    })
  }
  //drag页面保存
  auditsave() {
    this.refs.processmanagedrag.style.display = "none"
  }
  //drag页面取消关闭
  tocloseauditpage() {
    this.refs.processmanagedrag.style.display = "none"
  }
  render() {
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    const loop = data =>
      data.map(item => {
        if (item.children) {
          return (
            <TreeNode key={`${item.ID}`} title={item.FlowTypeName} leaf={item.leaf} name={item.Remark}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={`${item.ID}`} title={item.FlowTypeName} leaf={item.leaf} name={item.Remark} />;
      });
    const { getFieldDecorator } = this.props.form;
    return (
      <section className="processmanagement" style={{ height: clientHeight - 50 + 'px' }}>
        {/* 编辑审核表单界面 */}
        <Draggable
          position={this.state.controlledPositionA}
          onStart={this.handleStart.bind(this)}
          onDrag={this.handleDragA.bind(this)}
          onStop={this.handleStop.bind(this)}>
          <div className="processmanagedrag" ref="processmanagedrag">
            {
              this.state.addeditname == "审核" ?
                <div className="auditeditpage" ref="auditeditpage">
                  <h2>编辑审核表单界面
                    </h2>
                  <div className="content">
                    <TextArea rows={10} className="contentipt"
                      onMouseDown={(e) => { e.stopPropagation() }}
                      value={this.state.HtmlData}
                      onChange={(e) => { this.setState({ HtmlData: e.target.value }) }}
                    />
                  </div>
                  <div className="auditcontentsavecancel">
                    <Button type="primary" onClick={this.auditsave.bind(this)}>
                      保存
              </Button>
                    <Button onClick={this.tocloseauditpage.bind(this)}>
                      取消
              </Button>
                  </div>
                </div>
                : this.state.addeditname == "流程分类" ?
                  <div className="auditeditpage" ref="auditeditpage">
                    <h2>{this.state.addedit + '' + this.state.addeditname}
                    </h2>
                    <div className="contenta">
                      {this.state.addeditname}名称:<Input
                        className="addflowname"
                        value={this.state.addflowname}
                        onChange={(e) => { this.setState({ addflowname: e.target.value }) }}
                        onMouseDown={(e) => { e.stopPropagation() }} />
                    </div>
                    <div className="auditcontentsavecancel">
                      <Button type="primary" onClick={this.auditcontentsave.bind(this)}>
                        保存
                      </Button>
                      <Button onClick={this.tocloseauditpage.bind(this)}>
                        取消
                      </Button>
                    </div>
                  </div> : ""
            }
          </div>
        </Draggable>

        {/* <Drawer
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="processmanagementdraw"
        > */}
        <div className="processmanagementaddedit">
          <Form className="form" layout="vertical"
          >
            <Row gutter={16} className="formbtn">
              <Form.Item className="formbutton">
                <Button htmlType="submit" onClick={this.handleSubmitok.bind(this)} type="primary">
                  保存
                  </Button>
                <Button onClick={this.handleSubmitcancel.bind(this)} >
                  取消
                  </Button>
              </Form.Item>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="流程编号">
                  {getFieldDecorator('FlowNumber', {
                    initialValue: this.state.formiptdataobj["FlowNumber"],
                    rules: [{ required: true, message: '请输入流程编号' }],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="流程名称">
                  {getFieldDecorator('FlowName', {
                    initialValue: this.state.formiptdataobj["FlowName"],
                    rules: [{ required: true, message: '请输入流程名称' }],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="流程分类">
                  {getFieldDecorator('FlowTypeID', {
                    initialValue: this.state.addflownamefu,
                    //rules: [{ required: false, message: '请输入流程分类' }],
                  })(
                    <Input disabled readOnly />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="流程关联主表">
                  {getFieldDecorator('MasterTable', {
                    initialValue: this.state.formiptdataobj["MasterTable"],
                    //rules: [{ required: false, message: '请输入流程关联主表' }],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="流程关联键值">
                  {getFieldDecorator('MasterTableKeyField', {
                    initialValue: this.state.formiptdataobj["MasterTableKeyField"],
                    //rules: [{ required: false, message: '请输入流程关联键值' }],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="所属组织">
                  {getFieldDecorator('Authority_CorpPositions1', {
                    initialValue: this.state.formiptdataobj["Authority_CorpPositions"],
                    //rules: [{ required: false, message: '请输入所属组织' }],
                  })(
                    <Select>
                      {
                        this.state.dataSourcedepartment.map((item, index) => {
                          return (<Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item className="formbutton">
                  <Button onClick={this.handleEditAuditpage.bind(this)} >
                    编辑审核表单页面
                    </Button>
                </Form.Item>
              </Col>
            </Row>
            <h2 className="h2title">流程节点管理</h2>

            <Row gutter={16} className="formbtn">
              <Form.Item className="formbutton">
                <Button onClick={this.handleAddjiedian.bind(this)}>
                  添加节点
                  </Button>
                <Button onClick={this.handleDeljiedian.bind(this)} >
                  删除节点
                  </Button>
              </Form.Item>
            </Row>
            <Row gutter={16} className="stepjiedian">
              <ul>
                {
                  this.state.stepcon.map((item, index) => {
                    return (<li> <Tooltip title={item.NodeName}><span
                    key={index}
                      className={this.state.stepcurrent == index ? "stepjiedianselected" : "stepjiediannoselected"}
                      onClick={this.onStepChange.bind(this, index)}>{item.NodeName}</span></Tooltip> </li>)
                  })
                }
              </ul>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="节点名称">
                  {getFieldDecorator('NodeName', {
                    initialValue: this.state.formiptdatabobj.NodeName,
                    // rules: [{ required: true, message: '请输入节点名称' }],
                  })(
                    <Input 
                    style={{width:"178px"}}
                    onChange={(e) => {
                      var dataobj = this.state.formiptdataobj
                      dataobj.Nodes[this.state.stepcurrent].NodeName = e.target.value
                      this.setState({
                        stepcon: dataobj.Nodes,
                        formiptdataobj: dataobj
                      })
                    }} />
                  )}
                  <Input disabled style={{width:"80px",color:"#fff"}} value={this.state.formiptdatabobj.ID}/>
                  {/* <span style={{color:"#fff",width:"30px",float:"right"}}>{this.state.formiptdatabobj.ID}</span> */}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="节点顺序">
                  {getFieldDecorator('NodeIndex', {
                    initialValue: this.state.formiptdatabobj.NodeIndex,
                    // //rules: [{ required: true, message: '请输入节点顺序' }],
                  })(
                    <Input disabled readOnly />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="节点类型">
                  {getFieldDecorator('NodeTypeID', {
                    initialValue: this.state.formiptdatabobj.NodeTypeID,
                    //rules: [{ required: true, message: '请输入节点类型' }],
                  })(
                    <Select disabled>
                      <Option value={0} key={0}>起点</Option>
                      <Option value={1} key={1}>审核</Option>
                      <Option value={2} key={2}>终点</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="上个节点">
                  {getFieldDecorator('PreviousNodeID', {
                    initialValue: this.state.formiptdatabobj.PreviousNodeID ?
                      Number(this.state.formiptdatabobj.PreviousNodeID) : "",
                    //rules: [{ required: true, message: '请输入上个节点' }],
                  })(
                    <Select disabled readOnly>
                      <Option value="" key="">无</Option>
                      {
                        this.state.stepcon.map((item, index) => {
                          return (<Option value={item.ID} key={item.ID}>{item.NodeName}</Option>)
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="保存函数">
                  {getFieldDecorator('SaveFunction', {
                    initialValue: this.state.formiptdatabobj.SaveFunction,
                    //rules: [{ required: true, message: '请输入保存函数' }],
                  })(
                    <Input />
                    // <Select showSearch mode="multiple">
                    //    {
                    //     this.state.dataSourceinit.map((item, index) => {
                    //       return (<Option value={item.ReadName} key={item.id}>{item.ReadName}</Option>)
                    //     })
                    //   } 
                    //   <Option value="1" key="1">1</Option>
                    //   <Option value="2" key="2">2</Option>
                    // </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="回退函数">
                  {getFieldDecorator('BackFunction', {
                    initialValue: this.state.formiptdatabobj.BackFunction,
                    //rules: [{ required: true, message: '请输入回退函数' }],
                  })(
                    <Input />
                    // <Select showSearch mode="multiple">
                    //    {
                    //     this.state.dataSourceinit.map((item, index) => {
                    //       return (<Option value={item.ReadName} key={item.id}>{item.ReadName}</Option>)
                    //     })
                    //   } 
                    //   <Option value="1" key="1">1</Option>
                    //   <Option value="2" key="2">2</Option>
                    // </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="撤销函数">
                  {getFieldDecorator('CancelFunction', {
                    initialValue: this.state.formiptdatabobj.CancelFunction,
                    //rules: [{ required: true, message: '请输入撤销函数' }],
                  })(
                    <Input />
                    // <Select showSearch mode="multiple">
                    //    {
                    //     this.state.dataSourceinit.map((item, index) => {
                    //       return (<Option value={item.ReadName} key={item.id}>{item.ReadName}</Option>)
                    //     })
                    //   } 
                    //   <Option value="1" key="1">1</Option>
                    //   <Option value="2" key="2">2</Option>
                    // </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="审核角色">
                  {getFieldDecorator('Authority_Roles', {
                    initialValue: this.state.formiptdatabobj.Authority_Roles ? this.state.formiptdatabobj.Authority_Roles.indexOf(',') > -1 ?
                      this.state.formiptdatabobj.Authority_Roles.split(',') : [this.state.formiptdatabobj.Authority_Roles] : [],
                    //rules: [{ required: true, message: '请输入审核角色' }],
                  })(
                    <Select showSearch mode="multiple">
                      {
                        this.state.dataSourcerole.map((item, index) => {
                          return (<Option value={item.ID} key={item.ID}>{item.RoleName}</Option>)
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="审核用户">
                  {getFieldDecorator('Authority_UserIDS', {
                    initialValue: this.state.formiptdatabobj.Authority_UserIDS ? this.state.formiptdatabobj.Authority_UserIDS.indexOf(',') > -1 ?
                      this.state.formiptdatabobj.Authority_UserIDS.split(',') : [this.state.formiptdatabobj.Authority_UserIDS] : [],
                    //rules: [{ required: true, message: '请输入审核用户' }],
                  })(
                    <Select showSearch mode="multiple">
                      {
                        this.state.dataSourceuser.map((item, index) => {
                          return (<Option value={item.ID} key={item.ID}>{item.UserName}</Option>)
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="审核部门">
                  {getFieldDecorator('Authority_CorpPositions', {
                    initialValue: this.state.formiptdatabobj.Authority_CorpPositions ? this.state.formiptdatabobj.Authority_CorpPositions.indexOf(',') > -1 ?
                      this.state.formiptdatabobj.Authority_CorpPositions.split(',') : [this.state.formiptdatabobj.Authority_CorpPositions] : [],
                    rules: [{ required: false, message: '请选择审核部门' }],
                  })(
                    <Select showSearch mode="multiple">
                      {
                        this.state.dataSourcedepartment.map((item, index) => {
                          return (<Option value={item.ID} key={item.ID}>{item.Name}</Option>)
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="是否条件判断">
                  {getFieldDecorator('IsCondition', {
                    initialValue: this.state.formiptdatabobj.IsCondition,
                    rules: [{ required: false, message: '' }],
                  })(
                    <Radio.Group onChange={(e)=>{
                      this.setState({
                        IsCondition:e.target.value
                      },function(){
                        if(this.state.IsCondition == false){
                          this.props.form.setFieldsValue({
                            Condition:""
                          })
                        }
                      })
                    }}>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="条件">
                  {getFieldDecorator('Condition', {
                    initialValue: this.state.formiptdatabobj.Condition,
                    rules: [{ required: false, message: '' }],
                  })(
                    <Input disabled={this.state.IsCondition?false:true}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="是否指定节点">
                  {getFieldDecorator('IsAssignNode', {
                    initialValue: this.state.formiptdatabobj.IsAssignNode,
                    rules: [{ required: false, message: '' }],
                  })(
                    <Radio.Group onChange={(e)=>{
                      console.log(e)
                      this.setState({
                        IsAssignNode:e.target.value
                      },function(){
                        if(this.state.IsAssignNode == false){
                          this.props.form.setFieldsValue({
                            AssignNode:""
                          })
                        }
                      })
                    }}>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="节点编号">
                  {getFieldDecorator('AssignNode', {
                    initialValue: this.state.formiptdatabobj.AssignNode,
                    rules: [{ required: false, message: '' }],
                  })(
                    <Input disabled={this.state.IsAssignNode?false:true}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="textipt">
              <Col span={12}>
                <Form.Item label="备注">
                  {getFieldDecorator('Remark', {
                    initialValue: this.state.formiptdatabobj.Remark,
                    //rules: [{ required: false, message: '请输入备注' }],
                  })(
                    <TextArea rows={9} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        {/* </Drawer> */}


        <Spin tip="Loading..." spinning={this.state.counflag} style={{ width: "100%", height: clientHeight + 'px' }}>
        </Spin>

        <div className="processmanagementl" ref="processmanagementl">
          <div className="processmanagementaction">
            <div className="processmanagementactionl">
              <Button style={{display:this.state.buttonarr.indexOf('添加-流程类型')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '添加分类')}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑-流程类型')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this, '编辑分类')}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除-流程类型')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this, '删除分类')}>删除</Button>
            </div>
          </div>
          <h2 className="h2title">
            流程分类
          </h2>
          <div className="tree" style={{ height: clientHeight - 50 - 40 - 40 + 'px' }}>
            <Tree
              onSelect={this.onSelectA.bind(this)}
              selectedKeys={this.state.selectedKeys}
              onRightClick={this.onClicktocanceltreenode.bind(this)}
            >
              {
                loop(this.state.treedata)
              }
            </Tree>
          </div>
        </div>

        <div className="processmanagementr" ref="processmanagementr" style={{
          height: clientHeight - 50 + 'px',
        }}>
          <div className="processmanagementaction">
            <div className="processmanagementactionl">
              <Button style={{display:this.state.buttonarr.indexOf('添加-流程模型')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this, '添加流程')}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑-流程模型')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this, '编辑流程')}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除-流程模型')>-1?"inline-block":"none"}} onClick={this.handleDel.bind(this, '删除流程')}>删除</Button>
            </div>
            {/* <div className="processmanagementactionr">
              <Button onClick={this.handleUpdate.bind(this)}>刷新</Button>
            </div> */}
          </div>
          <h2 className="h2title">
            流程列表
          </h2>
          <div className="listpage" style={{ height: clientHeight - 50 - 40 - 40 + 'px' }}>
            <Table
              size="small"
              dataSource={this.state.dataSource}
              columns={this.columns}
              onRow={(record) => {
                return {
                  onClick: this.senddata.bind(this, record)
                }
              }}
              rowClassName={this.setRowClassName}
              pagination={{
                pageSize: this.state.pageSize,
                current: this.state.pageIndex,
                total: Number(this.state.dataSourcelength)
              }}
              onChange={this.pagechange.bind(this)}
            />
          </div>
        </div>
      </section >
    )
  }
}
const ProcessManagementapp = Form.create()(ProcessManagement);
export default ProcessManagementapp;

