import React from 'react';
import { Table, Input, Tree, Tooltip, Form, Button, Select, Tabs, Drawer, Modal, Row, Col, InputNumber, Radio, DatePicker, message } from 'antd';
import axios from 'axios';
import { conversion } from '../../converse'
import { onCellf } from '../../comfunc'
import { toProTreeData1 } from '../../utils/toprojecttreedata'
import $ from 'jquery'
import echarts from 'echarts';
import './style.scss'
import moment from 'moment'
// import "moment/locale/zh-cn"
// moment.locale('zh-cn')
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { getButtonLimit } from '../../utils/getbuttonlimit'
import store from '../../store';
const { Option } = Select;
const TabPane = Tabs.TabPane;
const { TreeNode, DirectoryTree } = Tree;
const { Search, TextArea } = Input;
const { confirm } = Modal

class WisdomDiagnosisTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonarr:[],
      //树
      treedataA: [],
      visible: false,
      addedit: '',
      formiptobj: {},
      detaillistId: '',

      //表
      dataSourceall: [],
      dataSourcein: [],
      dataSourceout: [],
      dataSourceoutfu: [],
      dataSource: [],

      //专业初始化
      majorarr: [],
      majorselect: "",

      activeKey: '1',

      selectedRowKeysA: [],
      idliststrout: '',
      //移入的数据
      selectedRowKeysB: [],
      idliststrin: '',
      codeliststrin: '',
      nameliststrin: '',

      //专业树
      searchValue: '',
      checkedKeys: [],
      autoExpandParent: false,
      expandedKeys: [],
      attr: {
        ID: 'ID',
        ParentId: 'ParentId',
        Code: 'Code',
        Name: 'Name',
        rootId: "0",
        Leaf: 'Leaf',
        Rank: 'Rank',
        RecordVersion: 'RecordVersion',
        UpdateTime: 'UpdateTime',
        UpdatePeople: 'UpdatePeople',
        CreatePeople: 'CreatePeople',
        CreateTime: 'CreateTime'
      },
    };
    //基础属性 供应商 责任相关 技术参数  文档关联
    this.columnsin = [
      {
        title: '编号',
        dataIndex: 'Code',
        key: 'Code',
        width: '45%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '45%',
        onCell: onCellf,
        render: this.renderf
      },
    ]
    this.columnsout = [
      {
        title: '编号',
        dataIndex: 'Code',
        key: 'Code',
        width: '45%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '45%', 
        onCell: onCellf,
        render: this.renderf
      },
    ]
    this.columns = [
      {
        title: '编号',
        dataIndex: 'Code',
        key: 'Code',
        width: '45%',
        render: (text, record, index) => {
          return <span className="tabledatacenter">{text}</span>
        }
      },
      {
        title: '名称',
        dataIndex: 'Name',
        key: 'Name',
        width: '45%',
        onCell: onCellf,
        render: this.renderf
      },
    ]
     
    this.sortCode = this.sortCode.bind(this)
    this.renderf = this.renderf.bind(this)
  }
  renderf(text) {
    return (<Tooltip placement="bottomLeft" className="managetooltip tabledatapadding" title={text}>{text}</Tooltip>)
  }

  getButtonLimitarr = async (id) => {
    const res = await getButtonLimit(id)
    console.log('按钮权限',res)
    this.setState({
      buttonarr:res
    })
  }
  componentDidMount() {
    this.getButtonLimitarr(76)

    var service = sessionStorage.getItem('service')
    this.setState({
      service: service
    })
    //诊断模型装配
    axios.get('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=1').then(data => {
      console.log('诊断模型装配列表', conversion(data))
      this.setState({
        dataSource: conversion(data)
      })
    })


    //获取专业
    axios.get('/DATA/Paraset/Paraset.aspx?flag=100').then(data => {
      console.log('专业', conversion(data))
      if(conversion(data).length>0){
        this.setState({
          majorarr: conversion(data),
          majorselect:conversion(data)[0].Code
        },function(){
          //专业树数据
          axios.get('/DATA/ParaSet/ParaSet.aspx?flag=4').then(res => {
            //console.log(conversion(res))
            let attr = this.state.attr;
            var arr = conversion(res)
            var newarr = []
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].Code.length>8 && arr[i].Code.indexOf(this.state.majorselect)>-1) {
                newarr.push(arr[i])
              }
            }
            // let arr1 = toProTreeData1(arr, attr)
            //console.log('专业树数据', arr)
            this.setState({
              dataSourcein:newarr,
              dataSourceall: arr,
            })
          })
        })
      }
    })

  }


  zhuangpeishushou() {

  }

  onCheckA = (checkedKeys, info) => {
    console.log(checkedKeys, info);
    var arr = checkedKeys
    var arr1 = info.checkedNodes
    var idliststr = ''
    var codeliststr = ''
    var nameliststr = ''
    for (let i = 0; i < arr.length; i++) {
      if (i == arr.length - 1) {
        idliststr = idliststr + arr[i]
        nameliststr = nameliststr + arr1[i].props.title.props.Name
        codeliststr = codeliststr + arr1[i].props.title.props.Code
      } else {
        idliststr = idliststr + arr[i] + ','
        nameliststr = nameliststr + arr1[i].props.title.props.Name + ','
        codeliststr = codeliststr + arr1[i].props.title.props.Code + ','
      }
    }

    // var newarrCode = [], newarrRead = []
    // for (let j = 0; j < arr1.length; j++) {
    //   newarrCode.push(arr1[j].props.title.props.Code)
    //   newarrRead.push(arr1[j].props.title.props.Name)
    // }
    console.log(nameliststr, codeliststr)
    this.setState({
      checkedKeys,
      idliststrin: idliststr,
      nameliststrin: nameliststr,
      codeliststrin: codeliststr
    });
  };
  //树添加 编辑 删除
  //配置添加删除修改
  handleAdd() {
    this.setState({
      addedit: '添加',
      visible: true,
      formiptobj: {},
    }, function () {
      this.props.form.resetFields()
    })
  }
  handleEdit() {
    if (this.state.detaillistId) {
      this.setState({
        addedit: '修改',
        visible: true,
      })
    } else {
      message.error('请选择!')
    }
  }

  onClose = () => {
    this.setState({
      visible: false,
      detaillistId: ''
    }, function () {
      this.props.form.resetFields();
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values)
      for (var i in values) {
        var value = values[i]
        if (value == undefined) {
          values[i] = ""
        } else if (value.label) {
          values[i] = value.label
        } else if (value._d) {
          values[i] = value._d.getFullYear() + '-' + (value._d.getMonth() + 1) + '-' + value._d.getDate()
        } else {
          values[i] = values[i]
        }
      }

      if (!err) {
        if (this.state.addedit == "添加") {
          axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=5', values).then(data => {
            console.log(data)
            if (data.data.success) {
              //诊断模型装配
              axios.get('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=1').then(data => {
                console.log('诊断模型装配列表', conversion(data))
                this.setState({
                  dataSource: conversion(data),
                  visible: false
                })
              })
            }else if(typeof data.data == "string" && data.data.indexOf('err')>-1){
              message.error('该编码已存在！')
            }
          })
        } else if (this.state.addedit == "修改") {
          values.ID = this.state.detaillistId
          axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=6', values).then(data => {
            console.log(data)
            if (data.data.success) {
              //诊断模型装配
              axios.get('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=1').then(data => {
                console.log('诊断模型装配列表', conversion(data))
                this.setState({
                  dataSource: conversion(data),
                  visible: false
                })
              })
            }
          })
        }
      }
    });
  };

  //树删除
  handleDeltree() {
    var _this = this
    var key = this.state.detaillistId
    if (this.state.detaillistId) {
      confirm({
        title: '确定要删除吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=7', {
            ID: _this.state.detaillistId
          }).then(data => {
            console.log(data)
            if (data.data.success) {
              const dataSource = [..._this.state.dataSource];
              _this.setState({ dataSource: dataSource.filter(item => item.ID !== key), detaillistId: "" },
                function () {
                  message.success('删除成功！')
                });
            }
          })
        },
        onCancel() {
          _this.setState({
            detaillistId: ''
          })
        },
      });

    } else {
      message.error('请选择!')
    }
  }
  
  getListData(key){
    axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=2', {
      ID: key
    }).then(data => {
      console.log('装配模型', conversion(data))
      this.setState({
        dataSourceout: conversion(data),
        dataSourceoutfu: conversion(data)
      })
    })
  }

  //选中
  senddata(record) {
    console.log(record)
    this.setState({
      detaillistId: record.ID,
      formiptobj: record
    }, function () {
      //装配模型
      this.getListData(this.state.detaillistId)
    })
  }

  setRowClassName = (record) => {
    return record.ID === this.state.detaillistId ? 'clickRowStyl' : 'clickRowSty';
  }

  sortCode(obj1, obj2) {
    var val1 = obj1.Code;
    var val2 = obj2.Code;
    if (val1 < val2) {
      return -1;
    } else if (val1 > val2) {
      return 1;
    } else {
      return 0;
    }
  }
  handleSelect(value) {
    console.log(value)
    //全部专业列表
    var arr = this.state.dataSourceall
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].Code.length>8 && arr[i].Code.indexOf(value)>-1) {
        newarr.push(arr[i])
      }
    }
    this.setState({
      majorselect:value,
      dataSourcein: newarr.sort(this.sortCode)
    })
  }

  tojiebind() {
    if (this.state.detaillistId && this.state.idliststrout) {
      axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=4', {
        IDList: this.state.idliststrout,
      }).then(data => {
        console.log(data)
        if (data.data.success) {
          
          this.getListData(this.state.detaillistId)
          this.setState({
            selectedRowKeysA: []
          }, function () {
            message.success('解绑成功！')
          })
        }
      })
    } else {
      message.error('请选择！')
    }
  }
  tobind() {
    if (this.state.detaillistId && this.state.idliststrin) {
      axios.post('/DATA/MonitoringAndDiagnosis/DiagnosticModelAssembling.aspx?flag=3', {
        ID: this.state.detaillistId,
        ProfessionalTreeIdList : this.state.idliststrin,
        CodeList: this.state.codeliststrin,
        NameList: this.state.nameliststrin
      }).then(data => {
        console.log(data)
        if (data.data.success) {
          this.getListData(this.state.detaillistId)
          this.setState({
            selectedRowKeysA: [],
            selectedRowKeysB: [],
            checkedKeys: []
          }, function () {
            message.success('绑定成功！')
          })
        }
      })
    } else {
      message.error('请选择！')
    }
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedRowKeysA, selectedRowKeysB } = this.state;
    const clientHeight = store.getState().clientHeight
    const clientWidth = store.getState().clientWidth
    const rowSelectionA = {
      selectedRowKeys: selectedRowKeysA,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys, selectedRows);
        var idliststr = ''
        for (let i = 0; i < selectedRows.length; i++) {
          if (i == selectedRows.length - 1) {
            idliststr = idliststr + selectedRows[i].ID
          } else {
            idliststr = idliststr + selectedRows[i].ID + ','
          }
        }
        this.setState({
          selectedRowKeysA: selectedRowKeys,
          idliststrout: idliststr
        })
      },
    };
    const rowSelectionB = {
      selectedRowKeys: selectedRowKeysB,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys, selectedRows);
        var idliststr = ''
        var codeliststr = ''
        var nameliststr = ''
        for (let i = 0; i < selectedRows.length; i++) {
          if (i == selectedRows.length - 1) {
            idliststr = idliststr + selectedRows[i].ID
            codeliststr = codeliststr + selectedRows[i].Code
            nameliststr = nameliststr + selectedRows[i].Name
          } else {
            idliststr = idliststr + selectedRows[i].ID + ','
            codeliststr = codeliststr + selectedRows[i].Code + ','
            nameliststr = nameliststr + selectedRows[i].Name + ','
          }
        }
        this.setState({
          selectedRowKeysB: selectedRowKeys,
          idliststrin: idliststr,
          codeliststrin: codeliststr,
          nameliststrin: nameliststr
        })
      },
    };
    const { expandedKeys, autoExpandParent, searchValue } = this.state

    const loop = data =>
      data.map(item => {
        const index = item.Code.indexOf(searchValue);
        const beforeStr = item.Code.substr(0, index);
        const afterStr = item.Code.substr(index + searchValue.length);

        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: '#f50' }}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
              <span>{item.Code}</span>
            );
        if (item.children) {
          return (
            <TreeNode key={item.id} title={<Tooltip placement="right" Code={item.Name} Name={item.Name} title={title}>{title}</Tooltip>}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.id} title={title} />;
      });
    return (
      <section className="wisdomdiagnosismodel" style={{ height: clientHeight - 50 + 'px' }}>

        <Drawer
          title={'诊断模型装配' + this.state.addedit}
          onClose={this.onClose.bind(this)}
          visible={this.state.visible}
          className="Drawercommon"
        >
          <div className="Drawercommonaddedit" style={{ height: clientHeight - 50 - 55+ 'px' }}>
            <Form className="form" onSubmit={this.handleSubmit.bind(this)} layout="vertical"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="编码">
                    {getFieldDecorator('Code', {
                      initialValue: this.state.formiptobj['Code'],
                      rules: [{ required: true, message: '请输入编码' }],
                    })(
                      <Input disabled={this.state.addedit == "修改" ? true : false} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="名称">
                    {getFieldDecorator('Name', {
                      initialValue: this.state.formiptobj['Name'],
                      rules: [{ required: true, message: '请输入名称' }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="formbutton">
                <Form.Item>
                  <Button htmlType="submit" onClick={this.handleSubmit.bind(this)} type="primary">
                    保存
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </Drawer>

        <div className="wisdomdiagnosismodelleft" ref="wisdomdiagnosismodelleft" style={{ height: clientHeight - 50 + 'px' }}>
          <h2 className="h2title">
            <span>诊断模型装配</span>
            <span onClick={this.zhuangpeishushou.bind(this)} className="zhuangpeishushousuo"></span>
          </h2>
          <div className="wisdomdiagnosismodeltree" style={{ height: clientHeight - 50 - 30 + 'px' }}>
            <span className="treeaction">
              <Button style={{display:this.state.buttonarr.indexOf('添加')>-1?"inline-block":"none"}} onClick={this.handleAdd.bind(this)}>添加</Button>
              <Button style={{display:this.state.buttonarr.indexOf('编辑')>-1?"inline-block":"none"}} onClick={this.handleEdit.bind(this)}>编辑</Button>
              <Button style={{display:this.state.buttonarr.indexOf('删除')>-1?"inline-block":"none"}} onClick={this.handleDeltree.bind(this)}>删除</Button>
            </span>
            <div className="listscroll" style={{ height: clientHeight - 50 - 30 - 40 + 'px' }}>
              <Table
                size="small"
                dataSource={this.state.dataSource}
                columns={this.columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: this.senddata.bind(this, record)
                  }
                }}
                rowClassName={this.setRowClassName}
                scroll={{ y: clientHeight - 50 - 30 - 30 - 30 - 40 + 'px' }}
              />
            </div>
          </div>
        </div>

        <div className="wisdomdiagnosismodelright" ref="wisdomdiagnosistoolsright"
          style={{ height: clientHeight - 50 + 'px' }}
        >
          <div className="wisdomdiagnosismodelright1">
            <h2 className="h2title">
              装配模型
            </h2>
            <div className="con" style={{ height: clientHeight - 50 - 30 + 'px' }}>
              <span>
                <span><Search
                  placeholder="关键字"
                  onSearch={(value) => {
                    var newarr = []
                    var arr = this.state.dataSourceoutfu
                    for (let i = 0; i < arr.length; i++) {
                      if (arr[i].Name.indexOf(value) > -1 || arr[i].Code.indexOf(value) > -1) {
                        newarr.push(arr[i])
                      }
                    }
                    this.setState({
                      dataSourceout: newarr
                    })
                  }}
                  style={{ width: 180 }}
                /></span>
                <span><Button style={{display:this.state.buttonarr.indexOf('解绑')>-1?"inline-block":"none"}} onClick={this.tojiebind.bind(this)}>解绑</Button></span>
              </span>
              <div className="listscrollcheckbox" style={{ height: clientHeight - 50 - 30 - 30 + 'px' }}>
                <Table
                  rowSelection={rowSelectionA}
                  size="small"
                  dataSource={this.state.dataSourceout}
                  columns={this.columnsout}
                  pagination={false}
                  scroll={{ y: clientHeight - 50 - 30 - 30 - 30 - 40 + 'px' }}
                />
              </div>

            </div>
          </div>
          <div className="wisdomdiagnosismodelright2">
            <h2 className="h2title">
              所有模型
            </h2>
            <div className="con" style={{ height: clientHeight - 50 - 30 + 'px' }}>
              <span>
                <span>
                  <Select onChange={this.handleSelect.bind(this)} value={this.state.majorselect}>
                    {
                      this.state.majorarr.map((item, index) => {
                        return (<Option value={item.Code} key={item.Code}>{item.Code}</Option>)
                      })
                    }
                  </Select>
                </span>
                <span><Button style={{display:this.state.buttonarr.indexOf('绑定')>-1?"inline-block":"none"}} onClick={this.tobind.bind(this)}>绑定</Button></span>
              </span>
              <div className="listscrollcheckbox" style={{ height: clientHeight - 50 - 30 - 30 - 30 + 'px' }}>
                <Table
                  size="small"
                  rowSelection={rowSelectionB}
                  dataSource={this.state.dataSourcein}
                  columns={this.columnsin}
                  pagination={false}
                  scroll={{ y: clientHeight - 50 - 30 - 30 - 30 - 40 + 'px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </section >
    )
  }
}
const WisdomDiagnosisToolsapp = Form.create()(WisdomDiagnosisTools);
export default WisdomDiagnosisToolsapp;