import React from 'react';
import { Table, Modal, Progress ,Tooltip} from 'antd';
import axios from 'axios';
import { conversion } from '../../../converse'
import './style.scss'
import store from '../../../store/index'
class Firstdata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arr1: [],
      arr2: [],
      arr3: [],
      dataSource1: store.getState().userdata,
      dataSource2: [],
      visible: false,
      taskname: '',

      isclick:false,//测试async await
    };
    this.columns1 = [
      // {
      //   title: '', dataIndex: 'photo', key: 'photo',
      //   width: '25%',
      //   render: (text) => {
      //     return (<img src={"../../../upload/user/photo/" + text} />)
      //   }
      // },
      { title: '账号', width: '30%', dataIndex: 'UserNumber', key: 'UserNumber' },
      { title: '姓名', width: '35%', dataIndex: 'UserName', key: 'UserName' },
      {
        title: '在线状态', width: '35%', dataIndex: 'State', key: 'State',
        render: (text) => {
          if (text == "1") {
            return (<span  style={{ color: "#ced" }}>离线</span>)
          } else {
            return (<span  style={{ color: "#fff" ,fontWeight:900}}>在线</span>)
          }
        }
      }
    ];
    this.columns2 = [
      { title: '图号/文件号', dataIndex: 'Graphnum', key: 'Graphnum', width: '25%', },
      { title: '文件名称', dataIndex: 'filenewname', key: 'filenewname', width: '40%',
      onCell: () => {
        return {
          style: {
            maxWidth: "10px",
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer'
          }
        }
      },
      render: (text) => <Tooltip placement="right" className="managetooltip" title={text}>{text}</Tooltip> },
      { title: '文件类型', dataIndex: 'filepic', key: 'filepic', width: '12%' ,
      render: (text, record, index) => {
        return <span >{text}</span>
      }},
      { title: '上传日期', dataIndex: 'subdate', key: 'subdate', width: '23%' ,
      render: (text, record, index) => {
        return <span >{text}</span>
      }},
      // { title: '上传人', dataIndex: 'subid', key: 'subid', width: '15%' },
    ];
  }
  componentDidMount() {
    axios.post('/Data/ParaSet/DataPanel.aspx?flag=5').then(data => {
      var arr = conversion(data);
      var arr1 = [], arr2 = [], arr3 = [];
      for (let i = 0; i < arr.length; i++) {
        arr1.push(arr[i].BFS)
        arr2.push(arr[i].D2)
        arr3.push(arr[i].E2)
      }

      this.setState({
        arr1,
        arr2,
        arr3
      })
    })
    // axios.post('/Data/ParaSet/DataPanel.aspx?flag=7').then(data => {
    //   this.setState({
    //     dataSource2: conversion(data)
    //   })
    // })
  }
  //点击显示任务
  showalltask(value) {
    this.setState({
      visible: true,
      taskname: value
    })
  }
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }
  //日期选择
  onDateChange(date, dateString) {
  }

  async  testasyncawait(){
    await this.setState({
      isclick : true
    })
    console.log(this.state.isclick)
  }

  testasyncawait (){
    //执行setState 实际上会执行
    this.setState((prevState)=>{
      return {
        isclick: !prevState.isclick
      }
    }, ()=>{
      console.log(this.state.isclick)
    })
  }
  render() {
    const clientHeight = store.getState().clientHeight
    return (
      <div className="firstpage">
        {/* 上部 */}
        <section className="firsttop">
          <div className="alldata">
            <div className="data">
              <span className="datatitlename" 
              // onClick={this.testasyncawait.bind(this)}
              >特急任务</span>
              <div className="dataleft">
                <Progress 
                  type="circle" 
                  strokeWidth={12}
                  strokeColor="#fb0bba"
                  // strokeColor={{
                  //   "0%":"#fb0bba",
                  //   "100%":"#cd86fb"
                  // }}
                  // strokeColor={{
                  //   from: '#fb0bba',
                  //   to: '#cd86fb',
                  // }}
                  className="dataleft1" percent={parseInt(this.state.arr1[0])} format={percent => `${percent}%`} />
                <span className="finishrate">完成率</span>
                <p className="wait">
                  <span>已完成<em>{this.state.arr2[0]}</em>项</span>&nbsp;/&nbsp;
                  <span>特急任务共<em>{this.state.arr3[0]}</em>项</span>
                </p>
              </div>
            </div>

            <div className="data">
              <span className="datatitlename">加急任务</span>
              <div className="dataleft">
                <Progress type="circle" strokeWidth={12}
                  strokeColor="#f1c40f"
                  // strokeColor={{
                  //   '0%': '#f1c40f',
                  //   '100%': '#fdf752',
                  // }} 
                  className="dataleft2" percent={parseInt(this.state.arr1[1])} format={percent => `${percent}%`} />
                <span className="finishrate">完成率</span>
                <p className="wait">
                  <span>已完成<em>{this.state.arr2[1]}</em>项</span>&nbsp;/&nbsp;
                  <span>加急任务共<em>{this.state.arr3[1]}</em>项</span>
                </p>
              </div>
            </div>

            <div className="data">
              <span className="datatitlename">普通任务</span>
              <div className="dataleft">
                <Progress type="circle" strokeWidth={12}
                  strokeColor="#41ebff"
                  // strokeColor={{
                  //   '0%': '#41ebff',
                  //   '100%': '#659be0',
                  // }}
                  className="dataleft3" percent={parseInt(this.state.arr1[2])} format={percent => `${percent}%`} />
                <span className="finishrate">完成率</span>
                <p className="wait">
                  <span>已完成<em>{this.state.arr2[2]}</em>项</span>&nbsp;/&nbsp;
                  <span>普通任务共<em>{this.state.arr3[2]}</em>项</span>
                </p>
              </div>
            </div>
          </div>
          {/* 显示所有任务 */}
          <Modal
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={null}>
            <h2>{this.state.taskname}</h2>
            <div className="taskcontent">
              所有任务
            </div>
          </Modal>
        </section>
        {/* 下部 */}
        <section className="firstdown" style={{ height: clientHeight - 50 - 30 - 240  + 'px' }}>
          <div className="userrecord">
            <span className="datatitlename">用户记录</span>
            <div className="listscroll" style={{ height: clientHeight - 50 - 30 - 240 - 20 - 40 + 'px' }}>
              <Table
                rowKey={record => record.userid}
                columns={this.columns1}
                scroll={{ y: clientHeight - 50 - 30 - 240 - 20 - 40 - 20 }}
                dataSource={store.getState().userdata}
                pagination={false}
              />
            </div>

          </div>
          <div className="filerecord">
            <span className="datatitlename">文档记录</span>
            <div className="listscroll" style={{ height: clientHeight - 50 - 30 - 240 - 20 - 40 + 'px' }}>
              <Table
                rowKey={record => record.index}
                columns={this.columns2}
                dataSource={this.state.dataSource2}
                scroll={{ y: clientHeight - 50 - 30 - 240 - 20 - 40 - 20 }}
                pagination={false}
              />
            </div>
          </div>
        </section>
      </div>
    )
  }
}
export default Firstdata;