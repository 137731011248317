import React from 'react';
import store from '../../store/index'
import { changeclienthwact, changeuserdata,changedeptdata, changeconfigimage ,changemoduledata} from '../../store/actionCreators'
// import { Change_clientHW ,Change_userData } from '../../store/actionTypes' //type

import { Link, Switch, Route } from 'react-router-dom'
import axios from 'axios';
import { Progress, Tooltip, Select, Modal, Collapse, Dropdown, Menu, Icon, Carousel, message, Input, Tabs } from 'antd';
import $ from 'jquery';
import screenfull from 'screenfull'
// import 'swiper/dist/css/swiper.//min.css'
// 引入 ECharts 主模块
import echarts from 'echarts';
import { conversion } from '../../converse'
import MenuItem from 'antd/lib/menu/MenuItem';
import './style.scss'
// 引入折现图
import 'echarts/lib/chart/bar';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
//引入子组件 
import Firstdata from '../../components/Pagecom/Firstpage'
import Seconddata from '../../components/Pagecom/Secondpage'
import Threedata from '../../components/Pagecom/Threepage'
import Fourdata from '../../components/Pagecom/Fourpage'
import Fivedata from '../../components/Pagecom/Fivepage'
import Sixdata from '../../components/Pagecom/Sixpage'
import Sevendata from '../../components/Pagecom/Sevenpage'


//////管理驾驶舱 StewardCockpit

//统计与报表
import Report from '../../components/StewardCockpit/Report/index'
//小指标考核
import SmallIndicators from '../../components/StewardCockpit/SmallIndicators/index'
//考核设置
import PerformanceAssessment from '../../components/StewardCockpit/PerformanceAssessment/index'



///////数据中心 DataCenter

//项目管理
import ProConfig from '../../components/ConfigManagement/index1'
import FactoryConfig from '../../components/ConfigManagement/index2'
import ProjectTree from '../../components/ConfigManagement/index3'
import AreaTree from '../../components/ConfigManagement/index4'
import ConstructTree from '../../components/ConfigManagement/index5'
import EquipmentTree from '../../components/ConfigManagement/index6'
import CeDianConfig from '../../components/ConfigManagement/index7'
//文档管理
import Documentmanage from '../../components/Documentmanage/index'
//清单管理 
import ListManagement from '../../components/ListManagement/index'
//数字化装配
import DigitalAssembly from '../../components/DigitalAssembly/index'
//关联关系可视化
import Visualizationofrelative from '../../components/Visualizationofrelative/index'
//设备属性表
import EquipmenProperty from '../../components/EquipmenProperty/index'


///////智慧运维 IntelligenceOperations

//资产可视化
import AssetView from '../../components/AssetView/index'
//智慧两票
import WisdomTwoticket from '../../components/WisdomTwoticket/index'
import TicketModule from '../../components/WisdomTwoticket/index1'
//定期工作
import RegularworkList from '../../components/Regularwork/index'
import RegularworkSet from '../../components/Regularwork/index1'
//智慧巡检
//巡检任务
import InspectionTask from '../../components/InspectionTask/index'
//巡检路径
import InspectionRoute from '../../components/InspectionRoute/index'
//实时巡检
import PatrolRouteFu from '../../components/PatrolRouteFu/index'
//巡检记录
import InspectionRecord from '../../components/InspectionRecord/index'
//设备配置
import InpectionSpecification from '../../components/InpectionSpecification/index'
//巡检参数
import Inspectioncategory from '../../components/Inspectioncategory/index'



//////智能诊断 IntelligentDiagnosis

//智慧诊断
import WisdomDiagnosis from '../../components/WisdomDiagnosis/index'
//状态检测
import ConditionInspection from '../../components/ConditionInspection/index'

//诊断模型测点
import SensorSet from '../../components/SensorSet/index'
//诊断工具集
import WisdomDiagnosisTools from '../../components/WisdomDiagnosisTools/index'
//诊断模型装配
import WisdomDiagnosisModel from '../../components/WisdomDiagnosisModel/index'
//诊断模型配置
import WisdomDiagnosisModelConfig from '../../components/WisdomDiagnosisModelConfig/index'


//////系统设置 SystemSet
import PortalEngine from '../../components/SystemPlatform/PortalEngine/index'
import MenuManage from '../../components/SystemPlatform/MenuManage/index'
import UserManage from '../../components/SystemPlatform/UserManage/index'
import OrganizationManage from '../../components/SystemPlatform/OrganizationManage/index'
import RoleManage from '../../components/SystemPlatform/RoleManage/index'
import DictionaryManage from '../../components/SystemPlatform/DictionaryManage/index'
import ProcessManagement from '../../components/ProcessManagement/index'
import AccessLog from '../../components/SystemPlatform/Logmanage/index'
import OperationLog from '../../components/SystemPlatform/Logmanage/index1.js'
import LimitManage from '../../components/SystemPlatform/LimitManage/index'


//////个人工作台 WorkPlace
import WorkList from '../../components/WorkPlace/index'
import WorkDone from '../../components/WorkPlace/index1'
import WorkStart from '../../components/WorkPlace/index2'

/////// 发运管理 ShippingManagment
import ShippingPlan from '../../components/ShippingManagement/shippingplan'
import BoxManagement from '../../components/ShippingManagement/boxmanagement'



import {equalsObj } from '../../utils/comparearrobj'



const Option = Select.Option;
const { TabPane } = Tabs;
const Panel = Collapse.Panel;
const SubMenu = Menu.SubMenu;
//用户长时间未操作页面 自动退出时间
var maxTime = 24 * 60 * 60;
var time = maxTime;
var timer1;

var timer11;
var timer12;

//React16 getSnapshotBeforeUpdate 代替 componentWillUpdate    
//React16 getDerivedStateFromProps 代替 componentWillReceivePorps
//React17 新增componentDidCatch


class HomeModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutvisible:false,//关于
      editpwdvisible: false,//修改密码
      clientHeight: 0,
      clientWidth: 0,
      anticonflag: '0',

      icon: 'arrows-alt',

      //登陆者信息
      username: '',
      service: '',
      company: '',
      photoUrl: '',


      //月发电量
      montheleoption: {},
      monthelearr: [],//月发电量数据

      intemperaturedata: [],
      intemperaturexAais: [],
      intemperatureoption: {},
      intemperaturedata2: [],
      intemperaturexAais2: [],
      intemperatureoption2: {},

      consumeenergydata: [],
      consumeenergyxAais: [],
      consumeenergyoption: {},
      consumeenergydata2: [],
      consumeenergyxAais2: [],
      consumeenergyoption2: {},

      dayfuhelineoption: {},
      dayfuhegetdate: [],

      viewshowright2con1option: {},

      //大气污染物key
      atmosphere: {},
      changetabskey: '氮氧化物',
      viewshowright2con2option: {},
      viewshowright2con3option: {},

      //当值情况
      dangzhiban: '',
      Y_GenerationCapacity: '',
      Y_FuelCT: '',
      Y_MainSS: '',

      //缺陷 燃料
      progressoption1: {},
      progressoption2: {},
      yearstockper: {},
      finalper: {},

      //热电比
      CYDL1obj: {},
      RDBobj: {},

      //大气污染物
      atmosphereNO: '',
      atmosphereCO2: '',
      atmosphereT: '',

      //炉选择
      boilersele: '',
      intemperaturesele: '锅炉蒸发量',
      consumeenergysele: '主蒸汽温度',
      unitintemperature: "t/h",
      unitconsumeenergy: "℃",

      namehover: '',
      dotPosition: "bottom",

      //警告信息
      defaultarr: [],

      //锅炉在燃
      firstbilerdata: '',
      sencondboilerdata: '',

      //模块数据
      pagenum: '',
      allmoduleDataArr: [],
      moduleDataArr: [],
      moduleDataArrIdsele: "",
      moduleDataArrObjsele: {},
      moduleDataArrNamesele: "",
      //模块子级树
      MenuDataArr: [],
      openKeys: [],
      selectedKeys: [],
      //多页签内容
      tabconarr: [],
      seletabframe: '',//选择页签ID
    }
    this.winresize = this.winresize.bind(this)
    this.storeChange = this.storeChange.bind(this)
    store.subscribe(this.storeChange)
    this.getConfigimage = this.getConfigimage.bind(this)
  }
  storeChange() {
    this.setState(store.getState())
  }

  //项目 工厂图片
  getConfigimage() {
    axios.get('/DATA/ParaSet/BasicInformationConfiguration.aspx?flag=1').then(res => {
      // //console.log(conversion(res))
      if (conversion(res)[0] && conversion(res)[0].CompletPathProjectPhoto) {
        var ProjectconfigimageUrl = conversion(res)[0].CompletPathProjectPhoto
        var CompanyconfigimageUrl = conversion(res)[0].CompletPathCompanyPhoto
        var configID = conversion(res)[0].ID
        var configVersion = conversion(res)[0].RecordVersion
        const action = changeconfigimage(ProjectconfigimageUrl, CompanyconfigimageUrl, configID, configVersion)
        store.dispatch(action)
      }
    })
  }

  gethomeInfo(){
    //获取权限控制
    axios.get(`/DATA/WebSet/T_PermitMenu.aspx?flag=2`).then(res=>{
      if(res.data.success){
        var arr = res.data.data
        //console.log(arr)
        const action = changemoduledata(arr)
        store.dispatch(action)
        var newarr = arr.filter(function (s) {
          return s.ParentId == 0
        })
        this.setState({
          moduleDataArr: newarr,
          allmoduleDataArr: arr
        })
      }
    })

    //获取所有模块
    // axios.get('/DATA/WebSet/FunctionMenu.aspx?flag=1').then(data => {
    //   //console.log('模块', conversion(data))
    //   var arr = conversion(data)
      
    //   const action = changemoduledata(arr)
    //   store.dispatch(action)
    //   var newarr = arr.filter(function (s) {
    //     return s.ParentId == 0
    //   })
    //   this.setState({
    //     moduleDataArr: newarr,
    //     allmoduleDataArr: arr
    //   })
    // })


    this.getConfigimage()

    var _this = this
    timer1 = setInterval(function () {
      var a1arr = []
      var a2arr = []
      var a3arr = []
      var a4arr = []
      var a5arr = []
      for (let i = 0; i < 120; i++) {
        var a1 = (Math.random() * 20 + 130).toFixed(1)
        a1arr.push(a1)
        var a2 = (Math.random() * 40 + 770).toFixed(1)
        a2arr.push(a2)
        var a3 = (Math.random() * 10 + 8).toFixed(1)
        a3arr.push(a3)
        var a4 = (Math.random() * 10 + 8).toFixed(1)
        a4arr.push(a4)
        var a5 = (Math.random() * 5 + 25).toFixed(1)
        a5arr.push(a5)
      }
      var timearr = []
      var current = new Date().getTime()
      for (let i = 120; i > 0; i--) {
        timearr.push(new Date(current - i * 1000).getHours() + ':' + new Date(current - i * 1000).getMinutes() + ':' + new Date(current - i * 1000).getSeconds())
      }
      _this.setState({
        atmosphereNO: a3arr[a3arr.length - 1],
        atmosphereCO2: a4arr[a4arr.length - 1],
        atmosphereT: a5arr[a5arr.length - 1],
      })
      if (_this.state.changetabskey == "氮氧化物") {
        var viewshowright2con1option = {
          grid: {
            left: '2%',
            right: '2%',
            top: '2%',
            bottom: '5%',
            scontainLabel: true
          },
          tooltip: {
            trigger: 'item',
            // position: [50, 20],
            formatter: "{c}"
          },
          xAxis: {
            show: false,
            type: 'category',
            boundaryGap: false,
            data: timearr
          },
          yAxis: {
            show: false,
            type: 'value'
          },
          series: [{
            data: a3arr,
            type: 'line',
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0, color: '#159fff' // 0% 处的颜色
                  },
                  {
                    offset: 1, color: '#159fff' // 100% 处的颜色
                  }]
                )
              }
            },
            itemStyle: {
              show: false,
              normal: {
                color: '#8cd5c2', //改变折线点的颜色
                lineStyle: {
                  color: 'none' //改变折线颜色
                }
              }
            },
            smooth: 0.5,
          }]
        };

        _this.setState({
          viewshowright2con1option: viewshowright2con1option,
        }, function () {
          //基于准备好的dom，初始化echarts实例
          var myChart4 = echarts.init(document.getElementById('viewshowright2con1'));
          // 绘制图表
          myChart4.setOption(_this.state.viewshowright2con1option);
        })
      }
      if (_this.state.changetabskey == "二氧化碳") {
        //二氧化碳
        var viewshowright2con2option = {
          grid: {
            left: '2%',
            right: '2%',
            top: '2%',
            bottom: '5%',
            scontainLabel: true
          },
          tooltip: {
            trigger: 'item',
            // position: [50, 20],
            formatter: "{c}"
          },
          xAxis: {
            show: false,
            type: 'category',
            boundaryGap: false,
            data: timearr
          },
          yAxis: {
            show: false,
            type: 'value'
          },
          series: [{
            data: a4arr,
            type: 'line',
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0, color: '#159fff' // 0% 处的颜色
                  },
                  {
                    offset: 1, color: '#159fff' // 100% 处的颜色
                  }]
                )
              }
            },
            itemStyle: {
              show: false,
              normal: {
                color: '#8cd5c2', //改变折线点的颜色
                lineStyle: {
                  color: 'none' //改变折线颜色
                }
              }
            },
            smooth: 0.5,
          }]
        };

        _this.setState({
          viewshowright2con2option: viewshowright2con2option,
        }, function () {
          //基于准备好的dom，初始化echarts实例
          var myChart5 = echarts.init(document.getElementById('viewshowright2con2'));
          // 绘制图表
          myChart5.setOption(_this.state.viewshowright2con2option);

        })
      }
      if (_this.state.changetabskey == "环境温度") {
        var viewshowright2con3option = {
          grid: {
            left: '2%',
            right: '2%',
            top: '2%',
            bottom: '5%',
            scontainLabel: true
          },
          tooltip: {
            trigger: 'item',
            // position: [50, 20],
            formatter: "{c}"
          },
          xAxis: {
            show: false,
            type: 'category',
            boundaryGap: false,
            data: timearr
          },
          yAxis: {
            show: false,
            type: 'value'
          },
          series: [{
            data: a5arr,
            type: 'line',
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0, color: '#159fff' // 0% 处的颜色
                  },
                  {
                    offset: 1, color: '#159fff' // 100% 处的颜色
                  }]
                )
              }
            },
            itemStyle: {
              show: false,
              normal: {
                color: '#8cd5c2', //改变折线点的颜色
                lineStyle: {
                  color: 'none' //改变折线颜色
                }
              }
            },
            smooth: 0.5,
          }]
        };
        _this.setState({
          viewshowright2con3option: viewshowright2con3option,
        }, function () {
          //基于准备好的dom，初始化echarts实例
          var myChart6 = echarts.init(document.getElementById('viewshowright2con3'));
          // 绘制图表
          myChart6.setOption(_this.state.viewshowright2con3option);

        })
      }
    }, 10000)

    window.addEventListener("resize", this.winresize)
    var clientHeight = document.documentElement.clientHeight
    var clientWidth = document.documentElement.clientWidth
    // //console.log('宽和高', clientHeight, clientWidth)
    //登录状态    
    var username = sessionStorage.getItem('uname');
    var companyname = sessionStorage.getItem('factory')
    var servicename = sessionStorage.getItem('service')
    this.setState({
      clientHeight: clientHeight,
      clientWidth: clientWidth,
      company: companyname,
      service: servicename,
      username: username
    })

    if (username) {
      this.props.history.push('/')
    } else {
      this.props.history.push('/login')
    }

    //头像
    $('.useravatar .anticon').hover(function () {
      _this.setState({
        anticonflag: "1"
      })
      setTimeout(function () {

      }, 3000);
    }, function () {
      _this.setState({
        anticonflag: "0"
      })
    })
    //用户数据
    axios.get('/DATA/WebSet/UserInfo.aspx?flag=10').then(data => {
      // //console.log(conversion(data) ,username)
      const action = changeuserdata(conversion(data))
      store.dispatch(action)
      var arr = conversion(data)

      for (let i = 0; i < arr.length; i++) {
        if (username == arr[i].UserName) {
          this.setState({
            photoUrl: arr[i].photo
          })
        }
      }
    })

    //部门数据
    axios.get('/DATA/WebSet/CompanyOrganization.aspx?flag=13').then(res=>{
      const action = changedeptdata(res.data.data)
      store.dispatch(action)
    })

    //阻止浏览后退
    $(window).on('popstate', function () {
      window.history.forward(1)
    });
    //超时
    document.body.addEventListener("mousemove", function () {
      // ////////console.log("AAA");
      time = maxTime; // 重置超时时间
    }, false);
    
    var intervalId = setInterval(function () {
      time--;
      if (time <= 0) {
        sessionStorage.clear();
        document.querySelector('.smartproshou').addEventListener("click", function () {
          alert('已超时,请重新登陆')
          sessionStorage.clear()
          _this.props.history.push('/login')
        }, false);
        clearInterval(intervalId);
      }
    }, 1000)

    //全屏
    screenfull.onchange(() => {
      this.setState({
        icon: screenfull.isFullscreen ? 'shrink' : 'arrows-alt'
      }, function () {
      })
    })

    var a1arr = []
    var a2arr = []
    var a3arr = []
    var a4arr = []
    var a5arr = []
    for (let i = 0; i < 120; i++) {
      var a1 = (Math.random() * 20 + 130).toFixed(1)
      a1arr.push(a1)
      var a2 = (Math.random() * 40 + 770).toFixed(1)
      a2arr.push(a2)
      var a3 = (Math.random() * 10 + 8).toFixed(1)
      a3arr.push(a1)
      var a4 = (Math.random() * 10 + 8).toFixed(1)
      a4arr.push(a1)
      var a5 = (Math.random() * 5 + 25).toFixed(1)
      a5arr.push(a1)
    }
    var timearr = []
    var current = new Date().getTime()
    for (let i = 120; i > 0; i--) {
      timearr.push(new Date(current - i * 1000).getHours() + ':' + new Date(current - i * 1000).getMinutes() + ':' + new Date(current - i * 1000).getSeconds())
    }

    //当值数据
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=10').then(data => {
      // //console.log('当值数据',data)
      this.setState({
        dangzhiban: data.data
      })
    })
    //年发电总量
    var Adate = new Date("2019-8-27").getTime();
    var now = new Date().getTime();
    var days = now - Adate;
    var day = parseInt(days / (1000 * 60 * 60 * 24));
    //年发电量
    var Adata1 = 44875540;
    var Year_FDL = parseFloat(Adata1) + ((Math.random() * 40000 + 190000).toFixed(1)) * day
    //年燃料消耗量
    var Adata2 = 66108;
    var Year_RLXH = parseFloat(Adata2) + ((Math.random() * 30 + 270).toFixed(1)) * day
    //年供气量
    var Adata3 = 62778.9;
    var Year_GQL = parseFloat(Adata3) + (((Math.random() * 150 + 130)) * day).toFixed(1)

    this.setState({
      Y_GenerationCapacity: (parseFloat(Year_FDL) / 10000).toFixed(2),
      Y_FuelCT: parseFloat(Year_RLXH).toFixed(2),
      Y_MainSS: parseFloat(Year_GQL).toFixed(2)
    })

    //月发电量
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=12').then(data => {
      var arr = conversion(data)
      var dataAxis = []

      var Adate = new Date("2019-11-1").getTime();
      var now = new Date().getTime();
      var days = now - Adate;
      var day = parseInt(days / (1000 * 60 * 60 * 24));
      var month_FDL = (((Math.random() * 40000 + 200000)) * day).toFixed(1)

      var data = [405.0080, 183.8080, 473.3880, 687.1620, 734.8160, 694.7040, 720.9560, 623.8770, 669.0400, 641.7500, (month_FDL / 10000).toFixed(2)]
      for (let i = 1; i <= 11; i++) {
        dataAxis.push(`${i}月`)
      }
      var yMax = 500;
      var dataShadow = [];

      for (var i = 0; i < data.length; i++) {
        dataShadow.push(yMax);
      }
      for (let i = 0; i < arr.length; i++) {
        dataAxis.push(arr[i].RecordDate.split(' ')[0])
        data.push(arr[i].GenerationCapacity)
      }
      var montheleoption = {
        grid: {
          left: '0%',
          right: '0%',
          top: '15%',
          bottom: '4%',
          scontainLabel: true
        },
        tooltip: {
          trigger: 'item',
          // position: [50, 20],
          formatter: " {b} {c} "
        },
        // title: {
        //   text: '特性示例：渐变色 阴影 点击缩放',
        //   subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
        // },
        xAxis: {
          show: false,
          data: dataAxis,
          axisLabel: {
            inside: true,
            textStyle: {
              color: '#fff'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          z: 10
        },
        yAxis: {
          show: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999'
            }
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          // { // For shadow
          //   type: 'bar',
          //   itemStyle: {
          //     normal: { color: 'rgba(0,0,0,0.05)' }
          //   },
          //   barGap: '-100%',
          //   barCategoryGap: '40%',
          //   data: dataShadow,
          //   animation: false
          // },
          {
            type: 'bar',
            barWidth: 15,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#3cecfc' },
                    { offset: 0.5, color: '#3cecfc' },
                    { offset: 1, color: '#3cecfc' }
                  ]
                )
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#2378f7' },
                    { offset: 0.7, color: '#2378f7' },
                    { offset: 1, color: '#83bff6' }
                  ]
                )
              }
            },
            data: data
          }
        ]
      };
      this.setState({
        montheleoption: montheleoption,
        monthelearr: data
      }, function () {
        //基于准备好的dom，初始化echarts实例
        var myChart1 = echarts.init(document.getElementById('monthele'));
        // 绘制图表
        myChart1.setOption(this.state.montheleoption);

      })
    })
    //请求比较哪个燃量大
    //#1炉
    axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=1').then(data => {
      // //console.log('1038', conversion(data))
      var firstbilerdata = conversion(data)[0].Value
      this.setState({
        firstbilerdata
      }, function () {
        if (this.state.sencondboilerdata) {
          if (Number(this.state.firstbilerdata) > Number(this.state.sencondboilerdata)) {
            //锅炉蒸发量 #1
            axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=10').then(res => {
              ////console.log('锅炉蒸发量#1炉', conversion(res))
              var arr = conversion(res)
              var dataAxis = []
              var data = []

              for (let i = 0; i < arr.length; i++) {
                dataAxis.push(arr[i].Time.split(' ')[1])
                data.push((parseFloat(arr[i].Value)).toFixed(2))
              }
              var intemperatureoption = {
                tooltip: {
                  trigger: 'item',
                  // position: [50, 20],
                  formatter: " {c} "
                },
                grid: {
                  left: '13%',
                  right: '0%',
                  top: '7%',
                  bottom: '8%',
                  scontainLabel: true
                },
                xAxis: {
                  show: false,
                  type: 'category',
                  boundaryGap: false,
                  data: dataAxis
                },
                yAxis: {
                  axisLabel: {
                    show: true,
                    textStyle: {
                      color: '#fff',  //更改坐标轴文字颜色
                      fontSize: 12      //更改坐标轴文字大小
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#fff' //更改坐标轴颜色
                    }
                  },
                  splitLine: {
                    show: false
                  },
                  min: function (value) {
                    return value.min - 1;
                  },
                  // show: false,
                  type: 'value',
                },
                series: [{
                  data: data,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0, color: '#fb5679' // 0% 处的颜色
                        },
                        {
                          offset: 1, color: '#fb5679' // 100% 处的颜色
                        }]
                      )
                    }
                  },
                  itemStyle: {
                    show: false,
                    normal: {
                      color: '#8cd5c2', //改变折线点的颜色
                      lineStyle: {
                        color: 'none' //改变折线颜色
                      }
                    }
                  },
                  smooth: 0.5,
                }]
              };
              this.setState({
                intemperatureoption: intemperatureoption,
                intemperaturexAais: dataAxis,
                intemperaturedata: data,
                boilersele: '#1炉'
              }, function () {
                //基于准备好的dom，初始化echarts实例
                var myChart11 = echarts.init(document.getElementById('intemperatureline'));
                // 绘制图表
                myChart11.setOption(this.state.intemperatureoption);
                var _this = this
                timer11 = setInterval(function () {
                  axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=1').then(res => {
                    ////console.log('锅炉蒸发量#1炉', conversion(res))
                    var data = _this.state.intemperaturedata
                    var dataAxis = _this.state.intemperaturexAais
                    var arr = conversion(res)

                    dataAxis.shift()
                    data.shift()
                    for (let i = 0; i < arr.length; i++) {
                      dataAxis.push(arr[i].Time.split(' ')[1])
                      data.push((parseFloat(arr[i].Value)).toFixed(2))
                    }
                    var intemperatureoption = {
                      tooltip: {
                        trigger: 'item',
                        // position: [50, 20],
                        formatter: " {c} "
                      },
                      grid: {
                        left: '13%',
                        right: '0%',
                        top: '7%',
                        bottom: '8%',
                        scontainLabel: true
                      },
                      xAxis: {
                        show: false,
                        type: 'category',
                        boundaryGap: false,
                        data: dataAxis
                      },
                      yAxis: {
                        axisLabel: {
                          show: true,
                          textStyle: {
                            color: '#fff',  //更改坐标轴文字颜色
                            fontSize: 12      //更改坐标轴文字大小
                          }
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#fff' //更改坐标轴颜色
                          }
                        },
                        splitLine: {
                          show: false
                        },
                        min: function (value) {
                          return value.min - 1;
                        },
                        // show: false,
                        type: 'value',
                      },
                      series: [{
                        data: data,
                        type: 'line',
                        areaStyle: {
                          normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                offset: 0, color: '#fb5679' // 0% 处的颜色
                              },
                              {
                                offset: 1, color: '#fb5679' // 100% 处的颜色
                              }]
                            )
                          }
                        },
                        itemStyle: {
                          show: false,
                          normal: {
                            color: '#8cd5c2', //改变折线点的颜色
                            lineStyle: {
                              color: 'none' //改变折线颜色
                            }
                          }
                        },
                        smooth: 0.5,
                      }]
                    };
                    _this.setState({
                      intemperatureoption: intemperatureoption,
                      intemperaturexAais: dataAxis,
                      intemperaturedata: data
                    }, function () {
                      //基于准备好的dom，初始化echarts实例
                      var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                      // 绘制图表
                      myChart111.setOption(_this.state.intemperatureoption);
                    })
                  }, 10000)
                }, 10000)
              })
            })
          } else {
            //锅炉蒸发量 #2
            axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=10').then(res => {
              ////console.log('锅炉蒸发量#2炉', conversion(res))
              var arr = conversion(res)
              var dataAxis = []
              var data = []

              for (let i = 0; i < arr.length; i++) {
                dataAxis.push(arr[i].Time.split(' ')[1])
                data.push((parseFloat(arr[i].Value)).toFixed(2))
              }
              var intemperatureoption = {
                tooltip: {
                  trigger: 'item',
                  // position: [50, 20],
                  formatter: " {c} "
                },
                grid: {
                  left: '13%',
                  right: '0%',
                  top: '7%',
                  bottom: '8%',
                  scontainLabel: true
                },
                xAxis: {
                  show: false,
                  type: 'category',
                  boundaryGap: false,
                  data: dataAxis
                },
                yAxis: {
                  axisLabel: {
                    show: true,
                    textStyle: {
                      color: '#fff',  //更改坐标轴文字颜色
                      fontSize: 12      //更改坐标轴文字大小
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#fff' //更改坐标轴颜色
                    }
                  },
                  splitLine: {
                    show: false
                  },
                  min: function (value) {
                    return value.min - 1;
                  },
                  // show: false,
                  type: 'value',
                },
                series: [{
                  data: data,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0, color: '#fb5679' // 0% 处的颜色
                        },
                        {
                          offset: 1, color: '#fb5679' // 100% 处的颜色
                        }]
                      )
                    }
                  },
                  itemStyle: {
                    show: false,
                    normal: {
                      color: '#8cd5c2', //改变折线点的颜色
                      lineStyle: {
                        color: 'none' //改变折线颜色
                      }
                    }
                  },
                  smooth: 0.5,
                }]
              };
              this.setState({
                intemperatureoption: intemperatureoption,
                intemperaturexAais: dataAxis,
                intemperaturedata: data,
                boilersele: '#2炉'
              }, function () {
                //基于准备好的dom，初始化echarts实例
                var myChart11 = echarts.init(document.getElementById('intemperatureline'));
                // 绘制图表
                myChart11.setOption(this.state.intemperatureoption);
                var _this = this
                timer11 = setInterval(function () {
                  axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=1').then(res => {
                    ////console.log('锅炉蒸发量#2炉', conversion(res))
                    var data = _this.state.intemperaturedata
                    var dataAxis = _this.state.intemperaturexAais
                    var arr = conversion(res)

                    dataAxis.shift()
                    data.shift()
                    for (let i = 0; i < arr.length; i++) {
                      dataAxis.push(arr[i].Time.split(' ')[1])
                      data.push((parseFloat(arr[i].Value)).toFixed(2))
                    }
                    var intemperatureoption = {
                      tooltip: {
                        trigger: 'item',
                        // position: [50, 20],
                        formatter: " {c} "
                      },
                      grid: {
                        left: '13%',
                        right: '0%',
                        top: '7%',
                        bottom: '8%',
                        scontainLabel: true
                      },
                      xAxis: {
                        show: false,
                        type: 'category',
                        boundaryGap: false,
                        data: dataAxis
                      },
                      yAxis: {
                        axisLabel: {
                          show: true,
                          textStyle: {
                            color: '#fff',  //更改坐标轴文字颜色
                            fontSize: 12      //更改坐标轴文字大小
                          }
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#fff' //更改坐标轴颜色
                          }
                        },
                        splitLine: {
                          show: false
                        },
                        min: function (value) {
                          return value.min - 1;
                        },
                        // show: false,
                        type: 'value',
                      },
                      series: [{
                        data: data,
                        type: 'line',
                        areaStyle: {
                          normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                offset: 0, color: '#fb5679' // 0% 处的颜色
                              },
                              {
                                offset: 1, color: '#fb5679' // 100% 处的颜色
                              }]
                            )
                          }
                        },
                        itemStyle: {
                          show: false,
                          normal: {
                            color: '#8cd5c2', //改变折线点的颜色
                            lineStyle: {
                              color: 'none' //改变折线颜色
                            }
                          }
                        },
                        smooth: 0.5,
                      }]
                    };
                    _this.setState({
                      intemperatureoption: intemperatureoption,
                      intemperaturexAais: dataAxis,
                      intemperaturedata: data
                    }, function () {
                      //基于准备好的dom，初始化echarts实例
                      var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                      // 绘制图表
                      myChart111.setOption(_this.state.intemperatureoption);
                    })
                  })
                }, 10000)
              })
            })
          }
        }
      })
    })
    //#2炉
    axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=1').then(data => {
      // //console.log('1037', conversion(data))
      var sencondboilerdata = conversion(data)[0].Value
      this.setState({
        sencondboilerdata
      }, function () {
        if (this.state.firstbilerdata) {
          if (Number(this.state.firstbilerdata) > Number(this.state.sencondboilerdata)) {
            //锅炉蒸发量 #1
            axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=10').then(res => {
              ////console.log('锅炉蒸发量#1炉', conversion(res))
              var arr = conversion(res)
              var dataAxis = []
              var data = []

              for (let i = 0; i < arr.length; i++) {
                dataAxis.push(arr[i].Time.split(' ')[1])
                data.push((parseFloat(arr[i].Value)).toFixed(2))
              }
              var intemperatureoption = {
                tooltip: {
                  trigger: 'item',
                  // position: [50, 20],
                  formatter: " {c} "
                },
                grid: {
                  left: '13%',
                  right: '0%',
                  top: '7%',
                  bottom: '8%',
                  scontainLabel: true
                },
                xAxis: {
                  show: false,
                  type: 'category',
                  boundaryGap: false,
                  data: dataAxis
                },
                yAxis: {
                  axisLabel: {
                    show: true,
                    textStyle: {
                      color: '#fff',  //更改坐标轴文字颜色
                      fontSize: 12      //更改坐标轴文字大小
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#fff' //更改坐标轴颜色
                    }
                  },
                  splitLine: {
                    show: false
                  },
                  min: function (value) {
                    return value.min - 1;
                  },
                  // show: false,
                  type: 'value',
                },
                series: [{
                  data: data,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0, color: '#fb5679' // 0% 处的颜色
                        },
                        {
                          offset: 1, color: '#fb5679' // 100% 处的颜色
                        }]
                      )
                    }
                  },
                  itemStyle: {
                    show: false,
                    normal: {
                      color: '#8cd5c2', //改变折线点的颜色
                      lineStyle: {
                        color: 'none' //改变折线颜色
                      }
                    }
                  },
                  smooth: 0.5,
                }]
              };
              this.setState({
                intemperatureoption: intemperatureoption,
                intemperaturexAais: dataAxis,
                intemperaturedata: data,
                boilersele: '#1炉'
              }, function () {
                //基于准备好的dom，初始化echarts实例
                var myChart11 = echarts.init(document.getElementById('intemperatureline'));
                // 绘制图表
                myChart11.setOption(this.state.intemperatureoption);
                var _this = this
                timer11 = setInterval(function () {
                  axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=1').then(res => {
                    ////console.log('锅炉蒸发量#1炉', conversion(res))
                    var data = _this.state.intemperaturedata
                    var dataAxis = _this.state.intemperaturexAais
                    var arr = conversion(res)

                    dataAxis.shift()
                    data.shift()
                    for (let i = 0; i < arr.length; i++) {
                      dataAxis.push(arr[i].Time.split(' ')[1])
                      data.push((parseFloat(arr[i].Value)).toFixed(2))
                    }
                    var intemperatureoption = {
                      tooltip: {
                        trigger: 'item',
                        // position: [50, 20],
                        formatter: " {c} "
                      },
                      grid: {
                        left: '13%',
                        right: '0%',
                        top: '7%',
                        bottom: '8%',
                        scontainLabel: true
                      },
                      xAxis: {
                        show: false,
                        type: 'category',
                        boundaryGap: false,
                        data: dataAxis
                      },
                      yAxis: {
                        axisLabel: {
                          show: true,
                          textStyle: {
                            color: '#fff',  //更改坐标轴文字颜色
                            fontSize: 12      //更改坐标轴文字大小
                          }
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#fff' //更改坐标轴颜色
                          }
                        },
                        splitLine: {
                          show: false
                        },
                        min: function (value) {
                          return value.min - 1;
                        },
                        // show: false,
                        type: 'value',
                      },
                      series: [{
                        data: data,
                        type: 'line',
                        areaStyle: {
                          normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                offset: 0, color: '#fb5679' // 0% 处的颜色
                              },
                              {
                                offset: 1, color: '#fb5679' // 100% 处的颜色
                              }]
                            )
                          }
                        },
                        itemStyle: {
                          show: false,
                          normal: {
                            color: '#8cd5c2', //改变折线点的颜色
                            lineStyle: {
                              color: 'none' //改变折线颜色
                            }
                          }
                        },
                        smooth: 0.5,
                      }]
                    };
                    _this.setState({
                      intemperatureoption: intemperatureoption,
                      intemperaturexAais: dataAxis,
                      intemperaturedata: data
                    }, function () {
                      //基于准备好的dom，初始化echarts实例
                      var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                      // 绘制图表
                      myChart111.setOption(_this.state.intemperatureoption);
                    })
                  }, 10000)
                }, 10000)
              })
            })
          } else {
            //锅炉蒸发量 #2
            axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=10').then(res => {
              ////console.log('锅炉蒸发量#2炉', conversion(res))
              var arr = conversion(res)
              var dataAxis = []
              var data = []

              for (let i = 0; i < arr.length; i++) {
                dataAxis.push(arr[i].Time.split(' ')[1])
                data.push((parseFloat(arr[i].Value)).toFixed(2))
              }
              var intemperatureoption = {
                tooltip: {
                  trigger: 'item',
                  // position: [50, 20],
                  formatter: " {c} "
                },
                grid: {
                  left: '13%',
                  right: '0%',
                  top: '7%',
                  bottom: '8%',
                  scontainLabel: true
                },
                xAxis: {
                  show: false,
                  type: 'category',
                  boundaryGap: false,
                  data: dataAxis
                },
                yAxis: {
                  axisLabel: {
                    show: true,
                    textStyle: {
                      color: '#fff',  //更改坐标轴文字颜色
                      fontSize: 12      //更改坐标轴文字大小
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#fff' //更改坐标轴颜色
                    }
                  },
                  splitLine: {
                    show: false
                  },
                  min: function (value) {
                    return value.min - 1;
                  },
                  // show: false,
                  type: 'value',
                },
                series: [{
                  data: data,
                  type: 'line',
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0, color: '#fb5679' // 0% 处的颜色
                        },
                        {
                          offset: 1, color: '#fb5679' // 100% 处的颜色
                        }]
                      )
                    }
                  },
                  itemStyle: {
                    show: false,
                    normal: {
                      color: '#8cd5c2', //改变折线点的颜色
                      lineStyle: {
                        color: 'none' //改变折线颜色
                      }
                    }
                  },
                  smooth: 0.5,
                }]
              };
              this.setState({
                intemperatureoption: intemperatureoption,
                intemperaturexAais: dataAxis,
                intemperaturedata: data,
                boilersele: '#2炉'
              }, function () {
                //基于准备好的dom，初始化echarts实例
                var myChart11 = echarts.init(document.getElementById('intemperatureline'));
                // 绘制图表
                myChart11.setOption(this.state.intemperatureoption);
                var _this = this
                timer11 = setInterval(function () {
                  axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=1').then(res => {
                    ////console.log('锅炉蒸发量#2炉', conversion(res))
                    var data = _this.state.intemperaturedata
                    var dataAxis = _this.state.intemperaturexAais
                    var arr = conversion(res)

                    dataAxis.shift()
                    data.shift()
                    for (let i = 0; i < arr.length; i++) {
                      dataAxis.push(arr[i].Time.split(' ')[1])
                      data.push((parseFloat(arr[i].Value)).toFixed(2))
                    }
                    var intemperatureoption = {
                      tooltip: {
                        trigger: 'item',
                        // position: [50, 20],
                        formatter: " {c} "
                      },
                      grid: {
                        left: '13%',
                        right: '0%',
                        top: '7%',
                        bottom: '8%',
                        scontainLabel: true
                      },
                      xAxis: {
                        show: false,
                        type: 'category',
                        boundaryGap: false,
                        data: dataAxis
                      },
                      yAxis: {
                        axisLabel: {
                          show: true,
                          textStyle: {
                            color: '#fff',  //更改坐标轴文字颜色
                            fontSize: 12      //更改坐标轴文字大小
                          }
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#fff' //更改坐标轴颜色
                          }
                        },
                        splitLine: {
                          show: false
                        },
                        min: function (value) {
                          return value.min - 1;
                        },
                        // show: false,
                        type: 'value',
                      },
                      series: [{
                        data: data,
                        type: 'line',
                        areaStyle: {
                          normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                offset: 0, color: '#fb5679' // 0% 处的颜色
                              },
                              {
                                offset: 1, color: '#fb5679' // 100% 处的颜色
                              }]
                            )
                          }
                        },
                        itemStyle: {
                          show: false,
                          normal: {
                            color: '#8cd5c2', //改变折线点的颜色
                            lineStyle: {
                              color: 'none' //改变折线颜色
                            }
                          }
                        },
                        smooth: 0.5,
                      }]
                    };
                    _this.setState({
                      intemperatureoption: intemperatureoption,
                      intemperaturexAais: dataAxis,
                      intemperaturedata: data
                    }, function () {
                      //基于准备好的dom，初始化echarts实例
                      var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                      // 绘制图表
                      myChart111.setOption(_this.state.intemperatureoption);
                    })
                  })
                }, 10000)
              })
            })
          }
        }
      })
    })


    //主蒸汽温度
    axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1001&num=10').then(res => {
      //////console.log('中温过热器温度1', conversion(data))
      var arr = conversion(res)
      var dataAxis = []
      var data = []

      for (let i = 0; i < arr.length; i++) {
        dataAxis.push(arr[i].Time)
        data.push((parseFloat(arr[i].Value)).toFixed(2))
      }
      var consumeenergyoption = {
        grid: {
          left: '13%',
          right: '0%',
          top: '7%',
          bottom: '8%',
          scontainLabel: true
        },
        tooltip: {
          trigger: 'item',
          // position: [50, 20],
          formatter: " {c} "
        },
        xAxis: {
          show: false,
          type: 'category',
          boundaryGap: false,
          data: dataAxis
        },
        yAxis: {
          // show: false,
          type: 'value',
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 12      //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          },
          min: function (value) {
            return value.min - 1;
          }
        },
        series: [{
          data: data,
          type: 'line',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0, color: '#159fff' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#159fff' // 100% 处的颜色
                }]
              )
            }
          },
          itemStyle: {
            show: false,
            normal: {
              color: '#8cd5c2', //改变折线点的颜色
              lineStyle: {
                color: 'none' //改变折线颜色
              }
            }
          },
          smooth: 0.5,
        }]
      };
      this.setState({
        consumeenergyoption: consumeenergyoption,
        consumeenergyxAais: dataAxis,
        consumeenergydata: data
      }, function () {
        //基于准备好的dom，初始化echarts实例
        var myChart12 = echarts.init(document.getElementById('consumeenergyline'));
        // 绘制图表
        myChart12.setOption(this.state.consumeenergyoption);

        var _this = this
        timer12 = setInterval(function () {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1001&num=1').then(res => {
            ////console.log('锅炉蒸发量#1炉', conversion(res))
            var data = _this.state.consumeenergydata
            var dataAxis = _this.state.consumeenergyxAais
            var arr = conversion(res)

            dataAxis.shift()
            data.shift()
            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var consumeenergyoption = {
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                // show: false,
                type: 'value',
                splitLine: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                min: function (value) {
                  return value.min - 1;
                }
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#159fff' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#159fff' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            _this.setState({
              consumeenergyoption: consumeenergyoption,
              consumeenergyxAais: dataAxis,
              consumeenergydata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart122 = echarts.init(document.getElementById('consumeenergyline'));
              // 绘制图表
              myChart122.setOption(_this.state.consumeenergyoption);
            })
          })
        }, 10000)
      })
    })
    //进度
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=20').then(res => {
      //////console.log(conversion(data))
      var arr = conversion(res)
      var data = [
        { value: parseInt(arr[0].Final), name: '已处理缺陷' },
        { value: parseInt(parseInt(arr[0].Total) - parseInt(arr[0].Final)), name: '剩余缺陷' },
      ]
      var progressoption1 = {
        tooltip: {
          trigger: 'item',
          // position: [50, 20],
          formatter: "{c}"
        },
        // legend: {
        //   orient: 'vertical',
        //   x: 'left',
        //   data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
        // },
        series: [
          {
            name: '缺陷情况',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '12',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: data,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    'rgb(21,159,255)', 'rgb(0,71,157)',
                    // '#FFFF00', '#FF8C00', '#FF0000', '#FE8463',
                  ];
                  return colorList[params.dataIndex]
                }
              }
            }
          }
        ]
      };

      this.setState({
        finalper: arr[0],
        progressoption1: progressoption1
      }, function () {
        //基于准备好的dom，初始化echarts实例
        var myChart43 = echarts.init(document.getElementById('progressoption1'));
        // 绘制图表
        myChart43.setOption(this.state.progressoption1);
      })
    })
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=21').then(res => {
      //////console.log(conversion(data))
      var arr = conversion(res)
      var data = [
        { value: parseInt(arr[0].Y_FuelCT), name: '使用燃料' },
        { value: parseInt(parseInt(arr[0].YearStock) + parseInt(arr[0].Y_FuelIn) - parseInt(arr[0].Y_FuelCT)), name: '剩余库存' },
      ]
      var progressoption2 = {
        tooltip: {
          trigger: 'item',
          // position: [50, 20],
          formatter: "{c}"
        },
        // legend: {
        //   orient: 'vertical',
        //   x: 'left',
        //   data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
        // },
        series: [
          {
            name: '燃料情况',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '12',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: data,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    'rgb(126,206,244)', 'rgb(21,159,255)',
                    //  '#FFFF00', '#FF8C00', '#FF0000', '#FE8463',
                  ];
                  return colorList[params.dataIndex]
                }
              }
            }
          }
        ]
      };

      this.setState({
        yearstockper: arr[0],
        progressoption2: progressoption2
      }, function () {
        //基于准备好的dom，初始化echarts实例
        var myChart44 = echarts.init(document.getElementById('progressoption2'));
        // 绘制图表
        myChart44.setOption(this.state.progressoption2);
      })
    })

    //日负荷曲线
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=13').then(res => {
      //////console.log('日负荷曲线', conversion(data))
      var arr = conversion(res)
      var xAxisdata = []
      var data = []
      for (let i = 0; i < arr.length; i++) {
        xAxisdata.push(arr[i].date.slice(5))
        data.push(arr[i].values)
      }
      var dayfuhelineoption = {
        grid: {
          left: '14%',
          right: '8%',
          top: '15%',
          bottom: '18%',
          scontainLabel: true
        },
        tooltip: {
          trigger: 'item',
          // position: [50, 20],
          formatter: "{c}"
        },
        xAxis: {
          type: 'category',
          data: xAxisdata,
          axisLabel: {
            show: true,
            interval: 0,  //类目全显
            // rotate: -45,  //顺时针旋转45度
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 12      //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 12      //更改坐标轴文字大小
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' //更改坐标轴颜色
            }
          }
        },
        series: [{
          data: data,
          type: 'line',
          smooth: true
        }]
      };

      this.setState({
        dayfuhelineoption: dayfuhelineoption,
        dayfuhegetdate: arr
      }, function () {
        //基于准备好的dom，初始化echarts实例
        var myChart4 = echarts.init(document.getElementById('dayfuheline'));
        // 绘制图表
        myChart4.setOption(this.state.dayfuhelineoption);

      })
    })

    //大气污染物
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=14').then(data => {
      // //////console.log(conversion(data))
      this.setState({
        atmosphere: conversion(data)[0],
        atmosphereNO: conversion(data)[0].NO,
        atmosphereCO2: conversion(data)[0].CO2,
        atmosphereT: conversion(data)[0].T
      })
    })
    //氮氧化物
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=15').then(res => {
      // //////console.log(conversion(data))
      var arr = conversion(res)
      var dataAxis = []
      var data = []
      for (let i = 0; i < arr.length; i++) {
        dataAxis.push(arr[i].time)
        data.push(arr[i].NO)
      }

      var viewshowright2con1option = {
        grid: {
          left: '2%',
          right: '2%',
          top: '2%',
          bottom: '5%',
          scontainLabel: true
        },
        tooltip: {},
        xAxis: {
          show: false,
          type: 'category',
          boundaryGap: false,
          data: dataAxis
        },
        yAxis: {
          show: false,
          type: 'value'
        },
        series: [{
          data: data,
          type: 'line',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0, color: '#159fff' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#159fff' // 100% 处的颜色
                }]
              )
            }
          },
          itemStyle: {
            show: false,
            normal: {
              color: '#8cd5c2', //改变折线点的颜色
              lineStyle: {
                color: 'none' //改变折线颜色
              }
            }
          },
          smooth: 0.5,
        }]
      };

      this.setState({
        viewshowright2con1option: viewshowright2con1option,
      }, function () {
        //基于准备好的dom，初始化echarts实例
        var myChart4 = echarts.init(document.getElementById('viewshowright2con1'));
        // 绘制图表
        myChart4.setOption(this.state.viewshowright2con1option);
      })
    })
    //二氧化碳
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=16').then(res => {
      // //////console.log(conversion(data))
      var arr = conversion(res)
      var dataAxis = []
      var data = []
      for (let i = 0; i < arr.length; i++) {
        dataAxis.push(arr[i].time)
        data.push(arr[i].CO2)
      }

      var viewshowright2con2option = {
        grid: {
          left: '2%',
          right: '2%',
          top: '2%',
          bottom: '5%',
          scontainLabel: true
        },
        tooltip: {},
        xAxis: {
          show: false,
          type: 'category',
          boundaryGap: false,
          data: dataAxis
        },
        yAxis: {
          show: false,
          type: 'value'
        },
        series: [{
          data: data,
          type: 'line',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0, color: '#159fff' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#159fff' // 100% 处的颜色
                }]
              )
            }
          },
          itemStyle: {
            show: false,
            normal: {
              color: '#8cd5c2', //改变折线点的颜色
              lineStyle: {
                color: 'none' //改变折线颜色
              }
            }
          },
          smooth: 0.5,
        }]
      };

      this.setState({
        viewshowright2con2option: viewshowright2con2option,
      })
    })
    //环境温度
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=17').then(res => {
      // //////console.log(conversion(data))
      var arr = conversion(res)
      var dataAxis = []
      var data = []
      for (let i = 0; i < arr.length; i++) {
        dataAxis.push(arr[i].time)
        data.push(arr[i].T)
      }

      var viewshowright2con3option = {
        grid: {
          left: '2%',
          right: '2%',
          top: '2%',
          bottom: '5%',
          scontainLabel: true
        },
        tooltip: {},
        xAxis: {
          show: false,
          type: 'category',
          boundaryGap: false,
          data: dataAxis
        },
        yAxis: {
          show: false,
          type: 'value'
        },
        series: [{
          data: data,
          type: 'line',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0, color: '#159fff' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#159fff' // 100% 处的颜色
                }]
              )
            }
          },
          itemStyle: {
            show: false,
            normal: {
              color: '#8cd5c2', //改变折线点的颜色
              lineStyle: {
                color: 'none' //改变折线颜色
              }
            }
          },
          smooth: 0.5,
        }]
      };

      this.setState({
        viewshowright2con3option: viewshowright2con3option,
      })
    })
    //热电比
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=22').then(data => {
      //////console.log(conversion(data))
      this.setState({
        RDBobj: conversion(data)[0]
      })
    })
    axios.get('/Data/ParaSet/DataPanel.aspx?flag=23').then(data => {
      //////console.log(conversion(data))
      this.setState({
        CYDL1obj: conversion(data)[0]
      })
    })


    //警告信息
    axios.get('/DATA/ParaSet/DataPanel.aspx?flag=30').then(data => {
      // //console.log('警告信息',conversion(data))
      this.setState({
        defaultarr: conversion(data)
      })
    })
  }

  componentDidMount() {
    const factory = sessionStorage.getItem('factory')
    // console.log(factory)
    if(factory){
      this.props.history.push('/')
      this.gethomeInfo()
    }else{
      this.props.history.push('/login')
    }
  }
  winresize() {
    var clientHeight = document.documentElement.clientHeight
    var clientWidth = document.documentElement.clientWidth
    this.setState({
      clientHeight,
      clientWidth
    })
    const action = changeclienthwact(clientHeight, clientWidth)
    store.dispatch(action)
  }
  componentWillUnmount() {
    screenfull.off('change')
    clearInterval(timer1)
    screenfull.off('change')
    window.removeEventListener('resize', this.winresize)
  }
  // shouldComponentUpdate(nextStates){ 
  // 应该使用这个方法，否则无论state是否有变化都将会导致组件重新渲染
  // if(nextStates.someThings === this.state.someThings){
  //   return false
  //   }
  // }

  //全屏显示 
  screenfullToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle()
    }
  }

  //关于
  showModal() {
    this.setState({
      aboutvisible:true
    })
    // Modal.info({
    //   okText: '确定',
    //   content: (
    //     <div className="about">
    //       <h2 style={{ textAlign: 'center' }}>关于 伯勒智慧流程工厂软件B-SPPs V2.0</h2>
    //       <img src={"./image/img/sss.png"} />
    //       <Collapse accordion>
    //         <Panel showArrow={true} header="当前版本号：B-SPPs V2.1 发布时间2019年9月15号" key="1">
    //           <div><h2>1. B-SPPs </h2><p>1.1 首页对接电厂DCS数据，并实时显示电厂重要指标数据。</p></div>
    //           <div><h2>2. B-SPP Decisionmaking</h2><p>2.1 用于统计庆元电厂运行指标，支持图表显示指标数值、报表导出。</p><p>2.2小指标考核模块算法优化调整，新增直接读取DCS数据进行考核计算，减少用户工作量，同时优化异常点屏蔽功能，支持一键屏蔽所有异常测点。</p></div>
    //           <div><h2>3. B-SPP M&D</h2><p>3.1 界面风格优化调整。</p><p>3.2 新增状态监测模块，与电厂DCS数据对接，实时显示设备测点数据。</p><p>3.3 自动化诊断，将DCS数据对接计算软件，输出理论计算数据与DCS数据对比，优化设备运行。</p></div>
    //         </Panel>
    //         <Panel showArrow={true} header="版本号:B-SPPs V2.0 发布时间2019年7月27号" key="2">
    //           <div><h2>1. B-SPPs </h2><p>1.1 界面优化，全新升级，采用最先进的React框架搭建软件，提升软件流畅度。</p><p>1.2 采用全新的配色体系，使得软件更具有科技感、未来感。</p></div>
    //           <div><h2>2. B-SPP AssetView</h2><p>2.1 界面风格优化调整。</p><p>2.2 通过勾选模型编号，加载资产模型，提高资产浏览的自由度。</p><p>2.3 增加构件查找、统计功能。</p></div>
    //         </Panel>
    //         <Panel showArrow={true} header="版本号:B-SPPs V1.10 发布时间2019年03月28日" key="3">
    //           <div><h2>1. B-SPP M&D</h2><p>1.1 更新智慧诊断模块，支持BPDs、BDCs计算。</p></div>
    //           <div><h2>2. B-SPPs</h2><p> 2.1 更新SPPs界面风格。采用React.js丰富前端界面。</p><p>2.2 新增各个板块统计浏览界面。</p></div>
    //           <div><h2>3. B-SPP Platform</h2><p>3.1 优化测点管理模块。</p></div>
    //           <div><h2>4. B-SPP Assetview</h2><p>4.1 更新模型组合方式，模型加载更快。</p></div>
    //         </Panel>
    //         <Panel showArrow={true} header="版本号:B-SPPs V1.02 发布时间2018年12月18日" key="4">
    //           <div><h2>1. B-SPP Platform</h2><p>1.1 更新配置管理专业划分、区域划分、设备清单。</p></div>
    //           <div><h2>2. B-SPP AssetView</h2><p>2.1 新增数字化装配模块，基本属性、技术参数根据国标GB/T32575-2016进行修改</p></div>
    //           <div><h2>3. B-SPP O&M</h2><p>3.1 模快拆分为1、两票管理 2、设备台账 3、巡检管理 4、检修管理。</p></div>
    //           <div><h2>4. B-SPP DecisionMaking</h2><p>4.1 模块拆分为1、管理者驾驶舱 2、绩效考核 3、统计与报表。</p></div>
    //           <div><h2>5. B-SPPs</h2><p>5.1 新增状态监测模块。</p></div>
    //         </Panel>
    //       </Collapse>
    //       <div className="connact">
    //         <p>邮箱：<span>Boilerjs@vip.163.com</span></p>
    //         <p>网址：<a href="http://www.boilersoftware.com" target="_blank">http://www.boilersoftware.com</a></p>
    //       </div>
    //     </div>
    //   ),
    //   onOk() { },
    //   onCancel() {},
    // });
  }

  //退出系统
  exitsys() {
    axios.get('/Exit.aspx').then(data => {
      sessionStorage.removeItem('uname')
      sessionStorage.clear()
      this.props.history.push('/login')
    })
  }

  //新页签
  newframe(obj) {
    //console.log(obj)
    var arr = this.state.tabconarr
    var ishas = false
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].ID == obj.ID) {
        ishas = true
      }
    }
    if (ishas) {
      this.setState({
        seletabframe: obj.ID
      }, function () {
        this.refs.home1.style.display = "none"
        this.refs.home1aside.style.display = "block"
        this.props.history.push(`/${obj.Code}`)
      })
    } else {
      arr.push(obj)
      this.setState({
        tabconarr: arr,
        seletabframe: obj.ID
      }, function () {
        //console.log(this.state.seletabframe)
        this.props.history.push(`/${obj.Code}`)
        this.refs.home1.style.display = "none"
        this.refs.home1aside.style.display = "block"
      })
    }
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };
  //页签移除
  remove = targetKey => {
    var arr = this.state.tabconarr
    if (arr.length == 1) {
      this.setState({
        tabconarr: [],
        seletabframe: '',
        selectedKeys: []
      }, function () {
        this.props.history.push(`/`)
        this.refs.home1.style.display = "block"
        this.refs.home1aside.style.display = "none"
      })
    } else {
      var newarr = []
      var index = 0
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].ID == targetKey) {
          index = i
        } else {
          newarr.push(arr[i])
        }
      }
      if (index >= 1) {
        this.setState({
          tabconarr: newarr,
          seletabframe: newarr[index - 1].ID
        }, function () {
          this.props.history.push(`/${newarr[index - 1].Code}`)
        })
      } else if (index == 0) {
        this.setState({
          tabconarr: newarr,
          seletabframe: newarr[0].ID
        }, function () {
          this.props.history.push(`/${newarr[0].Code}`)
        })
      }
    }
  };
  //页签切换
  ontabsChange(activeKey) {
    var arr = this.state.tabconarr
    //console.log(activeKey, arr)
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].ID == activeKey) {
        this.setState({
          seletabframe: arr[i].ID
        }, function () {
          this.refs.shou.style.display = "none";
          this.refs.home1.style.display = "none";
          this.refs.home1aside.style.display = "block"
          this.props.history.push(`/${arr[i].Code}`)
        })
      }
    }
  }


  //切换模块
  qiehuanmodules(obj) {
    this.refs.shou.style.display = "none";
    this.refs.home1.style.display = "block";
    this.refs.home1aside.style.display = "none"

    var arr = this.state.allmoduleDataArr
    // //console.log('所有模块',arr)
    var arr1 = arr.filter(function (s) {
      return s.ParentId == obj.ID && s.ModuleType !="3"
    })

    for (let i = 0; i < arr1.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        arr1[i].children = arr.filter(function (s) {
          return s.ParentId == arr1[i].ID && s.ModuleType != "3"
        })
      }
    }
    var moduleDataArrNamesele = ""
    var pagenum = 0
    if (obj.Name == "管理驾驶舱") {
      moduleDataArrNamesele = "管理者驾驶舱"
      pagenum = 1
    } else if (obj.Name == "数据中心") {
      moduleDataArrNamesele = "数据平台"
      pagenum = 2
    } else if (obj.Name == "个人工作台") {
      moduleDataArrNamesele = "个人工作台"
      pagenum = 3
    } else if (obj.Name == "建设施工") {
      moduleDataArrNamesele = "施工展示平台"
      pagenum = 4
    } else if (obj.Name == "智慧运维") {
      moduleDataArrNamesele = "运维展示平台"
      pagenum = 5
    } else if (obj.Name == "智能诊断") {
      moduleDataArrNamesele = "诊断平台"
      pagenum = 6
    } else if (obj.Name == "系统设置") {
      moduleDataArrNamesele = "系统平台"
      pagenum = 7
    } else if (obj.Name == "发运管理") {
      moduleDataArrNamesele = "发运管理"
      pagenum = 8
    }
    this.setState({
      moduleDataArrIdsele: obj.ID,
      moduleDataArrObjsele: obj,
      moduleDataArrNamesele: moduleDataArrNamesele,
      pagenum: pagenum,
      MenuDataArr: arr1
    })
  }

  //返回首页
  homeshowcon() {
    this.refs.shou.style.display = "flex";
    this.refs.home1.style.display = "none";
    this.refs.home1aside.style.display = "none";
    this.setState({
      moduleDataArrIdsele: "",
      moduleDataArrObjsele: {}
    })
  }


  //全屏显示 
  screenfullToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle()
    }
  }

  //大气污染物
  changetabs(key) {
    this.setState({
      changetabskey: key
    }, function () {
      if (this.state.changetabskey == "二氧化碳") {
        //基于准备好的dom，初始化echarts实例
        var myChart5 = echarts.init(document.getElementById('viewshowright2con2'));
        // 绘制图表
        myChart5.setOption(this.state.viewshowright2con2option);
      } else if (this.state.changetabskey == "环境温度") {
        //基于准备好的dom，初始化echarts实例
        var myChart6 = echarts.init(document.getElementById('viewshowright2con3'));
        // 绘制图表
        myChart6.setOption(this.state.viewshowright2con3option);
      } else if (this.state.changetabskey == "氮氧化物") {
        //基于准备好的dom，初始化echarts实例
        var myChart4 = echarts.init(document.getElementById('viewshowright2con1'));
        // 绘制图表
        myChart4.setOption(this.state.viewshowright2con1option);
      }
    })
  }
  //锅炉选择
  boilerchange(value) {
    clearInterval(timer11)
    this.setState({
      boilersele: value
    }, function () {
      if (this.state.boilersele == "#1炉") {
        if (this.state.intemperaturesele == "锅炉蒸发量") {
          //锅炉蒸发量 #1
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=10').then(res => {
            ////console.log('锅炉蒸发量#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=1').then(res => {
                  ////console.log('锅炉蒸发量#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "锅炉排烟温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1067&num=10').then(res => {
            ////console.log('锅炉排烟温度#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1067&num=1').then(res => {
                  ////console.log('锅炉排烟温度#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "中温过热器温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1052&num=10').then(res => {
            ////console.log('中温过热器温度#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1052&num=1').then(res => {
                  ////console.log('中温过热器温度#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "锅炉出口蒸汽温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1033&num=10').then(res => {
            //console.log('锅炉出口蒸汽温度#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1033&num=1').then(res => {
                  ////console.log('中温过热器温度#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        }
      } else if (this.state.boilersele == "#2炉") {
        if (this.state.intemperaturesele == "锅炉蒸发量") {
          //锅炉蒸发量 #1
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=10').then(res => {
            ////console.log('锅炉蒸发量#2炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=1').then(res => {
                  ////console.log('锅炉蒸发量#2炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "锅炉排烟温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1068&num=10').then(res => {
            ////console.log('锅炉排烟温度#2炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1068&num=1').then(res => {
                  ////console.log('锅炉排烟温度#2炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "中温过热器温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1051&num=10').then(res => {
            ////console.log('中温过热器温度#2炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1051&num=1').then(res => {
                  ////console.log('中温过热器温度#2炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "锅炉出口蒸汽温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1034&num=10').then(res => {
            //console.log('锅炉出口蒸汽温度#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1034&num=1').then(res => {
                  ////console.log('中温过热器温度#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        }
      }
    })
  }
  //锅炉排烟温度
  intemperaturechange(value) {
    clearInterval(timer11)
    this.setState({
      intemperaturesele: value,
      unitintemperature: value == "锅炉蒸发量" ? "t/h" : "℃"
    }, function () {
      if (this.state.boilersele == "#1炉") {
        if (this.state.intemperaturesele == "锅炉蒸发量") {
          //锅炉蒸发量 #1
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=10').then(res => {
            ////console.log('锅炉蒸发量#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1038&num=1').then(res => {
                  ////console.log('锅炉蒸发量#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "锅炉排烟温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1067&num=10').then(res => {
            ////console.log('锅炉排烟温度#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1067&num=1').then(res => {
                  ////console.log('锅炉排烟温度#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "中温过热器温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1052&num=10').then(res => {
            ////console.log('中温过热器温度#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1052&num=1').then(res => {
                  ////console.log('中温过热器温度#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "锅炉出口蒸汽温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1033&num=10').then(res => {
            //console.log('锅炉出口蒸汽温度#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1033&num=1').then(res => {
                  ////console.log('中温过热器温度#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        }
      } else if (this.state.boilersele == "#2炉") {
        if (this.state.intemperaturesele == "锅炉蒸发量") {
          //锅炉蒸发量 #1
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=10').then(res => {
            ////console.log('锅炉蒸发量#2炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1037&num=1').then(res => {
                  ////console.log('锅炉蒸发量#2炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "锅炉排烟温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1068&num=10').then(res => {
            ////console.log('锅炉排烟温度#2炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1068&num=1').then(res => {
                  ////console.log('锅炉排烟温度#2炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "中温过热器温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1051&num=10').then(res => {
            ////console.log('中温过热器温度#2炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1051&num=1').then(res => {
                  ////console.log('中温过热器温度#2炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        } else if (this.state.intemperaturesele == "锅炉出口蒸汽温度") {
          axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1034&num=10').then(res => {
            //console.log('锅炉出口蒸汽温度#1炉', conversion(res))
            var arr = conversion(res)
            var dataAxis = []
            var data = []

            for (let i = 0; i < arr.length; i++) {
              dataAxis.push(arr[i].Time.split(' ')[1])
              data.push((parseFloat(arr[i].Value)).toFixed(2))
            }
            var intemperatureoption = {
              tooltip: {
                trigger: 'item',
                // position: [50, 20],
                formatter: " {c} "
              },
              grid: {
                left: '13%',
                right: '0%',
                top: '7%',
                bottom: '8%',
                scontainLabel: true
              },
              xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: dataAxis
              },
              yAxis: {
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: '#fff',  //更改坐标轴文字颜色
                    fontSize: 12      //更改坐标轴文字大小
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#fff' //更改坐标轴颜色
                  }
                },
                splitLine: {
                  show: false
                },
                min: function (value) {
                  return value.min - 1;
                },
                // show: false,
                type: 'value',
              },
              series: [{
                data: data,
                type: 'line',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0, color: '#fb5679' // 0% 处的颜色
                      },
                      {
                        offset: 1, color: '#fb5679' // 100% 处的颜色
                      }]
                    )
                  }
                },
                itemStyle: {
                  show: false,
                  normal: {
                    color: '#8cd5c2', //改变折线点的颜色
                    lineStyle: {
                      color: 'none' //改变折线颜色
                    }
                  }
                },
                smooth: 0.5,
              }]
            };
            this.setState({
              intemperatureoption: intemperatureoption,
              intemperaturexAais: dataAxis,
              intemperaturedata: data
            }, function () {
              //基于准备好的dom，初始化echarts实例
              var myChart11 = echarts.init(document.getElementById('intemperatureline'));
              // 绘制图表
              myChart11.setOption(this.state.intemperatureoption);
              var _this = this
              timer11 = setInterval(function () {
                axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1034&num=1').then(res => {
                  ////console.log('中温过热器温度#1炉', conversion(res))
                  var data = _this.state.intemperaturedata
                  var dataAxis = _this.state.intemperaturexAais
                  var arr = conversion(res)

                  dataAxis.shift()
                  data.shift()
                  for (let i = 0; i < arr.length; i++) {
                    dataAxis.push(arr[i].Time.split(' ')[1])
                    data.push((parseFloat(arr[i].Value)).toFixed(2))
                  }
                  var intemperatureoption = {
                    tooltip: {
                      trigger: 'item',
                      // position: [50, 20],
                      formatter: " {c} "
                    },
                    grid: {
                      left: '13%',
                      right: '0%',
                      top: '7%',
                      bottom: '8%',
                      scontainLabel: true
                    },
                    xAxis: {
                      show: false,
                      type: 'category',
                      boundaryGap: false,
                      data: dataAxis
                    },
                    yAxis: {
                      axisLabel: {
                        show: true,
                        textStyle: {
                          color: '#fff',  //更改坐标轴文字颜色
                          fontSize: 12      //更改坐标轴文字大小
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#fff' //更改坐标轴颜色
                        }
                      },
                      splitLine: {
                        show: false
                      },
                      min: function (value) {
                        return value.min - 1;
                      },
                      // show: false,
                      type: 'value',
                    },
                    series: [{
                      data: data,
                      type: 'line',
                      areaStyle: {
                        normal: {
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0, color: '#fb5679' // 0% 处的颜色
                            },
                            {
                              offset: 1, color: '#fb5679' // 100% 处的颜色
                            }]
                          )
                        }
                      },
                      itemStyle: {
                        show: false,
                        normal: {
                          color: '#8cd5c2', //改变折线点的颜色
                          lineStyle: {
                            color: 'none' //改变折线颜色
                          }
                        }
                      },
                      smooth: 0.5,
                    }]
                  };
                  _this.setState({
                    intemperatureoption: intemperatureoption,
                    intemperaturexAais: dataAxis,
                    intemperaturedata: data
                  }, function () {
                    //基于准备好的dom，初始化echarts实例
                    var myChart111 = echarts.init(document.getElementById('intemperatureline'));
                    // 绘制图表
                    myChart111.setOption(_this.state.intemperatureoption);
                  })
                }, 10000)
              }, 10000)
            })
          })
        }
      }
    })
  }
  //中温过热器温度
  consumeenergychange(value) {
    clearInterval(timer12)
    this.setState({
      consumeenergysele: value,
      unitconsumeenergy: value == "主蒸汽温度" ? "℃" : "MPa"
    }, function () {
      //中温过热器温度
      if (this.state.consumeenergysele == "主蒸汽温度") {
        //主蒸汽温度
        axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1001&num=10').then(res => {
          ////console.log('主蒸汽温度', conversion(data))
          var arr = conversion(res)
          var dataAxis = []
          var data = []

          for (let i = 0; i < arr.length; i++) {
            dataAxis.push(arr[i].Time)
            data.push((parseFloat(arr[i].Value)).toFixed(2))
          }
          var consumeenergyoption = {
            grid: {
              left: '13%',
              right: '0%',
              top: '7%',
              bottom: '8%',
              scontainLabel: true
            },
            tooltip: {
              trigger: 'item',
              // position: [50, 20],
              formatter: " {c} "
            },
            xAxis: {
              show: false,
              type: 'category',
              boundaryGap: false,
              data: dataAxis
            },
            yAxis: {
              // show: false,
              type: 'value',
              splitLine: {
                show: false
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#fff',  //更改坐标轴文字颜色
                  fontSize: 12      //更改坐标轴文字大小
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              },
              min: function (value) {
                return value.min - 1;
              }
            },
            series: [{
              data: data,
              type: 'line',
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0, color: '#159fff' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#159fff' // 100% 处的颜色
                    }]
                  )
                }
              },
              itemStyle: {
                show: false,
                normal: {
                  color: '#8cd5c2', //改变折线点的颜色
                  lineStyle: {
                    color: 'none' //改变折线颜色
                  }
                }
              },
              smooth: 0.5,
            }]
          };
          this.setState({
            consumeenergyoption: consumeenergyoption,
            consumeenergyxAais: dataAxis,
            consumeenergydata: data
          }, function () {
            //基于准备好的dom，初始化echarts实例
            var myChart12 = echarts.init(document.getElementById('consumeenergyline'));
            // 绘制图表
            myChart12.setOption(this.state.consumeenergyoption);

            var _this = this
            timer12 = setInterval(function () {
              axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1001&num=1').then(res => {
                ////console.log('主蒸汽温度', conversion(res))
                var data = _this.state.consumeenergydata
                var dataAxis = _this.state.consumeenergyxAais
                var arr = conversion(res)

                dataAxis.shift()
                data.shift()
                for (let i = 0; i < arr.length; i++) {
                  dataAxis.push(arr[i].Time.split(' ')[1])
                  data.push((parseFloat(arr[i].Value)).toFixed(2))
                }
                var consumeenergyoption = {
                  grid: {
                    left: '13%',
                    right: '0%',
                    top: '7%',
                    bottom: '8%',
                    scontainLabel: true
                  },
                  tooltip: {
                    trigger: 'item',
                    // position: [50, 20],
                    formatter: " {c} "
                  },
                  xAxis: {
                    show: false,
                    type: 'category',
                    boundaryGap: false,
                    data: dataAxis
                  },
                  yAxis: {
                    // show: false,
                    type: 'value',
                    splitLine: {
                      show: false
                    },
                    axisLabel: {
                      show: true,
                      textStyle: {
                        color: '#fff',  //更改坐标轴文字颜色
                        fontSize: 12      //更改坐标轴文字大小
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#fff' //更改坐标轴颜色
                      }
                    },
                    min: function (value) {
                      return value.min - 1;
                    }
                  },
                  series: [{
                    data: data,
                    type: 'line',
                    areaStyle: {
                      normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0, color: '#159fff' // 0% 处的颜色
                          },
                          {
                            offset: 1, color: '#159fff' // 100% 处的颜色
                          }]
                        )
                      }
                    },
                    itemStyle: {
                      show: false,
                      normal: {
                        color: '#8cd5c2', //改变折线点的颜色
                        lineStyle: {
                          color: 'none' //改变折线颜色
                        }
                      }
                    },
                    smooth: 0.5,
                  }]
                };
                _this.setState({
                  consumeenergyoption: consumeenergyoption,
                  consumeenergyxAais: dataAxis,
                  consumeenergydata: data
                }, function () {
                  //基于准备好的dom，初始化echarts实例
                  var myChart122 = echarts.init(document.getElementById('consumeenergyline'));
                  // 绘制图表
                  myChart122.setOption(_this.state.consumeenergyoption);
                })
              })
            }, 10000)
          })
        })
      } else if (this.state.consumeenergysele == "主蒸汽压力") {
        axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1002&num=10').then(res => {
          ////console.log('主蒸汽压力', conversion(res))
          var arr = conversion(res)
          var dataAxis = []
          var data = []

          for (let i = 0; i < arr.length; i++) {
            dataAxis.push(arr[i].Time)
            data.push((parseFloat(arr[i].Value)).toFixed(2))
          }
          var consumeenergyoption = {
            grid: {
              left: '13%',
              right: '0%',
              top: '7%',
              bottom: '8%',
              scontainLabel: true
            },
            tooltip: {
              trigger: 'item',
              // position: [50, 20],
              formatter: " {c} "
            },
            xAxis: {
              show: false,
              type: 'category',
              boundaryGap: false,
              data: dataAxis
            },
            yAxis: {
              // show: false,
              type: 'value',
              splitLine: {
                show: false
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#fff',  //更改坐标轴文字颜色
                  fontSize: 12      //更改坐标轴文字大小
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff' //更改坐标轴颜色
                }
              },
              min: function (value) {
                return value.min - 1;
              }
            },
            series: [{
              data: data,
              type: 'line',
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0, color: '#159fff' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#159fff' // 100% 处的颜色
                    }]
                  )
                }
              },
              itemStyle: {
                show: false,
                normal: {
                  color: '#8cd5c2', //改变折线点的颜色
                  lineStyle: {
                    color: 'none' //改变折线颜色
                  }
                }
              },
              smooth: 0.5,
            }]
          };
          this.setState({
            consumeenergyoption: consumeenergyoption,
            consumeenergyxAais: dataAxis,
            consumeenergydata: data
          }, function () {
            //基于准备好的dom，初始化echarts实例
            var myChart12 = echarts.init(document.getElementById('consumeenergyline'));
            // 绘制图表
            myChart12.setOption(this.state.consumeenergyoption);

            var _this = this
            timer12 = setInterval(function () {
              axios.get('http://sppsapi.bolesoftware.com/GetInstantaneousvValuesbyNum?id=1002&num=1').then(res => {
                ////console.log('主蒸汽压力', conversion(res))
                var data = _this.state.consumeenergydata
                var dataAxis = _this.state.consumeenergyxAais
                var arr = conversion(res)

                dataAxis.shift()
                data.shift()
                for (let i = 0; i < arr.length; i++) {
                  dataAxis.push(arr[i].Time.split(' ')[1])
                  data.push((parseFloat(arr[i].Value)).toFixed(2))
                }
                var consumeenergyoption = {
                  grid: {
                    left: '13%',
                    right: '0%',
                    top: '7%',
                    bottom: '8%',
                    scontainLabel: true
                  },
                  tooltip: {
                    trigger: 'item',
                    // position: [50, 20],
                    formatter: " {c} "
                  },
                  xAxis: {
                    show: false,
                    type: 'category',
                    boundaryGap: false,
                    data: dataAxis
                  },
                  yAxis: {
                    // show: false,
                    type: 'value',
                    splitLine: {
                      show: false
                    },
                    axisLabel: {
                      show: true,
                      textStyle: {
                        color: '#fff',  //更改坐标轴文字颜色
                        fontSize: 12      //更改坐标轴文字大小
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#fff' //更改坐标轴颜色
                      }
                    },
                    min: function (value) {
                      return value.min - 1;
                    }
                  },
                  series: [{
                    data: data,
                    type: 'line',
                    areaStyle: {
                      normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0, color: '#159fff' // 0% 处的颜色
                          },
                          {
                            offset: 1, color: '#159fff' // 100% 处的颜色
                          }]
                        )
                      }
                    },
                    itemStyle: {
                      show: false,
                      normal: {
                        color: '#8cd5c2', //改变折线点的颜色
                        lineStyle: {
                          color: 'none' //改变折线颜色
                        }
                      }
                    },
                    smooth: 0.5,
                  }]
                };
                _this.setState({
                  consumeenergyoption: consumeenergyoption,
                  consumeenergyxAais: dataAxis,
                  consumeenergydata: data
                }, function () {
                  //基于准备好的dom，初始化echarts实例
                  var myChart122 = echarts.init(document.getElementById('consumeenergyline'));
                  // 绘制图表
                  myChart122.setOption(_this.state.consumeenergyoption);
                })
              })
            }, 10000)
          })
        })
      }
    })
  }

  //修改密码
  editPwd() {
    this.setState({
      editpwdvisible: true
    }, function () {
      $('.initpwd').val("")
      $('.newpwd').val("")
      $('.newpwdconfirm').val("")
    })
  }
  handleOk() {
    var uname = sessionStorage.getItem('unumber')
    if ($('.initpwd').val() == "") {
      message.error('请输入原密码！')
    } else if ($('.newpwd').val() == "") {
      message.error('请输入新密码！')
    } else if ($('.newpwdconfirm').val() == "") {
      message.error('请再次输入新密码！')
    } else if ($('.newpwd').val() != $('.newpwdconfirm').val()) {
      message.error('输入的确认密码与新密码不匹配！')
    } else if ($('.newpwdconfirm').val() == $('.newpwd').val()) {
      axios.post("/DATA/WebSet/UserInfo.aspx?flag=15", {
        UserNumber: uname,
        OldPassword: $('.initpwd').val(),
        NewPassword: $('.newpwd').val()
      }).then(data => {
        //console.log(data)
        if (data.data == true || data.data.indexOf('true') > -1) {
          this.setState({
            editpwdvisible: false
          }, function () {
            message.success('密码修改成功！')
            this.props.history.push('/')
          })
        }
        else if (data.data == "{success:'false',msg:'不存在该用户名'}") {
          message.error('不存在该用户名！')
        } else if (data.data == "{success:'false',msg:'两次密码不一致！'}") {
          message.error('两次密码不一致！')
        } else if (data.data == "{success:'false',msg:'原密码输入不正确！'}") {
          message.error('原密码输入不正确！')
        }
      })
    }
  }
  handleCancel() {
    this.setState({
      editpwdvisible: false,
      aboutvisible:false
    })
  }

  //菜单切换
  onOpenChange = (openKeys) => {
    //此函数的作用只展开当前父级菜单（父级菜单下可能还有子菜单）
    if (openKeys.length === 0 || openKeys.length === 1) {
      this.setState({
        openKeys
      })
      return
    }

    const latestOpenKey = openKeys[openKeys.length - 1]

    if (latestOpenKey.includes(openKeys[0])) {
      this.setState({
        openKeys
      })
    } else {
      this.setState({
        openKeys: [latestOpenKey]
      })
    }
  }

  //关闭页签
  closecurrent() {
    var arr = this.state.tabconarr
    if (arr.length == 1) {
      this.setState({
        tabconarr: [],
        seletabframe: '',
        selectedKeys: []
      }, function () {
        this.props.history.push(`/`)
        this.refs.home1.style.display = "block"
        this.refs.home1aside.style.display = "none"
      })
    } else {
      var targetKey = this.state.seletabframe
      var newarr = []
      var index = 0
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].ID == targetKey) {
          index = i
        } else {
          newarr.push(arr[i])
        }
      }
      if (index >= 1) {
        this.setState({
          tabconarr: newarr,
          seletabframe: newarr[index - 1].ID
        }, function () {
          this.props.history.push(`/${newarr[index - 1].Code}`)
        })
      } else if (index == 0) {
        this.setState({
          tabconarr: newarr,
          seletabframe: newarr[0].ID
        }, function () {
          this.props.history.push(`/${newarr[0].Code}`)
        })
      }
    }
  }
  closeother() {
    var targetKey = this.state.seletabframe
    var arr = this.state.tabconarr
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].ID == targetKey) {
        newarr.push(arr[i])
      }
    }
    this.setState({
      tabconarr: newarr,
      seletabframe: newarr[0].ID
    }, function () {
      this.props.history.push(`/${newarr[0].Code}`)
    })
  }
  //移除所有页签
  closeall() {
    this.setState({
      tabconarr: [],
      seletabframe: '',
      selectedKeys: []
    }, function () {
      this.props.history.push(`/`)
      this.refs.home1.style.display = "block"
      this.refs.home1aside.style.display = "none"
    })
  }

  //重新渲染模块
  renderModule(){
    var moduleDataArrIdsele = this.state.moduleDataArrIdsele
    var arr = store.getState().moduledata
      
    var arr0 = arr.filter(function (s) {
      return s.ParentId == 0
    })
    var arr1 = arr.filter(function (s) {
      return s.ParentId == moduleDataArrIdsele
    })
    for (let i = 0; i < arr1.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        arr1[i].children = arr.filter(function (s) {
          return s.ParentId == arr1[i].ID && s.ModuleType != "3"
        })
      }
    }
    this.setState({
      MenuDataArr:arr1,
      moduleDataArr:arr0,
      allmoduleDataArr:arr
    })
  }
  render() {
    const { icon ,moduleDataArrIdsele} = this.state;
    const usermenu = (
      <Menu className='menu'>
        <Menu.Item>
          <span>
            你好-
										{
              sessionStorage.getItem('uname') ?
                sessionStorage.getItem('uname') : '11'
            }
          </span>
        </Menu.Item>
        <Menu.Item>
          <span>
            {this.state.service}
          </span>
        </Menu.Item>
        <Menu.Item>
          <span>
            {this.state.company}
          </span>
        </Menu.Item>
        <MenuItem onClick={this.editPwd.bind(this)}>
          <span>
            修改密码
					</span>
        </MenuItem>
        <MenuItem onClick={this.showModal.bind(this)}>
          <span>
            关于
					</span>
        </MenuItem>
        <MenuItem onClick={this.exitsys.bind(this)}>
          <span>
            退出
					</span>
        </MenuItem>
      </Menu>
    )
    const tabsmenu = (
      <Menu className='menu'>
        <MenuItem onClick={this.closecurrent.bind(this)}>
          <span>
            关闭当前标签
					</span>
        </MenuItem>
        <MenuItem onClick={this.closeother.bind(this)}>
          <span>
            关闭其它标签
					</span>
        </MenuItem>
        <MenuItem onClick={this.closeall.bind(this)}>
          <span>
            关闭全部标签
					</span>
        </MenuItem>
      </Menu>
    )
    //菜单编辑删除 及时响应
    if(store.getState().moduledata.length != this.state.allmoduleDataArr.length){
      this.renderModule()
    }else if(store.getState().moduledata.length == this.state.allmoduleDataArr.length){
      var arr = store.getState().moduledata
      var arr2 = this.state.allmoduleDataArr
      if(equalsObj(arr,arr2)){
      }else{
        this.renderModule()
      }
    }
    return (
      <div className="smartproshou" style={{ height: this.state.clientHeight + 'px' }}>
        <Modal
          title="修改密码"
          visible={this.state.editpwdvisible}
          onOk={this.handleOk.bind(this)}
          className="changepwd"
          onCancel={this.handleCancel.bind(this)}
        >
          <section><span className="bitian">原密码</span><Input placeholder="请输入原密码" className="initpwd" /></section>
          <section><span className="bitian">新密码</span><Input placeholder="请输入新密码" className="newpwd" /></section>
          <section><span className="bitian">确认新密码</span><Input placeholder="请再次输入新密码" className="newpwdconfirm" /></section>
        </Modal>

        <Modal
          title={null}
          footer={null}
          visible={this.state.aboutvisible}
          onOk={this.handleCancel.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          okText="确认"
          width={800}
          height={500}
          className="aboutusinfo"
          cancelText="取消"
        >
        <div className="about">
          <h2 style={{ textAlign: 'center' }}>关于 伯勒智慧流程工厂软件B-SPPs V2.0</h2>
          <img src={"./image/img/sss.png"} />
          <Collapse accordion>
            <Panel showArrow={true} header="当前版本号：B-SPPs V2.1 发布时间2019年9月15号" key="1">
              <div><h2>1. B-SPPs </h2><p>1.1 首页对接电厂DCS数据，并实时显示电厂重要指标数据。</p></div>
              <div><h2>2. B-SPP Decisionmaking</h2><p>2.1 用于统计庆元电厂运行指标，支持图表显示指标数值、报表导出。</p><p>2.2小指标考核模块算法优化调整，新增直接读取DCS数据进行考核计算，减少用户工作量，同时优化异常点屏蔽功能，支持一键屏蔽所有异常测点。</p></div>
              <div><h2>3. B-SPP M&D</h2><p>3.1 界面风格优化调整。</p><p>3.2 新增状态监测模块，与电厂DCS数据对接，实时显示设备测点数据。</p><p>3.3 自动化诊断，将DCS数据对接计算软件，输出理论计算数据与DCS数据对比，优化设备运行。</p></div>
            </Panel>
            <Panel showArrow={true} header="版本号:B-SPPs V2.0 发布时间2019年7月27号" key="2">
              <div><h2>1. B-SPPs </h2><p>1.1 界面优化，全新升级，采用最先进的React框架搭建软件，提升软件流畅度。</p><p>1.2 采用全新的配色体系，使得软件更具有科技感、未来感。</p></div>
              <div><h2>2. B-SPP AssetView</h2><p>2.1 界面风格优化调整。</p><p>2.2 通过勾选模型编号，加载资产模型，提高资产浏览的自由度。</p><p>2.3 增加构件查找、统计功能。</p></div>
            </Panel>
            <Panel showArrow={true} header="版本号:B-SPPs V1.10 发布时间2019年03月28日" key="3">
              <div><h2>1. B-SPP M&D</h2><p>1.1 更新智慧诊断模块，支持BPDs、BDCs计算。</p></div>
              <div><h2>2. B-SPPs</h2><p> 2.1 更新SPPs界面风格。采用React.js丰富前端界面。</p><p>2.2 新增各个板块统计浏览界面。</p></div>
              <div><h2>3. B-SPP Platform</h2><p>3.1 优化测点管理模块。</p></div>
              <div><h2>4. B-SPP Assetview</h2><p>4.1 更新模型组合方式，模型加载更快。</p></div>
            </Panel>
            <Panel showArrow={true} header="版本号:B-SPPs V1.02 发布时间2018年12月18日" key="4">
              <div><h2>1. B-SPP Platform</h2><p>1.1 更新配置管理专业划分、区域划分、设备清单。</p></div>
              <div><h2>2. B-SPP AssetView</h2><p>2.1 新增数字化装配模块，基本属性、技术参数根据国标GB/T32575-2016进行修改</p></div>
              <div><h2>3. B-SPP O&M</h2><p>3.1 模快拆分为1、两票管理 2、设备台账 3、巡检管理 4、检修管理。</p></div>
              <div><h2>4. B-SPP DecisionMaking</h2><p>4.1 模块拆分为1、管理者驾驶舱 2、绩效考核 3、统计与报表。</p></div>
              <div><h2>5. B-SPPs</h2><p>5.1 新增状态监测模块。</p></div>
            </Panel>
          </Collapse>
          <div className="connact">
            <p>邮箱：<span>Boilerjs@vip.163.com</span></p>
            <p>网址：<a href="http://www.boilersoftware.com" target="_blank">http://www.boilersoftware.com</a></p>
          </div>
        </div>
        </Modal>
        {/* 头部 */}
        <section className="home1top">
          <div className="home1top-left">
            <Tooltip title="首页">
              <span className="homepage" onClick={this.homeshowcon.bind(this)} style={{ borderRight: this.state.moduleDataArr.length > 0 ? "2px  solid #c7c4c4" : "none" }}>
                <img style={{ width: "20px", height: "20px" }} src={this.state.moduleDataArrIdsele ? require('../../assets/icon1/首页.png') : require('../../assets/icon1/首页c.png')} />
              </span>
            </Tooltip>
            <div className="fivemodules" style={{ borderRight: this.state.moduleDataArr.length > 0 ? "2px  solid #c7c4c4" : "none" }}>
              {
                this.state.moduleDataArr.length > 0 && this.state.moduleDataArr.map((item, index) => {
                  return (<Tooltip title={item.Name} key={index}>
                    <span onClick={this.qiehuanmodules.bind(this, item)}>
                    <img src={this.state.moduleDataArrIdsele == item.ID ?
                        item.OnClickIconCls : item.IconCls} alt="" />
                      {/* <img src={this.state.moduleDataArrIdsele == item.ID?
                        require(`../../assets/icon1/${item.Name}c.png`) : require(`../../assets/icon1/${item.Name}.png`)} alt="" /> */}
                    </span>
                  </Tooltip>)
                })
              }
            </div>
            <Tabs
              onChange={this.ontabsChange.bind(this)}
              onTabClick={this.ontabsChange.bind(this)}
              activeKey={this.state.seletabframe.toString()}
              type="editable-card"
              className="apptabs"
              onEdit={this.onEdit.bind(this)}
              style={{
                width: this.state.clientWidth > 1366 ? 780 : this.state.clientWidth > 900 ? 460 : 200,
                borderBottom: this.state.tabconarr.length > 0 ? "1px solid #203b5a" : "none"
              }}
            >
              {this.state.tabconarr.map(paneele => (
                <TabPane tab={paneele.Name} key={paneele.ID.toString()} closable={true}>

                </TabPane>
              ))}
            </Tabs>
            <Dropdown overlay={tabsmenu}>
              <span className="closealltabs" style={{ display: this.state.tabconarr.length > 0 ? "flex" : "none", alignItems: "center", justifyContent: "center" }}>
                <img src={require(`../../assets/icon1/下角标.png`)} alt="" />
              </span>
            </Dropdown>
          </div>

          <div className="home1top-right">
            <Icon type={icon} onClick={this.screenfullToggle} />
            <span>{this.state.username}</span>
            <Dropdown overlay={usermenu}>
              <span className="useravatar">
                <span className="avatartouxiang">
                  <img src={ this.state.photoUrl ? "/" + this.state.photoUrl:"./image/img/defaultUser.jpg" } className="userpic" alt="" />
                </span>
                {
                  this.state.anticonflag == "0" ?
                    <Icon type="caret-down" /> :
                    <Icon type="caret-up" />
                }
              </span>
            </Dropdown>
          </div>
        </section>

        {/* 首页界面 */}
        <div className="viewshou" ref="shou" style={{ height: this.state.clientHeight - 50 + 'px' }}>
          <div className="viewshowleft" style={{ width: this.state.clientHeight < 800 ? "326px" : "462px", height: this.state.clientHeight - 50 - 30 + 'px' }}>
            <div className="viewshowleft1" style={{
              height: (this.state.clientHeight - 50 - 30 - 40) * 0.15 + 'px',
              fontSize: this.state.clientHeight < 800 ? "12px" : "12px"
            }}>
              <h2 style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1300 ? "14px" : "12px" }}>当值情况：运行{this.state.dangzhiban}班</h2>
              <div className="viewshowleft1con">
                <div className="viewshowleft1con1">
                  <img style={{ width: this.state.clientHeight < 800 ? "20px" : "34px", height: this.state.clientHeight < 800 ? "20px" : "34px" }} alt="" src={require('../../assets/homepagetu/1.png')} />
                  <span className="viewshowleft1condata">{this.state.Y_GenerationCapacity}万kW•h</span>
                  <span className="viewshowleft1contitle">年累计发电总量</span>
                </div>
                <div className="viewshowleft1con2">
                  <img style={{ width: this.state.clientHeight < 800 ? "20px" : "34px", height: this.state.clientHeight < 800 ? "20px" : "34px" }} alt="" src={require('../../assets/homepagetu/2.png')} />
                  <span className="viewshowleft1condata">{this.state.Y_FuelCT} t</span>
                  <span className="viewshowleft1contitle">年累计燃料消耗量</span>
                </div>
                <div className="viewshowleft1con3">
                  <img style={{ width: this.state.clientHeight < 800 ? "20px" : "34px", height: this.state.clientHeight < 800 ? "20px" : "34px" }} alt="" src={require('../../assets/homepagetu/3.png')} />
                  <span className="viewshowleft1condata">{this.state.Y_MainSS} t</span>
                  <span className="viewshowleft1contitle">年累计供汽量</span>
                </div>
              </div>
            </div>
            <div className="viewshowleft2" style={{
              height: (this.state.clientHeight - 50 - 30 - 40) * 0.23 + 'px',
            }}>
              <div className="monthelecount">
                <div>
                  <span className="montheledata">{this.state.monthelearr[this.state.monthelearr.length - 1]}万kW•h</span>
                  <h2 style={{ fontSize: this.state.clientHeight < 720 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>月发电量统计</h2>
                </div>
                {/* <span className="monthelecompare"><span>同比上月</span><span><span>+53.6%</span></span></span> */}
              </div>
              <div id="monthele" style={{ height: (this.state.clientHeight - 50 - 30 - 40) * 0.23 - 40 + 'px', width: this.state.clientHeight < 800 ? "306px" : "442px", }}>

              </div>
            </div>
            <div className="viewshowleft3" style={{
              height: (this.state.clientHeight - 50 - 30 - 40) * 0.23 + 'px',
            }}>
              <div className="monthelecount">
                <div>
                  <span className="montheledata">
                    {this.state.intemperaturedata.length > 0 ? this.state.intemperaturedata[this.state.intemperaturedata.length - 1] : ""} {this.state.unitintemperature}
                  </span>
                  <h2 style={{ fontSize: this.state.clientHeight < 720 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>{this.state.intemperaturesele}</h2>
                </div>
                <div className="viewshowseletwo" >
                  <Select value={this.state.boilersele} onChange={this.boilerchange.bind(this)}>
                    <Option value="#1炉">#1炉</Option>
                    <Option value="#2炉">#2炉</Option>
                  </Select>
                  <Select value={this.state.intemperaturesele} onChange={this.intemperaturechange.bind(this)} style={{ marginLeft: "2px" }}>
                    <Option value="锅炉蒸发量">锅炉蒸发量</Option>
                    <Option value="锅炉排烟温度">锅炉排烟温度</Option>
                    <Option value="中温过热器温度">中温过热器温度</Option>
                    <Option value="锅炉出口蒸汽温度">锅炉出口蒸汽温度</Option>
                  </Select>
                </div>
              </div>
              <div id="intemperatureline" style={{
                height: (this.state.clientHeight - 50 - 30 - 40) * 0.23 - 40 + 'px',
                width: this.state.clientHeight < 800 ? "306px" : "442px",
              }}>


              </div>
            </div>
            <div className="viewshowleft4" style={{
              height: (this.state.clientHeight - 50 - 30 - 40) * 0.23 + 'px',
            }}>
              <div className="monthelecount">
                <div>
                  <span className="montheledata">
                    {this.state.consumeenergydata.length > 0 ? this.state.consumeenergydata[this.state.consumeenergydata.length - 1] : ""} {this.state.unitconsumeenergy}
                  </span>
                  <h2 style={{ fontSize: this.state.clientHeight < 720 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>{this.state.consumeenergysele}</h2>
                </div>
                <div className="viewshowsele">
                  <Select value={this.state.consumeenergysele} onChange={this.consumeenergychange.bind(this)}>
                    <Option value="主蒸汽温度">主蒸汽温度</Option>
                    <Option value="主蒸汽压力">主蒸汽压力</Option>
                  </Select>
                </div></div>
              <div id="consumeenergyline" style={{
                height: (this.state.clientHeight - 50 - 30 - 40) * 0.23 - 40 + 'px',
                width: this.state.clientHeight < 800 ? "306px" : "442px",
              }}>

              </div>
            </div>
            <div className="viewshowleft5" style={{
              height: (this.state.clientHeight - 50 - 30 - 40) * 0.15 + 'px',
            }}>
              <div className="viewshowleft51">
                <div id="progressoption1" style={{ width: this.state.clientHeight < 800 ? "70px" : "112px", height: this.state.clientHeight < 800 ? "70px" : "112px", }}>

                </div>
                {/* <Progress percent={100}
                  strokeWidth={20}
                  strokeColor="#ff6151" successPercent={this.state.finalper ? (parseInt(this.state.finalper.Final) / parseInt(this.state.finalper.Total)).toFixed(2) * 100 : ""} type="circle" /> */}
                <div className="viewshowleft5con">
                  <h2 style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>{(parseInt(this.state.finalper.Final) / parseInt(this.state.finalper.Total)).toFixed(2) * 100}%</h2>
                  <span>缺陷处理情况</span>
                </div>
              </div>
              <div className="viewshowleft52">
                <div id="progressoption2" style={{ width: this.state.clientHeight < 800 ? "70px" : "112px", height: this.state.clientHeight < 800 ? "70px" : "112px", }}>

                </div>
                {/* <Progress percent={100}
                  strokeWidth={20}
                  strokeColor="#ff7d43" successPercent={this.state.yearstockper ? (parseInt(this.state.yearstockper.Y_FuelCT) / (parseInt(this.state.yearstockper.Y_FuelIn) + parseInt(this.state.yearstockper.YearStock))).toFixed(2) * 100 : ""} type="circle" /> */}
                <div className="viewshowleft5con">
                  <h2 style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>{(parseInt(this.state.yearstockper.Y_FuelCT) / (parseInt(this.state.yearstockper.Y_FuelIn) + parseInt(this.state.yearstockper.YearStock))).toFixed(2) * 100}%</h2>
                  <span>燃料消耗比</span>
                </div>
              </div>
            </div>
          </div>
          <div className="viewshowright" style={{ width: this.state.clientHeight < 800 ? "326px" : "462px", height: this.state.clientHeight - 50 - 30 + 'px' }}>
            <div className="viewshowright1" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 + 'px' }}>
              <h2 ><span style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>日负荷曲线</span> <span>{this.state.dayfuhegetdate[0] ? this.state.dayfuhegetdate[0].date + ' ' + '—' + ' ' + this.state.dayfuhegetdate[this.state.dayfuhegetdate.length - 1].date : ""}</span></h2>
              <div id="dayfuheline" style={{ width: "100%", height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 - 24 + 'px' }}>

              </div>
            </div>
            <div className="viewshowright2" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 + 'px' }}>
              <h2 style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}><span>大气污染物 - {this.state.changetabskey}</span></h2>
              <div className="viewshowright2tabs">
                <div className="viewshowright2tab" onClick={this.changetabs.bind(this, '氮氧化物')}>
                  <span>{this.state.atmosphereNO}mg/m³</span>
                  <span>氮氧化物</span>
                </div>
                <div className="viewshowright2tab" onClick={this.changetabs.bind(this, '二氧化碳')}>
                  <span>{this.state.atmosphereCO2}mg/m³</span>
                  <span>二氧化碳</span>
                </div>
                <div className="viewshowright2tab" onClick={this.changetabs.bind(this, '环境温度')}>
                  <span>{this.state.atmosphereT}℃</span>
                  <span>环境温度</span>
                </div>
              </div>
              <div className="viewshowright2con" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 - 40 - 20 + 'px', width: "100%" }}>
                {
                  this.state.changetabskey == '氮氧化物' ?
                    <div id="viewshowright2con1" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 - 40 - 20 + 'px', width: this.state.clientHeight < 800 ? "310px" : "448px" }}>
                    </div> :
                    this.state.changetabskey == '二氧化碳' ?
                      <div id="viewshowright2con2" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 - 40 - 20 + 'px', width: this.state.clientHeight < 800 ? "310px" : "448px" }}>
                      </div> :
                      <div id="viewshowright2con3" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 - 40 - 20 + 'px', width: this.state.clientHeight < 800 ? "310px" : "448px" }}>
                      </div>
                }
              </div>
            </div>
            <div className="viewshowright3" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.16 + 'px' }}>
              <div className="viewshowright3con" >
                <div className="progressname"><h3 style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>热电比</h3>

                </div>
                <div className="progresscon">
                  <Progress type="circle" strokeWidth={6}
                    strokeColor="rgb(97, 149, 199)" percent={100} />
                  {/* <span>{this.state.RDBobj.RDB}</span>
                <span>热电比</span> */}
                  <span className="montheledata">{this.state.RDBobj.RDB}</span>
                </div>
              </div>
              <div className="viewshowright3con">
                <div className="progressname">
                  <h3 style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>厂用电率1%</h3>
                </div>
                <div className="progresscon">
                  <Progress type="circle" strokeWidth={6}
                    strokeColor="#5d6aa6" percent={100} />
                  <span className="montheledata">{this.state.CYDL1obj.CYDL1}</span>
                  {/* <span>{this.state.CYDL1obj.CYDL1}</span>
                <span>厂用电率1%</span> */}
                </div>
              </div>
            </div>
            <div className="viewshowright4" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 + 'px' }}>
              <h2 style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1200 ? "14px" : this.state.clientHeight > 1200 ? "18px" : "12px" }}>报警信息</h2>
              <div className="swiperdata" style={{ height: (this.state.clientHeight - 50 - 30 - 30) * 0.28 - 24 + 'px' }}>
                <Carousel autoplay vertical="true">
                  {
                    this.state.defaultarr.map((item, index) => {
                      return (
                        <div className="Carousellist" key={index} style={{ height: this.state.clientHeight > 800 ? "32%" : "50%" }}>
                          <h3 className={item.FaultRecord.indexOf('请检查') > -1 ? "jinggao" : "finished"} style={{ fontSize: this.state.clientHeight < 800 ? "12px" : this.state.clientHeight < 1200 ? "13px" : this.state.clientHeight > 1200 ? "14px" : "12px" }}>
                            <span>{item.FaultRecord}</span>
                            <span>{item.RecordTime}</span>
                          </h3>
                        </div>)
                    })
                  }
                </Carousel>
              </div>
            </div>

          </div>
        </div>

        {/* 业务模块 */}
        <div className="home1" ref="home1" style={{ height: this.state.clientHeight - 50 + 'px' }}>
          <section className="home1bot" style={{ height: this.state.clientHeight - 50 + 'px' }}>
            <div className="home1bot-left">
              <div className="contents">
                <h2>{this.state.moduleDataArrNamesele}</h2>
                <div className="assort" style={{ height: this.state.clientHeight - 50 - 46 + 'px' }}>
                  <Menu
                    theme="dark"
                    mode="inline"
                    onOpenChange={this.onOpenChange.bind(this)}
                    onClick={({ key }) => { this.setState({ selectedKeys: [key] }) }}
                    openKeys={this.state.openKeys}
                    selectedKeys={this.state.selectedKeys}
                  >
                    {
                      // src={require(`../../assets/icon1/${item.Name}.png`)}
                      this.state.MenuDataArr.length>0 ? this.state.MenuDataArr.map((item, index) => {
                        if (item.children && item.children.length > 0) {
                          return (<SubMenu
                            key={item.ID}
                            title={<span style={{ display: "flex", alignItems: "center" }}>
                              <img style={{ width: "14px", height: "14px", marginRight: "6px" }}  src={item.IconCls}  /> 
                              {item.Name}</span>}
                          >
                            {
                              item.children.map((itm, inde) => {
                                return (<Menu.Item id={itm.ID} key={'/' + itm.Code} onClick={this.newframe.bind(this, itm)}>
                                  <Link to={'/' + itm.Code}>
                                    <span style={{ display: "flex", alignItems: "center" }}>
                                      <img style={{ width: "14px", height: "14px", marginRight: "6px" }} src={itm.IconCls} />
                                      {itm.Name}
                                    </span>
                                  </Link></Menu.Item>
                                )
                              })
                            }
                          </SubMenu>)
                        } else {
                          return (<Menu.Item id={item.ID} key={'/' + item.Code} onClick={this.newframe.bind(this, item)}>
                            <Link to={'/' + item.Code}><span style={{ display: "flex", alignItems: "center" }}>
                              <img style={{ width: "14px", height: "14px", marginRight: "6px" }} src={item.IconCls} />{item.Name}</span>
                            </Link></Menu.Item>)
                        }
                      }) : ""
                    }
                  </Menu>
                </div>
              </div>
            </div>
            <div className="home1bot-right" style={{ width: this.state.clientWidth - 176 + 'px' }}>
              <div className="conshow" style={{ height: this.state.clientHeight - 50 + 'px' }}>
                {
                  this.state.pagenum === 1 ?
                    <Firstdata /> :
                    this.state.pagenum === 2 ?
                      <Seconddata opennewframe={this.newframe.bind(this)} configimageUrl={this.state.configimageUrl} /> :
                      this.state.pagenum === 3 ?
                        <Threedata /> :
                        this.state.pagenum === 4 ?
                          <Fourdata /> :
                          this.state.pagenum === 5 ?
                            <Fivedata status={this.state.icon} /> :
                            this.state.pagenum === 6 ?
                              <Sixdata /> :
                              this.state.pagenum === 7 ?
                                <Sevendata /> :
                                ''
                }
              </div>
            </div>
          </section>
        </div>

        {/* 业务模块内容 */}
        <section className="home1aside" ref="home1aside" style={{ width: "100%", height: this.state.clientHeight - 50 + 'px' }}>
          <Switch>
            {/* 管理驾驶舱 */}
            <Route exact path='/Report' component={Report} />

            <Route exact path='/SmallIndicators' component={SmallIndicators} />
            <Route exact path='/PerformanceAssessment' component={PerformanceAssessment} />



            {/* 数据平台 */}
            {/* 数据平台 项目信息管理*/}
            <Route exact path='/ProConfig' component={ProConfig} />
            <Route exact path='/FactoryConfig' component={FactoryConfig} />
            <Route exact path='/ProjectTree' component={ProjectTree} />
            <Route exact path='/AreaTree' component={AreaTree} />
            <Route exact path='/EquipmentTree' component={EquipmentTree} />
            <Route exact path='/ConstructTree' component={ConstructTree} />
            <Route exact path='/CeDianConfig' component={CeDianConfig} />

            {/* 数据平台 文档管理*/}
            <Route exact path='/Documentmanage' component={Documentmanage} />


            {/* 数据平台 清单管理*/}
            <Route exact path='/ListManagement' component={ListManagement} />

            {/* 数据平台 可视模型管理*/}
            <Route exact path='/DigitalAssembly' component={DigitalAssembly} />
            <Route exact path='/Visualizationofrelative' component={Visualizationofrelative} />
            <Route exact path='/EquipmenProperty' component={EquipmenProperty} />

            {/*个人工作台 */}

            {/* 建设施工 */}


            {/* 智慧运维 */}
            {/* 智慧运维 资产可视化*/}
            <Route exact path='/AssetView' component={AssetView} />

            {/* 智慧运维 智慧两票*/}
            <Route exact path='/TicketModule' component={TicketModule} />
            <Route exact path='/WisdomTwoticket' component={WisdomTwoticket} />

            {/* 智慧运维 定期工作*/}
            <Route exact path='/RegularworkList' component={RegularworkList} />
            <Route exact path='/RegularworkSet' component={RegularworkSet} />

            {/* 智慧运维 智慧巡检*/}
            <Route exact path='/InspectionTask' component={InspectionTask} />
            <Route exact path='/InspectionRoute' component={InspectionRoute} />
            <Route exact path='/PatrolRouteFu' component={PatrolRouteFu} />
            <Route exact path='/InspectionRecord' component={InspectionRecord} />
            <Route exact path='/InpectionSpecification' component={InpectionSpecification} />
            <Route exact path='/Inspectioncategory' component={Inspectioncategory} />


            {/* 智能诊断 */}
            {/* 智能诊断 诊断中心*/}
            <Route exact path='/WisdomDiagnosis' component={WisdomDiagnosis} />

            {/* 智能诊断 状态监测*/}
            <Route exact path='/ConditionInspection' component={ConditionInspection} />

            {/* 智能诊断 诊断配置*/}
            <Route exact path='/SensorSet' component={SensorSet} />
            <Route exact path='/WisdomDiagnosisModelConfig' component={WisdomDiagnosisModelConfig} />
            <Route exact path='/WisdomDiagnosisTools' component={WisdomDiagnosisTools} />
            <Route exact path='/WisdomDiagnosisModel' component={WisdomDiagnosisModel} />


            {/* 系统设置*/}
            {/* 系统设置 门户引擎*/}
            {/* <Route exact path='/门户引擎' component={PortalEngine} /> */}

            {/* 系统设置 菜单管理*/}
            <Route exact path='/MenuManage' component={MenuManage} />

            {/* 系统设置 用户管理*/}
            <Route exact path='/UserManage' component={UserManage} />

            {/* 系统设置 机构管理*/}
            <Route exact path='/OrganizationManage' component={OrganizationManage} />

            {/* 系统设置 角色管理*/}
            <Route exact path='/RoleManage' component={RoleManage} />

            {/* 系统设置 字典管理*/}
            <Route exact path='/DictionaryManage' component={DictionaryManage} />

            {/* 系统设置 权限管理*/}
            <Route exact path='/LimitManage' component={LimitManage} />

            {/* 系统设置 流程管理*/}
            <Route exact path='/ProcessManagement' component={ProcessManagement}/>

            {/* 系统设置 日志管理*/}
            <Route exact path='/AccessLog' component={AccessLog} />
            <Route exact path='/OperationLog' component={OperationLog} />


            {/*个人工作台*/}
            <Route exact path='/WorkList' component={WorkList} />
            <Route exact path='/WorkDone' component={WorkDone} />
            <Route exact path='/WorkStart' component={WorkStart} />


            {/* 发运管理 */}
            <Route exact path='/ShippingPlan' component={ShippingPlan} />
            <Route exact path='/BoxManagement' component={BoxManagement} />

          </Switch>
        </section>
      </div>
    );
  }
}

export default HomeModule;